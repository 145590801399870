// src/app/services/prompt-layer.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { PromptLayerTemplate, PromptLayerRequest } from '../models/prompt-layer.model';

@Injectable({
  providedIn: 'root'
})
export class PromptLayerService {
  private readonly promptLayerApiUrl = `${environment.artifactsApiUrl}/promptlayer`;
  private templateCache = new Map<string, PromptLayerTemplate>();

  constructor(private http: HttpClient) {}

  /**
   * Gets a prompt template by its identifier
   * @param templateId The unique identifier of the template
   * @param request Optional request parameters
   * @param bypassCache Optional flag to force a server request, bypassing cache
   * @returns Promise containing the prompt template
   */
  async getTemplate(
    templateId: string, 
    request?: Partial<PromptLayerRequest>, 
    bypassCache: boolean = false
  ): Promise<PromptLayerTemplate> {
   // console.log(`[PromptLayerService] Getting template ${templateId}`, { request, bypassCache });

    // Check cache first if not bypassing
    if (!bypassCache && this.templateCache.has(templateId)) {
      //console.log(`[PromptLayerService] Retrieved template ${templateId} from cache`);
      return this.templateCache.get(templateId)!;
    }

    try {
      // Construct the request URL
      const url = `${this.promptLayerApiUrl}/templates/${templateId}${environment.keyCode}`;

      // If there are additional request parameters, include them in the request body
      const response = await firstValueFrom(request ? this.http.post<PromptLayerTemplate>(url, request) : this.http.post<PromptLayerTemplate>(url, {}));

      // Cache the response
      this.templateCache.set(templateId, response);
      //console.log(`[PromptLayerService] Successfully retrieved template ${templateId} from server and cached`);
      
      return response;

    } catch (error) {
      console.error('[PromptLayerService] Error retrieving template:', error);
      throw error;
    }
  }

  /**
   * Gets a prompt template by its identifier as an Observable
   * @param templateId The unique identifier of the template
   * @param request Optional request parameters
   * @param bypassCache Optional flag to force a server request, bypassing cache
   * @returns Observable of the prompt template
   */
  getTemplateObservable(
    templateId: string, 
    request?: Partial<PromptLayerRequest>,
    bypassCache: boolean = false
  ): Observable<PromptLayerTemplate> {
    // Check cache first if not bypassing
    if (!bypassCache && this.templateCache.has(templateId)) {
      //console.log(`[PromptLayerService] Retrieved template ${templateId} from cache (Observable)`);
      return of(this.templateCache.get(templateId)!);
    }

    const url = `${this.promptLayerApiUrl}/templates/${templateId}${environment.keyCode}`;
    
    return request 
      ? this.http.post<PromptLayerTemplate>(url, request)
      : this.http.get<PromptLayerTemplate>(url);
  }

  /**
   * Clears the entire template cache
   */
  clearCache(): void {
    this.templateCache.clear();
    //console.log('[PromptLayerService] Template cache cleared');
  }

  /**
   * Removes a specific template from the cache
   * @param templateId The ID of the template to remove from cache
   */
  removeFromCache(templateId: string): void {
    this.templateCache.delete(templateId);
   // console.log(`[PromptLayerService] Template ${templateId} removed from cache`);
  }
}
