import { Injectable } from '@angular/core';

/**
 * RiffML DOM Node representation
 */
export interface RiffMLNode {
  tagName: string;
  content: string;
  attributes: { [key: string]: string };
  children: RiffMLNode[];
}

/**
 * Service for parsing RiffML markup language into a DOM-like structure
 */
@Injectable({
  providedIn: 'root'
})
export class RiffMLParserService {
  
  // Regular expression to detect template placeholders like {{tag}}
  private readonly templatePlaceholderRegex = /\{\{([^}]+)\}\}/g;
  
  // List of valid RiffML tag names (all lowercase for case-insensitive matching)
  private readonly validTagNames: string[] = [
    'if', 'setstate', 'addtostate', 'removefromstate', 'setmemory', 
    'wait', 'next', 'end', 'runtool', 'message', 'processingmessage', 
    'usermessage', 'print', 'tablelink', 'sessionlink', 'workstreamlink', 
    'webpagelink', 'unitlink', 'button', 'ignore_elements', 'selectfromlist', 'sessionslist', 'workstreamslist', 'sleep', 'progressmessage'
  ];
  
  // Debug traces
  private traces: string[] = [];
  
  constructor() { }
  
  /**
   * Parse RiffML text into a DOM-like structure
   * @param riffmlText The RiffML markup text to parse
   * @returns A hierarchical structure of RiffMLNode objects
   */
  parseRiffML(riffmlText: string): RiffMLNode[] {
    try {
      this.traces = []; // Reset traces
      this.trace('Starting RiffML parsing');
      
      // Remove HTML comments
      riffmlText = this.removeHtmlComments(riffmlText);
      
      // Replace template placeholders like {{tag}} with <Print value="tag"/>
      riffmlText = this.replaceTemplatePlaceholders(riffmlText);
      
      // Process IGNORE_ELEMENTS blocks
      riffmlText = this.preprocessIgnoreElements(riffmlText);
      
      // Pre-process to encode non-RiffML angle brackets
      riffmlText = this.preprocessRiffML(riffmlText);
      
      // Try parsing with DOM parser first
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(`<root>${riffmlText}</root>`, 'application/xml');
        
        // Check for parsing errors
        const parserError = doc.querySelector('parsererror');
        if (parserError) {
          this.trace('XML parsing error, switching to manual parser');
          const result = this.parseManually(riffmlText);
          this.outputTraces();
          return result;
        }
        
        // Convert XML DOM to RiffML DOM structure
        const result = this.convertXmlToRiffML(doc.documentElement);
        this.outputTraces();
        return result;
      } catch (error) {
        this.trace(`XML parsing failed: ${error}`);
        const result = this.parseManually(riffmlText);
        this.outputTraces();
        return result;
      }
    } catch (error) {
      this.trace(`Error parsing RiffML: ${error}`);
      const result = this.parseManually(riffmlText);
      this.outputTraces();
      return result;
    }
  }
  
  /**
   * Add a trace message
   */
  private trace(message: string): void {
    this.traces.push(message);
  }
  
  /**
   * Output all traces to console
   */
  private outputTraces(): void {
    console.log('PARSER::', this.traces);
  }
  
  /**
   * Removes HTML comments from the RiffML text
   */
  private removeHtmlComments(text: string): string {
    return text.replace(/<!--[\s\S]*?-->/g, '');
  }
  
  /**
   * Replace template placeholders {{tag}} with <Print value="tag"/> elements
   */
  private replaceTemplatePlaceholders(text: string): string {
    return text.replace(this.templatePlaceholderRegex, (match, placeholderContent) => {
      return `<Print value="${placeholderContent.trim()}"/>`;
    });
  }
  
  /**
   * Preprocess RiffML text to encode non-RiffML angle brackets
   * This function only keeps angle brackets that are part of valid RiffML tags
   */
  private preprocessRiffML(text: string): string {
    this.trace('Preprocessing RiffML to encode non-RiffML brackets');
    
    // Create a pattern that matches opening and closing tags for valid RiffML elements (case-insensitive)
    // This regex specifically handles:
    // 1. Opening tags: <tagname attr="value">
    // 2. Closing tags: </tagname>
    // 3. Self-closing tags: <tagname attr="value" /> or <tagname/>
    const validTagPattern = new RegExp(
      `<(\\/?)(${this.validTagNames.join('|')})([^>]*?)(\\s*\\/?\\s*)>`, 
      'gi'
    );
    
    let result = '';
    let lastIndex = 0;
    let match;
    
    // Reset regex lastIndex
    validTagPattern.lastIndex = 0;
    
    // Process the text by finding all valid tags and preserving them
    while ((match = validTagPattern.exec(text)) !== null) {
      const matchStart = match.index;
      const matchEnd = matchStart + match[0].length;
      
      // Add any text before this match, with < and > encoded
      if (matchStart > lastIndex) {
        const beforeText = text.substring(lastIndex, matchStart);
        result += beforeText.replace(/</g, '___LT___').replace(/>/g, '___GT___');
      }
      
      // Keep the matched tag as-is
      result += match[0];
      
      // Update last position
      lastIndex = matchEnd;
    }
    
    // Process any remaining text after the last match
    if (lastIndex < text.length) {
      const remainingText = text.substring(lastIndex);
      result += remainingText.replace(/</g, '___LT___').replace(/>/g, '___GT___');
    }
    
    // Log stats for debugging
    const originalTagsCount = (text.match(/</g) || []).length;
    const preservedTagsCount = (result.match(/</g) || []).length;
    const encodedCount = originalTagsCount - preservedTagsCount;
    
    this.trace(`Encoded ${encodedCount} non-RiffML angle brackets, preserved ${preservedTagsCount} valid tags`);
    
    return result;
  }
  
  /**
   * Process IGNORE_ELEMENTS blocks
   */
  private preprocessIgnoreElements(riffmlText: string): string {
    // Regex to match IGNORE_ELEMENTS blocks (case-insensitive)
    const ignoreBlockRegex = /<IGNORE_ELEMENTS>([\s\S]*?)<\/IGNORE_ELEMENTS>/gi;
    
    return riffmlText.replace(ignoreBlockRegex, (match, content) => {
      // Within IGNORE_ELEMENTS, encode standard < and > 
      let processedContent = content.replace(/</g, '___LT___').replace(/>/g, '___GT___');
      
      // Replace « and » with real < and >
      processedContent = processedContent.replace(/«/g, '<').replace(/»/g, '>');
      
      return processedContent;
    });
  }
  
  /**
   * Convert XML DOM to RiffML DOM structure
   */
  private convertXmlToRiffML(xmlNode: Element): RiffMLNode[] {
    const result: RiffMLNode[] = [];
    
    for (const childNode of Array.from(xmlNode.childNodes)) {
      if (childNode.nodeType === Node.TEXT_NODE) {
        // If it's a text node and it's not just whitespace
        let text = childNode.textContent || '';
        text = this.decodeSpecialEntities(text);
        
        if (text.trim().length > 0) {
          result.push({
            tagName: '#text',
            content: text,
            attributes: {},
            children: []
          });
        }
      } else if (childNode.nodeType === Node.ELEMENT_NODE) {
        const element = childNode as Element;
        
        // Skip parsererror elements
        if (element.tagName.toLowerCase() === 'parsererror') {
          continue;
        }
        
        // Extract all attributes
        const attributes: { [key: string]: string } = {};
        for (const attr of Array.from(element.attributes)) {
          attributes[attr.name] = this.decodeSpecialEntities(attr.value);
        }
        
        // Convert child nodes
        const children = this.convertXmlToRiffML(element);
        
        // Set content from text content if appropriate
        let content = '';
        if (children.length === 0 && element.textContent) {
          content = this.decodeSpecialEntities(element.textContent.trim());
        }
        
        // Create RiffML node
        const riffNode: RiffMLNode = {
          tagName: element.tagName.toLowerCase(),
          content: content,
          attributes: attributes,
          children: children
        };
        
        result.push(riffNode);
      }
    }
    
    return result;
  }
  
  /**
   * Decode special entities that were encoded during preprocessing
   */
  private decodeSpecialEntities(text: string): string {
    return text
      .replace(/___LT___/g, '<')
      .replace(/___GT___/g, '>')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'");
  }
  
  /**
   * Manual parsing method for when XML parsing fails
   * Uses a more robust approach for tag recognition
   */
  private parseManually(text: string): RiffMLNode[] {
    this.trace('Using manual parser for RiffML');
    
    // First, normalize line endings and whitespace
    text = text.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
    
    // Tokenize the input
    const tokens = this.lexer(text);
    this.trace(`Lexer found ${tokens.length} tokens`);
    
    // Parse the tokens
    return this.parser(tokens);
  }
  
  /**
   * Improved lexer - Breaks down the input text into tokens
   * with better handling of complex content
   */
  private lexer(text: string): Array<{type: string, content: string, attributes?: Record<string, string>, position?: number}> {
    const tokens: Array<{type: string, content: string, attributes?: Record<string, string>, position?: number}> = [];
    let pos = 0;
    
    // Pattern to match all types of tags - we only need to process valid tags here
    // since all other angle brackets should have been encoded in preprocessing
    const tagPattern = /<(\/?)([\w-]+)([^>]*?)(\/?)>/gi;
    let match;
    let lastIndex = 0;
    
    // Reset the RegExp's lastIndex to start from the beginning
    tagPattern.lastIndex = 0;
    
    while ((match = tagPattern.exec(text)) !== null) {
      const [fullMatch, isClosing, tagName, attributesStr, selfClosing] = match;
      const matchStart = match.index;
      const matchEnd = matchStart + fullMatch.length;
      
      // Handle text before this tag
      if (matchStart > lastIndex) {
        const textContent = text.substring(lastIndex, matchStart).trim();
        if (textContent) {
          tokens.push({ 
            type: 'text', 
            content: this.decodeSpecialEntities(textContent),
            position: lastIndex
          });
        }
      }
      
      // Convert tag name to lowercase for case-insensitive matching
      const tagNameLower = tagName.toLowerCase();
      
      // Check if it's a valid RiffML tag
      if (this.validTagNames.includes(tagNameLower)) {
        if (isClosing) {
          // It's a closing tag
          this.trace(`Found closing tag: ${tagNameLower} at position ${matchStart}`);
          tokens.push({
            type: 'close-tag',
            content: tagNameLower,
            position: matchStart
          });
        } else {
          // It's an opening tag
          this.trace(`Found opening tag: ${tagNameLower} at position ${matchStart}`);
          const attributes = this.extractAttributes(attributesStr);
          
          tokens.push({
            type: 'open-tag',
            content: tagNameLower,
            attributes: attributes,
            position: matchStart
          });
          
          // Handle self-closing tags
          if (selfClosing) {
            this.trace(`Self-closing tag detected: ${tagNameLower}`);
            tokens.push({
              type: 'close-tag',
              content: tagNameLower,
              position: matchEnd - 2 // Position right before the />
            });
          }
        }
      } else {
        // Not a valid RiffML tag, treat as text
        this.trace(`Non-RiffML tag found: ${tagName} at position ${matchStart}`);
        tokens.push({
          type: 'text',
          content: this.decodeSpecialEntities(fullMatch),
          position: matchStart
        });
      }
      
      lastIndex = matchEnd;
    }
    
    // Handle any remaining text
    if (lastIndex < text.length) {
      const textContent = text.substring(lastIndex).trim();
      if (textContent) {
        tokens.push({ 
          type: 'text', 
          content: this.decodeSpecialEntities(textContent),
          position: lastIndex
        });
      }
    }
    
    return tokens;
  }
  
  /**
   * Extract attributes from attribute string with improved handling
   */
  private extractAttributes(attributesStr: string): Record<string, string> {
    const attributes: Record<string, string> = {};
    
    // Skip leading/trailing whitespace
    attributesStr = attributesStr.trim();
    if (!attributesStr) return attributes;
    
    // Regex pattern to match attribute names and values
    // Handles both quoted and unquoted attribute values
    const attributePattern = /([^\s=]+)(?:\s*=\s*(?:"([^"]*)"|\s*'([^']*)'|([^\s>]+))?)?/g;
    let attrMatch;
    
    while ((attrMatch = attributePattern.exec(attributesStr)) !== null) {
      const [_, name, doubleQuotedValue, singleQuotedValue, unquotedValue] = attrMatch;
      
      // Determine the attribute value
      let value = '';
      if (doubleQuotedValue !== undefined) {
        value = doubleQuotedValue;
      } else if (singleQuotedValue !== undefined) {
        value = singleQuotedValue;
      } else if (unquotedValue !== undefined) {
        value = unquotedValue;
      }
      
      attributes[name] = this.decodeSpecialEntities(value);
    }
    
    return attributes;
  }
  
  /**
   * Improved parser with better tag handling
   */
  private parser(tokens: Array<{type: string, content: string, attributes?: Record<string, string>, position?: number}>): RiffMLNode[] {
    const rootNodes: RiffMLNode[] = [];
    const stack: {node: RiffMLNode, tagName: string, position?: number}[] = [];
    let currentTextContent = '';
    
    // Debug info
    const tagPairs: {open: number, close?: number, tag: string}[] = [];
    
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];
      
      if (token.type === 'text') {
        currentTextContent += token.content;
      } else if (token.type === 'open-tag') {
        // If we have accumulated text content, add it to current parent
        if (currentTextContent.trim()) {
          const textNode: RiffMLNode = {
            tagName: '#text',
            content: currentTextContent.trim(),
            attributes: {},
            children: []
          };
          
          if (stack.length > 0) {
            stack[stack.length - 1].node.children.push(textNode);
          } else {
            rootNodes.push(textNode);
          }
          
          currentTextContent = '';
        }
        
        // Track tag pair for debugging
        tagPairs.push({
          open: token.position || 0,
          tag: token.content
        });
        
        // Create new node for this tag
        const newNode: RiffMLNode = {
          tagName: token.content,
          content: '',
          attributes: token.attributes || {},
          children: []
        };
        
        // Add this node to its parent or to root nodes
        if (stack.length > 0) {
          stack[stack.length - 1].node.children.push(newNode);
        } else {
          rootNodes.push(newNode);
        }
        
        // Push this node onto the stack
        stack.push({ 
          node: newNode, 
          tagName: token.content,
          position: token.position
        });
        
        this.trace(`Push to stack: ${token.content}, stack depth: ${stack.length}`);
      } else if (token.type === 'close-tag') {
        // Update tag pair tracking
        const pairIndex = tagPairs.findIndex(pair => 
          pair.tag === token.content && pair.close === undefined
        );
        
        if (pairIndex >= 0) {
          tagPairs[pairIndex].close = token.position || 0;
        }
        
        // If we have accumulated text content, add it to current parent
        if (currentTextContent.trim()) {
          if (stack.length > 0) {
            const lastStackItem = stack[stack.length - 1];
            
            // If this is specifically the closing tag for the current node and it has no children yet,
            // set the text content directly on the node
            if (lastStackItem.tagName === token.content && 
                lastStackItem.node.children.length === 0) {
              lastStackItem.node.content = currentTextContent.trim();
              this.trace(`Set direct content for ${token.content}: "${currentTextContent.trim().substring(0, 30)}${currentTextContent.trim().length > 30 ? '...' : ''}"`);
            } else {
              // Otherwise, add it as a child text node
              const textNode: RiffMLNode = {
                tagName: '#text',
                content: currentTextContent.trim(),
                attributes: {},
                children: []
              };
              lastStackItem.node.children.push(textNode);
              this.trace(`Added text node to ${lastStackItem.tagName}`);
            }
          } else {
            // Text at the root level
            const textNode: RiffMLNode = {
              tagName: '#text',
              content: currentTextContent.trim(),
              attributes: {},
              children: []
            };
            rootNodes.push(textNode);
            this.trace(`Added text node to root`);
          }
          
          currentTextContent = '';
        }
        
        // Find the matching opening tag in the stack
        let matchIndex = -1;
        
        for (let j = stack.length - 1; j >= 0; j--) {
          // Use case-insensitive comparison
          if (stack[j].tagName.toLowerCase() === token.content.toLowerCase()) {
            matchIndex = j;
            break;
          }
        }
        
        if (matchIndex >= 0) {
          // We found a match
          if (matchIndex === stack.length - 1) {
            // It's the top of the stack, normal case
            stack.pop();
            this.trace(`Pop from stack: ${token.content}, stack depth: ${stack.length}`);
          } else {
            // The match is not at the top, we have unbalanced tags
            this.trace(`WARNING: Unbalanced tags - found ${token.content} but it's not at top of stack`);
            // Pop all elements up to and including the matching tag
            const tagsBeingClosed = stack.splice(matchIndex).map(item => item.tagName);
            this.trace(`Force-closing tags: ${tagsBeingClosed.join(', ')}`);
          }
        } else {
          this.trace(`WARNING: Found closing tag ${token.content} with no matching open tag`);
        }
      }
    }
    
    // Handle any remaining text
    if (currentTextContent.trim()) {
      const textNode: RiffMLNode = {
        tagName: '#text',
        content: currentTextContent.trim(),
        attributes: {},
        children: []
      };
      
      if (stack.length > 0) {
        stack[stack.length - 1].node.children.push(textNode);
        this.trace(`Added final text node to ${stack[stack.length - 1].tagName}`);
      } else {
        rootNodes.push(textNode);
        this.trace(`Added final text node to root`);
      }
    }
    
    // Check for unclosed tags
    if (stack.length > 0) {
      const unclosedTags = stack.map(item => item.tagName);
      this.trace(`WARNING: Unclosed tags remain: ${unclosedTags.join(', ')}`);
      
      // Add diagnostic info about potentially problematic tag pairs
      const unpairedTags = tagPairs
        .filter(pair => pair.close === undefined)
        .map(pair => `${pair.tag} at pos ${pair.open}`);
      
      if (unpairedTags.length > 0) {
        this.trace(`Unpaired opening tags: ${unpairedTags.join(', ')}`);
      }
      
      // Handle unclosed tags by implicitly closing them
      for (let i = stack.length - 1; i >= 0; i--) {
        this.trace(`Implicitly closing unclosed tag: ${stack[i].tagName}`);
        // No need to manipulate the tree structure since the nodes are already in place
      }
    }
    
    return rootNodes;
  }
  
  /**
   * Perform a depth-first search (DFS) through the RiffML DOM tree
   * @param rootNodes The root nodes to start the search from
   * @param callback The function to call for each node visited
   */
  traverseDFS(rootNodes: RiffMLNode[], callback: (node: RiffMLNode) => void): void {
    const visit = (node: RiffMLNode) => {
      callback(node);
      for (const child of node.children) {
        visit(child);
      }
    };
    
    for (const rootNode of rootNodes) {
      visit(rootNode);
    }
  }
  
  /**
   * Creates a string representation of the RiffML DOM for debugging
   * @param rootNodes The root nodes to start from
   * @returns A formatted string representation
   */
  toString(rootNodes: RiffMLNode[]): string {
    let result = '';
    
    const printNode = (node: RiffMLNode, indent: string) => {
      if (node.tagName === '#text') {
        result += `${indent}TEXT: "${node.content}"\n`;
      } else {
        result += `${indent}${node.tagName}`;
        
        // Add attributes
        const attrs = Object.entries(node.attributes);
        if (attrs.length > 0) {
          result += ' (';
          result += attrs.map(([key, value]) => `${key}="${value}"`).join(', ');
          result += ')';
        }
        
        if (node.content && node.content.trim().length > 0) {
          result += ` => "${node.content}"`;
        }
        
        result += '\n';
        
        // Print children
        for (const child of node.children) {
          printNode(child, indent + '  ');
        }
      }
    };
    
    for (const node of rootNodes) {
      printNode(node, '');
    }
    
    return result;
  }
}