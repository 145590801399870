import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { HeaderComponent } from '../header/header.component';
import { DomSanitizer } from '@angular/platform-browser';
import { LibraryMenuService, MenuData, MenuItem } from '../../services/library-menu.service';
import { ProjectManagerService } from '../../services/project-manager.service';
import { WorkspaceState } from '../../models/workspace-state.model';
import { Subscription } from 'rxjs';
import { MenuTriggerService } from '../../services/menu-trigger.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    RouterModule, 
    CommonModule, 
    MatTooltipModule, 
    HeaderComponent, 
    MatMenuModule,
    MatIconModule
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  
  isContentExpanded = true;
  menuData: MenuData | null = null;
  expandedSections: boolean[] = [];
  private subscription: Subscription = new Subscription();

  constructor(
    private libraryMenuService: LibraryMenuService,
    public sanitizer: DomSanitizer,
    private projectManagerService: ProjectManagerService,
    private workspaceState: WorkspaceState,
    private menuTriggerService: MenuTriggerService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.libraryMenuService.menuData$.subscribe(data => {
        this.menuData = data;
        // Initialize expandedSections array with first section expanded by default
        if (data && data.categories) {
          this.expandedSections = data.categories.map((_, index) => index === 0);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.menuTriggerService.registerTrigger(this.menuTrigger);
  }

  toggleSection(index: number, event: MouseEvent) {
    // Stop event propagation to prevent the menu from closing
    event.stopPropagation();
    this.expandedSections[index] = !this.expandedSections[index];
  }
  
  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  async onCreatePMSession() {
    const sessionId = await this.projectManagerService.createSession({name: 'New PM Session'});
    this.projectManagerService.updateSelectedSession(sessionId);
  }

  async onItemClick(item: MenuItem) {
    if (!item.playbookId) return;

    const sessionId = await this.projectManagerService.createSession({
      playbook: item.playbookId,
      name: item.name
    });

    await this.projectManagerService.updateSelectedSession(sessionId);

    if (item.firstMessage) {
      setTimeout(() => {
        this.workspaceState.sendChatMessage$.next({ 
          sessionId: sessionId, 
          message: item.firstMessage??'' 
        });
      }, 1000);
    }
  }
}