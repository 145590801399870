import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, merge, Subject, timer } from 'rxjs';
import { takeUntil, switchMap, mapTo, map } from 'rxjs/operators';

interface HoverEvent {
  isHovered: boolean;
  event?: MouseEvent;
}

@Directive({
  selector: '[appHoverContainer]',
  standalone: true
})
export class HoverContainerDirective implements OnInit, OnDestroy {
  @Output() hover = new EventEmitter<HoverEvent>();
  
  private destroy$ = new Subject<void>();
  private readonly HOVER_DELAY = 50;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const element = this.elementRef.nativeElement;
    const mouseenter$ = fromEvent<MouseEvent>(element, 'mouseenter');
    const mouseleave$ = fromEvent<MouseEvent>(element, 'mouseleave');

    merge(
      mouseenter$.pipe(
        map((event: MouseEvent) => ({ isHovered: true, event }))
      ),
      mouseleave$.pipe(
        switchMap((event: MouseEvent) => 
          timer(this.HOVER_DELAY).pipe(
            mapTo({ isHovered: false, event })
          )
        )
      )
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe(hoverEvent => {
      this.hover.emit(hoverEvent);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
