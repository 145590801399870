/**
 * @file src/app/components/codeview/codeview.component.ts
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceState } from '../../../models/workspace-state.model';
import { Subscription } from 'rxjs';
import { ToolsService } from '../../../services/tools.service';
import { NgxCodeJarComponent } from 'ngx-codejar';
import * as Prism from 'prismjs';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-r';
import 'prismjs/components/prism-json';

@Component({
  selector: 'app-codeview',
  standalone: true,
  imports: [CommonModule, NgxCodeJarComponent],
  templateUrl: './codeview.component.html',
  styleUrls: ['./codeview.component.scss']
})
export class CodeviewComponent implements OnInit, OnDestroy {
  private subscription: Subscription | undefined;
  private codeTypeSubscription: Subscription | undefined;
  sqlCode: string = '';
  currentCodeType: string = 'sql';

  // CodeJar options
  codeJarOptions = {
    tab: '    ',
    indentOn: /[(\[]$/,
    moveToNewLine: /^[)}\]]/,
    spellcheck: false,
    catchTab: true,
    preserveIdent: true,
    addClosing: true,
    history: true
  };

  constructor(
    private workspaceState: WorkspaceState,
    private toolsService: ToolsService
  ) {}

  ngOnInit(): void {
    // Subscribe to the current SQL state
    this.subscription = this.workspaceState.CurrentSql$.subscribe(sql => {
      this.sqlCode = sql || '';
    });

    // Subscribe to code type changes
    this.codeTypeSubscription = this.workspaceState.CurrentCodeType$.subscribe(type => {
      this.currentCodeType = type || 'sql';
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.codeTypeSubscription) {
      this.codeTypeSubscription.unsubscribe();
    }
  }

  /**
   * Called whenever user edits the code. Updates the shared CurrentSql$.
   */
  onCodeUpdate(code: string): void {
    this.sqlCode = code;
    this.workspaceState.CurrentSql$.next(this.sqlCode);
  }

  /**
   * Checks if the SQL code is not empty
   */
  isSqlValid(): boolean {
    return this.sqlCode !== null && this.sqlCode.trim() !== '';
  }

  /**
   * Calls the "runCurrentSql" tool when the user clicks the play button.
   */
  runSql(): void {
    if (this.isSqlValid()) {
      this.toolsService.callTool('runCurrentSql', '', '', null);
    }
  }

  /**
   * Highlights the code using Prism
   */
  highlight = (editor: HTMLElement) => {
    const code = editor.textContent || '';
    editor.innerHTML = Prism.highlight(
      code,
      Prism.languages[this.currentCodeType] || Prism.languages['sql'],
      this.currentCodeType
    );
  }
}