// src/app/components/workboard/workboard.component.ts

import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectManagerService } from '../../services/project-manager.service';
import { WorkboardState, WorkboardGroup, Workstream } from '../../models/workboard.model';
import { Subscription } from 'rxjs';
import { WorkstreamComponent } from './workstream/workstream.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-workboard',
  standalone: true,
  imports: [CommonModule, WorkstreamComponent, MatTooltipModule],
  templateUrl: './workboard.component.html',
  styleUrls: ['./workboard.component.scss']
})
export class WorkboardComponent implements OnInit, OnDestroy {
  workboardState: WorkboardState = { groups: [] };
  private subscription: Subscription | undefined;
  private zoomLevel: number = 1;
  private readonly ZOOM_STEP: number = 0.1;
  private readonly MIN_ZOOM: number = 0.5;
  private readonly MAX_ZOOM: number = 2;

  constructor(private projectManager: ProjectManagerService) {}

  ngOnInit(): void {
    this.subscription = this.projectManager.getWorkboardState()
      .subscribe((state: WorkboardState) => {
        this.workboardState = state;
      });
    this.applyZoom();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleGroupState(group: WorkboardGroup, event: Event): void {
    event.stopPropagation();
    this.projectManager.updateGroupState(group.id, group.state === 'open' ? 'closed' : 'open');
  }

  toggleWorkstreamState(workstream: Workstream, event: Event): void {
    event.stopPropagation();
    this.projectManager.updateWorkstreamState(workstream.id, workstream.state === 'open' ? 'closed' : 'open');
  }

  toggleWorkstreamSelection(workstream: Workstream, event: Event): void {
    event.stopPropagation();
    this.projectManager.updateWorkstreamSelection(
      workstream.id, 
      workstream.selectedState === 'yes' ? 'no' : 'yes'
    );
  }

  zoomIn(): void {
    if (this.zoomLevel < this.MAX_ZOOM) {
      this.zoomLevel = Math.min(this.zoomLevel + this.ZOOM_STEP, this.MAX_ZOOM);
      this.applyZoom();
    }
  }

  zoomOut(): void {
    if (this.zoomLevel > this.MIN_ZOOM) {
      this.zoomLevel = Math.max(this.zoomLevel - this.ZOOM_STEP, this.MIN_ZOOM);
      this.applyZoom();
    }
  }

  zoomReset(): void {
    this.zoomLevel = 1;
    this.applyZoom();
  }

  private applyZoom(): void {
    const container = document.querySelector('.zoom-content') as HTMLElement;
    if (container) {
      container.style.transform = `scale(${this.zoomLevel})`;
      container.style.transformOrigin = 'top left';
    }
  }
}