/**
 * @file src/app/services/tools/session-link.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';

/**
 * Tool: sessionlink
 * Sets the current session, and if needed sends a message to it
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerSessionLinkTool(service: ToolsService): void {
  (service as any).sessionlink = async function(nextVal: string, sessionId: string, riffMLNode: RiffMLNode | null, value:any): Promise<void> {
    if (!riffMLNode) return;

    let newSessionId = riffMLNode.attributes['id'];
    if (!newSessionId) return;

    var session = this.sessionsManagerService.getSessionByIdPrefix(newSessionId, sessionId);
    if (!session) 
        return;

    await this.sessionsManagerService.updateSelectedSession(session.id);

    if (session.relatedWorkstreamIds && session.relatedWorkstreamIds.length > 0) {
        const mode = this.workspaceState.autoSelectMode$.value;
        this.workboardManagerService.autoSelectWorkstreams(session.relatedWorkstreamIds, mode);
      }

      setTimeout(() => {
        // Send a message to the chat component to send a message
        this.workspaceState.sendChatMessage$.next({ sessionId: session.id, message: 'ready' });
      }, 1500);
  };
}