// src/app/mocks/data.mock.ts
import { v4 as uuidv4 } from 'uuid';

export interface MockObject {
  id: string;
  label: string;
  color: string;
  type: string;
  parentId?: string;
}

const PROJECTS = {
  DISCOUNT_ANALYSIS: {
    id: 'project-1',
    label: 'Discount Codes Analysis',
    color: '#5270ff',
    phases: {
      OVERVIEW: { id: 'phase-1-1', label: 'Overview' },
      CODE_USAGE: { id: 'phase-1-2', label: 'Code Usage' },
      PERFORMANCE: { id: 'phase-1-3', label: 'Performance' }
    }
  },
  MUSICFAIR: {
    id: 'project-2',
    label: 'Musicfair Data',
    color: '#ff9340',
    phases: {
      SALES: { id: 'phase-2-1', label: 'Sales Analysis' },
      INSIGHTS: { id: 'phase-2-2', label: 'Customer Insights' },
      TRENDS: { id: 'phase-2-3', label: 'Trends' }
    }
  },
  OPTIMIZATION: {
    id: 'project-3',
    label: 'Optimization Project',
    color: '#4CAF50',
    phases: {
      STATUS: { id: 'phase-3-1', label: 'Current Status' },
      METRICS: { id: 'phase-3-2', label: 'Metrics' },
      RESULTS: { id: 'phase-3-3', label: 'Results' }
    }
  }
};

export async function GetObjects(type: string, parentId?: string): Promise<MockObject[]> {
  // Simulate network delay
  await new Promise(resolve => setTimeout(resolve, 100));

  if (type === 'Project') {
    return Object.values(PROJECTS).map(project => ({
      id: project.id,
      label: project.label,
      color: project.color,
      type: 'Project'
    }));
  } else if (type === 'Phase') {
    const project = Object.values(PROJECTS).find(p => p.color === parentId);
    if (project) {
      return Object.values(project.phases).map(phase => ({
        id: phase.id,
        label: phase.label,
        color: project.color,
        type: 'Phase',
        parentId: project.id
      }));
    }
  }

  return [];
}
