import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';

interface ArtifactResponse {
    id: string;
    content: {
      blob: string;
      agentId: string;
      lastaccess: number;
    };
}

interface ArtifactData {
  id: string;
  artifactcontainerreference: string;
  displayname: string;
  description: string;
  content: {
    blob: string;
    organizationId: string;
    agentId: string;
    lastaccess: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ArtifactsService {
  private readonly containerRef = environment.workspaceContainerRef;
  private readonly artifactsApiUrl = environment.artifactsApiUrl;
  
  // In-memory cache to store artifacts data
  private artifactsCache: Map<string, ArtifactData> = new Map<string, ArtifactData>();

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    console.log('[ArtifactsService] Initializing service');
  }

  async loadArtifact(idParam: string, idByUser: boolean = false): Promise<any> {
    try {
      const user = await this.userService.getCurrentUser();
      if (!user?.email) {
        console.warn('[ArtifactsService] No user email available');
        return null;
      }

      const artifactId = idParam + (idByUser ? '_' + user.email : '');
      
      // Check if the artifact exists in the cache
      if (this.artifactsCache.has(artifactId)) {
        const cachedArtifact = this.artifactsCache.get(artifactId);
        console.log('[ArtifactsService] Retrieved artifact from cache:', cachedArtifact);

        if (cachedArtifact) {
          try {
            return JSON.parse(cachedArtifact.content.blob);
          } catch (parseError) {
            console.error('[ArtifactsService] Error parsing cached artifact blob:', parseError);
            // If there's an error parsing the cached blob, we'll fall back to loading from the API
          }
        }
      }

      console.log('[ArtifactsService] Cache miss, loading from API:', artifactId);
      
      const queryBody = {
        artifactcontainerreference: this.containerRef,
        id: artifactId,
      };

      const response = await firstValueFrom(
        this.http.post<ArtifactResponse>(`${this.artifactsApiUrl}/artifacts/byid${environment.keyCode}`, queryBody)
      );

      if (!response || !response.content || !response.content.blob) {
        console.log('[ArtifactsService] No existing artifact found:', queryBody, response);
        return null;
      }

      try {
        // Store the response in the cache
        const artifactData: ArtifactData = {
          id: artifactId,
          artifactcontainerreference: this.containerRef,
          displayname: `Artifact for ${user.email}`,
          description: 'User artifact data',
          content: {
            blob: response.content.blob,
            organizationId: environment.organizationId,
            agentId: user.email,
            lastaccess: response.content.lastaccess
          }
        };
        
        //this.artifactsCache.set(artifactId, artifactData);
        
        const parsedBlob = JSON.parse(response.content.blob);
        console.log('[ArtifactsService] Successfully parsed artifact blob:', parsedBlob);
        return parsedBlob;
      } catch (parseError) {
        console.error('[ArtifactsService] Error parsing artifact blob:', parseError);
        return null;
      }
    } catch (error) {
      console.error('[ArtifactsService] Error loading artifact:', error);
      return null;
    }
  }

  async saveArtifact(idParam: string, artifact: any, idByUser: boolean = false, useCache:boolean = true): Promise<void> {
    try {
      const user = await this.userService.getCurrentUser();
      if (!user?.email) {
        console.warn('[ArtifactsService] No user email available for save');
        return;
      }

      const artifactId = idParam + (idByUser ? '_' + user.email : '');
      const artifactBlob = JSON.stringify(artifact);

      const artifactData: ArtifactData = {
        id: artifactId,
        artifactcontainerreference: this.containerRef,
        displayname: `Artifact for ${user.email}`,
        description: 'User artifact data',
        content: {
          blob: artifactBlob,
          organizationId: environment.organizationId,
          agentId: user.email,
          lastaccess: Date.now()
        }
      };

      if (useCache) {
        // Store in cache before HTTP request
        //this.artifactsCache.set(artifactId, artifactData);
      }
      
      // Also save to the HTTP service
      await firstValueFrom(
        this.http.post(`${this.artifactsApiUrl}/artifacts`, artifactData)
      );

      console.log('[ArtifactsService] Artifact saved successfully (cache and API)', artifactData);
    } catch (error) {
      console.error('[ArtifactsService] Error saving artifact:', error);
      throw error;
    }
  }
  
  /**
   * Clears the in-memory cache
   */
  clearCache(): void {
    this.artifactsCache.clear();
    console.log('[ArtifactsService] Cache cleared');
  }
  
  /**
   * Removes a specific artifact from the cache
   */
  async removeCachedArtifact(idParam: string, idByUser: boolean = false): Promise<boolean> {
    try {
      if (idByUser) {
        const user = await this.userService.getCurrentUser();
        if (!user?.email) {
          console.warn('[ArtifactsService] No user email available for cache removal');
          return false;
        }
        
        const artifactId = idParam + '_' + user.email;
        const removed = this.artifactsCache.delete(artifactId);
        console.log(`[ArtifactsService] Artifact ${artifactId} ${removed ? 'removed from' : 'not found in'} cache`);
        return removed;
      } else {
        const removed = this.artifactsCache.delete(idParam);
        console.log(`[ArtifactsService] Artifact ${idParam} ${removed ? 'removed from' : 'not found in'} cache`);
        return removed;
      }
    } catch (error) {
      console.error('[ArtifactsService] Error removing cached artifact:', error);
      return false;
    }
  }
  
  /**
   * Gets the size of the cache
   */
  getCacheSize(): number {
    return this.artifactsCache.size;
  }
}