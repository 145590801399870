import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RecommendedFlow, RecommendedFlowsData, ProjectFlows, RECOMMENDED_FLOWS } from '../mocks/record-management.mock';

@Injectable({
  providedIn: 'root'
})
export class RecommendedFlowsService {
  private recommendedFlows = new BehaviorSubject<RecommendedFlow[]>([]);
  recommendedFlows$ = this.recommendedFlows.asObservable();

  constructor() {
    // Initialize with default flows
    this.updateFlows();
    console.log('RecommendedFlowsService initialized with default flows');
  }

  updateFlows(project?: string, phase?: string): void {
    console.log('Updating flows with project:', project, 'phase:', phase);
    const flows = this.getRecommendedFlows(project, phase);
    console.log('New flows:', flows);
    this.recommendedFlows.next(flows);
  }

  private getRecommendedFlows(project?: string, phase?: string): RecommendedFlow[] {
    // If no project, return default flows
    if (!project) {
      console.log('No project, returning default flows');
      return RECOMMENDED_FLOWS['default'];
    }

    const projectKey = project.toLowerCase();
    const projectFlows = RECOMMENDED_FLOWS[projectKey];
    
    if (!projectFlows || Array.isArray(projectFlows)) {
      console.log('No project flows found, returning default flows');
      return RECOMMENDED_FLOWS['default'];
    }

    // If we have a phase, return only phase-specific flows
    if (phase) {
      const phaseKey = phase.toLowerCase();
      const phaseFlows = projectFlows[phaseKey];
      
      if (phaseFlows) {
        console.log('Returning phase-specific flows');
        return phaseFlows;
      }
      
      console.log('No phase flows found, returning project general flows');
    }
    
    // If no phase or phase flows not found, return project general flows
    console.log('Returning project general flows');
    return projectFlows.general;
  }
}
