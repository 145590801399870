// src/app/models/workspace-state.model.ts

import { BehaviorSubject } from 'rxjs';
import { WorkspacesData } from './workspace.interface';
import { Session } from './session.model';
import { WorkboardState } from './workboard.model';
import { SessionsGridState } from './session.model';
import { UserWorkspaceState } from './user-workspace-state.interface';
import { Injectable } from '@angular/core';
import { ChatSession, ChatTurn } from './chat.model';
import { TablesCollection } from './tables.model';
import { ProjectManagerSessionMessage, ProjectManagerWorkboardGroup } from './project-manager-chat.model';
import { Workstream, WorkstreamConfig, TableExportConfig } from './workboard.model';
import { ProjectData } from './project.interface';
import { TableModel } from './tables.model';
import { Artifact } from './artifact.model';

export interface WorkspaceStateModel {
  workspacesData$: BehaviorSubject<WorkspacesData>;
  userWorkspaceState$: BehaviorSubject<UserWorkspaceState | null>;
  sessions$: BehaviorSubject<Session[]>;
  workboardState$: BehaviorSubject<WorkboardState>;
  sessionsGridState$: BehaviorSubject<SessionsGridState>;
  sessionMessagesMap$: Map<string, BehaviorSubject<ChatTurn[]>>;
  projectManagerMessagesMap$: Map<string, BehaviorSubject<ProjectManagerSessionMessage>>;
  selectedWorkstreams$: BehaviorSubject<Workstream[]>;
  projectsMap$: Map<string, BehaviorSubject<ProjectData>>;
  autoSelectMode$ : BehaviorSubject<'auto-replace' | 'auto-select' | 'off'>;
  sendChatMessage$: BehaviorSubject<{sessionId: string, message: string} | null>;
  CurrentCodeType$ : BehaviorSubject<string>;
  CurrentChartDescription$ : BehaviorSubject<string>;
  CurrentChartDetailedDescription$ : BehaviorSubject<string>;

   /**
   * Gets all workstreams from the workboard
   * @returns Array of all workstreams
   */
   getAllWorkstreams(): Workstream[];
  
   /**
    * Gets the most recently edited sessions
    * @param count Number of sessions to return (default: 5)
    * @returns Array of the most recent sessions
    */
   getLastSessions(count?: number): Session[];

     /**
   * Gets the last message sent by the user in a session
   * @param sessionId Optional session ID (if not provided, uses current session)
   * @returns Content of the last user message or empty string if none found
   */
  getLastUserMessage(sessionId?: string): string;
  
  /**
   * Gets the last message sent by the agent in a session
   * @param sessionId Optional session ID (if not provided, uses current session)
   * @returns Content of the last agent message or empty string if none found
   */
  getLastAgentMessage(sessionId?: string): string;
}

// Define the state item interface
export interface StateItem {
  id: string;
  name: string;
  description: string;
  whyRelevant: string;
  type: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class WorkspaceState implements WorkspaceStateModel {
  workspacesData$ = new BehaviorSubject<WorkspacesData>({
    version: '1.0',
    lastUpdated: new Date().toISOString(),
    organization: '',
    workspaces: [],
  });
  userWorkspaceState$ = new BehaviorSubject<UserWorkspaceState | null>(null);
  sessions$ = new BehaviorSubject<Session[]>([]);
  workboardState$ = new BehaviorSubject<WorkboardState>({ groups: [] });
  sessionsGridState$ = new BehaviorSubject<SessionsGridState>({ filters: [] });
  tablesCache$: { [workspaceId: string]: TablesCollection } = {};

  // Add a new BehaviorSubject for the current SQL and table
  public CurrentSql$ = new BehaviorSubject<string>('');
  public CurrentCodeType$ = new BehaviorSubject<string>('SQL');
  public CurrentMermaid$ = new BehaviorSubject<string>('');
  public CurrentChartDescription$ = new BehaviorSubject<string>('');
  public CurrentChartDetailedDescription$ = new BehaviorSubject<string>('');

  public CurrentTable$ = new BehaviorSubject<any>(null);

  /**
   * A Map holding a BehaviorSubject of ChatMessage[] for each sessionId.
   * This way, any service/component can subscribe to updates for a given session.
   */
  sessionMessagesMap$ = new Map<string, BehaviorSubject<ChatTurn[]>>;

  chatSessionsMap$ = new Map<string, BehaviorSubject<ChatSession>>;

  /**
   * A Map holding ProjectManagerSessionMessage for each sessionId.
   * Enables tracking and merging of project suggestions per session.
   */
  projectManagerMessagesMap$ = new Map<string, BehaviorSubject<ProjectManagerSessionMessage>>();


  // -------------
  // Selected workstreams
  // New BehaviorSubject for selected workstreams
    selectedWorkstreams$ = new BehaviorSubject<Workstream[]>([]);

    public autoSelectMode$ = new BehaviorSubject<'auto-replace' | 'auto-select' | 'off'>('auto-replace');
    public sendChatMessage$ = new BehaviorSubject<{sessionId: string, message: string, artifacts?: Artifact[]} | null>(null);



   // New methods for managing selected workstreams
   updateSelectedWorkstreams(workstream: Workstream, selected: boolean): void {
    const currentSelected = this.selectedWorkstreams$.value;
    let newSelected: Workstream[];
    
    if (selected) {
      // Add workstream if not already in the list
      if (!currentSelected.some(ws => ws.id === workstream.id)) {
        newSelected = [...currentSelected, workstream];
      } else {
        newSelected = currentSelected;
      }
    } else {
      // Remove workstream if present
      newSelected = currentSelected.filter(ws => ws.id !== workstream.id);
    }
    
    this.selectedWorkstreams$.next(newSelected);
  }

  getSelectedWorkstreams(): Workstream[] {
    return this.selectedWorkstreams$.value;
  }

  clearSelectedWorkstreams(): void {
    this.selectedWorkstreams$.next([]);
  }

  // Add implementations to WorkspaceState class
  getAllWorkstreams(): Workstream[] {
    const workstreams: Workstream[] = [];
    const workboard = this.workboardState$.value;
    
    workboard.groups.forEach(group => {
      group.workstreamCols.forEach(col => {
        workstreams.push(...col.workstreams);
      });
    });
    
    return workstreams;
  }

  // Add implementations to WorkspaceState class
  getLastUserMessage(sessionId?: string): string {
    // If no sessionId provided, try to get from current state
    const targetSessionId = sessionId;
    if (!targetSessionId) {
      return '';
    }
    
    const messages = this.tryGetSessionMessages(targetSessionId)?.value || [];
    
    // Loop from newest to oldest
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].role === 'user') {
        return messages[i].content || '';
      }
    }
    
    return '';
  }

  getLastAgentMessage(sessionId?: string): string {
    // If no sessionId provided, try to get from current state
    const targetSessionId = sessionId ;
    if (!targetSessionId) {
      return '';
    }
    
    const messages = this.tryGetSessionMessages(targetSessionId)?.value || [];
    
    // Loop from newest to oldest
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].role === 'agent') {
        return messages[i].content || '';
      }
    }
    
    return '';
  }

  getLastSessions(count: number = 5): Session[] {
    return this.sessions$.value
      .sort((a, b) => new Date(b.lastEdited).getTime() - new Date(a.lastEdited).getTime())
      .slice(0, count);
  }

  /**
   * Retrieves an observable of ChatMessages for the given sessionId.
   * If no BehaviorSubject exists yet, it creates one with an empty array.
   */
  getSessionMessagesObservable(sessionId: string): BehaviorSubject<ChatTurn[]> {
    if (!this.sessionMessagesMap$.has(sessionId)) {
      this.sessionMessagesMap$.set(sessionId, new BehaviorSubject<ChatTurn[]>([]));
    }
    return this.sessionMessagesMap$.get(sessionId)!;
  }

  /**
   * Returns the current ChatMessages for the given sessionId.
   */
  getSessionMessagesValue(sessionId: string): ChatTurn[] {
    if (!this.sessionMessagesMap$.has(sessionId)) {
      this.sessionMessagesMap$.set(sessionId, new BehaviorSubject<ChatTurn[]>([]));
    }
    return this.sessionMessagesMap$.get(sessionId)!.value;
  }

  /**
   * Updates the messages array for a given sessionId and notifies all subscribers.
   */
  setSessionMessages(sessionId: string, messages: ChatTurn[]): void {
    if (!this.sessionMessagesMap$.has(sessionId)) {
      this.sessionMessagesMap$.set(sessionId, new BehaviorSubject<ChatTurn[]>(messages));
    } else {
      this.sessionMessagesMap$.get(sessionId)!.next(messages);
    }
  }

    /**
   * Updates the messages array for a given sessionId and notifies all subscribers.
   */
    setChatSession(sessionId: string, chatSession: ChatSession): void {
      if (!this.chatSessionsMap$.has(sessionId)) {
        this.chatSessionsMap$.set(sessionId, new BehaviorSubject<ChatSession>(chatSession));
      } else {
        this.chatSessionsMap$.get(sessionId)!.next(chatSession);
      }
    }

  /**
   * Clears a session's messages from the map (optional usage).
   */
  clearSessionMessages(sessionId: string): void {
    if (this.sessionMessagesMap$.has(sessionId)) {
      this.sessionMessagesMap$.delete(sessionId);
    }
  }

  /**
   * Safely retrieves a value from a map or returns null if the key doesn't exist.
   * @param map The map to retrieve the value from
   * @param key The key to look up
   * @returns The value from the map or null if not found
   */
  private tryGet<K, V>(map: Map<K, V>, key: K): V | null {
    return map.has(key) ? map.get(key)! : null;
  }

  /**
   * Safely gets session messages observable or null if not found
   */
  tryGetSessionMessages(sessionId: string): BehaviorSubject<ChatTurn[]> | null {
    return this.tryGet(this.sessionMessagesMap$, sessionId);
  }

  /**
   * Safely gets chat session observable or null if not found
   */
  tryGetChatSession(sessionId: string): BehaviorSubject<ChatSession> | null {
    return this.tryGet(this.chatSessionsMap$, sessionId);
  }

  /**
   * Safely gets project manager message observable or null if not found
   */
  tryGetProjectManagerMessage(sessionId: string): BehaviorSubject<ProjectManagerSessionMessage> | null {
    return this.tryGet(this.projectManagerMessagesMap$, sessionId);
  }

  /**
   * Returns the ProjectManagerSessionMessage observable for a given sessionId.
   * Creates a new BehaviorSubject if none exists.
   */
  getProjectManagerMessageObservable(sessionId: string): BehaviorSubject<ProjectManagerSessionMessage> {
    if (!this.projectManagerMessagesMap$.has(sessionId)) {
      const emptyMessage: ProjectManagerSessionMessage = {
        sessionName: '',
        sessionDescription: '',
        suggestedWorkboardNewItems: { groups: [] },
        suggestedExistingSessions: [],
        suggestedNewSessions: [],
        messageToUser: ''
      };
      this.projectManagerMessagesMap$.set(sessionId, new BehaviorSubject<ProjectManagerSessionMessage>(emptyMessage));
    }
    return this.projectManagerMessagesMap$.get(sessionId)!;
  }

  /**
   * Updates or merges a ProjectManagerSessionMessage for a given sessionId.
   * Merges new workstreams into existing groups if they exist.
   */
  updateProjectManagerMessage(sessionId: string, newMessage: ProjectManagerSessionMessage): void {
    const currentMessage = this.getProjectManagerMessageObservable(sessionId).value;
    const mergedMessage = this.mergeProjectManagerMessages(currentMessage, newMessage);
    this.projectManagerMessagesMap$.get(sessionId)?.next(mergedMessage);
    console.log('Updated ProjectManagerSessionMessage:', mergedMessage);
  }


/**
 * Merges two ProjectManagerSessionMessages, focusing on unique workstreams and sessions.
 */
private mergeProjectManagerMessages(
  current: ProjectManagerSessionMessage,
  next: ProjectManagerSessionMessage
): ProjectManagerSessionMessage {

  var mergedGroups = current.suggestedWorkboardNewItems.groups;

  if (next && next.suggestedWorkboardNewItems && next.suggestedWorkboardNewItems.groups) {
     mergedGroups = this.mergeWorkboardGroups(
      current.suggestedWorkboardNewItems.groups,
      next.suggestedWorkboardNewItems.groups
    );
  }

  // Only keep unique new sessions by ID
  var uniqueNewSessions = [...current.suggestedNewSessions];
  if (next && next.suggestedNewSessions)
  {
    next.suggestedNewSessions.forEach(newSession => {
      if (!uniqueNewSessions.some(existing => existing.id === newSession.id)) {
        uniqueNewSessions.push(newSession);
      }
    });
  }

  return {
    ...next,
    suggestedWorkboardNewItems: { groups: mergedGroups },
    suggestedNewSessions: uniqueNewSessions,
    suggestedExistingSessions: next.suggestedExistingSessions
  };
}

/**
 * Merges workboard groups ensuring unique workstreams by ID within each group and column.
 */
private mergeWorkboardGroups(
  currentGroups: ProjectManagerWorkboardGroup[],
  newGroups: ProjectManagerWorkboardGroup[]
): ProjectManagerWorkboardGroup[] {
  const mergedGroups = [...currentGroups];

  if (newGroups && newGroups.length > 0)
  {
    newGroups.forEach(newGroup => {
      const existingGroup = mergedGroups.find(group => group.id === newGroup.id);

      if (!existingGroup) {
        mergedGroups.push(newGroup);
      } else {
        newGroup.workstreamCols.forEach(newCol => {
          const existingCol = existingGroup.workstreamCols.find(col => col.id === newCol.id);
          
          if (!existingCol) {
            existingGroup.workstreamCols.push(newCol);
          } else {
            // Only add unique workstreams by ID
            newCol.workstreams.forEach(newWorkstream => {
              if (!existingCol.workstreams.some(existing => existing.id === newWorkstream.id)) {
                existingCol.workstreams.push(newWorkstream);
              }
            });
          }
        });
      }
    });
  }

  return mergedGroups;
}


  /**
   * Checks a condition in the format:
   *  - "hasXxx" means there should be more than 0 of type "Xxx"
   *  - "XxxOverN" means there should be more than N of type "Xxx"
   * For example: "hasTables", "TablesOver2", "hasWorkstreams", "WorkstreamsOver1", "SessionsOver0".
   */
  checkCondition(condition: string): boolean {
    const hasPrefix = condition.toLowerCase().startsWith('has');
    if (hasPrefix) {
      const typeName = condition.substring(3);
      const count = this.getItemCount(typeName);
      return count > 0;
    }

    const match = condition.match(/^([A-Za-z]+)Over(\d+)$/);
    if (match) {
      const typeName = match[1];
      const threshold = parseInt(match[2], 10);
      const count = this.getItemCount(typeName);
      return count > threshold;
    }

    return false;
  }

  /**
 * Returns the count of items based on typeName.
 */
private getItemCount(typeName: string): number {
  const normalizedName = typeName.trim().toLowerCase();
  switch (normalizedName) {
    case 'tables':
      return this.getTablesCount();
    case 'workstreams':
      return this.getWorkstreamsCount();
    case 'sessions':
      return this.getSessionsCount();
    case 'projectmanagermessages':
      return this.projectManagerMessagesMap$.size;
    case 'sessionsuggesedworkstreams':
      return this.getSuggestedWorkstreamsCount();
    case 'sessionsuggesedsessions':
      return this.getSuggestedSessionsCount();
    default:
      return 0;
  }
}

/**
 * Counts total suggested workstreams across all groups and columns
 * for the currently selected session.
 */
private getSuggestedWorkstreamsCount(): number {
  const selectedSessionId = this.sessionsGridState$.value.selectedSessionId;
  if (!selectedSessionId || !this.projectManagerMessagesMap$.has(selectedSessionId)) {
    return 0;
  }

  const message = this.projectManagerMessagesMap$.get(selectedSessionId)!.value;
  let total = 0;

  message.suggestedWorkboardNewItems.groups.forEach(group => {
    group.workstreamCols.forEach(col => {
      total += col.workstreams.length;
    });
  });

  return total;
}

/**
 * Counts total suggested new sessions for the currently selected session.
 */
  private getSuggestedSessionsCount(): number {
    const selectedSessionId = this.sessionsGridState$.value.selectedSessionId;
    if (!selectedSessionId || !this.projectManagerMessagesMap$.has(selectedSessionId)) {
      return 0;
    }

    const message = this.projectManagerMessagesMap$.get(selectedSessionId)!.value;
    return message.suggestedNewSessions.length;
  }

  /**
   * Counts the tables for the current workspace (if any).
   */
  private getTablesCount(): number {
    return 0;
  }

  /**
   * Counts all workstreams across all groups in the workboard.
   */
  private getWorkstreamsCount(): number {
    const workboard = this.workboardState$.value;
    let total = 0;
    workboard.groups.forEach(group => {
      group.workstreamCols.forEach(col => {
        total += col.workstreams.length;
      });
    });
    return total;
  }

  /**
   * Counts the number of sessions.
   */
  private getSessionsCount(): number {
    return this.sessions$.value.length;
  }

  // Add new projects map
  projectsMap$ = new Map<string, BehaviorSubject<ProjectData>>();

  // Add helper methods for managing projects map
  getProjectObservable(projectId: string): BehaviorSubject<ProjectData> | null {
    return this.tryGet(this.projectsMap$, projectId);
  }

  setProject(projectId: string, projectData: ProjectData): void {
    if (!this.projectsMap$.has(projectId)) {
      this.projectsMap$.set(projectId, new BehaviorSubject<ProjectData>(projectData));
    } else {
      this.projectsMap$.get(projectId)!.next(projectData);
    }
  }

  removeProject(projectId: string): void {
    if (this.projectsMap$.has(projectId)) {
      this.projectsMap$.delete(projectId);
    }
  }


/**
 * Merges workstream changes with an existing workstream by ID
 * 
 * @param workstreamChanges The workstream changes to merge
 * @returns True if a workstream was found and updated, false otherwise
 */
async mergeWorkstream(workstreamChanges: Partial<Workstream>): Promise<boolean> {
  if (!workstreamChanges.id) {
    console.error('Cannot merge workstream without an ID');
    return false;
  }

  let found = false;
  const workstreamId = workstreamChanges.id;
  const workboard = this.workboardState$.value;
  
  // Find the workstream in the workboard
  for (const group of workboard.groups) {
    for (const col of group.workstreamCols) {
      const existingWorkstreamIndex = col.workstreams.findIndex(ws => ws.id === workstreamId);
      
      if (existingWorkstreamIndex >= 0) {
        const existingWorkstream = col.workstreams[existingWorkstreamIndex];
        
        // Check if any field has changed by comparing values
        const hasChanges = this.detectWorkstreamChanges(existingWorkstream, workstreamChanges);
        
        // Only proceed with version handling if there are actual changes
        if (hasChanges) {
          // Handle versioning
          this.handleWorkstreamVersioning(existingWorkstream);
          
          // Merge changes into the existing workstream
          this.mergeWorkstreamProperties(existingWorkstream, workstreamChanges);
          
          // Handle units recursively if they exist
          if (workstreamChanges.units && workstreamChanges.units.length > 0) {
            // CRITICAL CHANGE: Don't initialize units array right now
            // Instead of existingWorkstream.units = [], keep the existing array and work with it
            // Or create a new array only if it doesn't exist
            
            if (!existingWorkstream.units) {
              // Defer the creation of the units array until we start adding elements
              // This should prevent the browser from crashing
              existingWorkstream.units = new Array();
              // Using "new Array()" instead of "[]" for extra safety
            }
            
            // Process each unit and add/update in the existing array
            for (const unitChange of workstreamChanges.units) {
              if (!unitChange.id) continue;
              
              // Try to find the unit in the existing array
              let unitIndex = -1;
              if (existingWorkstream.units) {
                for (let i = 0; i < existingWorkstream.units.length; i++) {
                  if (existingWorkstream.units[i].id === unitChange.id) {
                    unitIndex = i;
                    break;
                  }
                }
              }
              
              if (unitIndex >= 0) {
                // Unit exists, update it
                const existingUnit = existingWorkstream.units[unitIndex];
                const hasUnitChanges = this.detectWorkstreamChanges(existingUnit, unitChange);
                
                if (hasUnitChanges) {
                  this.handleWorkstreamVersioning(existingUnit);
                  this.mergeWorkstreamProperties(existingUnit, unitChange);
                }
              } else {
                // Unit doesn't exist, add it
                existingWorkstream.units.push(unitChange as Workstream);
              }
            }
          }
          
          // Update the workboard state
          const updatedWorkboard = JSON.parse(JSON.stringify(workboard));
          this.workboardState$.next(updatedWorkboard);
          found = true;
          break;
        }
      }
    }
    
    if (found) break;
  }
  
  return found;
}

/**
 * Detects if there are changes between an existing workstream and incoming changes
 * Special handling for arrays and nested objects
 * 
 * @param existingWorkstream The existing workstream
 * @param workstreamChanges The incoming changes
 * @returns True if changes are detected, false otherwise
 */
private detectWorkstreamChanges(existingWorkstream: Workstream, workstreamChanges: Partial<Workstream>): boolean {
  let hasChanges = false;
  
  // Skip checking these fields for change detection
  const skipFields = ['id', 'lastCommitedVersions'];
  
  // Check each property in the changes
  Object.keys(workstreamChanges).forEach(key => {
    // Skip fields that should not trigger version changes
    if (skipFields.includes(key)) {
      return;
    }
    
    const typedKey = key as keyof Workstream;
    const existingValue = existingWorkstream[typedKey];
    const newValue = workstreamChanges[typedKey];
    
    // Special handling for arrays (input_table_ids and output_table_ids)
    if (Array.isArray(newValue)) {
      if (key === 'input_table_ids' || key === 'output_table_ids') {
        // For these arrays, check if there are new items not in the existing array
        if (!existingValue || !Array.isArray(existingValue)) {
          // If existing value is not an array but new value is, that's a change
          hasChanges = true;
        } else {
          // Check for new items in the array
          const existingArray = existingValue as any[];
          const newArray = newValue as any[];
          
          // For input_table_ids (string array), check for new IDs
          if (key === 'input_table_ids') {
            for (const item of newArray) {
              if (!existingArray.includes(item)) {
                hasChanges = true;
                break;
              }
            }
          } 
          // For output_table_ids (object array), check by name property
          else if (key === 'output_table_ids') {
            for (const newItem of newArray) {
              if (!existingArray.some((existingItem: any) => 
                existingItem.name === newItem.name)) {
                hasChanges = true;
                break;
              }
            }
          }
        }
      } 
      // For other arrays, use JSON comparison
      else {
        if (JSON.stringify(existingValue) !== JSON.stringify(newValue)) {
          hasChanges = true;
        }
      }
    } 
    // Special handling for config object
    else if (key === 'config' && newValue) {
      if (!existingValue) {
        hasChanges = true;
      } else {
        // Compare each property in config
        Object.keys(newValue).forEach(configKey => {
          const typedConfigKey = configKey as keyof WorkstreamConfig;
          if ((existingValue as WorkstreamConfig)[typedConfigKey] !== 
              (newValue as WorkstreamConfig)[typedConfigKey]) {
            hasChanges = true;
          }
        });
      }
    }
    // For other properties, use JSON comparison
    else if (JSON.stringify(existingValue) !== JSON.stringify(newValue)) {
      hasChanges = true;
    }
  });
  
  return hasChanges;
}

/**
 * Handles versioning for a workstream
 * Creates a deep copy of the current state and updates version
 * - Creates a new major version for changes when the major version matches previous version
 * - Increments minor version for updates when the major version differs from previous version
 * 
 * @param workstream The workstream to version
 */
private handleWorkstreamVersioning(workstream: Workstream): void {
  // Initialize lastCommitedVersions array if it doesn't exist
  if (!workstream.lastCommitedVersions) {
    workstream.lastCommitedVersions = [];
  }
  
  // Create a temporary copy to avoid nested version arrays
  const workstreamCopy = JSON.parse(JSON.stringify(workstream));
  
  // Clear the nested lastCommitedVersions to avoid duplication
  workstreamCopy.lastCommitedVersions = [];
  
  // Initial version case - first time being versioned
  if (!workstream.version) {
    // Set the copy's version to 0.0 (initial version)
    workstreamCopy.version = "0.0";
    
    // Add to version history
    workstream.lastCommitedVersions.push(workstreamCopy);
    
    // Set the current version to 1.0
    workstream.version = "1.0";
    return;
  }
  
  // Get the previous version (if it exists)
  const prevVersion = workstream.lastCommitedVersions.length > 0 
    ? workstream.lastCommitedVersions[workstream.lastCommitedVersions.length - 1].version 
    : null;
  
  // Parse current version
  const versionParts = workstream.version.split('.');
  let majorVersion = parseInt(versionParts[0], 10);
  let minorVersion = parseInt(versionParts[1], 10);
  
  if (!prevVersion) {
    // Should not happen if the logic is correct, but handle it just in case
    workstreamCopy.version = "0.0";
    workstream.lastCommitedVersions.push(workstreamCopy);
    workstream.version = "1.0";
    return;
  }
  
  // Parse previous version
  const prevVersionParts = prevVersion.split('.');
  const prevMajor = parseInt(prevVersionParts[0], 10);
  
  // Set the copy's version to the current version before we change it
  workstreamCopy.version = workstream.version;
  
  // Add the copy to version history
  workstream.lastCommitedVersions.push(workstreamCopy);
  
  // Update the version according to the rules
  if (prevVersion == workstream.version) {
    majorVersion += 1;
    minorVersion = 0;
  } else {
    minorVersion += 1;
  }
  
  // Set the new version
  workstream.version = `${majorVersion}.${minorVersion}`;
}

/**
 * Merges properties from changes into the existing workstream
 * Special handling for arrays and objects to ensure proper merging
 * 
 * @param existingWorkstream The existing workstream to update
 * @param workstreamChanges The changes to apply
 */
private mergeWorkstreamProperties(existingWorkstream: Workstream, workstreamChanges: Partial<Workstream>): void {
  // Process each property in the changes
  Object.keys(workstreamChanges).forEach(key => {
    if (key === 'id' || key === 'lastCommitedVersions' || key === 'units') {
      // Skip these fields as they require special handling
      return;
    }
    
    const typedKey = key as keyof Workstream;
    const newValue = workstreamChanges[typedKey];
    
    // Special handling for arrays
    if (Array.isArray(newValue)) {
      if (key === 'input_table_ids') {
        // Initialize if needed
        if (!existingWorkstream.input_table_ids) {
          existingWorkstream.input_table_ids = [];
        }
        
        // Add new IDs without duplicates
        for (const tableId of newValue) {
          if (!existingWorkstream.input_table_ids.includes(tableId as string)) {
            existingWorkstream.input_table_ids.push(tableId as string);
          }
        }
      } 
      else if (key === 'output_table_ids') {
        // Initialize if needed
        if (!existingWorkstream.output_table_ids) {
          existingWorkstream.output_table_ids = [];
        }
        
        // Add new items without duplicates (by name)
        for (const newTable of newValue as TableExportConfig[]) {
          if (!existingWorkstream.output_table_ids.some(t => t.name === newTable.name)) {
            existingWorkstream.output_table_ids.push(newTable);
          }
        }
      } 
      else {
        // For other arrays, replace the entire array
        (existingWorkstream[typedKey] as any) = newValue;
      }
    } 
    // Special handling for config object
    else if (key === 'config' && newValue) {
      if (!existingWorkstream.config) {
        existingWorkstream.config = {} as WorkstreamConfig;
      }
      
      // Merge config properties
      Object.assign(existingWorkstream.config, newValue);
    } 
    // For all other properties, simply assign the new value
    else {
      (existingWorkstream[typedKey] as any) = newValue;
    }
  });
}
}