import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FileInfo } from '../../../models/fileinfo.model';
import { AudioRecordingService } from '../../../services/audio-recording.service';
import { TranscriptionService } from '../../../services/transcription.service';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatIconModule]
})
export class InputBoxComponent implements AfterViewInit {
  @Input() isProcessing: boolean = false;
  @Output() messageSent = new EventEmitter<{message: string, fileInfo?: FileInfo}>();
  @ViewChild('textArea') textArea!: ElementRef<HTMLTextAreaElement>;

  message: string = '';
  private composing: boolean = false;
  attachedFile: FileInfo | null = null;
  
  // Audio recording properties
  isRecording: boolean = false;
  recordingDuration: number = 0;
  isTranscribing: boolean = false;
  transcriptionError: string | null = null;
  private recordingSubscription?: Subscription;

  constructor(
    private audioRecordingService: AudioRecordingService,
    private transcriptionService: TranscriptionService
  ) {}

  ngAfterViewInit() {
    this.adjustTextarea();
    this.subscribeToRecordingState();
  }
  
  ngOnDestroy() {
    if (this.recordingSubscription) {
      this.recordingSubscription.unsubscribe();
    }
  }
  
  private subscribeToRecordingState() {
    this.recordingSubscription = this.audioRecordingService.recordingState$.subscribe(state => {
      this.isRecording = state.isRecording;
      this.recordingDuration = state.duration;
      if (state.error) {
        this.transcriptionError = state.error;
      }
    });
  }

  onSubmit(): void {
    if ((this.message.trim() || this.attachedFile) && !this.isProcessing) {
      this.messageSent.emit({
        message: this.message,
        fileInfo: this.attachedFile || undefined
      });
      this.message = '';
      this.attachedFile = null;
      // Reset textarea height to minimum
      if (this.textArea) {
        this.textArea.nativeElement.style.height = '34px';
      }
    }
  }

  onAttachFile(): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv,.tsv,.txt,.text,.xlsx,.xls';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        // Check if file is one of the allowed types
        const validExtensions = ['.csv', '.tsv', '.txt', '.text', '.xlsx', '.xls'];
        const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
        
        if (validExtensions.includes(fileExtension)) {
          // If it's an Excel file, convert it to TSV first
          if (fileExtension === '.xlsx' || fileExtension === '.xls') {
            this.convertExcelToTsv(file);
          } else {
            // Handle as before for non-Excel files
            this.attachedFile = {
              file: file,
              name: file.name,
              type: file.type,
              size: file.size
            };
          }
        } else {
          console.error('Invalid file type. Only CSV, TSV, TXT, and Excel files are allowed.');
          // Could add user-facing error message here
        }
      }
    };
    input.click();
  }

  // New method to convert Excel to TSV
  private convertExcelToTsv(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        
        // Get the first sheet
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        
        // Convert to TSV string - using FS option for delimiter
        const tsvData = XLSX.utils.sheet_to_csv(worksheet, { FS: '\t' });
        
        // Create a new File object with the TSV data
        const tsvBlob = new Blob([tsvData], { type: 'text/tab-separated-values' });
        const tsvFile = new File([tsvBlob], file.name.replace(/\.xlsx?$/, '.tsv'), {
          type: 'text/tab-separated-values'
        });
        
        // Use the TSV file as the attached file
        this.attachedFile = {
          file: tsvFile,
          name: tsvFile.name,
          type: tsvFile.type,
          size: tsvFile.size
        };
      } catch (error) {
        console.error('Error converting Excel to TSV:', error);
        // Could add user-facing error message here
      }
    };
    reader.readAsArrayBuffer(file);
  }

  removeAttachedFile(): void {
    this.attachedFile = null;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Process') {
      this.composing = true;
      return;
    }

    if (event.key === 'Enter' && !event.shiftKey && !this.composing) {
      event.preventDefault();
      this.onSubmit();
    }

    if (this.composing && event.key === 'Enter') {
      this.composing = false;
    }
  }

  adjustTextarea(): void {
    const textarea = this.textArea?.nativeElement;
    if (!textarea) return;
    
    // Reset to minimum height
    textarea.style.height = '34px';
    // Calculate required height
    const newHeight = Math.min(textarea.scrollHeight, 355);
    textarea.style.height = `${newHeight}px`;
  }

  /**
   * NEW METHODS to allow ChatComponent to set or append lines
   */
  setMessage(msg: string): void {
    this.message = msg;
    this.adjustTextarea();
  }

  appendMessageLine(msg: string): void {
    if (this.message.trim() !== '') {
      this.message += '\n';
    }
    this.message += msg;
    this.adjustTextarea();
  }
  
  /**
   * Handle button click for send/record/stop functionality
   * This addresses the issue with the stop button not responding properly
   */
  handleButtonClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    
    console.log('Button clicked, recording state:', this.isRecording);
    
    if (this.isRecording) {
      console.log('Explicitly stopping recording');
      this.stopRecordingAndTranscribe();
    } else if (this.shouldShowAudioIcon()) {
      console.log('Starting recording');
      this.startRecording();
    } else {
      console.log('Submitting message');
      this.onSubmit();
    }
  }

  /**
   * NEW METHODS for audio recording and transcription
   */
  async toggleRecording(): Promise<void> {
    try {
      // Clear any previous errors
      this.transcriptionError = null;
      
      console.log('Toggle recording - current state:', this.isRecording);
      
      if (this.isRecording) {
        console.log('Attempting to stop recording...');
        await this.stopRecordingAndTranscribe();
      } else {
        console.log('Attempting to start recording...');
        await this.startRecording();
      }
    } catch (error) {
      console.error('Error in toggleRecording:', error);
      this.transcriptionError = 'Error toggling recording. Please try again.';
    }
  }
  
  private async startRecording(): Promise<void> {
    try {
      await this.audioRecordingService.startRecording();
      console.log('Recording started successfully');
    } catch (error) {
      console.error('Failed to start recording:', error);
      this.transcriptionError = 'Could not start recording. Please ensure microphone permissions are granted.';
    }
  }
  
  public async stopRecordingAndTranscribe(): Promise<void> {
    try {
      console.log('Stopping recording...');
      
      // First update UI state to provide immediate feedback
      this.isRecording = false;
      this.isTranscribing = true;
      
      // Then stop the actual recording
      const audioBlob = await this.audioRecordingService.stopRecording();
      console.log('Recording stopped, blob size:', audioBlob.size);
      
      if (audioBlob.size === 0) {
        console.error('Audio blob is empty');
        this.isTranscribing = false;
        this.transcriptionError = 'No audio recorded. Please try again and speak clearly.';
        return;
      }
      
      // Send audio for transcription
      console.log('Sending for transcription...');
      const transcriptionResult = await this.transcriptionService.transcribeAudio(audioBlob);
      console.log('Transcription result:', transcriptionResult);
      
      // Set the transcribed text in the input box
      this.setMessage(transcriptionResult.text);
      
      // Clear transcribing state
      this.isTranscribing = false;
      
    } catch (error) {
      console.error('Error stopping recording or transcribing:', error);
      this.isTranscribing = false;
      this.isRecording = false; // Ensure recording state is reset
      this.transcriptionError = 'Failed to transcribe audio. Please try again or type your message.';
    }
  }
  
  cancelRecording(): void {
    this.audioRecordingService.cancelRecording();
    this.transcriptionError = null;
  }
  
  formatRecordingTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
  
  shouldShowAudioIcon(): boolean {
    return !this.message.trim() && !this.isRecording && !this.isTranscribing;
  }
}