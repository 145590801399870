import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class JsonParserService {
  
  /**
   * Parses JSON strings that may contain line breaks within string values
   * 
   * @param jsonString - The JSON string to parse
   * @returns The parsed JavaScript object
   * @throws Error if parsing fails
   */
  public parseJsonWithLineBreaks(jsonString: string): any {
    // Skip processing if the input is not a string
    if (typeof jsonString !== 'string') {
      return jsonString;
    }
    
    // Step 1: Replace line breaks in string values with a temporary placeholder
    const TEMP_LINEBREAK = "__LINEBREAK_PLACEHOLDER__";
    let processedJson = '';
    let inString = false;
    let escaped = false;

    for (let i = 0; i < jsonString.length; i++) {
      const char = jsonString[i];

      if (escaped) {
        processedJson += char;
        escaped = false;
      } else if (char === '\\') {
        processedJson += char;
        escaped = true;
      } else if (char === '"') {
        processedJson += char;
        inString = !inString;
      } else if (char === '\n') {
        if (inString) {
          // Replace line breaks inside strings with placeholder
          processedJson += TEMP_LINEBREAK;
        } else {
          // Keep line breaks outside strings for better error reporting
          processedJson += char;
        }
      } else {
        processedJson += char;
      }
    }

    // Step 2: Parse the JSON
    try {
      const parsedObj = JSON.parse(processedJson);

      // Step 3: Replace placeholders with real line breaks in the parsed object
      const restoredObj = this.restoreLineBreaks(parsedObj, TEMP_LINEBREAK);
      return restoredObj;
    } catch (error) {
      console.error('Failed to parse JSON:', error);
      // Get the first 200 characters of the JSON for better error reporting
      const preview = jsonString.substring(0, 200);
      throw new Error(`JSON parse error: ${error}\nJSON preview: ${preview}`);
    }
  }

  /**
   * Recursively restores line breaks in a parsed object
   * 
   * @param obj - The object to process
   * @param placeholder - The placeholder string to replace with line breaks
   * @returns The processed object with restored line breaks
   */
  private restoreLineBreaks(obj: any, placeholder: string): any {
    if (typeof obj === 'string') {
      return obj.replace(new RegExp(placeholder, 'g'), '\n');
    } else if (Array.isArray(obj)) {
      return obj.map(item => this.restoreLineBreaks(item, placeholder));
    } else if (obj !== null && typeof obj === 'object') {
      const result: Record<string, any> = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          result[key] = this.restoreLineBreaks(obj[key], placeholder);
        }
      }
      return result;
    }
    return obj;
  }

  /**
   * Safely attempts to parse a string as JSON
   * 
   * @param value - The string to parse
   * @returns The parsed object or the original value if parsing fails
   */
  public safeParseJson(value: any): any {
    if (typeof value !== 'string') {
      return value;
    }
    
    // Don't trim the value - might be removing important whitespace
    const jsonString = value;
    
    // Direct approach - try standard JSON.parse first
    try {
      return JSON.parse(jsonString);
    } catch (directError) {
      // If direct parsing fails, continue with more complex approaches
    }
    
    // Only attempt advanced parsing if the string looks like JSON
    if ((jsonString.trim().startsWith('{') && jsonString.trim().endsWith('}')) || 
        (jsonString.trim().startsWith('[') && jsonString.trim().endsWith(']'))) {
      try {
        // Handle Python-style arrays with single quotes
        let processedValue = jsonString;
        
        // Check if it looks like a Python list (starts with [ and contains ')
        if (jsonString.trim().startsWith('[') && jsonString.includes("'")) {
          // Convert Python-style single quotes to double quotes
          // This regex handles single-quoted strings including escaped single quotes
          processedValue = jsonString.replace(/'((?:[^'\\]|\\.)*)'/g, function(match, p1) {
            // Replace any double quotes in the content with escaped double quotes
            const sanitized = p1.replace(/"/g, '\\"');
            return `"${sanitized}"`;
          });
        }
        
        return this.parseJsonWithLineBreaks(processedValue);
      } catch (e) {
        console.warn('Failed to parse JSON string:', e);
        // If parsing fails, try to salvage arrays by doing a simpler replacement
        if (jsonString.trim().startsWith('[') && jsonString.includes("'")) {
          try {
            // Simple global replacement of single quotes with double quotes
            // This is a fallback and might not work for all cases but better than nothing
            const simpleReplacement = jsonString.replace(/'/g, '"');
            return JSON.parse(simpleReplacement);
          } catch (e2) {
            console.warn('Failed to parse with fallback method:', e2);
            // If all else fails, treat as a comma-separated list of values
            if (jsonString.trim().startsWith('[') && jsonString.trim().endsWith(']')) {
              try {
                const content = jsonString.substring(1, jsonString.length - 1).trim();
                if (content) {
                  return content.split(',').map(item => item.trim().replace(/^['"](.*)['"]$/, '$1'));
                }
                return [];
              } catch (e3) {
                console.warn('All parsing attempts failed, returning original value');
                return value;
              }
            }
          }
        }
        return value;
      }
    }
    
    return value;
  }

  /**
   * Converts a JavaScript object to a formatted JSON string
   * 
   * @param obj - The object to stringify
   * @param pretty - Whether to format the JSON with indentation (default: false)
   * @returns The JSON string representation of the object
   */
  public stringifyJson(obj: any, pretty: boolean = false): string {
    try {
      return JSON.stringify(obj, null, pretty ? 2 : 0);
    } catch (e) {
      console.error('Error stringifying object:', e);
      return '';
    }
  }
}