import { Injectable } from "@angular/core";
import { v4 as uuidv4 } from "uuid";
import { ChatTurn, MessageRole, ChatSession, ChatContext } from "../models/chat.model";
import { DTeamChatResponse } from "../models/dteam-chat.model";
import { ProjectManagerSessionMessage } from "../models/project-manager-chat.model";
import { StateOperationResult } from "../models/state-operation-result.model";
import { TablesCollection } from "../models/tables.model";
import { WorkboardState, Workstream } from "../models/workboard.model";
import { WorkspaceState } from "../models/workspace-state.model";
import { BedrockService } from "./bedrock.service";
import { PromptLayerService } from "./prompt-layer.service";
import { RiffMLParserService, RiffMLNode } from "./riffml-parser.service";
import { SessionsManagerService } from "./sessions-manager.service";
import { StateManagerService } from "./state-manager.service";
import { TablesManagerService } from "./tables-manager.service";
import { ToolsService } from "./tools.service";
import { WorkboardManagerService } from "./workboard-manager.service";
import { WorkspaceManagerService } from "./workspace-manager.service";
import { JsonParserService } from "./jsonParserService.service";
import { MemoryManagerService } from "./memory-manager.service";
import { MemoryItem } from "../models/memory-item.model";
import { ProcessTurnInterface } from "../models/process-turn.interface";

@Injectable({
  providedIn: 'root'
})
export class ChatMessageProcessorService {
  constructor(
    private promptLayerService: PromptLayerService,
    private bedrockService: BedrockService,
    private sharedState: WorkspaceState,
    private tablesManagerService: TablesManagerService,
    private workboardManagerService: WorkboardManagerService,
    private sessionsManagerService: SessionsManagerService,
    private workspaceState: WorkspaceState,
    private stateManagerService: StateManagerService,

    /* Inject the RiffML parser service */
    private riffmlParserService: RiffMLParserService,

    /* Inject the WorkspaceManagerService to save if dirty */
    private workspaceManagerService: WorkspaceManagerService,

    /* Inject the ToolsService to call tools */
    private toolsService: ToolsService,

    private jsonParser: JsonParserService,
    
    /* Inject the MemoryManagerService to handle memory operations */
    private memoryManagerService: MemoryManagerService
  ) { }

  private processTurnHandler: ProcessTurnInterface | null = null;

  /**
   * Sets the ProcessTurnInterface implementation that tools will use
   * @param handler An implementation of the ProcessTurnInterface
   */
  public setProcessTurnHandler(handler: ProcessTurnInterface): void {
    this.processTurnHandler = handler;
  }
  
  /**
   * Creates a new user message turn for the chat session
   */
  createUserMessageTurn(
    sessionId: string,
    content: string,
    userId: string,
    currentTurnCount: number
  ): ChatTurn {
    return {
      id: uuidv4(),
      sessionId,
      content,
      role: MessageRole.User,
      senderId: userId,
      timestamp: new Date(),
      artifacts: [],
      turnMetadata: {
        turnNumber: currentTurnCount + 1,
        processingTime: 0
      }
    };
  }

  /**
   * Creates a process message turn to indicate system processing
   */
  createProcessMessageTurn(
    sessionId: string,
    content: string,
    currentTurnCount: number,
    metadata?: Record<string, unknown>
  ): ChatTurn {
    return {
      id: uuidv4(),
      sessionId,
      content,
      role: MessageRole.Process,
      senderId: 'system',
      timestamp: new Date(),
      artifacts: [],
      turnMetadata: {
        turnNumber: currentTurnCount + 1,
        processingTime: 0,
        turnData: metadata
      }
    };
  }


  public static findWorkstreamById(workboard: WorkboardState, workstreamId: string): Workstream | undefined {
    for (const group of workboard.groups) {
      for (const col of group.workstreamCols) {
        const workstream = col.workstreams.find(ws => ws.id === workstreamId);
        if (workstream) {
          return workstream;
        }
      }
    }
    return undefined;
  }

  private formatWorkstreamInfo(workstream: Workstream): string {
    return `
  Workstream ${workstream.id}
  name: ${workstream.name}
  description: ${workstream.description}
  input tables: ${workstream.input_table_ids?.join(', ') || 'none'}
  expected outcome: ${workstream.outcome || 'not specified'}\n`;
  }

  /**
   * Generates a formatted prompt string for suggested workstreams
   */
  private generateWorkstreamsPrompt(data: ProjectManagerSessionMessage): string {
    const workstreams: string[] = [];
    workstreams.push('Suggested Workboard Items:\n');

    data.suggestedWorkboardNewItems?.groups?.forEach(group => {
      group.workstreamCols?.forEach(col => {
        col.workstreams?.forEach(ws => {
          workstreams.push(`suggested workstream: ${group.id} -> ${col.id} -> ${ws.id}`);
          workstreams.push(`name: ${ws.name}`);
          workstreams.push(`workstreamType: ${ws.workstreamType}`);
          workstreams.push(`description: ${ws.description}`);
          if (ws.input_table_ids) workstreams.push(`input tables: ${ws.input_table_ids.join(', ')}`);
          if (ws.output_table_ids) workstreams.push(`output tables: ${ws.output_table_ids.join(', ')}`);
          if (ws.outcome) workstreams.push(`outcome: ${ws.outcome}`);
          if (ws.whyRelevant) workstreams.push(`whyRelevant: ${ws.whyRelevant}`);
          workstreams.push('');
        });
      });
    });

    return workstreams.join('\n').trim();
  }

  /**
   * Generates a formatted prompt string for suggested sessions
   */
  private generateSessionsPrompt(data: ProjectManagerSessionMessage): string {
    const sessions: string[] = [];

    data.suggestedNewSessions?.forEach(session => {
      sessions.push(`suggested session id: ${session.id}`);
      sessions.push(`name: ${session.name}`);
      sessions.push(`description: ${session.description}`);

      if (session.sessionPlaybook) {
        sessions.push('sessionPlaybook: {');
        sessions.push(` id: ${session.sessionPlaybook.id}`);
        sessions.push(` name: ${session.sessionPlaybook.name}`);
        sessions.push('}');
      }

      if (session.relatedWorkstreamIds?.length) {
        sessions.push(`relatedWorkstreamIds: ${session.relatedWorkstreamIds.join(', ')}`);
      }

      if (session.goal) sessions.push(`goal: ${session.goal}`);
      if (session.whyRelevant) sessions.push(`whyRelevant: ${session.whyRelevant}`);
      sessions.push('');
    });

    return sessions.join('\n').trim();
  }

  /**
   * New method replacing buildPrompt:
   * Parses the RiffML template and returns:
   *   1) The final prompt string
   *   2) A list of action nodes (wait, next, end, message, processingmessage)
   */
  public async processRiffMl(
    rawTemplate: string,
    content: string,
    sessionId: string
  ): Promise<{ prompt: string; actionNodes: RiffMLNode[]; }> {
    // Parse RiffML
    const riffDom = this.riffmlParserService.parseRiffML(rawTemplate);

    // We track dirty objects to only write them once after processing
    const dirtySessions = new Set<string>();
    const dirtyProjects = new Set<string>();
    const dirtyWorkspaces = new Set<string>();

    // Process the RiffML DOM to produce the final prompt text
    const finalPrompt = await this.processRiffMLNodes(
      riffDom,
      content,
      sessionId,
      dirtySessions,
      dirtyProjects,
      dirtyWorkspaces
    );

    // Collect action nodes
    const actionNodes = this.collectActionNodes(riffDom);

    // After processing all nodes, if any session, project, or workspace is dirty, save them
    // Sessions
    for (const s of dirtySessions) {
      await this.workspaceManagerService.saveChatSessionData(s);
    }

    // Projects
    for (const p of dirtyProjects) {
      await this.workspaceManagerService.saveProjectData(p);
    }

    // Workspaces (there may be multiple, but our service saves them all at once)
    if (dirtyWorkspaces.size > 0) {
      await this.workspaceManagerService.saveWorkspacesData();
    }

    return { prompt: finalPrompt, actionNodes };
  }

  /**
   * Recursively processes the RiffML DOM structure to build the prompt text
   */
  private async processRiffMLNodes(
    nodes: RiffMLNode[],
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    let output = '';
    for (const node of nodes) {
      // Process state tokens in attributes before passing to handleRiffMLNode
      if (node.attributes) {
        for (const attrKey in node.attributes) {
          const attrValue = node.attributes[attrKey];
          if (attrValue && typeof attrValue === 'string' && attrValue.includes('{{')) {
            // Replace all state tokens in the attribute value
            node.attributes[attrKey] = await this.replaceStateTokens(attrValue, sessionId);
          }
        }
      }
      
      output += await this.handleRiffMLNode(
        node,
        content,
        sessionId,
        dirtySessions,
        dirtyProjects,
        dirtyWorkspaces
      );
    }
    return output;
  }

  /**
   * Replaces all state tokens in the format {{key}} with their values
   * Similar to handlePrintTag but optimized for token replacement
   */
  private async replaceStateTokens(text: string, sessionId: string): Promise<string> {
    // Find all tokens in the format {{key}}
    const tokenRegex = /\{\{([^}]+)\}\}/g;
    let result = text;
    let match;
    
    // Process each token found in the string
    while ((match = tokenRegex.exec(text)) !== null) {
      const fullToken = match[0];       // {{key}}
      const tokenKey = match[1].trim(); // key
      
      // Get the value for this token using logic similar to handlePrintTag
      const tokenValue = await this.resolveStateToken(tokenKey, sessionId);
      
      // Replace the token with its value
      result = result.replace(fullToken, tokenValue);
    }
    
    return result;
  }
  
  /**
   * Resolves a single state token to its value
   * Based on handlePrintTag logic but simplified for token resolution
   */
  private async resolveStateToken(valueKey: string, sessionId: string): Promise<string> {
    let rootId = '';
    let contextKey = valueKey.toLowerCase();

    // Determine the root ID based on the token prefix
    if (valueKey.toLowerCase().startsWith('workspace.')) {
      rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
    } else if (valueKey.toLowerCase().startsWith('project.')) {
      rootId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
    } else {
      rootId = sessionId || this.sharedState.sessionsGridState$.value.selectedSessionId || '';
      if (!valueKey.toLowerCase().startsWith('session.')) {
        contextKey = 'session.' + contextKey;
      }
    }

    if (!rootId) {
      return '';
    }

    try {
      // Use the same logic as handlePrintTag to resolve special state keys
      if (contextKey === 'session.statemap' || 
          contextKey === 'project.statemap' || 
          contextKey === 'workspace.statemap') {
        const stateTypePart = contextKey.split('.')[0];
        // Ensure stateType is one of the expected values for getFullStateMap
        const stateType = (stateTypePart === 'workspace' || stateTypePart === 'project' || stateTypePart === 'session') 
          ? stateTypePart as 'workspace' | 'project' | 'session'
          : 'session'; // Default to session if unexpected value
        const stateMap = this.stateManagerService.getFullStateMap(rootId, stateType);
        return this.formatStateMap(stateMap);
      } else {
        // Handle regular state values
        const value = this.stateManagerService.getObject(rootId, contextKey);

        if (value === null || value === undefined) {
          return '';
        }

        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
          return String(value);
        }

        if (typeof value === 'object') {
          try {
            return JSON.stringify(value);
          } catch (e) {
            console.error(`Error stringifying object for token ${valueKey}:`, e);
            return '';
          }
        }
      }
      
      return '';
    } catch (error: any) {
      console.error(`Error resolving state token ${valueKey}:`, error);
      return '';
    }
  }

  /**
   * Collects all the RiffMLNode elements of types: wait, next, end, message, processingmessage, usermessage
   */
  private collectActionNodes(nodes: RiffMLNode[]): RiffMLNode[] {
    let result: RiffMLNode[] = [];

    for (const node of nodes) {
      const tagName = node.tagName.toLowerCase();
      if (['wait', 'next', 'end', 'message', 'processingmessage', 'usermessage'].includes(tagName)) {
        result.push(node);
      }
      // Recurse into children
      if (node.children && node.children.length > 0) {
        result = result.concat(this.collectActionNodes(node.children));
      }
    }

    return result;
  }

  /**
   * Dispatches each node to a relevant handler based on its tag name
   */
  private async handleRiffMLNode(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    switch (node.tagName.toLowerCase()) {
      case '#text':
        return this.handleTextNode(node);
      case 'if':
        return (
          await this.handleIfTag(node, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces)
        ) + '\n';
      case 'setstate':
        return await this.handleSetStateTag(node, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces);
      case 'addtostate':
        return await this.handleAddToStateTag(node, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces);
      case 'removefromstate':
        return this.handleRemoveFromStateTag(node, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces);
      case 'setmemory':
        return await this.handleSetMemoryTag(node, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces);
      case 'wait':
        return this.handleWaitTag(node);
      case 'next':
        return this.handleNextTag(node);
      case 'end':
        return this.handleEndTag(node);
      case 'runtool':
        return await this.handleRunTool(node, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces);
      case 'message':
        return await this.handleMessageTag(node, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces);
      case 'progressmessage':
          return await this.handleProgressMessageTag(node, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces);
      case 'processingmessage':
          return this.handleProcessingMessageTag(node);
      case 'usermessage':
        let tmp= await this.handleMessageTag(node, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces);
        node.content = tmp;
        return tmp;
      case 'print':
        return (await this.handlePrintTag(node)) + '\n';
      case 'sleep':
        return await this.handleSleepTag(node);

      case 'tablelink':
      case 'sessionlink':
      case 'workstreamlink':
      case 'webpagelink':
      case 'unitlink':
        // As per instructions, return empty string
        return '';
      default:
        // Unknown or unhandled tag: process its children
        let childOutput = '';
        for (const child of node.children) {
          childOutput += await this.handleRiffMLNode(
            child,
            content,
            sessionId,
            dirtySessions,
            dirtyProjects,
            dirtyWorkspaces
          );
        }
        return childOutput;
    }
  }

  /**
   * Mark rootId as dirty according to rootType
   */
  private flagDirty(
    rootType: 'workspace' | 'project' | 'session',
    rootId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ) {
    switch (rootType) {
      case 'session':
        dirtySessions.add(rootId);
        break;
      case 'project':
        dirtyProjects.add(rootId);
        break;
      case 'workspace':
        dirtyWorkspaces.add(rootId);
        break;
    }
  }

  /**
   * Handle a text node (simple passthrough)
   */
  private handleTextNode(node: RiffMLNode): string {
    return node.content;
  }

  /**
   * A placeholder expansion (no longer uses contextData).
   */
  private expandPlaceholders(text: string): string {
    return text; // returning as-is
  }

  /**
   * Handle <If cond="expression"> ... </If>
   * Evaluates a JavaScript condition based on state manager data.
   */
  private async handleIfTag(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    const cond = node.attributes['cond'] || '';
    if (!cond) {
      return '';
    }

    try {
      // 1. Determine the root context and get the appropriate state object
      const stateInfo = this.extractStateInfo(cond, sessionId);
      if (!stateInfo.rootId) {
        console.warn(`Unable to determine root ID for condition: ${cond}`);
        return '';
      }

      // 2. Get the state object from the state manager
      const stateObject = await this.stateManagerService.getObject(stateInfo.rootId, stateInfo.stateKey);
      if (stateObject === null || stateObject === undefined) {
        console.warn(`No state object found for condition: ${cond}`);
        return '';
      }

      // 3. Create a deep copy of the state object for evaluation
      const __stateObject = JSON.parse(JSON.stringify(stateObject));

      // 4. Create the evaluation condition by replacing state references
      const evalCondition = this.createEvalCondition(cond, stateInfo, __stateObject);

      // 5. Evaluate the condition
      // eslint-disable-next-line no-eval
      const isTrue = eval(evalCondition);
      if (!isTrue) {
        return '';
      }

      // 6. Process child nodes if condition is true
      let output = '';
      for (const child of node.children) {
        output +=
          await this.handleRiffMLNode(child, content, sessionId, dirtySessions, dirtyProjects, dirtyWorkspaces) + '\n';
      }
      return output;
    } catch (error) {
      console.error(`Error evaluating condition "${cond}":`, error);
      return '';
    }
  }

  /**
   * Extracts state information from a condition string.
   */
  private extractStateInfo(
    condition: string,
    sessionId: string
  ): {
    rootType: 'workspace' | 'project' | 'session';
    rootPrefix: string;
    rootId: string;
    stateKey: string;
    objectPath: string;
  } {
    const jsKeywords = new Set([
      'length',
      'indexOf',
      'lastIndexOf',
      'includes',
      'some',
      'every',
      'find',
      'filter',
      'map',
      'reduce',
      'forEach',
      'push',
      'pop',
      'shift',
      'unshift',
      'slice',
      'splice',
      'concat',
      'join',
      'split',
      'toString',
      'valueOf',
      'hasOwnProperty',
      'isPrototypeOf',
      'propertyIsEnumerable',
      'toLocaleString',
      'constructor',
      'prototype',
      '__proto__'
    ]);

    let rootType: 'workspace' | 'project' | 'session' = 'session';
    let rootPrefix = 'session';
    let rootId = '';
    let objectPath = '';
    let stateKey = '';

    // 1. Determine if condition has an explicit prefix
    let prefixMatch: RegExpExecArray | null = null;
    const prefixRegex = /(workspace|project|session)\.([a-zA-Z0-9_]+)/i;
    prefixMatch = prefixRegex.exec(condition);

    if (prefixMatch) {
      rootPrefix = prefixMatch[1].toLowerCase();
      objectPath = prefixMatch[2];

      if (rootPrefix === 'workspace') {
        rootType = 'workspace';
        rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
      } else if (rootPrefix === 'project') {
        rootType = 'project';
        rootId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
      } else {
        rootType = 'session';
        rootId = sessionId || this.sharedState.sessionsGridState$.value.selectedSessionId || '';
      }
    } else {
      // No explicit prefix - look for unprefixed reference => session
      const unprefixedRegex = /\b([a-zA-Z0-9_]+)\b(?!:\s*function|\s*=>|\s*\()/;
      const unprefixedMatch = unprefixedRegex.exec(condition);

      if (unprefixedMatch && !jsKeywords.has(unprefixedMatch[1].toLowerCase())) {
        rootType = 'session';
        rootPrefix = 'session';
        objectPath = unprefixedMatch[1];
        rootId = sessionId || this.sharedState.sessionsGridState$.value.selectedSessionId || '';
      } else {
        rootType = 'session';
        rootPrefix = 'session';
        rootId = sessionId || this.sharedState.sessionsGridState$.value.selectedSessionId || '';

        const propAccessRegex = /\.([\w]+)/g;
        let propMatch;
        let firstValidProp = '';

        while ((propMatch = propAccessRegex.exec(condition)) !== null) {
          if (!jsKeywords.has(propMatch[1].toLowerCase())) {
            firstValidProp = propMatch[1];
            break;
          }
        }

        objectPath = firstValidProp;
      }
    }

    objectPath = this.cleanObjectPath(objectPath);
    stateKey = objectPath ? `${rootPrefix}.${objectPath}` : rootPrefix;

    return { rootType, rootPrefix, rootId, stateKey, objectPath };
  }

  private createEvalCondition(
    originalCondition: string,
    stateInfo: { rootType: string; rootPrefix: string; objectPath: string; },
    stateObject: any
  ): string {
    let evalCondition = originalCondition;
    const { rootPrefix, objectPath } = stateInfo;

    if (rootPrefix && objectPath) {
      // Replace fully qualified path
      const fullPathRegex = new RegExp(`\\b${rootPrefix}\\.${objectPath}\\b`, 'gi');
      evalCondition = evalCondition.replace(fullPathRegex, '__stateObject');

      // If root is session, also replace unprefixed references
      if (rootPrefix === 'session') {
        const unprefixedRegex = new RegExp(`\\b${objectPath}\\b(?!\\.${rootPrefix})`, 'gi');
        evalCondition = evalCondition.replace(unprefixedRegex, '__stateObject');
      }
    } else if (rootPrefix) {
      // If we only have a root prefix
      const rootRegex = new RegExp(`\\b${rootPrefix}\\b`, 'gi');
      evalCondition = evalCondition.replace(rootRegex, '__stateObject');
    }

    return evalCondition;
  }

  private cleanObjectPath(path: string): string {
    let cleanPath = path.split('[')[0];
    cleanPath = cleanPath.split('(')[0];
    const operators = ['+', '-', '*', '/', '!', '=', '>', '<', '&', '|', '?', ':'];
    for (const op of operators) {
      cleanPath = cleanPath.split(op)[0];
    }
    return cleanPath.trim();
  }

  /**
   * Shared helper function to process child nodes content
   */
  private async processChildNodesContent(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    let result = '';

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        result += (await this.handleRiffMLNode(
          child,
          content,
          sessionId,
          dirtySessions,
          dirtyProjects,
          dirtyWorkspaces
        )).trim();
      }
    } else {
      result = node.content || '';
    }

    return result;
  }

  /**
   * Resolves the root ID and context name based on the state key
   */
  private resolveStateContext(name: string): { rootId: string; contextName: string; } {
    let rootId = '';
    let contextName = name;

    if (name.toLowerCase().startsWith('workspace.')) {
      rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
    } else if (name.toLowerCase().startsWith('project.')) {
      rootId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
    } else {
      rootId = this.sharedState.sessionsGridState$.value.selectedSessionId || '';
    }

    return { rootId, contextName };
  }

  /**
   * Handle <SetState name="session.Status">Active</SetState>
   */
  private async handleSetStateTag(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    const name = node.attributes['name'] || '';
    const id = node.attributes['id'] || '';
    const description = node.attributes['description'] || '';
    const whyRelevant = node.attributes['whyRelevant'] || '';
    const type = node.attributes['type'] || '';

    var value = await this.processChildNodesContent(
      node,
      content,
      sessionId,
      dirtySessions,
      dirtyProjects,
      dirtyWorkspaces
    );

    try {
      if (value.trim().startsWith('{') || value.trim().startsWith('[')) {
        value = this.jsonParser.safeParseJson(value);
      }
    } catch (e) {
    }

    const { rootId, contextName } = this.resolveStateContext(name);

    if (rootId) {
      const result = await this.stateManagerService.setState(
        rootId,
        contextName,
        id || contextName,
        name,
        description,
        whyRelevant,
        type,
        value
      );
      if (result.success) {
        // Mark dirty
        this.flagDirty(result.rootType, rootId, dirtySessions, dirtyProjects, dirtyWorkspaces);
        // Additional system state handling
        await this.handleSystemStates(result, value, sessionId);

        console.log('[ChatMessageProcessorService] SetState result successful handleSystemStates:', rootId, contextName, result);
      }

      else {
        console.log('[ChatMessageProcessorService] SetState result unsuccessful:', rootId, contextName, result);
      }

    }

    else {
      console.log('[ChatMessageProcessorService] SetState result no root', rootId, contextName);
    }


    return '';
  }

  /**
   * Handle system states that require special processing
   */
  private async handleSystemStates(result: StateOperationResult, value: any, sessionId: string): Promise<void> {
    // Only process session-level system states
    if (result.rootType !== 'session') {
      return;
    }

    const rootId = result.newItem?.id || '';
    // If newItem doesn't store the actual session ID, skip
    if (!rootId) {
      return;
    }

    // Check for "taskname" or "taskdescription"
    if (result.mapKey.toLowerCase() === 'name') {

      const chatSession = this.sharedState.tryGetChatSession(sessionId);

      if (chatSession && chatSession.value && chatSession.value.title !== String(value)) {
        chatSession.value.title = String(value);
        await this.sessionsManagerService.updateProjectMetaData(sessionId, String(value), '');
        await this.workspaceManagerService.saveChatSessionData(sessionId);
      }

    } else if (result.mapKey.toLowerCase() === 'description') {
      const chatSessiond = this.sharedState.tryGetChatSession(sessionId);

      if (chatSessiond && chatSessiond.value && chatSessiond.value.description !== String(value)) {
        chatSessiond.value.description = String(value);
        await this.sessionsManagerService.updateProjectMetaData(sessionId, '', String(value));
        await this.workspaceManagerService.saveChatSessionData(sessionId);
      }
    }
    else if (result.mapKey.toLowerCase().indexOf('workstreamchanges') > -1) {
      // Handle workstream changes
      try {
        // Parse the workstream change data if it's a string
        const workstreamChange = typeof value === 'string' ? this.jsonParser.safeParseJson(value) : value;

        // Check if the workstream change has an ID
        if (workstreamChange && workstreamChange.id) {

          if (workstreamChange.units && workstreamChange.units.length > 0) {
            for (const unit of workstreamChange.units) {
      
              // handle unit code to generate mingus code
              if (unit.code && unit.code.length > 0)
              {
                  if (unit.description && unit.description.length > 0)
                  {
                    this.stateManagerService.setState(sessionId, 'codeDescription', "codeDescription", "codeDescription", "codeDescription", 'value changed', 'text', unit.description);
                  }
      
                  this.stateManagerService.setState(sessionId, 'unitCode', "unitCode", "unitCode", "unitCode", 'value changed', 'text', unit.code);
      
                  // Get the process turn handler
                  if (this.processTurnHandler) {
                    await this.processTurnHandler.runPlaybook(sessionId, "47673", false, "unitMingus");
                    let unitMingus =  this.stateManagerService.getObject(sessionId, "session.unitMingus");
                    if (unitMingus && unitMingus.length > 0)
                    {
                      if (unitMingus.length)
                      {
                          // Clean the JSON string before parsing
                          const mingusString = String(unitMingus);
                          // Find the first { and last } to extract valid JSON
                          const firstBrace = mingusString.indexOf('{');
                          const lastBrace = mingusString.lastIndexOf('}');
                          
                          if (firstBrace !== -1 && lastBrace !== -1 && lastBrace > firstBrace) {
                            const cleanedMingusString = mingusString.substring(firstBrace, lastBrace + 1);
                            try {
                              // Parse the cleaned JSON string
                              const parsedMingus = this.jsonParser.safeParseJson(cleanedMingusString);
                              // Set the mingus property to the parsed object
                              unit.mingus = JSON.stringify(parsedMingus);
                            } catch (parseError) {
                              console.error('Error parsing mingus JSON:', parseError);
                            }
                          }
                      }
                      else
                      {
                        unit.mingus = unitMingus;
                      }
                    }
                  }
                  else
                  {
                    console.error('No ProcessTurnHandler registered to handle playbook');
                  }
              }
            }
          }

          // Call the mergeWorkstream function in the workspaceState service
          await this.workspaceState.mergeWorkstream(workstreamChange);
        }

        // TODO: merge code for units mingus generation


      } catch (error) {
        console.error('Error processing workstream changes:', error);
      }
    }
  }

  /**
   * Handle <AddToState name="ContextTables">{...}</AddToState>
   */
  private async handleAddToStateTag(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    const name = node.attributes['name'] || '';
    const jsonValue = await this.processChildNodesContent(
      node,
      content,
      sessionId,
      dirtySessions,
      dirtyProjects,
      dirtyWorkspaces
    );

    let value: any;
    try {
      if (jsonValue.trim().startsWith('{') || jsonValue.trim().startsWith('[')) {
        value = this.jsonParser.safeParseJson(jsonValue);
      } else {
        value = jsonValue;
      }
    } catch (e) {
      value = jsonValue;
    }

    const { rootId, contextName } = this.resolveStateContext(name);

    if (rootId) {
      const result = await this.stateManagerService.addToState(rootId, contextName, value);
      if (result.success) {
        this.flagDirty(result.rootType, rootId, dirtySessions, dirtyProjects, dirtyWorkspaces);
        await this.handleSystemStates(result, value, sessionId);
        console.log('[ChatMessageProcessorService] addToState result successful handleSystemStates:', rootId, contextName, result);
      }
      else {
        console.log('[ChatMessageProcessorService] addToState result unsuccessful:', rootId, contextName, result);
      }
    }
    else {
      console.log('[ChatMessageProcessorService] addToState result no root', rootId, contextName, value);
    }

    return '';
  }

  /**
   * Handle <RemoveFromState name="ContextTables[table1]"/>
   */
  private async handleRemoveFromStateTag(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    const name = node.attributes['name'] || '';
    const { rootId, contextName } = this.resolveStateContext(name);

    if (rootId) {
      const result = await this.stateManagerService.deleteState(rootId, contextName);
      if (result.success) {
        this.flagDirty(result.rootType, rootId, dirtySessions, dirtyProjects, dirtyWorkspaces);
      }
    }
    return '';
  }

  /**
   * Handle <Wait/>
   */
  private handleWaitTag(node: RiffMLNode): string {
    console.log('Wait tag encountered: typically pause for user input');
    return '';
  }

  /**
   * Handle <Next/>
   */
  private handleNextTag(node: RiffMLNode): string {
    console.log('Next tag encountered: proceed to next playbook or step');
    return '';
  }

  /**
   * Handle <End/>
   */
  private handleEndTag(node: RiffMLNode): string {
    console.log('End tag encountered: end the current session');
    return '';
  }

  /**
  * Handle <RunTool>
  */
  private async handleRunTool(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    // Process child nodes to get the tool input
    var value = await this.processChildNodesContent(
      node,
      content,
      sessionId,
      dirtySessions,
      dirtyProjects,
      dirtyWorkspaces
    );

    var valueJson = this.jsonParser.safeParseJson(value);

    // Check if there's a progress message attribute
    const progressMessages = node.attributes['progressmessage'];
    const intervalStr = node.attributes['interval'] || '5000';
    const interval = parseInt(intervalStr);

    if (progressMessages) {
      // Parse the messages
      const messages = progressMessages.split(';').map(msg => msg.trim()).filter(msg => msg);
      let messageIndex = 0;
      
      // Set up a timer to display progress messages
      const progressTimer = setInterval(async () => {
        if (messageIndex < messages.length) {
          const message = messages[messageIndex++];
          const chatSession = this.sharedState.tryGetChatSession(sessionId);
          
          if (chatSession && chatSession.value) {
            const procMessage: ChatTurn = {
              id: uuidv4(),
              sessionId,
              content: message,
              role: MessageRole.Process,
              senderId: 'system',
              timestamp: new Date(),
              artifacts: [],
              turnMetadata: {
                turnNumber: chatSession.value.turns.length + 1,
                processingTime: 0,
                turnData: { toolNode: node }
              }
            };
            chatSession.value.turns.push(procMessage);
            await this.workspaceManagerService.saveChatSessionData(sessionId);
          }
        } else {
          // Reset to first message if we've gone through all of them
          messageIndex = 0;
        }
      }, interval);
      
      try {
        // Call the tool and wait for it to complete
        await this.toolsService.callTool(node.attributes['tool'], node.attributes['next'], sessionId, node, valueJson);
      } finally {
        // Clear the timer when the tool finishes
        clearInterval(progressTimer);
      }
    } else {
      // Just call the tool without progress messages
      await this.toolsService.callTool(node.attributes['tool'], node.attributes['next'], sessionId, node, valueJson);
    }
    
    return '';
  }

  /**
   * Handle <Message>Some text</Message>
   */
  private async handleMessageTag(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    // First, identify and preprocess any Print tags within the Message
    if (node.children && node.children.length > 0) {
      // Process each child, with special handling for Print tags
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        if (child.tagName.toLowerCase() === 'print') {
          // Get the value attribute
          const valueKey = child.attributes['value'] || '';

          // Handle Print tag and get its output
          const printOutput = await this.handlePrintTag(child);

          // Replace the Print tag with a text node containing the output
          node.children[i] = {
            tagName: '#text',
            content: printOutput,
            attributes: {},
            children: []
          };
        }
      }
    }

    // Now process the updated children normally to get the message text
    let messageText = '';
    for (const child of node.children) {
      // Skip print tags as they've already been replaced with text nodes
      if (child.tagName.toLowerCase() !== 'print') {
        messageText += await this.handleRiffMLNode(
          child,
          content,
          sessionId,
          dirtySessions,
          dirtyProjects,
          dirtyWorkspaces
        );
      }
    }

    // If no children processed, use content directly
    if (!messageText && node.content) {
      messageText = this.expandPlaceholders(node.content);
    }

    return messageText;
  }

  /**
   * Handle <ProcessingMessage>Temporary status</ProcessingMessage>
   */
  private handleProcessingMessageTag(node: RiffMLNode): string {
    console.log('ProcessingMessage encountered, ephemeral text: ' + node.content);
    return '';
  }

  /**
   * Handle <ProgressMessage interval="5000">Message1;Message2;Message3</ProgressMessage>
   * Creates and sends a series of processing messages with the specified interval
   */
  private async handleProgressMessageTag(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    const interval = parseInt(node.attributes['interval'] || '5000', 10);
    const messages = node.content.split(';').map(msg => msg.trim()).filter(msg => msg);
    
    // Create and send each message with the specified interval
    for (const message of messages) {
      const chatSession = this.sharedState.tryGetChatSession(sessionId);
      if (chatSession && chatSession.value) {
        const procMessage: ChatTurn = {
          id: uuidv4(),
          sessionId,
          content: message,
          role: MessageRole.Process,
          senderId: 'system',
          timestamp: new Date(),
          artifacts: [],
          turnMetadata: {
            turnNumber: chatSession.value.turns.length + 1,
            processingTime: 0,
            turnData: { messageNode: node }
          }
        };
        chatSession.value.turns.push(procMessage);
        await this.workspaceManagerService.saveChatSessionData(sessionId);
      }
      
      // Wait for the specified interval before showing the next message
      // Only wait if this isn't the last message
      if (message !== messages[messages.length - 1]) {
        await new Promise(resolve => setTimeout(resolve, interval));
      }
    }

    return '';
  }

  /**
   * Handle <UserMessage>Message for the user to send</UserMessage>
   */
  private handleUserMessageTag(node: RiffMLNode): string {
    console.log('UserMessage encountered, message to send: ' + node.content);
    return '';
  }

  /**
   * Formats a state map for printing in a readable format
   * @param stateMap The state map to format
   * @returns A formatted string representation of the state map
   */
  private formatStateMap(stateMap: { [key: string]: any } | null): string {
    if (!stateMap) {
      return 'No state map available';
    }

    const formattedLines: string[] = [
      '------',
      'State Map:',
    ];

    Object.entries(stateMap).forEach(([key, stateItem]) => {
      formattedLines.push('--');
      
      // Format each state item with its key and value
      let value: string;
      if (stateItem.data === null || stateItem.data === undefined) {
        value = 'null';
      } else if (typeof stateItem.data === 'string') {
        value = stateItem.data;
      } else if (typeof stateItem.data === 'object') {
        try {
          value = JSON.stringify(stateItem.data);
        } catch (e) {
          value = '[Complex Object]';
        }
      } else {
        value = String(stateItem.data);
      }

      formattedLines.push(`${key} : ${value}`);
    });

    return formattedLines.join('\n');
  }

  /**
   * Formats memory items for printing in a readable format
   * @param memories Array of memory items to format
   * @returns A formatted string representation of the memory items
   */
  private formatMemories(memories: any[]): string {
    if (!memories || memories.length === 0) {
      return 'No memories available';
    }

    const formattedLines: string[] = [
      '------',
      `Memories (${memories.length} items):`,
    ];

    memories.forEach(memory => {
      formattedLines.push('--');
      formattedLines.push(`ID: ${memory.memory_id}`);
      formattedLines.push(`Type: ${memory.type}`);
      formattedLines.push(`Created: ${memory.created_date}`);
      formattedLines.push(`Importance: ${memory.importance}`);
      
      if (memory.session_id) {
        formattedLines.push(`Session: ${memory.session_id}`);
      }
      
      if (memory.title) {
        formattedLines.push(`Title: ${memory.title}`);
      }
      
      if (memory.content) {
        formattedLines.push(`Content: ${typeof memory.content === 'string' ? 
          memory.content : JSON.stringify(memory.content)}`);
      }
      
      if (memory.context && memory.context.objects && memory.context.objects.length > 0) {
        formattedLines.push(`Context Objects: ${memory.context.objects.map((obj: any) => 
          `${obj.object_type}:${obj.object_id}`).join(', ')}`);
      }
      
      if (memory.context && memory.context.related_memories && memory.context.related_memories.length > 0) {
        formattedLines.push(`Related Memories: ${memory.context.related_memories.join(', ')}`);
      }
    });

    return formattedLines.join('\n');
  }

  /**
   * Handle <Print value="session.Status"/>
   */
  private async handlePrintTag(node: RiffMLNode): Promise<string> {
    const valueKey = node.attributes['value'] || '';
    let rootId = '';
    let contextKey = valueKey.toLowerCase();

    if (valueKey.toLowerCase().startsWith('workspace.')) {
      rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
    } else if (valueKey.toLowerCase().startsWith('project.')) {
      rootId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
    } else {
      rootId = this.sharedState.sessionsGridState$.value.selectedSessionId || '';
      if (!valueKey.toLowerCase().startsWith('session.'))
      contextKey = 'session.' + contextKey;
    }

    if (!rootId) {
      return '';
    }

    try {
      // Handle state map printing specifically
      if (contextKey === 'session.statemap') {
        const stateMap = this.stateManagerService.getFullStateMap(rootId, 'session');
        return this.formatStateMap(stateMap);
      } else if (contextKey === 'project.statemap') {
        const stateMap = this.stateManagerService.getFullStateMap(rootId, 'project');
        return this.formatStateMap(stateMap);
      } else if (contextKey === 'workspace.statemap') {
        const stateMap = this.stateManagerService.getFullStateMap(rootId, 'workspace');
        return this.formatStateMap(stateMap);
      } else if (contextKey === 'project.memories') {
        // Load memories for project scope
        const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
        if (projectId) {
          const memories = await this.memoryManagerService.loadMemoriesForScope('project', projectId);
          return this.formatMemories(memories);
        }
        return 'No project memories available';
      } else if (contextKey.startsWith('project.memories.')) {
        // Handle filtered memories by type for project scope
        const memoryType = contextKey.substring('project.memories.'.length);
        const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
        if (projectId && memoryType) {
          await this.memoryManagerService.loadMemoriesForScope('project', projectId);
          const memories = await this.memoryManagerService.getMemoryItemsByType('project', projectId, memoryType);
          return this.formatMemories(memories);
        }
        return `No project memories of type ${memoryType} available`;
      } else if (contextKey === 'workspace.memories') {
        // Load memories for workspace scope
        const workspaceId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
        if (workspaceId) {
          const memories = await this.memoryManagerService.loadMemoriesForScope('workspace', workspaceId);
          return this.formatMemories(memories);
        }
        return 'No workspace memories available';
      } else if (contextKey.startsWith('workspace.memories.')) {
        // Handle filtered memories by type for workspace scope
        const memoryType = contextKey.substring('workspace.memories.'.length);
        const workspaceId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
        if (workspaceId && memoryType) {
          await this.memoryManagerService.loadMemoriesForScope('workspace', workspaceId);
          const memories = await this.memoryManagerService.getMemoryItemsByType('workspace', workspaceId, memoryType);
          return this.formatMemories(memories);
        }
        return `No workspace memories of type ${memoryType} available`;
      } else if (contextKey === 'session.suggestedworkstreams') {
        const workstreamsObj = this.stateManagerService.getObject(rootId, valueKey);
        return workstreamsObj ? this.getSuggestionPrompt(workstreamsObj) : '';
      } else if (contextKey === 'session.suggestedsessions') {
        const sessionsObj = this.stateManagerService.getObject(rootId, valueKey);
        return sessionsObj ? this.generateSessionsPrompt(sessionsObj) : '';
      } else if (contextKey === 'session.suggestedtables') {
        const tablesObj = this.stateManagerService.getObject(rootId, valueKey);
        return tablesObj ? this.getSuggestionPrompt(tablesObj) : '';
      } else if (contextKey === 'session.contextworkstreams') {
        let workstreamsArray = this.stateManagerService.getObject(rootId, valueKey);
        // Check if it's a string that needs parsing
        if (typeof workstreamsArray === 'string') {
          workstreamsArray = this.jsonParser.safeParseJson(workstreamsArray);
        }
        return workstreamsArray ? this.printContextworkstreams(workstreamsArray) : '';
      } else if (contextKey === 'session.contextworkstreamsfull') {
        let workstreamsArray = this.stateManagerService.getObject(rootId, 'contextworkstreams');
        // Check if it's a string that needs parsing
        if (typeof workstreamsArray === 'string') {
          workstreamsArray = this.jsonParser.safeParseJson(workstreamsArray);
        }
        return workstreamsArray ? this.printContextworkstreamsFull(workstreamsArray) : '';
      }
      else if (contextKey === 'session.contexttables') {
        let tablesArray = this.stateManagerService.getObject(rootId, valueKey);
        // Check if it's a string that needs parsing
        if (typeof tablesArray === 'string') {
          tablesArray = this.jsonParser.safeParseJson(tablesArray);
        }
        return tablesArray ? this.printContextTables(tablesArray) : '';
      }
      
      else if (contextKey === 'session.log') {
        const sessionSubject = this.sharedState.tryGetChatSession(rootId);
        if (sessionSubject && sessionSubject.value) {
          const session = sessionSubject.value;
          return this.printMessagesForPrompt(session.turns, session.DTeamID ?? 'PM');
        }
        return '';
      } else if (contextKey === 'project.workstreams') {
        const workboardPrompt = await this.workboardManagerService.GetWorkboardForPrompt();
        return workboardPrompt;
      } else if (contextKey === 'project.lastsessions') {
        const lastSessionsPrompt = await this.sessionsManagerService.GetLastSessionsForPrompt();
        return lastSessionsPrompt;
      } else if (contextKey === 'workspace.tables') {
        const workspaceId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
        if (workspaceId) {
          const tablesCollection = await this.tablesManagerService.getTablesCollection();
          return this.tablesManagerService.getTablesCollectionAsString(tablesCollection);
        }
        return '';
      } else {
        const value = this.stateManagerService.getObject(rootId, contextKey);

        if (value === null || value === undefined) {
          return '';
        }

        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
          return String(value);
        }

        if (typeof value === 'object') {
          try {
            return JSON.stringify(value);
          } catch (e) {
            console.error('Error stringifying object:', e);
            return '';
          }
        }

        return '';
      }
    } catch (error: any) {
      console.error(`Error in handlePrintTag for key ${valueKey}:`, error);
      return '';
    }
  }

  private getSuggestionPrompt(objectMap: Record<string, { id: string; whyRelevant: string }>): string {
    let result = '';
    
    for (const key in objectMap) {
      const obj = objectMap[key];
      result += `${obj.id}: ${obj.whyRelevant}\n`;
    }
    
    return result;
  }

  /**
   * Prints formatted information for an array of workstream IDs
   * by calling the workboard manager service for each workstream's data
   */
  private printContextworkstreams(workstreamIds: string[] | any): string {
    // Ensure we have an array to work with
    if (!workstreamIds) {
      console.log('printContextworkstreams: No workstream IDs provided');
      return 'No context workstreams available';
    }
    
    try {
      // Convert to array if it's not already
      let idsArray: any[] = [];
      
      if (Array.isArray(workstreamIds)) {
        idsArray = workstreamIds;
      } else if (typeof workstreamIds === 'string') {
        // If it's a plain string that's not JSON-formatted, treat as a single ID
        if (!workstreamIds.trim().startsWith('[')) {
          idsArray = [workstreamIds];
        } else {
          // Try to parse the string as JSON if it looks like an array
          try {
            const parsed = this.jsonParser.safeParseJson(workstreamIds);
            idsArray = Array.isArray(parsed) ? parsed : [parsed];
          } catch (e) {
            console.error('Error parsing workstream IDs string:', e);
            idsArray = [workstreamIds];
          }
        }
      } else if (typeof workstreamIds === 'object' && workstreamIds !== null) {
        idsArray = Object.values(workstreamIds);
      }
      
      if (idsArray.length === 0) {
        return 'No context workstreams available';
      }

      const result: string[] = ['Context Workstreams:'];
      
      for (let i = 0; i < idsArray.length; i++) {
        const workstreamId = idsArray[i];
        // Skip null or undefined values
        if (!workstreamId) {
          console.log(`Skipping empty workstream ID at index ${i}`);
          continue;
        }
        
        // Convert to string if it's not already
        const idStr = String(workstreamId).trim();
        if (!idStr) {
          console.log(`Skipping empty workstream ID string at index ${i}`);
          continue;
        }
        
        const workstreamData = this.workboardManagerService.getWorkstreamById(idStr);
        if (workstreamData) {
          result.push(this.formatWorkstreamInfo(workstreamData));
        } else {
          console.log(`No workstream data found for ID: ${idStr}`);
        }
      }

      return result.join('\n');
    } catch (error: any) {
      console.error('Error in printContextworkstreams:', error);
      const errorMessage = error instanceof Error ? error.message : String(error);
      return `Error processing workstreams: ${errorMessage}`;
    }
  }

  private printContextworkstreamsFull(workstreamIds: string[] | any): string {
    // Ensure we have an array to work with
    if (!workstreamIds) {
      console.log('printContextworkstreamsFull: No workstream IDs provided');
      return 'No context workstreams available';
    }
    
    try {
      // Convert to array if it's not already
      let idsArray: any[] = [];
      
      if (Array.isArray(workstreamIds)) {
        idsArray = workstreamIds;
      } else if (typeof workstreamIds === 'string') {
        // If it's a plain string that's not JSON-formatted, treat as a single ID
        if (!workstreamIds.trim().startsWith('[')) {
          idsArray = [workstreamIds];
        } else {
          // Try to parse the string as JSON if it looks like an array
          try {
            const parsed = this.jsonParser.safeParseJson(workstreamIds);
            idsArray = Array.isArray(parsed) ? parsed : [parsed];
          } catch (e) {
            console.error('Error parsing workstream IDs string:', e);
            idsArray = [workstreamIds];
          }
        }
      } else if (typeof workstreamIds === 'object' && workstreamIds !== null) {
        idsArray = Object.values(workstreamIds);
      }
      
      if (idsArray.length === 0) {
        return 'No context workstreams available';
      }

      const result: string[] = ['Context Workstreams:'];
      
      for (let i = 0; i < idsArray.length; i++) {
        const workstreamId = idsArray[i];
        // Skip null or undefined values
        if (!workstreamId) {
          console.log(`Skipping empty workstream ID at index ${i}`);
          continue;
        }
        
        // Convert to string if it's not already
        const idStr = String(workstreamId).trim();
        if (!idStr) {
          console.log(`Skipping empty workstream ID string at index ${i}`);
          continue;
        }
        
        const workstreamData = this.workboardManagerService.getWorkstreamById(idStr);
        if (workstreamData) {
          result.push(JSON.stringify(workstreamData));
        } else {
          console.log(`No workstream data found for ID: ${idStr}`);
        }
      }

      return result.join('\n');
    } catch (error: any) {
      console.error('Error in printContextworkstreamsFull:', error);
      const errorMessage = error instanceof Error ? error.message : String(error);
      return `Error processing workstreams: ${errorMessage}`;
    }
  }

  /**
   * Prints formatted information for an array of table IDs
   */
  private printContextTables(tableIds: string[] | any): string {
    // Ensure we have an array to work with
    if (!tableIds) {
      console.log('printContextTables: No table IDs provided');
      return 'No context tables available';
    }
    
    try {
      // Convert to array if it's not already
      let idsArray: any[] = [];
      
      if (Array.isArray(tableIds)) {
        idsArray = tableIds;
      } else if (typeof tableIds === 'string') {
        // If it's a plain string that's not JSON-formatted, treat as a single ID
        if (!tableIds.trim().startsWith('[')) {
          idsArray = [tableIds];
        } else {
          // Try to parse the string as JSON if it looks like an array
          try {
            const parsed = this.jsonParser.safeParseJson(tableIds);
            idsArray = Array.isArray(parsed) ? parsed : [parsed];
          } catch (e) {
            console.error('Error parsing table IDs string:', e);
            idsArray = [tableIds];
          }
        }
      } else if (typeof tableIds === 'object' && tableIds !== null) {
        idsArray = Object.values(tableIds);
      }
      
      if (idsArray.length === 0) {
        return 'No context tables available';
      }

      const result: string[] = ['Context Tables:'];
      
      for (let i = 0; i < idsArray.length; i++) {
        const tableId = idsArray[i];
        // Skip null or undefined values
        if (!tableId) {
          console.log(`Skipping empty table ID at index ${i}`);
          continue;
        }
        
        // Convert to string if it's not already
        const idStr = String(tableId).trim();
        if (!idStr) {
          console.log(`Skipping empty table ID string at index ${i}`);
          continue;
        }
        
        let tableData = this.stateManagerService.getObject('', "workspace.tables." + idStr);

        if (!tableData && idStr.length) {
          tableData = this.stateManagerService.getObject('', "workspace.tables." + idStr.toLowerCase().replace(/\./g, '$').replace(/ /g, '%'));
        }

        if (tableData) {
          result.push(this.tablesManagerService.getTableModelAsString(tableData));
        } else {
          console.log(`No table data found for ID: ${idStr}`);
        }
      }

      return result.join('\n');
    } catch (error: any) {
      console.error('Error in printContextTables:', error);
      const errorMessage = error instanceof Error ? error.message : String(error);
      return `Error processing tables: ${errorMessage}`;
    }
  }

  /**
   * (TableLink, SessionLink, WorkstreamLink, and UnitLink all return empty per new instructions)
   * Implementation is replaced in handleRiffMLNode switch.
   */
  /**
   * Searches for conditional blocks in the form [[if:Condition]]...[[endif]]
   * (Legacy logic only; not used in new RiffML flow.)
   */
  private parseConditionalBlocks(template: string): string {
    const regex = /\[\[if:(.*?)\]\](.*?)\[\[endif\]\]/gs;
    return template.replace(regex, (match, condition, content) => {
      const isTrue = this.sharedState.checkCondition(condition);
      return isTrue ? content.trim() : '';
    });
  }

/**
 * Generates metadata for the last messages in the conversation
 * Now includes artifact content in the output
 */
printMessagesForPrompt(turns: ChatTurn[], dTeamId: string): string {
  return turns
    .map(turn => {
      if (turn.role === 'process') {
        return '';
      }
      
      let content: string;
      if (typeof turn.content === 'string') {
        content = turn.content;
      } else if (this.isRiffMLNode(turn.content) || Array.isArray(turn.content)) {
        content = this.renderRiffMLContentToString(turn.content);
      } else {
        content = String(turn.content);
      }
      
      let metadata = `${new Date(turn.timestamp).toISOString()} | ${turn.role}${turn.role === 'agent' ? ' :' + dTeamId : ''}${turn.senderDTeamId && turn.senderDTeamId !== dTeamId ? ' :' + turn.senderDTeamId : ''} | ${content}`;
      
      // Add artifact information if any exists
      if (turn.artifacts && turn.artifacts.length > 0) {
        turn.artifacts.forEach(artifact => {
          // Check if artifact has content and it's a string
          const artifactContent = typeof artifact.content === 'string' ? artifact.content : 
                                (artifact.content ? JSON.stringify(artifact.content) : 'No content available');
          
          metadata += `\n\n==start added artifact '${artifact.title || artifact.id}' of type '${artifact.type || 'text/plain'}' ==\n`;
          metadata += artifactContent;
          metadata += `\n==end added artifact '${artifact.title || artifact.id}' of type '${artifact.type || 'text/plain'}' ==`;
        });
      }
      
      if (turn.role === 'agent' && turn.turnMetadata?.turnData?.responseData) {
        if (dTeamId === 'PM') {
          const agentData = turn.turnMetadata.turnData.responseData as ProjectManagerSessionMessage;
          if (agentData.suggestedWorkboardNewItems?.groups?.length > 0) {
            metadata += '\n\n  Suggested Workboard Items:';
            agentData.suggestedWorkboardNewItems.groups.forEach(group => {
              group.workstreamCols.forEach(col => {
                col.workstreams.forEach(ws => {
                  metadata += `\n    ${group.id} -> ${col.id} -> ${ws.id}`;
                });
              });
            });
          }
          if (agentData.suggestedNewSessions?.length > 0) {
            metadata += '\n\n  Suggested New Sessions:';
            agentData.suggestedNewSessions.forEach(session => {
              metadata += `\n    ${session.id}`;
            });
          }
        } else {
          const agentData = turn.turnMetadata.turnData.responseData as DTeamChatResponse;
          if (agentData.playbook_id) {
            metadata += `
            * next playbook id: ${agentData.playbook_id}`;
          }
          if (agentData.handover_message) {
            metadata += `
            * context to next agent: ${agentData.handover_message}`;
          }
          if (agentData.handover_data) {
            metadata += `
            * data from agent: ${JSON.stringify(agentData.handover_data)}`;
          }
          if (agentData.debuggingMessage) {
            metadata += `
            * notes: ${agentData.debuggingMessage}`;
          }
        }
      }
      
      return metadata;
    })
    .join('\n\n');
}

  /**
   * Check if an object is a RiffMLNode
   */
  private isRiffMLNode(obj: any): obj is RiffMLNode {
    return obj && typeof obj === 'object' && 'tagName' in obj && 'attributes' in obj && 'children' in obj;
  }

  /**
   * Renders RiffML content to a string representation
   */
  private renderRiffMLContentToString(content: RiffMLNode | RiffMLNode[]): string {
    const nodes = Array.isArray(content) ? content : [content];
    let result = '';

    const traverseNodes = (nodeList: RiffMLNode[]): void => {
      for (const node of nodeList) {
        if (node.tagName === '#text') {
          result += node.content;
        } else if (['tablelink', 'sessionlink', 'workstreamlink', 'unitlink'].includes(node.tagName.toLowerCase())) {
          // Return a special link representation
          result += this.createSpecialLinkText(node);
        } else {
          // For other nodes, add tag info and children
          if (node.tagName.toLowerCase() !== '#text') {
            result += `[${node.tagName}`;
            if (Object.keys(node.attributes).length > 0) {
              const attrStr = Object.entries(node.attributes)
                .map(([key, value]) => `${key}="${value}"`)
                .join(' ');
              result += ` ${attrStr}`;
            }
            result += '] ';
          }

          if (node.content && node.content.trim() !== '') {
            result += node.content;
          }

          if (node.children && node.children.length > 0) {
            traverseNodes(node.children);
          }

          if (node.tagName.toLowerCase() !== '#text') {
            result += ` [/${node.tagName}]`;
          }
        }
      }
    };

    traverseNodes(nodes);
    return result;
  }

  /**
   * Creates a text representation for special link nodes
   */
  private createSpecialLinkText(node: RiffMLNode): string {
    const tagType = node.tagName.toLowerCase();
    let prefix = '';
    switch (tagType) {
      case 'tablelink':
        prefix = '📊 Table: ';
        break;
      case 'sessionlink':
        prefix = '🔗 Session: ';
        break;
      case 'workstreamlink':
        prefix = '📋 Workstream: ';
        break;
      case 'unitlink':
        prefix = '📑 Unit: ';
        break;
    }

    const displayText = node.content ||
      node.attributes['name'] ||
      node.attributes['title'] ||
      Object.values(node.attributes)[0] ||
      tagType;

    let attributesStr = '';
    if (Object.keys(node.attributes).length > 0) {
      attributesStr =
        ' (' +
        Object.entries(node.attributes)
          .map(([key, value]) => `${key}=${value}`)
          .join(', ') +
        ')';
    }

    return `${prefix}${displayText}${attributesStr}`;
  }

  /**
   * Returns the content of the last user message in the conversation
   */
  getLastUserMessage(turns: ChatTurn[], dTeamId: string): string {
    for (let i = turns.length - 1; i >= 0; i--) {
      if (turns[i].role === 'user') {
        return turns[i].content;
      }
    }
    return '';
  }

  /**
   * Handle <SetMemory scope="project" scopeId="projectId123">...</SetMemory>
   * Adds a memory item to project or workspace memory
   */
  private async handleSetMemoryTag(
    node: RiffMLNode,
    content: string,
    sessionId: string,
    dirtySessions: Set<string>,
    dirtyProjects: Set<string>,
    dirtyWorkspaces: Set<string>
  ): Promise<string> {
    // Extract scope type: 'project' or 'workspace'
    const scope = (node.attributes['scope'] || 'project').toLowerCase() as 'project' | 'workspace';
    
    // Extract scopeId or use default from current context if not provided
    let scopeId = node.attributes['scopeId'] || '';
    
    // If scopeId is not provided, resolve from current context
    if (!scopeId) {
      if (scope === 'project') {
        scopeId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
      } else if (scope === 'workspace') {
        scopeId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
      }
    }
    
    // Ensure we have a valid scopeId
    if (!scopeId) {
      console.error(`[SetMemory] No valid ${scope} ID found for memory operation`);
      return '';
    }
    
    // Process the content of the node to get the memory item data
    const memoryContent = await this.processChildNodesContent(
      node, 
      content, 
      sessionId,
      dirtySessions,
      dirtyProjects,
      dirtyWorkspaces
    );
    
    try {
      // Parse the content as JSON to create a memory item
      let memoryItem: MemoryItem;
      
      if (memoryContent.trim().startsWith('{')) {
        memoryItem = this.jsonParser.safeParseJson(memoryContent);
      } else {
        console.error('[SetMemory] Memory item content must be a valid JSON object');
        return '';
      }
      
      // Ensure the memory has an ID
      if (!memoryItem.memory_id) {
        memoryItem.memory_id = uuidv4();
      }
      
      // Ensure the memory has a session ID
      memoryItem.session_id = sessionId;
      memoryItem.project_id = this.sharedState.userWorkspaceState$.value?.selectedProject;
      memoryItem.workspace_id = this.sharedState.userWorkspaceState$.value?.selectedWorkspace;

      // Add the memory item to the appropriate scope
      await this.memoryManagerService.addMemoryItem(scope, scopeId, memoryItem);
      
      // Mark the appropriate scope as dirty
      if (scope === 'project') {
        dirtyProjects.add(scopeId);
      } else if (scope === 'workspace') {
        dirtyWorkspaces.add(scopeId);
      }
      
      console.log(`[SetMemory] Successfully added memory item (ID: ${memoryItem.memory_id}) to ${scope} (ID: ${scopeId})`);
    } catch (error) {
      console.error(`[SetMemory] Error adding memory item:`, error);
    }
    
    return '';
  }

  /**
   * Handle <Sleep time="1000"/> 
   * Pauses execution for the specified number of milliseconds
   */
  private async handleSleepTag(node: RiffMLNode): Promise<string> {
    const timeValue = node.attributes['time'] || '200';
    const sleepTime = parseInt(timeValue, 10);
    
    if (!isNaN(sleepTime) && sleepTime > 0) {
      await new Promise(resolve => setTimeout(resolve, sleepTime));
    } else {
      console.warn(`Invalid sleep time value: ${timeValue}, must be a positive integer`);
    }
    
    return '';
  }
}
