/**
 * @file src/app/services/tools/tablelink-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';

/**
 * Tool: tablelink
 * Sets the CurrentSql$ value in WorkspaceState to "Select TOP 300 * from {tableId}"
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerTableLinkTool(service: ToolsService): void {
  (service as any).tablelink = async function(nextVal: string, sessionId: string, riffMLNode: RiffMLNode | null, value:any): Promise<void> {
    if (!riffMLNode) return;
    let tableId = riffMLNode.attributes['tableName'] || riffMLNode.attributes['tablename'] || riffMLNode.attributes['Tablename'] || riffMLNode.attributes['TableName'];
    if (!tableId) return;

    let type = riffMLNode.attributes['type'] ?? 'sql';

    let chartMingus = riffMLNode.attributes['chartMingus'] || '';
    let chartPrompt = riffMLNode.attributes['chartPrompt'] || '';

    if (!type || !type.length)
    {
      type = 'sql';
    }

    tableId = tableId.replaceAll('$', '.').replaceAll('%', ' ');
    const code = riffMLNode.attributes['code'] ?? '';

    let runCode = `SELECT TOP 300 * FROM ${tableId}`;
    if (type == 'sql')
    {
        if (code && code.length)
        {
            runCode = code + '\n' + runCode;
        }

        this.workspaceState.CurrentSql$.next(runCode);
        this.workspaceState.CurrentTable$.next(null);
        
        if (chartMingus && chartMingus.length)
        {
            this.workspaceState.CurrentChartDescription$.next(chartMingus);
        }
        else
        {
            this.workspaceState.CurrentChartDescription$.next('');
        }

        if (chartPrompt && chartPrompt.length)  
        {
            this.workspaceState.CurrentChartDetailedDescription$.next(chartPrompt);
        }
        else
        {
            this.workspaceState.CurrentChartDetailedDescription$.next('');
        }
    
        this.runCurrentSql(nextVal, sessionId, riffMLNode);
    }
    else if (type == 'storedSql')
    {
        // Get SQL from state manager using the session and table ID
        const storedSql = this.stateManagerService.getObject(sessionId, tableId);
        
        if (storedSql) {
            // Use the stored SQL from state
            this.workspaceState.CurrentSql$.next(storedSql);
            this.workspaceState.CurrentTable$.next(null);
            
            // Run the SQL query
            this.runCurrentSql(nextVal, sessionId, riffMLNode);
        } else {
            // Handle case when no SQL is found in state
            console.error('No SQL found in state for tableId:', tableId);
            this.workspaceState.CurrentTable$.next({
                error: true,
                message: 'No SQL found in state for the specified table'
            });
        }
    }
    else if (type=='python')
    {
      runCode = 'CALL ' + tableId  + '();';

      this.workspaceState.CurrentSql$.next(code);
      this.workspaceState.CurrentTable$.next(null);

      try {
        // Subscribe to the observable and store the result in CurrentTable$
        // Remove generic type parameter from method call
        await this.snowflakeService.executeCommandObservable(code).subscribe({
          next: (response: any) => {
            if (response && response.Success) {
              console.log('SQL command executed successfully:', response);

              this.workspaceState.CurrentSql$.next(runCode);
              this.workspaceState.CurrentTable$.next(null);
              this.runCurrentSql(nextVal, sessionId, riffMLNode);

            } else {
              console.error('SQL command failed:', response.ErrorMessage);
              // Set the table to null or an error state object
              this.workspaceState.CurrentTable$.next({
                error: true,
                message: response.ErrorMessage || 'code setup failed'
              });
            }
          },
          error: (error: Error) => {
            console.error('Error code setup command:', error);
            this.workspaceState.CurrentTable$.next({
              error: true,
              message: error.message || 'code setup failed'
            });
          }
        });
      } catch (error: unknown) {
        console.error('Exception during code setup:', error);
        this.workspaceState.CurrentTable$.next({
          error: true,
          message: error instanceof Error ? error.message : 'Unknown error during code setup'
        });
      }
    }
    
   
  };
}