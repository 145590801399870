/**
 * @file src/app/services/tools/run-current-sql-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';
import { SqlResponse } from '../../models/sql.model';


/**
 * Tool: runCurrentSql
 * Executes the current SQL query from WorkspaceState.CurrentSql$
 * and stores the result in WorkspaceState.CurrentTable$
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerRunCurrentSqlTool(service: ToolsService): void {
  (service as any).runCurrentSql = async function(nextVal: string, sessionId: string, riffMLNode: RiffMLNode | null, value:any): Promise<void> {
    const currentSql = this.workspaceState.CurrentSql$.getValue();
    
    if (!currentSql || currentSql.trim() === '') {
      console.warn('No SQL query to execute');
      return;
    }
    
    console.log("Running SQL: ", currentSql);
    
    try {
      // Subscribe to the observable and store the result in CurrentTable$
      // Remove generic type parameter from method call
      this.snowflakeService.executeQueryObservable(currentSql).subscribe({
        next: (response: SqlResponse) => {
          if (response.Success) {
            console.log('SQL query executed successfully:', response);
            this.workspaceState.CurrentTable$.next(response.Data);
          } else {
            console.error('SQL query failed:', response.ErrorMessage);
            // Set the table to null or an error state object
            this.workspaceState.CurrentTable$.next({
              error: true,
              message: response.ErrorMessage || 'Query execution failed'
            });
          }
        },
        error: (error: Error) => {
          console.error('Error executing SQL query:', error);
          this.workspaceState.CurrentTable$.next({
            error: true,
            message: error.message || 'Query execution error'
          });
        }
      });
    } catch (error: unknown) {
      console.error('Exception during SQL query execution:', error);
      this.workspaceState.CurrentTable$.next({
        error: true,
        message: error instanceof Error ? error.message : 'Unknown error during query execution'
      });
    }
  };
}