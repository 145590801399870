<!-- src/app/components/sessions-grid/sessions-grid.component.html -->

<div class="sessions-grid">
  
  <div class="control-panel">
    <div class="control-buttons">
      <!-- New Task button (more compact) -->
      <button (click)="toggleLibraryMenu()" class="new-task-button compact" title="Create New Task">
        <div class="icon-circle compact">
          <svg class="plus-icon compact" viewBox="0 0 24 24">
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
          </svg>
        </div>
      </button>
      
      <button (click)="onFilter('viewStatus', 'visible')" class="control-button">
        <svg class="mini-icon" viewBox="0 0 24 24">
          <path d="M24 5a1 1 0 0 1-1 1H1a1 1 0 1 1 0-2h22a1 1 0 0 1 1 1Zm-4 7H4a1 1 0 1 0 0 2h16a1 1 0 0 1 0-2Zm-3 8H7a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2Z"></path>
        </svg>
        Filter
      </button>
      <button (click)="onSort('name')" class="control-button">
        <svg class="mini-icon" viewBox="0 0 24 24">
          <path d="M17.999 5v13h3a1 1 0 0 1 .707 1.707l-4 4a.997.997 0 0 1-1.414 0l-4-4A1 1 0 0 1 12.999 18h3V5a1 1 0 1 1 2 0Zm-15.924.383A1 1 0 0 0 2.999 6h3v13a1 1 0 1 0 2 0V6h3a1 1 0 0 0 .707-1.707l-4-4a.999.999 0 0 0-1.414 0l-4 4a1 1 0 0 0-.217 1.09Z"></path>
        </svg>
        Sort
      </button>
      <button (click)="onGroup('session')" class="control-button">
        <svg class="mini-icon" viewBox="0 0 24 24">
          <path d="M5.454 22h13.09A3.455 3.455 0 0 0 22 18.545V5.455A3.455 3.455 0 0 0 18.544 2H5.454A3.455 3.455 0 0 0 2 5.455v13.091A3.454 3.454 0 0 0 5.454 22ZM4 5.455C4 4.653 4.651 4 5.454 4h13.091C19.348 4 20 4.652 20 5.455v13.092c0 .802-.652 1.455-1.455 1.455H5.455a1.456 1.456 0 0 1-1.456-1.455V5.455ZM6.5 9a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 6.5 9Zm5 0a1 1 0 0 1 1-1H17a1 1 0 1 1 0 2h-4.5a1 1 0 0 1-1-1Zm-5 6a1.5 1.5 0 1 1 3.001.001 1.5 1.5 0 0 1-3-.001Zm5 0a1 1 0 0 1 1-1H17a1 1 0 1 1 0 2h-4.5a1 1 0 0 1-1-1Z"></path>
        </svg>
        Group
      </button>
    </div>
  </div>

  <!-- Loop over grouped sessions -->
  <ng-container *ngFor="let group of groupedSessions | keyvalue">
    <div *ngIf="state.groupBy" class="group-header">
      {{ group.key }}
    </div>

    <table class="sessions-table">
      <tbody>
        <tr
          *ngFor="let session of group.value"
          [class.session-row]="isProjectJammerSession(session)"
          [class.not-session-row]="!isProjectJammerSession(session)"
          [class.completed-row]="session.completionStatus === 'done'"
          [class.selected]="session.id === selectedSessionId"
          [class.hidden]="session.viewStatus === 'hidden'"
          [style.opacity]="session.completionStatus === 'new' ? 0.9 : 1"
          (click)="onSessionSelect(session)"
        >
          <td class="name-cell" id="{{ session.id }}">
            <div class="name-content">{{ session.name }}</div>
            <div class="participants-list">
              <div class="participant-icon" *ngFor="let participant of session.participants">
                <div class="initials-icon">
                  {{ getInitials(participant.name) }}
                </div>
              </div>
              <span class="todo-label" *ngIf="session.completionStatus === 'new'">
                todo
              </span>
            </div>
          </td>
          <td class="session-id-cell">
            <span class="session-id-label">{{ session.session }}</span>
          </td>
          <td class="actions-cell">
            <button class="hide-button" (click)="onHideSession(session)">
              <svg class="hide-icon" viewBox="0 0 24 24">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </button>
            <button class="hide-button" (click)="openContextMenu($event, session)">
              i
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>

<!-- The context popup container -->
<div
  class="context-popup-container"
  *ngIf="showContextPopup"
  #contextPopupRef
  [style.left.px]="contextPopupX"
  [style.top.px]="contextPopupY"
>
  <app-context-viewer
    [stateItems]="contextStateItems"
    (itemSelected)="onContextItemSelected($event)"
  ></app-context-viewer>
</div>
