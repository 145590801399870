import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChatService } from '../../../services/chat.service';
import { SessionsService } from '../../../services/sessions.service';
import { UserService } from '../../../services/user.service';
import { ChatMessage, ChatSession, MessageRole } from '../../../models/chat.models';
import { Project, Phase } from '../../../models/session.interface';
import { RecommendedFlow } from '../../../models/recommended-flow';
import { Observable, BehaviorSubject, map, tap } from 'rxjs';
import { MessageComponent } from '../message/message.component';
import InputBoxComponent from '../input-box/input-box.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MessageComponent,
    InputBoxComponent
  ]
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() sessionId!: string;
  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;
  
  messages: ChatMessage[] = [];
  messages$ = new BehaviorSubject<ChatMessage[]>([]);
  recommendedFlows$ = this.chatService.recommendedFlows$;
  session: ChatSession | null = null;
  hasMessages: boolean = false;
  isProcessing$: Observable<boolean>;
  projects$: Observable<Project[]>;
  phases$: Observable<Phase[]>;
  selectedProjectId: string | null = null;
  selectedPhaseId: string | null = null;
  private processingSubject = new BehaviorSubject<boolean>(false);
  private phasesSubject = new BehaviorSubject<Phase[]>([]);
  private shouldScrollToBottom = false;
  private userId: string | null = null;
  isLoading = true;
  
  constructor(
    private chatService: ChatService,
    private sessionsService: SessionsService,
    private userService: UserService
  ) {
    this.isProcessing$ = this.processingSubject.asObservable();
    this.projects$ = this.sessionsService.getProjects();
    this.phases$ = this.phasesSubject.asObservable();
  }

  async ngOnInit() {
    console.log('Chat component initializing...');
    this.isLoading = true;
    const user = await this.userService.getCurrentUser();
    this.userId = user?.email || null;
    
    if (!this.userId) {
      console.error('No user ID available');
      this.isLoading = false;
      return;
    }

    // Get the current tab's reference
    const tabRef = this.sessionsService.getTabReference(this.sessionId);
    console.log('Initial tab reference:', tabRef);
    
    if (tabRef) {
      this.selectedProjectId = tabRef.projectId;
      this.selectedPhaseId = tabRef.phaseId;
      
      // Initialize recommended flows
      console.log('Initializing flows with project:', tabRef.projectId, 'phase:', tabRef.phaseId);
      this.chatService.updateContextReferences(tabRef.projectId, tabRef.phaseId);
      
      // Load phases for the current project
      await this.loadPhases(tabRef.color);
    }

    if (this.sessionId) {
      const sessions = await this.sessionsService.getSessions(this.userId);
      this.session = sessions.find(s => s.id === this.sessionId) || null;
      
      if (this.session) {
        const messages = await this.chatService.getMessages(this.sessionId, this.userId);
        this.messages = messages;
        this.hasMessages = messages.length > 0;
        this.shouldScrollToBottom = true;
      }

      // Subscribe to new messages
      this.chatService.onMessage((message) => {
        if (message.sessionId === this.sessionId) {
          this.messages = [...this.messages, message];
          this.hasMessages = true;
          this.shouldScrollToBottom = true;
        }
      });
    }
    
    this.isLoading = false;
  }

  ngAfterViewChecked() {
    if (this.shouldScrollToBottom) {
      this.scrollToBottom();
      this.shouldScrollToBottom = false;
    }
  }

  async onMessageSent(content: string): Promise<void> {
    if (!this.sessionId || !this.userId) return;

    this.processingSubject.next(true);
    this.shouldScrollToBottom = true;
    
    try {
      // If this is the first message, update the session title
      if (!this.hasMessages) {
        await this.sessionsService.updateSessionTitleOnFirstMessage(this.sessionId, content);
      }

      await this.chatService.sendMessage(
        this.sessionId,
        content,
        this.userId
      );
      this.processingSubject.next(false);
    } catch (error) {
      console.error('Error sending message:', error);
      this.processingSubject.next(false);
    }
  }

  onEditMessage(message: ChatMessage): void {
    // TODO: Implement edit functionality
    console.log('Edit message:', message);
  }

  onCopyMessage(message: ChatMessage): void {
    if (message.content) {
      navigator.clipboard.writeText(message.content);
    }
  }

  onReadMessage(message: ChatMessage): void {
    if (message.content) {
      const utterance = new SpeechSynthesisUtterance(message.content);
      window.speechSynthesis.speak(utterance);
    }
  }

  onLikeMessage(message: ChatMessage): void {
    // TODO: Implement like functionality through metadata
    console.log('Like message:', message);
  }

  onDislikeMessage(message: ChatMessage): void {
    // TODO: Implement dislike functionality through metadata
    console.log('Dislike message:', message);
  }

  onFlowSelect(flow: RecommendedFlow) {
    if (!this.sessionId || !this.userId) return;
    
    // Send the flow name as a message
    this.onMessageSent(flow.name);
  }

  private async loadPhases(projectColor: string): Promise<void> {
    try {
      const phases = await this.sessionsService.getPhases(projectColor);
      this.phasesSubject.next(phases);
    } catch (error) {
      console.error('Error loading phases:', error);
      this.phasesSubject.next([]);
    }
  }

  onProjectChange(event: any) {
    console.log('Project changed:', event.value);
    this.projects$.pipe(
      map(projects => projects.find(p => p.id === event.value))
    ).subscribe(async project => {
      if (project) {
        console.log('Selected project:', project);
        this.selectedProjectId = project.id;
        this.selectedPhaseId = null; // Reset phase when project changes
        
        // Update tab reference with new project
        this.sessionsService.setTabReference(
          this.sessionId,
          project.id,
          '',  // Reset phase ID
          project.color
        );
        
        // Update recommended flows
        console.log('Updating flows for project:', project.id);
        this.chatService.updateContextReferences(project.id, undefined);
        
        // Load phases for the new project
        await this.loadPhases(project.color);
      }
    });
  }

  onPhaseChange(event: any) {
    const phaseId = event.value;
    console.log('Phase changed:', phaseId);
    this.selectedPhaseId = phaseId;
    
    // Get current tab reference to preserve project info
    const tabRef = this.sessionsService.getTabReference(this.sessionId);
    if (tabRef) {
      console.log('Current tab reference:', tabRef);
      this.sessionsService.setTabReference(
        this.sessionId,
        tabRef.projectId,
        phaseId,
        tabRef.color
      );

      // Update recommended flows
      console.log('Updating flows for project:', tabRef.projectId, 'phase:', phaseId);
      this.chatService.updateContextReferences(tabRef.projectId, phaseId);
    }
  }

  private scrollToBottom(): void {
    if (this.messagesContainer) {
      const element = this.messagesContainer.nativeElement;
      element.scrollTop = element.scrollHeight;
    }
  }

  ngOnDestroy(): void {
    this.processingSubject.complete();
  }
}