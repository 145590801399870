/**
 * @file src/app/services/tools/run-playbook-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';

/**
 * Tool: runPlaybook
 * Runs a specified playbook with configurable parameters
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerRunPlaybookTool(service: ToolsService): void {
  (service as any).runPlaybook = async function(next: string, sessionId: string, riffMLNode: RiffMLNode | null, value: any): Promise<void> {
    console.log(`ToolsService.runPlaybook() called with next=${next}`);
    
    // Ensure required parameters are present
    if (!riffMLNode || !riffMLNode.attributes['playbook']) {
      console.error('Required parameter "playbook" is missing');
      return;
    }

    // Get the playbook ID
    const playbook = riffMLNode.attributes['playbook'];

    const storeTarget = riffMLNode.attributes['storeTarget'] ?? null;
    
    // Get optional parameters with default values
    const setNextPlaybookId = riffMLNode.attributes['setNextPlaybookId'] === 'true' || false;
    const shouldWait = riffMLNode.attributes['shouldWait'] !== 'false'; // Default to true
    
    // Get the process turn handler
    const processTurnHandler = this.getProcessTurnHandler();
    if (!processTurnHandler) {
      console.error('No ProcessTurnHandler registered to handle playbook:', playbook);
      return;
    }
    
    console.log(`Running playbook: ${playbook}, setNextPlaybookId: ${setNextPlaybookId}, shouldWait: ${shouldWait}`);
    
    if (shouldWait) {
      // Run the playbook and wait for it to complete
      await processTurnHandler.runPlaybook(sessionId, playbook, setNextPlaybookId, storeTarget);
      console.log(`Playbook ${playbook} completed`);
    } else {
      // Run the playbook in the background without waiting
      processTurnHandler.runPlaybook(sessionId, playbook, setNextPlaybookId, storeTarget)
        .then(() => console.log(`Background playbook ${playbook} completed`))
        .catch((err: Error) => console.error(`Error in background playbook ${playbook}:`, err));
      
      console.log(`Started playbook ${playbook} in the background`);
    }
  };
} 