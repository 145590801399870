<!-- 
  @file src/app/components/codeview/codeview.component.html
  Simple SQL editor with a run button in the top right corner.
-->
<div class="codeview-container">
  <div class="code-header">
    <ng-container *ngIf="isSqlValid(); else emptyState">
      <span class="code-type-label">{{ currentCodeType.toUpperCase() }}</span>
      <button class="run-button" (click)="runSql()" [disabled]="!isSqlValid()">
        <i class="fas fa-play"></i>
      </button>
    </ng-container>
    <ng-template #emptyState>
      <span class="code-type-label">Code Pane</span>
    </ng-template>
  </div>
  <div class="code-content">
    <ngx-codejar
      [(code)]="sqlCode"
      [options]="codeJarOptions"
      [highlightMethod]="highlight"
      [showLineNumbers]="false"
      (update)="onCodeUpdate($event)"
      class="code-editor"
    ></ngx-codejar>
  </div>
</div>