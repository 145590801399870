import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RecordsGroup, RecordGroupList } from '../models/record-management.interface';
import { MockRecordManagementServer } from '../mocks/record-management.mock';

@Injectable({
  providedIn: 'root'
})
export class RecordManagementService {
  private mockServer = new MockRecordManagementServer();
  private recordGroupLists = new BehaviorSubject<RecordGroupList[]>([]);

  constructor() {
    this.loadRecordGroupLists();
  }

  private async loadRecordGroupLists(): Promise<void> {
    const lists = await this.mockServer.getRecordGroupLists();
    this.recordGroupLists.next(lists);
  }

  getRecordGroupLists(): Observable<RecordGroupList[]> {
    return this.recordGroupLists.asObservable();
  }

  async toggleGroupPin(groupId: string): Promise<void> {
    await this.mockServer.toggleGroupPin(groupId);
    await this.loadRecordGroupLists();
  }

  async toggleGroupExpansion(groupId: string): Promise<void> {
    await this.mockServer.toggleGroupExpansion(groupId);
    await this.loadRecordGroupLists();
  }

  getDataCollectionStage(): RecordGroupList {
    return this.mockServer.getDataCollectionStage();
  }

  getProcessingStage(): RecordGroupList {
    return this.mockServer.getProcessingStage();
  }

  getModelingStage(): RecordGroupList {
    return this.mockServer.getModelingStage();
  }

  getProductionStage(): RecordGroupList {
    return this.mockServer.getProductionStage();
  }
}
