import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { 
  AICompletionRequest, 
  AICompletionResponse, 
  AIModel 
} from '../models/bedrock.model';

@Injectable({
  providedIn: 'root'
})
export class BedrockService {
  private readonly bedrockApiUrl =  `${environment.artifactsApiUrl}/bedrock/invoke`;
  private responseCache = new Map<string, AICompletionResponse>();

  constructor(private http: HttpClient) {}

  /**
   * Sends a completion request to the Bedrock API
   * @param request The completion request parameters
   * @param bypassCache Optional flag to force a server request, bypassing cache
   * @returns Promise containing the completion response
   */
  async getCompletion(
    request: AICompletionRequest,
    bypassCache: boolean = false
  ): Promise<AICompletionResponse> {
    //console.log('[BedrockService] Sending completion request: ' + request.details, request);

    // Generate cache key based on request parameters
    const cacheKey = this.generateCacheKey(request);

    // Check cache first if not bypassing
    if (!bypassCache && this.responseCache.has(cacheKey)) {
      console.log('[BedrockService] Retrieved response from cache');
      return this.responseCache.get(cacheKey)!;
    }

    try {
      const response = await firstValueFrom(
        this.http.post<AICompletionResponse>(this.bedrockApiUrl, request)
      );

      // Cache the response
      //this.responseCache.set(cacheKey, response); avoid for now
      //console.log('[BedrockService] PROMPT+RESULT for: ', response);
      
      return response;

    } catch (error) {
      console.error('[BedrockService] Error getting completion:', error);
      throw error;
    }
  }

  /**
   * Sends a completion request to the Bedrock API as an Observable
   * @param request The completion request parameters
   * @param bypassCache Optional flag to force a server request, bypassing cache
   * @returns Observable of the completion response
   */
  getCompletionObservable(
    request: AICompletionRequest,
    bypassCache: boolean = false
  ): Observable<AICompletionResponse> {
    const cacheKey = this.generateCacheKey(request);

    // Check cache first if not bypassing
    if (!bypassCache && this.responseCache.has(cacheKey)) {
      console.log('[BedrockService] Retrieved response from cache (Observable)');
      return of(this.responseCache.get(cacheKey)!);
    }

    return this.http.post<AICompletionResponse>(this.bedrockApiUrl, request);
  }

  /**
   * Generates a simple completion with default parameters
   * @param prompt The prompt text
   * @param model Optional AI model to use (defaults to SONNET)
   * @returns Promise containing the completion response
   */
  async generateSimpleCompletion(
    prompt: string,
    model: AIModel = AIModel.SONNET
  ): Promise<AICompletionResponse> {
    const request: AICompletionRequest = {
      prompt,
      model,
      maxTokens: 8000,
      temperature: 0.7
    };

    return this.getCompletion(request);
  }

  /**
   * Clears the entire response cache
   */
  clearCache(): void {
    this.responseCache.clear();
    console.log('[BedrockService] Response cache cleared');
  }

  /**
   * Removes a specific response from the cache
   * @param request The request parameters to remove from cache
   */
  removeFromCache(request: AICompletionRequest): void {
    const cacheKey = this.generateCacheKey(request);
    this.responseCache.delete(cacheKey);
    console.log('[BedrockService] Response removed from cache');
  }

  /**
   * Generates a cache key based on request parameters
   * @param request The request parameters
   * @returns A string key for caching
   */
  private generateCacheKey(request: AICompletionRequest): string {
    return JSON.stringify({
      prompt: request.prompt,
      model: request.model,
      maxTokens: request.maxTokens,
      temperature: request.temperature
    });
  }
}
