/**
 * @file src/app/services/tools.service.ts
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StateManagerService } from './state-manager.service';
import { ChangeManagerService } from './change-manager.service';
import { WorkspaceState } from '../models/workspace-state.model';
import { RiffMLNode } from './riffml-parser.service';
import { SnowflakeService } from './snowflake.service';
import { registerTableLinkTool } from './tools/tablelink-tool';
import { registerRunCurrentSqlTool } from './tools/run-current-sql-tool';
import { registerAcceptSuggestedWorkspaceComponentTool } from './tools/accept-suggested-workspace-component-tool';
import { registerAcceptWorkboardChangesTool } from './tools/accept-workboard-changes';
import { registerRunApiTool } from './tools/runapi-tool';
import { registerRunBigQueryPreviewTool } from './tools/runBigQueryPreview-tool';
import { registerRunPlaybookTool } from './tools/run-playbook-tool';
import { registerGenerateChartTool } from './tools/generate-chart-tool';
import { WorkboardManagerService } from './workboard-manager.service';
import { PromptLayerService } from './prompt-layer.service';
import { registerSessionLinkTool } from './tools/session-link';
import { SessionsManagerService } from './sessions-manager.service';
import { registerWorkstreamLinkTool } from './tools/workstreamlink-tool';
import { registerRunSqlTool } from './tools/runSql-tool';
import { registerEditWorkstreamTool } from './tools/editWorkstream-tool';
import { registerUserChangedWorkstreamTool } from './tools/userChangedWorkstream-tool';
import { registerAddNotificationTool } from './tools/add-notification-tool';
import { ProcessTurnInterface } from '../models/process-turn.interface';
import { NotificationsService } from './notifications.service';
import { JsonParserService } from "./jsonParserService.service";
import { v4 as uuidv4 } from 'uuid';
import { ChatTurn, MessageRole } from '../models/chat.model';
import { WorkspaceManagerService } from './workspace-manager.service';


/**
 * This is the ToolsService.
 * It provides a method callTool(toolName, nextVal)
 * that tries to call a function with the same name as toolName.
 *
 * Tools are defined in separate files in the tools/ directory and
 * attached to this service at runtime.
 */
@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  constructor(
    private stateManagerService: StateManagerService,
    private changeManagerService: ChangeManagerService,
    private workspaceState: WorkspaceState,
    private snowflakeService: SnowflakeService,
    private workboardManagerService: WorkboardManagerService,
    private httpClient: HttpClient,
    private sessionsManagerService: SessionsManagerService,
    public notificationsService: NotificationsService,
    private jsonParserService: JsonParserService,
    private workspaceManagerService: WorkspaceManagerService
  ) {
    // Register all tools by passing 'this' as context
    registerTableLinkTool(this);
    registerRunCurrentSqlTool(this);
    registerAcceptSuggestedWorkspaceComponentTool(this);
    registerAcceptWorkboardChangesTool(this);
    registerRunApiTool(this);
    registerRunBigQueryPreviewTool(this);
    registerSessionLinkTool(this);
    registerWorkstreamLinkTool(this);
    registerRunSqlTool(this);
    registerEditWorkstreamTool(this);
    registerUserChangedWorkstreamTool(this);
    registerRunPlaybookTool(this);
    registerAddNotificationTool(this);
    registerGenerateChartTool(this);
  }

  private processTurnHandler: ProcessTurnInterface | null = null;

  /**
   * Sets the ProcessTurnInterface implementation that tools will use
   * @param handler An implementation of the ProcessTurnInterface
   */
  public setProcessTurnHandler(handler: ProcessTurnInterface): void {
    this.processTurnHandler = handler;
  }

  /**
   * Gets the current ProcessTurnInterface implementation
   * @returns The current ProcessTurnInterface implementation or null
   */
  public getProcessTurnHandler(): ProcessTurnInterface | null {
    return this.processTurnHandler;
  }
  
  /**
   * Dynamically calls a tool function by name.
   * If no matching function is found, do nothing or log an error.
   */
  public async callTool(toolName: string, nextVal: string, sessionId: string, riffMLNode: RiffMLNode | null, value: any=null): Promise<void> {
    // Store a reference to 'this' that won't be lost in async contexts
    const self = this;

    toolName = toolName.replaceAll('"', '').replaceAll("\\", ''); // Remove path
    const func = (this as any)[toolName];
    if (typeof func === 'function') {
      if(riffMLNode?.attributes?.['processingmessage']) {
        // Add processing message to the chat session
        const chatSessionResult = this.workspaceState.tryGetChatSession(sessionId);
        if (chatSessionResult && chatSessionResult.value) {
          const chatSession = chatSessionResult.value;
          const procMessage: ChatTurn = {
            id: uuidv4(),
            sessionId,
            content: riffMLNode.attributes['processingmessage'],
            role: MessageRole.Process,
            senderId: 'system',
            timestamp: new Date(),
            artifacts: [],
            turnMetadata: {
              turnNumber: chatSession.turns.length + 1,
              processingTime: 0,
              turnData: { messageNode: riffMLNode }
            }
          };
          chatSession.turns.push(procMessage);
          await this.workspaceManagerService.saveChatSessionData(sessionId);
        }
      }

      await func.call(this, nextVal, sessionId, riffMLNode, value);
    } else {
      console.warn(`ToolsService: No tool function found named "${toolName}"`);
    }
  }

  public async setState(sessionId: string, storeTarget: string, storeTargetId: string, storeTargetName: string, storeTargetDescription: string, storeTargetWhyRelevant: string, storeTargetType: string, value: any) {
    try {
      let rootId = null;
      rootId = sessionId;

      if (storeTarget.startsWith('project.') || storeTarget.startsWith('workspace.')) {
        rootId = null;
      }
   
      // Store the result in the state manager
      await this.stateManagerService.setState(
        rootId,
        storeTarget,
        storeTargetId,
        storeTargetName,
        storeTargetDescription,
        storeTargetWhyRelevant,
        storeTargetType,
        value
      );
    } catch (error) {
      console.error('[ToolsService] Error calling API:', error);
    }
  }

  public async addToState(sessionId: string, typedKey: string, value: any) {
    try {
      let rootId = null;
      rootId = sessionId;

      if (typedKey.startsWith('project.') || typedKey.startsWith('workspace.')) {
        rootId = null;
      }

      // Store the result in the state manager
      await this.stateManagerService.addToState(
        rootId,
        typedKey,
        value
      );
    } catch (error) {
      console.error('[ToolsService] Error calling API:', error);
    }
  }
}