// src/app/components/session/message/message.component.ts
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatMessage } from '../../../models/chat.models';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class MessageComponent {
  @Input() message!: ChatMessage;
  @Output() edit = new EventEmitter<void>();
  @Output() copy = new EventEmitter<void>();
  @Output() read = new EventEmitter<void>();
  @Output() like = new EventEmitter<void>();
  @Output() dislike = new EventEmitter<void>();
  
  isHovered = false;

  onCopy(): void {
    if (this.message.content) {
      navigator.clipboard.writeText(this.message.content);
      this.copy.emit();
    }
  }

  onRead(): void {
    if (this.message.content) {
      const utterance = new SpeechSynthesisUtterance(this.message.content);
      window.speechSynthesis.speak(utterance);
      this.read.emit();
    }
  }

  onEdit(): void {
    this.edit.emit();
  }

  onLike(): void {
    this.like.emit();
  }

  onDislike(): void {
    this.dislike.emit();
  }
}