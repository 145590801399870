import { Injectable } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuTriggerService {
  private megaMenuTriggerSubject = new BehaviorSubject<MatMenuTrigger | null>(null);
  public megaMenuTrigger$ = this.megaMenuTriggerSubject.asObservable();

  constructor() {}

  registerTrigger(trigger: MatMenuTrigger) {
    this.megaMenuTriggerSubject.next(trigger);
  }

  setMegaMenuTrigger(trigger: MatMenuTrigger) {
    this.megaMenuTriggerSubject.next(trigger);
  }

  openMegaMenu() {
    const trigger = this.megaMenuTriggerSubject.value;
    if (trigger) {
      trigger.openMenu();
    } else {
      console.warn('Mega menu trigger not set');
    }
  }

  closeMegaMenu() {
    const trigger = this.megaMenuTriggerSubject.value;
    if (trigger) {
      trigger.closeMenu();
    }
  }
}
