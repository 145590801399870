import { Component } from '@angular/core';

@Component({
  selector: 'app-spaces',
  standalone: true,
  template: `
    <div class="spaces">
      <h2>Spaces</h2>
      <p>Your workspaces and projects</p>
    </div>
  `,
  styles: [`
    .spaces {
      padding: 2rem;
    }
  `]
})
export class SpacesComponent {} 