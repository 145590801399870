import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PromptLayerService } from './prompt-layer.service';

export interface MenuItem {
  id: string;
  name: string;
  playbookId?: string;
  firstMessage?: string;
  svg?: string;
  iconName?: string;
}

export interface Category {
  name: string;
  items: MenuItem[];
}

export interface MenuData {
  menuDescription: string;
  categories: Category[];
}

export interface SolutionComponent {
  type: string;
  name: string;
  description: string;
}

export interface Solution {
  id: string;
  name: string;
  goal: string;
  description: string;
  svg?: string;
  iconurl?: string;
  previewurl?: string;
  components: SolutionComponent[];
  keyApproaches: string[];
}

export interface SolutionCategory {
  id: string;
  name: string;
  emoji: string;
  solutions: Solution[];
}

export interface SolutionData {
  solutionDescription: string;
  categories: SolutionCategory[];
}

@Injectable({
  providedIn: 'root'
})
export class LibraryMenuService {
  private menuDataSubject = new BehaviorSubject<MenuData | null>(null);
  public menuData$ = this.menuDataSubject.asObservable();

  private solutionDataSubject = new BehaviorSubject<SolutionData | null>(null);
  public solutionData$ = this.solutionDataSubject.asObservable();

  constructor(private promptLayerService: PromptLayerService) {
    this.initializeMenuData();
    this.initializeSolutionData();
  }

  private async initializeMenuData() {
    try {
      const template = await this.promptLayerService.getTemplate('47974');
      console.log('Raw template from PromptLayer:', template);
      
      if (template?.PromptTemplate?.Content?.[0]?.Text) {
        const menuData = JSON.parse(template.PromptTemplate.Content[0].Text);
        console.log('Parsed menu data:', menuData);
        this.menuDataSubject.next(menuData);
      } else {
        console.error('Invalid menu data structure:', template);
      }
    } catch (error) {
      console.error('Error loading menu data:', error);
    }
  }

  private async initializeSolutionData() {
    try {
      const template = await this.promptLayerService.getTemplate('48853');
      console.log('Raw solution template from PromptLayer:', template);
      
      if (template?.PromptTemplate?.Content?.[0]?.Text) {
        const solutionData = JSON.parse(template.PromptTemplate.Content[0].Text);
        console.log('Parsed solution data:', solutionData);
        this.solutionDataSubject.next(solutionData);
      } else {
        console.error('Invalid solution data structure:', template);
      }
    } catch (error) {
      console.error('Error loading solution data:', error);
    }
  }

  getMenuData(): MenuData | null {
    return this.menuDataSubject.value;
  }

  getSolutionData(): SolutionData | null {
    return this.solutionDataSubject.value;
  }
} 