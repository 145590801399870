// src/app/components/session/message/message.component.ts

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ChatTurn } from '../../../models/chat.model';
import { Artifact } from '../../../models/artifact.model';
import {
  ProjectManagerSessionMessage,
  ProjectManagerWorkstream,
  ProjectManagerWorkboardGroup,
  ProjectManagerWorkstreamColumn,
  SuggestedSession,
  SuggestedNewSession
} from '../../../models/project-manager-chat.model';
import { RiffMLParserService, RiffMLNode } from '../../../services/riffml-parser.service';
import { StateManagerService } from '../../../services/state-manager.service';
import { ToolsService } from '../../../services/tools.service';
import { ChatService } from '../../../services/chat.service';

/** NEW IMPORT: Our new SelectListComponent */
import { SelectListComponent } from './select-list/select-list.component';

interface ProcessedPart {
  content: string;
  artifactTitle?: string;
  remainingContent?: string;
  nodeType?:
    | 'WorkstreamsList'
    | 'SessionsList'
    | 'Button'
    | 'specialLink'
    | 'SelectFromList';
  tooltip?: string;
  tool?: string;
  nextPlaybook?: string;
  linkIcon?: string;
  linkClass?: string;
  riffMLNode: RiffMLNode | null;
  hidden?: boolean; // <-- Added so we can dynamically hide after click
  clicked?: boolean;
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,

    // NEW: Import the SelectListComponent so we can use <app-select-list>
    SelectListComponent
  ]
})
export class MessageComponent implements OnInit {
  @Input() message!: ChatTurn;
  @Input() sessionId!: string;
  @Input() showDebugButton = false;
  @Input() isLastMessage = false;

  @Output() edit = new EventEmitter<void>();
  @Output() copy = new EventEmitter<void>();
  @Output() read = new EventEmitter<void>();
  @Output() like = new EventEmitter<void>();
  @Output() dislike = new EventEmitter<void>();
  @Output() enlargeArtifact = new EventEmitter<Artifact>();
  @Output() debug = new EventEmitter<void>();

  @Output() acceptWorkstream = new EventEmitter<{
    messageId: string;
    sessionId: string;
    workstream: ProjectManagerWorkstream;
    group: ProjectManagerWorkboardGroup;
    col: ProjectManagerWorkstreamColumn;
  }>();
  @Output() rejectWorkstream = new EventEmitter<{
    messageId: string;
    sessionId: string;
    workstream: ProjectManagerWorkstream;
    group: ProjectManagerWorkboardGroup;
    col: ProjectManagerWorkstreamColumn;
  }>();
  @Output() openExistingSession = new EventEmitter<{
    messageId: string;
    sessionId: string;
    session: SuggestedSession;
  }>();
  @Output() acceptNewSession = new EventEmitter<{
    messageId: string;
    sessionId: string;
    session: SuggestedNewSession;
  }>();
  @Output() rejectNewSession = new EventEmitter<{
    messageId: string;
    sessionId: string;
    session: SuggestedNewSession;
  }>();

  /**
   * NEW OUTPUT: Emitted when a user clicks on an item in the SelectFromList
   * so that ChatComponent can update or send the input text.
   */
  @Output() selectListItemClicked = new EventEmitter<{
    text: string;
    multiple: boolean;
    sendOnClick: boolean;
    item: any;
  }>();

  processedParts: ProcessedPart[] = [];
  artifactPreviews: { [key: string]: string } = {};

  projectManagerData?: ProjectManagerSessionMessage;
  contextData?: any;

  constructor(
    private riffmlParser: RiffMLParserService,
    private stateManager: StateManagerService,
    private toolsService: ToolsService,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.extractProjectManagerData();
    this.processMessageContent();
    
    // If not the last message, apply clicked state to buttons
    if (!this.isLastMessage) {
      this.processedParts.forEach(part => {
        if (part.nodeType === 'Button' && part.riffMLNode?.attributes['clickContent']) {
          part.content = part.riffMLNode.attributes['clickContent'];
          part.clicked = true;
        }
      });
    }
  }

  private extractProjectManagerData() {
    const turnData = this.message.turnMetadata?.turnData;
    if (turnData && turnData.responseData) {
      this.contextData = turnData.responseData;
    }
    if (
      turnData &&
      turnData.responseData &&
      (
        turnData.responseData.suggestedWorkboardNewItems ||
        turnData.responseData.suggestedExistingSessions ||
        turnData.responseData.suggestedNewSessions
      )
    ) {
      this.projectManagerData = turnData.responseData as ProjectManagerSessionMessage;
    }
  }

  private processMessageContent(): void {
    // 1) Build artifact previews
    if (this.message.artifacts && this.message.artifacts.length > 0) {
      for (const artifact of this.message.artifacts) {
        const preview = artifact.content.split('\n').slice(0, 2).join('\n');
        this.artifactPreviews[artifact.title] = preview;
      }
    }

    // 2) Check if content is a string or a RiffMLNode
    if (typeof this.message.content === 'string') {
      // Try parse as RiffML
      if (this.mightContainRiffML(this.message.content)) {
        this.parseRiffMLAndBuildParts(this.message.content);
      } else {
        // no RiffML => handle artifact placeholders
        this.processStringWithArtifactPlaceholders(this.message.content);
      }
    } else if (this.isRiffMLNode(this.message.content)) {
      // Already a RiffML structure
      const nodeArray = Array.isArray(this.message.content) ? this.message.content : [this.message.content];
      this.handleRiffMLNodes(nodeArray);
    } else {
      // fallback: single text part
      this.processedParts.push({ 
        content: this.convertNewlinesToBrTags(String(this.message.content)), 
        riffMLNode: null 
      });
    }
  }

  private flattenChildren(children: RiffMLNode[]): ProcessedPart[] {
    let res: ProcessedPart[] = [];
    children.forEach(ch => {
      res = res.concat(this.convertNodeToParts(ch));
    });
    return res;
  }

  /**
   * Helper method to convert newlines to <br/> tags for HTML display
   */
  private convertNewlinesToBrTags(text: string): string {
    if (!text) return '';
    return text.replace(/\n/g, '<br/>');
  }

  getArtifactByTitle(title: string): Artifact | undefined {
    return this.message.artifacts?.find(a => a.title === title);
  }

  getArtifactPreview(title: string): string {
    return this.artifactPreviews[title] || '';
  }

  getUnplacedArtifacts(): Artifact[] {
    if (!this.message.artifacts) return [];
    return this.message.artifacts.filter(artifact =>
      !this.message.content?.includes?.(`[[${artifact.title}]]`)
    );
  }

  onEnlargeClick(artifact: Artifact) {
    this.enlargeArtifact.emit(artifact);
  }

  onCopy(): void {
    let combined = '';
    for (const part of this.processedParts) {
      combined += part.content + ' ';
    }
    navigator.clipboard.writeText(combined.trim());
    this.copy.emit();
  }

  onRead(): void {
    let combined = '';
    for (const part of this.processedParts) {
      combined += part.content + ' ';
    }
    const utterance = new SpeechSynthesisUtterance(combined.trim());
    window.speechSynthesis.speak(utterance);
    this.read.emit();
  }

  onEdit(): void {
    this.edit.emit();
  }

  onLike(): void {
    this.like.emit();
  }

  onDislike(): void {
    this.dislike.emit();
  }

  onDebug(): void {
    this.debug.emit();
  }

  /**
   * Called when a RiffML <Button> is clicked
   */
  async onRiffButtonClick(part: ProcessedPart) {
    // If node has attribute clickContent, change the label after the click
    const newLabel = part.riffMLNode?.attributes['clickContent'];
    if (newLabel) {
      part.content = newLabel;
    }

    // If node has attribute hideAfter, hide the button after N seconds (>= 0)
    const hideAttr = part.riffMLNode?.attributes['hideAfter'];
    if (hideAttr) {
      const hideAfterNum = parseInt(hideAttr, 10);
      if (!isNaN(hideAfterNum) && hideAfterNum >= 0) {
        setTimeout(() => {
          part.hidden = true;
          part.content = '';
        }, hideAfterNum * 1000);
      }
    }

    // Apply clicked state styles
    part.clicked = true;

    if (newLabel || hideAttr) {
      // After changing label or hiding, call the 'ChangedButtonProperties' tool
      // await this.toolsService.callTool('ChangedButtonProperties', '', this.sessionId, part.riffMLNode);
    }

    if (!part.tool) {
      if (part.nextPlaybook) {
        await this.chatService.runPlaybook(this.sessionId, part.nextPlaybook);
      }
      return;
    }

    // Original logic: call the designated tool
    await this.toolsService.callTool(part.tool, part.nextPlaybook || '', this.sessionId, part.riffMLNode);
    if (part.nextPlaybook) {
      await this.chatService.runPlaybook(this.sessionId, part.nextPlaybook);
    }
  }

  /**
   * Called when the user clicks on <tablelink>, <sessionlink>, <workstreamlink>, or <webpagelink>
   */
  async onRiffLinkClick(part: ProcessedPart) {
    // Handle webpagelink differently
    if (part.linkClass === 'webpage-link') {
      // Extract URL from the content
      const url = part.riffMLNode?.attributes['url'];
      if (url) {
        this.onWebpageLinkClick(url);
      }
      return;
    }

    // Original logic for other link types
    if (!part.tool) return;
    await this.toolsService.callTool(part.tool, '', this.sessionId, part.riffMLNode);
  }

  /**
   * Opens a URL in a popup window with specific dimensions
   */
  async onWebpageLinkClick(url: string) {
    // Open the URL in a popup window with specific dimensions
    const width = 600;
    const height = 700;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    
    window.open(
      url,
      'Google Authentication',
      `width=${width},height=${height},left=${left},top=${top},toolbar=no,menubar=no,scrollbars=yes,resizable=yes,status=no`
    );
  }

  async onAcceptWorkstream(
    ws: ProjectManagerWorkstream,
    group: ProjectManagerWorkboardGroup,
    col: ProjectManagerWorkstreamColumn
  ) {
    this.acceptWorkstream.emit({
      messageId: this.message.id || '',
      sessionId: this.sessionId || '',
      workstream: ws,
      group,
      col
    });
  }

  onRejectWorkstream(
    ws: ProjectManagerWorkstream,
    group: ProjectManagerWorkboardGroup,
    col: ProjectManagerWorkstreamColumn
  ) {
    this.rejectWorkstream.emit({
      messageId: this.message.id || '',
      sessionId: this.sessionId || '',
      workstream: ws,
      group,
      col
    });
  }

  onOpenExistingSession(session: SuggestedSession) {
    this.openExistingSession.emit({
      messageId: this.message.id || '',
      sessionId: this.sessionId || '',
      session
    });
  }

  onAcceptNewSession(session: SuggestedNewSession) {
    this.acceptNewSession.emit({
      messageId: this.message.id || '',
      sessionId: this.sessionId || '',
      session
    });
  }

  onRejectNewSession(session: SuggestedNewSession) {
    this.rejectNewSession.emit({
      messageId: this.message.id || '',
      sessionId: this.sessionId || '',
      session
    });
  }

  isAcceptedStatus(status?: string): boolean {
    return status === 'accepted' || status === 'accepted_group';
  }
  isRejectedStatus(status?: string): boolean {
    return status === 'rejected' || status === 'rejected_group';
  }

  getFilteredWorkstreamGroups(): ProjectManagerWorkboardGroup[] {
    if (!this.projectManagerData?.suggestedWorkboardNewItems?.groups) {
      return [];
    }
    return this.projectManagerData.suggestedWorkboardNewItems.groups
      .map((group) => {
        const filteredCols = group.workstreamCols.map((col) => {
          const filteredWS = col.workstreams.filter(ws => !this.isRejectedStatus(ws.changeStatus));
          return { ...col, workstreams: filteredWS };
        }).filter(c => c.workstreams.length > 0);
        return { ...group, workstreamCols: filteredCols };
      })
      .filter(g => g.workstreamCols.length > 0);
  }

  getFilteredExistingSessions(): SuggestedSession[] {
    if (!this.projectManagerData?.suggestedExistingSessions) {
      return [];
    }
    return this.projectManagerData.suggestedExistingSessions.filter(
      (session) => !this.isRejectedStatus(session.changeStatus)
    );
  }

  getFilteredNewSessions(): SuggestedNewSession[] {
    if (!this.projectManagerData?.suggestedNewSessions) {
      return [];
    }
    return this.projectManagerData.suggestedNewSessions.filter(
      (session) => !this.isRejectedStatus(session.changeStatus)
    );
  }

  /**
   * When a child SelectListComponent emits (itemClicked),
   * we funnel that up with our own output event so Chat can handle it.
   */
  async onSelectListItemClicked(
    part: ProcessedPart,
    event: { text: string; multiple: boolean; sendOnClick: boolean; item:any }
  ) {
    this.selectListItemClicked.emit(event);

    if (part.riffMLNode && part.riffMLNode.attributes && part.riffMLNode.attributes["toolOnClick"]) {
      await this.toolsService.callTool(part.riffMLNode.attributes["toolOnClick"], '', this.sessionId, part.riffMLNode, event.item);
    }
  }

  private processStringWithArtifactPlaceholders(text: string): void {
    const parts = text.split(/\[\[(.*?)\]\]/);
    this.processedParts = parts.map((part, index) => {
      if (index % 2 === 0) {
        return { content: this.convertNewlinesToBrTags(part), riffMLNode: null };
      } else {
        // artifact placeholder
        return {
          content: '',
          artifactTitle: part,
          remainingContent: parts[index + 1],
          riffMLNode: null
        };
      }
    });
  }

  private mightContainRiffML(text: string): boolean {
    return text.includes('<') && text.includes('>');
  }

  private isRiffMLNode(obj: any): obj is RiffMLNode {
    return obj &&
      typeof obj === 'object' &&
      'tagName' in obj &&
      'attributes' in obj &&
      'children' in obj;
  }

  private parseRiffMLAndBuildParts(content: string): void {
    try {
      const nodes = this.riffmlParser.parseRiffML(content);
      if (nodes.length > 0) {
        this.handleRiffMLNodes(nodes);
      } else {
        this.processStringWithArtifactPlaceholders(content);
      }
    } catch (err) {
      this.processStringWithArtifactPlaceholders(content);
    }
  }

  private handleRiffMLNodes(nodes: RiffMLNode[]): void {
    const flattened: ProcessedPart[] = [];
    nodes.forEach(node => {
      flattened.push(...this.convertNodeToParts(node));
    });
    this.processedParts = flattened;
  }

  private convertNodeToParts(node: RiffMLNode): ProcessedPart[] {
    // If the node is hidden="true", skip it entirely
    if (node.attributes['hidden'] === 'true') {
      return [];
    }

    // #text node
    if (node.tagName === '#text') {
      return [{ content: this.convertNewlinesToBrTags(node.content), riffMLNode: node }];
    }

    // For recognized link nodes
    if (['tablelink', 'sessionlink', 'workstreamlink', 'webpagelink'].includes(node.tagName.toLowerCase())) {
      const linkClassMap: { [key: string]: string } = {
        tablelink: 'table-link',
        sessionlink: 'session-link',
        workstreamlink: 'workstream-link',
        webpagelink: 'webpage-link'
      };
      const iconMap: { [key: string]: string } = {
        tablelink: '⊞',
        sessionlink: '💬',
        workstreamlink: '🔀',
        webpagelink: '🔗'
      };
      const tag = node.tagName.toLowerCase();

      // Extract child text if needed
      let childContent = this.getContentFromChildren(node.children);
      if (node.content && node.content.length) {
        childContent = node.content;
      }

      return [{
        content: this.convertNewlinesToBrTags(childContent),
        nodeType: 'specialLink',
        linkClass: linkClassMap[tag],
        linkIcon: iconMap[tag],
        tool: tag === 'webpagelink' ? '' : tag, // No tool for webpagelink
        riffMLNode: node
      }];
    }

    // <WorkstreamsList>
    if (node.tagName.toLowerCase() === 'workstreamslist') {
      const tooltip = node.attributes['tooltip'];
      let childContent = this.getContentFromChildren(node.children);
      if (node.content && node.content.length) {
        childContent = node.content;
      }

      return [{
        content: this.convertNewlinesToBrTags(childContent || ''),
        nodeType: 'WorkstreamsList',
        tooltip,
        riffMLNode: node
      }];
    }

    // <SessionsList>
    if (node.tagName.toLowerCase() === 'sessionslist') {
      const tooltip = node.attributes['tooltip'];
      let childContent = this.getContentFromChildren(node.children);
      if (node.content && node.content.length) {
        childContent = node.content;
      }

      return [{
        content: this.convertNewlinesToBrTags(childContent || ''),
        nodeType: 'SessionsList',
        tooltip,
        riffMLNode: node
      }];
    }

    // <Button>
    if (node.tagName.toLowerCase() === 'button') {
      const textChild = node.children.find(c => c.tagName === '#text');
      const finalText = textChild && textChild.content.trim() !== ''
        ? textChild.content.trim()
        : (node.content || 'Click');

      return [{
        content: this.convertNewlinesToBrTags(finalText),
        nodeType: 'Button',
        tool: node.attributes['tool'] || '',
        nextPlaybook: node.attributes['nextPlaybook'] || '',
        riffMLNode: node
      }];
    }

    /**
     * NEW LOGIC: <SelectFromList> node
     */
    if (node.tagName.toLowerCase() === 'selectfromlist') {
      return [{
        content: this.convertNewlinesToBrTags(node.content || ''),
        nodeType: 'SelectFromList',
        riffMLNode: node
      }];
    }

    // Otherwise treat as text + flatten children
    const thisPart: ProcessedPart = { content: this.convertNewlinesToBrTags(node.content || ''), riffMLNode: node };
    return [thisPart, ...this.flattenChildren(node.children)];
  }

  private getContentFromChildren(children: RiffMLNode[]): string {
    if (!children || children.length === 0) return '';
    return children.map(child => {
      if (child.tagName === '#text') {
        return child.content;
      } else {
        return this.getContentFromChildren(child.children);
      }
    }).join('').trim();
  }
}