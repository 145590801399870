// src/app/components/layout/navigation-bar/navigation-bar.component.ts
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { NavigationService } from '../../../services/navigation.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface NavigationItem {
  label: string;
  path: string;
  isLink: boolean;
}

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, MatDividerModule]
})
export class NavigationBarComponent implements OnInit {
  lastItem$: Observable<NavigationItem>;

  constructor(private navigationService: NavigationService) {
    this.lastItem$ = this.navigationService.navigation$.pipe(
      map(items => items[items.length - 1])
    );
  }

  ngOnInit() {
  }

  loadAvailableItems(parentId?: string): void {
    this.navigationService.getAvailableItems(parentId)
      .subscribe(items => {
        console.log('Available items:', items);
      });
  }
}