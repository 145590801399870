<!-- src/app/components/header/header.component.html -->
<header class="header">
  <div class="header-grid">
    <!-- Left Column: Logo -->
    <div class="left-section">
      <div class="logo-overlay">
        <a routerLink="/studio" routerLinkActive="active" matTooltip="Home">
          <img src="assets/images/asky-logo.svg" alt="Ask-Y Logo" width="120" height="40">
        </a>
      </div>
    </div>

    <!-- Middle Column: Project selection (can expand/shrink) -->
    <div class="middle-section">
      <app-project-selection 
        (workspaceSelected)="closeRecordsPane()" 
        (projectSelected)="closeRecordsPane()"
        (menuOpened)="closeRecordsPane()">
      </app-project-selection>
    </div>

    <!-- Right Column: User info -->
    <div class="right-section">
      
      <!-- Records button -->
      <button mat-button class="records-button" (click)="toggleRecordsPane()">
        <mat-icon>memory</mat-icon>
        <span>Joint Associative Memory</span>
      </button>
      
      <!-- Activity Log -->
      <app-activity-log></app-activity-log>
      
      <!-- Notifications bell -->
      <button mat-icon-button class="notifications-button" (click)="toggleNotificationsPane()">
        <mat-icon [class.has-notifications]="unreadNotificationsCount > 0">notifications</mat-icon>
        <span class="notification-badge" *ngIf="unreadNotificationsCount > 0">{{ unreadNotificationsCount > 99 ? '99+' : unreadNotificationsCount }}</span>
      </button>
      
      <!-- User profile section -->
      <div class="user-section">
        <button
          mat-button
          [matMenuTriggerFor]="userMenu"
          class="user-button"
          *ngIf="user"
        >
          <div class="user-info">
            <ng-container *ngIf="user.avatar; else noAvatar">
              <img
                [src]="user.avatar"
                alt="User avatar"
                class="avatar"
              />
            </ng-container>
            <ng-template #noAvatar>
              <div class="avatar">
                {{ getUserInitials(user.name) }}
              </div>
            </ng-template>
            <span class="user-name">{{ user.name }}</span>
          </div>
        </button>

        <mat-menu #userMenu="matMenu" class="user-menu">
          <button mat-menu-item>
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</header>

<!-- Records Floating Pane -->
<div class="records-pane-overlay" *ngIf="showRecordsPane">
  <div class="records-pane" 
       [class.expanded]="isRecordsPaneExpanded" 
       [style.width]="isRecordsPaneExpanded ? '100%' : recordsPaneWidth"
       [style.right]="isRecordsPaneExpanded ? '0' : '0'">
    <div class="records-pane-header">
      <div class="header-title">
        <button mat-icon-button class="expand-toggle-button" (click)="toggleRecordsPaneExpansion()">
          <mat-icon>{{ isRecordsPaneExpanded ? 'chevron_right' : 'chevron_left' }}</mat-icon>
        </button>
        <h2>Joint Associative Memory</h2>
      </div>
      <div class="header-actions">
        <button mat-icon-button class="close-button" (click)="closeRecordsPane()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="records-pane-content">
      <app-records></app-records>
    </div>
  </div>
</div>

<!-- Notifications Component -->
<app-notifications></app-notifications>