<div class="sessions-container">
  <div class="sessions-list">
    <div class="section-title">Recent Sessions</div>
    
    <!-- First 2 Expanded Sessions -->
    <div class="session-item expanded" *ngFor="let session of recentSessions.slice(0,2)">
      <div class="session-header">
        <div class="session-info">
          <div class="session-title">{{session.title}}</div>
          <div class="session-meta">
            <span>{{session.date}}</span>
            <div class="indicators">
              <mat-icon class="indicator-icon" *ngFor="let icon of session.indicators" [inline]="true">{{icon}}</mat-icon>
            </div>
          </div>
        </div>
        <span class="suggestion-type" *ngIf="session.suggestionType">{{session.suggestionType}}</span>
        <button mat-icon-button class="pin-button" (click)="togglePin()">
          <mat-icon>push_pin</mat-icon>
        </button>
      </div>
      <div class="session-records">
        <div class="record-item" 
             *ngFor="let record of session.records"
             (mouseenter)="onRecordHover(record)"
             (mouseleave)="onRecordLeave()">
          <mat-icon class="record-type-icon" [inline]="true">{{record.icon}}</mat-icon>
          <span>{{record.name}}</span>
          
          <div class="record-flows-popup" *ngIf="hoveredRecord === record && record.recommendedFlows?.length">
            <div class="flow-item" *ngFor="let flow of record.recommendedFlows">
              <mat-icon class="flow-icon" [inline]="true">arrow_forward</mat-icon>
              <span class="flow-name">{{flow.name}}</span>
            </div>
          </div>
        </div>
        
        <div class="recommended-flows" *ngIf="session.recommendedFlows?.length">
          <div class="flow-item" *ngFor="let flow of session.recommendedFlows">
            <mat-icon class="flow-icon" [inline]="true">arrow_forward</mat-icon>
            <span class="flow-name">{{flow.name}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Next 4 Regular Sessions -->
    <div class="sessions-panel">
      <div class="session-item" *ngFor="let session of recentSessions.slice(2,6)">
        <div class="session-header">
          <div class="session-info">
            <div class="session-title">{{session.title}}</div>
            <div class="session-meta">
              <span>{{session.date}}</span>
              <div class="indicators">
                <mat-icon class="indicator-icon" *ngFor="let icon of session.indicators" [inline]="true">{{icon}}</mat-icon>
              </div>
            </div>
          </div>
          <span class="suggestion-type" *ngIf="session.suggestionType">{{session.suggestionType}}</span>
          <button mat-icon-button class="pin-button" (click)="togglePin()">
            <mat-icon>push_pin</mat-icon>
          </button>
          <button mat-icon-button class="expand-button">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
