// src/app/services/snowflake.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

// Interfaces for request/response types
export interface SnowflakeQueryRequest {
  query: string;
}

export interface SnowflakeCommandRequest {
  command: string;
}

export interface SnowflakeBatchRequest {
  commands: string[];
}

export interface SnowflakeResponse<T> {
  Success: boolean;
  Data?: T;
  ErrorMessage?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SnowflakeService {
  private readonly baseUrl = `${environment.artifactsApiUrl}/snowflake`;

  constructor(private http: HttpClient) {}

  /**
   * Executes a query against Snowflake
   * @param query The SQL query to execute
   * @returns Promise containing the query results
   */
  async executeQuery<T>(query: string): Promise<SnowflakeResponse<T>> {
    console.log('[SnowflakeService] Executing query:', query);

    try {
      const request: SnowflakeQueryRequest = { query };
      const response = await firstValueFrom(
        this.http.post<SnowflakeResponse<T>>(`${this.baseUrl}/query`, request)
      );

      console.log('[SnowflakeService] Query executed successfully:', response);
      return response;

    } catch (error) {
      console.error('[SnowflakeService] Error executing query:', error);
      throw error;
    }
  }

  /**
   * Executes a command (INSERT, UPDATE, DELETE) against Snowflake
   * @param command The SQL command to execute
   * @returns Promise containing the execution result
   */
  async executeCommand(command: string): Promise<SnowflakeResponse<string>> {
    console.log('[SnowflakeService] Executing command:', command);

    try {
      const request: SnowflakeCommandRequest = { command };
      const response = await firstValueFrom(
        this.http.post<SnowflakeResponse<string>>(`${this.baseUrl}/command`, request)
      );

      console.log('[SnowflakeService] Command executed successfully:', response);
      return response;

    } catch (error) {
      console.error('[SnowflakeService] Error executing command:', error);
      throw error;
    }
  }

  /**
   * Executes a batch of commands against Snowflake
   * @param commands Array of SQL commands to execute
   * @returns Promise containing the batch execution result
   */
  async executeBatch(commands: string[]): Promise<SnowflakeResponse<string>> {
    console.log('[SnowflakeService] Executing batch commands:', commands);

    try {
      const request: SnowflakeBatchRequest = { commands };
      const response = await firstValueFrom(
        this.http.post<SnowflakeResponse<string>>(`${this.baseUrl}/batch`, request)
      );

      console.log('[SnowflakeService] Batch executed successfully:', response);
      return response;

    } catch (error) {
      console.error('[SnowflakeService] Error executing batch:', error);
      throw error;
    }
  }

  /**
   * Executes a query and returns an Observable
   * @param query The SQL query to execute
   * @returns Observable of the query results
   */
  executeQueryObservable<T>(query: string): Observable<SnowflakeResponse<T>> {
    const request: SnowflakeQueryRequest = { query };
    return this.http.post<SnowflakeResponse<T>>(`${this.baseUrl}/query`, request);
  }

  /**
   * Executes a command and returns an Observable
   * @param command The SQL command to execute
   * @returns Observable of the execution result
   */
  executeCommandObservable(command: string): Observable<SnowflakeResponse<boolean>> {
    const request: SnowflakeCommandRequest = { command };
    return this.http.post<SnowflakeResponse<boolean>>(`${this.baseUrl}/command`, request);
  }

  /**
   * Executes a batch of commands and returns an Observable
   * @param commands Array of SQL commands to execute
   * @returns Observable of the batch execution result
   */
  executeBatchObservable(commands: string[]): Observable<SnowflakeResponse<boolean>> {
    const request: SnowflakeBatchRequest = { commands };
    return this.http.post<SnowflakeResponse<boolean>>(`${this.baseUrl}/batch`, request);
  }
}