/**
 * @file src/app/services/tools/runapi-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';

/**
 * Tool: RunApi
 * Calls an API endpoint and stores the result in the state manager
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerRunApiTool(service: ToolsService): void {
  (service as any).RunApi = async function(nextVal: string, sessionId: string, riffMLNode: RiffMLNode | null, value: any): Promise<void> {
    if (!riffMLNode) return;
    
    // Get parameters from node attributes
    const path = riffMLNode.attributes['path'];
    const storeTarget = riffMLNode.attributes['storeTarget'];
    const storeTargetId = riffMLNode.attributes['storeTargetId'] ?? storeTarget;
    const storeTargetName = riffMLNode.attributes['storeTargetName'] ?? storeTarget;
    const storeTargetDescription = riffMLNode.attributes['storeTargetDescription'] ?? '';
    const storeTargetWhyRelevant = riffMLNode.attributes['storeTargetWhyRelevant'] ?? '';
    const storeTargetType = riffMLNode.attributes['storeTargetType'] ?? storeTarget;
    
    if (!path || !storeTarget) return;
    
    try {
      // Construct the API URL with the function key
      const url = `${environment.artifactsApiUrl}/${path}${environment.keyCode}`;
      
      // Call the API with the content as the body
      const httpClient = (this as any).httpClient as HttpClient;
      const returnValue = await firstValueFrom(httpClient.post(url, value));
      
      // Determine rootId based on storeTarget
      let rootId = null;
      rootId = sessionId;

      if (storeTarget.startsWith('project.') || storeTarget.startsWith('workspace.')) {
        rootId = null;
      }
      
      // Store the result in the state manager
      await this.stateManagerService.setState(
        rootId,
        storeTarget,
        storeTargetId,
        storeTargetName,
        storeTargetDescription,
        storeTargetWhyRelevant,
        storeTargetType,
        returnValue
      );
    } catch (error) {
      console.error('[RunApiTool] Error calling API:', error);
    }
  };
}