<div class="datatable-container">
  <div class="datatable-content">
    <div class="histogram-display" *ngIf="showHistogram && selectedHistogramColumn">
      <div class="histogram-header">
        <h4>Histogram: {{selectedHistogramColumn}}</h4>
        <button class="close-btn" (click)="closeHistogram()">×</button>
      </div>
      <div class="histogram-chart">
        <div class="histogram-bar" *ngFor="let item of histogramData">
          <div class="bar-container">
            <div class="bar" [style.width.%]="(item.count / histogramData[0].count) * 100">
              <span class="bar-value">{{item.count}}</span>
            </div>
          </div>
          <div class="bar-label">{{item.label}}</div>
        </div>
      </div>
    </div>
    
    <table *ngIf="currentTable">
      <thead>
        <tr>
          <th *ngFor="let col of columnSchemas; trackBy: trackByColName" class="sortable-header">
            <div class="th-content">
              <div class="header-actions">
                <span class="sort-icon" (click)="toggleColumnSort(col.name)" [class.active]="sortColumn === col.name">
                  <span class="material-icons">
                    {{sortColumn === col.name && sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward'}}
                  </span>
                </span>
                <span class="filter-icon" (click)="toggleFilterDialog(col, $event)" [class.active]="isFilterActive(col.name)">
                  <span class="material-icons">filter_alt</span>
                </span>
              </div>
              <span class="column-name" (click)="toggleColumnSort(col.name)">{{col.name}}</span>
            </div>
          </th>
        </tr>
        <tr class="filter-row" *ngIf="showFilters">
          <th *ngFor="let col of columnSchemas; trackBy: trackByColName">
            <div class="filter-cell" *ngIf="isFilterActive(col.name) || activeFilterColumn === col.name">
              <input 
                type="text" 
                class="filter-input" 
                [(ngModel)]="filterValues[col.name]" 
                (input)="applyFilters()"
                placeholder="Filter..."
              >
              <button 
                class="clear-filter" 
                *ngIf="filterValues[col.name]" 
                (click)="clearColumnFilter(col.name)"
              >
                ×
              </button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="filteredData && filteredData.length === 0">
          <td [attr.colspan]="columnSchemas.length || 1" class="no-results">No results found</td>
        </tr>
        <tr *ngFor="let row of filteredData || []">
          <td *ngFor="let col of columnSchemas || []; let i = index">{{row && row[i]}}</td>
        </tr>
      </tbody>
    </table>
    
    <div class="empty-state" *ngIf="!currentTable">
      No table data available
    </div>
    
    <!-- Filter Dialog -->
    <div class="filter-dialog" *ngIf="showFilterDialog && filterDialogColumn" [style.left.px]="filterDialogPosition.x" [style.top.px]="filterDialogPosition.y" (click)="$event.stopPropagation()">
      <div class="filter-content">
        <div class="filter-header">
          <span>Filter: {{filterDialogColumn.name}}</span>
        </div>
        <div class="filter-body">
          <div class="filter-row">
            <select [(ngModel)]="filterOperator" class="filter-select">
              <option value="equals">Equals</option>
              <option value="contains">Contains</option>
              <option value="startsWith">Starts with</option>
              <option value="endsWith">Ends with</option>
            </select>
            <input type="text" [(ngModel)]="filterValue" placeholder="Value" class="filter-input">
          </div>
          <div class="filter-actions">
            <button class="btn-clear" (click)="cancelFilter()">Cancel</button>
            <button class="btn-apply" (click)="applyFilter()">Apply</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>