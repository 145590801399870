import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceState } from '../../../models/workspace-state.model';
import { Subscription } from 'rxjs';
import { ToolsService } from '../../../services/tools.service';
import { NgxCodeJarComponent } from 'ngx-codejar';
import mermaid from 'mermaid';

interface TokenItem {
  key: string;
  value: string;
  location?: {
    line: number;
    start: number;
    end: number;
  };
}

@Component({
  selector: 'app-chartview',
  standalone: true,
  imports: [CommonModule, NgxCodeJarComponent],
  templateUrl: './chartview.component.html',
  styleUrls: ['./chartview.component.scss']
})
export class ChartviewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() mermaidCode: string = '';
  @Input() isLoading: boolean = false;
  @ViewChild('mermaidContent') mermaidContent!: ElementRef;
  @ViewChild('chartContainer') chartContainer!: ElementRef;
  private subscription: Subscription | undefined;
  chartQuery: string = 'Describe how the chart should look like..';
  zoomLevel: number = 1;
  currentSessionId: string = '';
  highlightedTokens: TokenItem[] = [];
  isDragging: boolean = false;
  startX: number = 0;
  startY: number = 0;
  translateX: number = 0;
  translateY: number = 0;

  // CodeJar options
  codeJarOptions = {
    tab: '    ',
    indentOn: /[(\[]$/,
    moveToNewLine: /^[)}\]]/,
    spellcheck: false,
    catchTab: true,
    preserveIdent: true,
    addClosing: true,
    history: true
  };

  constructor(
    private workspaceState: WorkspaceState,
    private toolsService: ToolsService
  ) {
    // Initialize mermaid
    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
      securityLevel: 'loose',
    });
  }

  ngOnInit(): void {
    // Subscribe to the current chart description
    this.subscription = this.workspaceState.CurrentChartDescription$.subscribe((description: string) => {
      this.chartQuery = description || 'Describe how the chart should look like..';
      this.processTokens(this.chartQuery);
    });

    // Get the current session ID from the workspace state
    this.currentSessionId = this.workspaceState.sessionsGridState$.value.selectedSessionId || '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mermaidCode'] && this.mermaidCode) {
      this.renderChart();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Called whenever user edits the chart description
   */
  onChartQueryUpdate(code: string): void {
    this.chartQuery = code;
    this.workspaceState.CurrentChartDetailedDescription$.next('');
    this.workspaceState.CurrentChartDescription$.next(this.chartQuery);
    this.processTokens(code);
  }

  /**
   * Processes the chart description to extract and highlight tokens
   */
  private processTokens(text: string): void {
    this.highlightedTokens = [];
    const lines = text.split('\n');
    
    lines.forEach((line, lineIndex) => {
      const matches = line.match(/\{([^}]+)\}/g);
      if (matches) {
        matches.forEach(match => {
          const key = match.slice(1, -1); // Remove braces
          const start = line.indexOf(match);
          const end = start + match.length;
          
          this.highlightedTokens.push({
            key,
            value: key, // In this case, the value is the same as the key
            location: {
              line: lineIndex + 1,
              start,
              end
            }
          });
        });
      }
    });
  }

  /**
   * Generates the chart when the user clicks the generate button
   */
  generateChart(): void {
    if (this.chartQuery && this.chartQuery.trim() !== '') {
      // Clear the mermaid content first
      if (this.mermaidContent) {
        this.mermaidContent.nativeElement.innerHTML = '';
      }
      this.isLoading = true;
      this.currentSessionId = this.workspaceState.sessionsGridState$.value.selectedSessionId || '';
      this.toolsService.callTool('generateChart', '',this.currentSessionId, null).finally(() => {
        this.isLoading = false;
      });
    }
  }

  /**
   * Highlights the text and tokens
   */
  highlight = (editor: HTMLElement) => {
    let code = editor.textContent || '';
    let result = code;

    // Sort tokens by start position in reverse order to handle overlapping tokens
    const sortedTokens = [...this.highlightedTokens].sort((a, b) => 
      (b.location?.start || 0) - (a.location?.start || 0)
    );

    // Replace each token with a highlighted span
    sortedTokens.forEach(token => {
      const location = token.location;
      if (!location) return;

      const beforeToken = result.substring(0, location.start);
      const tokenText = result.substring(location.start, location.end);
      const afterToken = result.substring(location.end);

      // Only process if we found the token at the expected position
      if (tokenText === `{${token.key}}`) {
        result = beforeToken + 
                 `<span class="chart-token" title="${token.value}">${token.key}</span>` + 
                 afterToken;
      }
    });

    editor.innerHTML = result;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    if (event.target instanceof SVGElement || event.target instanceof HTMLElement) {
      this.isDragging = true;
      this.startX = event.clientX - this.translateX;
      this.startY = event.clientY - this.translateY;
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      event.preventDefault();
      this.translateX = event.clientX - this.startX;
      this.translateY = event.clientY - this.startY;
      this.updateChartTransform();
    }
  }

  @HostListener('mouseup')
  onMouseUp(): void {
    this.isDragging = false;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.isDragging = false;
  }

  private updateChartTransform(): void {
    if (this.mermaidContent) {
      this.mermaidContent.nativeElement.style.transform = 
        `translate(${this.translateX}px, ${this.translateY}px) scale(${this.zoomLevel})`;
    }
  }

  zoomIn(): void {
    this.zoomLevel = this.zoomLevel + 0.1;
    this.updateChartTransform();
  }

  zoomOut(): void {
    this.zoomLevel = Math.max(this.zoomLevel - 0.1, 0.5);
    this.updateChartTransform();
  }

  resetZoom(): void {
    this.zoomLevel = 1;
    this.translateX = 0;
    this.translateY = 0;
    this.updateChartTransform();
  }

  private async renderChart(): Promise<void> {
    if (!this.mermaidCode) return;

    try {
      // Clear previous content
      if (this.mermaidContent) {
        this.mermaidContent.nativeElement.innerHTML = '';
      }

      // Generate unique ID for the chart
      const id = `mermaid-${Math.random().toString(36).substr(2, 9)}`;

      // Render the chart
      const { svg } = await mermaid.render(id, this.mermaidCode);
      
      // Add the SVG to the container
      if (this.mermaidContent) {
        this.mermaidContent.nativeElement.innerHTML = svg;
        // Reset position and apply current transform
        this.translateX = 0;
        this.translateY = 0;
        this.updateChartTransform();
      }
    } catch (error) {
      console.error('Error rendering Mermaid chart:', error);
    }
  }
} 