<!-- src/app/components/layout/layout.component.html -->
<div class="layout-container">
  <nav class="left-menu">
    <!-- Home link (moved up) -->
    <a routerLink="/landing" routerLinkActive="active" class="menu-item" matTooltip="Home">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
        <polyline points="9 22 9 12 15 12 15 22"></polyline>
      </svg>
    </a>

    <!-- Templates button (moved down) -->
    <a routerLink="/templates" routerLinkActive="active" class="menu-item" matTooltip="Templates">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
        <polyline points="14 2 14 8 20 8"></polyline>
        <path d="M8 13h8"></path>
        <path d="M8 17h8"></path>
        <path d="M8 9h1"></path>
      </svg>
    </a>

    <!-- Hidden menu trigger for the mega menu -->
    <div style="display: none;">
      <button [matMenuTriggerFor]="megaMenu" #menuTrigger="matMenuTrigger"></button>
    </div>

    <!-- Mega Menu Panel -->
    <mat-menu #megaMenu="matMenu" class="mega-menu-panel" xPosition="after" yPosition="below">
      <div class="mega-menu-content" (click)="$event.stopPropagation()">
        <!-- Header with close button -->
        <div class="mega-menu-header">
          <h3>Create New</h3>
          <button class="close-button" mat-icon-button (click)="closeMenu()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        
        <!-- Empty Task button -->
        <button class="new-task-button" (click)="onCreatePMSession(); closeMenu()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256">
            <path d="M71.59,61.47a8,8,0,0,0-15.18,0l-40,120A8,8,0,0,0,24,192h80a8,8,0,0,0,7.59-10.53ZM35.1,176,64,89.3,92.9,176ZM208,76a52,52,0,1,0-52,52A52.06,52.06,0,0,0,208,76Zm-88,0a36,36,0,1,1,36,36A36,36,0,0,1,120,76Zm104,68H136a8,8,0,0,0-8,8v56a8,8,0,0,0,8,8h88a8,8,0,0,0,8-8V152A8,8,0,0,0,224,144Zm-8,56H144V160h72Z"></path>
          </svg>
          <span>Empty Task</span>
        </button>
        
        <!-- Categories as collapsible sections -->
        <div class="mega-menu-sections">
          <div *ngFor="let category of menuData?.categories; let i = index" class="mega-menu-section">
            <div class="section-header" (click)="toggleSection(i, $event)">
              <span>{{ category.name }}</span>
              <svg class="arrow-icon" [class.expanded]="expandedSections[i]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </div>
            <div class="section-content" [class.expanded]="expandedSections[i]">
              <div *ngFor="let item of category.items" class="mega-menu-item" (click)="onItemClick(item); closeMenu()">
                <div class="item-icon">
                  <ng-container *ngIf="item.svg">
                    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.svg)" class="svg-icon"></div>
                  </ng-container>
                  <mat-icon *ngIf="!item.svg && item.iconName">{{ item.iconName }}</mat-icon>
                </div>
                <div class="item-name">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-menu>

    <!-- Main Navigation -->
    <a routerLink="/spaces" routerLinkActive="active" class="menu-item" matTooltip="Spaces">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M20.942 13.02a9 9 0 1 0 -9.47 7.964" />
        <path d="M3.6 9h16.8" />
        <path d="M3.6 15h9.9" />
        <path d="M11.5 3a17 17 0 0 0 0 18" />
        <path d="M12.5 3c2 3.206 2.837 6.913 2.508 10.537" />
        <path d="M20 21l2 -2l-2 -2" />
        <path d="M17 17l-2 2l2 2" />
      </svg>
    </a>

    <a routerLink="/studio" routerLinkActive="active" class="menu-item" matTooltip="Studio">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M14 4a1 1 0 0 1 1 -1h5a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1z" />
        <path d="M3 14h12a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h3a2 2 0 0 1 2 2v12" />
      </svg>
    </a>

    <div class="separator"></div>

    <!-- Content Section -->
    <div class="content-section">
      <button class="menu-item" matTooltip="Content">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
          <path d="M4 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
          <path d="M14 12l6 0" />
          <path d="M14 16l6 0" />
          <path d="M14 20l6 0" />
        </svg>
      </button>

      <div class="content-submenu">
        <a routerLink="/connections" routerLinkActive="active" class="menu-item submenu-item" matTooltip="Connections">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 18a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
            <path d="M20 6a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
            <path d="M8 6a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
            <path d="M20 18a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
            <path d="M14 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
            <path d="M7.5 7.5l3 3" />
            <path d="M7.5 16.5l3 -3" />
            <path d="M13.5 13.5l3 3" />
            <path d="M16.5 7.5l-3 3" />
          </svg>
        </a>
        <a routerLink="/data-prep" routerLinkActive="active" class="menu-item submenu-item" matTooltip="Data Preparation">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M20 11a8.1 8.1 0 0 0 -6.986 -6.918a8.095 8.095 0 0 0 -8.019 3.918" />
            <path d="M4 13a8.1 8.1 0 0 0 15 3" />
            <path d="M19 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M5 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
          </svg>
        </a>
        <a routerLink="/modeling" routerLinkActive="active" class="menu-item submenu-item" matTooltip="Modeling">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M20 11v-2a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v5a2 2 0 0 0 2 2h4" />
            <path d="M14 21v-4a2 2 0 1 1 4 0v4" />
            <path d="M14 19h4" />
            <path d="M21 15v6" />
          </svg>
        </a>
        <a routerLink="/analysis" routerLinkActive="active" class="menu-item submenu-item" matTooltip="Analysis">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 3v18h18" />
            <path d="M9 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M19 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M14 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M10.16 10.62l2.34 2.88" />
            <path d="M15.088 13.328l2.837 -4.586" />
          </svg>
        </a>
      </div>
    </div>

    <div class="spacer"></div>

    <!-- Admin at bottom -->
    <a routerLink="/admin" routerLinkActive="active" class="menu-item" style="margin-bottom: 25px;" matTooltip="Admin">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
        <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
      </svg>
    </a>
  </nav>
  <main class="main-content">
    <app-header></app-header>
    <router-outlet></router-outlet>
  </main>
</div>