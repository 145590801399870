// src/app/services/navigation.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

export interface NavigationItem {
  label: string;
  path: string;
  isLink: boolean;
  id?: string;  // For database reference
  parentId?: string;  // For hierarchy
  children?: NavigationItem[];
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private navigationSubject = new BehaviorSubject<NavigationItem[]>([]);
  public navigation$ = this.navigationSubject.asObservable();

  // Simulated data - will be replaced with API calls
  private readonly mockNavigationData: NavigationItem[] = [
    {
      id: '1',
      label: 'Body & Soul',
      path: '/accounts/1',
      isLink: true,
      children: [
        {
          id: '1-1',
          label: 'First Jam',
          path: '',
          isLink: false
        },
        {
          id: '1-2',
          label: 'Settings',
          path: '/accounts/1/settings',
          isLink: true
        },
        {
          id: '1-3',
          label: 'Users',
          path: '/accounts/1/users',
          isLink: true
        }
      ]
    }
  ];

  constructor() {
    // Initialize with mock data
    this.loadNavigationData();
  }

  // Simulates loading data from backend
  private loadNavigationData(): void {
    // For now using first level of navigation
    this.updateNavigation([
      this.mockNavigationData[0],
      this.mockNavigationData[0].children![0]
    ]);
  }

  // Updates the navigation state
  private updateNavigation(items: NavigationItem[]): void {
    this.navigationSubject.next(items);
  }

  // Get available navigation items for a specific parent
  getAvailableItems(parentId?: string): Observable<NavigationItem[]> {
    // Simulate API call
    return of(this.mockNavigationData.filter(item => item.parentId === parentId));
  }

  // Get a specific navigation item by ID
  getNavigationItem(id: string): Observable<NavigationItem | undefined> {
    // Simulate API call
    const findItem = (items: NavigationItem[]): NavigationItem | undefined => {
      for (const item of items) {
        if (item.id === id) return item;
        if (item.children) {
          const found = findItem(item.children);
          if (found) return found;
        }
      }
      return undefined;
    };

    return of(findItem(this.mockNavigationData));
  }

  // Future implementation will make API calls
  // loadNavigationForUser(userId: string): Observable<NavigationItem[]> {
  //   return this.http.get<NavigationItem[]>(`/api/users/${userId}/navigation`);
  // }
}