import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

export interface ProjectDialogData {
  title: string;
  message: string;
  confirmText: string;
  label?: string;
  itemName?: string;
}

@Component({
  selector: 'app-project-dialog',
  template: `
    <div class="dialog-container">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>
        <p>{{ data.message }}{{ data.itemName ? ' "' + data.itemName + '"?' : '' }}</p>
        <div class="input-container" *ngIf="data.label !== undefined">
          <input matInput [(ngModel)]="data.label" cdkFocusInitial placeholder="Project Name">
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-flat-button 
                color="primary"
                [mat-dialog-close]="data.label !== undefined ? data.label : true">
          {{ data.confirmText }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [`
    :host {
      --mdc-dialog-container-color: white;
      display: block;
    }

    :host ::ng-deep {
      .mat-mdc-dialog-title {
        font-size: 14px !important;
        margin-bottom: 8px !important;
        font-weight: normal !important;
      }

      .mat-mdc-dialog-content {
        font-size: 12px !important;
        margin: 0 !important;
        padding: 0 24px 12px !important;
        color: rgba(0, 0, 0, 0.6) !important;
      }

      // Input styling
      .input-container {
        margin-top: 8px;
      }

      .mat-mdc-input-element {
        width: 100%;
        height: 40px !important;
        font-size: 12px !important;
        background-color: #f5f5f5 !important;
        border: none !important;
        border-radius: 4px !important;
        padding: 0 12px !important;
        outline: none !important;

        &::placeholder {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      // Button styling
      .mat-mdc-dialog-actions {
        padding: 8px 24px !important;
        margin: 0 !important;
        min-height: unset !important;
        gap: 4px !important;
      }

      .mdc-button {
        height: 32px !important;
        padding: 0 16px !important;
      }

      .mdc-button__label {
        font-size: 12px !important;
      }

      // Primary button styling
      .mat-mdc-unelevated-button.mat-primary {
        --mdc-filled-button-container-color: var(--mat-app-primary) !important;
        background-color: #1976d2 !important;
        color: white !important;

        .mdc-button__label {
          color: white !important;
        }
      }
    }
  `],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    FormsModule
  ]
})
export class ProjectDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectDialogData
  ) {}
}
