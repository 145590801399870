import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordManagementService } from '../../../services/record-management.service';
import { RecordGroupListComponent } from '../record-group-list/record-group-list.component';
import { RecordGroupList } from '../../../models/record-management.interface';

@Component({
  selector: 'app-sessions-history',
  standalone: true,
  imports: [CommonModule, RecordGroupListComponent],
  template: `
    <div class="sessions-container">
      <div class="lists-container">
        <div class="recent-list">
          <app-record-group-list
            *ngIf="recentList"
            [list]="recentList"
            (onPinToggle)="onPinToggle($event)"
            (onExpandToggle)="onExpandToggle($event)">
          </app-record-group-list>
        </div>
        <div class="highlighted-list">
          <app-record-group-list
            *ngIf="highlightedList"
            [list]="highlightedList"
            (onPinToggle)="onPinToggle($event)"
            (onExpandToggle)="onExpandToggle($event)">
          </app-record-group-list>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .sessions-container {
      display: block;
      max-width: 800px;
      margin: 0 auto;
      padding: 24px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    }

    .lists-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
  `]
})
export class SessionsHistoryComponent implements OnInit {
  recentList: RecordGroupList | null = null;
  highlightedList: RecordGroupList | null = null;

  constructor(private recordManagementService: RecordManagementService) {}

  ngOnInit() {
    this.recordManagementService.getRecordGroupLists().subscribe(lists => {
      this.recentList = lists.find(l => l.id === 'recent') || null;
      this.highlightedList = lists.find(l => l.id === 'highlighted') || null;
    });
  }

  onPinToggle(groupId: string) {
    this.recordManagementService.toggleGroupPin(groupId);
  }

  onExpandToggle(groupId: string) {
    this.recordManagementService.toggleGroupExpansion(groupId);
  }
}
