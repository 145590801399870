<div class="record-group-list">
  <div class="section-title">{{list.title}}</div>
  <div class="groups" [class.vertical]="vertical">
    <app-records-group
      *ngFor="let group of list.groups"
      [group]="group"
      (onPinToggle)="onPinToggle.emit($event)"
      (onExpandToggle)="onExpandToggle.emit($event)">
    </app-records-group>
  </div>
</div>
