import { Component, Input, ElementRef, AfterViewInit, Output, EventEmitter, HostBinding, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-splitter-pane',
  template: `
    <div class="pane-content">
      <div class="content-wrapper" [class.sessions-grid-wrapper]="containsSessionsGrid">
        <ng-content></ng-content>
      </div>
    </div>
    <div *ngIf="!isLastPane" 
         class="splitter-handle"
         [class.vertical]="isVertical"
         [class.dragging]="isDragging"
         (mousedown)="startResize($event)"
         (mouseover)="handleHover = true"
         (mouseleave)="handleHover = false">
      <div class="handle-line" [class.hover]="handleHover || isDragging"></div>
      <div class="collapse-button" (click)="toggleCollapse($event)">
        <span class="arrow" [class.collapsed]="collapsed">
          {{ isVertical ? (collapsed ? '↑' : '↓') : (collapsed ? '←' : '→') }}
        </span>
      </div>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      position: relative;
      overflow: hidden;
      min-width: 0;
      min-height: 0;
    }
    .pane-content {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      min-width: 0;
      min-height: 0;
      flex: 1;
      display: flex;
    }
    .content-wrapper {
      flex: 1;
      min-width: 0;
      min-height: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      display: flex;
    }
    .content-wrapper.sessions-grid-wrapper {
      overflow-x: hidden;
    }
    .splitter-handle {
      position: relative;
      flex: 0 0 6px;
      background: transparent;
      transition: background-color 0.2s;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .splitter-handle.dragging {
      background: rgba(0, 0, 0, 0.05);
    }
    .handle-line {
      position: absolute;
      background: #e0e0e0;
      transition: all 0.2s ease;
    }
    .splitter-handle:not(.vertical) .handle-line {
      width: 1px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    .splitter-handle.vertical .handle-line {
      height: 1px;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    .handle-line.hover {
      background: #999;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    }
    .splitter-handle:not(.vertical) {
      cursor: col-resize;
    }
    .splitter-handle.vertical {
      cursor: row-resize;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0px;
      background-color: #adadad;
    }
    .collapse-button {
      position: absolute;
      width: 20px;
      height: 40px;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      right: -7px;
      border-radius: 0 4px 4px 0;
      opacity: 0;
      transition: all 0.2s;
      z-index: 11;
      border: 1px solid #e0e0e0;
      border-left: none;
    }
    .splitter-handle:hover .collapse-button {
      opacity: 1;
    }
    .vertical .collapse-button {
      width: 40px;
      height: 20px;
      top: -7px;
      right: 50%;
      transform: translateX(50%);
      border-radius: 0 0 4px 4px;
      border: 1px solid #e0e0e0;
      border-top: none;
    }
    .arrow {
      font-size: 12px;
      transition: transform 0.2s;
      color: #666;
    }
    .arrow.collapsed {
      transform: rotate(180deg);
    }
  `]
})
export class SplitterPaneComponent implements AfterViewInit, OnDestroy {
  @Input() size?: string;
  @Input() minSize = 50;
  @Input() isVertical = false;
  @Input() isLastPane = false;
  @Input() containsSessionsGrid = false;
  @Output() sizeChange = new EventEmitter<string>();

  @HostBinding('style.flex') hostFlex = '1';
  @HostBinding('style.width') hostWidth = 'auto';
  @HostBinding('style.height') hostHeight = 'auto';
  @HostBinding('style.minHeight') hostMinHeight = '0';

  private startX = 0;
  private startY = 0;
  private startWidth = 0;
  private startHeight = 0;
  public isDragging = false;
  public collapsed = false;
  public handleHover = false;
  private originalSize: string | null = null;
  private resizeObserver: ResizeObserver | null = null;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.updateDisplay();
    
    if (this.isVertical && this.isLastPane) {
      // Set up resize observer for the parent container and previous sibling
      this.resizeObserver = new ResizeObserver(() => {
        this.updateDisplay();
      });

      const parent = this.elementRef.nativeElement.parentElement;
      const previousSibling = this.elementRef.nativeElement.previousElementSibling;

      if (parent) {
        this.resizeObserver.observe(parent);
      }
      if (previousSibling) {
        this.resizeObserver.observe(previousSibling);
      }
    }
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  updateDisplay() {
    if (this.size) {
      if (this.isVertical && this.isLastPane) {
        // For the bottom pane in a vertical split, calculate remaining height
        const parentHeight = this.elementRef.nativeElement.parentElement?.offsetHeight || 0;
        const siblingHeight = this.elementRef.nativeElement.previousElementSibling?.offsetHeight || 0;
        const remainingHeight = Math.max(0, parentHeight - siblingHeight - 2); // 2px for the splitter handle
        this.hostFlex = '0 0 auto';
        this.hostWidth = '100%';
        this.hostHeight = `${remainingHeight}px`;
      } else if (this.size.includes('%')) {
        this.hostFlex = `0 0 ${this.size}`;
        this.hostWidth = this.isVertical ? '100%' : this.size;
        this.hostHeight = this.isVertical ? this.size : '100%';
      } else {
        this.hostFlex = `0 0 ${this.size}`;
        this.hostWidth = this.isVertical ? '100%' : this.size;
        this.hostHeight = this.isVertical ? this.size : '100%';
      }
    } else {
      this.hostFlex = this.isLastPane ? '1 1 auto' : '1';
      this.hostWidth = '100%';
      this.hostHeight = '100%';
    }
  }

  startResize(event: MouseEvent) {
    event.preventDefault();
    this.isDragging = true;
    this.startX = event.pageX;
    this.startY = event.pageY;
    this.startWidth = this.elementRef.nativeElement.offsetWidth;
    this.startHeight = this.elementRef.nativeElement.offsetHeight;

    const mouseMoveHandler = (e: MouseEvent) => {
      if (!this.isDragging) return;
      e.preventDefault();

      let newSize;
      if (this.isVertical) {
        const dy = e.pageY - this.startY;
        newSize = Math.max(this.minSize, this.startHeight + dy);
        this.hostHeight = `${newSize}px`;
      } else {
        const dx = e.pageX - this.startX;
        newSize = Math.max(this.minSize, this.startWidth + dx);
        this.hostWidth = `${newSize}px`;
      }

      this.hostFlex = `0 0 ${newSize}px`;
      this.sizeChange.emit(`${newSize}px`);
    };

    const mouseUpHandler = () => {
      this.isDragging = false;
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  toggleCollapse(event: MouseEvent) {
    event.stopPropagation();
    this.collapsed = !this.collapsed;
    
    if (this.collapsed) {
      this.originalSize = this.size || '50%';
      const collapsedSize = '25px';
      this.hostFlex = `0 0 ${collapsedSize}`;
      this.hostWidth = this.isVertical ? '100%' : collapsedSize;
      this.hostHeight = this.isVertical ? collapsedSize : '100%';
    } else {
      this.size = this.originalSize || '50%';
      this.updateDisplay(); 
    }
  }
} 