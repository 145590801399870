import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import * as d3 from 'd3';

export interface FeaturePoint {
  x: number;
  featureValue: number;
}

export interface FeatureData {
  id: number;
  feature: string;
  label: string;
  value: number;
  points: FeaturePoint[];
}

@Component({
  selector: 'app-feature-importance-chart',
  templateUrl: './feature-importance-chart.component.html',
  styleUrls: ['./feature-importance-chart.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule]
})
export class FeatureImportanceChartComponent implements OnInit, OnDestroy {
  @Input() memoryId: string = '';
  @Output() close = new EventEmitter<void>();
  @ViewChild('chartContainer') chartContainer!: ElementRef;
  
  private svg: any;
  private data: FeatureData[] = [];
  
  // Feature mapping for more descriptive labels
  private featureMapping: {[key: string]: string} = {
    "temp": "Data Significance Factor",
    "hum": "Cross-Project Impact",
    "season.WINTER": "Multi-Stakeholder Relevance",
    "windspeed": "Artifact Dependency Strength",
    "workingday.WORKING DAY": "Change Sensitivity Index",
    "mnth.SEP": "Pattern Recognition Value",
    "weathersit.RAIN/SNOW/STORM": "Edge Case Influence",
    "mnth.FEB": "Resource Utilization Factor",
    "weekday.SAT": "Decision Point Weight",
    "weekday.MON": "Process Optimization Value"
  };

  constructor() { }

  ngOnInit(): void {
    // Generate mock data for demonstration
    this.generateMockData();
    
    // Wait for the view to be initialized
    setTimeout(() => {
      this.createChart();
    }, 100);
  }
  
  ngOnDestroy(): void {
    // Clean up any D3 bindings
    if (this.svg) {
      d3.select(this.chartContainer.nativeElement).selectAll('*').remove();
    }
  }
  
  private generateMockData(): void {
    // Generate mock data based on the provided example
    this.data = [
      { id: 1, feature: "temp", label: this.featureMapping["temp"], value: 1147.140, points: this.generatePoints("temp", 1147.140) },
      { id: 2, feature: "hum", label: this.featureMapping["hum"], value: 442.765, points: this.generatePoints("hum", 442.765) },
      { id: 3, feature: "season.WINTER", label: this.featureMapping["season.WINTER"], value: 220.756, points: this.generatePoints("season.WINTER", 220.756) },
      { id: 4, feature: "windspeed", label: this.featureMapping["windspeed"], value: 214.215, points: this.generatePoints("windspeed", 214.215) },
      { id: 5, feature: "workingday.WORKING DAY", label: this.featureMapping["workingday.WORKING DAY"], value: 48.783, points: this.generatePoints("workingday.WORKING DAY", 48.783) },
      { id: 6, feature: "mnth.SEP", label: this.featureMapping["mnth.SEP"], value: 46.225, points: this.generatePoints("mnth.SEP", 46.225) },
      { id: 7, feature: "weathersit.RAIN/SNOW/STORM", label: this.featureMapping["weathersit.RAIN/SNOW/STORM"], value: 36.846, points: this.generatePoints("weathersit.RAIN/SNOW/STORM", 36.846) },
      { id: 8, feature: "mnth.FEB", label: this.featureMapping["mnth.FEB"], value: 31.710, points: this.generatePoints("mnth.FEB", 31.710) },
      { id: 9, feature: "weekday.SAT", label: this.featureMapping["weekday.SAT"], value: 30.821, points: this.generatePoints("weekday.SAT", 30.821) },
      { id: 10, feature: "weekday.MON", label: this.featureMapping["weekday.MON"], value: 27.431, points: this.generatePoints("weekday.MON", 27.431) }
    ];
  }
  
  private generatePoints(feature: string, value: number): FeaturePoint[] {
    const points: FeaturePoint[] = [];
    const count = 50 + Math.floor(Math.random() * 50);
    
    // Different point distribution strategies based on the feature
    if (feature === "temp") {
      // Temperature has a gradient from left to right
      for (let i = 0; i < count; i++) {
        const x = Math.random() * 2000 - 1000;
        const featureValue = x < 0 ? 0.1 + Math.random() * 0.3 : 0.4 + Math.random() * 0.6;
        points.push({ x, featureValue });
      }
    } else if (feature === "hum") {
      // Humidity has clusters on both sides
      for (let i = 0; i < count; i++) {
        const side = Math.random() > 0.4 ? 1 : -1;
        const x = side * (Math.random() * 1000 + 200);
        const featureValue = Math.random() * 0.9 + 0.1;
        points.push({ x, featureValue });
      }
    } else if (feature === "season.WINTER") {
      // Winter has most points on the right side
      for (let i = 0; i < count; i++) {
        const side = Math.random() > 0.2 ? 1 : -1;
        const x = side * (Math.random() * 800);
        const featureValue = side > 0 ? 0.6 + Math.random() * 0.4 : 0.1 + Math.random() * 0.3;
        points.push({ x, featureValue });
      }
    } else if (feature === "windspeed") {
      // Windspeed has scattered points with a right bias
      for (let i = 0; i < count; i++) {
        const x = Math.random() * 2000 - 1500;
        const featureValue = x > 0 ? 0.4 + Math.random() * 0.6 : 0.1 + Math.random() * 0.3;
        points.push({ x, featureValue });
      }
    } else {
      // Other features have more concentrated distributions
      for (let i = 0; i < count; i++) {
        // Bias direction based on feature
        const centerBias = Math.random() > 0.7 ? 200 : -200;
        const spread = Math.random() * 400 - 200;
        const x = centerBias + spread;
        const featureValue = Math.random() * 0.9 + 0.1;
        points.push({ x, featureValue });
      }
    }
    
    return points;
  }
  
  private createChart(): void {
    // Clear any existing chart
    d3.select(this.chartContainer.nativeElement).selectAll('*').remove();
    
    // Set up dimensions
    const margin = { top: 40, right: 200, bottom: 60, left: 250 };
    const width = 800 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;
    
    // Create SVG
    this.svg = d3.select(this.chartContainer.nativeElement)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
    
    // Background rectangle for the chart area - using dark theme color
    this.svg.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', '#1e1f21')
      .attr('stroke', 'rgba(255, 255, 255, 0.1)')
      .attr('stroke-width', 1);
    
    // Create scales
    const y = d3.scaleBand()
      .domain(this.data.map(d => d.id.toString()))
      .range([0, height])
      .padding(0.3);
    
    const x = d3.scaleLinear()
      .domain([-2000, 2000])
      .range([0, width]);
    
    // Color scale for feature values (using a color palette that fits with the dark theme)
    const colorScale = d3.scaleSequential()
      .domain([0, 1])
      .interpolator(d3.interpolate('#607d8b', '#ff584a')); // Using the app's blue-gray to accent red
    
    // Add grid lines
    const gridLinesX = x.ticks(10);
    this.svg.selectAll('gridlines-x')
      .data(gridLinesX)
      .enter()
      .append('line')
      .attr('class', 'grid-line')
      .attr('x1', (d: number) => x(d))
      .attr('y1', 0)
      .attr('x2', (d: number) => x(d))
      .attr('y2', height)
      .attr('stroke', 'rgba(255, 255, 255, 0.05)')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '3,3');
    
    this.data.forEach(d => {
      const yPos = y(d.id.toString());
      if (yPos !== undefined) {
        this.svg.append('line')
          .attr('class', 'grid-line')
          .attr('x1', 0)
          .attr('y1', yPos + y.bandwidth())
          .attr('x2', width)
          .attr('y2', yPos + y.bandwidth())
          .attr('stroke', 'rgba(255, 255, 255, 0.05)')
          .attr('stroke-width', 1)
          .attr('stroke-dasharray', '3,3');
      }
    });
    
    // Add the zero line
    const zeroLine = x(0);
    this.svg.append('line')
      .attr('x1', zeroLine)
      .attr('y1', 0)
      .attr('x2', zeroLine)
      .attr('y2', height)
      .attr('stroke', 'rgb(162, 160, 162)')
      .attr('stroke-width', 1.5);
    
    // Create tooltip
    const tooltip = d3.select('body')
      .append('div')
      .attr('class', 'feature-tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('background-color', '#1e1f21')
      .style('border', '1px solid rgba(255, 255, 255, 0.1)')
      .style('padding', '8px')
      .style('border-radius', '4px')
      .style('pointer-events', 'none')
      .style('font-size', '12px')
      .style('color', 'rgb(162, 160, 162)')
      .style('z-index', '100');
    
    // Add the dots
    this.data.forEach(featureData => {
      const yPos = y(featureData.id.toString());
      if (yPos !== undefined) {
        this.svg.selectAll(`.dot-${featureData.id}`)
          .data(featureData.points)
          .enter()
          .append('circle')
          .attr('class', `dot-${featureData.id}`)
          .attr('cx', (d: FeaturePoint) => x(d.x))
          .attr('cy', yPos + y.bandwidth() / 2)
          .attr('r', 4)
          .attr('fill', (d: FeaturePoint) => colorScale(d.featureValue))
          .attr('opacity', 0.8)
          .attr('stroke', 'rgba(0,0,0,0.2)')
          .attr('stroke-width', 0.5)
          .on('mouseover', (event: MouseEvent, d: FeaturePoint) => {
            d3.select(event.currentTarget as SVGCircleElement)
              .attr('r', 6)
              .attr('stroke-width', 1);
            
            tooltip.transition()
              .duration(200)
              .style('opacity', 0.9);
            
            tooltip.html(`<strong>${featureData.label}</strong><br/>
                        Original: ${featureData.feature}<br/>
                        SHAP Value: ${d.x.toFixed(2)}<br/>
                        Feature Value: ${d.featureValue.toFixed(2)}`)
              .style('left', (event.pageX + 10) + 'px')
              .style('top', (event.pageY - 28) + 'px');
          })
          .on('mouseout', (event: MouseEvent) => {
            d3.select(event.currentTarget as SVGCircleElement)
              .attr('r', 4)
              .attr('stroke-width', 0.5);
            
            tooltip.transition()
              .duration(500)
              .style('opacity', 0);
          });
      }
    });
    
    // Add the axes with light text for dark theme
    this.svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(10))
      .selectAll('text')
      .style('font-size', '12px')
      .style('fill', 'rgb(162, 160, 162)');
    
    // Customize axis lines and ticks for dark theme
    this.svg.selectAll('.domain, .tick line')
      .style('stroke', 'rgba(255, 255, 255, 0.1)');
    
    // Custom y-axis that uses IDs for positioning but shows labels
    this.svg.append('g')
      .selectAll('text')
      .data(this.data)
      .enter()
      .append('text')
      .attr('class', 'feature-label')
      .attr('x', -10)
      .attr('y', (d: FeatureData) => {
        const yPos = y(d.id.toString());
        return yPos !== undefined ? yPos + y.bandwidth() / 2 : 0;
      })
      .attr('dy', '0.35em')
      .style('text-anchor', 'end')
      .style('fill', 'rgb(162, 160, 162)')
      .style('font-size', '12px')
      .text((d: FeatureData) => d.label);
    
    // Add feature values as text
    this.svg.selectAll('.feature-value')
      .data(this.data)
      .enter()
      .append('text')
      .attr('class', 'feature-value')
      .attr('x', 5)
      .attr('y', (d: FeatureData) => {
        const yPos = y(d.id.toString());
        return yPos !== undefined ? yPos + y.bandwidth() / 2 : 0;
      })
      .attr('dy', '0.35em')
      .attr('text-anchor', 'start')
      .style('fill', 'rgb(162, 160, 162)')
      .style('font-size', '12px')
      .style('font-weight', 'bold')
      .text((d: FeatureData) => d.value.toFixed(3));
    
    // Add tick marks for y-axis
    this.svg.append('g')
      .call(d3.axisLeft(y).tickFormat(() => '').tickSize(0))
      .style('stroke', 'rgba(255, 255, 255, 0.1)');
    
    // Add x-axis label
    this.svg.append('text')
      .attr('x', width / 2)
      .attr('y', height + 40)
      .attr('text-anchor', 'middle')
      .style('font-size', '14px')
      .style('font-weight', 'bold')
      .style('fill', 'rgb(162, 160, 162)')
      .text('SHAP value (impact on model output)');
    
    // Add title
    this.svg.append('text')
      .attr('x', width / 2)
      .attr('y', -15)
      .attr('text-anchor', 'middle')
      .style('font-size', '18px')
      .style('font-weight', 'bold')
      .style('fill', 'rgb(162, 160, 162)')
      .text('Feature Importance Analysis');
    
    // Add color legend
    const legendWidth = 200;
    const legendHeight = 20;
    
    const legendX = width + 20;
    const legendY = 80;
    
    const defs = this.svg.append('defs');
    
    const linearGradient = defs.append('linearGradient')
      .attr('id', 'linear-gradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%');
    
    linearGradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#607d8b');
    
    linearGradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#ff584a');
    
    // Add legend box
    this.svg.append('rect')
      .attr('x', legendX - 10)
      .attr('y', legendY - 40)
      .attr('width', legendWidth + 20)
      .attr('height', 100)
      .attr('fill', '#1e1f21')
      .attr('stroke', 'rgba(255, 255, 255, 0.1)')
      .attr('stroke-width', 1)
      .attr('rx', 5)
      .attr('ry', 5);
    
    this.svg.append('rect')
      .attr('x', legendX)
      .attr('y', legendY)
      .attr('width', legendWidth)
      .attr('height', legendHeight)
      .style('fill', 'url(#linear-gradient)')
      .style('stroke', 'rgba(255, 255, 255, 0.1)')
      .style('stroke-width', 0.5);
    
    this.svg.append('text')
      .attr('x', legendX + legendWidth / 2 - 55)
      .attr('y', legendY - 15)
      .attr('text-anchor', 'middle')
      .style('font-size', '14px')
      .style('font-weight', 'bold')
      .style('fill', 'rgb(162, 160, 162)')
      .text('Feature value');
    
    this.svg.append('text')
      .attr('x', legendX)
      .attr('y', legendY + legendHeight + 15)
      .attr('text-anchor', 'start')
      .style('font-size', '12px')
      .style('fill', 'rgb(162, 160, 162)')
      .text('Low');
    
    this.svg.append('text')
      .attr('x', legendX + legendWidth - 55)
      .attr('y', legendY + legendHeight + 15)
      .attr('text-anchor', 'end')
      .style('font-size', '12px')
      .style('fill', 'rgb(162, 160, 162)')
      .text('High');
  }
}
