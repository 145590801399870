// src/app/components/workstream/workstream.component.ts
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxCodeJarComponent } from 'ngx-codejar';
import { Workstream, WorkstreamUnit, MingusDictionaryItem, HighlightedMingus, TableExportConfig } from '../../../models/workboard.model';
import { ToolsService } from '../../../services/tools.service';
import { RiffMLNode } from '../../../services/riffml-parser.service';
import { DebugViewComponent } from '../../session/debug-view/debug-view.component'
import { WorkboardManagerService } from '../../../services/workboard-manager.service';
import * as Prism from 'prismjs';
import 'prismjs/components/prism-sql';

@Component({
  selector: 'app-workstream',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogModule, MatTooltipModule, NgxCodeJarComponent],
  templateUrl: './workstream.component.html',
  styleUrls: ['./workstream.component.scss']
})
export class WorkstreamComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() workstream!: Workstream;
  @Input() isSelected = false;
  @Output() stateToggle = new EventEmitter<Event>();
  @Output() selectionToggle = new EventEmitter<Event>();
  
  @ViewChild('codeDisplay') codeDisplay!: ElementRef<HTMLPreElement>;
  @ViewChild('unitCodeDisplay') unitCodeDisplay!: ElementRef<HTMLPreElement>;
  
  activeView: 'mingus' | 'code' = 'mingus';
  unitActiveViews: {[unitId: string]: 'mingus' | 'code'} = {};
  isDevMode = false;
  displayMode: 'default' | 'units' = 'default';
  expandedUnits: {[unitId: string]: boolean} = {};
  allExpanded = false;
  hasVersionChange = false;
  unitChanges: {[unitId: string]: boolean} = {};
  
  // Properties for edit mode
  editMode = false;
  hasChanges = false;

  // Properties for workboard dimensions
  workboardWidth = 0;
  workboardHeight = 0;

  // ResizeObserver for container size changes
  private resizeObserver: ResizeObserver | null = null;

  // Properties for code binding
  currentCode: string = '';
  unitCodes: { [unitId: string]: string } = {};

  // CodeJar options
  codeJarOptions = {
    tab: '    ',
    indentOn: /[(\[]$/,
    moveToNewLine: /^[)}\]]/,
    spellcheck: false,
    catchTab: true,
    preserveIdent: true,
    addClosing: true,
    history: true
  };

  // Add a cache for processed headers
  private processedHeaderCache: { [unitId: string]: string } = {};
  private processedMingusCache: { [unitId: string]: string } = {};
  private processedParsedMingusCache: { [unitId: string]: HighlightedMingus } = {};

  // Add properties for code and mingus changes
  private codeChangesCache: { [unitId: string]: any[] } = {};
  private mingusChangesCache: { [unitId: string]: any[] } = {};

  constructor(
    private toolsService: ToolsService,
    private dialog: MatDialog,
    private workboardManagerService: WorkboardManagerService
  ) {}

  ngOnInit(): void {
    // Check if dev mode is enabled via URL query parameter
    this.isDevMode = window.location.search.includes('dev');
    
    // Set display mode based on workstream having units
    this.displayMode = this.workstream.units && this.workstream.units.length > 0 ? 'units' : 'default';
    
    // Initialize all units to collapsed state
    if (this.workstream.units) {
      this.workstream.units.forEach(unit => {
        this.expandedUnits[unit.id] = false;
      });
    }

    // Initialize each unit's active view to mingus
    if (this.workstream.units) {
      this.workstream.units.forEach(unit => {
        this.unitActiveViews[unit.id] = 'mingus';
      });
    }

    // Check for version changes
    this.checkVersionChanges();
    
    // Check for unit changes
    this.checkUnitChanges();

    // Initialize current code
    this.updateCurrentCode();

    // Update workboard dimensions
    this.updateWorkboardDimensions();

    // Process mingus for workstream and units on load
    this.processMingusChanges(this.workstream.mingus);
    if (this.workstream.units) {
      this.workstream.units.forEach(unit => {
        this.processMingusChanges(unit.mingus, unit);
      });
    }

    // Process code and mingus changes for each unit
    if (this.workstream.units) {
      this.workstream.units.forEach(unit => {
        // Parse code changes
        if (unit.codeChanges) {
          try {
            const parsedChanges = JSON.parse(unit.codeChanges);
            this.codeChangesCache[unit.id] = Array.isArray(parsedChanges) ? parsedChanges : [];
          } catch (e) {
            console.error('Error parsing code changes:', e);
            this.codeChangesCache[unit.id] = [];
          }
        } else {
          this.codeChangesCache[unit.id] = [];
        }

        // Parse mingus changes
        if (unit.mingusChanges) {
          try {
            const parsedChanges = JSON.parse(unit.mingusChanges);
            this.mingusChangesCache[unit.id] = Array.isArray(parsedChanges) ? parsedChanges : [];
          } catch (e) {
            console.error('Error parsing mingus changes:', e);
            this.mingusChangesCache[unit.id] = [];
          }
        } else {
          this.mingusChangesCache[unit.id] = [];
        }
      });
    }

    if (this.workstream.inEditMode) {
      this.editMode = true;
      this.hasChanges = false;
    }
  }

  ngAfterViewInit(): void {
    this.highlightAllCode();
    // Set up ResizeObserver for container size changes
    this.setupResizeObserver();
  }

  ngOnDestroy(): void {
    // Clean up ResizeObserver
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  private setupResizeObserver(): void {
    const workboardContainer = document.querySelector('.workboard-container') as HTMLElement;
    if (workboardContainer) {
      this.resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          const rect = entry.contentRect;
          this.workboardWidth = rect.width;
          this.workboardHeight = rect.height;
        }
      });
      this.resizeObserver.observe(workboardContainer);
    }
  }

  private updateWorkboardDimensions(): void {
    const workboardContainer = document.querySelector('.workboard-container') as HTMLElement;
    if (workboardContainer) {
      const rect = workboardContainer.getBoundingClientRect();
      this.workboardWidth = rect.width;
      this.workboardHeight = rect.height;
    }
  }

  private highlightAllCode(): void {
    // Highlight main content
    this.highlightCode();

    // Highlight all unit content
    if (this.workstream.units) {
      this.workstream.units.forEach(unit => {
        if (this.expandedUnits[unit.id]) {
          this.highlightUnitCode(unit);
        }
      });
    }
  }

  private highlightCode(): void {
    if (this.codeDisplay) {
      const content = this.contentToShow;
      this.codeDisplay.nativeElement.innerHTML = Prism.highlight(
        content || '',
        Prism.languages['sql'],
        'sql'
      );
    }
  }

  private highlightUnitCode(unit: WorkstreamUnit): void {
    const unitCodeElement = document.querySelector(`#unit-${unit.id}-code`) as HTMLElement;
    if (unitCodeElement) {
      const content = this.getUnitContent(unit);
      unitCodeElement.innerHTML = Prism.highlight(
        content || '',
        Prism.languages['sql'],
        'sql'
      );
    }
  }

  // Helper function to check if a unit has empty content
  isUnitEmpty(unit: WorkstreamUnit): boolean {
    // Check if both mingus and code are empty (null, undefined, or empty string)
    return (!unit.mingus || unit.mingus.trim() === '') && 
           (!unit.code || unit.code.trim() === '');
  }

  checkVersionChanges(): void {
    // Check if there are committed versions and if the current version differs from the last committed version
    if (this.workstream.lastCommitedVersions && this.workstream.lastCommitedVersions.length > 0) {
      const lastCommittedVersion = this.workstream.lastCommitedVersions[this.workstream.lastCommitedVersions.length - 1];
      this.hasVersionChange = lastCommittedVersion.version !== this.workstream.version;
    }
  }

  checkUnitChanges(): void {
    // Skip if no units or no committed versions
    if (!this.workstream.units || !this.workstream.lastCommitedVersions || this.workstream.lastCommitedVersions.length === 0) {
      return;
    }

    const lastCommittedVersion = this.workstream.lastCommitedVersions[this.workstream.lastCommitedVersions.length - 1];
    
    // No units in the last version
    if (!lastCommittedVersion?.units || lastCommittedVersion.units.length === 0) {
      // Mark all current units as changed
      this.workstream.units.forEach(unit => {
        this.unitChanges[unit.id] = true;
      });
      return;
    }

    // Check each current unit against the last committed version
    this.workstream.units.forEach(currentUnit => {
      // Find the corresponding unit in the last committed version
      if (!lastCommittedVersion || !lastCommittedVersion.units) {
        this.unitChanges[currentUnit.id] = true;
        return;
      }
      
      const lastVersionUnit = lastCommittedVersion.units.find(u => u.id === currentUnit.id);
      
      // If unit didn't exist in the last version or its version changed
      if (!lastVersionUnit) {
        this.unitChanges[currentUnit.id] = true;
      } else {
        // Compare versions if available
        if (currentUnit.version && lastVersionUnit.version) {
          this.unitChanges[currentUnit.id] = currentUnit.version !== lastVersionUnit.version;
        } else {
          // If versions not available, do a simple comparison of code or mingus
          this.unitChanges[currentUnit.id] = 
            currentUnit.code !== lastVersionUnit.code || 
            currentUnit.mingus !== lastVersionUnit.mingus;
        }
      }
    });
  }

  toggleState(event: Event): void {
    // Don't toggle state if in edit mode
    if (this.editMode) {
      event.stopPropagation();
      return;
    }
    this.stateToggle.emit(event);
  }

  toggleToFull(event: Event): void {
    event.stopPropagation();
    this.editMode = !this.editMode;
  }

  toggleSelection(event: Event): void {
    this.selectionToggle.emit(event);
  }

  toggleView(): void {
    this.activeView = this.activeView === 'mingus' ? 'code' : 'mingus';
    this.updateCurrentCode();
  }

  executeWorkstream(event: Event): void {
    event.stopPropagation();
    // Implement execution logic here
    console.log('Executing workstream:', this.workstream.id);
  }

  handleTableClick(event: Event, tableId: string): void {
    event.stopPropagation();
    const node: RiffMLNode = {
      tagName: 'tablelink',
      content: '',
      children: [],
      attributes: { tableName: tableId }
    };
    this.toolsService.callTool('tablelink', '', '', node);
  }

  handleOutputTableClick(event: Event, tableDef: TableExportConfig): void {
    event.stopPropagation();
    
    // Generate SQL WITH statements for all units up to and including the one with this table
    const sql = this.workboardManagerService.getWorkstreamSqlWithTables(this.workstream, tableDef.name, 5000);
    
    const node: RiffMLNode = {
      tagName: 'tablelink',
      content: '',
      children: [],
      attributes: { 
        tableName: tableDef.name, 
        code: sql || this.workstream.code || '', // Fallback to workstream.code if sql is empty
        type: this.workstream.type || 'sql',
        chartMingus: tableDef.visualization?.mingus || '',
        chartPrompt: tableDef.visualization?.prompt || ''
      }
    };
    this.toolsService.callTool('tablelink', '', '', node);
  }

  openDebugInfo(event: Event): void {
    event.stopPropagation();
    this.dialog.open(DebugViewComponent, {
      data: this.workstream,
      width: '90vw',
      maxHeight: '90vh'
    });
  }

  toggleUnitExpand(event: Event, unitId: string): void {
    event.stopPropagation();
    this.expandedUnits[unitId] = !this.expandedUnits[unitId];
    if (this.expandedUnits[unitId]) {
      const unit = this.workstream.units?.find(u => u.id === unitId);
      if (unit) {
        // Process mingus when unit is expanded
        this.processMingusChanges(unit.mingus, unit);
        setTimeout(() => this.highlightUnitCode(unit), 0);
      }
    }
  }

  toggleAllExpand(event: Event): void {
    event.stopPropagation();
    this.allExpanded = !this.allExpanded;
    if (this.workstream.units) {
      this.workstream.units.forEach(unit => {
        this.expandedUnits[unit.id] = this.allExpanded;
      });
    }
  }

  toggleUnitView(unitId: string, event: Event): void {
    event.stopPropagation();
    this.unitActiveViews[unitId] = this.unitActiveViews[unitId] === 'mingus' ? 'code' : 'mingus';
    const unit = this.workstream.units?.find(u => u.id === unitId);
    if (unit) {
      setTimeout(() => this.highlightUnitCode(unit), 0);
    }
  }

  getUnitActiveView(unitId: string): 'mingus' | 'code' {
    return this.unitActiveViews[unitId] || 'mingus';
  }

  getUnitContent(unit: WorkstreamUnit): string {
    const view = this.getUnitActiveView(unit.id);
    return view === 'mingus' 
      ? (unit.mingus || 'Mingus will be created here') 
      : (unit.code || 'Code will be created here');
  }

  get contentToShow(): string {
    return this.activeView === 'mingus' 
      ? (this.workstream.mingus || 'Mingus will be created here') 
      : (this.workstream.code || 'Code will be created here');
  }

  shouldShowUnitTitle(unit: WorkstreamUnit): boolean {
    return !unit.output_table_ids || unit.output_table_ids.length === 0;
  }

  isUnitChanged(unitId: string): boolean {
    return !!this.unitChanges[unitId];
  }

  // Methods for edit mode
  toggleEditMode(event: Event): void {
    event.stopPropagation();
    
    if (!this.workstream.inEditMode) {
      // Entering edit mode
      console.log('Entering edit mode');
      this.editMode = true;
      this.hasChanges = false;
      
      // Ensure workstream is in open mode
      if (this.workstream.state === 'closed') {
        this.workstream.state = 'open';
      }
      
      // Set the workstream's edit mode state
      this.workstream.inEditMode = true;
      
      // Initialize staged changes to match current values
      this.workstream.stagedMingus = this.workstream.mingus;
      this.workstream.stagedCode = this.workstream.code;
      
      // Initialize staged changes for units to match current values
      if (this.workstream.units) {
        this.workstream.units.forEach(unit => {
          console.log('Unit entering edit mode:', {
            id: unit.id,
            mingus: unit.mingus,
            highlightedMingus: unit.highlightedMingus,
            stagedMingus: unit.stagedMingus
          });

          // If mingus is a JSON string, parse it and set stagedMingus to processed header lines
          if (unit.mingus && unit.mingus.trim().startsWith('{')) {
            try {
              const processedMingus = this.processMingusChanges(unit.mingus);
              if (processedMingus) {
                const processedHeader = this.processHeaderWithDictionary(processedMingus);
                unit.mingusForComparison = processedHeader;
                unit.stagedMingus = processedHeader;
              } else {
                unit.stagedMingus = unit.mingus;
              }
            } catch (e) {
              console.error('Error processing mingus:', e);
              unit.stagedMingus = unit.mingus;
            }
          } else {
            unit.stagedMingus = unit.mingus;
          }
          unit.stagedCode = unit.code;
        });
      }
      
      // Make sure workstream is open and selected
      if (!this.isSelected) {
        this.isSelected = true;
        this.selectionToggle.emit(new Event('click'));
      }
      
      // Call editWorkstream tool with the current edit mode state
      this.toolsService.callTool('editWorkstream', '', '', null, {
        ...this.workstream,
        inEditMode: true
      });
    } else {
      // Exiting edit mode without saving changes
      console.log('Exiting edit mode');
      this.editMode = false;
      this.hasChanges = false;
      
      // Clear staged changes
      this.workstream.stagedMingus = undefined;
      this.workstream.stagedCode = undefined;
      this.workstream.inEditMode = false;
      
      // Clear cache when exiting edit mode
      this.processedHeaderCache = {};
      
      // Clear staged changes for units and ensure highlightedMingus is preserved
      if (this.workstream.units) {
        this.workstream.units.forEach(unit => {
          console.log('Unit before processing:', {
            id: unit.id,
            mingus: unit.mingus,
            highlightedMingus: unit.highlightedMingus,
            stagedMingus: unit.stagedMingus
          });

          // Store the current mingus before clearing staged changes
          const currentMingus = unit.stagedMingus !== undefined ? unit.stagedMingus : unit.mingus;
          
          // Clear staged changes
          unit.stagedMingus = undefined;
          unit.stagedCode = undefined;
          
          // Process the mingus to ensure highlightedMingus is properly set
          if (currentMingus) {
            const processedMingus = this.processMingusChanges(currentMingus, unit);
            if (processedMingus) {
              unit.highlightedMingus = processedMingus;
              unit.mingusOriginal = currentMingus;
              const processedHeader = this.processHeaderWithDictionary(processedMingus);
              this.processedHeaderCache[unit.id] = processedHeader;
            }
          }

          console.log('Unit after processing:', {
            id: unit.id,
            mingus: unit.mingus,
            highlightedMingus: unit.highlightedMingus,
            stagedMingus: unit.stagedMingus,
            processedHeader: this.processedHeaderCache[unit.id]
          });
        });
      }
    }
  }

  /**
   * Called whenever user edits the code. Updates the staged content.
   */
  handleContentEdit(code: string): void {
    if (this.activeView === 'mingus') {
      this.workstream.stagedMingus = code !== this.workstream.mingus ? code : undefined;
      this.processMingusChanges(code);
    } else {
      this.workstream.stagedCode = code !== this.workstream.code ? code : undefined;
    }
    this.currentCode = code;
    this.checkForChanges();
  }

  /**
   * Called whenever user edits a unit's code. Updates the staged content.
   */
  handleUnitContentEdit(code: string, unit: WorkstreamUnit, type: 'mingus' | 'code'): void {
    if (type === 'mingus') {
      // Store the raw JSON string
      unit.stagedMingus = code !== unit.mingus ? code : undefined;
      
      // Process the mingus to detect if it's a highlighted version
      if (typeof code === 'string' && code.trim().startsWith('{')) {
        try {
          const processedMingus = this.processMingusChanges(code, unit);
          if (processedMingus) {
            unit.highlightedMingus = processedMingus;
            unit.mingusOriginal = code;
            const processedHeader = this.processHeaderWithDictionary(processedMingus);
            this.processedHeaderCache[unit.id] = processedHeader;
          }
        } catch (e) {
          console.error('Error processing mingus:', e);
        }
      }
      
      // Update the editor with the processed mingus
      const editor = document.querySelector(`#unit-${unit.id}-mingus .code-editor`) as HTMLElement;
      if (editor) {
        editor.textContent = code;
        this.highlight(editor);
      }
    } else {
      unit.stagedCode = code !== unit.code ? code : undefined;
      
      // Update the code editor with syntax highlighting
      const editor = document.querySelector(`#unit-${unit.id}-code .code-editor`) as HTMLElement;
      if (editor) {
        editor.textContent = code;
        editor.innerHTML = Prism.highlight(
          code,
          Prism.languages['sql'],
          'sql'
        );
      }
    }
    this.checkForChanges();
  }

  checkForChanges(): void {
    // Check for changes in the main workstream
    let hasMainChanges = false;
    if (this.workstream.stagedMingus !== undefined && this.workstream.stagedMingus !== this.workstream.mingus) {
      hasMainChanges = true;
    }
    if (this.workstream.stagedCode !== undefined && this.workstream.stagedCode !== this.workstream.code) {
      hasMainChanges = true;
    }
    
    // Check for changes in units
    let hasUnitChanges = false;
    if (this.workstream.units) {
      for (const unit of this.workstream.units) {
        // Normalize values for comparison
        const normalizedStagedCode = unit.stagedCode || '';
        const normalizedCurrentCode = unit.code || '';
        
        // Handle mingus comparison
        let hasUnitMingusChanges = false;
        if (unit.stagedMingus !== undefined) {
          // Compare with mingusForComparison if it exists, otherwise fall back to mingus
          hasUnitMingusChanges = unit.stagedMingus !== (unit.mingusForComparison || unit.mingus);
        }
        
        const hasUnitCodeChanges = unit.stagedCode !== undefined && normalizedStagedCode !== normalizedCurrentCode;
        
        if (hasUnitMingusChanges || hasUnitCodeChanges) {
          hasUnitChanges = true;
          console.log('Unit changes detected:', {
            unitId: unit.id,
            hasUnitMingusChanges,
            hasUnitCodeChanges,
            stagedMingus: unit.stagedMingus,
            currentMingus: unit.mingus,
            mingusForComparison: unit.mingusForComparison,
            stagedCode: normalizedStagedCode,
            currentCode: normalizedCurrentCode
          });
          break;
        }
      }
    }
    
    this.hasChanges = hasMainChanges || hasUnitChanges;
    console.log('Checking for changes:', {
      hasMainChanges,
      hasUnitChanges,
      hasChanges: this.hasChanges,
      stagedMingus: this.workstream.stagedMingus,
      stagedCode: this.workstream.stagedCode,
      currentMingus: this.workstream.mingus,
      currentCode: this.workstream.code,
      units: this.workstream.units?.map(unit => ({
        id: unit.id,
        stagedMingus: unit.stagedMingus || '',
        currentMingus: unit.mingus || '',
        mingusForComparison: unit.mingusForComparison || '',
        stagedCode: unit.stagedCode || '',
        currentCode: unit.code || ''
      }))
    });
  }

  async saveChanges(event: Event): Promise<void> {
    event.stopPropagation();
    
    // Update units' code and mingus from staged values
    if (this.workstream.units) {
      this.workstream.units.forEach(unit => {
        if (unit.stagedMingus !== undefined) {       
          // Process the mingus to generate highlighted version
          var tunit = JSON.parse(JSON.stringify(unit));
          this.processMingusChanges(tunit.mingusOriginal, tunit);
          unit.mingusForComparison = tunit.mingus;
        }
      });
    }
    
    this.hasChanges = false;

    // Call userChangedWorkstream tool with the entire workstream
    await this.toolsService.callTool('userChangedWorkstream', '', '', null, {
      workstream: JSON.parse(JSON.stringify(this.workstream)),
      changedValue: this.activeView === 'mingus' ? this.workstream.mingus : this.workstream.code,
      valueType: this.activeView
    });
    
    // Reset changes flag and staged values
    this.workstream.stagedMingus = undefined;
    this.workstream.stagedCode = undefined;
    
    if (this.workstream.units) {
      this.workstream.units.forEach(unit => {
        unit.stagedMingus = undefined;
        unit.stagedCode = undefined;
      });
    }
  }

  private updateCurrentCode(): void {
    if (this.activeView === 'mingus') {
      this.currentCode = this.workstream.stagedMingus !== undefined ? 
        this.workstream.stagedMingus : 
        this.workstream.mingus || '';
    } else {
      this.currentCode = this.workstream.stagedCode !== undefined ? 
        this.workstream.stagedCode : 
        this.workstream.code || '';
    }
  }

  getUnitCode(unit: WorkstreamUnit): string {
    // In edit mode, always return the code
    if (this.editMode) {
      return unit.stagedCode !== undefined ? unit.stagedCode : unit.code || '';
    }
    
    // In non-edit mode, respect the view toggle
    const view = this.getUnitActiveView(unit.id);
    if (view === 'mingus') {
      return unit.stagedMingus !== undefined ? unit.stagedMingus : unit.mingus || '';
    } else {
      return unit.stagedCode !== undefined ? unit.stagedCode : unit.code || '';
    }
  }

  getUnitMingusCode(unit: WorkstreamUnit): string {
    // Get the current mingus content based on edit mode
    const currentMingus = this.editMode ? 
      (unit.stagedMingus !== undefined ? unit.stagedMingus : unit.mingus) : 
      unit.mingus;

    // If no mingus content, return empty string to trigger placeholder
    if (!currentMingus || currentMingus.trim() === '') {
      return '';
    }

    // Check if we have cached processed mingus and it matches current content
    if (unit.mingusOriginal === currentMingus && this.processedHeaderCache[unit.id]) {
      //console.log('Using cached processed mingus');
      return this.processedHeaderCache[unit.id];
    }
    
    // If mingus is a string that looks like JSON, try to parse and process it
    if (typeof currentMingus === 'string' && currentMingus.trim().startsWith('{')) {
      try {
        const processedMingus = this.processMingusChanges(currentMingus, unit);
        if (processedMingus) {
          // Cache the processed result
          unit.mingusOriginal = currentMingus;
          unit.highlightedMingus = processedMingus;
          const processedHeader = this.processHeaderWithDictionary(processedMingus);
          this.processedHeaderCache[unit.id] = processedHeader;
          return processedHeader;
        }
      } catch (e) {
        console.error('Error processing mingus:', e);
      }
    }

    // If all else fails or mingus is not JSON, return the raw mingus
    return currentMingus;
  }

  private processHeaderWithDictionary(highlightedMingus: HighlightedMingus): string {
    if (!highlightedMingus?.header || !highlightedMingus?.dictionary) {
      return '';
    }

    let processedHeader = highlightedMingus.header.join('\n');
    
    // Replace each token with its key (without braces)
    highlightedMingus.dictionary.forEach(item => {
      const token = `{${item.key}}`;
      const escapedToken = token.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedToken, 'g');
      processedHeader = processedHeader.replace(regex, item.key);
    });

    return processedHeader;
  }

  private processMingusChanges(mingus: string | undefined, unit?: WorkstreamUnit): HighlightedMingus | undefined {
    if (!mingus) return undefined;
    
    // First trim spaces
    let trimmedMingus = mingus.trim();

    // Then trim quotes if they exist on both sides
    if (trimmedMingus.startsWith('"') && trimmedMingus.endsWith('"')) {
      trimmedMingus = trimmedMingus.slice(1, -1).trim();
    }
    
    if (!trimmedMingus.startsWith('{')) {
      return;
    }
      
    // Check if we have cached results for this mingus
    const cacheKey = unit?.id || 'main';
    if (this.processedMingusCache[cacheKey] === mingus && this.processedParsedMingusCache[cacheKey]) {
      return this.processedParsedMingusCache[cacheKey];
    }
    
    console.log('Processing mingus changes:', {
      mingus: mingus.substring(0, 100) + '...',  // Log just the start to avoid huge logs
      unitId: unit?.id
    });
    
    if (trimmedMingus.startsWith('{')) {
      try {
        const parsedMingus = JSON.parse(trimmedMingus);
        
        // Ensure the dictionary items have all required fields
        if (parsedMingus.dictionary) {
          parsedMingus.dictionary = parsedMingus.dictionary.map((item: any) => ({
            key: item.key,
            value: item.value,
            location: item.location ? {
              line: parseInt(item.location.line, 10) || 0,
              start: parseInt(item.location.start, 10) || 0,
              end: parseInt(item.location.end, 10) || 0
            } : undefined
          }));
        }

        // Cache the results
        this.processedMingusCache[cacheKey] = mingus;
        this.processedParsedMingusCache[cacheKey] = parsedMingus as HighlightedMingus;

        return parsedMingus as HighlightedMingus;
      } catch (error) {
        console.error('Failed to parse highlighted mingus:', error);
        return undefined;
      }
    }
    
    return undefined;
  }

  getUnitSqlCode(unit: WorkstreamUnit): string {
    const view = this.getUnitActiveView(unit.id);
    if (view === 'mingus') {
      return unit.stagedMingus !== undefined ? unit.stagedMingus : unit.mingus || '';
    } else {
      return unit.stagedCode !== undefined ? unit.stagedCode : unit.code || '';
    }
  }

  /**
   * Highlights the code using Prism
   */
  highlight = (editor: HTMLElement) => {
    let code = editor.textContent || '';
    
    // Extract unit ID from the editor ID
    const editorId = editor.closest('[id]')?.getAttribute('id') || '';
    const unitId = editorId.replace('-mingus', '').replace('unit-', '');
    const unit = this.workstream.units?.find(u => u.id === unitId);
    
    // First apply Prism highlighting
    let highlighted = Prism.highlight(
      code,
      Prism.languages['sql'],
      'sql'
    );

    // Split the code into lines for proper line number handling
    const lines = code.split('\n');
    
    // Handle mingus tokens if available
    if (unit?.highlightedMingus) {
      // Then replace our tokens with highlighted spans that include tooltips and data attributes
      unit.highlightedMingus.dictionary.forEach((item: MingusDictionaryItem) => {
        const token = item.key;
        const escapedToken = token.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const escapedValue = item.value
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#39;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;');

        // Find the actual line content if location exists
        const lineNumber = item.location?.line;
        if (lineNumber !== undefined && lineNumber > 0 && lineNumber <= lines.length) {
          const lineContent = lines[lineNumber - 1];
          const regex = new RegExp(`(>[^<]*)(${escapedToken})([^<]*<)`, 'g');
          
          // Add data attributes for the location
          const locationAttrs = item.location ? 
            `data-line="${item.location.line}" data-start="${item.location.start}" data-end="${item.location.end}"` : '';
          
          highlighted = highlighted.replace(
            regex, 
            `$1<span class="mingus-token" title="${escapedValue}" ${locationAttrs} onmouseover="this.dispatchEvent(new CustomEvent('token-hover', {bubbles: true, detail: this.dataset}))" onmouseout="this.dispatchEvent(new CustomEvent('token-hover-end', {bubbles: true}))">$2</span>$3`
          );
        }
      });
    }

    // Set the initial innerHTML with basic highlighting and tokens
    editor.innerHTML = highlighted;

    // Now apply change highlights by working with the DOM directly
    if (unit && (this.codeChangesCache[unit.id] || this.mingusChangesCache[unit.id])) {
      // Apply mingus changes to the mingus editor
      if (this.mingusChangesCache[unit.id]) {
        this.applyMingusChangeHighlights(editor, this.mingusChangesCache[unit.id], lines);
      }

      // Apply code changes to the code editor
      if (this.codeChangesCache[unit.id]) {
        const codeEditorId = `unit-${unitId}-code`;
        this.waitForCodeEditor(codeEditorId).then(codeEditor => {
          if (codeEditor) {
            const codeLines = codeEditor.textContent?.split('\n') || [];
            this.applyCodeChangeHighlights(codeEditor, this.codeChangesCache[unit.id], codeLines);
          }
        });
      }
    }

    // Add event listeners for token hover
    if (this.editMode) {
      const codeEditorId = `unit-${unitId}-code`;
      this.waitForCodeEditor(codeEditorId).then(codeEditor => {
        if (codeEditor) {
          const codeEditorWrapper = document.querySelector(`#${codeEditorId}`) as HTMLElement;
          this.setupTokenHoverListeners(editor, codeEditor, codeEditorWrapper);
        }
      });
    }

    // Add event listeners for change hover
    if (this.editMode) {
      this.setupChangeHoverListeners(editor);
    }
  }

  private waitForCodeEditor(editorId: string): Promise<HTMLElement | null> {
    return new Promise((resolve) => {
      const checkEditor = () => {
        const editor = document.querySelector(`#${editorId} pre`) as HTMLElement;
        if (editor && editor.textContent) {
          resolve(editor);
        } else {
          // Use MutationObserver to watch for changes in the editor
          const observer = new MutationObserver((mutations, obs) => {
            const editor = document.querySelector(`#${editorId} pre`) as HTMLElement;
            if (editor && editor.textContent) {
              obs.disconnect();
              resolve(editor);
            }
          });

          // Start observing the editor container
          const editorContainer = document.querySelector(`#${editorId}`);
          if (editorContainer) {
            observer.observe(editorContainer, {
              childList: true,
              subtree: true,
              characterData: true
            });
          } else {
            resolve(null);
          }
        }
      };

      // Initial check
      checkEditor();
    });
  }

  private applyMingusChangeHighlights(editor: HTMLElement, changes: any[], lines: string[]): void {
    changes.forEach(change => {
      const lineNumber = change.location?.line;
      if (!lineNumber || lineNumber <= 0 || lineNumber > lines.length) return;

      // Find the line element in the DOM
      const lineNodes = this.findNodesInLine(editor, lineNumber);
      if (lineNodes.length === 0) return;

      const lineElement = lineNodes[0].parentElement?.closest('.line') || lineNodes[0].parentElement;
      if (!lineElement) return;

      // Add highlight to the line
      lineElement.classList.add('highlighted-line');

      // Find the text node containing the change
      let currentPos = 0;
      const startPos = change.location.start;
      const endPos = change.location.end;

      lineNodes.forEach(node => {
        const text = node.textContent || '';
        const nodeEnd = currentPos + text.length;

        if (nodeEnd > startPos && currentPos < endPos) {
          // Create a span for the change
          const changeSpan = document.createElement('span');
          changeSpan.className = 'mingus-change';
          changeSpan.setAttribute('data-line', lineNumber.toString());
          changeSpan.setAttribute('data-start', startPos.toString());
          changeSpan.setAttribute('data-end', endPos.toString());
          if (change.description) {
            changeSpan.setAttribute('data-description', change.description);
          }

          // Add hover events
          changeSpan.addEventListener('mouseover', () => {
            changeSpan.dispatchEvent(new CustomEvent('change-hover', {
              bubbles: true,
              detail: {
                line: lineNumber,
                start: startPos,
                end: endPos,
                description: change.description
              }
            }));
          });

          changeSpan.addEventListener('mouseout', () => {
            changeSpan.dispatchEvent(new CustomEvent('change-hover-end', {
              bubbles: true
            }));
          });

          // Wrap the text in the span
          if (node instanceof HTMLElement) {
            node.classList.add('highlighted-text');
          } else {
            const wrapper = document.createElement('span');
            wrapper.className = 'highlighted-text';
            node.parentNode?.insertBefore(wrapper, node);
            wrapper.appendChild(node);
          }
        }

        currentPos = nodeEnd;
      });
    });
  }

  private applyCodeChangeHighlights(editor: HTMLElement, changes: any[], lines: string[]): void {
    changes.forEach(change => {
      const lineNumber = change.location?.line;
      if (!lineNumber || lineNumber <= 0 || lineNumber > lines.length) return;

      // Find the line element in the DOM
      const lineNodes = this.findNodesInLine(editor, lineNumber);
      if (lineNodes.length === 0) return;

      const lineElement = lineNodes[0].parentElement?.closest('.line') || lineNodes[0].parentElement;
      if (!lineElement) return;

      // Add highlight to the line
      lineElement.classList.add('highlighted-line');

      // Find the text node containing the change
      let currentPos = 0;
      const startPos = change.location.start;
      const endPos = change.location.end;

      lineNodes.forEach(node => {
        const text = node.textContent || '';
        const nodeEnd = currentPos + text.length;

        if (nodeEnd > startPos && currentPos < endPos) {
          // Create a span for the change
          const changeSpan = document.createElement('span');
          changeSpan.className = 'code-change';
          changeSpan.setAttribute('data-line', lineNumber.toString());
          changeSpan.setAttribute('data-start', startPos.toString());
          changeSpan.setAttribute('data-end', endPos.toString());
          if (change.description) {
            changeSpan.setAttribute('data-description', change.description);
          }

          // Add hover events
          changeSpan.addEventListener('mouseover', () => {
            changeSpan.dispatchEvent(new CustomEvent('change-hover', {
              bubbles: true,
              detail: {
                line: lineNumber,
                start: startPos,
                end: endPos,
                description: change.description
              }
            }));
          });

          changeSpan.addEventListener('mouseout', () => {
            changeSpan.dispatchEvent(new CustomEvent('change-hover-end', {
              bubbles: true
            }));
          });

          // Wrap the text in the span
          if (node instanceof HTMLElement) {
            node.classList.add('highlighted-text');
          } else {
            const wrapper = document.createElement('span');
            wrapper.className = 'highlighted-text';
            node.parentNode?.insertBefore(wrapper, node);
            wrapper.appendChild(node);
          }
        }

        currentPos = nodeEnd;
      });
    });
  }

  private setupTokenHoverListeners(mingusEditor: HTMLElement, codeEditor: HTMLElement, codeEditorWrapper: HTMLElement): void {
    const findNodesInLine = (lineNumber: number): Node[] => {
      const nodes: Node[] = [];
      let currentLine = 1;
      let currentNode = codeEditor.firstChild;
      
      while (currentNode) {
        const text = currentNode.textContent || '';
        const lines = text.split('\n');
        
        if (currentLine === lineNumber) {
          nodes.push(currentNode);
        } else if (currentLine > lineNumber && nodes.length > 0) {
          break;
        }
        
        if (lines.length > 1) {
          currentLine += lines.length - 1;
        }
        
        currentNode = currentNode.nextSibling;
      }
      
      return nodes;
    };

    mingusEditor.addEventListener('token-hover', ((e: CustomEvent) => {
      const { line, start, end } = e.detail;
      if (!line || !start || !end) return;

      const lineNumber = parseInt(line);
      const startPos = parseInt(start);
      const endPos = parseInt(end);
      
      // Find all nodes in the target line
      const lineNodes = findNodesInLine(lineNumber);
      
      if (lineNodes.length > 0) {
        let currentPos = 0;
        let firstNodeInLine = lineNodes[0];
        
        // Find the parent line element and add highlight
        const lineElement = firstNodeInLine.parentElement?.closest('.line') || firstNodeInLine.parentElement;
        if (lineElement) {
          lineElement.classList.add('highlighted-line');
        }
        
        lineNodes.forEach(node => {
          const text = node.textContent || '';
          const nodeEnd = currentPos + text.length;
          
          // Add text highlight if in range
          if (nodeEnd > startPos && currentPos < endPos) {
            if (node instanceof HTMLElement) {
              node.classList.add('highlighted-text');
            } else {
              const wrapper = document.createElement('span');
              wrapper.className = 'highlighted-text';
              node.parentNode?.insertBefore(wrapper, node);
              wrapper.appendChild(node);
            }
          }
          
          currentPos = nodeEnd;
        });

        // Scroll both editors to show the highlighted line
        const scrollToLine = (element: HTMLElement, lineNum: number) => {
          const lineHeight = 20; // Approximate line height in pixels
          const scrollPosition = (lineNum - 1) * lineHeight;
          const padding = 100; // Add some padding to ensure line is not at the very top
          
          // Ensure the element is scrollable
          if (element.scrollHeight > element.clientHeight) {
            element.scrollTop = Math.max(0, scrollPosition - padding);
          }
        };

        // Scroll the code editor wrapper
        if (codeEditorWrapper instanceof HTMLElement) {
          scrollToLine(codeEditorWrapper, lineNumber);
        }

        // Scroll the mingus editor wrapper
        const mingusEditorWrapper = mingusEditor.closest('.code-editor-wrapper');
        if (mingusEditorWrapper instanceof HTMLElement) {
          scrollToLine(mingusEditorWrapper, lineNumber);
        }

        // Also try to scroll the pre element if needed
        if (codeEditor instanceof HTMLElement) {
          scrollToLine(codeEditor, lineNumber);
        }
      }
    }) as EventListener);

    mingusEditor.addEventListener('token-hover-end', () => {
      // Remove line highlights
      codeEditor.querySelectorAll('.highlighted-line').forEach(el => {
        el.classList.remove('highlighted-line');
      });

      // Remove text highlights
      codeEditor.querySelectorAll('.highlighted-text').forEach(el => {
        if (el.classList.length === 1) {
          const parent = el.parentNode;
          while (el.firstChild) {
            parent?.insertBefore(el.firstChild, el);
          }
          el.remove();
        } else {
          el.classList.remove('highlighted-text');
        }
      });
    });
  }

  private setupChangeHoverListeners(editor: HTMLElement): void {
    editor.addEventListener('change-hover', ((e: CustomEvent) => {
      const { line, start, end, description } = e.detail;
      if (!line || !start || !end) return;

      const lineNumber = parseInt(line);
      const startPos = parseInt(start);
      const endPos = parseInt(end);
      
      // Find all nodes in the target line
      const lineNodes = this.findNodesInLine(editor, lineNumber);
      
      if (lineNodes.length > 0) {
        let currentPos = 0;
        let firstNodeInLine = lineNodes[0];
        
        // Find the parent line element and add highlight
        const lineElement = firstNodeInLine.parentElement?.closest('.line') || firstNodeInLine.parentElement;
        if (lineElement) {
          lineElement.classList.add('highlighted-line');
        }
        
        lineNodes.forEach(node => {
          const text = node.textContent || '';
          const nodeEnd = currentPos + text.length;
          
          // Add text highlight if in range
          if (nodeEnd > startPos && currentPos < endPos) {
            if (node instanceof HTMLElement) {
              node.classList.add('highlighted-text');
            } else {
              const wrapper = document.createElement('span');
              wrapper.className = 'highlighted-text';
              node.parentNode?.insertBefore(wrapper, node);
              wrapper.appendChild(node);
            }
          }
          
          currentPos = nodeEnd;
        });

        // Show tooltip with description
        const tooltip = document.createElement('div');
        tooltip.className = 'change-tooltip';
        tooltip.textContent = description;
        document.body.appendChild(tooltip);

        // Position tooltip
        const rect = editor.getBoundingClientRect();
        const lineHeight = 20; // Approximate line height in pixels
        const scrollTop = editor.scrollTop;
        const tooltipTop = rect.top + (lineNumber - 1) * lineHeight - scrollTop - tooltip.offsetHeight - 10;
        const tooltipLeft = rect.left + 10;
        
        tooltip.style.top = `${tooltipTop}px`;
        tooltip.style.left = `${tooltipLeft}px`;
      }
    }) as EventListener);

    editor.addEventListener('change-hover-end', () => {
      // Remove line highlights
      editor.querySelectorAll('.highlighted-line').forEach(el => {
        el.classList.remove('highlighted-line');
      });

      // Remove text highlights
      editor.querySelectorAll('.highlighted-text').forEach(el => {
        if (el.classList.length === 1) {
          const parent = el.parentNode;
          while (el.firstChild) {
            parent?.insertBefore(el.firstChild, el);
          }
          el.remove();
        } else {
          el.classList.remove('highlighted-text');
        }
      });

      // Remove tooltip
      const tooltip = document.querySelector('.change-tooltip');
      if (tooltip) {
        tooltip.remove();
      }
    });
  }

  private findNodesInLine(element: HTMLElement, lineNumber: number): Node[] {
    const nodes: Node[] = [];
    let currentLine = 1;
    let currentNode = element.firstChild;
    
    while (currentNode) {
      const text = currentNode.textContent || '';
      const lines = text.split('\n');
      
      if (currentLine === lineNumber) {
        nodes.push(currentNode);
      } else if (currentLine > lineNumber && nodes.length > 0) {
        break;
      }
      
      if (lines.length > 1) {
        currentLine += lines.length - 1;
      }
      
      currentNode = currentNode.nextSibling;
    }
    
    return nodes;
  }

  async handleLanguageSelection(event: Event, language: string, unit?: any) {
    event.stopPropagation();
    
    if (unit) {
      // Store original code if we're changing from a different type
      if (language && language !== this.workstream.type) {
        if (!unit.originalCode) {
          unit.originalCode = unit.code;
        }
        //unit.code = 'Generating...';
      } else if (unit.originalCode && language === this.workstream.type) {
        // Restore original code if we're switching back to the original type
        //unit.code = unit.originalCode;
      }
      unit.type = language;
    } else {
      this.workstream.type = language;
    }

    // Call the Change unit code tool
    const code = unit ? unit.code : this.currentCode;
    await this.toolsService.callTool('Change unit code', '', '', null, {
      code,
      language
    });

    this.hasChanges = true;
  }
}