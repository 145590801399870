/**
 * @file src/app/services/tools/workstreamlink-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';

/**
 * Tool: workstreamlink
 * Sets the current workstream
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerWorkstreamLinkTool(service: ToolsService): void {
  (service as any).workstreamlink = async function(nextVal: string, sessionId: string, riffMLNode: RiffMLNode | null, value:any): Promise<void> {
    if (!riffMLNode) return;

    let workstreamId = riffMLNode.attributes['id'];
    if (!workstreamId) return;

    this.workboardManagerService.autoSelectWorkstreams([workstreamId], 'auto-select');
  };
}