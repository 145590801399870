<!-- src/app/components/tabs/tab-container/tab-container.component.html -->
<div class="tabs-container">
    <div class="tabs-header">
      <div class="tabs-wrapper">
        <div class="tabs-list" [class.shrink-tabs]="shouldShrinkTabs">
          <ng-container *ngFor="let tab of tabs">
            <app-tab
              [tab]="tab"
              [color]="getTabColor(tab.sessionId)"
              (close)="onTabClose(tab.sessionId)"
              (activate)="onTabActivate(tab.sessionId)">
            </app-tab>
          </ng-container>
          <ng-container *ngIf="tabs.length === 0">
            <span class="empty-state">Start Session</span>
          </ng-container>
        </div>
        <button mat-icon-button 
                class="new-tab-button" 
                (click)="createNewSession()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    
    <div class="tabs-content">
      <ng-container *ngFor="let tab of tabs">
        <div *ngIf="tab.active" class="tab-content">
          <ng-container [ngSwitch]="tab.type">
            <app-chat *ngSwitchCase="'chat'" [sessionId]="tab.sessionId"></app-chat>
            <app-sessions-history *ngSwitchCase="'sessions-history'"></app-sessions-history>
            <app-start-page *ngSwitchCase="'start'"></app-start-page>
            <div *ngSwitchDefault>Unknown tab type</div>
          </ng-container>
        </div>
      </ng-container>
    </div>
</div>