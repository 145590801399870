<!-- src/app/components/records/records.component.html -->
<div class="records-container">
  <div class="filter-tabs">
    <div class="tab" [class.active]="activeTab === 'all'" (click)="setActiveTab('all')">All Records</div>
    <div class="tab" [class.active]="activeTab === 'byType'" (click)="setActiveTab('byType')">By Type</div>
    <div class="tab" [class.active]="activeTab === 'byDate'" (click)="setActiveTab('byDate')">By Date</div>
    <div class="tab" [class.active]="activeTab === 'byUser'" (click)="setActiveTab('byUser')">By User</div>
    <div class="tab" [class.active]="activeTab === 'byImportance'" (click)="setActiveTab('byImportance')">By Importance</div>
  </div>
  
  <div class="filter-controls">
    <!-- Project filter - always visible -->
    <div class="filter-group">
      <label>Project:</label>
      <select [ngModel]="selectedProjectId" (change)="filterByProject($event)">
        <option value="all">All Projects</option>
        <option *ngFor="let project of projects" [value]="project.id">{{ project.name || project.label || project.id }}</option>
      </select>
    </div>
    
    <!-- Session filter - visible when a project is selected -->
    <div class="filter-group" *ngIf="selectedProjectId !== 'all' && sessions.length > 0">
      <label>Session:</label>
      <select [ngModel]="selectedSessionId" (change)="filterBySession($event)">
        <option *ngFor="let session of sessions" [value]="session.id">{{ session.name }}</option>
      </select>
      <div *ngIf="loadingSessions" class="loading-indicator-inline">
        <mat-spinner diameter="16"></mat-spinner>
      </div>
    </div>
    
    <!-- Type filter - visible when byType tab is active -->
    <div class="filter-group" *ngIf="activeTab === 'byType'">
      <label>Memory Type:</label>
      <select (change)="filterByMemoryType($event)">
        <option value="all">All Types</option>
        <option value="data_issue">Data Issue</option>
        <option value="model_evaluation">Model Evaluation</option>
        <option value="default">Other</option>
      </select>
    </div>
    
    <!-- User filter - visible when byUser tab is active -->
    <div class="filter-group" *ngIf="activeTab === 'byUser'">
      <label>User:</label>
      <select (change)="filterByUser($event)">
        <option value="all">All Users</option>
        <option value="Marketing Manager">Marketing Manager</option>
        <option value="Data Analyst">Data Analyst</option>
        <option value="Data Engineer">Data Engineer</option>
        <option value="default">Other</option>
      </select>
    </div>
    
    <!-- Date filter - visible when byDate tab is active -->
    <div class="filter-group" *ngIf="activeTab === 'byDate'">
      <label>Time Range:</label>
      <select (change)="filterByTimeRange($event)">
        <option value="all">All Time</option>
        <option value="today">Today</option>
        <option value="week">This Week</option>
        <option value="month">This Month</option>
        <option value="custom">Custom Range</option>
      </select>
      
      <div class="date-range" *ngIf="selectedTimeRange === 'custom'">
        <input type="date" [value]="startDate" (change)="updateStartDate($event)">
        <span>to</span>
        <input type="date" [value]="endDate" (change)="updateEndDate($event)">
      </div>
    </div>
    
    <!-- Importance filter - visible when byImportance tab is active -->
    <div class="filter-group" *ngIf="activeTab === 'byImportance'">
      <label>Minimum Importance:</label>
      <select (change)="filterByImportance($event)">
        <option value="0">Any</option>
        <option value="3">3+</option>
        <option value="5">5+</option>
        <option value="7">7+</option>
        <option value="9">9+</option>
      </select>
    </div>
    
    <div class="zoom-controls">
      <!-- Reset filters button -->
      <button class="reset-filters-button" (click)="resetAllFilters()" title="Reset all filters">
        Reset Filters
      </button>
      <button class="zoom-in" title="Zoom In">+</button>
      <button class="zoom-out" title="Zoom Out">-</button>
      <button class="zoom-reset" title="Reset Zoom">↺</button>
      <!-- Legend toggle button moved to zoom controls -->
      <button class="legend-toggle" title="{{ legendsExpanded ? 'Hide Legends' : 'Show Legends' }}" (click)="toggleLegendsPanel()">
        <mat-icon>{{ legendsExpanded ? 'chevron_right' : 'chevron_left' }}</mat-icon>
      </button>
    </div>
  </div>
    
  <div *ngIf="loadingProjects" class="loading-indicator">
    <mat-spinner diameter="30"></mat-spinner>
    <span>Loading Records...</span>
  </div>
  
  <div class="main-content-wrapper">
    <div class="diagram-wrapper" [ngClass]="{'legends-collapsed': !legendsExpanded, 'details-open': selectedMemory}">
      <!-- Always visible diagram container -->
      <figure id="records-diagram"></figure>
    </div>
    
    <!-- Memory details panel -->
    <div class="memory-details-panel" *ngIf="selectedMemory" [ngClass]="{'open': selectedMemory}">
      <div class="panel-header">
        <h2>Memory Details</h2>
        <button class="close-button" (click)="closeMemoryDetails()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      
      <div class="panel-content">
        <div class="memory-type-badge" [ngStyle]="{'background-color': getMemoryTypeColor(selectedMemory.type)}">
          {{ getTypeLabel(selectedMemory.type) }}
        </div>
        
        <div class="memory-details">
          <div class="memory-id">
            <strong>ID:</strong> {{ selectedMemory.memory_id }}
          </div>
          
          <div class="memory-metadata">
            <div class="metadata-item" *ngIf="selectedMemory.created_date">
              <strong>Created:</strong> {{ formatDate(selectedMemory.created_date) }}
            </div>
            
            <div class="metadata-item" *ngIf="selectedMemory.importance">
              <strong>Importance:</strong> 
              <span class="importance-badge" 
                [ngClass]="getImportanceClass(selectedMemory.importance)" 
                (click)="toggleFeatureImportanceChart()"
                style="cursor: pointer; text-decoration: underline;">
                {{ selectedMemory.importance }}/10
              </span>
              <span *ngIf="showFeatureImportanceChart" style="margin-left: 5px; font-size: 12px; color: #66bb6a;">(Chart showing)</span>
            </div>
            
            <div class="metadata-item" *ngIf="selectedMemory.reliability">
              <strong>Reliability:</strong> 
              <span class="reliability-badge" [ngClass]="getReliabilityClass(selectedMemory.reliability)">
                {{ selectedMemory.reliability }}/10
              </span>
            </div>
          </div>
          
          <!-- Collapsible Observation Section -->
          <button class="section-header" *ngIf="selectedMemory.observation" (click)="toggleSection('observation')">
            <span class="section-icon" [ngClass]="{'expanded': expandedSections['observation']}">›</span>
            <span>Observation</span>
          </button>
          <div class="section-content" [ngClass]="{'expanded': expandedSections['observation']}" *ngIf="selectedMemory.observation">
            <div class="memory-content-section">
              <p>{{ selectedMemory.observation }}</p>
            </div>
          </div>
          
          <!-- Collapsible Insight Section -->
          <button class="section-header" *ngIf="selectedMemory.insight" (click)="toggleSection('insight')">
            <span class="section-icon" [ngClass]="{'expanded': expandedSections['insight']}">›</span>
            <span>Insight</span>
          </button>
          <div class="section-content" [ngClass]="{'expanded': expandedSections['insight']}" *ngIf="selectedMemory.insight">
            <div class="memory-content-section">
              <p>{{ selectedMemory.insight }}</p>
            </div>
          </div>
          
          <!-- Collapsible Session Section -->
          <button class="section-header" *ngIf="selectedMemory.session_id" (click)="toggleSection('session')">
            <span class="section-icon" [ngClass]="{'expanded': expandedSections['session']}">›</span>
            <span>Session</span>
          </button>
          <div class="section-content" [ngClass]="{'expanded': expandedSections['session']}" *ngIf="selectedMemory.session_id">
            <div class="memory-content-section">
              <p>{{ selectedMemory.session_id }}</p>
              <p *ngIf="selectedMemory.session_name">{{ selectedMemory.session_name }}</p>
            </div>
          </div>
          
          <!-- Collapsible Project Section -->
          <button class="section-header" *ngIf="selectedMemory.project_id" (click)="toggleSection('project')">
            <span class="section-icon" [ngClass]="{'expanded': expandedSections['project']}">›</span>
            <span>Project</span>
          </button>
          <div class="section-content" [ngClass]="{'expanded': expandedSections['project']}" *ngIf="selectedMemory.project_id">
            <div class="memory-content-section">
              <p>{{ selectedMemory.project_id }}</p>
            </div>
          </div>
        </div>
        
        <!-- Collapsible Related Records Section -->
        <button class="section-header" *ngIf="relatedRecords && relatedRecords.length > 0" (click)="toggleSection('relatedRecords')">
          <span class="section-icon" [ngClass]="{'expanded': expandedSections['relatedRecords']}">›</span>
          <span>Related Records ({{ relatedRecords.length }})</span>
        </button>
        <div class="section-content" [ngClass]="{'expanded': expandedSections['relatedRecords']}" *ngIf="relatedRecords && relatedRecords.length > 0">
          <div class="related-records">
            <div class="records-list">
              <div class="record-item" *ngFor="let record of relatedRecords" [attr.data-type]="record.type">
                <div class="record-type">{{ record.type }}</div>
                <div class="record-name">{{ record.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Feature importance chart panel -->
    <div class="feature-importance-container" *ngIf="showFeatureImportanceChart && selectedMemory">
      <app-feature-importance-chart
        [memoryId]="selectedMemory.memory_id"
        (close)="closeFeatureImportanceChart()">
      </app-feature-importance-chart>
    </div>
    
    <!-- Vertical legends panel on the right side -->
    <div class="legends-panel-wrapper" [ngClass]="{'collapsed': !legendsExpanded}">
      <!-- Legends panel content -->
      <div class="legends-panel">
        <!-- Column Types Legend -->
        <div class="legend" *ngIf="usedTypes.size > 0">
          <h4 class="legend-header">Column Types</h4>
          <div class="legend-items">
            <div class="legend-item" *ngFor="let type of usedTypes">
              <div class="color-box" [style.background-color]="getTypeColor(type)"></div>
              <div class="label">{{ type | titlecase }}</div>
            </div>
          </div>
        </div>
        
        <!-- Memory Types Legend -->
        <div class="legend">
          <h4 class="legend-header">Memory Types</h4>
          <div class="legend-items">
            <div class="legend-item" *ngFor="let type of memoryTypes">
              <div class="color-box" [style.background-color]="memoryTypeColorMap[type]"></div>
              <div class="label">{{ type | titlecase }}</div>
            </div>
          </div>
        </div>
        
        <!-- User Types Legend -->
        <div class="legend">
          <h4 class="legend-header">User Types</h4>
          <div class="legend-items">
            <div class="legend-item" *ngFor="let type of userTypes">
              <div class="color-box" [style.background-color]="userTypeColorMap[type]"></div>
              <div class="label">{{ type | titlecase }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>