import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryMenuService, SolutionData, Solution } from '../../services/library-menu.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  solutionData$: Observable<SolutionData | null>;
  selectedSolution: Solution | null = null;

  constructor(private libraryMenuService: LibraryMenuService) {
    this.solutionData$ = this.libraryMenuService.solutionData$;
  }

  ngOnInit() {
    // Initialize any necessary data
  }

  getFilteredSolutions(solutionData: SolutionData): Solution[] {
    if (!solutionData) return [];
    return solutionData.categories.reduce((acc, category) => {
      return [...acc, ...category.solutions];
    }, [] as Solution[]);
  }

  openModal(solution: Solution) {
    this.selectedSolution = solution;
  }
} 