import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChatMessage, ChatSession, Artifact, MessageRole, SessionStatus } from '../models/chat.models';
import { RecommendedFlow } from '../models/recommended-flow';
import { RecommendedFlowsService } from './recommended-flows.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private messagesSubject = new BehaviorSubject<ChatMessage[]>([]);
  private artifactsSubject = new BehaviorSubject<Artifact[]>([]);
  recommendedFlows$ = this.recommendedFlowsService.recommendedFlows$;
  private messageHandlers: ((message: ChatMessage) => void)[] = [];

  private mockResponses: Record<string, string> = {
    'hello': 'Hi there! How can I help you today?',
    'thanks': 'de nada'
  };

  private currentProject?: string;
  private currentPhase?: string;

  constructor(private recommendedFlowsService: RecommendedFlowsService) {}

  onMessage(handler: (message: ChatMessage) => void): () => void {
    this.messageHandlers.push(handler);
    return () => {
      const index = this.messageHandlers.indexOf(handler);
      if (index > -1) {
        this.messageHandlers.splice(index, 1);
      }
    };
  }

  private notifyMessageHandlers(message: ChatMessage): void {
    this.messageHandlers.forEach(handler => handler(message));
  }

  async getMessages(
    sessionId: string,
    userId: string,
    limit?: number,
    before?: Date
  ): Promise<ChatMessage[]> {
    console.log(`[ChatService] Getting messages for session ${sessionId}`, { userId, limit, before });
    const messages = this.messagesSubject.value.filter(m => m.sessionId === sessionId);
    console.log(`[ChatService] Found ${messages.length} messages`);
    return messages;
  }

  async sendMessage(
    sessionId: string,
    content: string,
    userId: string,
    artifacts?: Artifact[]
  ): Promise<ChatMessage> {
    console.log(`[ChatService] Sending message in session ${sessionId}`, { content, userId });
    
    const userMessage: ChatMessage = {
      id: uuidv4(),
      sessionId,
      content,
      role: MessageRole.User,
      senderId: userId,
      timestamp: new Date(),
      artifacts: artifacts || [],
    };

    const currentMessages = this.messagesSubject.value;
    const updatedMessages = [...currentMessages, userMessage];
    this.messagesSubject.next(updatedMessages);
    console.log('[ChatService] User message added to state', userMessage);
    this.notifyMessageHandlers(userMessage);

    // Generate mock response
    const response = this.mockResponses[content.toLowerCase()] || 'I don\'t understand';
    console.log('[ChatService] Generated mock response:', response);
    
    const agentMessage: ChatMessage = {
      id: uuidv4(),
      sessionId,
      content: response,
      role: MessageRole.Agent,
      senderId: 'assistant-1',
      timestamp: new Date(),
      artifacts: [],
    };

    // Add agent response after a small delay
    setTimeout(() => {
      console.log('[ChatService] Adding agent response after delay');
      const messages = this.messagesSubject.value;
      this.messagesSubject.next([...messages, agentMessage]);
      this.notifyMessageHandlers(agentMessage);
      console.log('[ChatService] Agent message added to state', agentMessage);
    }, 1000);

    return userMessage;
  }

  async getSessionArtifacts(
    sessionId: string,
    userId: string
  ): Promise<Artifact[]> {
    console.log(`[ChatService] Getting artifacts for session ${sessionId}`, { userId });
    const artifacts = this.artifactsSubject.value.filter(a => 
      this.messagesSubject.value
        .filter(m => m.sessionId === sessionId)
        .some(m => m.artifacts.some(ma => ma.id === a.id))
    );
    console.log(`[ChatService] Found ${artifacts.length} artifacts`);
    return artifacts;
  }

  async createArtifact(
    sessionId: string,
    artifact: Omit<Artifact, 'id' | 'createdAt' | 'updatedAt'>,
    userId: string
  ): Promise<Artifact> {
    console.log(`[ChatService] Creating artifact in session ${sessionId}`, { artifact, userId });
    const newArtifact: Artifact = {
      ...artifact,
      id: uuidv4(),
      createdAt: new Date(),
    };

    const currentArtifacts = this.artifactsSubject.value;
    this.artifactsSubject.next([...currentArtifacts, newArtifact]);
    console.log('[ChatService] New artifact created:', newArtifact);
    return newArtifact;
  }

  async updateArtifact(
    sessionId: string,
    artifactId: string,
    updates: Partial<Omit<Artifact, 'id' | 'createdAt' | 'updatedAt'>>,
    userId: string
  ): Promise<Artifact> {
    console.log(`[ChatService] Updating artifact ${artifactId} in session ${sessionId}`, { updates, userId });
    const currentArtifacts = this.artifactsSubject.value;
    const artifactIndex = currentArtifacts.findIndex(a => a.id === artifactId);
    
    if (artifactIndex === -1) {
      throw new Error('Artifact not found');
    }

    const updatedArtifact: Artifact = {
      ...currentArtifacts[artifactIndex],
      ...updates,
      updatedAt: new Date()
    };

    const updatedArtifacts = [...currentArtifacts];
    updatedArtifacts[artifactIndex] = updatedArtifact;
    this.artifactsSubject.next(updatedArtifacts);
    console.log('[ChatService] Artifact updated:', updatedArtifact);
    return updatedArtifact;
  }

  async deleteArtifact(
    sessionId: string,
    artifactId: string,
    userId: string
  ): Promise<void> {
    console.log(`[ChatService] Deleting artifact ${artifactId} in session ${sessionId}`, { userId });
    const currentArtifacts = this.artifactsSubject.value;
    this.artifactsSubject.next(
      currentArtifacts.filter(a => a.id !== artifactId)
    );
    console.log('[ChatService] Artifact deleted');
  }

  async hasMessages(sessionId: string): Promise<boolean> {
    const messages = await this.getMessages(sessionId, 'default-user', 1);
    return messages.length > 0;
  }

  onArtifactUpdate(callback: (artifact: Artifact) => void): void {
    this.artifactsSubject.subscribe(artifacts => {
      if (artifacts.length > 0) {
        callback(artifacts[artifacts.length - 1]);
      }
    });
  }

  updateContextReferences(projectId?: string, phaseId?: string): void {
    console.log('[ChatService] Updating context references:', { projectId, phaseId });
    this.recommendedFlowsService.updateFlows(projectId?.toLowerCase(), phaseId?.toLowerCase());
  }
}
