// src/app/components/records/records.component.ts
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TablesManagerService } from "../../services/tables-manager.service";
import { TableModel } from '../../models/tables.model';
import { TablesDrawService, TableRenderData } from './tables-draw.service';
import { environment } from '../../../environments/environment';
import { ProjectManagerService } from '../../services/project-manager.service';
import { WorkboardManagerService } from "../../services/workboard-manager.service";
import { ProjectData, ProjectItem } from '../../models/project.interface';
import { ProjectsDrawService, ProjectRenderData } from './projects-draw.service';
import { firstValueFrom } from 'rxjs';
import * as d3 from 'd3';
import { MemoryManagerService } from '../../services/memory-manager.service';
import { MemoryItem, MemoryObject } from '../../models/memory-item.model';
import { WorkspaceManagerService } from '../../services/workspace-manager.service';
import { StateManagerService } from '../../services/state-manager.service';
import { DocumentsDrawService, DocumentRenderData } from './documents-draw.service';
import { AgentsDrawService, AgentRenderData } from './agents-draw.service';
import { MemoriesDrawService, MemoryRenderData } from './memories-draw.service';
import { ConnectionsDrawService, ConnectionRenderData } from './connections-draw.service';
import { PipelinesDrawService, PipelineRenderData } from './pipelines-draw.service';
import { Subscription } from 'rxjs';
import { FeatureImportanceChartComponent } from './feature-importance-chart/feature-importance-chart.component';

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
    MatIconModule, 
    MatTooltipModule, 
    MatProgressSpinnerModule,
    FeatureImportanceChartComponent
  ]
})
export class RecordsComponent implements OnInit, OnDestroy {
  private width = 960;
  private height = 600;
  private margin = 20; // Simple number margin
  private tables: Record<string, TableModel> = {};
  private tablesList: TableModel[] = [];
  projects: ProjectData[] = []; 
  private documents: any = {};
  private agents: any[] = [];
  private pipelines: any[] = [];
  private connections: any[] = [];
  public memories: MemoryItem[] = [];
  public sessions: any[] = [];
  
  // Selected project for filtering
  selectedProjectId: string = 'all';
  selectedSessionId: string = 'all';
  
  // Tab and filter state
  activeTab: string = 'all';
  selectedMemoryType: string = 'all';
  selectedTimeRange: string = 'all';
  selectedImportance: number = 0;
  selectedUser: string = 'all';
  startDate: string = '';
  endDate: string = '';
  
  // Legend state
  legendsExpanded: boolean = true;
  
  // Loading states
  loadingProjects = false;
  loadingMemories = false;
  loadingSessions = false;
  
  // Dictionary to store rendered objects for cross-component referencing
  public renderedObjects: Record<string, TableRenderData | ProjectRenderData | DocumentRenderData | AgentRenderData | MemoryRenderData | ConnectionRenderData | PipelineRenderData> = {};
  
  // Track used types for the legend
  usedTypes: Set<string> = new Set();
  
  // Data type colors
  public dataTypeColorMap: {[key: string]: string} = {
    'number': '#4caf50',
    'string': '#2196f3',
    'boolean': '#ff9800',
    'date': '#9c27b0',
    'time': '#9c27b0',
    'array': '#f44336',
    'object': '#607d8b'
  };
  
  // Memory type colors
  public memoryTypeColorMap: {[key: string]: string} = {
    'data_issue': '#607d8b',      // Blue-gray for issues (was red)
    'model_evaluation': '#78909c', // Darker blue-gray for evaluations (was green)
    'other': '#90a4ae'          // Light blue-gray for other types (was gray)
  };
  
  // User type colors
  public userTypeColorMap: {[key: string]: string} = {
    'marketing_manager': '#ff7043', // Orange for marketing
    'data_analyst': '#42a5f5',      // Blue for analysts
    'data_engineer': '#66bb6a',     // Green for engineers
    'other': '#9e9e9e'              // Gray for other types
  };
  
  // Legend data
  public memoryTypes: string[] = ['data_issue', 'model_evaluation', 'other'];
  public userTypes: string[] = ['marketing_manager', 'data_analyst', 'data_engineer', 'other'];
  
  // Memory objects organized by their context
  private memoryMaps: {
    byTableId: {[tableId: string]: MemoryItem[]},
    byProjectId: {[projectId: string]: MemoryItem[]},
    byProjectName: {[projectName: string]: MemoryItem[]},
    bySessionId: {[sessionId: string]: MemoryItem[]},
    byWorkstreamId: {[workstreamId: string]: MemoryItem[]},
    byUnitId: {[unitId: string]: MemoryItem[]}
  } = {
    byTableId: {},
    byProjectId: {},
    byProjectName: {},
    bySessionId: {},
    byWorkstreamId: {},
    byUnitId: {}
  };
  
  // Public method for the template to check if a type is used
  isTypeUsed(type: string): boolean {
    return this.usedTypes.has(type);
  }
  
  // Track focused memory and its connections
  private focusedMemory: MemoryItem | null = null;
  private connectedObjects: Set<string> = new Set();
  
  // Memory details panel state
  selectedMemory: MemoryItem | null = null;
  relatedRecords: any[] = [];
  expandedSections: Record<string, boolean> = {
    observation: true,  // Start with observation expanded by default
    insight: true,      // Start with insight expanded by default
    session: false,
    project: false,
    relatedRecords: false
  };
  
  // Flag to show/hide feature importance chart
  showFeatureImportanceChart: boolean = false;

  constructor(
    private tablesManagerService: TablesManagerService,
    private tablesDrawService: TablesDrawService,
    private projectManagerService: ProjectManagerService,
    private workboardManagerService: WorkboardManagerService,
    private projectsDrawService: ProjectsDrawService,
    private memoryManagerService: MemoryManagerService,
    private workspaceManagerService: WorkspaceManagerService,
    private stateManagerService: StateManagerService,
    private documentsDrawService: DocumentsDrawService,
    private agentsDrawService: AgentsDrawService,
    private memoriesDrawService: MemoriesDrawService,
    private connectionsDrawService: ConnectionsDrawService,
    private pipelinesDrawService: PipelinesDrawService
  ) {}

  @HostListener('window:resize')
  onResize(): void {
    this.updateDimensions();
    this.drawDiagram();
  }

  async ngOnInit(): Promise<void> {
    // Initialize with legends expanded by default
    this.legendsExpanded = true;
    
    this.updateDimensions();
    await this.loadData();
    
    this.findUsedTypes();
    this.drawDiagram();
    
    // Subscribe to memory click events with consolidated handling
    this.memoriesDrawService.memoryClicked.subscribe(data => {
      const memory = data.memory;
      
      // If it's the same memory being clicked again (toggle behavior)
      if (data.isSameMemory) {
        // Clear focus and redraw the full diagram
        this.clearMemoryFocus();
        return;
      }
      
      // It's a different memory - set it as the focused memory
      this.focusedMemory = memory;
      this.connectedObjects = new Set();
      
      // Add connected objects to the set
      if (memory) {
        // Always include the memory itself
        if (memory.memory_id) {
          this.connectedObjects.add(memory.memory_id);
        }
        
        // Add direct properties if they exist
        if (memory.session_id) {
          this.connectedObjects.add(memory.session_id);
        }
        
        if (memory.workstream_id) {
          this.connectedObjects.add(memory.workstream_id);
        }
        
        if (memory.table_id) {
          this.connectedObjects.add(memory.table_id);
        }
        
        if (memory.project_id) {
          this.connectedObjects.add(memory.project_id);
        }
        
        if (memory.unit_id) {
          this.connectedObjects.add(memory.unit_id);
        }
        
        if (memory.workspace_id) {
          this.connectedObjects.add(memory.workspace_id);
        }
        
        // Add context objects if they exist
        if (memory.context && memory.context.objects) {
          memory.context.objects.forEach((obj: MemoryObject) => {
            if (obj.object_id) {
              this.connectedObjects.add(obj.object_id);
            }
          });
        }
        
        // Add related memories if they exist
        if (memory.context && memory.context.related_memories) {
          memory.context.related_memories.forEach((memoryId: string) => {
            this.connectedObjects.add(memoryId);
          });
        }
      }
      
      // Update the selected memory for the panel display
      this.selectedMemory = memory;
      
      // Populate related records for the Memory Details panel
      this.relatedRecords = data.relatedRecords || [];
      
      // Redraw the diagram with only the connected elements
      this.drawDiagramWithFilteredElements();
    });
  }

  private updateDimensions(): void {
    // Get container dimensions
    const container = document.querySelector('.diagram-wrapper') as HTMLElement;
    if (!container) return;
    
    // Calculate available width (accounting for legends panel if expanded)
    const containerWidth = container.clientWidth;
    
    // Set dimensions
    this.width = containerWidth - (this.margin * 2); // Apply margin on both sides
    this.height = 400; // Fixed height for the main diagram section
    
    // Update SVG dimensions
    const svg = d3.select('figure#records-diagram')
      .select('svg');
    
    if (!svg.empty()) {
      svg
        .attr('width', this.width + (this.margin * 2))
        .attr('height', this.height + (this.margin * 2));
    }
  }

  private async loadData(): Promise<void> {
    try {
      // First, get the current workspace ID
      const workspaceId = this.getCurrentWorkspaceId();
      
      if (!workspaceId) {
        console.warn('No workspace selected. Waiting for workspace selection.');
        return;
      }
      
      // Load documents first
      this.documents = this.stateManagerService.getObject('', 'workspace.documents') || {};
      
      // Filter out documents without an ID field
      if (this.documents) {
        const filteredDocs: any = {};
        Object.entries(this.documents).forEach(([key, doc]: [string, any]) => {
          if (doc && doc.id) {
            filteredDocs[key] = doc;
          }
        });
        this.documents = filteredDocs;
      }
      
      // Load agents
      this.agents = this.stateManagerService.getObject('', 'workspace.agents') || [];
      
      // Load pipelines
      const pipelinesData = this.stateManagerService.getObject('', 'workspace.pipelines') || {};
      // Transform pipelines from dictionary/object to array if needed
      const rawPipelines: any[] = Array.isArray(pipelinesData) ? pipelinesData : Object.values(pipelinesData);
      
      // Process each pipeline to remove case-insensitive duplicate properties
      this.pipelines = rawPipelines.map((pipeline: Record<string, any>) => {
        const uniquePipeline: Record<string, any> = {};
        const seenKeys: Set<string> = new Set();
        
        // Process entries, keeping track of keys in lowercase
        Object.entries(pipeline).forEach(([key, value]: [string, any]) => {
          const lowerKey = key.toLowerCase();
          if (!seenKeys.has(lowerKey)) {
            seenKeys.add(lowerKey);
            uniquePipeline[key] = value;
          }
        });
        
        return uniquePipeline;
      });
      console.log('Loaded pipelines:', this.pipelines);
      
      // Load connections
      const connectionsData = this.stateManagerService.getObject('', 'workspace.connections') || {};
      // Transform connections from dictionary/object to array if needed
      this.connections = Array.isArray(connectionsData) ? connectionsData : Object.values(connectionsData);
      console.log('Loaded connections:', this.connections);

      // Load tables
      this.tables = await this.tablesManagerService.getTablesCollection();
      this.tablesList = Object.values(this.tables)
      .filter(table => table && table.name !== undefined)
      .sort((a, b) => a.name.localeCompare(b.name));
      
      // Then load projects
      await this.loadProjects();
      
      // Set the default project to the current project
      await this.setDefaultProject();
      
      // Load memories for workspace and projects
      await this.loadMemories();
      
      // Organize memories by their context
      this.organizeMemoriesByContext();
      
      // Log workspace relationships
      this.logWorkspaceRelationships();
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }
  
  private async loadProjects(): Promise<void> {
    try {
      
      this.loadingProjects = true;
      
      // Get all project items
      const projectItems = await firstValueFrom(this.projectManagerService.getCurrentWorkspaceProjects());
      
      // Load detailed project data for each item
      this.projects = [];
      for (const projectItem of projectItems) {
        try {
          const projectData = await this.workboardManagerService.getProjectDataById(projectItem.id);
          if (projectData) {
            this.projects.push(projectData);
          }
        } catch (error) {
          console.error(`Error loading project data for ID ${projectItem.id}:`, error);
        }
      }
      
      this.loadingProjects = false;
    } catch (error) {
      console.error('Error loading projects:', error);
      this.loadingProjects = false;
    }
  }
  
  // Load sessions for a specific project
  private async loadSessions(projectId: string): Promise<void> {
    try {
      // Reset sessions and set loading state
      this.sessions = [];
      this.selectedSessionId = 'all';
      
      if (projectId === 'all') {
        // If "All Projects" is selected, we don't need to load sessions
        return;
      }
      
      this.loadingSessions = true;
      
      // Find the selected project
      const selectedProject = this.projects.find(project => project.id === projectId);
      
      if (!selectedProject) {
        console.error(`Project with ID ${projectId} not found`);
        this.loadingSessions = false;
        return;
      }
      
      // Get sessions directly from the project
      if (selectedProject.sessions && selectedProject.sessions.length > 0) {
        // Map project sessions to the format we need
        this.sessions = selectedProject.sessions.map(session => ({
          id: session.id,
          name: session.name || session.session || `Session ${session.id}`,
          description: session.description || '',
          created_date: session.createdDate ? new Date(session.createdDate).toISOString() : '',
          project_id: projectId
        }));
        
        // Sort sessions by name for better usability
        this.sessions.sort((a, b) => a.name.localeCompare(b.name));
      }
      
      // Only add "All Sessions" option if we have actual sessions
      if (this.sessions.length > 0) {
        this.sessions.unshift({
          id: 'all',
          name: 'All Sessions',
          description: 'View memories from all sessions in this project',
          created_date: '',
          project_id: projectId
        });
      }
      
      this.loadingSessions = false;
    } catch (error) {
      console.error('Error loading sessions:', error);
      this.loadingSessions = false;
    }
  }
  
  /**
   * Load memories for the current workspace and all projects
   */
  private async loadMemories(): Promise<void> {
    try {
      this.loadingMemories = true;
      this.memories = [];
      
      // Get current workspace ID
      const workspaceId = this.getCurrentWorkspaceId();
      
      if (workspaceId) {
        // Load workspace-level memories
        const workspaceMemories = await this.memoryManagerService.loadMemoriesForScope('workspace', workspaceId);
        
        if (workspaceMemories && workspaceMemories.length) {
          this.memories = [...workspaceMemories];
        }
        
        // Load project-level memories for each project
        for (const project of this.projects) {
          try {
            const projectMemories = await this.memoryManagerService.loadMemoriesForScope('project', project.id);
            
            if (projectMemories && projectMemories.length) {
              this.memories = [...this.memories, ...projectMemories];
            }
          } catch (error) {
            console.error(`Error loading memories for project ${project.id}:`, error);
          }
        }
      }
      
      this.loadingMemories = false;
    } catch (error) {
      console.error('Error loading memories:', error);
      this.loadingMemories = false;
    }
  }
  
  /**
   * Get the current workspace ID
   */
  private getCurrentWorkspaceId(): string | undefined {
    // Get the workspace ID from the workspace manager service
    return this.workspaceManagerService.getCurrentWorkspaceId();
  }
  
  /**
   * Organize memories by their context objects for easier lookup
   */
  private organizeMemoriesByContext(): void {
    // Reset memory maps
    this.memoryMaps = {
      byTableId: {},
      byProjectId: {},
      byProjectName: {},
      bySessionId: {},
      byWorkstreamId: {},
      byUnitId: {}
    };
    
    // Track which memories are added to which contexts to avoid duplicates
    const addedMemories = {
      byTableId: new Map<string, Set<string>>(),
      byProjectId: new Map<string, Set<string>>(),
      byProjectName: new Map<string, Set<string>>(),
      bySessionId: new Map<string, Set<string>>(),
      byWorkstreamId: new Map<string, Set<string>>(),
      byUnitId: new Map<string, Set<string>>()
    };
    
    // Helper function to generate a unique memory ID
    const getMemoryId = (memory: MemoryItem): string => {
      return (memory as any)._id || (memory as any).memory_id || 
        `mem-${memory.project_id}-${memory.session_id}-${memory.type}-${memory.observation}`;
    };
    
    // Helper function to add a memory to a context map if not already added
    const addToContextMap = (
      contextType: 'byTableId' | 'byProjectId' | 'byProjectName' | 'bySessionId' | 'byWorkstreamId' | 'byUnitId',
      contextId: string, 
      memory: MemoryItem
    ): void => {
      const memoryId = getMemoryId(memory);
      
      // Initialize tracking sets if needed
      if (!addedMemories[contextType].has(contextId)) {
        addedMemories[contextType].set(contextId, new Set<string>());
      }
      
      // Check if this memory has already been added to this context
      if (!addedMemories[contextType].get(contextId)!.has(memoryId)) {
        // Initialize memory array if needed
        if (!this.memoryMaps[contextType][contextId]) {
          this.memoryMaps[contextType][contextId] = [];
        }
        
        // Add memory to this context
        this.memoryMaps[contextType][contextId].push(memory);
        addedMemories[contextType].get(contextId)!.add(memoryId);
      }
    };
    
    // Organize memories by their contexts
    for (const memory of this.memories) {
      // Add to session map
      if (memory.session_id) {
        const sessionId = memory.session_id.toLowerCase();
        addToContextMap('bySessionId', sessionId, memory);
      }
      
      // Add to project map
      if (memory.project_id) {
        const projectId = memory.project_id.toLowerCase();
        addToContextMap('byProjectId', projectId, memory);
        const project = this.projects.find(p => p.id === projectId);
        const projectName = project?.name?.toLowerCase();
        if (projectName) {
          addToContextMap('byProjectName', projectName, memory);
        }
      }
      
      // Organize by context objects
      if (memory.context && memory.context.objects) {
        for (const obj of memory.context.objects) {
          const contextId = obj.object_id.toLowerCase();
          
          switch(obj.object_type) {
            case 'table':
              addToContextMap('byTableId', contextId, memory);
              break;
              
            case 'workstream':
              addToContextMap('byWorkstreamId', contextId, memory);
              break;
              
            case 'unit':
              addToContextMap('byUnitId', contextId, memory);
              break;
          }
        }
      }
    }
  }
  
  private findUsedTypes(): void {
    this.usedTypes.clear();
    this.tablesList.forEach(table => {
      table.colSchema.forEach(col => {
        const normalizedType = this.normalizeType(col.type);
        this.usedTypes.add(normalizedType);
      });
    });
  }
  
  private normalizeType(type: string): string {
    // Normalize the type to match our predefined categories
    const lowerType = type.toLowerCase().trim();
    
    if (lowerType.includes('string') || lowerType.includes('char') || lowerType.includes('text')) {
      return 'string';
    } else if (lowerType.includes('int')) {
      return 'integer';
    } else if (lowerType.includes('float') || lowerType.includes('double') || lowerType.includes('decimal') || lowerType.includes('number')) {
      return 'number';
    } else if (lowerType.includes('bool')) {
      return 'boolean';
    } else if (lowerType.includes('date') && lowerType.includes('time')) {
      return 'datetime';
    } else if (lowerType.includes('date')) {
      return 'date';
    } else if (lowerType.includes('time')) {
      return 'time';
    } else if (lowerType.includes('array') || lowerType.includes('[]')) {
      return 'array';
    } else if (lowerType.includes('object') || lowerType.includes('json')) {
      return 'object';
    }
    
    return 'other';
  }

  private drawDiagram(): void {
    // Clear existing SVG
    d3.select('figure#records-diagram').selectAll('*').remove();
    
    // Initialize SVG with additional height for all sections
    const titleMargin = 0; // No space needed for titles since it's in the header now
    const documentsHeight = 150; // Space for documents section
    const contentPadding = 80; // Increased padding between sections
    const memoriesHeight = 250; // Space for memories section
    const agentsHeight = 150; // Space for agents section
    const legendsHeight = 300; // Increased space for legends panel
    
    // Calculate total height needed - ensure it's at least 3000px for many projects
    const totalHeight = Math.max(3000, this.height + documentsHeight + memoriesHeight + agentsHeight + legendsHeight + (contentPadding * 5));
    
    this.tablesDrawService.initializeSvg(
      'figure#records-diagram', 
      this.width, 
      totalHeight + 300, // Add extra space for legends
      this.margin
    );
    
    // Get the SVG for adding titles
    const svg = this.tablesDrawService.getSvg();
    
    // Create a main group for all content
    const mainGroup = svg.append('g')
      .attr('class', 'main-content');
    
    // Filter memories based on current filters
    const filteredMemories = this.filterMemories();
    
    // Check if the selected memory is still present in the filtered memories
    // If not, close the memory details panel
    if (this.selectedMemory && !filteredMemories.some(memory => memory.memory_id === this.selectedMemory?.memory_id)) {
      this.selectedMemory = null;
      this.relatedRecords = [];
    }
    
    // Draw the diagram with filtered data
    this.drawDiagramWithData(filteredMemories, mainGroup);
    
    // Setup pan and zoom
    this.setupPanAndZoom(mainGroup);
  }
  
  // Filter memories based on current filter settings
  private filterMemories(): MemoryItem[] {
    let filteredMemories = [...this.memories];
    
    // Filter by project
    if (this.selectedProjectId !== 'all') {
      filteredMemories = filteredMemories.filter(memory => 
        // Keep memory if it has no project_id (not explicitly associated with any project)
        // or if it matches the selected project
        !memory.project_id || memory.project_id.toLowerCase() === this.selectedProjectId.toLowerCase()
      );
      
      // Filter by session if a specific session is selected
      if (this.selectedSessionId !== 'all') {
        filteredMemories = this.filterMemoriesBySession(filteredMemories);
      }
    } else if (this.selectedSessionId !== 'all') {
      // If all projects are selected but a specific session is selected
      filteredMemories = this.filterMemoriesBySession(filteredMemories);
    }
    
    // Filter by memory type
    if (this.activeTab === 'byType' && this.selectedMemoryType !== 'all') {
      filteredMemories = filteredMemories.filter(memory => 
        // Keep memory if it has no type (not explicitly typed)
        // or if it matches the selected type
        !memory.type || memory.type === this.selectedMemoryType
      );
    }
    
    // Filter by user
    if (this.activeTab === 'byUser' && this.selectedUser !== 'all') {
      filteredMemories = filteredMemories.filter(memory => {
        // If memory has no participants, keep it (not explicitly associated with any user)
        if (!memory.session_participants || memory.session_participants.length === 0) {
          return true;
        }
        
        // Check if the memory has participants that match the selected user
        return memory.session_participants.some(participant => 
          participant.includes(this.selectedUser)
        );
      });
    }
    
    // Filter by date range
    if (this.activeTab === 'byDate' && this.selectedTimeRange !== 'all') {
      if (this.startDate && this.endDate) {
        const startDate = new Date(this.startDate);
        const endDate = new Date(this.endDate);
        endDate.setHours(23, 59, 59, 999); // Include the entire end day
        
        filteredMemories = filteredMemories.filter(memory => {
          // If memory has no created_date, keep it (not explicitly dated)
          if (!memory.created_date) {
            return true;
          }
          
          const memoryDate = new Date(memory.created_date);
          return memoryDate >= startDate && memoryDate <= endDate;
        });
      }
    }
    
    // Filter by importance
    if (this.activeTab === 'byImportance' && this.selectedImportance > 0) {
      filteredMemories = filteredMemories.filter(memory => 
        // Keep memory if it has no importance (not explicitly rated)
        // or if its importance is at least the selected value
        memory.importance === undefined || memory.importance >= this.selectedImportance
      );
    }
    
    return filteredMemories;
  }

  /**
   * Filters memories based on the selected session
   */
  private filterMemoriesBySession(memories: MemoryItem[]): MemoryItem[] {
    if (!this.selectedSessionId || this.selectedSessionId === 'all') {
      return memories;
    }
    
    return memories.filter(memory => {
      // If memory has a session_id, check if it matches the selected session
      if (memory.session_id) {
        return memory.session_id === this.selectedSessionId;
      }
      
      // If memory has a project_id, check if that project has the selected session
      if (memory.project_id) {
        const project = this.projects.find(p => p.id === memory.project_id);
        if (!project) {
          console.error(`Project with ID ${memory.project_id} not found`);
          return true; // If we can't find the project, include the memory
        }
        
        // If project has no sessions, include the memory
        if (!project.sessions || project.sessions.length === 0) {
          return true;
        }
        
        // Check if the project has the selected session
        return project.sessions.some(session => session.id === this.selectedSessionId);
      }
      
      // If memory doesn't have an explicit session_id or project_id with sessions,
      // include it in the results (don't filter it out)
      return true;
    });
  }

  private drawDiagramWithData(filteredMemories: MemoryItem[], mainGroup: d3.Selection<SVGGElement, unknown, null, undefined>): void {
    // Reset rendered objects
    this.renderedObjects = {};
    
    // Share SVG with services
    this.projectsDrawService.setSvg(mainGroup);
    this.tablesDrawService.setSvg(mainGroup);
    this.documentsDrawService.setSvg(mainGroup);
    this.memoriesDrawService.setSvg(mainGroup);
    this.agentsDrawService.setSvg(mainGroup);
    this.pipelinesDrawService.setSvg(mainGroup);
    this.connectionsDrawService.setSvg(mainGroup);
    
    // Get memories to render
    const memoriesToRender = this.getMemoriesToRender(filteredMemories);
    
    // Calculate the center point of the diagram
    const centerX = this.width / 2;
    const leftMargin = centerX - ((this.width - (2 * this.margin)) / 2);
    
    // SECTION 1: MEMORIES SECTION (TOP)
    // --------------------------------
    // Only create memories section if there are memories to render
    let memoriesHeight = 0;
    if (memoriesToRender.length > 0) {
      // Create memories section container
      const memoriesContainer = mainGroup.append('g')
        .attr('class', 'memories-container')
        .attr('transform', `translate(${leftMargin}, 30)`);
      
      // Create group for memory items (positioned below title)
      const memoriesGroup = memoriesContainer.append('g')
        .attr('class', 'memories-group')
        .attr('transform', 'translate(0, 10)'); // Position with small padding from top
      
      memoriesHeight = 100;  

      // Draw memories in the dedicated group
      this.memoriesDrawService.setSvg(memoriesGroup);
      this.memoriesDrawService.drawMemories(memoriesToRender, {
        width: this.width - (2 * this.margin),
        height: memoriesHeight,
        margin: this.margin,
        memoryTypeColorMap: this.memoryTypeColorMap,
        centerX: 50,
        showTitle: false
      });
      
      // Add memory objects to rendered objects
      const memoryObjects = this.memoriesDrawService.getAllObjects();
      this.renderedObjects = { ...this.renderedObjects, ...memoryObjects };
    }
    
    // SECTION 2: TABLES, PROJECTS, DOCUMENTS, CONNECTIONS, PIPELINES (BOTTOM)
    // ---------------------------------------------------------------------
    
    // Create container for tables, projects, documents, connections, and pipelines
    const tablesProjectsContainer = mainGroup.append('g')
      .attr('class', 'tables-projects-container')
      .attr('transform', `translate(${leftMargin}, ${memoriesHeight + 60})`);
    
    // Define column widths based on the 3 requested types
    const narrowColumnWidth = 150; // Narrow columns for Users, Documents
    const wideColumnWidth = 300; // Wide columns for Projects
    const mediumColumnWidth = 250; // Medium columns for Tables, Connections, Pipelines
    
    // We'll calculate x positions dynamically based on which sections exist
    let currentXPosition = 0;
    const sectionSpacing = 20; // Space between sections
    
    // USERS SECTION
    // Get agents to render
    const agentsToRender = this.getAgentsToRender(filteredMemories);
    let usersXOffset = 0; // Default position
    
    // Only create users section if there are agents to render
    if (agentsToRender.length > 0) {
      usersXOffset = currentXPosition;
      currentXPosition += narrowColumnWidth + sectionSpacing;
      
      // Add "Users" section
      const usersSection = tablesProjectsContainer.append('g')
        .attr('class', 'users-section')
        .attr('transform', `translate(${usersXOffset}, 0)`);
        
      // Add "Users" title with consistent styling
      usersSection.append('text')
        .attr('x', 15)
        .attr('y', 20)
        .attr('font-size', '18px')
        .attr('font-weight', 'bold')
        .attr('fill', '#90a4ae') // Consistent color for all section titles
        .attr('class', 'diagram-title')
        .text('Users');
      
      // Create group for users (positioned below title)
      const usersGroup = usersSection.append('g')
        .attr('class', 'users-group')
        .attr('transform', 'translate(0, 40)');
      
      // Draw users in the dedicated group
      this.agentsDrawService.setSvg(usersGroup);
      this.agentsDrawService.drawAgents(agentsToRender, 0, {
        width: narrowColumnWidth,
        height: 400,
        margin: this.margin
      });
      
      // Add user objects to rendered objects
      const userObjects = this.agentsDrawService.getAllObjects();
      this.renderedObjects = { ...this.renderedObjects, ...userObjects };
    }

    // TABLES SECTION
    // Get tables to render
    const tablesToRender = this.getTablesToRender(filteredMemories);
    
    // Only create tables section if there are tables to render
    if (tablesToRender.length > 0) {
      const tablesXOffset = currentXPosition;
      currentXPosition += mediumColumnWidth + sectionSpacing;
      
      // Add "Tables" section with title
      const tablesSection = tablesProjectsContainer.append('g')
        .attr('class', 'tables-section')
        .attr('transform',  `translate(${tablesXOffset},0)`);
        
      // Add "Tables" title with consistent styling
      tablesSection.append('text')
        .attr('x', 15)
        .attr('y', 20)
        .attr('font-size', '18px')
        .attr('font-weight', 'bold')
        .attr('fill', '#90a4ae') // Consistent color for all section titles
        .attr('class', 'diagram-title')
        .text('Tables');
      
      // Create group for tables (positioned below title)
      const tablesGroup = tablesSection.append('g')
        .attr('class', 'tables-group')
        .attr('transform', 'translate(0, 40)');

      setTimeout(() => {
        tablesGroup.attr('transform', 'translate(0, 40)');
      }, 0);
     
      
      // Draw tables in the dedicated group
      this.tablesDrawService.setSvg(tablesGroup);
      this.tablesDrawService.drawTables(tablesToRender, {
        width: mediumColumnWidth,
        height: 400,
        tableMemories: this.memoryMaps.byTableId
      });
      
      // Add table objects to rendered objects
      const tableObjects = this.tablesDrawService.getAllObjects();
      this.renderedObjects = { ...this.renderedObjects, ...tableObjects };
    }
    
    // PROJECTS SECTION
    // Get projects to render
    const projectsToRender = this.getProjectsToRender(filteredMemories);
    
    // Only create projects section if there are projects to render
    if (projectsToRender.length > 0) {
      const projectsXOffset = currentXPosition;
      currentXPosition += wideColumnWidth + sectionSpacing;
      
      // Add "Projects" section with title
      const projectsSection = tablesProjectsContainer.append('g')
        .attr('class', 'projects-section')
        .attr('transform', `translate(${projectsXOffset}, 0)`);
        
      // Add "Projects" title with consistent styling
      projectsSection.append('text')
        .attr('x', 15)
        .attr('y', 20)
        .attr('font-size', '18px')
        .attr('font-weight', 'bold')
        .attr('fill', '#90a4ae') // Consistent color for all section titles
        .attr('class', 'diagram-title')
        .text('Projects');
      
      // Create group for projects (positioned below title)
      const projectsGroup = projectsSection.append('g')
        .attr('class', 'projects-group')
        .attr('transform', 'translate(0, 40)');
      
      // Draw projects in the dedicated group
      this.projectsDrawService.setSvg(projectsGroup);
      this.projectsDrawService.drawProjects(projectsToRender, 0, {
        width: wideColumnWidth,
        height: 400,
        projectMemories: this.memoryMaps.byProjectId,
        sessionMemories: this.memoryMaps.bySessionId,
        workstreamMemories: this.memoryMaps.byWorkstreamId,
        unitMemories: this.memoryMaps.byUnitId,
        memoryTypeColorMap: this.memoryTypeColorMap
      });
      
      // Add project objects to rendered objects
      const projectObjects = this.projectsDrawService.getAllObjects();
      this.renderedObjects = { ...this.renderedObjects, ...projectObjects };
    }
    
    // DOCUMENTS SECTION
    // Get documents to render
    const documentsToRender = this.getDocumentsToRender(filteredMemories);
    
    // Only create documents section if there are documents to render
    if (documentsToRender.length > 0) {
      const documentsXOffset = currentXPosition;
      currentXPosition += narrowColumnWidth + sectionSpacing;
      
      // Add "Documents" section
      const documentsSection = tablesProjectsContainer.append('g')
        .attr('class', 'documents-section')
        .attr('transform', `translate(${documentsXOffset}, 0)`);
        
      // Add "Documents" title with consistent styling
      documentsSection.append('text')
        .attr('x', 15)
        .attr('y', 20)
        .attr('font-size', '18px')
        .attr('font-weight', 'bold')
        .attr('fill', '#90a4ae') // Consistent color for all section titles
        .attr('class', 'diagram-title')
        .text('Documents');
      
      // Create group for documents (positioned below title)
      const documentsGroup = documentsSection.append('g')
        .attr('class', 'documents-group')
        .attr('transform', 'translate(0, 40)');
      
      // Draw documents in the dedicated group
      this.documentsDrawService.setSvg(documentsGroup);
      this.documentsDrawService.drawDocuments(documentsToRender, {
        width: narrowColumnWidth,
        height: 400,
        margin: this.margin
      });
      
      // Add document objects to rendered objects
      const documentObjects = this.documentsDrawService.getAllObjects();
      this.renderedObjects = { ...this.renderedObjects, ...documentObjects };
    }
    
    // CONNECTIONS SECTION
    // Get connections to render
    const connectionsToRender = this.getConnectionsToRender(filteredMemories);
    
    // Only create connections section if there are connections to render
    if (connectionsToRender.length > 0) {
      const connectionsXOffset = currentXPosition;
      currentXPosition += mediumColumnWidth + sectionSpacing;
      
      // Add "Connections" section
      const connectionsSection = tablesProjectsContainer.append('g')
        .attr('class', 'connections-section')
        .attr('transform', `translate(${connectionsXOffset}, 0)`);
        
      // Add "Connections" title with consistent styling
      connectionsSection.append('text')
        .attr('x', 15)
        .attr('y', 20)
        .attr('font-size', '18px')
        .attr('font-weight', 'bold')
        .attr('fill', '#90a4ae') // Consistent color for all section titles
        .attr('class', 'diagram-title')
        .text('Connections');
      
      // Create group for connections (positioned below title)
      const connectionsGroup = connectionsSection.append('g')
        .attr('class', 'connections-group')
        .attr('transform', 'translate(0, 40)');
      
      // Draw connections in the dedicated group
      this.connectionsDrawService.setSvg(connectionsGroup);
      this.connectionsDrawService.drawConnections(connectionsToRender, this.renderedObjects, {
        width: mediumColumnWidth,
        height: 400,
        margin: this.margin
      });
      
      // Add connection objects to rendered objects
      const connectionObjects = this.connectionsDrawService.getAllObjects();
      this.renderedObjects = { ...this.renderedObjects, ...connectionObjects };
    }
    
    // PIPELINES SECTION
    // Get pipelines to render
    const pipelinesToRender = this.getPipelinesToRender(filteredMemories);
    
    // Only create pipelines section if there are pipelines to render
    if (pipelinesToRender.length > 0) {
      const pipelinesXOffset = currentXPosition;
      // No need to update currentXPosition since this is the last section
      
      // Add "Pipelines" section
      const pipelinesSection = tablesProjectsContainer.append('g')
        .attr('class', 'pipelines-section')
        .attr('transform', `translate(${pipelinesXOffset}, 0)`);
        
      // Add "Pipelines" title with consistent styling
      pipelinesSection.append('text')
        .attr('x', 15)
        .attr('y', 20)
        .attr('font-size', '18px')
        .attr('font-weight', 'bold')
        .attr('fill', '#90a4ae') // Consistent color for all section titles
        .attr('class', 'diagram-title')
        .text('Pipelines');
      
      // Create group for pipelines (positioned below title)
      const pipelinesGroup = pipelinesSection.append('g')
        .attr('class', 'pipelines-group')
        .attr('transform', 'translate(0, 40)');
      
      // Draw pipelines in the dedicated group
      this.pipelinesDrawService.setSvg(pipelinesGroup);
      this.pipelinesDrawService.drawPipelines(pipelinesToRender, {
        width: mediumColumnWidth,
        height: 400,
        margin: this.margin
      });
      
      // Add pipeline objects to rendered objects
      const pipelineObjects = this.pipelinesDrawService.getAllObjects();
      this.renderedObjects = { ...this.renderedObjects, ...pipelineObjects };
    }
    
    // Calculate total height needed for SVG
    const projectsCount = this.getProjectsToRender(filteredMemories).length;
    const projectHeight = 60; // Base height per project
    const projectPadding = 20; // Padding between projects
    const minProjectsHeight = 3000; // Minimum height for projects section
    
    // Calculate dynamic height based on number of projects
    const projectsHeight = Math.max(
      minProjectsHeight,
      (projectsCount * (projectHeight + projectPadding)) + 100 // Add extra padding
    );
    
    const totalHeight = memoriesHeight + projectsHeight + (this.margin * 2);
    
    // Ensure the SVG is tall enough
    const svg = this.tablesDrawService.getSvg();
    svg.attr('height', totalHeight);
  }
  
  /**
   * Create hexagon points for the Ask-Y logo shape
   */
  private createHexagonPoints(size: number): [number, number][] {
    const points: [number, number][] = [];
    const angleOffset = Math.PI / 6; // 30 degrees offset
    
    for (let i = 0; i < 6; i++) {
      const angle = (i * Math.PI / 3) + angleOffset;
      const x = size * Math.sin(angle);
      const y = size * Math.cos(angle);
      points.push([x, y]);
    }
    
    // Close the path
    points.push(points[0]);
    
    return points;
  }
  
  // Set active tab
  setActiveTab(tab: string): void {
    this.activeTab = tab;
    this.drawDiagram();
  }
  
  // Filter by memory type
  filterByMemoryType(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedMemoryType = selectElement.value;
    this.drawDiagram();
  }
  
  // Filter by user
  filterByUser(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedUser = selectElement.value;
    this.drawDiagram();
  }
  
  // Filter by time range
  filterByTimeRange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTimeRange = selectElement.value;
    
    // Set default date range based on selection
    const today = new Date();
    if (this.selectedTimeRange === 'today') {
      this.startDate = today.toISOString().split('T')[0];
      this.endDate = today.toISOString().split('T')[0];
    } else if (this.selectedTimeRange === 'week') {
      const weekStart = new Date(today);
      weekStart.setDate(today.getDate() - today.getDay());
      this.startDate = weekStart.toISOString().split('T')[0];
      this.endDate = today.toISOString().split('T')[0];
    } else if (this.selectedTimeRange === 'month') {
      const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      this.startDate = monthStart.toISOString().split('T')[0];
      this.endDate = today.toISOString().split('T')[0];
    } else if (this.selectedTimeRange === 'custom' && !this.startDate) {
      // Default to last 30 days for custom range if not set
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      this.startDate = thirtyDaysAgo.toISOString().split('T')[0];
      this.endDate = today.toISOString().split('T')[0];
    }
    
    this.drawDiagram();
  }
  
  // Update start date
  updateStartDate(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.startDate = inputElement.value;
    this.drawDiagram();
  }
  
  // Update end date
  updateEndDate(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.endDate = inputElement.value;
    this.drawDiagram();
  }
  
  // Filter by importance
  filterByImportance(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedImportance = parseInt(selectElement.value, 10);
    this.drawDiagram();
  }
  
  // Get memory type color
  getMemoryTypeColor(type: string): string {
    const colorMap: Record<string, string> = {
      'data_issue': '#607d8b', // Blue-gray
      'model_evaluation': '#5c6bc0', // Indigo
      'user_feedback': '#26a69a', // Teal
      'system_alert': '#ef5350', // Light red
      'performance_metric': '#66bb6a', // Green
      'default': '#90a4ae' // Light blue-gray
    };
    
    return colorMap[type] || this.memoryTypeColorMap[type] || '#9e9e9e'; // Try both maps or use default
  }

  private setupPanAndZoom(mainGroup: any): void {
    const svg = d3.select('figure#records-diagram svg');
    
    // Create zoom behavior
    const zoom = d3.zoom()
      .scaleExtent([0.5, 3])  // Min and max zoom scale
      .on('zoom', (event) => {
        mainGroup.attr('transform', event.transform);
      });
    
    // Apply zoom behavior to SVG
    svg.call(zoom as any);
    
    // Add zoom controls
    this.addZoomControls(svg, zoom);
  }
  
  /**
   * Add zoom control buttons
   */
  private addZoomControls(svg: any, zoom: any): void {
    const zoomIn = d3.select('.zoom-controls .zoom-in');
    const zoomOut = d3.select('.zoom-controls .zoom-out');
    const zoomReset = d3.select('.zoom-controls .zoom-reset');
    
    if (zoomIn.empty() || zoomOut.empty() || zoomReset.empty()) {
      return;
    }
    
    zoomIn.on('click', () => {
      zoom.scaleBy(svg.transition().duration(300), 1.2);
    });
    
    zoomOut.on('click', () => {
      zoom.scaleBy(svg.transition().duration(300), 0.8);
    });
    
    zoomReset.on('click', () => {
      svg.transition().duration(300).call(zoom.transform, d3.zoomIdentity);
    });
  }
  
  /**
   * Public method to find an object by its ID
   * This can be used by other components to reference objects
   */
  public getObjectById(id: string): TableRenderData | ProjectRenderData | DocumentRenderData | AgentRenderData | MemoryRenderData | ConnectionRenderData | PipelineRenderData | null {
    return this.renderedObjects[id] || null;
  }
  
  /**
   * Public method to find all objects of a specific table
   */
  public getObjectsByTableId(tableId: string): TableRenderData[] {
    return Object.values(this.renderedObjects)
      .filter(obj => 'tableId' in obj && obj.tableId === tableId) as TableRenderData[];
  }
  
  /**
   * Public method to highlight a specific object
   */
  public highlightObject(id: string, options: { color?: string; duration?: number } = {}): void {
    const obj = this.renderedObjects[id];
    if (!obj) return;
    
    if ('tableId' in obj) {
      // It's a table object
      this.tablesDrawService.highlightObject(id, options);
    } else if ('projectId' in obj) {
      // It's a project object
      this.projectsDrawService.highlightObject(id, options);
    } else if ('documentId' in obj) {
      // It's a document object
      this.documentsDrawService.highlightObject(id, options);
    } else if (obj.type === 'agent') {
      // It's an agent object
      this.agentsDrawService.highlightObject(id, options);
    } else if (obj.type === 'memory') {
      // It's a memory object
      this.memoriesDrawService.highlightObject(id, options);
    } else if (obj.type === 'connection') {
      // It's a connection object
      this.connectionsDrawService.highlightObject(id, options);
    } else if (obj.type === 'pipeline') {
      // It's a pipeline object
      this.pipelinesDrawService.highlightObject(id, options);
    }
  }
  
  /**
   * Public method to create a connection between two objects
   */
  public connectObjects(sourceId: string, targetId: string, options: {
    color?: string;
    strokeWidth?: number;
    dashArray?: string;
    markerEnd?: boolean;
  } = {}): void {
    this.tablesDrawService.drawConnection(sourceId, targetId, options);
  }
  
  /**
   * Generate memory tooltip content
   */
  public generateMemoryTooltip(memory: MemoryItem): string {
    // Create a formatted tooltip for memory item
    let html = `
      <div style="font-weight: bold; font-size: 14px; margin-bottom: 8px; color: ${this.getMemoryTypeColor(memory.type)};">
        ${memory.type.toUpperCase()} Memory
      </div>
      <div><strong>Observation:</strong> ${memory.observation}</div>
      <div><strong>Insight:</strong> ${memory.insight}</div>
      <div><strong>Importance:</strong> ${memory.importance}/10</div>
      <div><strong>Reliability:</strong> ${memory.reliability}/10</div>
    `;
    
    // Add contextual information
    if (memory.context) {
      html += `<div><strong>Context:</strong> ${memory.context.description}</div>`;
      
      // Add business dimensions if any
      if (memory.context.business_dimensions && memory.context.business_dimensions.length) {
        html += `<div><strong>Business Dimensions:</strong></div><ul>`;
        memory.context.business_dimensions.forEach(dim => {
          html += `<li>${dim.dimension_type}: ${dim.value}</li>`;
        });
        html += `</ul>`;
      }
    }
    
    // Add analysis if any
    if (memory.analysis && memory.analysis.length) {
      html += `<div><strong>Analysis:</strong></div><ul>`;
      memory.analysis.forEach(analysis => {
        let analysisText = `${analysis.metric}`;
        if (analysis.value !== undefined) {
          analysisText += `: ${analysis.value}`;
          if (analysis.unit) {
            analysisText += ` ${analysis.unit}`;
          }
        }
        html += `<li>${analysisText}</li>`;
      });
      html += `</ul>`;
    }
    
    return html;
  }
  
  /**
   * Log workspace relationships as JSON
   */
  private logWorkspaceRelationships(): void {
    const recordsByType = {
      projects: this.projects,
      tables: this.tablesList,
      sessions: Object.keys(this.memoryMaps.bySessionId).map(sessionId => ({
        id: sessionId,
        projectId: this.projects.find(project =>
          this.memoryMaps.byProjectId[project.id] &&
          this.memoryMaps.byProjectId[project.id].some(memory => memory.session_id === sessionId)
        )?.id,
        memories: this.memoryMaps.bySessionId[sessionId] || []
      })),
      workstreams: Object.keys(this.memoryMaps.byWorkstreamId).map(workstreamId => ({
        id: workstreamId,
        units: this.memoryMaps.byUnitId[workstreamId] || [],
        memories: this.memoryMaps.byWorkstreamId[workstreamId] || []
      })),
      units: Object.keys(this.memoryMaps.byUnitId).map(unitId => ({
        id: unitId,
        workstreamId: this.memoryMaps.byWorkstreamId[unitId] || [],
        memories: this.memoryMaps.byUnitId[unitId] || []
      })),
      pipelines: this.pipelines,
      connections: this.connections,
      agents: this.agents,
      memories: this.memories
    };
  
    const memoryRelationships = this.memories.map(memory => ({
      memory_id: memory.memory_id,
      session_id: memory.session_id,
      project_id: memory.project_id,
      workspace_id: memory.workspace_id,
      workstream_id: memory.workstream_id,
      unit_id: memory.unit_id,
      table_id: memory.table_id,
      contextObjects: memory.context && memory.context.objects ? memory.context.objects : [],
      related_memories: memory.context && memory.context.related_memories ? memory.context.related_memories : []
    }));
  
    const workspaceRelationships = {
      projects: this.projects.map(project => ({
        id: project.id,
        name: project.name || project.id,
        tables: project.tables || [],
        sessions: this.memoryMaps.byProjectId[project.id] || [],
        memories: this.memoryMaps.byProjectId[project.id] || []
      })),
      tables: this.tablesList.map(table => ({
        id: table.id,
        name: table.name,
        projects: this.projects
          .filter(project => project.tables && Array.isArray(project.tables) && project.tables.includes(table.id))
          .map(project => project.id),
        memories: this.memoryMaps.byTableId[table.id] || []
      })),
      sessions: Object.keys(this.memoryMaps.bySessionId).map(sessionId => ({
        id: sessionId,
        projectId: this.projects.find(project =>
          this.memoryMaps.byProjectId[project.id] &&
          this.memoryMaps.byProjectId[project.id].some(memory => memory.session_id === sessionId)
        )?.id,
        memories: this.memoryMaps.bySessionId[sessionId] || []
      })),
      workstreams: Object.keys(this.memoryMaps.byWorkstreamId).map(workstreamId => ({
        id: workstreamId,
        units: this.memoryMaps.byUnitId[workstreamId] || [],
        memories: this.memoryMaps.byWorkstreamId[workstreamId] || []
      })),
      units: Object.keys(this.memoryMaps.byUnitId).map(unitId => ({
        id: unitId,
        workstreamId: this.memoryMaps.byWorkstreamId[unitId] || [],
        memories: this.memoryMaps.byUnitId[unitId] || []
      })),
      pipelines: this.pipelines,
      connections: this.connections,
      recordsByType: recordsByType,
      memoryRelationships: memoryRelationships
    };
  
    console.log('Workspace Relationships:', JSON.stringify(workspaceRelationships, null, 2));
  }
  

  ngOnDestroy(): void {
    // Clean up subscription
  }

  // Reset all filters and focused memory
  public resetAllFilters(): void {
    // Reset tab state
    this.activeTab = 'all';
    
    // Reset filter values
    this.selectedProjectId = 'all';
    this.selectedSessionId = 'all';
    this.selectedMemoryType = 'all';
    this.selectedTimeRange = 'all';
    this.selectedImportance = 0;
    this.selectedUser = 'all';
    this.startDate = '';
    this.endDate = '';
    
    // Clear any focused memory
    this.clearMemoryFocus();
    
    // Redraw the diagram with all memories
    this.drawDiagram();
  }

  // Get project name from project ID - exposed for the MemoriesDrawService
  public getProjectName(projectId: string): string {
    if (!projectId) return '';
    
    const project = this.projects.find(p => p.id.toLowerCase() === projectId.toLowerCase());
    if (project) {
      return project.name || project.label || projectId;
    }
    
    return '';
  }
  
  /**
   * Draw diagram with only elements related to the focused memory
   */
  private drawDiagramWithFilteredElements(): void {
    // Clear existing SVG
    d3.select('figure#records-diagram').selectAll('*').remove();
    
    // Use the same height calculation as in drawDiagram()
    const titleMargin = 0;
    const documentsHeight = 150;
    const contentPadding = 80;
    const memoriesHeight = 250;
    const agentsHeight = 150;
    const legendsHeight = 300;
    
    // Calculate total height needed - ensure it's at least 3000px for many projects
    const totalHeight = Math.max(3000, this.height + documentsHeight + memoriesHeight + agentsHeight + legendsHeight + (contentPadding * 5));
    
    this.tablesDrawService.initializeSvg(
      'figure#records-diagram', 
      this.width, 
      totalHeight + 300, // Add extra space for legends
      this.margin
    );
    
    // Get the SVG for adding titles
    const svg = this.tablesDrawService.getSvg();
    
    // Create a main group for all content
    const mainGroup = svg.append('g')
      .attr('class', 'main-content');
    
    // Get filtered memories based on the focused memory
    const filteredMemories = this.getFilteredMemories();
    
    // Draw the diagram with filtered data
    this.drawDiagramWithData(filteredMemories, mainGroup);
    
    // Store a local reference to focusedMemory to avoid null checking errors
    // We've already checked that focusedMemory is not null at the beginning of this method
    const focusedMemory = this.focusedMemory!;
    
    // After redrawing, ensure the current memory is highlighted
    setTimeout(() => {
      // Find the newly drawn memory in the objectsMap
      const memoryObjects = Object.entries(this.memoriesDrawService.objectsMap)
        .filter(([key, obj]) => 
          obj.type === 'memory' && 
          obj.data && 
          obj.data.memory_id === focusedMemory.memory_id);
      
      if (memoryObjects.length > 0) {
        const [key, obj] = memoryObjects[0];
        // Set this as the focused memory in the service
        this.memoriesDrawService.focusedMemoryId = key;
        // Apply highlighting
        this.memoriesDrawService.keepMemoryHighlighted(key);
      }
    }, 200);
    
    // Setup pan and zoom
    // this.setupPanAndZoom(mainGroup);
  }
  
  /**
   * Handle when a memory is focused
   */
  private handleMemoryFocus(memory: MemoryItem): void {
    // If this memory is already focused, clear the focus instead (toggle behavior)
    if (this.focusedMemory && this.focusedMemory.memory_id === memory.memory_id) {
      this.clearMemoryFocus();
      return;
    }
    
    // Store the previously focused memory for comparison
    const previousMemory = this.focusedMemory;
    
    // Set the new focused memory
    this.focusedMemory = memory;
    
    // Reset the connected objects set
    this.connectedObjects = new Set();
    
    // Add connected objects to the set
    if (memory) {
      // Always include the memory itself
      if (memory.memory_id) {
        this.connectedObjects.add(memory.memory_id);
      }
      
      // Add direct properties if they exist
      
      // Add session
      if (memory.session_id) {
        this.connectedObjects.add(memory.session_id);
      }
      
      // Add workstream
      if (memory.workstream_id) {
        this.connectedObjects.add(memory.workstream_id);
      }
      
      // Add table
      if (memory.table_id) {
        this.connectedObjects.add(memory.table_id);
      }
      
      // Add project
      if (memory.project_id) {
        this.connectedObjects.add(memory.project_id);
      }
      
      // Add unit
      if (memory.unit_id) {
        this.connectedObjects.add(memory.unit_id);
      }
      
      // Add workspace
      if (memory.workspace_id) {
        this.connectedObjects.add(memory.workspace_id);
      }
      
      // Add context objects if they exist
      if (memory.context && memory.context.objects) {
        memory.context.objects.forEach((obj: MemoryObject) => {
          if (obj.object_id) {
            this.connectedObjects.add(obj.object_id);
          }
        });
      }
      
      // Add related memories if they exist
      if (memory.context && memory.context.related_memories) {
        memory.context.related_memories.forEach((memoryId: string) => {
          this.connectedObjects.add(memoryId);
        });
      }
    }
    
    // Update the selected memory for the panel display
    this.selectedMemory = memory;
    
    // Populate related records for the Memory Details panel
    this.relatedRecords = [];
    
    // Redraw the diagram with only the connected elements
    this.drawDiagramWithFilteredElements();
    
    // After redrawing, ensure the current memory is highlighted
    setTimeout(() => {
      // Find the newly drawn memory in the objectsMap
      const memoryObjects = Object.entries(this.memoriesDrawService.objectsMap)
        .filter(([key, obj]) => 
          obj.type === 'memory' && 
          obj.data && 
          obj.data.memory_id === memory.memory_id);
      
      if (memoryObjects.length > 0) {
        const [key, obj] = memoryObjects[0];
        // Set this as the focused memory in the service
        this.memoriesDrawService.focusedMemoryId = key;
        // Apply highlighting
        this.memoriesDrawService.keepMemoryHighlighted(key);
      }
    }, 200);
    
    // Setup pan and zoom
    // this.setupPanAndZoom(mainGroup);
  }
  
  /**
   * Clear focus from the currently focused memory
   */
  private clearMemoryFocus(): void {
    this.focusedMemory = null;
    this.connectedObjects.clear();
    
    // Close the memory details panel when no memory is in focus
    this.selectedMemory = null;
    this.relatedRecords = [];
    
    // Redraw the full diagram
    this.drawDiagram();
  }
  
  /**
   * Apply focus effects to highlight connected objects and dim others
   */
  private applyFocusEffects(): void {
    if (!this.focusedMemory) return;
    
    // Apply visual effects to all rendered objects
    Object.keys(this.renderedObjects).forEach(id => {
      const obj = this.renderedObjects[id];
      const isConnected = this.connectedObjects.has(id);
      
      if (obj.element) {
        if (isConnected) {
          // Highlight connected objects
          obj.element.transition()
            .duration(300)
            .style('opacity', 1)
            .style('filter', 'drop-shadow(0 0 3px rgba(255, 255, 255, 0.3))');
        } else {
          // For non-connected objects
          if (obj.type === 'memory' && !this.isMemoryIndicatorInTable(obj)) {
            // Dim standalone memories that aren't connected
            obj.element.transition()
              .duration(300)
              .style('opacity', 0.3)
              .style('filter', 'grayscale(70%)');
          } else {
            // Keep tables and memory indicators inside tables at full opacity
            obj.element.transition()
              .duration(300)
              .style('opacity', 1)
              .style('filter', 'none');
          }
        }
      }
    });
    
    // Only apply special highlighting to the focused memory
    if (this.focusedMemory && this.focusedMemory.memory_id) {
      this.memoriesDrawService.keepMemoryHighlighted(this.focusedMemory.memory_id);
    }
  }
  
  /**
   * Check if a memory object is a small indicator inside a table
   */
  private isMemoryIndicatorInTable(obj: any): boolean {
    // Memory indicators in tables are typically smaller
    if (obj.width && obj.width < 15) {
      return true;
    }
    
    // Check if this memory is inside a table element
    if (obj.element && obj.element.node()) {
      const parentNode = obj.element.node().parentNode;
      if (parentNode) {
        const parentSelection = d3.select(parentNode);
        // Check if parent has table class or data attribute
        if (parentSelection.classed('table') || 
            parentSelection.attr('data-type') === 'table' ||
            parentSelection.attr('class')?.includes('table')) {
          return true;
        }
      }
    }
    
    // Check if this memory has a table parent in its data
    if (obj.data && obj.data.parent_type === 'table') {
      return true;
    }
    
    return false;
  }
  
  /**
   * Toggle a collapsible section in the memory details panel
   */
  toggleSection(sectionName: string): void {
    this.expandedSections[sectionName] = !this.expandedSections[sectionName];
  }
  
  /**
   * Get the CSS class for a reliability level
   */
  getReliabilityClass(reliability: number): string {
    if (reliability >= 8) return 'high';
    if (reliability >= 5) return 'medium';
    return 'low';
  }
  
  /**
   * Get color for a data type
   */
  public getTypeColor(type: string): string {
    const typeColorMap: {[key: string]: string} = {
      'string': '#8bc34a',
      'integer': '#42a5f5',
      'number': '#42a5f5',
      'boolean': '#ff9800',
      'date': '#9c27b0',
      'datetime': '#9c27b0',
      'time': '#9c27b0',
      'array': '#f44336',
      'object': '#607d8b',
      'other': '#90a4ae'
    };
    
    return typeColorMap[type] || typeColorMap['other'];
  }

  /**
   * Get the type label for a column type
   */
  public getTypeLabel(type: string): string {
    const typeLabels: Record<string, string> = {
      'data_issue': 'Data Issue',
      'model_evaluation': 'Model Evaluation',
      'user_feedback': 'User Feedback',
      'system_alert': 'System Alert',
      'performance_metric': 'Performance Metric',
      'default': 'Memory',
      'string': 'String',
      'number': 'Number',
      'boolean': 'Boolean',
      'date': 'Date',
      'object': 'Object',
      'array': 'Array',
      'other': 'Other'
    };
    
    return typeLabels[type] || typeLabels['other'];
  }
  
  /**
   * Close the memory details panel
   */
  closeMemoryDetails(): void {
    this.selectedMemory = null;
    this.relatedRecords = [];
    this.memoriesDrawService.clearMemoryFocus();
  }
  
  /**
   * Get the CSS class for an importance level
   */
  getImportanceClass(importance: number): string {
    if (importance >= 8) {
      return 'high';
    } else if (importance >= 5) {
      return 'medium';
    } else {
      return 'low';
    }
  }
  
  /**
   * Format a date string
   */
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  
  private getMemoryColor(type: string): string {
    // Use the color map from memory
    const colorMap: {[key: string]: string} = {
      'data_issue': '#607d8b',      // Blue-gray
      'model_evaluation': '#5c6bc0', // Indigo
      'user_feedback': '#26a69a',    // Teal
      'system_alert': '#ef5350',     // Light red
      'performance_metric': '#66bb6a', // Green
      'default': '#90a4ae'           // Light blue-gray
    };
    
    return colorMap[type] || this.memoryTypeColorMap[type] || '#9e9e9e'; // Try both maps or use default
  }

  // Helper methods for filtering diagram data
  private getTablesToRender(filteredMemories: MemoryItem[]): any[] {
    // If a focused memory exists, only show tables connected to it
    if (this.focusedMemory) {
      return this.tablesList.filter(table => 
        this.connectedObjects.has(table.id) || 
        this.connectedObjects.has(table.id.toLowerCase())
      );
    }
    
    // Get unique table IDs from the filtered memories
    const tableIds = new Set<string>();
    filteredMemories.forEach(memory => {
      if (memory.table_id) {
        tableIds.add(memory.table_id);
      } else if (memory.project_id) {
        // Instead of using table_id directly, we'll use project_id as a proxy
        // since we don't have direct table_id in the MemoryItem interface
        tableIds.add(memory.project_id);
      }
    });
    
    // If a specific project is selected, only show tables related to that project
    if (this.selectedProjectId !== 'all') {
      return this.tablesList.filter(table => {
        // Include tables without explicit project association
        const tableProjectId = (table as any).project_id;
        if (!tableProjectId) {
          return true;
        }
        
        // Include tables that match the selected project
        return tableProjectId === this.selectedProjectId;
      });
    }
    
    // If a specific session is selected, show tables related to that session
    // and tables without explicit session associations
    if (this.selectedSessionId !== 'all') {
      return this.tablesList.filter(table => {
        // Include tables without explicit project association
        const tableProjectId = (table as any).project_id;
        if (!tableProjectId) {
          return true;
        }
        
        // Find the project this table belongs to
        const project = this.projects.find(p => p.id === tableProjectId);
        if (!project) {
          console.error(`Project with ID ${tableProjectId} not found`);
          return true; // If we can't find the project, include the table
        }
        
        // If project has no sessions, include the table
        if (!project.sessions || project.sessions.length === 0) {
          return true;
        }
        
        // Check if the project has the selected session
        return project.sessions.some(session => session.id === this.selectedSessionId);
      });
    }
    
    // Return all tables if no specific filters are applied
    return this.tablesList;
  }
  
  private getProjectsToRender(filteredMemories: MemoryItem[]): any[] {
    // If a focused memory exists, only show projects connected to it
    if (this.focusedMemory) {
      return this.projects.filter(project => 
        this.connectedObjects.has(project.id) || 
        this.connectedObjects.has(project.id.toLowerCase())
      );
    }
    
    // If "All Projects" is selected, return all projects
    if (this.selectedProjectId === 'all') {
      // If a session is selected, filter projects to only show those with that session
      // and projects without explicit session associations
      if (this.selectedSessionId !== 'all') {
        return this.projects.filter(project => {
          // If project has no sessions, include it
          if (!project.sessions || project.sessions.length === 0) {
            return true;
          }
          
          // Check if the project has the selected session
          return project.sessions.some(session => session.id === this.selectedSessionId);
        });
      }
      return this.projects;
    }
    
    // If a specific project is selected, return only that project
    const selectedProject = this.projects.find(project => 
      project.id.toLowerCase() === this.selectedProjectId.toLowerCase()
    );
    
    // If a session is selected, filter the selected project's sessions
    if (selectedProject && this.selectedSessionId && this.selectedSessionId !== 'all') {
      // Create a copy of the project with only the selected session
      const filteredProject = { ...selectedProject };
      if (filteredProject.sessions) {
        filteredProject.sessions = filteredProject.sessions.filter(
          session => session.id === this.selectedSessionId
        );
      }
      return [filteredProject];
    }
    
    return selectedProject ? [selectedProject] : [];
  }
  
  private getDocumentsToRender(filteredMemories: MemoryItem[]): any[] {
    // If a focused memory exists, only show documents connected to it
    if (this.focusedMemory) {
      return Object.values(this.documents || {}).filter((doc: any) => 
        this.connectedObjects.has(doc.id) || 
        (doc.id && this.connectedObjects.has(doc.id.toLowerCase()))
      );
    }
    
    // If no documents are available, return an empty array
    if (!this.documents || Object.keys(this.documents).length === 0) {
      return [];
    }
    
    // Always return all documents, regardless of whether they're associated with memories
    // This ensures documents are always drawn, even for sessions without memories
    return Object.values(this.documents);
  }
  
  private getMemoriesToRender(filteredMemories: MemoryItem[]): any[] {
    // If a focused memory exists, return the filtered memories from getFilteredMemories()
    if (this.focusedMemory) {
      return this.getFilteredMemories();
    }
    
    // Otherwise return all filtered memories
    return filteredMemories;
  }
  
  private getAgentsToRender(filteredMemories: MemoryItem[]): any[] {
    // If a focused memory exists, only show agents connected to it
    if (this.focusedMemory) {
      return this.agents.filter(agent => 
        this.connectedObjects.has(agent.id) || 
        this.connectedObjects.has(agent.id.toLowerCase())
      );
    }
    
    // Always return all agents regardless of whether they're associated with memories
    // This ensures agents are always drawn, even for sessions without memories
    return this.agents;
  }
  
  private getConnectionsToRender(filteredMemories: MemoryItem[]): any[] {
    // If a focused memory exists, only show connections connected to it
    if (this.focusedMemory) {
      return this.connections.filter(connection => 
        this.connectedObjects.has(connection.id) || 
        this.connectedObjects.has(connection.id.toLowerCase()) ||
        this.connectedObjects.has(connection.sourceId) ||
        this.connectedObjects.has(connection.targetId)
      );
    }
    
    // If no connections, return empty array
    if (!this.connections || this.connections.length === 0) {
      return [];
    }
    
    // Filter connections based on the current filter settings
    let connectionsToRender = [...this.connections];
    
    // If a specific project is selected, filter connections related to that project
    if (this.selectedProjectId !== 'all') {
      // Commenting out projectId filtering since connections don't have this property yet
      // connectionsToRender = connectionsToRender.filter(connection => 
      //   connection.projectId === this.selectedProjectId || !connection.projectId
      // );
    }
    
    return connectionsToRender;
  }

  private getPipelinesToRender(filteredMemories: MemoryItem[]): any[] {
    // If a focused memory exists, only show pipelines connected to it
    if (this.focusedMemory) {
      return this.pipelines.filter(pipeline => 
        this.connectedObjects.has(pipeline.id) || 
        this.connectedObjects.has(pipeline.id.toLowerCase())
      );
    }
    
    // If no pipelines, return empty array
    if (!this.pipelines || this.pipelines.length === 0) {
      return [];
    }
    
    // Filter pipelines based on the current filter settings
    let pipelinesToRender = [...this.pipelines];
    
    // If a specific project is selected, filter pipelines related to that project
    if (this.selectedProjectId !== 'all') {
      // Commenting out projectId filtering since pipelines don't have this property yet
      // pipelinesToRender = pipelinesToRender.filter(pipeline => 
      //   pipeline.projectId === this.selectedProjectId || !pipeline.projectId
      // );
    }
    
    return pipelinesToRender;
  }
  
  // Filter by project
  filterByProject(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedProjectId = selectElement.value;
    
    // Load sessions for the selected project
    this.loadSessions(this.selectedProjectId);
    
    // Redraw the diagram with the selected project filter
    this.drawDiagram();
  }
  
  // Filter by session
  filterBySession(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedSessionId = selectElement.value;
    
    // Redraw the diagram with the selected session filter
    this.drawDiagram();
  }
  
  // Toggle legends panel
  toggleLegendsPanel(): void {
    this.legendsExpanded = !this.legendsExpanded;
    
    // Just redraw the diagram without updating dimensions
    setTimeout(() => {
      // Removed updateDimensions() call to keep the same dimensions
      this.drawDiagram();
    }, 350); // Match this with the CSS transition duration (300ms) plus a small buffer
  }

  // Set the default project to the current project
  private async setDefaultProject(): Promise<void> {
    try {
      // Use the workboardManagerService to get the current project
      const { projectId } = await this.workboardManagerService.loadCurrentProject();
      
      if (projectId && projectId !== 'all') {
        // Find the project in the loaded projects
        const currentProject = this.projects.find(p => p.id === projectId);
        
        if (currentProject) {
          this.selectedProjectId = projectId;
          
          // Apply the filter to show only memories for this project
          this.filterMemories();
        }
      }
    } catch (error) {
      console.error('Error setting default project:', error);
    }
  }

  /**
   * Get filtered memories based on the focused memory
   */
  private getFilteredMemories(): MemoryItem[] {
    // If no memory is focused, return all memories
    if (!this.focusedMemory) {
      return this.memories;
    }
    
    const result: MemoryItem[] = [];
    
    // Add related memories from context if they exist
    if (this.focusedMemory.context && this.focusedMemory.context.related_memories) {
      this.focusedMemory.context.related_memories.forEach(memoryId => {
        const relatedMemory = this.memories.find(m => m.memory_id === memoryId);
        if (relatedMemory && !result.includes(relatedMemory)) {
          result.push(relatedMemory);
        }
      });
    }
    
    // Also include memories that have this memory in their context
    this.memories.forEach(memory => {
      if (memory.context && 
          memory.context.related_memories && 
          this.focusedMemory && 
          this.focusedMemory.memory_id && 
          memory.context.related_memories.includes(this.focusedMemory.memory_id) &&
          !result.includes(memory)) {
        result.push(memory);
      }
    });
    
    // Store a local reference to focusedMemory to avoid null checking errors
    // We've already checked that focusedMemory is not null at the beginning of this method
    const focusedMemory = this.focusedMemory!;
    
    // Include memories that share the same project, session, or table
    this.memories.forEach(memory => {
      // Skip if this is the focused memory or already in results
      if (memory === focusedMemory || result.includes(memory)) {
        return;
      }
      
      // Check for shared project
      if (focusedMemory.project_id && memory.project_id === focusedMemory.project_id) {
        result.push(memory);
      }
      // Check for shared session
      else if (focusedMemory.session_id && memory.session_id === focusedMemory.session_id) {
        result.push(memory);
      }
      // Check for shared table
      else if (focusedMemory.table_id && memory.table_id === focusedMemory.table_id) {
        result.push(memory);
      }
    });
    
    return result;
  }

  /**
   * Toggle the feature importance chart
   */
  public toggleFeatureImportanceChart(): void {
    this.showFeatureImportanceChart = !this.showFeatureImportanceChart;
    console.log('Feature importance chart toggled:', this.showFeatureImportanceChart);
  }
  
  /**
   * Close the feature importance chart
   */
  public closeFeatureImportanceChart(): void {
    this.showFeatureImportanceChart = false;
  }
  

}