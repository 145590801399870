<!-- src/app/components/notifications/notifications.component.html -->
<div class="notifications-pane" *ngIf="showPane">
  <div class="pane-header">
    <h2>Notifications</h2>
    <div class="header-actions">
      <button mat-button class="dismiss-all-button" (click)="dismissAll()" [disabled]="notifications.length === 0">
        Dismiss all
      </button>
      <button mat-icon-button class="close-button" (click)="closePane()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  
  <div class="pane-content">
    <ng-container *ngIf="notifications.length > 0; else noNotifications">
      <div 
        class="notification-item" 
        *ngFor="let notification of notifications"
        [class.unread]="!notification.read"
        [ngClass]="getNotificationTypeClass(notification.type)"
        (click)="onNotificationClick(notification)"
      >
        <div class="notification-icon" [ngStyle]="{ 'background-color': getNotificationColor(notification.type) }">
          <mat-icon *ngIf="notification.type === 'info'">info</mat-icon>
          <mat-icon *ngIf="notification.type === 'success'">check_circle</mat-icon>
          <mat-icon *ngIf="notification.type === 'warning'">warning</mat-icon>
          <mat-icon *ngIf="notification.type === 'error'">error</mat-icon>
          <mat-icon *ngIf="notification.type === 'deployment'">cloud_done</mat-icon>
          <mat-icon *ngIf="notification.type === 'message'">message</mat-icon>
          <mat-icon *ngIf="!['info', 'success', 'warning', 'error', 'deployment', 'message'].includes(notification.type)">notifications</mat-icon>
        </div>
        
        <div class="notification-content">
          <div class="notification-message">{{ notification.message }}</div>
          <div class="notification-meta">
            <span class="notification-time">{{ formatTimestamp(notification.timestamp) }}</span>
            <span class="notification-type" [ngStyle]="{ 'color': getNotificationColor(notification.type) }">
              {{ notification.type }}
            </span>
          </div>
        </div>
        
        <button mat-icon-button class="dismiss-button" (click)="dismissNotification($event, notification.id)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </ng-container>
    
    <ng-template #noNotifications>
      <div class="no-notifications">
        <mat-icon>notifications_off</mat-icon>
        <p>No notifications to display</p>
      </div>
    </ng-template>
  </div>
</div> 