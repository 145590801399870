<div class="sidebar" [class.compact]="isCompact">
  <div class="sidebar-content">


    <!-- Menu Button -->
    <div class="action-group menu-action">
      <button *ngIf="menuAction"
              class="action-button"
              [class.selected]="isMenuOpen"
              (click)="onActionClick(menuAction)"
              [matTooltip]="menuAction.label"
              matTooltipPosition="right">
        <div class="icon-circle"></div>
        <mat-icon>{{menuAction.icon}}</mat-icon>
      </button>
    </div>

    <!-- Pinned Actions -->
    <div class="action-group pinned-actions">
      <button *ngFor="let action of pinnedActions"
              class="action-button"
              [class.selected]="selectedActionId === action.id"
              (click)="onActionClick(action)"
              [matTooltip]="action.label"
              matTooltipPosition="right">
        <div class="icon-circle"></div>
        <mat-icon>{{action.icon}}</mat-icon>
      </button>
    </div>

    <!-- Bottom Actions -->
    <div class="action-group bottom-actions">
      <button *ngFor="let action of bottomActions"
              class="action-button"
              [class.selected]="selectedActionId === action.id"
              (click)="onActionClick(action)"
              [matTooltip]="action.label"
              matTooltipPosition="right">
        <div class="icon-circle"></div>
        <mat-icon [style.color]="action.color">{{action.icon}}</mat-icon>
      </button>
    </div>
  </div>
</div>
