import { Component, OnInit, HostListener, ElementRef, AfterViewChecked } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionsService } from '../../../services/sessions.service';
import { UserService } from '../../../services/user.service';
import { TabInfo, Project, Phase } from '../../../models/session.interface';
import { TabComponent } from '../tab/tab.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ChatComponent } from '../../session/chat/chat.component';
import { firstValueFrom } from 'rxjs';
import { ChatSession } from '../../../models/chat.models';
import { SessionsHistoryComponent } from '../../record-management/sessions-history/sessions-history.component';
import { StartPageComponent } from '../../record-management/start-page/start-page.component';

@Component({
  selector: 'app-tab-container',
  standalone: true,
  imports: [
    CommonModule,
    TabComponent,
    MatButtonModule,
    MatIconModule,
    ChatComponent,
    SessionsHistoryComponent,
    StartPageComponent
  ],
  templateUrl: './tab-container.component.html',
  styleUrls: ['./tab-container.component.scss']
})
export class TabContainerComponent implements OnInit, AfterViewChecked {
  tabs: TabInfo[] = [];
  shouldShrinkTabs = false;
  activeSessionId: string | null = null;
  
  constructor(
    private sessionsService: SessionsService,
    private userService: UserService,
    private elementRef: ElementRef
  ) {}
  
  ngOnInit() {
    // Subscribe to tabs to get current state including active status
    this.sessionsService.getTabs().subscribe(tabs => {
      this.tabs = tabs;
      const activeTab = tabs.find(tab => tab.active);
      if (activeTab) {
        this.activeSessionId = activeTab.sessionId;
        this.handleTabActivation(activeTab.sessionId);
      }
      this.checkTabsOverflow();
    });
  }

  private async handleTabActivation(sessionId: string) {
    const tabRef = this.sessionsService.getTabReference(sessionId);
    if (tabRef) {
      try {
        // Get current projects from the Observable
        const projects = await firstValueFrom(this.sessionsService.getProjects());
        const project = projects.find((p: Project) => p.id === tabRef.projectId);
        
        if (project) {
          await this.sessionsService.selectProject(project);
          const phases = await this.sessionsService.getPhases(project.color);
          const phase = phases.find((p: Phase) => p.id === tabRef.phaseId);
          if (phase) {
            // Ensure phase is selected after project selection is complete
            setTimeout(() => this.sessionsService.selectPhase(phase), 0);
          }
        }
      } catch (error) {
        console.error('[TabContainer] Error handling tab activation:', error);
      }
    }
  }

  getTabColor(sessionId: string): string | undefined {
    const tabRef = this.sessionsService.getTabReference(sessionId);
    return tabRef?.color;
  }

  ngAfterViewChecked() {
    this.checkTabsOverflow();
  }

  @HostListener('window:resize')
  onResize() {
    this.checkTabsOverflow();
  }

  private checkTabsOverflow() {
    const tabsList = this.elementRef.nativeElement.querySelector('.tabs-list');
    if (!tabsList) return;

    const tabElements = Array.from(tabsList.children) as HTMLElement[];
    const totalWidth = tabElements.reduce((acc: number, child: HTMLElement) => {
      return acc + child.offsetWidth;
    }, 0);
    
    const availableWidth = tabsList.clientWidth;
    this.shouldShrinkTabs = totalWidth > availableWidth;
  }
  
  private scrollToTab(sessionId: string) {
    setTimeout(() => {
      const tabsList = this.elementRef.nativeElement.querySelector('.tabs-list');
      const activeTab = this.elementRef.nativeElement.querySelector(`app-tab[data-session-id="${sessionId}"]`);
      
      if (tabsList && activeTab) {
        const tabsListRect = tabsList.getBoundingClientRect();
        const activeTabRect = activeTab.getBoundingClientRect();
        
        const isTabOutsideView = 
          activeTabRect.left < tabsListRect.left || 
          activeTabRect.right > tabsListRect.right;
        
        if (isTabOutsideView) {
          tabsList.scrollTo({
            left: activeTab.offsetLeft - tabsListRect.width / 2 + activeTab.offsetWidth / 2,
            behavior: 'smooth'
          });
        }
      }
    });
  }

  onTabClose(sessionId: string) {
    this.sessionsService.closeSession(sessionId, 'default-user');
  }
  
  onTabActivate(sessionId: string) {
    this.activeSessionId = sessionId;
    this.sessionsService.setActiveSession(sessionId);
    this.scrollToTab(sessionId);
  }
  
  async createNewSession() {
    const user = await this.userService.getCurrentUser();
    const userId = user?.email || 'default-user';
    await this.sessionsService.startSession(userId);
  }

  async onNewTab() {
    const user = await this.userService.getCurrentUser();
    const userId = user?.email || 'default-user';
    
    // Get current project and phase
    const currentProject = await firstValueFrom(this.sessionsService.getSelectedProject());
    const currentPhase = await firstValueFrom(this.sessionsService.getSelectedPhase());
    
    if (!currentProject || !currentPhase) {
      console.warn('[TabContainer] Cannot create new tab: No project or phase selected');
      return;
    }
    
    await this.sessionsService.startSession(userId);
  }
}