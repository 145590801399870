import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitterComponent } from './splitter.component';
import { SplitterPaneComponent } from './splitter-pane.component';

@NgModule({
  declarations: [
    SplitterComponent,
    SplitterPaneComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SplitterComponent,
    SplitterPaneComponent
  ]
})
export class SplitterModule { } 