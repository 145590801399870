import { Component, ViewChild, ElementRef, OnInit, OnDestroy, Renderer2, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MingusviewComponent } from './mingusview/mingusview.component';
import { CodeviewComponent } from './codeview/codeview.component';
import { DatatableComponent } from './datatable/datatable.component';
import { SplitterModule } from '../../shared/components/splitter/splitter.module';
import { ChartviewComponent } from './chartview/chartview.component';
import { Subscription } from 'rxjs';
import { WorkspaceState } from '../../models/workspace-state.model';
import { TableModel } from '../../models/tables.model';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-datapane',
  standalone: true,
  imports: [
    CommonModule,
    MingusviewComponent,
    CodeviewComponent,
    DatatableComponent,
    SplitterModule,
    ChartviewComponent
  ],
  templateUrl: './datapane.component.html',
  styleUrls: ['./datapane.component.scss']
})
export class DatapaneComponent implements OnInit, OnDestroy {
  // Panel visibility toggles
  showMingus = false;
  showCode = false;
  showData = true;
  showChart = false;
  mermaidCode: string = '';
  mermaidSubscription: Subscription | undefined;
  currentTableSubscription: Subscription | undefined;
  currentTable: TableModel | null = null;
  isChartLoading: boolean = false;
  
  // Floating mode properties
  isFloating = false;
  
  // Apply floating class
  @HostBinding('class.floating') 
  get floatingClass() { 
    return this.isFloating; 
  }
  
  // Floating position (only used when floating)
  private _floatingTop = 100;
  private _floatingLeft = 100;
  
  // Only apply these bindings when actually floating
  @HostBinding('style.top.px') 
  get floatingTop() { 
    return this.isFloating ? this._floatingTop : null; 
  }
  
  @HostBinding('style.left.px') 
  get floatingLeft() { 
    return this.isFloating ? this._floatingLeft : null; 
  }

  // Dragging state
  private isDragging = false;
  private dragOffsetX = 0;
  private dragOffsetY = 0;

  @ViewChild(DatatableComponent) datatableComponent!: DatatableComponent;
  @ViewChild(ChartviewComponent) chartviewComponent!: ChartviewComponent;

  constructor(
    private workspaceState: WorkspaceState, 
    private toolsService: ToolsService,
    private elementRef: ElementRef, 
    private renderer: Renderer2
  ) {
    // Add event listeners for dragging only
    window.addEventListener('mousemove', this.onMouseMove.bind(this));
    window.addEventListener('mouseup', this.onMouseUp.bind(this));
  }

  ngOnInit(): void {
    // Subscribe to Mermaid code changes
    this.mermaidSubscription = this.workspaceState.CurrentMermaid$.subscribe(code => {
      this.mermaidCode = code || '';
    });

    // Subscribe to CurrentTable changes
    this.currentTableSubscription = this.workspaceState.CurrentTable$.subscribe(tableModel => {
      this.currentTable = tableModel;
    });
  }

  togglePane(pane: 'data' | 'mingus' | 'code' | 'chart'): void {
    switch(pane) {
      case 'mingus':
        this.showMingus = !this.showMingus;
        break;
      case 'code':
        this.showCode = !this.showCode;
        break;
      case 'data':
        this.showData = !this.showData;
        break;
      case 'chart':
        this.showChart = !this.showChart;
        // If showing the chart and there's no mermaid code, trigger chart generation
        if (this.showChart && (!this.mermaidCode || this.mermaidCode.trim() === '')) {
          this.isChartLoading = true;
          const currentSessionId = this.workspaceState.sessionsGridState$.value.selectedSessionId || '';
          this.toolsService.callTool('generateChart', '', currentSessionId, null).finally(() => {
            this.isChartLoading = false;
          });
        }
        break;
    }
  }

  getColspan(pane: 'mingus' | 'code' | 'data' | 'chart'): number {
    const visiblePanes = [this.showMingus, this.showCode, this.showData, this.showChart].filter(Boolean).length;
    
    // Single pane visible - take full width
    if (visiblePanes === 1) {
      return 4;
    }
    
    // Two panes visible
    if (visiblePanes === 2) {
      // If only data and chart are visible, split 50/50
      if (this.showData && this.showChart && !this.showCode && !this.showMingus) {
        return 2; // 50% each
      }
      // If data pane is one of the two visible
      if (this.showData && (pane === 'data')) {
        return 3; // 75%
      }
      if (this.showData && (pane === 'mingus' || pane === 'code' || pane === 'chart')) {
        return 1; // 25%
      }
      // If only mingus and code are visible
      return 2; // 50% each
    }
    
    // All three or four panes visible
    if (pane === 'data') {
      return 2; // 50%
    }
    return 1; // 25% for mingus and code
  }
  
  // Toggle floating mode
  toggleFloating(): void {
    console.log(`Toggling floating mode from ${this.isFloating} to ${!this.isFloating}`);
    
    this.isFloating = !this.isFloating;
    
    if (this.isFloating) {
      // Capture current dimensions for when we dock again
      this.centerFloatingPane();
    } else {
      // When docking, close the histogram if it's open
      if (this.datatableComponent) {
        this.datatableComponent.closeHistogram();
      }
      
      // IMPORTANT: Clear ALL inline styles when docking
      const element = this.elementRef.nativeElement;
      
      // We must preserve classes but remove all styles
      // This is to ensure no residual height/width/position styles remain
      const classList = [...element.classList];
      
      this.renderer.removeAttribute(element, 'style');
      
      // Re-add any classes that might have been removed
      classList.forEach(cls => {
        if (cls !== 'floating') {
          this.renderer.addClass(element, cls);
        }
      });
      
      console.log('Docked component and cleared all inline styles');
    }
  }
  
  // Center the floating pane in the viewport
  private centerFloatingPane(): void {
    // Wait for next frame to ensure DOM updates with floating class applied
    requestAnimationFrame(() => {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      
      // Set initial position to center and size to 96vw x 92vh
      const width = viewportWidth * 0.96;
      const height = viewportHeight * 0.92;
      this._floatingLeft = (viewportWidth - width) / 2;
      this._floatingTop = (viewportHeight - height) / 2;
      
      // Apply positioning explicitly
      const element = this.elementRef.nativeElement;
      this.renderer.setStyle(element, 'left', `${this._floatingLeft}px`);
      this.renderer.setStyle(element, 'top', `${this._floatingTop}px`);
      this.renderer.setStyle(element, 'width', `${width}px`);
      this.renderer.setStyle(element, 'height', `${height}px`);
      
      console.log(`Positioned floating pane at ${this._floatingLeft}x${this._floatingTop}`);
    });
  }
  
  // Start dragging the floating pane
  startDragging(event: MouseEvent): void {
    if (!this.isFloating) return;
    
    this.isDragging = true;
    
    const element = this.elementRef.nativeElement;
    const rect = element.getBoundingClientRect();
    this.dragOffsetX = event.clientX - rect.left;
    this.dragOffsetY = event.clientY - rect.top;
    
    event.preventDefault();
  }
  
  // Handle mouse movement for dragging
  private onMouseMove(event: MouseEvent): void {
    if (this.isDragging && this.isFloating) {
      this._floatingLeft = event.clientX - this.dragOffsetX;
      this._floatingTop = event.clientY - this.dragOffsetY;
    }
  }
  
  // Handle mouse up to stop dragging
  private onMouseUp(): void {
    this.isDragging = false;
  }
  
  // Export table data to Excel
  exportTableToExcel(): void {
    if (this.datatableComponent) {
      this.datatableComponent.exportToExcel();
    }
  }
  
  // For compatibility with HTML template
  startResizing(event: MouseEvent): void {
    // This is intentionally empty - we're not implementing custom resize
    console.log('Resize handle clicked, but custom resize is disabled');
    event.preventDefault();
  }
  
  ngOnDestroy(): void {
    // Clean up event listeners
    window.removeEventListener('mousemove', this.onMouseMove.bind(this));
    window.removeEventListener('mouseup', this.onMouseUp.bind(this));
    
    // Clean up subscriptions
    if (this.mermaidSubscription) {
      this.mermaidSubscription.unsubscribe();
    }
    if (this.currentTableSubscription) {
      this.currentTableSubscription.unsubscribe();
    }
  }
}