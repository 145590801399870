// src/app/services/workspace-manager.service.ts
 
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspacesData, WorkspaceItem } from '../models/workspace.interface';
import { ProjectItem } from '../models/project.interface';
import { UserService } from './user.service';
import { ArtifactsService } from './artifacts.service';
import { WorkspaceState } from '../models/workspace-state.model';
import { environment } from '../../environments/environment';
import { UserWorkspaceState, UserWorkspaceStateUpdate } from '../models/user-workspace-state.interface';
import { ProjectData } from '../models/project.interface';
import { ChatService } from './chat.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceManagerService {
  constructor(
    private artifactsService: ArtifactsService,
    private userService: UserService,
    private sharedState: WorkspaceState
  ) {}

  private generateId(): string {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  /**
   * Returns the ID of the currently selected workspace
   * @returns The current workspace ID or undefined if none is selected
   */
  public getCurrentWorkspaceId(): string | undefined {
    return this.sharedState.userWorkspaceState$.value?.selectedWorkspace;
  }

  public async initWorkspaces(): Promise<void> {
    console.log('[WorkspaceManagerService] Initializing service');
    try {
      const savedData = await this.artifactsService.loadArtifact(environment.rootWorkspaceName);
      if (savedData) {
        this.sharedState.workspacesData$.next(savedData);
      }

      const savedUserState = await this.artifactsService.loadArtifact(environment.rootWorkspaceName, true);
      if (savedUserState) {
        this.sharedState.userWorkspaceState$.next(savedUserState);
        
        // Load project data if there's a selected project
        if (savedUserState.selectedProject) {
          const artifactId = 'project_' + savedUserState.selectedProject;
          const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
          if (projectData) {
            this.sharedState.sessions$.next(projectData.sessions || []);
            this.sharedState.workboardState$.next(projectData.workboardState || { groups: [] });
            this.sharedState.setProject(savedUserState.selectedProject, projectData);
          }

          const userProjectView = await this.artifactsService.loadArtifact(artifactId, true);
          if (userProjectView) {
            this.sharedState.sessionsGridState$.next(userProjectView.sessionState || { filters: [] });
          } else {
            this.sharedState.sessionsGridState$.next({ filters: [] });
          }
        }
      } else {
        const user = await this.userService.getCurrentUser();
        const workspaces = this.sharedState.workspacesData$.value.workspaces;
        if (workspaces.length > 0) {
          const initialState: UserWorkspaceState = {
            agentId: user?.email || '',
            selectedWorkspace: workspaces[0].id,
            selectedProject: workspaces[0].projects.length > 0 ? workspaces[0].projects[0].id : '',
            lastUpdated: new Date().toISOString()
          };
          await this.saveUserWorkspaceState(initialState);
          
          // Load initial project data if there's a project
          if (initialState.selectedProject) {
            const artifactId = 'project_' + initialState.selectedProject;
            const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
            if (projectData) {
              this.sharedState.sessions$.next(projectData.sessions || []);
              this.sharedState.workboardState$.next(projectData.workboardState || { groups: [] });
              this.sharedState.setProject(initialState.selectedProject, projectData);
            } else {
              this.sharedState.sessions$.next([]);
              this.sharedState.workboardState$.next({ groups: [] });
            }

            const userProjectView = await this.artifactsService.loadArtifact(artifactId, true);
            if (userProjectView) {
              this.sharedState.sessionsGridState$.next(userProjectView.sessionState || { filters: [] });
            } else {
              this.sharedState.sessionsGridState$.next({ filters: [] });
            }
          }
        }
      }
    } catch (error) {
      console.error('Failed to load workspaces data:', error);
    }
  }

  getWorkspacesData(): Observable<WorkspacesData> {
    return this.sharedState.workspacesData$.asObservable();
  }

  public async saveWorkspacesData(): Promise<void> {
    const data = this.sharedState.workspacesData$.value;
    data.lastUpdated = new Date().toISOString();
    await this.artifactsService.saveArtifact(environment.rootWorkspaceName, data, false, false);
  }

   public async saveProjectData(projectId: string): Promise<void> {
    // Get the current project from the state system
    const projectSubject = this.sharedState.getProjectObservable(projectId);
    const artifactId = `project_${projectId}`;

    const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
    
    if (projectData) {
        projectData.lastUpdated = new Date().toISOString();
        
        // Save to artifact store and update shared state
        await this.artifactsService.saveArtifact(artifactId, projectData, false, false);
        this.sharedState.setProject(projectId, projectData);
    }
  }

  public async saveChatSessionData(sessionId: string): Promise<void> {
    const chatSessionSubject = this.sharedState.tryGetChatSession(sessionId);

    if (chatSessionSubject) {
      const chatSession = chatSessionSubject.value;
      const artifactId = `session_${sessionId}`;
      
      await this.artifactsService.saveArtifact(artifactId, chatSession, false, false);
      this.sharedState.setChatSession(sessionId, chatSession);
      this.sharedState.setSessionMessages(sessionId, chatSession.turns);
    }
  }


  async addWorkspace(name: string): Promise<void> {
    const data = this.sharedState.workspacesData$.value;
    const user = await this.userService.getCurrentUser();
    const newWorkspace: WorkspaceItem = {
      id: this.generateId(),
      name,
      createdAt: new Date().toISOString(),
      agentId: user?.email || '',
      projects: [],
      stateMap: {
        'name': {
          id: 'name',
          name: 'Workspace Name',
          description: 'Display name of the workspace',
          whyRelevant: 'Primary identifier for users',
          type: 'string',
          data: name
        },
        'id': {
          id: 'id',
          name: 'Workspace ID',
          description: 'Unique identifier for the workspace',
          whyRelevant: 'Used to uniquely identify this workspace in the system',
          type: 'string',
          data: this.generateId()
        },
        'createdat': {
          id: 'createdat',
          name: 'Creation Date',
          description: 'When the workspace was created',
          whyRelevant: 'Tracks workspace creation timeline',
          type: 'datetime',
          data: new Date().toISOString()
        },
        'createdby': {
          id: 'createdby',
          name: 'Created By',
          description: 'Email of workspace creator',
          whyRelevant: 'Tracks workspace ownership',
          type: 'string',
          data: user?.email || ''
        }
      }
    };
    
    data.workspaces.push(newWorkspace);
    this.sharedState.workspacesData$.next(data);
    await this.saveWorkspacesData();
  }

  async deleteWorkspace(workspaceId: string): Promise<void> {
    const data = this.sharedState.workspacesData$.value;
    const workspace = data.workspaces.find((w: WorkspaceItem) => w.id === workspaceId);
    if (workspace) {
      for (const project of workspace.projects) {
        await this.artifactsService.saveArtifact('project_' + project.id, null, false, false);

        await this.artifactsService.saveArtifact('project_' + project.id, null, true, false);
      }
    }
    data.workspaces = data.workspaces.filter((w: WorkspaceItem) => w.id !== workspaceId);
    this.sharedState.workspacesData$.next(data);
    await this.saveWorkspacesData();
  }

  async addProject(workspaceId: string, label: string): Promise<void> {
    const data = this.sharedState.workspacesData$.value;
    const workspace = data.workspaces.find((w: WorkspaceItem) => w.id === workspaceId);
    
    if (workspace) {
      const user = await this.userService.getCurrentUser();
      const newProject: ProjectItem = {
        id: this.generateId(),
        label,
        color: '#' + Math.floor(Math.random()*16777215).toString(16),
        type: 'default',
        createdAt: new Date().toISOString(),
        agentId: user?.email || '',
        description: '',
        stateMap: {}
      };
      
      workspace.projects.push(newProject);
      this.sharedState.workspacesData$.next(data);
      await this.saveWorkspacesData();

      // Create default session
      const projectJammer = {
        email: 'project.jammer@ask-y.ai',
        name: 'Project Manager'
      };

      const sessionId = 'A'; // First session is always 'A'
      const defaultSession = {
        id: this.generateId(),
        createdBy: user?.email || '',
        createdDate: new Date(),
        lastEdited: new Date(),
        session: sessionId,
        sessionId: sessionId,
        name: 'Ask a PM',
        viewStatus: 'visible' as const,
        completionStatus: 'opened' as const,
        participants: [projectJammer, ...(user ? [user] : [])],
        sessionType: 'session' as const,
        playbook: ChatService.GetDefaultPMPlaybook(),
        description: 'New session created by user'
      };

      // Create default project data with empty workboard
      const projectData: ProjectData = {
        sessions: [defaultSession],
        workboardState: {
          groups: []
        },
        lastUpdated: new Date().toISOString(),
        id: newProject.id,
        label: label,
        type: 'default',
        color: newProject.color,
        createdAt: newProject.createdAt,
        agentId: newProject.agentId,
        stateMap: {
          'id': {
            id: 'id',
            name: 'Project ID',
            description: 'Unique identifier for the project',
            whyRelevant: 'Used to uniquely identify this project in the system',
            type: 'string',
            data: newProject.id
          },
          'name': {
            id: 'name',
            name: 'Project Name',
            description: 'Display name of the project',
            whyRelevant: 'Used as the main identifier for users',
            type: 'string',
            data: label
          },
          'description': {
            id: 'description',
            name: 'Project Description',
            description: 'Detailed description of the project',
            whyRelevant: 'Provides context about the project purpose',
            type: 'string',
            data: newProject.description || ''
          },
          'createdby': {
            id: 'createdby',
            name: 'Created By',
            description: 'Agent ID of project creator',
            whyRelevant: 'Tracks project ownership',
            type: 'string',
            data: newProject.agentId
          },
          'createdat': {
            id: 'createdat',
            name: 'Creation Date',
            description: 'When the project was created',
            whyRelevant: 'Tracks project timeline',
            type: 'datetime',
            data: newProject.createdAt
          },
          'type': {
            id: 'type',
            name: 'Project Type',
            description: 'Type of project',
            whyRelevant: 'Categorizes the project',
            type: 'string',
            data: 'default'
          }
        }
      };

      await this.artifactsService.saveArtifact('project_' + newProject.id, projectData, false, false);
      this.sharedState.setProject(newProject.id, projectData);

      // Create default user project view with first session selected
      const userProjectView = {
        sessionState: {
          filters: [],
          sortBy: 'lastEdited' as const,
          sortDirection: 'desc' as const,
          selectedSessionId: defaultSession.id
        },
        groupStates: {},
        workstreamStates: {}
      };

      await this.artifactsService.saveArtifact('project_' + newProject.id, userProjectView, true, false);

      // Update shared state with new project data
      if (workspace.projects.length === 1) {
        // If this is the first project in the workspace, select it
        await this.selectProject(workspaceId, newProject.id);
      }
    }
  }

  async deleteProject(workspaceId: string, projectId: string): Promise<void> {
    const data = this.sharedState.workspacesData$.value;
    const workspace = data.workspaces.find((w: WorkspaceItem) => w.id === workspaceId);
    
    if (workspace) {
      workspace.projects = workspace.projects.filter((p: ProjectItem) => p.id !== projectId);
      this.sharedState.workspacesData$.next(data);
      await this.saveWorkspacesData();

      await this.artifactsService.saveArtifact('project_' + projectId, null, false, false);
      await this.artifactsService.saveArtifact('project_' + projectId, null, true, false);

      // Remove from projectsMap$
      this.sharedState.removeProject(projectId);
    }
  }

  async selectWorkspace(workspaceId: string): Promise<void> {
    const workspace = this.sharedState.workspacesData$.value.workspaces.find((w: WorkspaceItem) => w.id === workspaceId);
    if (workspace && workspace.projects.length > 0) {
      await this.selectProject(workspaceId, workspace.projects[0].id);
    }
  }

  async selectProject(workspaceId: string, projectId: string): Promise<void> {
    await this.saveUserWorkspaceState({
      selectedWorkspace: workspaceId,
      selectedProject: projectId
    });

    // Load project data
    const artifactId = 'project_' + projectId;
    const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
    if (projectData) {
      // Update projects map
      this.sharedState.setProject(projectId, projectData);

      // Update sessions and workboard state
      this.sharedState.sessions$.next(projectData.sessions || []);
      this.sharedState.workboardState$.next(projectData.workboardState || { groups: [] });
    } else {
      // Initialize with empty states if no data exists
      this.sharedState.sessions$.next([]);
      this.sharedState.workboardState$.next({ groups: [] });
    }

    // Load user project view data
    const userProjectView = await this.artifactsService.loadArtifact(artifactId, true);
    if (userProjectView) {
      this.sharedState.sessionsGridState$.next(userProjectView.sessionState || { filters: [] });
    } else {
      this.sharedState.sessionsGridState$.next({ filters: [] });
    }
  }

  public async saveUserWorkspaceState(update: UserWorkspaceStateUpdate): Promise<void> {
    try {
      const currentState = this.sharedState.userWorkspaceState$.value;
      const newState: UserWorkspaceState = {
        ...currentState,
        ...update,
        lastUpdated: new Date().toISOString()
      } as UserWorkspaceState;

      if (!newState.agentId) {
        const user = await this.userService.getCurrentUser();
        newState.agentId = user?.email || '';
      }

      await this.artifactsService.saveArtifact(environment.rootWorkspaceName, newState, true, false);
      this.sharedState.userWorkspaceState$.next(newState);
    } catch (error) {
      console.error('Failed to save user workspace state:', error);
      throw error;
    }
  }

  public getUserWorkspaceState(): Observable<UserWorkspaceState | null> {
    return this.sharedState.userWorkspaceState$.asObservable();
  }

    /**
   * Returns a WorkspaceItem by the specified workspace ID.
   * @param workspaceId The ID of the desired workspace
   * @returns The matching WorkspaceItem or undefined if not found
   */
    public getWorkspaceById(workspaceId: string): WorkspaceItem | undefined {
      const data = this.sharedState.workspacesData$.value;
      return data.workspaces.find((w: WorkspaceItem) => w.id === workspaceId);
    }
  
    /**
     * Returns a ProjectData by the specified project ID, 
     * looking within the given workspace ID.
     * @param projectId The ID of the project to retrieve
     * @returns The matching ProjectData or undefined if not found
     */
    public getProjectById(projectId: string): ProjectData | undefined {
      const projectSubject = this.sharedState.getProjectObservable(projectId);
      return projectSubject ? projectSubject.value : undefined;
    }
}
