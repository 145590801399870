import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

interface ActivityItem {
  id: string;
  icon: string;
  text: string;
  time: string;
  user: string;
}

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ]
})
export class ActivityLogComponent implements OnInit, OnDestroy {
  showPane = false;
  activities: ActivityItem[] = [
    {
      id: '1',
      icon: 'icon-code-commit',
      text: 'committed V14 of "Media-mix model"',
      time: '2 hours ago',
      user: 'Tania'
    },
    {
      id: '2',
      icon: 'icon-connection',
      text: 'connected GA4 in "NationalMusicShow" project',
      time: '5 hours ago',
      user: 'Harel'
    },
    {
      id: '3',
      icon: 'icon-data-pipeline',
      text: 'updated ETL pipeline "Daily Metrics"',
      time: 'Yesterday',
      user: 'Katrin'
    },
    {
      id: '4',
      icon: 'icon-model',
      text: 'trained new ML model for "Customer Segments"',
      time: '2 days ago',
      user: 'Tania'
    },
    {
      id: '5',
      icon: 'icon-data-pipeline',
      text: 'configured new data source "Social Media"',
      time: '2 days ago',
      user: 'Harel'
    }
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  @HostListener('document:keydown.escape')
  onEscapeKey(): void {
    this.closePane();
  }

  togglePane(): void {
    this.showPane = !this.showPane;
  }

  closePane(): void {
    this.showPane = false;
  }
} 