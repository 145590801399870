import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordManagementService } from '../../../services/record-management.service';
import { RecordGroupListComponent } from '../record-group-list/record-group-list.component';
import { RecordGroupList } from '../../../models/record-management.interface';

@Component({
  selector: 'app-start-page',
  standalone: true,
  imports: [CommonModule, RecordGroupListComponent],
  template: `
    <div class="start-page-container">
      <div class="stage" *ngFor="let stage of stages">
        <div class="groups-container">
          <app-record-group-list
            *ngIf="stage.list"
            [list]="stage.list"
            [vertical]="true"
            (onPinToggle)="onPinToggle($event)"
            (onExpandToggle)="onExpandToggle($event)">
          </app-record-group-list>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .start-page-container {
      display: flex;
      flex-direction: column;
      padding: 24px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    }

    .stage {
      background: var(--surface-card);
      border-radius: 8px;
      padding: 24px;
    }

    .stage-title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 16px;
      color: var(--text-color);
    }

    .groups-container {
      display: flex;
      gap: 24px;
      overflow-x: auto;
      padding-bottom: 16px;
    }

    :host ::ng-deep {
      .record-group-list {
        flex: 0 0 300px;
      }
    }
  `]
})
export class StartPageComponent implements OnInit {
  stages: { title: string; list: RecordGroupList | null }[] = [];

  constructor(private recordManagementService: RecordManagementService) {}

  ngOnInit() {
    this.loadStages();
  }

  private loadStages() {
    this.stages = [
      { title: 'Data Collection Stage', list: this.recordManagementService.getDataCollectionStage() },
      { title: 'Processing Stage', list: this.recordManagementService.getProcessingStage() },
      { title: 'Modeling Stage', list: this.recordManagementService.getModelingStage() },
      { title: 'Production Stage', list: this.recordManagementService.getProductionStage() }
    ];
  }

  onPinToggle(event: any) {
    // Handle pin toggle
  }

  onExpandToggle(groupId: string) {
    this.recordManagementService.toggleGroupExpansion(groupId);
  }
}
