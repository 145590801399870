<div class="chart-container" #chartContainer>
  <div class="chart-query-container">
    <div class="editor-container">
      <ngx-codejar
        [(code)]="chartQuery"
        [options]="codeJarOptions"
        [highlightMethod]="highlight"
        [showLineNumbers]="false"
        (update)="onChartQueryUpdate($event)"
        class="code-editor"
      ></ngx-codejar>
      <button class="run-button" (click)="generateChart()" [disabled]="!chartQuery || chartQuery.trim() === ''">
        <i class="fas fa-play"></i>
      </button>
    </div>
  </div>
  <div class="zoom-controls">
    <button (click)="zoomIn()" title="Zoom In">
      <span class="material-icons">add</span>
    </button>
    <button (click)="zoomOut()" title="Zoom Out">
      <span class="material-icons">remove</span>
    </button>
    <button (click)="resetZoom()" title="Reset Zoom">
      <span class="material-icons">refresh</span>
    </button>
  </div>
  <div class="chart-wrapper">
    <div #mermaidContent class="mermaid-content">
      <div *ngIf="isLoading" class="loading-overlay">
        <div class="loading-content">
          <i class="fas fa-spinner fa-spin"></i>
          <span>Creating chart...</span>
        </div>
      </div>
    </div>
  </div>
</div> 