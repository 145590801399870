/**
 * @file src/app/services/tools/add-notification-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { NotificationsService } from '../notifications.service';
import { NotificationRequest } from '../../models/notification.model';
import { Injector } from '@angular/core';

/**
 * Tool: AddNotification
 * Creates a new notification and sends it to the API
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerAddNotificationTool(service: ToolsService): void {
  (service as any).addNotification = async function(nextVal: string, sessionId: string, riffMLNode: RiffMLNode | null, value: any): Promise<void> {
    if (!riffMLNode) return;
    
    try {
      // Get parameters from node attributes
      const agentId = riffMLNode.attributes['agentId'] || 'default_agent';
      const workspaceId = riffMLNode.attributes['workspaceId'] || this.workspaceState.userWorkspaceState$.value?.selectedWorkspace;
      const message = riffMLNode.attributes['message'] || value;
      const type = riffMLNode.attributes['type'] || 'info';
      const projectId = riffMLNode.attributes['projectId'] || this.workspaceState.userWorkspaceState$.value?.selectedProject;
      const targetSessionId = riffMLNode.attributes['sessionId'] || sessionId;
      const parametersString = riffMLNode.attributes['parameters'] || '{}';
      const riffml = riffMLNode.attributes['riffml'] || '';
      const nextPlaybook = riffMLNode.attributes['nextPlaybook'] || undefined;
      
      // Parse parameters
      let parameters = {};
      try {
        parameters = JSON.parse(parametersString);
      } catch (error) {
        console.error('[AddNotificationTool] Error parsing parameters:', error);
      }
      
      // Validate required fields
      if (!workspaceId || !message) {
        console.error('[AddNotificationTool] Missing required fields: workspaceId or message');
        return;
      }
      
      // Create notification request
      const request: NotificationRequest = {
        agentId,
        workspaceId,
        message,
        type,
        projectId,
        sessionId: targetSessionId,
        parameters,
        riffml,
        nextPlaybook
      };
      
      // Get the notifications service directly from the tools service
      const notificationsService: NotificationsService = (service as any).notificationsService;
      
      if (!notificationsService) {
        console.error('[AddNotificationTool] NotificationsService not available');
        return;
      }
      
      // Send notification
      const result = await notificationsService.addNotification(request);
      
      console.log('[AddNotificationTool] Notification sent:', result);
      
    } catch (error) {
      console.error('[AddNotificationTool] Error creating notification:', error);
    }
  };
} 