// src/app/components/sessions-grid/sessions-grid.component.ts

import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectManagerService } from '../../services/project-manager.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user.interface';
import {
  Session,
  SessionsGridState,
  SessionUpdate,
  GroupField,
  SortField,
  FilterField
} from '../../models/session.model';
import { Subscription } from 'rxjs';
import { ContextViewerComponent } from '../project-selection/context-viewer/context-viewer.component';
import { StateItem } from '../../models/workspace-state.model';
import { ChatService } from '../../services/chat.service';
import { WorkspaceState } from '../../models/workspace-state.model';  
import { WorkboardManagerService } from '../../services/workboard-manager.service';
import { LibraryMenuComponent } from '../library-menu/library-menu.component';
import { LibraryMenuService } from '../../services/library-menu.service';
import { MenuTriggerService } from '../../services/menu-trigger.service';

@Component({
  selector: 'app-sessions-grid',
  standalone: true,
  imports: [
    CommonModule,
    ContextViewerComponent,
    LibraryMenuComponent
  ],
  templateUrl: './sessions-grid.component.html',
  styleUrls: ['./sessions-grid.component.scss']
})
export class SessionsGridComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  sessions: Session[] = [];
  groupedSessions: Map<string, Session[]> = new Map();
  currentUser: User | null = null;
  selectedSessionId: string | undefined;
  showLibraryMenu = false;

  state: SessionsGridState = {
    filters: [{
      field: 'viewStatus',
      value: 'visible'
    }],
    sortBy: 'session',
    sortDirection: 'asc'
  };

  private projectJammer: User = {
    email: 'project.jammer@ask-y.ai',
    name: 'Project Jammer'
  };

    // 1) The allowed autoSelect modes in a simple array for easy cycling:
    private autoSelectModes: Array<'auto-replace' | 'auto-select' | 'off'> = [
      'auto-replace',
      'auto-select',
      'off'
    ];

  // Debug mode
  debugMode = false;

  // For context viewer popup
  @ViewChild('contextPopupRef') contextPopupRef!: ElementRef;
  showContextPopup = false;
  contextPopupX = 0;
  contextPopupY = 0;
  contextStateItems: StateItem[] = [];

  constructor(
    private projectManager: ProjectManagerService,
    private userService: UserService,
    private chatService: ChatService,
    public workspaceState: WorkspaceState,            
    private workboardManager: WorkboardManagerService,
    private libraryMenuService: LibraryMenuService,
    private menuTriggerService: MenuTriggerService
  ) {}

  ngOnInit(): void {
    // Check query param for debug mode
    if (typeof window !== 'undefined') {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has('dev') && queryParams.get('dev') !== '0') {
        this.debugMode = true;
      }
    }

    // Subscribe to sessions updates
    this.subscription.add(
      this.projectManager.getSessions().subscribe(sessions => {
        this.sessions = sessions;
        this.applyGrouping();
      })
    );

    // Subscribe to current user
    this.subscription.add(
      this.userService.user$.subscribe(user => {
        this.currentUser = user;
      })
    );

    // Subscribe to grid state
    this.subscription.add(
      this.projectManager.getGridState().subscribe(state => {
        // Preserve the sorting while updating other state properties
        this.state = {
          ...state,
          sortBy: this.state.sortBy,
          sortDirection: this.state.sortDirection,
          filters: [...this.state.filters]
        };
        this.selectedSessionId = state.selectedSessionId;
        this.applyGrouping();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleAutoSelectMode(): void {
    const currentMode = this.workspaceState.autoSelectMode$.value;
    // Find the index of the current mode in the array
    let idx = this.autoSelectModes.indexOf(currentMode);
    // Move to next mode
    idx = (idx + 1) % this.autoSelectModes.length;
    // Update the BehaviorSubject value
    this.workspaceState.autoSelectMode$.next(this.autoSelectModes[idx]);
  }

  public isProjectJammerSession(session: Session): boolean {
    return session.participants.some(
      p => p.email === this.projectJammer.email
    );
  }

  onCompletionToggle(session: Session): void {
    const newStatus = session.completionStatus === 'done' ? 'opened' : 'done';
    const update: SessionUpdate = {
      id: session.id,
      field: 'completionStatus',
      value: newStatus
    };
    this.projectManager.updateSession(update);
  }

  onHideSession(session: Session): void {
    const update: SessionUpdate = {
      id: session.id,
      field: 'viewStatus',
      value: 'hidden'
    };
    this.projectManager.updateSession(update);
  }

  onSort(field: SortField): void {
    const direction = this.state.sortBy === field && this.state.sortDirection === 'asc'
      ? 'desc'
      : 'asc';

    this.state = {
      ...this.state,
      sortBy: field,
      sortDirection: direction
    };

    this.projectManager.updateGridState(this.state);
    this.applyGrouping();
  }

  onGroup(field: GroupField): void {
    this.state = {
      ...this.state,
      groupBy: field
    };

    this.projectManager.updateGridState(this.state);
    this.applyGrouping();
  }

  onFilter(field: FilterField, value: string | boolean): void {
    const filters = this.state.filters.filter(f => f.field !== field);
    filters.push({ field, value });

    this.state = {
      ...this.state,
      filters
    };

    this.projectManager.updateGridState(this.state);
    this.applyGrouping();
  }

  /**
   * Updates the selected session in the state and auto-selects related workstreams (if any).
   */
  onSessionSelect(session: Session): void {
    this.projectManager.updateSelectedSession(session.id);

    // 3) New code to handle auto-select logic:
    if (session.relatedWorkstreamIds && session.relatedWorkstreamIds.length > 0) {
      const mode = this.workspaceState.autoSelectMode$.value;
      this.workboardManager.autoSelectWorkstreams(session.relatedWorkstreamIds, mode);
    }
  }

  private applyGrouping(): void {
    let filteredSessions = this.applyFilters(this.sessions);
    filteredSessions = this.applySorting(filteredSessions);

    if (!this.state.groupBy) {
      this.groupedSessions = new Map([['', filteredSessions]]);
      return;
    }

    this.groupedSessions = new Map();

    switch (this.state.groupBy) {
      case 'assignedToMe':
        this.groupSessionsByAssignment(filteredSessions);
        break;
      case 'session':
        this.groupSessionsByField(filteredSessions, 'session');
        break;
      case 'completionStatus':
        this.groupSessionsByField(filteredSessions, 'completionStatus');
        break;
      default:
        this.groupedSessions = new Map([['', filteredSessions]]);
    }
  }

  private groupSessionsByAssignment(sessions: Session[]): void {
    if (!this.currentUser) return;

    const assignedToMe = sessions.filter(session =>
      session.participants.some(p => p.email === this.currentUser?.email)
    );
    const assignedToOthers = sessions.filter(session =>
      !session.participants.some(p => p.email === this.currentUser?.email)
    );

    if (assignedToMe.length) {
      this.groupedSessions.set('Assigned to Me', assignedToMe);
    }
    if (assignedToOthers.length) {
      this.groupedSessions.set('Assigned to Others', assignedToOthers);
    }
  }

  private groupSessionsByField(sessions: Session[], field: keyof Session): void {
    sessions.forEach(session => {
      const key = String(session[field]);
      const group = this.groupedSessions.get(key) || [];
      group.push(session);
      this.groupedSessions.set(key, group);
    });
  }

  private applyFilters(sessions: Session[]): Session[] {
    return sessions.filter(session => {
      return this.state.filters.every(filter => {
        switch (filter.field) {
          case 'assignedToAgent':
            return session.participants.some(p => p.email === filter.value);
          case 'viewStatus':
          case 'completionStatus':
          case 'createdBy':
            return session[filter.field] === filter.value;
          default:
            return true;
        }
      });
    });
  }

  private applySorting(sessions: Session[]): Session[] {
    if (!this.state.sortBy || !this.state.sortDirection) {
      return sessions;
    }

    return [...sessions].sort((a, b) => {
      const aValue = a[this.state.sortBy!];
      const bValue = b[this.state.sortBy!];
      const direction = this.state.sortDirection === 'asc' ? 1 : -1;

      if (this.state.sortBy === 'session') {
        return (String(aValue) > String(bValue) ? 1 : -1) * direction;
      }

      return aValue > bValue ? direction : -direction;
    });
  }

  getInitials(fullName: string): string {
    return fullName
      .split(' ')
      .map(name => name.charAt(0))
      .join('')
      .toUpperCase()
      .slice(0, 2);
  }

  async onCreateSession() {
    const newSession: Partial<Session> = {
      name: 'New Session'
    };
    const newSessionId = await this.projectManager.createSession(newSession);
    this.onSessionSelect({ id: newSessionId } as Session);
    this.menuTriggerService.openMegaMenu();
  }

  /**
   * Opens the context viewer for a session's stateMap
   */
  async openContextMenu(event: MouseEvent, session: Session): Promise<void> {
    event.stopPropagation();
    if (!this.debugMode) {
      return;
    }

    this.showContextPopup = true;
    this.contextPopupX = event.clientX;
    this.contextPopupY = event.clientY;

    // Retrieve the chat session from shared state
    const chatSession = await this.chatService.loadOrCreateChatSession(session.id);
    if (chatSession && chatSession && chatSession.stateMap) {
      this.contextStateItems = Object.values(chatSession.stateMap);
    } else {
      this.contextStateItems = [];
    }
  }

  closeContextPopup(): void {
    this.showContextPopup = false;
  }

  onContextItemSelected(item: StateItem) {
    // Here you can handle what happens when a context item is clicked
    console.log('Session context item selected:', item);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.showContextPopup && this.contextPopupRef) {
      const popupEl = this.contextPopupRef.nativeElement as HTMLElement;
      if (popupEl && !popupEl.contains(event.target as Node)) {
        this.closeContextPopup();
      }
    }
  }

  onCreateSessionButtonMouseEnter(): void {
    this.showLibraryMenu = true;
  }

  onLibraryMenuMouseLeave(): void {
    this.showLibraryMenu = false;
  }

  toggleLibraryMenu(): void {
    this.menuTriggerService.openMegaMenu();
  }
}
