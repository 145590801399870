<!-- login.component.html -->
<div class="content-wrapper">
  <div class="login-container">
    <div class="login-box">
      <header>
        <img src="https://images.squarespace-cdn.com/content/v1/63b230364f239c3c9da88e73/ee131354-9ba1-4714-9497-d9c9087f7370/transparent.jpg?format=1500w"
             alt="asky-logo"
             class="h-8" />
      </header>
      <main class="main-content">
        <section class="login-section">
          <h5 class="login-title">Login to your first jam</h5>
          <section class="button-section">
            <button mat-raised-button 
                    color="primary" 
                    class="login-button" 
                    (click)="login()">
              Sign in with Microsoft
            </button>
          </section>
        </section>
      </main>
    </div>
    <div class="hero-image"></div>
  </div>
</div>