import { Component, Input, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { Record as RecordModel } from '../../../models/record-management.interface';

@Component({
  selector: 'app-record-preview',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatChipsModule],
  templateUrl: './record-preview.component.html',
  styleUrls: ['./record-preview.component.scss']
})
export class RecordPreviewComponent implements OnInit {
  @Input() record: RecordModel | null = null;
  @Input() triggerElement: HTMLElement | null = null;
  @Input() set mousePosition(value: { x: number; y: number }) {
    if (!this.isPositioned) {
      this._mousePosition = value;
      this.updatePosition();
    }
  }
  get mousePosition(): { x: number; y: number } {
    return this._mousePosition;
  }

  positionX = 0;
  positionY = 0;
  private readonly OFFSET_X = -10;
  private readonly OFFSET_Y = -10;
  private _mousePosition = { x: 0, y: 0 };
  isPositioned = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    // Initial position will be set by the first mousePosition input
  }

  private updatePosition() {
    const preview = this.elementRef.nativeElement.querySelector('.record-preview');
    if (!preview || this.isPositioned) return;

    const previewRect = preview.getBoundingClientRect();
    
    // Position relative to mouse cursor
    let x = this._mousePosition.x + this.OFFSET_X;
    let y = this._mousePosition.y + this.OFFSET_Y;

    // Adjust position if preview would go off screen
    if (x + previewRect.width > window.innerWidth) {
      x = window.innerWidth - previewRect.width;
    }
    if (y + previewRect.height > window.innerHeight) {
      y = window.innerHeight - previewRect.height;
    }

    // Keep preview on screen if mouse is near edge
    x = Math.max(0, x);
    y = Math.max(0, y);

    this.positionX = x;
    this.positionY = y;
    this.isPositioned = true;
  }
}
