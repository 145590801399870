import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { ProjectData } from '../../models/project.interface';
import { Workstream, WorkboardGroup, WorkstreamColumn } from '../../models/workboard.model';
import { ElementsLinkService } from './elements-link.service';
import { MemoryItem } from '../../models/memory-item.model';
import { TooltipService } from './tooltip.service';

export interface WorkstreamRenderConfig {
  containerWidth: number;
  workstreamRectHeight: number;
  workstreamMargin: number;
  fontSize: number;
  maxTextLength: number;
  workstreamMemories?: {[workstreamId: string]: MemoryItem[]};
  unitMemories?: {[unitId: string]: MemoryItem[]};
  memoryTypeColorMap?: {[type: string]: string};
  memorySize?: number;
  memoryMargin?: number;
}

@Injectable({
  providedIn: 'root'
})
export class WorkstreamsDrawService {
  private tooltip: any;
  private workstreamTooltip: any; // Dedicated tooltip for workstreams
  private memoryTooltip: any; // Dedicated tooltip for memories
  private unitTooltip: any; // Dedicated tooltip for units
  
  // Workstream color - specific color for workstreams (updated to more modern teal)
  private workstreamColor: string = '#26a69a'; 
  
  // Table colors from the component styles (updated to more modern palette)
  private inputTableColor: string = '#7986cb'; // Input table background color - indigo
  private outputTableColor: string = '#81c784'; // Output table background color - light green
  private exportTableColor: string = '#4caf50'; // Exported output table background color - green
  
  private defaultConfig: WorkstreamRenderConfig = {
    containerWidth: 140,
    workstreamRectHeight: 16, // Slightly increased height for better readability
    workstreamMargin: 8, // Significantly increased margin between workstreams
    fontSize: 8,
    maxTextLength: 25
  };
  
  // Table rendering constants
  private readonly tableRectHeight: number = 7; // Height of the table rectangles
  private readonly tableWidth: number = 0.95; // 95% of container width
  private readonly betweenUnitMargin: number = 5; // Margin between units and input sections
  private readonly betweenTableMargin: number = 2; // Margin between tables in the same unit
  
  constructor(
    private elementsLinkService: ElementsLinkService,
    private tooltipService: TooltipService
  ) {
    // Create dedicated tooltips
    this.workstreamTooltip = this.tooltipService.createTooltip('workstream');
    this.memoryTooltip = this.tooltipService.createTooltip('memory');
    this.unitTooltip = this.tooltipService.createTooltip('unit');
  }
  
  /**
   * Set tooltip reference
   */
  public setTooltip(tooltip: any): void {
    this.tooltip = tooltip;
  }
  
  /**
   * Render workstreams inside a project container
   * @param projectGroup D3 selection of the project group element
   * @param projectData Project data containing workboard state
   * @param containerX X position for the workstreams container
   * @param containerY Y position for the workstreams container
   * @param config Optional rendering configuration
   * @returns Height of the rendered workstreams container
   */
  public renderWorkstreams(
    projectGroup: any,
    projectData: ProjectData,
    containerX: number,
    containerY: number,
    config: Partial<WorkstreamRenderConfig> = {}
  ): number {
    // Merge provided config with defaults
    const renderConfig = { ...this.defaultConfig, ...config };
    
    // Extract all workstreams from the workboard groups and columns
    const workstreams = this.extractAllWorkstreams(projectData.workboardState);
    
    if (!workstreams.length) {
      return 0; // No workstreams to render
    }
    
    // Create a container group for all workstreams
    const workstreamsGroup = projectGroup.append('g')
      .attr('class', 'workstreams-container')
      .attr('transform', `translate(${containerX},${containerY})`);
    
    // Add container title
    workstreamsGroup.append('text')
      .attr('class', 'workstreams-title')
      .attr('x', renderConfig.containerWidth / 2)
      .attr('y', 0) // Position at top
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'hanging') // Align to top
      .attr('font-size', `${renderConfig.fontSize + 1}px`)
      .attr('font-weight', 'bold')
      .attr('fill', '#333')
      .text('Workstreams');
    
    // Define memory styling properties
    const memorySize = renderConfig.memorySize || 8;
    const memoryMargin = renderConfig.memoryMargin || 4;
    const memoryTypeColorMap = renderConfig.memoryTypeColorMap || {
      'data_issue': '#607d8b',      // Blue-gray for issues
      'model_evaluation': '#78909c', // Darker blue-gray for evaluations
      'default': '#90a4ae'          // Light blue-gray for other types
    };
    
    // Track the total height as we add elements
    const titleHeight = 15; // Estimated title height including margin
    let totalHeight = titleHeight; // Start with padding for the title
    
    // Bring title text to front
    workstreamsGroup.select('.workstreams-title').raise();
    
    // Render each workstream with its tables
    workstreams.forEach((workstream, index) => {
      // Current Y position for this workstream
      let currentY = totalHeight;
      
      // Workstream group
      const workstreamGroup = workstreamsGroup.append('g')
        .attr('class', 'workstream-item')
        .attr('transform', `translate(0,${currentY})`); // No additional left padding
      
      // Get memories associated with this workstream
      const workstreamMemories = renderConfig.workstreamMemories?.[workstream.id] || [];
      let memoryRowsHeight = 0;
      
      // Workstream rectangle
      const workstreamRect = workstreamGroup.append('rect')
        .attr('class', 'workstream-rect')
        .attr('width', renderConfig.containerWidth)
        .attr('height', renderConfig.workstreamRectHeight)
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('fill', this.getWorkstreamColor(workstream.state))
        .attr('stroke', this.workstreamColor)
        .attr('stroke-width', 0.5);
      
      // Workstream name text
      const workstreamText = workstreamGroup.append('text')
        .attr('class', 'workstream-name')
        .attr('x', 5)
        .attr('y', renderConfig.workstreamRectHeight / 2)
        .attr('dominant-baseline', 'middle')
        .attr('fill', this.getWorkstreamTextColor(workstream.state))
        .attr('font-size', `${renderConfig.fontSize}px`)
        .text(this.truncateText(workstream.name, renderConfig.maxTextLength));
      
      // Draw workstream memories if there are any
      if (workstreamMemories.length > 0) {
        // Create a small row for memory items to the right of the workstream name
        const availableWidth = renderConfig.containerWidth - 
          (workstreamText.node().getComputedTextLength() + 10);
        
        // Only show memories if we have enough space
        if (availableWidth > memorySize * 2) {
          const memoriesPerRow = Math.floor(availableWidth / (memorySize + memoryMargin));
          const memoriesToShow = Math.min(workstreamMemories.length, memoriesPerRow);
          
          // Create memories container
          const memoriesContainer = workstreamGroup.append('g')
            .attr('class', 'memories-container')
            .attr('transform', `translate(${renderConfig.containerWidth - availableWidth},${(renderConfig.workstreamRectHeight - memorySize)/2})`);
          
          // Draw each memory item
          for (let i = 0; i < memoriesToShow; i++) {
            const memory = workstreamMemories[i];
            const memoryX = i * (memorySize + memoryMargin);
            
            // Get memory type color
            const memoryColor = this.getMemoryTypeColor(memory.type);
            
            // Create hexagon points
            const hexRadius = memorySize / 2;
            const hexPoints = this.calculateHexagonPoints(
              memoryX + memorySize/2,
              memorySize/2,
              hexRadius
            );
            
            // Create gradient for memory
            const gradientId = `workstream-memory-gradient-${Math.random().toString(36).substring(2, 9)}`;
            
            // Create gradient definition
            const defs = projectGroup.append('defs');
            const gradient = defs.append('radialGradient')
              .attr('id', gradientId)
              .attr('cx', '50%')
              .attr('cy', '50%')
              .attr('r', '50%')
              .attr('fx', '50%')
              .attr('fy', '50%');
              
            gradient.append('stop')
              .attr('offset', '0%')
              .attr('stop-color', d3.color(memoryColor)?.brighter(0.5)?.toString() || memoryColor);
              
            gradient.append('stop')
              .attr('offset', '100%')
              .attr('stop-color', memoryColor);
            
            // Draw memory as hexagon with gradient
            const memoryHexagon = memoriesContainer.append('polygon')
              .attr('class', 'memory-hexagon')
              .attr('points', hexPoints)
              .attr('fill', `url(#${gradientId})`)
              .attr('stroke', d3.color(memoryColor)?.darker(0.5)?.toString() || '#666')
              .attr('stroke-width', 1.5);
            
            // Setup tooltip for memory
            this.setupMemoryTooltip(memoryHexagon, memory);
          }
          
          // If there are more memories than can fit, add a plus indicator
          if (workstreamMemories.length > memoriesToShow) {
            memoriesContainer.append('text')
              .attr('class', 'more-memories')
              .attr('x', memoriesToShow * (memorySize + memoryMargin) + 2)
              .attr('y', memorySize/2 + 3)
              .attr('font-size', '8px')
              .attr('fill', '#666')
              .text(`+${workstreamMemories.length - memoriesToShow}`);
          }
        }
      }
      
      // Register this workstream with the link service
      this.elementsLinkService.registerWorkstream(
        workstream.id,
        projectData.id,
        workstreamGroup,
        workstream,
        0, // X position relative to the workstream group
        0, // Y position relative to the workstream group
        renderConfig.containerWidth,
        renderConfig.workstreamRectHeight
      );
      
      // Setup tooltip for this workstream
      this.setupWorkstreamTooltip(workstreamRect, workstream);
      this.setupWorkstreamTooltip(workstreamText, workstream);
      
      // Update current Y position after the workstream header
      currentY += renderConfig.workstreamRectHeight + this.betweenUnitMargin;
      
      // Only render tables and units if workstream is in 'open' state
      if (workstream.state === 'open') {
        // Render input tables if any
        if (workstream.input_table_ids && workstream.input_table_ids.length > 0) {
          const inputTablesGroup = workstreamGroup.append('g')
            .attr('class', 'input-tables-group')
            .attr('transform', `translate(0,${renderConfig.workstreamRectHeight + this.betweenUnitMargin})`);
          
          // Render each input table
          workstream.input_table_ids.forEach((tableId, tableIndex) => {
            const tableY = tableIndex * (this.tableRectHeight + this.betweenTableMargin);
            const tableWidth = renderConfig.containerWidth * this.tableWidth;
            const tableX = (renderConfig.containerWidth - tableWidth) / 2; // Center the table rect
            
            // Input table rectangle
            const tableRect = inputTablesGroup.append('rect')
              .attr('class', 'input-table-rect')
              .attr('x', tableX)
              .attr('y', tableY)
              .attr('width', tableWidth)
              .attr('height', this.tableRectHeight)
              .attr('rx', 2)
              .attr('ry', 2)
              .attr('fill', this.inputTableColor);
            
            // Setup tooltip for this input table
            this.setupTableTooltip(tableRect, { name: tableId }, workstream);
          });
          
          // Update current Y position after input tables
          currentY += (workstream.input_table_ids.length * (this.tableRectHeight + this.betweenTableMargin)) - this.betweenTableMargin + this.betweenUnitMargin;
        }
        
        // Render units and their output tables if any
        if (workstream.units && workstream.units.length > 0) {
          workstream.units.forEach((unit, unitIndex) => {
            // Create unit group
            const unitGroup = workstreamGroup.append('g')
              .attr('class', 'unit-group')
              .attr('transform', `translate(0,${currentY - totalHeight})`);
            
            // Apply tooltip to unit group instead of a label
            this.setupUnitTooltip(unitGroup, unit);
            
            // Get memories for this unit
            const unitMemories = renderConfig.unitMemories?.[unit.id] || [];
            let unitMemoryHeight = 0;
            
            // Only render output tables if any exist
            if (unit.output_table_ids && unit.output_table_ids.length > 0) {
              // If we have memories for this unit, render them above the output tables
              if (unitMemories.length > 0) {
                // Calculate how many memory items can fit per row
                const memoriesPerRow = Math.floor((renderConfig.containerWidth * this.tableWidth) / (memorySize + memoryMargin));
                const memoryRows = Math.ceil(unitMemories.length / memoriesPerRow);
                unitMemoryHeight = (memoryRows * (memorySize + memoryMargin)) + this.betweenTableMargin;
                
                // Create memories container
                const unitMemoriesContainer = unitGroup.append('g')
                  .attr('class', 'unit-memories-container')
                  .attr('transform', `translate(${(renderConfig.containerWidth - (renderConfig.containerWidth * this.tableWidth))/2},0)`);
                
                // Draw a memory label
                unitMemoriesContainer.append('text')
                  .attr('class', 'unit-memories-label')
                  .attr('x', 0)
                  .attr('y', 8)
                  .attr('font-size', '7px')
                  .attr('fill', '#666')
                  .text('Unit Memories:');
                
                // Draw each memory item
                unitMemories.forEach((memory, memIndex) => {
                  const rowIndex = Math.floor(memIndex / memoriesPerRow);
                  const colIndex = memIndex % memoriesPerRow;
                  
                  const memoryX = colIndex * (memorySize + memoryMargin);
                  const memoryY = rowIndex * (memorySize + memoryMargin) + 12; // Add space for label
                  
                  // Get memory type color
                  const memoryColor = this.getMemoryTypeColor(memory.type);
                  
                  // Create hexagon points
                  const hexRadius = memorySize / 2;
                  const hexPoints = this.calculateHexagonPoints(
                    memoryX + memorySize/2,
                    memoryY + memorySize/2,
                    hexRadius
                  );
                  
                  // Create gradient for memory
                  const gradientId = `workstream-memory-gradient-${Math.random().toString(36).substring(2, 9)}`;
                  
                  // Create gradient definition
                  const defs = projectGroup.append('defs');
                  const gradient = defs.append('radialGradient')
                    .attr('id', gradientId)
                    .attr('cx', '50%')
                    .attr('cy', '50%')
                    .attr('r', '50%')
                    .attr('fx', '50%')
                    .attr('fy', '50%');
                    
                  gradient.append('stop')
                    .attr('offset', '0%')
                    .attr('stop-color', d3.color(memoryColor)?.brighter(0.5)?.toString() || memoryColor);
                    
                  gradient.append('stop')
                    .attr('offset', '100%')
                    .attr('stop-color', memoryColor);
                  
                  // Draw memory as hexagon with gradient
                  const memoryHexagon = unitMemoriesContainer.append('polygon')
                    .attr('class', 'memory-hexagon')
                    .attr('points', hexPoints)
                    .attr('fill', `url(#${gradientId})`)
                    .attr('stroke', d3.color(memoryColor)?.darker(0.5)?.toString() || '#666')
                    .attr('stroke-width', 1.5);
                  
                  // Setup tooltip for memory
                  this.setupMemoryTooltip(memoryHexagon, memory);
                });
              }
              
              // Render each output table
              unit.output_table_ids.forEach((tableConfig, tableIndex) => {
                const tableY = tableIndex * (this.tableRectHeight + this.betweenTableMargin) + unitMemoryHeight;
                const tableWidth = renderConfig.containerWidth * this.tableWidth;
                const tableX = (renderConfig.containerWidth - tableWidth) / 2; // Center the table rect
                
                // Determine the color based on export status
                const tableColor = tableConfig.export ? this.exportTableColor : this.outputTableColor;
                
                // Output table rectangle
                const tableRect = unitGroup.append('rect')
                  .attr('class', 'output-table-rect')
                  .attr('x', tableX)
                  .attr('y', tableY)
                  .attr('width', tableWidth)
                  .attr('height', this.tableRectHeight)
                  .attr('rx', 2)
                  .attr('ry', 2)
                  .attr('fill', tableColor);
                
                // Setup tooltip for this output table
                this.setupTableTooltip(tableRect, tableConfig, unit);
              });
              
              // Update current Y position after this unit
              const unitHeight = (unit.output_table_ids.length * (this.tableRectHeight + this.betweenTableMargin)) 
                - this.betweenTableMargin + this.betweenUnitMargin + unitMemoryHeight;
              currentY += unitHeight;
            }
          });
        }
      }
      
      // Update total height for the next workstream
      totalHeight = currentY + renderConfig.workstreamMargin;
    });
    
    return totalHeight; // Return the total height of the rendered container
  }
  
  /**
   * Extract all workstreams from all groups and columns in the workboard state
   */
  private extractAllWorkstreams(workboardState: any): Workstream[] {
    if (!workboardState || !workboardState.groups || !workboardState.groups.length) {
      return [];
    }
    
    const allWorkstreams: Workstream[] = [];
    
    // Loop through all groups
    workboardState.groups.forEach((group: WorkboardGroup) => {
      // Loop through all columns in this group
      if (group.workstreamCols && group.workstreamCols.length) {
        group.workstreamCols.forEach((column: WorkstreamColumn) => {
          // Add all workstreams from this column
          if (column.workstreams && column.workstreams.length) {
            // Add group name context to each workstream for the tooltip
            column.workstreams.forEach(workstream => {
              // Create a new object with the group name added
              allWorkstreams.push({
                ...workstream,
                _groupName: group.name // Add an extra property for reference
              } as any);
            });
          }
        });
      }
    });
    
    return allWorkstreams;
  }
  
  /**
   * Get color based on workstream state
   */
  private getWorkstreamColor(state: string): string {
    switch (state) {
      case 'open': return '#e1f5fe'; // Modern light blue
      case 'closed': return '#ffebee'; // Modern light red
      default: return '#f5f5f5'; // Light grey
    }
  }
  
  /**
   * Get text color based on workstream state
   */
  private getWorkstreamTextColor(state: string): string {
    switch (state) {
      case 'open': return '#0288d1'; // Modern darker blue
      case 'closed': return '#e57373'; // Modern lighter red
      default: return '#424242'; // Dark grey
    }
  }
  
  /**
   * Setup tooltip for workstream items
   */
  private setupWorkstreamTooltip(element: any, workstream: Workstream): void {
    // Ensure the workstream tooltip exists
    if (!this.workstreamTooltip) {
      this.workstreamTooltip = this.tooltipService.createTooltip('workstream');
    }
    
    element
      .style('cursor', 'pointer')
      .on('mouseover', (event: MouseEvent) => {
        event.stopPropagation(); // Prevent parent tooltips
        
        // Show tooltip using the tooltip service
        this.tooltipService.showTooltip(
          this.workstreamTooltip,
          event,
          this.generateWorkstreamTooltip(workstream)
        );
        
        // Highlight the workstream rectangle - but don't change stroke
        element
          .attr('stroke-width', 1);
      })
      .on('mouseout', () => {
        // Hide tooltip using the tooltip service
        this.tooltipService.hideTooltip(this.workstreamTooltip);
        
        // Reset workstream style
        element
          .attr('stroke-width', 0.5);
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.workstreamTooltip, event);
      })
      .on('click', (event: MouseEvent) => {
        // Hide tooltip when clicked
        this.tooltipService.hideTooltip(this.workstreamTooltip);
        
        // Copy workstream data to clipboard
        const workstreamName = workstream.name || 'Unnamed Workstream';
        
        // Create a formatted string with workstream information
        let workstreamInfo = `Workstream: ${workstreamName}\n`;
        
        if (workstream.description) {
          workstreamInfo += `Description: ${workstream.description}\n`;
        }
        
        if (workstream.state) {
          workstreamInfo += `Status: ${workstream.state}\n`;
        }
        
        // Add group name if available
        if ((workstream as any)._groupName) {
          workstreamInfo += `Group: ${(workstream as any)._groupName}\n`;
        }
        
        // Add unit information
        if (workstream.units && workstream.units.length) {
          workstreamInfo += `Units: ${workstream.units.length}\n`;
          
          let inputCount = 0;
          let outputCount = 0;
          
          workstream.units.forEach(unit => {
            if (unit.input_table_ids) {
              inputCount += unit.input_table_ids.length;
            }
            if (unit.output_table_ids) {
              outputCount += unit.output_table_ids.length;
            }
          });
          
          if (inputCount > 0) {
            workstreamInfo += `Input Tables: ${inputCount}\n`;
          }
          
          if (outputCount > 0) {
            workstreamInfo += `Output Tables: ${outputCount}\n`;
          }
          
          // Add detailed unit information
          workstream.units.forEach((unit, index) => {
            workstreamInfo += `\nUnit ${index + 1}: ${unit.name || 'Unnamed Unit'}\n`;
            
            if (unit.description) {
              workstreamInfo += `  Description: ${unit.description}\n`;
            }
            
            if (unit.input_table_ids && unit.input_table_ids.length) {
              workstreamInfo += `  Input Tables: ${unit.input_table_ids.join(', ')}\n`;
            }
            
            if (unit.output_table_ids && unit.output_table_ids.length) {
              const outputTables = unit.output_table_ids.map((table: any) => {
                if (typeof table === 'string') {
                  return table;
                } else if (table.name) {
                  return table.name + (table.export ? ' (Exported)' : '');
                } else {
                  return 'Unnamed Table';
                }
              });
              workstreamInfo += `  Output Tables: ${outputTables.join(', ')}\n`;
            }
          });
        }
        
        // Add ID if available
        if (workstream.id) {
          workstreamInfo += `\nID: ${workstream.id}`;
        }
        
        // Copy to clipboard
        navigator.clipboard.writeText(workstreamInfo).then(() => {
          // Show notification
          this.tooltipService.showNotification('Workstream info copied!', event);
        }).catch(err => {
          console.error('Failed to copy workstream info: ', err);
        });
        
        // Prevent event bubbling
        event.stopPropagation();
      });
  }
  
  /**
   * Generate workstream tooltip content
   */
  private generateWorkstreamTooltip(workstream: Workstream): string {
    let content = '';
    
    // Add header with workstream name
    const workstreamName = workstream.name || 'Unnamed Workstream';
    content += this.tooltipService.formatHeader(workstreamName, 'workstream');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available
    if (workstream.description) {
      mainContent += `<div class="tooltip-description">${workstream.description}</div>`;
    }
    
    // Add workstream details section
    let detailsContent = '';
    
    // Add workstream properties
    if (workstream.id) {
      detailsContent += this.tooltipService.formatProperty('ID', workstream.id.substring(0, 8) + '...');
    }
    
    if (workstream.state) {
      detailsContent += this.tooltipService.formatProperty('Status', workstream.state);
    }
    
    // Add group name if available (from the _groupName property we added)
    if ((workstream as any)._groupName) {
      detailsContent += this.tooltipService.formatProperty('Group', (workstream as any)._groupName);
    }
    
    // Add input/output table counts if available
    if (workstream.units && workstream.units.length) {
      let inputCount = 0;
      let outputCount = 0;
      
      workstream.units.forEach(unit => {
        if (unit.input_table_ids) {
          inputCount += unit.input_table_ids.length;
        }
        if (unit.output_table_ids) {
          outputCount += unit.output_table_ids.length;
        }
      });
      
      if (inputCount > 0) {
        detailsContent += this.tooltipService.formatProperty('Input Tables', inputCount.toString());
      }
      
      if (outputCount > 0) {
        detailsContent += this.tooltipService.formatProperty('Output Tables', outputCount.toString());
      }
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add the main content
    content += mainContent;
    
    return content;
  }
  
  /**
   * Truncate text that's too long
   */
  private truncateText(text: string, maxLength: number): string {
    return text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text || '';
  }
  
  /**
   * Format tooltip title to break long titles into multiple lines
   * @param title The title text to format
   * @returns Formatted title with line breaks for long titles
   */
  private formatTooltipTitle(title: string): string {
    if (!title || title.length <= 30) {
      return title || '';
    }
    
    let formattedTitle = '';
    let currentIndex = 0;
    
    while (currentIndex < title.length) {
      // Calculate the end index for this segment (approx 30 chars)
      let endIndex = Math.min(currentIndex + 30, title.length);
      
      // If we're not at the end of the string and not exactly at 30 chars,
      // look for the last non-letter character before the 30th character
      if (endIndex < title.length && endIndex === currentIndex + 30) {
        // Look backward from the 30th character to find a non-letter character
        let breakIndex = endIndex;
        while (breakIndex > currentIndex) {
          // Check if the character is a non-letter (space, hyphen, comma, etc.)
          const char = title.charAt(breakIndex - 1);
          if (!/[a-zA-Z0-9]/.test(char)) {
            break;
          }
          breakIndex--;
        }
        
        // If we found a suitable break point, use it
        if (breakIndex > currentIndex) {
          endIndex = breakIndex;
        }
      }
      
      // Add this segment to the formatted title
      if (currentIndex > 0) {
        formattedTitle += '<br/>';
      }
      formattedTitle += title.substring(currentIndex, endIndex);
      
      // Move to next segment
      currentIndex = endIndex;
    }
    
    return formattedTitle;
  }
  
  /**
   * Setup tooltip for table elements
   * @param element D3 selection of the table element
   * @param tableConfig Table configuration data
   * @param parentObj Parent workstream or unit containing additional information
   */
  private setupTableTooltip(element: any, tableConfig: any, parentObj: any): void {
    if (!this.tooltip) return;
    
    element
      .on('mouseover', (event: any) => {
        this.tooltip
          .html(this.generateTableTooltip(tableConfig, parentObj))
          .style('left', (event.pageX + 15) + 'px')
          .style('top', (event.pageY - 28) + 'px')
          .transition()
          .duration(200)
          .style('opacity', 0.9);
      })
      .on('mouseout', () => {
        this.tooltip
          .transition()
          .duration(500)
          .style('opacity', 0);
      })
      .on('mousemove', (event: any) => {
        this.tooltip
          .style('left', (event.pageX + 15) + 'px')
          .style('top', (event.pageY - 28) + 'px');
      });
  }
  
  /**
   * Generate table tooltip content
   * @param tableConfig Table configuration data
   * @param parentObj Parent workstream or unit containing additional information
   */
  private generateTableTooltip(tableConfig: any, parentObj: any): string {
    let html = `
      <div style="font-weight: bold; font-size: 14px; margin-bottom: 8px; color: ${this.workstreamColor};">${this.formatTooltipTitle(tableConfig.name)}</div>
    `;
    
    // Add export info if available
    if (tableConfig.export !== undefined) {
      html += `<div><strong>Export:</strong> ${tableConfig.export ? 'Yes' : 'No'}</div>`;
    }
    
    // Add parent information depending on what's available
    if (parentObj) {
      // Show name of parent object (unit or workstream)
      if (parentObj.name) {
        html += `<div><strong>${parentObj.units ? 'Workstream' : 'Unit'}:</strong> ${parentObj.name}</div>`;
      }
      
      // Show mingus if available
      if (parentObj.mingus) {
        const shortMingus = this.truncateText(parentObj.mingus, 50);
        html += `<div><strong>Mingus:</strong> ${shortMingus}</div>`;
      }
      
      // Show version if available
      if (parentObj.version) {
        html += `<div><strong>Version:</strong> ${parentObj.version}</div>`;
      }
      
      // Show type if available
      if (parentObj.type) {
        html += `<div><strong>Type:</strong> ${parentObj.type}</div>`;
      }
    }
    
    return html;
  }
  
  /**
   * Setup memory tooltip
   */
  private setupMemoryTooltip(element: any, memory: MemoryItem): void {
    // Ensure the memory tooltip exists
    if (!this.memoryTooltip) {
      this.memoryTooltip = this.tooltipService.createTooltip('memory');
    }
    
    element
      .style('cursor', 'pointer')
      .on('mouseover', (event: MouseEvent) => {
        event.stopPropagation(); // Prevent parent tooltips
        
        // Show tooltip using the tooltip service
        this.tooltipService.showTooltip(
          this.memoryTooltip,
          event,
          this.generateMemoryTooltip(memory)
        );
        
        // Highlight the memory hexagon
        element
          .attr('stroke', '#000')
          .attr('stroke-width', 2);
      })
      .on('mouseout', () => {
        // Hide tooltip using the tooltip service
        this.tooltipService.hideTooltip(this.memoryTooltip);
        
        // Reset memory style
        element
          .attr('stroke', d3.color(this.getMemoryTypeColor(memory.type))?.darker(0.5)?.toString() || '#666')
          .attr('stroke-width', 1.5);
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.memoryTooltip, event);
      });
  }
  
  /**
   * Generate memory tooltip content
   */
  private generateMemoryTooltip(memory: MemoryItem): string {
    // Get type label with proper capitalization
    const typeLabel = memory.type ? 
      memory.type.charAt(0).toUpperCase() + memory.type.slice(1).replace('_', ' ') : 
      'Unknown';
      
    // Format the tooltip content using the tooltip service formatting methods
    let content = '';
    
    // Add header with memory type
    content += this.tooltipService.formatHeader(`${typeLabel} Memory`, 'memory');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available (observation or insight)
    if (memory.observation) {
      mainContent += `<div class="tooltip-description">${memory.observation}</div>`;
    } else if (memory.insight) {
      mainContent += `<div class="tooltip-description">${memory.insight}</div>`;
    }
    
    // Add memory details section
    let detailsContent = '';
    
    // Add memory properties
    if (memory.memory_id) {
      detailsContent += this.tooltipService.formatProperty('ID', memory.memory_id.substring(0, 8) + '...');
    }
    
    if (memory.importance !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Importance', `${memory.importance}/10`);
    }
    
    if (memory.reliability !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Reliability', `${memory.reliability}/10`);
    }
    
    if (memory.created_date) {
      const date = new Date(memory.created_date);
      detailsContent += this.tooltipService.formatProperty('Created', date.toLocaleString());
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add context section if available
    if (memory.context) {
      let contextContent = '';
      
      if (typeof memory.context === 'string') {
        contextContent += `<div>${memory.context}</div>`;
      } else if (typeof memory.context === 'object') {
        // Handle context object - show key properties
        Object.entries(memory.context).forEach(([key, value]) => {
          if (value && typeof value !== 'object') {
            contextContent += this.tooltipService.formatProperty(
              key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' '),
              value.toString()
            );
          }
        });
      }
      
      if (contextContent) {
        mainContent += this.tooltipService.formatSection(contextContent, 'Context');
      }
    }
    
    // Add the main content
    content += mainContent;
    
    return content;
  }
  
  /**
   * Get color for memory type
   */
  private getMemoryTypeColor(type: string): string {
    const memoryTypeColorMap: Record<string, string> = {
      'data_issue': '#607d8b',      // Blue-gray for issues
      'model_evaluation': '#5c6bc0', // Indigo for evaluations
      'user_feedback': '#26a69a',    // Teal for user feedback
      'system_alert': '#ef5350',     // Light red for system alerts
      'performance_metric': '#66bb6a', // Green for performance metrics
      'default': '#90a4ae'          // Light blue-gray for other types
    };
    
    return memoryTypeColorMap[type] || memoryTypeColorMap['default'];
  }
  
  /**
   * Calculate points for a hexagon
   */
  private calculateHexagonPoints(centerX: number, centerY: number, radius: number): string {
    const points = [];
    const angleOffset = Math.PI / 6; // 30 degrees offset
    
    for (let i = 0; i < 6; i++) {
      const angle = (i * Math.PI / 3) + angleOffset;
      const x = centerX + radius * Math.sin(angle);
      const y = centerY + radius * Math.cos(angle);
      points.push(`${x},${y}`);
    }
    
    // Close the path by returning to the first point
    points.push(points[0]);
    
    return points.join(' ');
  }
  
  /**
   * Highlight a workstream by ID
   */
  public highlightWorkstream(workstreamId: string): void {
    // Find the workstream element through the link service
    const workstreamRef = this.elementsLinkService.getWorkstreamReference(workstreamId);
    if (!workstreamRef || !workstreamRef.element) return;
    
    // Highlight the workstream rectangle
    workstreamRef.element.select('.workstream-rect')
      .transition()
      .duration(200)
      .attr('stroke', '#ff9800') // Orange highlight
      .attr('stroke-width', 2);
      
    // Make the workstream text bold
    workstreamRef.element.select('.workstream-name')
      .transition()
      .duration(200)
      .attr('font-weight', 'bold');
  }
  
  /**
   * Highlight a unit by ID
   */
  public highlightUnit(unitId: string): void {
    // Since units are part of workstreams, we need to find the unit element
    // This would require additional tracking of unit elements
    // For now, we'll just highlight the parent workstream if available
    
    // Find all workstream references
    const workstreamRefs = this.elementsLinkService.getAllWorkstreamReferences();
    
    // Look through each workstream to find the unit
    let foundWorkstream = false;
    Object.values(workstreamRefs).forEach(workstreamRef => {
      if (workstreamRef && workstreamRef.data && workstreamRef.data.units) {
        // Check if this workstream contains the unit
        const hasUnit = workstreamRef.data.units.some((unit: any) => 
          unit.id === unitId || unit.id.toLowerCase() === unitId.toLowerCase()
        );
        
        if (hasUnit) {
          // Highlight the parent workstream
          this.highlightWorkstream(workstreamRef.id);
          foundWorkstream = true;
        }
      }
    });
    
    // If we didn't find the unit in any workstream, we can't highlight it
    if (!foundWorkstream) {
      console.warn(`Unit with ID ${unitId} not found in any workstream`);
    }
  }
  
  /**
   * Reset all workstream highlights
   */
  public resetHighlights(): void {
    // Get all workstream references from the link service
    const workstreamRefs = this.elementsLinkService.getAllWorkstreamReferences();
    
    // Reset each workstream's style
    Object.values(workstreamRefs).forEach(workstreamRef => {
      if (workstreamRef && workstreamRef.element) {
        // Reset rectangle style
        workstreamRef.element.select('.workstream-rect')
          .transition()
          .duration(200)
          .attr('stroke', this.workstreamColor)
          .attr('stroke-width', 0.5);
          
        // Reset text style
        workstreamRef.element.select('.workstream-name')
          .transition()
          .duration(200)
          .attr('font-weight', 'normal');
      }
    });
  }
  
  /**
   * Setup tooltip for a unit element
   */
  private setupUnitTooltip(element: any, unit: any): void {
    if (!this.unitTooltip) {
      this.unitTooltip = this.tooltipService.createTooltip('unit');
    }
    
    element
      .style('cursor', 'pointer')
      .on('mouseenter', (event: MouseEvent) => {
        // Format tooltip content
        const tooltipContent = this.generateUnitTooltip(unit);
        
        // Show tooltip
        this.tooltipService.showTooltip(this.unitTooltip, event, tooltipContent);
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.unitTooltip, event);
      })
      .on('mouseleave', () => {
        // Hide tooltip
        this.tooltipService.hideTooltip(this.unitTooltip);
      });
  }

  /**
   * Generate tooltip content for a unit
   */
  private generateUnitTooltip(unit: any): string {
    // Format header with unit name
    const unitName = unit.name || unit.id || 'Unnamed Unit';
    let html = this.tooltipService.formatHeader(unitName, 'unit');
    
    // Main content section
    let mainContent = '';
    
    // Add unit description if available
    if (unit.description) {
      mainContent += `<div class="tooltip-description">${unit.description}</div>`;
    }
    
    // Add unit details section
    let detailsContent = '';
    
    // Add unit ID
    if (unit.id) {
      detailsContent += this.tooltipService.formatProperty('ID', unit.id);
    }
    
    // Add unit type if available
    if (unit.type) {
      detailsContent += this.tooltipService.formatProperty('Type', unit.type);
    }
    
    // Add input tables count if available
    if (unit.input_table_ids && unit.input_table_ids.length) {
      detailsContent += this.tooltipService.formatProperty('Input Tables', unit.input_table_ids.length);
    }
    
    // Add output tables count if available
    if (unit.output_table_ids && unit.output_table_ids.length) {
      detailsContent += this.tooltipService.formatProperty('Output Tables', unit.output_table_ids.length);
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add main content
    html += mainContent;
    
    // Add footer with ID if available
    if (unit.id) {
      html += this.tooltipService.formatFooter(`ID: ${unit.id}`);
    }
    
    return html;
  }
}