// app.routes.ts
import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './AuthGuard';
import { SessionsHistoryComponent } from './components/session/sessions-history/sessions-history.component';
import { StartPageComponent } from './components/record-management/start-page/start-page.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard, AuthGuard],
    children: [
      {
        path: 'sessions-history',
        component: SessionsHistoryComponent
      },
      {
        path: 'start',
        component: StartPageComponent
      }
    ]
  },
  { path: '**', redirectTo: 'home' }
];
