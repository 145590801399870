/**
 * @file src/app/services/tools/tablelink-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

/**
 * Tool: runBigQueryPreview
 * Sets the CurrentSql$ value in WorkspaceState to "Select TOP 300 * from {tableId}"
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerRunBigQueryPreviewTool(service: ToolsService): void {
  (service as any).runBigQueryPreview = async function(nextVal: string, sessionId: string, riffMLNode: RiffMLNode | null, value:any): Promise<void> {
    if (!value || !value.TableRepresentingId) return;
    const tableId = value.TableRepresentingId;
    if (!tableId) return;

    const code = value.Code ?? '';

    let sql = `SELECT * FROM ${tableId} LIMIT 100`;
    if (code && code.length)
    {
        sql = code + '\n' + sql;
    }
    
    this.workspaceState.CurrentSql$.next(sql);
    this.workspaceState.CurrentTable$.next(null);
    
    // TODO: you need to get the current connection data from the state like this:
    var connection = await this.stateManagerService.getObject(sessionId, "SelectedConnection");
    if (!connection) {
        console.error("No connection found");
        return;
    }

    // Get parameters from node attributes
    const path = 'armtemplates/bigquery-preview';
    var body = {
        "id": connection.id,
        "organization": connection.organization,
        "projectId": connection.projectId,
        "region": connection.region,
        "query": sql
    };

    try {
        // Construct the API URL with the function key
        const url = `${environment.artifactsApiUrl}/${path}${environment.keyCode}`;
        
        // Call the API with the content as the body
        const httpClient = (this as any).httpClient as HttpClient;
        const returnValue = await firstValueFrom(httpClient.post(url, body));
        
        if (returnValue) {
            console.log('runBigQueryPreview response from BQ:', returnValue);
            this.workspaceState.CurrentTable$.next(returnValue);
        }
        
    } catch (error) {
        console.error('[runBigQueryPreview] Error calling API:', error);
    }

    // TODO: call big query preview 
  };
}