import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import * as d3 from 'd3';
import { ProjectData, ProjectItem } from '../../models/project.interface';
import { SessionsDrawService } from './sessions-draw.service';
import { WorkstreamsDrawService } from './workstreams-draw.service';
import { ElementsLinkService } from './elements-link.service';
import { MemoryItem } from '../../models/memory-item.model';
import { TooltipService } from './tooltip.service';

export interface ProjectRenderData {
  id: string;
  projectId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: 'project' | 'memory';
  element: any; // D3 selection reference
  data: ProjectData | ProjectItem | MemoryItem;
}

export interface ProjectsDrawConfig {
  width: number;
  height: number;
  projectMemories?: {[projectId: string]: MemoryItem[]};
  sessionMemories?: {[sessionId: string]: MemoryItem[]};
  workstreamMemories?: {[workstreamId: string]: MemoryItem[]};
  unitMemories?: {[unitId: string]: MemoryItem[]};
  memoryTypeColorMap?: {[type: string]: string};
  xOffset?: number;
  margin?: number;
  projectWidth?: number;
  projectHeight?: number;
  projectPadding?: number;
  projectHeaderHeight?: number;
  sessionsPanelWidth?: number;
  workstreamsPanelWidth?: number;
  memorySize?: number;
  memoryMargin?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectsDrawService {
  private svg: any;
  private tooltip: any;
  private projectTooltip: any; // Dedicated tooltip for projects
  private memoryTooltip: any; // Dedicated tooltip for memories
  private customTooltip: any; // Custom tooltip for projects
  private objectsMap: Record<string, ProjectRenderData> = {};
  private renderer: Renderer2;
  
  // Project color - single color for all projects (updated to a more modern indigo)
  private projectColor: string = '#3949ab';
  
  // Memory type colors (default)
  private memoryTypeColorMap: {[key: string]: string} = {
    'data_issue': '#607d8b',      // Blue-gray for issues (was red)
    'model_evaluation': '#78909c', // Darker blue-gray for evaluations (was green)
    'default': '#90a4ae'          // Light blue-gray for other types (was gray)
  };
  
  private defaultConfig: ProjectsDrawConfig = {
    width: 960,
    height: 600,
    margin: 20,
    projectWidth: 300,  // Wider rectangles
    projectHeight: 60,
    projectPadding: 15, // Vertical padding between projects
    projectHeaderHeight: 25, // Updated from 20px to 25px to match pipelines
    sessionsPanelWidth: 140,    // Width for sessions panel
    workstreamsPanelWidth: 140,  // Width for workstreams panel
    memorySize: 8,
    memoryMargin: 4
  };
  
  constructor(
    rendererFactory: RendererFactory2,
    private sessionsDrawService: SessionsDrawService,
    private workstreamsDrawService: WorkstreamsDrawService,
    private elementsLinkService: ElementsLinkService,
    private tooltipService: TooltipService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    
    // Create dedicated tooltips
    this.customTooltip = this.tooltipService.createTooltip('project');
    this.memoryTooltip = this.tooltipService.createTooltip('memory');
  }
  
  /**
   * Set the SVG reference from tables-draw service
   */
  public setSvg(svg: any): void {
    this.svg = svg;
    
    // Set references for the link service
    this.elementsLinkService.setReferences(svg, null);
  }
  
  /**
   * Clear the objects map
   */
  public clear(): void {
    this.objectsMap = {};
    
    // Clear element references in the link service
    this.elementsLinkService.clear();
  }
  
  /**
   * Draw projects based on provided data
   */
  public drawProjects(
    projectsList: ProjectData[], 
    xOffset: number, 
    config: Partial<ProjectsDrawConfig> = {}
  ): void {
    // Clear existing project objects
    this.clear();
    
    // Merge provided config with defaults
    const drawConfig = { ...this.defaultConfig, ...config, xOffset };
    
    // Use provided memory type color map or fallback to default
    if (drawConfig.memoryTypeColorMap) {
      this.memoryTypeColorMap = drawConfig.memoryTypeColorMap;
    }
    
    if (!projectsList.length) {
      return;
    }
    
    // Draw each project - stacked vertically one under the other
    let cumulativeHeight = 0;
    projectsList.forEach((project, i) => {
      // Get memories for this project
      const projectMemories = drawConfig.projectMemories?.[project.id] || [];
      
      // Draw the project and get its height
      const projectHeight = this.drawProject(
        project, 
        i, 
        drawConfig, 
        cumulativeHeight,
        projectMemories
      );
      
      // Update cumulative height for next project
      cumulativeHeight += projectHeight + drawConfig.projectPadding!;
    });
  }
  
  /**
   * Draw a single project
   */
  private drawProject(
    project: ProjectData, 
    index: number, 
    config: ProjectsDrawConfig,
    yPosition: number,
    projectMemories: MemoryItem[] = []
  ): number {
    // Position projects vertically one below the other
    const x = config.xOffset!;
    const y = yPosition;
    
    // Define different padding values for horizontal and vertical sides
    const horizontalPadding = 13; // 2px smaller than before (15px)
    const verticalPadding = 11;   // 4px smaller than before (15px)
    
    // Default project height before adding panels
    let dynamicProjectHeight = config.projectHeight!;
    
    // Draw project rectangle
    const projectGroup = this.svg.append('g')
      .attr('class', 'project')
      .attr('transform', `translate(${x},${y})`);
    
    // Generate unique ID for the project
    const projectId = project.id.toLowerCase();
    
    // Determine if we have sessions and/or workstreams to render
    const hasSessions = project.sessions && project.sessions.length > 0;
    const hasWorkstreams = project.workboardState && 
                          project.workboardState.groups && 
                          project.workboardState.groups.length > 0;
    
    // Calculate height needed for the sessions and workstreams panels
    let sessionsHeight = 0;
    let workstreamsHeight = 0;
    
    // Calculate equal margins and positions for content
    const effectiveWidth = config.projectWidth! - (2 * horizontalPadding); // Width minus left and right padding
    const centerPadding = 15; // Padding between sessions and workstreams panels
    
    // Calculate widths for sessions and workstreams if both exist
    const sessionsPanelWidth = hasWorkstreams ? 
      (effectiveWidth - centerPadding) / 2 : 
      effectiveWidth;
    
    const workstreamsPanelWidth = hasSessions ? 
      (effectiveWidth - centerPadding) / 2 : 
      effectiveWidth;
    
    // Calculate positions
    const sessionsPanelX = horizontalPadding;
    const workstreamsPanelX = hasSessions ? 
      sessionsPanelX + sessionsPanelWidth + centerPadding : 
      horizontalPadding;
    
    // Draw sessions panel (left column) with memories
    if (hasSessions) {
      sessionsHeight = this.sessionsDrawService.renderSessions(
        projectGroup,
        project,
        sessionsPanelX, // X position with consistent padding
        config.projectHeaderHeight! + verticalPadding, // Y position with consistent padding
        {
          containerWidth: sessionsPanelWidth,
          sessionMemories: config.sessionMemories,
          memoryTypeColorMap: this.memoryTypeColorMap,
          memorySize: config.memorySize,
          memoryMargin: config.memoryMargin
        }
      );
    }
    
    // Draw workstreams panel (right column) with memories
    if (hasWorkstreams) {
      workstreamsHeight = this.workstreamsDrawService.renderWorkstreams(
        projectGroup,
        project,
        workstreamsPanelX, // X position with padding
        config.projectHeaderHeight! + verticalPadding, // Y position with consistent padding
        {
          containerWidth: workstreamsPanelWidth,
          workstreamMemories: config.workstreamMemories,
          unitMemories: config.unitMemories, 
          memoryTypeColorMap: this.memoryTypeColorMap,
          memorySize: config.memorySize,
          memoryMargin: config.memoryMargin
        }
      );
    }
    
    // Calculate the dynamic height of the project based on panels and consistent padding
    if (hasSessions || hasWorkstreams) {
      // Use consistent padding for bottom as well
      dynamicProjectHeight = config.projectHeaderHeight! + (2 * verticalPadding) + Math.max(sessionsHeight, workstreamsHeight);
    }
    
    // Filter out memories that are already displayed in workstreams/units to avoid duplication
    let filteredProjectMemories = projectMemories;
    if (projectMemories.length > 0 && (hasSessions || hasWorkstreams)) {
      // Get all memory IDs that are already displayed in sessions, workstreams, and units
      const displayedMemoryIds = new Set<string>();
      
      // Get session memory IDs
      if (project.sessions) {
        project.sessions.forEach(session => {
          const sessionId = session.id.toLowerCase();
          const sessionMemories = config.sessionMemories?.[sessionId] || [];
          sessionMemories.forEach(memory => {
            // Use _id or fallback to object_id or a combination of other fields as unique identifier
            const memoryId = (memory as any)._id || (memory as any).id || 
                            `mem-${memory.project_id}-${memory.session_id}-${memory.type}`;
            displayedMemoryIds.add(memoryId);
          });
        });
      }
      
      // Get workstream and unit memory IDs
      if (project.workboardState && project.workboardState.groups) {
        project.workboardState.groups.forEach(group => {
          if (group.workstreamCols) {
            group.workstreamCols.forEach(column => {
              if (column.workstreams) {
                column.workstreams.forEach(workstream => {
                  // Add workstream memories
                  const workstreamId = workstream.id.toLowerCase();
                  const workstreamMemories = config.workstreamMemories?.[workstreamId] || [];
                  workstreamMemories.forEach(memory => {
                    const memoryId = (memory as any)._id || (memory as any).id || 
                                    `mem-${memory.project_id}-${memory.session_id}-${memory.type}`;
                    displayedMemoryIds.add(memoryId);
                  });
                  
                  // Add unit memories
                  if (workstream.units) {
                    workstream.units.forEach(unit => {
                      const unitId = unit.id.toLowerCase();
                      const unitMemories = config.unitMemories?.[unitId] || [];
                      unitMemories.forEach(memory => {
                        const memoryId = (memory as any)._id || (memory as any).id || 
                                        `mem-${memory.project_id}-${memory.session_id}-${memory.type}`;
                        displayedMemoryIds.add(memoryId);
                      });
                    });
                  }
                });
              }
            });
          }
        });
      }
      
      // Filter out memories that are already displayed
      filteredProjectMemories = projectMemories.filter(memory => {
        const memoryId = (memory as any)._id || (memory as any).id || 
                        `mem-${memory.project_id}-${memory.session_id}-${memory.type}`;
        return !displayedMemoryIds.has(memoryId);
      });
    }
    
    // Add space for project memories if any
    if (filteredProjectMemories.length > 0) {
      const memoryHeight = this.drawProjectMemories(
        projectGroup, 
        filteredProjectMemories, 
        projectId, 
        config,
        dynamicProjectHeight
      );
      dynamicProjectHeight += memoryHeight;
    }
    
    // Draw outer project rectangle with dynamic height
    const projectRect = projectGroup.insert('rect', ':first-child')
      .attr('class', 'project-rect')
      .attr('width', config.projectWidth!)
      .attr('height', dynamicProjectHeight)
      .attr('rx', 10) // Updated from 4 to 10 to match pipelines
      .attr('ry', 10) // Updated from 4 to 10 to match pipelines
      .attr('fill', '#f5f8ff')  // Updated to a lighter blue background
      .attr('stroke', this.projectColor)
      .attr('stroke-width', 1.5);
    
    // Add hover effect to the project rectangle
    projectGroup
      .on('mouseover', () => {
        // Highlight the border on hover
        projectRect
          .transition()
          .duration(200)
          .attr('stroke-width', 3); // Increase stroke width on hover
      })
      .on('mouseout', () => {
        // Reset the border on mouseout
        projectRect
          .transition()
          .duration(200)
          .attr('stroke-width', 1.5); // Reset to original stroke width
      });
    
    // Draw project header background - updated color to match pipelines
    const projectHeader = projectGroup.append('rect')
      .attr('class', 'project-header')
      .attr('width', config.projectWidth!)
      .attr('height', config.projectHeaderHeight!)
      .attr('rx', 10) // Updated from 4 to 10 to match pipelines
      .attr('ry', 10) // Updated from 4 to 10 to match pipelines
      .attr('fill', '#2c3e50'); // Updated from this.projectColor to match pipeline header color
      
    // Add project name text - updated positioning and styling to match pipelines
    const projectNameText = projectGroup.append('text')
      .attr('class', 'project-name')
      .attr('x', 15) // Fixed padding value instead of non-existent config.connectionPadding
      .attr('y', config.projectHeaderHeight! / 2)
      .attr('text-anchor', 'start') // Changed from 'middle' to 'start' for left alignment
      .attr('dominant-baseline', 'middle')
      .attr('fill', 'white')
      .attr('font-size', '12px') // Updated from 10px to 12px to match pipelines
      .attr('font-weight', 'bold')
      .text(this.truncateText(project.label, 40));
    
    // Setup tooltip for project header and text
    this.addProjectEventListeners(projectGroup, project);
    
    // Store project reference with updated height
    this.objectsMap[projectId] = {
      id: projectId,
      projectId: project.id,
      x,
      y,
      width: config.projectWidth!,
      height: dynamicProjectHeight,
      type: 'project',
      element: projectGroup,
      data: project
    };
    
    // Return the height of this project for proper vertical positioning of the next one
    return dynamicProjectHeight;
  }
  
  /**
   * Draw memory items for a project
   */
  private drawProjectMemories(
    projectGroup: any, 
    memories: MemoryItem[], 
    projectId: string, 
    config: ProjectsDrawConfig,
    startY: number
  ): number {
    if (!memories || memories.length === 0) {
      return 0;
    }
    
    // Create a section for memory items at the bottom of the project
    const memoryStartY = startY - 5; // Slightly overlap with the previous content
    
    // Add a separator line
    projectGroup.append('line')
      .attr('x1', 10)
      .attr('y1', memoryStartY)
      .attr('x2', config.projectWidth! - 10)
      .attr('y2', memoryStartY)
      .attr('stroke', '#ccc')
      .attr('stroke-dasharray', '2,2');
    
    // Add a "Project Memories" label
    projectGroup.append('text')
      .attr('class', 'memories-label')
      .attr('x', 15)
      .attr('y', memoryStartY + 12)
      .attr('font-size', '8px')
      .attr('font-weight', 'bold')
      .attr('fill', '#666')
      .text('Project Memories:');
    
    // Create memories container
    const memoriesContainer = projectGroup.append('g')
      .attr('class', 'memories-container')
      .attr('transform', `translate(0,${memoryStartY + 20})`);
    
    // Calculate how many memory items can fit per row
    const memorySize = config.memorySize!;
    const memoryMargin = config.memoryMargin!;
    const memoriesPerRow = Math.floor((config.projectWidth! - 20) / (memorySize + memoryMargin));
    
    // Draw each memory item
    memories.forEach((memory, index) => {
      const rowIndex = Math.floor(index / memoriesPerRow);
      const colIndex = index % memoriesPerRow;
      
      const memoryX = colIndex * (memorySize + memoryMargin) + 15;
      const memoryY = rowIndex * (memorySize + memoryMargin);
      
      // Get memory type color
      const memoryColor = this.getMemoryTypeColor(memory.type);
      
      // Generate unique ID for the memory
      const memoryId = `${projectId}-memory-${memory.memory_id || index}`;
      
      // Create hexagon points
      const hexRadius = memorySize / 2;
      const hexPoints = this.calculateHexagonPoints(
        memoryX + hexRadius,
        memoryY + hexRadius,
        hexRadius
      );
      
      // Create gradient for memory
      const gradientId = `memory-gradient-${Math.random().toString(36).substring(2, 9)}`;
      
      // Create gradient definition
      const defs = this.svg?.append('defs');
      const gradient = defs?.append('radialGradient')
        .attr('id', gradientId)
        .attr('cx', '50%')
        .attr('cy', '50%')
        .attr('r', '50%')
        .attr('fx', '50%')
        .attr('fy', '50%');
        
      gradient?.append('stop')
        .attr('offset', '0%')
        .attr('stop-color', d3.color(memoryColor)?.brighter(0.5)?.toString() || memoryColor);
        
      gradient?.append('stop')
        .attr('offset', '100%')
        .attr('stop-color', memoryColor);
      
      // Draw memory as hexagon with gradient
      const memoryHexagon = memoriesContainer.append('polygon')
        .attr('class', 'memory-hexagon')
        .attr('points', hexPoints)
        .attr('fill', `url(#${gradientId})`)
        .attr('stroke', d3.color(memoryColor)?.darker(0.5)?.toString() || '#666')
        .attr('stroke-width', 1.5);
      
      
      // Store memory reference with absolute coordinates
      this.objectsMap[memoryId] = {
        id: memoryId,
        projectId: projectId,
        x: memoryX + parseInt(projectGroup.attr('transform').split('(')[1].split(',')[0], 10),
        y: memoryY + parseInt(projectGroup.attr('transform').split('(')[1].split(',')[1], 10) + memoryStartY + 20,
        width: memorySize,
        height: memorySize,
        type: 'memory',
        element: memoryHexagon,
        data: memory
      };
      
      // Setup tooltip for memory
      this.setupMemoryTooltip(memoryHexagon, memory);
    });
    
    // Calculate total height for memory section: separator + label + memory rows
    const totalRows = Math.ceil(memories.length / memoriesPerRow);
    const memoryHeight = 20 + // Space for separator and label
      (totalRows * (memorySize + memoryMargin)) + // Space for memory rows
      5; // Extra padding at bottom
    
    return memoryHeight;
  }
  
  /**
   * Calculate points for a hexagon
   */
  private calculateHexagonPoints(centerX: number, centerY: number, radius: number): string {
    const points = [];
    const angleOffset = Math.PI / 6; // 30 degrees offset
    
    for (let i = 0; i < 6; i++) {
      const angle = (i * Math.PI / 3) + angleOffset;
      const x = centerX + radius * Math.sin(angle);
      const y = centerY + radius * Math.cos(angle);
      points.push(`${x},${y}`);
    }
    
    // Close the path by returning to the first point
    points.push(points[0]);
    
    return points.join(' ');
  }
  
  /**
   * Add event listeners to project
   */
  private addProjectEventListeners(projectGroup: any, project: any): void {
    // Ensure the project tooltip exists
    if (!this.projectTooltip) {
      this.projectTooltip = this.tooltipService.createTooltip('project');
    }
    
    projectGroup
      .style('cursor', 'pointer')
      .on('mouseenter', (event: MouseEvent) => {
        // Show tooltip
        this.tooltipService.showTooltip(
          this.projectTooltip,
          event,
          this.generateProjectTooltip(project)
        );
        
        // Highlight project
        projectGroup.select('rect.project-rect')
          .transition()
          .duration(200)
          .attr('stroke-width', 2);
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.projectTooltip, event);
      })
      .on('mouseleave', () => {
        // Hide tooltip
        this.tooltipService.hideTooltip(this.projectTooltip);
          
        // Reset project highlight
        projectGroup.select('rect.project-rect')
          .transition()
          .duration(200)
          .attr('stroke-width', 1);
      })
      .on('click', (event: MouseEvent) => {
        // Hide tooltip when clicked
        this.tooltipService.hideTooltip(this.projectTooltip);

        // Copy project data to clipboard
        const projectName = project.label || project.name || 'Unnamed Project';
        
        // Create a formatted string with project information
        let projectInfo = `Project: ${projectName}\n`;
        
        if (project.description) {
          projectInfo += `Description: ${project.description}\n`;
        }
        
        if (project.type) {
          projectInfo += `Type: ${project.type}\n`;
        }
        
        if (project.status) {
          projectInfo += `Status: ${project.status}\n`;
        }
        
        if (project.createdAt) {
          projectInfo += `Created: ${new Date(project.createdAt).toLocaleString()}\n`;
        }
        
        if (project.updatedAt || project.lastUpdated) {
          const updateDate = project.updatedAt || project.lastUpdated;
          projectInfo += `Updated: ${new Date(updateDate).toLocaleString()}\n`;
        }
        
        if (project.agentId) {
          projectInfo += `Agent ID: ${project.agentId}\n`;
        }
        
        if (project.id) {
          projectInfo += `ID: ${project.id}`;
        }
        
        // Copy to clipboard
        navigator.clipboard.writeText(projectInfo).then(() => {
          // Show notification
          this.tooltipService.showNotification('Project info copied!', event);
        }).catch(err => {
          console.error('Failed to copy project info: ', err);
        });
        
        // Prevent event bubbling
        event.stopPropagation();
      });
  }
  
  /**
   * Setup tooltip for memory items
   */
  private setupMemoryTooltip(element: any, memory: MemoryItem): void {
    element
      .style('cursor', 'pointer')
      .on('mouseover', (event: MouseEvent) => {
        event.stopPropagation(); // Prevent parent tooltips
        
        // Show tooltip using the tooltip service
        this.tooltipService.showTooltip(
          this.memoryTooltip,
          event,
          this.generateMemoryTooltip(memory)
        );
        
        // Highlight the memory hexagon
        element
          .attr('stroke', '#000')
          .attr('stroke-width', 2);
          
        // Highlight associated elements
        this.highlightAssociatedElements(memory);
      })
      .on('mouseout', () => {
        // Hide tooltip using the tooltip service
        this.tooltipService.hideTooltip(this.memoryTooltip);
        
        // Reset memory style
        element
          .attr('stroke', d3.color(this.getMemoryTypeColor(memory.type))?.darker(0.5)?.toString() || '#666')
          .attr('stroke-width', 1.5);
          
        // Reset highlights
        this.resetHighlights();
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.memoryTooltip, event);
      });
  }
  
  /**
   * Highlight elements associated with a memory
   */
  private highlightAssociatedElements(memory: MemoryItem): void {
    // Highlight associated sessions
    if (memory.session_id) {
      this.sessionsDrawService.highlightSession(memory.session_id);
    }
    
    // Highlight associated workstreams and units
    if (memory.project_id) {
      // Find the project
      const project = this.objectsMap[memory.project_id.toLowerCase()];
      if (project && project.type === 'project') {
        // Highlight the project
        this.highlightObject(project.id, { color: '#ff9800', duration: 0 });
        
        // Highlight workstreams and units if available
        if (memory.workstream_id) {
          this.workstreamsDrawService.highlightWorkstream(memory.workstream_id);
        }
        
        if (memory.unit_id) {
          this.workstreamsDrawService.highlightUnit(memory.unit_id);
        }
      }
    }
    
    // Highlight associated tables
    if (memory.table_id) {
      // Signal to the elements link service to highlight this table
      this.elementsLinkService.highlightTable(memory.table_id);
    }
  }
  
  /**
   * Reset all highlights
   */
  private resetHighlights(): void {
    // Reset session highlights
    this.sessionsDrawService.resetHighlights();
    
    // Reset workstream and unit highlights
    this.workstreamsDrawService.resetHighlights();
    
    // Reset table highlights
    this.elementsLinkService.resetHighlights();
    
    // Reset project highlights
    Object.values(this.objectsMap).forEach(obj => {
      if (obj.type === 'project') {
        obj.element.select('.project-rect')
          .transition()
          .duration(200)
          .attr('stroke', this.projectColor)
          .attr('stroke-width', 1.5);
      }
    });
  }
  
  /**
   * Get object by ID
   */
  public getObject(id: string): ProjectRenderData | null {
    return this.objectsMap[id] || null;
  }
  
  /**
   * Get all objects
   */
  public getAllObjects(): Record<string, ProjectRenderData> {
    return this.objectsMap;
  }
  
  /**
   * Highlight a project
   */
  public highlightObject(id: string, options: { color?: string; duration?: number } = {}): void {
    // No visual changes on click - removed the border color change
    // This method is kept for compatibility with existing code that might call it
  }
  
  /**
   * Reset all project highlights
   */
  public resetAllProjectHighlights(): void {
    // Find all project elements
    const allProjects = this.svg.selectAll('.project-group');
    
    // Reset all projects to normal
    allProjects
      .style('display', 'block')
      .transition()
      .duration(300)
      .style('opacity', 1)
      .style('filter', null);
  }

  /**
   * Dim all projects except those with the specified IDs
   * @param exceptIds IDs of projects to keep highlighted
   */
  public dimAllExcept(exceptIds: string[]): void {
    // Find all project elements
    const allProjects = this.svg.selectAll('.project-group');
    
    // Apply dimming to all projects not in the exceptIds list
    allProjects.each((d: any, i: number, nodes: any) => {
      const projectNode = d3.select(nodes[i]);
      const projectId = d.id;
      
      if (exceptIds.includes(projectId)) {
        // Highlight this project
        projectNode
          .style('display', 'block')
          .transition()
          .duration(300)
          .style('opacity', 1)
          .style('filter', 'drop-shadow(0 0 5px rgba(66, 133, 244, 0.5))');
      } else {
        // Hide this project completely
        projectNode
          .transition()
          .duration(300)
          .style('opacity', 0)
          .on('end', function() {
            d3.select(this).style('display', 'none');
          });
      }
    });
  }

  /**
   * Get the color for a memory type
   */
  private getMemoryTypeColor(type: string): string {
    return this.memoryTypeColorMap[type] || this.memoryTypeColorMap['default'];
  }
  
  /**
   * Generate memory tooltip content
   */
  private generateMemoryTooltip(memory: MemoryItem): string {
    // Get type label with proper capitalization
    const typeLabel = memory.type ? 
      memory.type.charAt(0).toUpperCase() + memory.type.slice(1).replace('_', ' ') : 
      'Unknown';
      
    // Format the tooltip content using the tooltip service formatting methods
    let content = '';
    
    // Add header with memory type
    content += this.tooltipService.formatHeader(`${typeLabel} Memory`, 'memory');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available (observation or insight)
    if (memory.observation) {
      mainContent += `<div class="tooltip-description">${memory.observation}</div>`;
    } else if (memory.insight) {
      mainContent += `<div class="tooltip-description">${memory.insight}</div>`;
    }
    
    // Add memory details section
    let detailsContent = '';
    
    // Add memory properties
    if (memory.memory_id) {
      detailsContent += this.tooltipService.formatProperty('ID', memory.memory_id.substring(0, 8) + '...');
    }
    
    if (memory.importance !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Importance', `${memory.importance}/10`);
    }
    
    if (memory.reliability !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Reliability', `${memory.reliability}/10`);
    }
    
    if (memory.created_date) {
      const date = new Date(memory.created_date);
      detailsContent += this.tooltipService.formatProperty('Created', date.toLocaleString());
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add context section if available
    if (memory.context && memory.context.description) {
      let contextContent = this.tooltipService.formatProperty('Description', memory.context.description);
      
      // Add business dimensions if any
      if (memory.context.business_dimensions && memory.context.business_dimensions.length) {
        memory.context.business_dimensions.forEach(dim => {
          contextContent += this.tooltipService.formatProperty(dim.dimension_type, dim.value);
        });
      }
      
      mainContent += this.tooltipService.formatSection(contextContent, 'Context');
    }
    
    // Add analysis section if available
    if (memory.analysis && memory.analysis.length) {
      let analysisContent = '';
      
      memory.analysis.forEach(analysis => {
        let value = analysis.value !== undefined ? analysis.value : '';
        if (analysis.unit) {
          value += ` ${analysis.unit}`;
        }
        
        analysisContent += this.tooltipService.formatProperty(analysis.metric, value);
      });
      
      mainContent += this.tooltipService.formatSection(analysisContent, 'Analysis');
    }
    
    // Add the main content
    content += mainContent;
    
    return content;
  }
  
  /**
   * Generate project tooltip content
   */
  private generateProjectTooltip(project: any): string {
    let html = '';
    
    // Add header with project name - use label if available, otherwise name
    const projectName = project.label || project.name || 'Unnamed Project';
    html += this.tooltipService.formatHeader(projectName, 'project');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available
    if (project.description) {
      mainContent += `<div class="tooltip-description">${project.description}</div>`;
    }
    
    // Add project details section
    let detailsContent = '';
    
    // Add project type if available
    if (project.type) {
      detailsContent += this.tooltipService.formatProperty('Type', project.type);
    }
    
    // Add project status if available
    if (project.status) {
      detailsContent += this.tooltipService.formatProperty('Status', project.status);
    }
    
    // Add created date if available
    if (project.createdAt) {
      detailsContent += this.tooltipService.formatProperty('Created', new Date(project.createdAt).toLocaleString());
    }
    
    // Add updated date if available
    if (project.updatedAt || project.lastUpdated) {
      const updateDate = project.updatedAt || project.lastUpdated;
      detailsContent += this.tooltipService.formatProperty('Updated', new Date(updateDate).toLocaleString());
    }
    
    // Add agent ID if available
    if (project.agentId) {
      detailsContent += this.tooltipService.formatProperty('Agent ID', project.agentId);
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add statistics section if we have any statistics
    let statsContent = '';
    
    // Add sessions info if available
    if (project.sessions && project.sessions.length) {
      statsContent += this.tooltipService.formatProperty('Sessions', project.sessions.length);
    }
    
    // Add table count if available
    if (project.tableCount !== undefined) {
      statsContent += this.tooltipService.formatProperty('Tables', project.tableCount);
    }
    
    // Add pipeline count if available
    if (project.pipelineCount !== undefined) {
      statsContent += this.tooltipService.formatProperty('Pipelines', project.pipelineCount);
    }
    
    // Add connection count if available
    if (project.connectionCount !== undefined) {
      statsContent += this.tooltipService.formatProperty('Connections', project.connectionCount);
    }
    
    // Add statistics section if we have any statistics
    if (statsContent) {
      mainContent += this.tooltipService.formatSection(statsContent, 'Statistics');
    }
    
    // Add main content
    html += mainContent;
    
    // Add footer with ID if available
    if (project.id) {
      html += this.tooltipService.formatFooter(`ID: ${project.id}`);
    }
    
    return html;
  }
  
  /**
   * Truncate text that's too long
   */
  private truncateText(text: string, maxLength: number): string {
    return text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text || '';
  }
}