import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { WorkspaceState } from '../../../models/workspace-state.model';
import { TableModel, ColSchema } from '../../../models/tables.model';
import * as XLSX from 'xlsx';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-datatable',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, OnDestroy {
  currentTable: TableModel | null = null;
  columnSchemas: ColSchema[] = [];
  tableData: any[][] = [];
  
  // Sorting properties
  sortColumn: string | null = null;
  sortDirection: 'asc' | 'desc' = 'asc';
  
  // Filtering properties
  filterValues: { [key: string]: string } = {};
  filteredData: any[][] = []; // Initialize filteredData as an empty array
  showFilters: boolean = false;
  
  // Histogram properties
  showHistogram: boolean = false;
  selectedHistogramColumn: string | null = null;
  histogramData: { label: string, count: number }[] = [];
  showHistogramDropdown: boolean = false;
  
  // Column menu properties
  activeColumnMenu: string | null = null;
  menuPosition = { x: 0, y: 0 };
  
  // Filter dialog properties
  showFilterDialog: boolean = false;
  filterDialogPosition = { x: 0, y: 0 };
  filterDialogColumn: ColSchema | null = null;
  filterOperator: string = 'contains';
  filterValue: string = '';
  activeFilterColumn: string | null = null;
  
  private destroy$ = new Subject<void>();

  constructor(private workspaceState: WorkspaceState) {}

  ngOnInit(): void {
    // Subscribe to the CurrentTable$ observable
    this.workspaceState.CurrentTable$
      .pipe(takeUntil(this.destroy$))
      .subscribe(tableModel => {
        if (tableModel) {
          this.currentTable = tableModel;
          this.columnSchemas = tableModel.colSchema;
          this.tableData = tableModel.data || [];
          
          // Reset sorting and filtering when table changes
          this.sortColumn = null;
          this.sortDirection = 'asc';
          this.filterValues = {};
          
          // Initialize filtered data
          this.filteredData = [...this.tableData];
          this.applyFilters();
        } else {
          this.currentTable = null;
          this.columnSchemas = [];
          this.tableData = [];
          this.filteredData = [];
        }
      });
  }
  
  // Helper method to track items in ngFor
  trackByColName(index: number, col: ColSchema): string {
    return col.name;
  }
  
  trackByRowIndex(index: number): number {
    return index;
  }
  
  // Check if there are any active filters
  hasActiveFilters(): boolean {
    return Object.values(this.filterValues).some(value => value && value.trim() !== '');
  }
  
  // Sorting functionality
  sortData(columnName: string, direction?: 'asc' | 'desc'): void {
    // If clicking the same column, toggle direction
    if (this.sortColumn === columnName && !direction) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = columnName;
      this.sortDirection = direction || 'asc';
    }
    
    // Close the column menu
    this.activeColumnMenu = null;
    
    // Apply sorting
    this.applyFilters();
  }
  
  // Toggle column sort
  toggleColumnSort(columnName: string): void {
    // If clicking the same column, toggle direction
    if (this.sortColumn === columnName) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = columnName;
      this.sortDirection = 'asc';
    }
    
    // Apply sorting
    this.applyFilters();
  }
  
  // Get sort indicator for column header
  getSortIndicator(columnName: string): string {
    if (this.sortColumn !== columnName) {
      return '';
    }
    return this.sortDirection === 'asc' ? '↑' : '↓';
  }
  
  // Filtering functionality
  applyFilters(): void {
    let filteredResults = [...this.tableData];
    
    // Apply filters
    Object.keys(this.filterValues).forEach(columnName => {
      const filterValue = this.filterValues[columnName];
      if (filterValue && filterValue.trim() !== '') {
        const columnIndex = this.columnSchemas.findIndex(col => col.name === columnName);
        if (columnIndex !== -1) {
          filteredResults = filteredResults.filter(row => {
            const cellValue = row[columnIndex];
            if (cellValue === null || cellValue === undefined) {
              return false;
            }
            
            const cellText = cellValue.toString().toLowerCase();
            
            // Check if we have an operator
            if (filterValue.includes(':')) {
              const [operator, value] = filterValue.split(':');
              const searchValue = value.toLowerCase();
              
              switch (operator) {
                case 'equals':
                  return cellText === searchValue;
                case 'contains':
                  return cellText.includes(searchValue);
                case 'startsWith':
                  return cellText.startsWith(searchValue);
                case 'endsWith':
                  return cellText.endsWith(searchValue);
                default:
                  return cellText.includes(searchValue);
              }
            } else {
              // Simple contains filter if no operator
              return cellText.includes(filterValue.toLowerCase());
            }
          });
        }
      }
    });
    
    // Apply sorting if a column is selected
    if (this.sortColumn) {
      const columnIndex = this.columnSchemas.findIndex(col => col.name === this.sortColumn);
      if (columnIndex !== -1) {
        filteredResults.sort((a, b) => {
          const valueA = a[columnIndex];
          const valueB = b[columnIndex];
          
          // Handle null/undefined values
          if (valueA === null || valueA === undefined) return this.sortDirection === 'asc' ? -1 : 1;
          if (valueB === null || valueB === undefined) return this.sortDirection === 'asc' ? 1 : -1;
          
          // Compare based on type
          if (typeof valueA === 'number' && typeof valueB === 'number') {
            return this.sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
          } else {
            const strA = valueA.toString().toLowerCase();
            const strB = valueB.toString().toLowerCase();
            return this.sortDirection === 'asc' 
              ? strA.localeCompare(strB) 
              : strB.localeCompare(strA);
          }
        });
      }
    }
    
    this.filteredData = filteredResults;
  }
  
  // Toggle filters visibility
  toggleFilters(): void {
    this.showFilters = !this.showFilters;
    
    // If hiding filters, clear all filter values
    if (!this.showFilters) {
      this.filterValues = {};
      this.applyFilters();
    }
  }
  
  // Toggle histogram dropdown
  toggleHistogramDropdown(): void {
    this.showHistogramDropdown = !this.showHistogramDropdown;
    if (!this.showHistogramDropdown) {
      this.showHistogram = false;
    }
  }
  
  // Generate histogram for selected column
  generateHistogram(columnName: string): void {
    this.selectedHistogramColumn = columnName;
    this.showHistogramDropdown = false;
    this.showHistogram = true;
    
    // Get the column index
    const columnIndex = this.columnSchemas.findIndex(col => col.name === columnName);
    if (columnIndex === -1) return;
    
    // Count occurrences of each value in the column
    const counts: { [key: string]: number } = {};
    this.filteredData.forEach(row => {
      const value = row[columnIndex]?.toString() || 'null';
      counts[value] = (counts[value] || 0) + 1;
    });
    
    // Convert to array for the chart
    this.histogramData = Object.keys(counts).map(key => ({
      label: key,
      count: counts[key]
    }));
    
    // Sort by count (descending)
    this.histogramData.sort((a, b) => b.count - a.count);
    
    // Limit to top 10 values
    if (this.histogramData.length > 10) {
      this.histogramData = this.histogramData.slice(0, 10);
    }
  }
  
  // Close histogram
  closeHistogram(): void {
    this.showHistogram = false;
    this.selectedHistogramColumn = null;
  }
  
  // Clear all filters
  clearFilters(): void {
    this.filterValues = {};
    this.applyFilters();
  }
  
  // Clear filter for a specific column
  clearColumnFilter(columnName: string): void {
    delete this.filterValues[columnName];
    this.applyFilters();
    
    if (this.activeFilterColumn === columnName) {
      this.activeFilterColumn = null;
    }
  }
  
  // Clear filter
  clearFilter(): void {
    if (this.filterDialogColumn) {
      // Remove filter for this column
      delete this.filterValues[this.filterDialogColumn.name];
      
      // Apply filters
      this.applyFilters();
    }
    
    // Close filter dialog
    this.showFilterDialog = false;
    this.filterDialogColumn = null;
    this.activeFilterColumn = null;
  }
  
  // Excel export functionality
  exportToExcel(): void {
    if (!this.currentTable) return;
    
    // Create worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      this.columnSchemas.map(col => col.name), // Header row
      ...this.filteredData // Data rows
    ]);
    
    // Create workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    
    // Generate Excel file
    XLSX.writeFile(workbook, `${this.currentTable.name || 'table_data'}.xlsx`);
  }
  
  // Toggle column menu
  toggleColumnMenu(columnName: string, event: MouseEvent): void {
    event.stopPropagation();
    
    // Toggle menu for this column
    if (this.activeColumnMenu === columnName) {
      this.activeColumnMenu = null;
    } else {
      this.activeColumnMenu = columnName;
      
      // Position the menu
      const headerElement = event.currentTarget as HTMLElement;
      const rect = headerElement.getBoundingClientRect();
      
      this.menuPosition = {
        x: 0, // Align with the left edge of the header
        y: rect.height // Position below the header
      };
    }
  }
  
  // Show filter input for a column
  showFilterInput(columnName: string): void {
    // Show the filter row
    this.showFilters = true;
    
    // Close the column menu
    this.activeColumnMenu = null;
    
    // Focus the filter input for this column
    setTimeout(() => {
      const filterInput = document.querySelector(`input[placeholder="Filter..."][ng-reflect-model="${this.filterValues[columnName] || ''}"]`) as HTMLInputElement;
      if (filterInput) {
        filterInput.focus();
      }
    }, 0);
  }
  
  // Check if filter is active for a column
  isFilterActive(columnName: string): boolean {
    return this.filterValues[columnName] !== undefined && this.filterValues[columnName] !== '';
  }
  
  // Toggle filter dialog
  toggleFilterDialog(column: ColSchema, event: MouseEvent): void {
    event.stopPropagation();
    
    // If dialog is already open for this column, close it
    if (this.showFilterDialog && this.filterDialogColumn && this.filterDialogColumn.name === column.name) {
      this.showFilterDialog = false;
      this.filterDialogColumn = null;
      this.activeFilterColumn = null;
      return;
    }
    
    // Otherwise, open the dialog
    this.filterDialogColumn = column;
    this.showFilterDialog = true;
    this.activeFilterColumn = column.name;
    
    // Show the filter row if it's not already visible
    if (!this.showFilters) {
      this.showFilters = true;
    }
    
    // Reset or set initial filter values
    if (this.filterValues[column.name]) {
      const filterParts = this.filterValues[column.name].split(':');
      if (filterParts.length === 2) {
        this.filterOperator = filterParts[0];
        this.filterValue = filterParts[1];
      } else {
        this.filterOperator = 'contains';
        this.filterValue = this.filterValues[column.name];
      }
    } else {
      this.filterOperator = 'contains';
      this.filterValue = '';
    }
    
    // Position the dialog - center it under the column
    const headerElement = (event.target as HTMLElement).closest('th');
    if (headerElement) {
      const rect = headerElement.getBoundingClientRect();
      this.filterDialogPosition = {
        x: rect.left,
        y: rect.bottom + window.scrollY
      };
    }
  }
  
  // Apply filter
  applyFilter(): void {
    if (this.filterDialogColumn && this.filterValue.trim()) {
      // Store filter with operator
      this.filterValues[this.filterDialogColumn.name] = `${this.filterOperator}:${this.filterValue}`;
    } else if (this.filterDialogColumn) {
      // Remove filter if value is empty
      delete this.filterValues[this.filterDialogColumn.name];
    }
    
    // Apply filters
    this.applyFilters();
    
    // Close filter dialog
    this.showFilterDialog = false;
    this.filterDialogColumn = null;
    this.activeFilterColumn = null;
  }
  
  // Cancel filter
  cancelFilter(): void {
    this.showFilterDialog = false;
    this.filterDialogColumn = null;
  }
  
  // Document click handler to close menus
  @HostListener('document:click')
  closeMenus(): void {
    this.activeColumnMenu = null;
    this.showFilterDialog = false;
    
    // Only reset active filter column if filter dialog is closed
    if (!this.showFilterDialog) {
      this.activeFilterColumn = null;
    }
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}