/***************************************************************************************************
 * src/app/services/project-manager.service.ts
 ***************************************************************************************************/

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspacesData } from '../models/workspace.interface';
import { Session, SessionsGridState, SessionUpdate } from '../models/session.model';
import { WorkboardState } from '../models/workboard.model';
import { UserWorkspaceState, UserWorkspaceStateUpdate } from '../models/user-workspace-state.interface';
import { WorkspaceState } from '../models/workspace-state.model';
import { WorkspaceManagerService } from './workspace-manager.service';
import { SessionsManagerService } from './sessions-manager.service';
import { WorkboardManagerService } from './workboard-manager.service';
import { ArtifactsService } from './artifacts.service';
import {
  ProjectManagerWorkstream,
  SuggestedSession,
  SuggestedNewSession,
  ProjectManagerWorkboardGroup,
  ProjectManagerWorkstreamColumn
} from '../models/project-manager-chat.model';

import { TablesManagerService } from './tables-manager.service';
import { TablesCollection, TableModel, ColSchema, Insight } from '../models/tables.model';
import { ChangeManagerService } from './change-manager.service';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ProjectItem } from '../models/project.interface';
import { ChatService } from './chat.service';
@Injectable({
  providedIn: 'root'
})
export class ProjectManagerService {
  constructor(
    private sharedState: WorkspaceState,
    private workspaceManager: WorkspaceManagerService,
    private sessionsManager: SessionsManagerService,
    private workboardManager: WorkboardManagerService,
    private artifactsService: ArtifactsService,
    private tablesManager: TablesManagerService,
    private changeManager: ChangeManagerService,
    private chatService: ChatService
  ) {}

  // -----------------------------
  // Workspace Management
  // -----------------------------
  async initWorkspaces(): Promise<void> {
    await this.workspaceManager.initWorkspaces();
  }

  getWorkspacesData(): Observable<WorkspacesData> {
    return this.workspaceManager.getWorkspacesData();
  }

  getUserWorkspaceState(): Observable<UserWorkspaceState | null> {
    return this.workspaceManager.getUserWorkspaceState();
  }

  async saveUserWorkspaceState(update: UserWorkspaceStateUpdate): Promise<void> {
    await this.workspaceManager.saveUserWorkspaceState(update);
  }

  async addWorkspace(name: string): Promise<void> {
    await this.workspaceManager.addWorkspace(name);
  }

  async deleteWorkspace(workspaceId: string): Promise<void> {
    await this.workspaceManager.deleteWorkspace(workspaceId);
  }

  async addProject(workspaceId: string, label: string): Promise<void> {
    await this.workspaceManager.addProject(workspaceId, label);
  }

  async deleteProject(workspaceId: string, projectId: string): Promise<void> {
    await this.workspaceManager.deleteProject(workspaceId, projectId);
  }

  async selectWorkspace(workspaceId: string): Promise<void> {
    await this.workspaceManager.selectWorkspace(workspaceId);
  }

  async selectProject(workspaceId: string, projectId: string): Promise<void> {
    await this.workspaceManager.selectProject(workspaceId, projectId);
  }


  getCurrentWorkspaceProjects(): Observable<ProjectItem[]> {
    return combineLatest([
      this.getWorkspacesData(),
      this.getUserWorkspaceState()
    ]).pipe(
      map(([workspacesData, state]) => {
        if (state && workspacesData) {
          const workspace = workspacesData.workspaces.find(w => w.id === state.selectedWorkspace);
          if (workspace) {
            return workspace.projects || [];
          }
        }
        return [];
      })
    );
  }
  

  // -----------------------------
  // Sessions Management
  // -----------------------------
  getSessions(): Observable<Session[]> {
    return this.sessionsManager.getSessions();
  }

  async updateSession(update: SessionUpdate): Promise<void> {
    await this.sessionsManager.updateSession(update);
  }

  getGridState(): Observable<SessionsGridState> {
    return this.sessionsManager.getGridState();
  }

  async updateGridState(gridState: SessionsGridState): Promise<void> {
    await this.sessionsManager.updateGridState(gridState);
  }

  async updateSelectedSession(sessionId: string): Promise<void> {
    await this.sessionsManager.updateSelectedSession(sessionId);
  }

  async createSession(session: Partial<Session>): Promise<string> {
   let sessionId = await this.sessionsManager.createSession(session);
   this.chatService.loadOrCreateChatSession(sessionId);
   return sessionId;
  }

  async deleteSession(id: string): Promise<void> {
    await this.sessionsManager.deleteSession(id);
  }

  // -----------------------------
  // Workboard Management
  // -----------------------------
  getWorkboardState(): Observable<WorkboardState> {
    return this.workboardManager.getWorkboardState();
  }

  async updateGroupState(groupId: string, state: 'open' | 'closed'): Promise<void> {
    await this.workboardManager.updateGroupState(groupId, state);
  }

  async updateWorkstreamState(workstreamId: string, state: 'open' | 'closed'): Promise<void> {
    await this.workboardManager.updateWorkstreamState(workstreamId, state);
  }

  async updateWorkstreamSelection(workstreamId: string, selectedState: 'yes' | 'no'): Promise<void> {
    await this.workboardManager.updateWorkstreamSelection(workstreamId, selectedState);
  }

// -----------------------------
  // Accept / Reject Logic
  // calling the new ChangeManagerService
  // -----------------------------
  async acceptWorkstream(params: {
    messageId: string;
    sessionId: string;
    workstream: ProjectManagerWorkstream;
    group: ProjectManagerWorkboardGroup;
    col: ProjectManagerWorkstreamColumn;
  }): Promise<void> {
    console.log(
      '[ProjectManagerService] Accepting workstream',
      params.workstream,
      'in column',
      params.col,
      'of group',
      params.group,
      'from message',
      params.messageId,
      'and session',
      params.sessionId
    );
    await this.changeManager.acceptWorkstream(params);
  }

  async rejectWorkstream(params: {
    messageId: string;
    sessionId: string;
    workstream: ProjectManagerWorkstream;
    group: ProjectManagerWorkboardGroup;
    col: ProjectManagerWorkstreamColumn;
  }): Promise<void> {
    console.log(
      '[ProjectManagerService] Rejecting workstream',
      params.workstream,
      'in column',
      params.col,
      'of group',
      params.group,
      'from message',
      params.messageId,
      'and session',
      params.sessionId
    );
    await this.changeManager.rejectWorkstream(params);
  }

  async openExistingSession(params: {
    messageId: string;
    sessionId: string;
    session: SuggestedSession;
  }): Promise<void> {
    console.log(
      '[ProjectManagerService] Opening existing session:',
      params.session,
      ' triggered by message',
      params.messageId,
      'in session',
      params.sessionId
    );
    await this.changeManager.openExistingSession(params);
  }

  async acceptNewSession(params: {
    messageId: string;
    sessionId: string;
    session: SuggestedNewSession;
  }): Promise<void> {
    console.log(
      '[ProjectManagerService] Accepting new session:',
      params.session,
      ' triggered by message',
      params.messageId,
      'in session',
      params.sessionId
    );
    await this.changeManager.acceptNewSession(params);
  }

  async rejectNewSession(params: {
    messageId: string;
    sessionId: string;
    session: SuggestedNewSession;
  }): Promise<void> {
    console.log(
      '[ProjectManagerService] Rejecting new session:',
      params.session,
      ' triggered by message',
      params.messageId,
      'in session',
      params.sessionId
    );
    await this.changeManager.rejectNewSession(params);
  }

  // ------------------------------------------------------------------
  // ---------------------- TABLES Management --------------------------
  // ------------------------------------------------------------------
  public async getTablesCollection(workspaceId: string): Promise<TablesCollection> {
    return this.tablesManager.getTablesCollection();
  }

  public async updateTablesCollection(
    workspaceId: string,
    updatedCollection: TablesCollection
  ): Promise<void> {
    return this.tablesManager.updateTablesCollection(updatedCollection);
  }

  public async getTableModel(workspaceId: string, tableId: string): Promise<TableModel | null> {
    return await this.tablesManager.getTableModel(tableId);
  }

  public async updateTableModel(
    workspaceId: string,
    table: TableModel
  ): Promise<TablesCollection | null> {
    return this.tablesManager.updateTableModel(table);
  }

  public async addOrUpdateColSchema(
    workspaceId: string,
    tableId: string,
    colSchema: ColSchema
  ): Promise<TablesCollection | null> {
    return this.tablesManager.addOrUpdateColSchema(tableId, colSchema);
  }

  public async addOrUpdateInsight(
    workspaceId: string,
    tableId: string,
    insight: Insight
  ): Promise<TablesCollection | null> {
    return this.tablesManager.addOrUpdateInsight(tableId, insight);
  }

  public async addOrUpdateInsightInColumn(
    workspaceId: string,
    tableId: string,
    colName: string,
    insight: Insight
  ): Promise<TablesCollection | null> {
    return this.tablesManager.addOrUpdateInsightInColumn(tableId, colName, insight);
  }

  public async getTablesCollectionAsString(workspaceId: string): Promise<string> {
    const tablesCollection = await this.tablesManager.getTablesCollection();
    return this.tablesManager.getTablesCollectionAsString(tablesCollection);
  }

  public getTableModelAsString(table: TableModel): string {
    return this.tablesManager.getTableModelAsString(table);
  }

  public getTableSchemaAsString(table: TableModel): string {
    return this.tablesManager.getTableSchemaAsString(table);
  }

  public getTableRowExamplesAsString(table: TableModel): string {
    return this.tablesManager.getTableRowExamplesAsString(table);
  }
}
