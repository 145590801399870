// src/app/services/tooltip.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import * as d3 from 'd3';

/**
 * Service to create and manage tooltips with consistent styling and behavior.
 * Each component can request its own dedicated tooltip instance while maintaining
 * consistent styling across the application.
 */
@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  private tooltipCounter = 0;
  private activeTooltip: any = null; // Track the currently active tooltip
  private renderer: Renderer2;
  
  // Color palette based on the application's theme
  private colorPalette: {[key: string]: {primary: string, secondary: string, icon: string}} = {
    connection: {
      primary: '#607d8b',
      secondary: '#4a636f',
      icon: '🔗'
    },
    project: {
      primary: '#607d8b',
      secondary: '#4a636f',
      icon: '📂'
    },
    table: {
      primary: '#607d8b',
      secondary: '#4a636f',
      icon: '📊'
    },
    pipeline: {
      primary: '#607d8b',
      secondary: '#4a636f',
      icon: '⚙️'
    },
    session: {
      primary: '#6d9889', // More green-tinted grey
      secondary: '#5a8276', // Darker green-grey for gradient
      icon: '📅'
    },
    memory: {
      primary: '#5c6bc0', // Indigo color from the memory palette
      secondary: '#4a53a3',
      icon: '🧠'
    },
    workstream: {
      primary: '#26a69a', // Teal color from the workstream palette
      secondary: '#1e8e82',
      icon: '📋'
    },
    unit: {
      primary: '#7986cb', // Indigo color similar to input table color
      secondary: '#5c6bc0',
      icon: '🔄'
    }
  };

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Create a new tooltip instance with the application's dark theme styling.
   * Each tooltip will have a unique class name to avoid conflicts.
   * 
   * @param tooltipType A string identifier for the tooltip type (e.g., 'project', 'table')
   * @returns The created tooltip as a D3 selection
   */
  public createTooltip(tooltipType: string): any {
    // Generate a unique class name for this tooltip instance
    const tooltipId = `${tooltipType}-tooltip-${++this.tooltipCounter}`;
    const tooltipClass = `${tooltipType}-tooltip`;
    
    // Get color scheme for this tooltip type
    const colorScheme = this.colorPalette[tooltipType] || this.colorPalette['connection'];
    
    // Remove any existing tooltip with the same ID (shouldn't happen, but just in case)
    d3.select(`body .${tooltipId}`).remove();
    
    // Create a new tooltip with enhanced styling
    const tooltip = d3.select('body')
      .append('div')
      .attr('class', `tooltip-container ${tooltipClass} ${tooltipId}`)
      .style('position', 'absolute')
      .style('background-color', '#1e1f21') // Dark background
      .style('background-image', `linear-gradient(145deg, #1e1f21, #252628)`) // Subtle gradient
      .style('color', 'rgb(162, 160, 162)') // Text color
      .style('padding', '0') // No padding (will be added to sections)
      .style('border', '1px solid rgba(255, 255, 255, 0.15)') // Brighter border
      .style('border-radius', '0px') // Square corners
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .style('z-index', 1000001) // Very high z-index to ensure visibility
      .style('max-width', '350px')
      .style('min-width', '250px')
      .style('box-shadow', '0 10px 25px rgba(0, 0, 0, 0.5), 0 6px 12px rgba(0, 0, 0, 0.4)') // Enhanced shadow
      .style('display', 'none')
      .style('font-family', 'Arial, sans-serif')
      .style('font-size', '13px')
      .style('line-height', '1.5') // Improved line height
      .style('transform', 'translateY(10px) scale(0.98)') // Start slightly below for animation
      .style('transition', 'opacity 0.2s ease-out, transform 0.2s ease-out') // Smooth transitions
      .style('overflow', 'hidden'); // Ensure content doesn't overflow
    
    // Add global CSS styles to the document head if they don't exist yet
    if (!document.getElementById('tooltip-global-styles')) {
      const styleElement = document.createElement('style');
      styleElement.id = 'tooltip-global-styles';
      styleElement.textContent = `
        .tooltip-container .tooltip-header {
          margin: 0;
          padding: 10px 15px;
          background-color: var(--primary-color);
          background-image: linear-gradient(145deg, var(--primary-color), var(--secondary-color));
          color: rgba(255, 255, 255, 0.95);
          font-weight: bold;
          font-size: 14px;
          position: relative;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          display: flex;
          align-items: center;
        }
        
        .tooltip-container .tooltip-header::after {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 20px;
          width: 10px;
          height: 10px;
          background-color: var(--primary-color);
          transform: rotate(45deg);
        }
        
        .tooltip-container .tooltip-icon {
          margin-right: 8px;
          font-size: 16px;
        }
        
        .tooltip-container .tooltip-content {
          padding: 12px 15px;
        }
        
        .tooltip-container .tooltip-title {
          font-size: 14px;
          font-weight: bold;
          color: var(--primary-color);
          margin-bottom: 8px;
          padding-bottom: 6px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
        
        .tooltip-container .tooltip-description {
          font-style: italic;
          margin-bottom: 10px;
          color: rgba(255, 255, 255, 0.8);
          padding: 8px 10px;
          background-color: rgba(255, 255, 255, 0.03);
          border-left: 2px solid var(--primary-color);
          position: relative;
        }
        
        .tooltip-container .tooltip-description::before {
          content: '"';
          position: absolute;
          left: 5px;
          top: 0;
          color: var(--primary-color);
          font-size: 20px;
          opacity: 0.5;
        }
        
        .tooltip-container .tooltip-description::after {
          content: '"';
          position: absolute;
          right: 5px;
          bottom: 0;
          color: var(--primary-color);
          font-size: 20px;
          opacity: 0.5;
        }
        
        .tooltip-container .tooltip-section {
          margin: 10px 0;
          padding-bottom: 8px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.08);
          position: relative;
        }
        
        .tooltip-container .tooltip-section::before {
          content: '';
          position: absolute;
          left: -15px;
          top: 0;
          bottom: 0;
          width: 3px;
          background-color: var(--primary-color);
          opacity: 0.2;
        }
        
        .tooltip-container .tooltip-section:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        
        .tooltip-container .tooltip-section-title {
          font-weight: 600;
          color: rgba(255, 255, 255, 0.9);
          margin-bottom: 8px;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }
        
        .tooltip-container .tooltip-property {
          display: flex;
          justify-content: space-between;
          margin: 4px 0;
          padding: 3px 8px;
          border-radius: 2px;
          transition: background-color 0.2s ease;
        }
        
        .tooltip-container .tooltip-property:hover {
          background-color: rgba(255, 255, 255, 0.05);
        }
        
        .tooltip-container .tooltip-property-label {
          color: rgba(162, 160, 162, 0.8);
          margin-right: 10px;
        }
        
        .tooltip-container .tooltip-property-value {
          color: rgba(255, 255, 255, 0.95);
          text-align: right;
          word-break: break-word;
          font-weight: 500;
        }
        
        .tooltip-container .tooltip-footer {
          margin: 0;
          padding: 8px 15px;
          background-color: rgba(0, 0, 0, 0.2);
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
          border-top: 1px solid rgba(255, 255, 255, 0.05);
          font-size: 11px;
          color: rgba(162, 160, 162, 0.7);
          font-family: monospace;
        }
      `;
      document.head.appendChild(styleElement);
    }
    
    // Add tooltip-specific CSS variables
    tooltip.style('--primary-color', colorScheme.primary)
          .style('--secondary-color', colorScheme.secondary);
    
    return tooltip;
  }
  
  /**
   * Show the tooltip with content at the specified position
   * 
   * @param tooltip The tooltip D3 selection
   * @param event The mouse event
   * @param content HTML content to display in the tooltip
   */
  public showTooltip(tooltip: any, event: MouseEvent, content: string): void {
    // Hide any currently active tooltip
    if (this.activeTooltip && this.activeTooltip !== tooltip) {
      this.hideTooltip(this.activeTooltip);
    }
    
    // Set this as the active tooltip
    this.activeTooltip = tooltip;
    
    // Wrap content in a div for proper padding
    const wrappedContent = `<div class="tooltip-content">${content}</div>`;
    
    // Make sure the tooltip is visible and positioned correctly
    tooltip
      .style('display', 'block')
      .style('left', (event.pageX + 15) + 'px')
      .style('top', (event.pageY - 80) + 'px')
      .html(wrappedContent)
      .style('transform', 'translateY(10px) scale(0.98)') // Reset transform for animation
      .style('opacity', 0); // Start with opacity 0
    
    // Force a reflow to ensure styles are applied
    tooltip.node().offsetHeight;
    
    // Apply the transition
    tooltip
      .transition()
      .duration(200)
      .style('opacity', 1)
      .style('transform', 'translateY(0) scale(1)'); // Animate to final position
  }
  
  /**
   * Hide the tooltip with a fade-out animation
   * 
   * @param tooltip The tooltip D3 selection
   */
  public hideTooltip(tooltip: any): void {
    // Only clear activeTooltip if this is the active tooltip
    if (this.activeTooltip === tooltip) {
      this.activeTooltip = null;
    }
    
    tooltip
      .transition()
      .duration(200)
      .style('opacity', 0)
      .style('transform', 'translateY(10px) scale(0.98)') // Animate out
      .on('end', () => tooltip.style('display', 'none'));
  }
  
  /**
   * Update the tooltip position to follow the mouse
   * 
   * @param tooltip The tooltip D3 selection
   * @param event The mouse event
   */
  public moveTooltip(tooltip: any, event: MouseEvent): void {
    tooltip
      .style('left', (event.pageX + 15) + 'px')
      .style('top', (event.pageY - 80) + 'px');
  }
  
  /**
   * Helper method to format a tooltip property with label and value
   * 
   * @param label The property label
   * @param value The property value
   * @returns Formatted HTML for the property
   */
  public formatProperty(label: string, value: any): string {
    if (value === undefined || value === null || value === '') {
      return '';
    }
    
    return `
      <div class="tooltip-property">
        <span class="tooltip-property-label">${label}:</span>
        <span class="tooltip-property-value">${value}</span>
      </div>
    `;
  }
  
  /**
   * Create a tooltip header section with icon
   * 
   * @param title The title to display in the header
   * @param tooltipType The type of tooltip (project, table, etc.)
   * @returns Formatted HTML for the header
   */
  public formatHeader(title: string, tooltipType?: string): string {
    const icon = tooltipType && this.colorPalette[tooltipType] ? 
      this.colorPalette[tooltipType].icon : '';
    
    return `<div class="tooltip-header">
      ${icon ? `<span class="tooltip-icon">${icon}</span>` : ''}
      <span>${title}</span>
    </div>`;
  }
  
  /**
   * Create a tooltip section with optional title
   * 
   * @param content The HTML content for the section
   * @param title Optional title for the section
   * @returns Formatted HTML for the section
   */
  public formatSection(content: string, title?: string): string {
    return `<div class="tooltip-section">
      ${title ? `<div class="tooltip-section-title">${title}</div>` : ''}
      ${content}
    </div>`;
  }
  
  /**
   * Create a tooltip footer section
   * 
   * @param content The HTML content for the footer
   * @returns Formatted HTML for the footer
   */
  public formatFooter(content: string): string {
    return `<div class="tooltip-footer">${content}</div>`;
  }

  // Add a method to show a temporary notification tooltip
  public showNotification(message: string, event: MouseEvent, duration: number = 1500): void {
    console.log('Showing notification:', message); // Debug log
    
    // Create a notification element
    const notification = document.createElement('div');
    
    // Apply styles that match the dark theme of the application
    notification.style.position = 'fixed';
    notification.style.top = '10px';
    notification.style.left = '50%';
    notification.style.transform = 'translateX(-50%)';
    notification.style.backgroundColor = '#1e1f21'; // Dark background matching the app theme
    notification.style.color = 'rgb(162, 160, 162)'; // Text color matching the app theme
    notification.style.border = '1px solid rgba(255, 255, 255, 0.1)'; // Border color matching the app theme
    notification.style.borderLeft = '4px solid #66bb6a'; // Green accent for success
    notification.style.padding = '10px 16px';
    notification.style.fontSize = '14px';
    notification.style.fontFamily = 'Arial, sans-serif';
    notification.style.zIndex = '2147483647'; // Maximum z-index
    notification.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.5)';
    notification.style.minWidth = '200px';
    notification.style.textAlign = 'center';
    notification.style.borderRadius = '0px'; // Square corners for integrated look
    
    // Add content
    notification.textContent = message;
    
    // Add to body
    document.body.appendChild(notification);
    
    // Set timeout to remove notification
    setTimeout(() => {
      if (notification.parentNode) {
        document.body.removeChild(notification);
      }
    }, duration);
  }
  
  // Add a method to copy content to clipboard
  public copyToClipboard(text: string): Promise<boolean> {
    console.log('Copying to clipboard:', text); // Debug log
    
    // Check if the Clipboard API is available
    if (!navigator.clipboard) {
      console.error('Clipboard API not available');
      
      // Fallback method using textarea
      try {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        document.body.appendChild(textarea);
        textarea.select();
        const success = document.execCommand('copy');
        document.body.removeChild(textarea);
        console.log('Fallback copy result:', success);
        return Promise.resolve(success);
      } catch (err) {
        console.error('Fallback copy failed:', err);
        return Promise.resolve(false);
      }
    }
    
    return navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Clipboard API copy succeeded');
        return true;
      })
      .catch(err => {
        console.error('Clipboard API copy failed:', err);
        return false;
      });
  }
}
