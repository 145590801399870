import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

interface SessionRecord {
  icon: string;
  name: string;
  recommendedFlows?: RecommendedFlow[];
}

interface RecommendedFlow {
  name: string;
}

interface Session {
  title: string;
  date: string;
  suggestionType: string;
  records: SessionRecord[];
  indicators: string[];
  recommendedFlows?: RecommendedFlow[];
  isPinned?: boolean;
}

@Component({
  selector: 'app-sessions-history',
  templateUrl: './sessions-history.component.html',
  styleUrls: ['./sessions-history.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ]
})
export class SessionsHistoryComponent implements OnInit {
  pinnedSessions: Session[] = [
    {
      title: 'Revenue Prediction Model',
      date: '24/11/24',
      suggestionType: '',
      isPinned: true,
      indicators: ['sync', 'data_object'],
      records: [
        { icon: 'storage', name: 'storage' },
        { icon: 'settings', name: 'settings' },
        { icon: 'trending_up', name: 'trending_up' }
      ],
      recommendedFlows: [
        { name: 'Analyze customer segments for MVP features' },
        { name: 'Create event monitoring dashboard' }
      ]
    },
    {
      title: 'Customer Segmentation Analysis',
      date: '23/11/24',
      suggestionType: '',
      isPinned: true,
      indicators: ['api', 'event_note', 'storage'],
      records: [
        { icon: 'storage', name: 'storage' },
        { icon: 'cleaning_services', name: 'cleaning_services' },
        { icon: 'pie_chart', name: 'pie_chart' }
      ],
      recommendedFlows: [
        { name: 'Set up real-time analytics pipeline' }
      ]
    }
  ];

  recentSessions: Session[] = [
    {
      title: 'IoT Sensor Data Pipeline',
      date: '24/11/24',
      suggestionType: '',
      indicators: ['sync', 'data_object'],
      records: [
        { icon: 'storage', name: 'storage' },
        { icon: 'stream', name: 'stream' }
      ],
      recommendedFlows: [
        { name: 'Analyze customer segments for MVP features' },
        { name: 'Create event monitoring dashboard' }
      ]
    },
    {
      title: 'Geographic Sales Analysis',
      date: '23/11/24',
      suggestionType: '',
      indicators: ['api', 'event_note', 'storage'],
      records: [
        { icon: 'storage', name: 'storage' },
        { icon: 'map', name: 'map' },
        { icon: 'table_chart', name: 'table_chart' }
      ],
      recommendedFlows: [
        { name: 'Set up real-time analytics pipeline' }
      ]
    }
  ];

  suggestedSessions: Session[] = [
    {
      title: 'Building API Integration',
      date: '2 hours ago',
      suggestionType: 'Most used',
      indicators: ['sync', 'data_object'],
      records: [
        { 
          icon: 'sync', 
          name: 'Customer Events Stream Connection',
          recommendedFlows: [
            { name: 'Set up event filtering rules' },
            { name: 'Configure stream monitoring' }
          ]
        },
        { 
          icon: 'data_object', 
          name: 'Data Processing Pipeline',
          recommendedFlows: [
            { name: 'Add data transformation steps' },
            { name: 'Create data validation rules' }
          ]
        }
      ],
      recommendedFlows: [
        { name: 'Analyze customer segments for MVP features' },
        { name: 'Create event monitoring dashboard' }
      ]
    },
    {
      title: 'Event Processing Setup',
      date: '3 hours ago',
      suggestionType: 'Popular next stage',
      indicators: ['api', 'event_note', 'storage'],
      records: [
        { 
          icon: 'api', 
          name: 'API Integration',
          recommendedFlows: [
            { name: 'Add rate limiting configuration' },
            { name: 'Set up API monitoring' }
          ]
        },
        { 
          icon: 'event_note', 
          name: 'Event Logs',
          recommendedFlows: [
            { name: 'Create log aggregation pipeline' },
            { name: 'Set up log rotation policy' }
          ]
        },
        { 
          icon: 'storage', 
          name: 'Database Records',
          recommendedFlows: [
            { name: 'Optimize database indices' },
            { name: 'Configure backup strategy' }
          ]
        }
      ],
      recommendedFlows: [
        { name: 'Set up real-time analytics pipeline' }
      ]
    }
  ];

  hoveredRecord: SessionRecord | null = null;

  onRecordHover(record: SessionRecord) {
    this.hoveredRecord = record;
  }

  onRecordLeave() {
    this.hoveredRecord = null;
  }

  constructor() { }

  ngOnInit(): void { }

  togglePin(): void {
    // Implement pin/unpin logic
  }
}
