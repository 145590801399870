import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, firstValueFrom, interval } from 'rxjs';
import { environment } from '../../environments/environment';
import { NotificationModel, NotificationParameters, NotificationRequest, NotificationsState } from '../models/notification.model';
import { SnowflakeService } from './snowflake.service';
import { StateManagerService } from './state-manager.service';
import { WorkspaceManagerService } from './workspace-manager.service';
import { ProcessTurnInterface } from '../models/process-turn.interface';
import { WorkspaceState } from '../models/workspace-state.model';

// Define interfaces for the API response
interface ColumnSchema {
  name: string;
  type: string;
  description: string;
  insights: any[];
  tags: any[];
  resolveNames: any[];
}

interface TableData {
  id: string;
  name: string;
  path: string;
  description: string;
  colSchema: ColumnSchema[];
  sampleRows: string[];
  insights: any[];
  tags: any[];
  resolveNames: any[];
  data: any[][];
}

interface QueryResponse {
  Success: boolean;
  Data?: TableData | undefined;
  ErrorMessage?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private readonly notificationsApiUrl = `${environment.artifactsApiUrl}/notifications/add`;
  private readonly POLL_INTERVAL = 30000; // 30 seconds
  
  private pollingSubscription: Subscription | null = null;
  private processTurnHandler: ProcessTurnInterface | null = null;
  private processedNotificationIds: Set<string> = new Set(); // Cache of all notification IDs we've ever received
  
  private _notifications$ = new BehaviorSubject<NotificationsState>({
    notifications: [],
    unreadCount: 0,
    lastReadTimestamp: new Date().toISOString(), // Initialize to current time when service starts
    showNotificationsPane: false
  });
  
  public notifications$ = this._notifications$.asObservable();

  constructor(
    private http: HttpClient,
    private snowflakeService: SnowflakeService,
    private stateManagerService: StateManagerService,
    private workspaceManagerService: WorkspaceManagerService,
    private sharedState: WorkspaceState
  ) {
    this.loadSavedState();
    this.startPolling();
  }

  /**
   * Sets the ProcessTurnInterface implementation that will be used
   * @param handler An implementation of the ProcessTurnInterface
   */
  public setProcessTurnHandler(handler: ProcessTurnInterface): void {
    this.processTurnHandler = handler;
  }

  /**
   * Gets the current ProcessTurnInterface implementation
   * @returns The current ProcessTurnInterface implementation or null
   */
  public getProcessTurnHandler(): ProcessTurnInterface | null {
    return this.processTurnHandler;
  }

  /**
   * Load any saved state from local storage
   */
  private loadSavedState(): void {
    try {
      const savedState = localStorage.getItem('notifications_state');
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        this._notifications$.next({
          ...this._notifications$.value,
          ...parsedState
        });
      }
    } catch (error) {
      console.error('[NotificationsService] Error loading saved state:', error);
    }
  }

  /**
   * Save current state to local storage
   */
  private saveState(): void {
    try {
      const stateToSave = {
        lastReadTimestamp: this._notifications$.value.lastReadTimestamp
      };
      localStorage.setItem('notifications_state', JSON.stringify(stateToSave));
    } catch (error) {
      console.error('[NotificationsService] Error saving state:', error);
    }
  }

  /**
   * Start polling for notifications
   */
  startPolling(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }

    // Fetch immediately on start
    this.fetchNotifications();

    // Then set up regular polling
    this.pollingSubscription = interval(this.POLL_INTERVAL).subscribe(() => {
      this.fetchNotifications();
    });
  }

  /**
   * Stop polling for notifications
   */
  stopPolling(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = null;
    }
  }

  /**
   * Toggle the notifications pane visibility
   */
  toggleNotificationsPane(): void {
    const currentState = this._notifications$.value;
    this._notifications$.next({
      ...currentState,
      showNotificationsPane: !currentState.showNotificationsPane
    });

    // If opening the pane, mark all as read
    if (!currentState.showNotificationsPane) {
      this.markAllAsRead();
    }
  }

  /**
   * Close the notifications pane
   */
  closeNotificationsPane(): void {
    const currentState = this._notifications$.value;
    if (currentState.showNotificationsPane) {
      this._notifications$.next({
        ...currentState,
        showNotificationsPane: false
      });
    }
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    const currentState = this._notifications$.value;
    
    // If no unread notifications, return early
    if (currentState.unreadCount === 0) return;
    
    // Mark all notifications as read
    const updatedNotifications = currentState.notifications.map(notification => ({
      ...notification,
      read: true
    }));
    
    // Update timestamp to the most recent notification
    let mostRecentTimestamp = currentState.lastReadTimestamp;
    for (const notification of currentState.notifications) {
      if (notification.timestamp > mostRecentTimestamp) {
        mostRecentTimestamp = notification.timestamp;
      }
    }
    
    // Update state
    this._notifications$.next({
      ...currentState,
      notifications: updatedNotifications,
      unreadCount: 0,
      lastReadTimestamp: mostRecentTimestamp
    });
    
    // Save updated state
    this.saveState();
  }

  /**
   * Remove a notification
   * @param id The ID of the notification to remove
   */
  removeNotification(id: string): void {
    const currentState = this._notifications$.value;
    const notification = currentState.notifications.find(n => n.id === id);
    
    if (!notification) return;
    
    const updatedNotifications = currentState.notifications.filter(n => n.id !== id);
    const unreadCount = notification.read 
      ? currentState.unreadCount 
      : Math.max(0, currentState.unreadCount - 1);
    
    this._notifications$.next({
      ...currentState,
      notifications: updatedNotifications,
      unreadCount
    });
  }

  /**
   * Fetch notifications from the server
   */
  private async fetchNotifications(): Promise<void> {
    try {
      
      const currentState = this._notifications$.value;
      const lastTimestamp = currentState.lastReadTimestamp;

      // Query to get new notifications since last read timestamp
      const query = `SELECT * FROM STAGING.notifications WHERE TIMESTAMP > '${lastTimestamp}' ORDER BY TIMESTAMP ASC`;
      
      const response: QueryResponse = await firstValueFrom(
        this.snowflakeService.executeQueryObservable(query)
      );
      
      if (!response.Success) {
        console.error('[NotificationsService] Error fetching notifications:', response.ErrorMessage || 'Unknown error');
        return;
      }
      
      // Handle the TableModel data structure
      if (!response.Data || !response.Data.data || response.Data.data.length === 0) {
        return; // No new notifications
      }

      
      // Get the column schema to map indexes to column names
      const colSchema = response.Data.colSchema || [];
      const columnMap: { [key: string]: number } = {};
      
      // Create a mapping from column name to array index
      colSchema.forEach((col: ColumnSchema, index: number) => {
        columnMap[col.name.toUpperCase()] = index;
      });
      
      // Get current selected values from workspace state
      const currentWorkspaceId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
      const currentProjectId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
      const currentSessionId = this.sharedState.sessionsGridState$.value.selectedSessionId || '';
      
      // Get session ID from state manager
      const sessionIdFromStart = this.stateManagerService.getObject(currentSessionId, 'session.id');
      
      // Process new notifications from the data array
      const newNotifications: NotificationModel[] = response.Data.data.map((row: any[]) => {
        // Get values from row using column mapping
        const workspaceId = row[columnMap['WORKSPACE_ID']]?.toString() || '';
        const projectId = row[columnMap['PROJECT_ID']]?.toString() || undefined;
        const sessionId = row[columnMap['SESSION_ID']]?.toString() || undefined;
        
        // Use column mapping to extract values by name and fill in with current values if empty
        return {
          id: row[columnMap['NOTIFICATION_ID']]?.toString() || '',
          agentId: row[columnMap['AGENT_ID']]?.toString() || '',
          workspaceId: workspaceId, // Use current workspace if empty
          message: row[columnMap['MESSAGE']]?.toString() || '',
          type: row[columnMap['TYPE']]?.toString() || 'info',
          projectId: projectId, 
          sessionId: sessionId, 
          parameters: row[columnMap['PARAMS']]
            ? (typeof row[columnMap['PARAMS']] === 'string' 
               ? JSON.parse(row[columnMap['PARAMS']]) 
               : row[columnMap['PARAMS']])
            : undefined,
          riffml: row[columnMap['RIFFML']]?.toString() || undefined,
          nextPlaybook: row[columnMap['NEXT_PLAYBOOK']]?.toString() || undefined,
          timestamp: row[columnMap['TIMESTAMP']]?.toString() || new Date().toISOString(),
          read: false
        };
      });

      // Filter notifications based on context and duplicate prevention
      const filteredNotifications = newNotifications.filter(notification => {
        // Check if notification is already in our processed cache
        if (this.processedNotificationIds.has(notification.id)) {
          return false;
        }
        
        // Add to processed cache
        this.processedNotificationIds.add(notification.id);
        
        // Check if notification is relevant to current context
        // If notification has specific workspace/project/session IDs, they should match current context
        // If notification IDs are undefined/empty, we assume it's a global notification and keep it
        
        // Workspace check - if notification has a workspaceId, it must match current
        if (notification.workspaceId.trim() !== currentWorkspaceId) {
          //return false;
        }
        
        // Project check - if notification has a projectId, it must match current
        if (!notification.projectId || (notification.projectId.trim() !== currentProjectId)) {
          //return false;
        }
        
        // Session check - if notification has a sessionId, it must match current
        if (!notification.sessionId || (notification.sessionId.trim() !== currentSessionId)) {
          // Don't filter if notification.sessionId matches sessionIdFromStart
          if (!sessionIdFromStart || !notification.sessionId || notification.sessionId.trim() !== sessionIdFromStart) {
            return false;
          }
        }
        
        // Include notification if it passed all checks
        return true;
      });
      
      // Find the most recent timestamp from the new notifications
      let newLastReadTimestamp = lastTimestamp;
      for (const notification of filteredNotifications) {
        if (notification.timestamp > newLastReadTimestamp) {
          newLastReadTimestamp = notification.timestamp;
        }
      }
      
      // Filter out notifications with empty messages - they've already been processed for state/playbook
      const displayableNotifications = filteredNotifications.filter(notification => 
        notification.message && notification.message.trim() !== ''
      );
      
      // Update state with new notifications (only those with messages) and the updated timestamp
      this._notifications$.next({
        ...currentState,
        notifications: [...currentState.notifications, ...displayableNotifications],
        unreadCount: currentState.unreadCount + displayableNotifications.length,
        lastReadTimestamp: newLastReadTimestamp
      });
      
      // Save the updated timestamp to localStorage
      this.saveState();
      
      // Process parameters for state updates
      for (const notification of filteredNotifications) {
        await this.processNotificationParameters(notification);
        
        // Check if notification has a nextPlaybook and process it
        if (notification.nextPlaybook && this.processTurnHandler) {
          // Use notification's sessionId or the current selected sessionId if empty
          const sessionId = notification.sessionId || currentSessionId;
          
          // Only proceed if we have both a valid sessionId and nextPlaybook
          if (sessionId) {
            console.log(`[NotificationsService] Running playbook ${notification.nextPlaybook} for session ${sessionId}`);
            try {
              // Ensure nextPlaybook is a string
              const playBookId = String(notification.nextPlaybook);
              await this.processTurnHandler.runPlaybook(sessionId, playBookId, false);
            } catch (error) {
              console.error('[NotificationsService] Error running playbook:', error);
            }
          } else {
            console.warn('[NotificationsService] Cannot run playbook - no sessionId specified and no session selected');
          }
        }
      }
      
    } catch (error) {
      console.error('[NotificationsService] Error in fetchNotifications:', error);
    }
  }

  /**
   * Process notification parameters and update state if needed
   */
  private async processNotificationParameters(notification: NotificationModel): Promise<void> {
    if (!notification.parameters) return;
    
    // notification.parameters is an array of parameter objects
    const params = Array.isArray(notification.parameters) ? 
      notification.parameters : [notification.parameters];
    
    // Process each parameter object in the array
    for (const param of params) {
      // If there is a scope, add to the state
      if (param.scope && param.name) {
        let { scope, name, value } = param;

        if (!scope || !name || value === undefined) {
          scope = 'session';
        }
        
        // Get current selected values from workspace state
        const currentWorkspaceId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
        const currentProjectId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
        const currentSessionId = this.sharedState.sessionsGridState$.value.selectedSessionId || '';
        
        // Determine root ID based on scope
        let rootId: string | null = null;
        
        switch (scope) {
          case 'workspace':
            rootId = notification.workspaceId || currentWorkspaceId || this.workspaceManagerService.getCurrentWorkspaceId() || null;
            break;
          case 'project':
            rootId = notification.projectId || currentProjectId || null;
            break;
          case 'session':
            rootId = notification.sessionId || currentSessionId || null;
            break;
        }
        
        // Create state key (e.g., "workspace.user_auth_status_ga4")
        const stateKey = `${scope}.${name}`;
        
        // Update state
        try {
          await this.stateManagerService.setState(
            rootId,
            stateKey,
            name,
            name,
            `Parameter from notification: ${notification.message}`,
            'Added from notification',
            typeof value,
            value
          );
        } catch (error) {
          console.error('[NotificationsService] Error updating state:', error);
        }
      }
    }
  }

  /**
   * Add a new notification
   * @param request The notification request
   * @returns Promise with the result
   */
  async addNotification(request: NotificationRequest): Promise<any> {
    try {
      const response = await firstValueFrom(
        this.http.post(this.notificationsApiUrl, request)
      );
      
      return response;
    } catch (error) {
      console.error('[NotificationsService] Error adding notification:', error);
      throw error;
    }
  }
} 