// src/app/components/records/connections-draw.service.ts
import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { MemoryItem } from '../../models/memory-item.model';
import { TooltipService } from './tooltip.service';

export interface ConnectionRenderData {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: 'connection';
  element: any; // D3 selection reference
  data: any;
}

export interface DrawConfig {
  width: number;
  height: number;
  margin: number;
  connectionWidth: number;
  connectionHeight: number;
  connectionPadding: number;
  connectionHeaderHeight: number;
  connectionBorderRadius: number; // Added border radius property
  nodeSize: number;
  nodeMargin: number;
  nodesPerRow: number;
  connectionMemories?: {[connectionId: string]: MemoryItem[]};
  memorySize?: number;
  memoryMargin?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ConnectionsDrawService {
  private svg: any;
  private tooltip: any;
  private connectionTooltip: any; // Dedicated tooltip for connections
  private memoryTooltip: any; // Dedicated tooltip for memories
  private logoImage: any;
  private objectsMap: Record<string, ConnectionRenderData> = {};
  
  // Connection type colors
  private connectionTypeColorMap: {[key: string]: string} = {
    'data_flow': '#00B0FF',      // Vibrant blue for data flow connections
    'dependency': '#FF9100',      // Vibrant orange for dependency connections
    'reference': '#00E676',      // Vibrant green for reference connections
    'trigger': '#FF1744',        // Vibrant red for trigger connections
    'default': '#B0BEC5'         // Gray for other types
  };
  
  // Service type colors and icons
  private serviceTypeColorMap: {[key: string]: {color: string, icon: string}} = {
    'BigQuery': { color: '#1A73E8', icon: '📊' },      // Vibrant Google blue for BigQuery
    'Snowflake': { color: '#29B5E8', icon: '❄️' },     // Snowflake blue
    'Redshift': { color: '#8C4FFF', icon: '🔴' },      // Amazon purple for Redshift
    'AzureSQL': { color: '#0078D4', icon: '☁️' },      // Azure blue
    'PostgreSQL': { color: '#336791', icon: '🐘' },    // PostgreSQL blue
    'MySQL': { color: '#00758F', icon: '🐬' },         // MySQL blue
    'MongoDB': { color: '#4DB33D', icon: '🍃' },       // MongoDB green
    'Databricks': { color: '#FF3621', icon: '⚡' },    // Databricks red
    'S3': { color: '#FF9900', icon: '📦' },           // AWS orange for S3
    'GCS': { color: '#FBBC05', icon: '☁️' },          // Google yellow for GCS
    'ADLS': { color: '#0078D4', icon: '💾' },         // Azure blue for ADLS
    'default': { color: '#90A4AE', icon: '🔌' }       // Default gray
  };
  
  private defaultConfig: DrawConfig = {
    width: 960,
    height: 600,
    margin: 20,
    connectionWidth: 220,
    connectionHeight: 100, // Changed from 80 to 100 to match pipeline height
    connectionPadding: 15,
    connectionHeaderHeight: 25,
    connectionBorderRadius: 10, // Added default border radius
    nodeSize: 4,
    nodeMargin: 4,
    nodesPerRow: 5
  };

  constructor(
    private tooltipService: TooltipService
  ) {
    // Create a dedicated custom tooltip for connections
    this.connectionTooltip = this.tooltipService.createTooltip('connection');
    
    // Create a dedicated custom tooltip for memories
    this.memoryTooltip = this.tooltipService.createTooltip('memory');
    
    // Preload the logo
    this.preloadLogo();
  }

  /**
   * Preload the Ask-Y logo for use in visualizations
   */
  private preloadLogo(): void {
    // Create an image element to load the SVG
    this.logoImage = new Image();
    this.logoImage.src = 'assets/images/asky-logo.svg';
  }

  /**
   * Set the SVG element
   */
  public setSvg(svg: any): void {
    this.svg = svg;
  }

  /**
   * Create a custom tooltip element
   */
  private createCustomTooltip(): void {
    // Use the tooltip service to create a tooltip
    this.connectionTooltip = this.tooltipService.createTooltip('connection');
  }

  /**
   * Draw connections based on provided data
   */
  public drawConnections(connectionsList: any[], renderedObjects: Record<string, any> = {}, config: Partial<DrawConfig> = {}): void {
    // Clear existing content
    this.clear();

    // Ensure connectionMemories is a valid object
    config.connectionMemories = config.connectionMemories || {};

    // Merge provided config with defaults
    const drawConfig = { ...this.defaultConfig, ...config };

    if (!connectionsList || connectionsList.length === 0) {
      return;
    }

    // Calculate vertical spacing
    const verticalMargin = 20;
    let currentY = 0;

    // Draw each connection
    connectionsList.forEach((connection, index) => {
      const yPosition = currentY;
      const height = this.drawConnection(connection, index, verticalMargin, drawConfig, yPosition);
      currentY += height + verticalMargin;
    });
  }

  /**
   * Draw a single connection
   */
  private drawConnection(
    connection: any, 
    index: number, 
    verticalMargin: number, 
    config: DrawConfig, 
    yPosition: number = 0
  ): number {
    // Create connection group
    const connectionGroup = this.svg.append('g')
      .attr('class', 'connection')
      .attr('transform', `translate(0, ${yPosition})`);
    
    // Determine connection color based on type
    const connectionType = connection.type || 'default';
    const connectionColor = this.connectionTypeColorMap[connectionType] || this.connectionTypeColorMap['default'];
    const darkerConnectionColor = this.darkenColor(connectionColor, 0.2); // Darker version for header

    // Draw connection rectangle with gradient
    const gradientId = `connection-gradient-${index}`;
    const defs = this.svg.append('defs');
    const gradient = defs.append('linearGradient')
      .attr('id', gradientId)
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%');
    
    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', connectionColor)
      .attr('stop-opacity', 0.2);
      
    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', darkerConnectionColor)
      .attr('stop-opacity', 0.2);

    // Draw connection rectangle
    const connectionRect = connectionGroup.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', config.connectionWidth)
      .attr('height', config.connectionHeight)
      .attr('rx', config.connectionBorderRadius) // Use border radius from config
      .attr('ry', config.connectionBorderRadius)
      .attr('fill', `url(#${gradientId})`) // Use gradient
      .attr('stroke', connectionColor) // Border color matching the fill
      .attr('stroke-width', 1);

    // Draw connection header
    const headerHeight = config.connectionHeaderHeight;
    const headerWidth = config.connectionWidth;
    
    // Header background
    connectionGroup.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', headerWidth)
      .attr('height', headerHeight)
      .attr('fill', '#2c3e50')  // Darker blue-gray for better contrast with white text
      .attr('rx', config.connectionBorderRadius)
      .attr('ry', config.connectionBorderRadius);
    
    // Connection name
    connectionGroup.append('text')
      .attr('x', config.connectionPadding)
      .attr('y', headerHeight / 2)
      .attr('dominant-baseline', 'middle')
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
      .attr('fill', '#ffffff')
      .text(this.truncateText(connection.name || 'Unnamed Connection', 30));

    // Draw connection visualization
    this.drawConnectionVisualization(connectionGroup, connection, config);

    // Draw memory items if available
    if (config.connectionMemories && config.connectionMemories[connection.id]) {
      const memoryHeight = this.drawConnectionMemories(connectionGroup, config.connectionMemories[connection.id], connection.id, config);
      // Update connection height to include memory items
      connectionRect.attr('height', config.connectionHeight + memoryHeight);
    }

    // Store connection reference
    this.objectsMap[connection.id] = {
      id: connection.id,
      x: 0,
      y: yPosition,
      width: config.connectionWidth,
      height: config.connectionHeight,
      type: 'connection',
      element: connectionGroup,
      data: connection
    };

    // Add event listeners to connection
    this.addConnectionEventListeners(connectionGroup, connection);

    return config.connectionHeight;
  }

  /**
   * Draw memory items for a connection
   */
  private drawConnectionMemories(connectionGroup: any, memories: MemoryItem[], connectionId: string, config: DrawConfig): number {
    // Default memory size and margin if not provided
    const memorySize = config.memorySize || 16;
    const memoryMargin = config.memoryMargin || 8;
    
    // Start position for memories (below the connection content)
    const memoryStartY = config.connectionHeight + 10;
    
    // Create memories container
    const memoriesContainer = connectionGroup.append('g')
      .attr('class', 'connection-memories-container')
      .attr('transform', `translate(${config.connectionPadding}, ${memoryStartY})`);
    
    // Calculate items per row based on connection width
    const itemsPerRow = Math.floor((config.connectionWidth - (2 * config.connectionPadding)) / (memorySize + memoryMargin));
    
    // Draw memory items
    memories.forEach((memory, index) => {
      const rowIndex = Math.floor(index / itemsPerRow);
      const colIndex = index % itemsPerRow;
      
      const memX = colIndex * (memorySize + memoryMargin);
      const memY = rowIndex * (memorySize + memoryMargin);
      
      // Determine color based on memory type
      let memoryColor = '#90a4ae'; // Default color
      
      switch(memory.type) {
        case 'data_issue':
          memoryColor = '#607d8b'; // Blue-gray
          break;
        case 'model_evaluation':
          memoryColor = '#5c6bc0'; // Indigo
          break;
        case 'user_feedback':
          memoryColor = '#26a69a'; // Teal
          break;
        case 'system_alert':
          memoryColor = '#ef5350'; // Light red
          break;
        case 'performance_metric':
          memoryColor = '#66bb6a'; // Green
          break;
      }
      
      // Draw memory item
      const memoryItem = memoriesContainer.append('rect')
        .attr('class', 'memory-item')
        .attr('x', memX)
        .attr('y', memY)
        .attr('width', memorySize)
        .attr('height', memorySize)
        .attr('rx', 2)
        .attr('ry', 2)
        .attr('fill', memoryColor)
        .attr('stroke', '#666')
        .attr('stroke-width', 0.5)
        .attr('data-memory-id', (memory as any).id || index.toString())
        .style('cursor', 'pointer');
      
      // Setup tooltip for memory item
      this.setupMemoryTooltip(memoryItem, memory);
    });
    
    // Calculate the total height used by memories
    const memoryRows = Math.ceil(memories.length / itemsPerRow);
    const memoryHeight = memoryRows * (memorySize + memoryMargin);
    
    return memoryHeight;
  }

  /**
   * Setup tooltip for memory item
   */
  private setupMemoryTooltip(element: any, memory: MemoryItem): void {
    element
      .on('mouseenter', (event: MouseEvent) => {
        // Format tooltip content
        const tooltipContent = `
          <div class="tooltip-title">${memory.type || 'Memory'}</div>
          <div class="tooltip-content">
            <div><strong>Observation:</strong> ${memory.observation || 'N/A'}</div>
            <div><strong>Insight:</strong> ${memory.insight || 'N/A'}</div>
            ${memory.importance ? `<div><strong>Importance:</strong> ${memory.importance}</div>` : ''}
          </div>
        `;
        
        this.tooltipService.showTooltip(this.memoryTooltip, event, tooltipContent);
      })
      .on('mouseleave', () => {
        this.tooltipService.hideTooltip(this.memoryTooltip);
      })
      .on('mousemove', (event: MouseEvent) => {
        this.tooltipService.moveTooltip(this.memoryTooltip, event);
      });
  }

  /**
   * Draw a visual representation of the connection
   */
  private drawConnectionVisualization(connectionGroup: any, connection: any, config: DrawConfig): void {
    const visualizationGroup = connectionGroup.append('g')
      .attr('class', 'connection-visualization')
      .attr('transform', `translate(${config.connectionPadding}, ${config.connectionHeaderHeight + 10})`);

    // Connection type determines visualization style
    const connectionType = connection.type || 'default';
    const connectionColor = this.connectionTypeColorMap[connectionType] || this.connectionTypeColorMap['default'];
    
    // Get service type for the target
    const serviceType = connection.serviceType || connection.servicetype || 'default';
    const serviceInfo = this.serviceTypeColorMap[serviceType] || this.serviceTypeColorMap['default'];
    
    // Draw source and target nodes with a line between them
    const sourceX = 25;  // Closer to the logo
    const sourceY = 15;  // Position above the logo but closer
    const targetX = config.connectionWidth - 60; // Further from the right edge
    const centerY = 25;
    
    // Add Ask-Y logo as source - moved more to the left
    visualizationGroup.append('svg:image')
      .attr('x', 0)  // Position at the very left edge
      .attr('y', centerY - 25)  // Center vertically
      .attr('width', 80)  // Keep the same width
      .attr('height', 50)  // Keep the same height
      .attr('xlink:href', 'assets/images/asky-logo.svg')
      .attr('preserveAspectRatio', 'xMidYMid meet');
    
    // Target node (service-specific) - moved away from right edge
    const targetGradientId = `target-gradient-${connection.id}`;
    const targetDefs = this.svg.append('defs');
    const targetGradient = targetDefs.append('radialGradient')
      .attr('id', targetGradientId)
      .attr('cx', '50%')
      .attr('cy', '50%')
      .attr('r', '70%');
    
    targetGradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', serviceInfo.color)
      .attr('stop-opacity', 1);
      
    targetGradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', this.darkenColor(serviceInfo.color, 0.3))
      .attr('stop-opacity', 1);
    
    visualizationGroup.append('circle')
      .attr('cx', targetX)
      .attr('cy', centerY)
      .attr('r', 12)
      .attr('fill', `url(#${targetGradientId})`)
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 1.5)
      .attr('filter', 'drop-shadow(0 0 4px rgba(0,0,0,0.4))');
    
    // Target icon
    visualizationGroup.append('text')
      .attr('x', targetX)
      .attr('y', centerY)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr('font-size', '12px')
      .attr('fill', '#ffffff')
      .text(serviceInfo.icon);
    
    // Connection line with animation - now starts above the logo and extends beyond BigQuery
    const pathData = this.createConnectionPath(sourceX, sourceY, targetX, centerY, connectionType);
    
    const path = visualizationGroup.append('path')
      .attr('d', pathData)
      .attr('fill', 'none')
      .attr('stroke', connectionColor)
      .attr('stroke-width', 2.5)
      .attr('stroke-dasharray', connectionType === 'reference' ? '4,3' : '0')
      .attr('marker-end', connectionType !== 'reference' ? `url(#arrow-${connectionType})` : null)
      .attr('filter', 'drop-shadow(0 0 2px rgba(0,0,0,0.3))');
    
    // Create arrow marker if not already defined
    this.createArrowMarker(connectionType, connectionColor);
    
    // Add animation for data flow connections
    if (connectionType === 'data_flow') {
      this.animateDataFlow(path);
    }
    
    // Add target label with better visibility
    // Create a background for the text to improve readability
    const targetLabel = serviceType;
    
    // Target label with background - smaller and better positioned
    const targetLabelWidth = Math.max(40, targetLabel.length * 6); // Adjust width based on text length
    const targetLabelBg = visualizationGroup.append('rect')
      .attr('x', targetX - (targetLabelWidth / 2))
      .attr('y', centerY - 22)
      .attr('width', targetLabelWidth)
      .attr('height', 14)
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('fill', 'rgba(0,0,0,0.7)');
    
    visualizationGroup.append('text')
      .attr('x', targetX)
      .attr('y', centerY - 15)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .attr('font-size', '9px')
      .attr('fill', '#ffffff')
      .text(targetLabel);
  }
  
  /**
   * Create a custom path that arcs above the logo and extends beyond BigQuery
   */
  private createCustomPath(sourceX: number, sourceY: number, targetX: number, targetY: number, connectionType: string): string {
    // Create a path that curves over the logo text
    const controlPoint1X = sourceX + 30;
    const controlPoint1Y = sourceY - 5; // Pull up slightly for a nice arc
    const controlPoint2X = targetX - 60;
    const controlPoint2Y = sourceY - 5;
    
    return `M${sourceX},${sourceY} C${controlPoint1X},${controlPoint1Y} ${controlPoint2X},${controlPoint2Y} ${targetX},${targetY}`;
  }
  
  /**
   * Create a path for the connection line based on connection type
   */
  private createConnectionPath(sourceX: number, sourceY: number, targetX: number, targetY: number, connectionType: string): string {
    // Different path styles based on connection type
    switch (connectionType) {
      case 'data_flow':
        // Straight line with slight curve
        return `M${sourceX},${sourceY} C${sourceX + 30},${sourceY} ${targetX - 30},${targetY} ${targetX},${targetY}`;
      case 'dependency':
        // Stepped line
        const midX = (sourceX + targetX) / 2;
        return `M${sourceX},${sourceY} H${midX} V${targetY - 5} H${targetX} V${targetY}`;
      case 'trigger':
        // Zigzag line
        const segment = (targetX - sourceX) / 4;
        return `M${sourceX},${sourceY} 
                l${segment},${-10} 
                l${segment},${20} 
                l${segment},${-10} 
                L${targetX},${targetY}`;
      default:
        // Simple curved line
        return `M${sourceX},${sourceY} Q${(sourceX + targetX) / 2},${sourceY - 15} ${targetX},${targetY}`;
    }
  }
  
  /**
   * Create arrow marker for connection lines
   */
  private createArrowMarker(connectionType: string, color: string): void {
    // Check if marker already exists
    if (d3.select(`#arrow-${connectionType}`).empty()) {
      const defs = this.svg.append('defs');
      
      defs.append('marker')
        .attr('id', `arrow-${connectionType}`)
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 8)
        .attr('refY', 0)
        .attr('markerWidth', 6)
        .attr('markerHeight', 6)
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M0,-5L10,0L0,5')
        .attr('fill', color);
    }
  }
  
  /**
   * Animate data flow along the connection path
   */
  private animateDataFlow(path: any): void {
    // Create moving dots along the path
    const pathNode = path.node();
    if (!pathNode) return;
    
    const pathLength = pathNode.getTotalLength();
    
    // Create a group for the animation
    const animGroup = path.parent.append('g')
      .attr('class', 'data-flow-animation');
    
    // Add 3 dots that will move along the path
    for (let i = 0; i < 3; i++) {
      const dot = animGroup.append('circle')
        .attr('r', 2)
        .attr('fill', '#fff')
        .style('opacity', 0);
        
      // Animate each dot
      this.animateDot(dot, pathNode, pathLength, i * 1000, 3000);
    }
  }
  
  /**
   * Animate a single dot along a path
   */
  private animateDot(dot: any, pathNode: any, pathLength: number, delay: number, duration: number): void {
    function repeat() {
      dot.style('opacity', 1)
        .attr('transform', `translate(${pathNode.getPointAtLength(0).x}, ${pathNode.getPointAtLength(0).y})`)
        .transition()
        .delay(delay)
        .duration(duration)
        .ease(d3.easeLinear)
        .attrTween('transform', function() {
          return function(t: number) {
            const point = pathNode.getPointAtLength(t * pathLength);
            return `translate(${point.x}, ${point.y})`;
          };
        })
        .on('end', function() {
          dot.style('opacity', 0)
            .transition()
            .delay(Math.random() * 1000)
            .on('end', repeat);
        });
    }
    
    repeat();
  }

  /**
   * Generate tooltip content for connection
   */
  private generateConnectionTooltip(connection: any): string {
    let html = '';
    
    // Add header with connection name
    const connectionName = connection.name || 'Unnamed Connection';
    html += this.tooltipService.formatHeader(connectionName, 'connection');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available
    if (connection.description) {
      mainContent += `<div class="tooltip-description">${connection.description}</div>`;
    }
    
    // Add connection details section
    let detailsContent = '';
    
    // Add connection type if available
    if (connection.type) {
      detailsContent += this.tooltipService.formatProperty('Type', connection.type);
    }
    
    // Add connection status if available
    if (connection.status) {
      detailsContent += this.tooltipService.formatProperty('Status', connection.status);
    }
    
    // Add source and target information
    detailsContent += this.tooltipService.formatProperty('Source', 'Ask-Y');
    
    if (connection.target) {
      detailsContent += this.tooltipService.formatProperty('Target', connection.target);
    } else if (connection.targetType) {
      detailsContent += this.tooltipService.formatProperty('Target', connection.targetType);
    } else {
      detailsContent += this.tooltipService.formatProperty('Target', 'BigQuery');
    }
    
    // Add BigQuery section if we have any BigQuery details
    let bigQueryContent = '';
    
    // Add BigQuery project if available
    if (connection.bigQueryProject) {
      bigQueryContent += this.tooltipService.formatProperty('BQ Project', connection.bigQueryProject);
    }
    
    // Add BigQuery dataset if available
    if (connection.bigQueryDataset) {
      bigQueryContent += this.tooltipService.formatProperty('BQ Dataset', connection.bigQueryDataset);
    }
    
    // Add BigQuery table if available
    if (connection.bigQueryTable) {
      bigQueryContent += this.tooltipService.formatProperty('BQ Table', connection.bigQueryTable);
    }
    
    // Add BigQuery section if we have any BigQuery details
    if (bigQueryContent) {
      mainContent += this.tooltipService.formatSection(bigQueryContent, 'BigQuery Details');
    }
    
    // Add main content
    html += mainContent;
    
    // Add footer with ID if available
    if (connection.id) {
      html += this.tooltipService.formatFooter(`ID: ${connection.id}`);
    }
    
    return html;
  }

  /**
   * Highlight a specific connection
   */
  public highlightConnection(id: string, options: { color?: string; duration?: number } = {}): void {
    const obj = this.objectsMap[id];
    if (!obj) return;

    const color = options.color || '#ff9800';
    const duration = options.duration || 1000;

    // Highlight the connection
    obj.element.select('rect')
      .transition()
      .duration(duration / 2)
      .attr('stroke', color)
      .attr('stroke-width', 3)
      .attr('filter', 'drop-shadow(0 0 5px rgba(255, 255, 255, 0.3))')
      .transition()
      .duration(duration / 2)
      .attr('stroke', this.connectionTypeColorMap[obj.data.type] || this.connectionTypeColorMap['default'])
      .attr('stroke-width', 1)
      .attr('filter', null);
  }

  /**
   * Get all rendered objects
   */
  public getAllObjects(): Record<string, ConnectionRenderData> {
    return this.objectsMap;
  }

  /**
   * Highlight an object (alias for highlightConnection to maintain API consistency)
   */
  public highlightObject(id: string, options: { color?: string; duration?: number } = {}): void {
    this.highlightConnection(id, options);
  }

  /**
   * Truncate text if too long
   */
  private truncateText(text: string, maxLength: number): string {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
  }

  /**
   * Darken a color by a specified amount
   */
  private darkenColor(color: string, amount: number): string {
    // Convert hex to RGB
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    
    // Darken each component
    const darkenComponent = (c: number) => Math.max(0, Math.floor(c * (1 - amount)));
    
    // Convert back to hex
    return `#${darkenComponent(r).toString(16).padStart(2, '0')}${darkenComponent(g).toString(16).padStart(2, '0')}${darkenComponent(b).toString(16).padStart(2, '0')}`;
  }

  /**
   * Clear the objects map
   */
  public clear(): void {
    this.objectsMap = {};
  }

  /**
   * Add event listeners to connection
   */
  private addConnectionEventListeners(connectionGroup: any, connection: any): void {
    // Ensure the connection tooltip exists
    if (!this.connectionTooltip) {
      this.connectionTooltip = this.tooltipService.createTooltip('connection');
    }
    
    connectionGroup
      .style('cursor', 'pointer')
      .on('mouseenter', (event: MouseEvent) => {
        // Show tooltip
        this.tooltipService.showTooltip(
          this.connectionTooltip,
          event,
          this.generateConnectionTooltip(connection)
        );
        
        // Highlight connection
        connectionGroup.select('rect')
          .transition()
          .duration(200)
          .attr('stroke-width', 2);
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.connectionTooltip, event);
      })
      .on('mouseleave', () => {
        // Hide tooltip
        this.tooltipService.hideTooltip(this.connectionTooltip);
          
        // Reset connection highlight
        connectionGroup.select('rect')
          .transition()
          .duration(200)
          .attr('stroke-width', 1);
      })
      .on('click', (event: MouseEvent) => {
        // Hide tooltip when clicked
        this.tooltipService.hideTooltip(this.connectionTooltip);
        
        // Copy connection data to clipboard
        const connectionName = connection.name || 'Unnamed Connection';
        
        // Create a formatted string with connection information
        let connectionInfo = `Connection: ${connectionName}\n`;
        
        if (connection.description) {
          connectionInfo += `Description: ${connection.description}\n`;
        }
        
        // Add connection type if available
        if (connection.type) {
          connectionInfo += `Type: ${connection.type}\n`;
        }
        
        // Add connection status if available
        if (connection.status) {
          connectionInfo += `Status: ${connection.status}\n`;
        }
        
        // Add source and target information
        connectionInfo += `Source: ${connection.source || 'Ask-Y'}\n`;
        
        if (connection.target) {
          connectionInfo += `Target: ${connection.target}\n`;
        } else if (connection.targetType) {
          connectionInfo += `Target: ${connection.targetType}\n`;
        } else {
          connectionInfo += `Target: BigQuery\n`;
        }
        
        // Add BigQuery details if available
        if (connection.bigQueryProject) {
          connectionInfo += `BQ Project: ${connection.bigQueryProject}\n`;
        }
        
        if (connection.bigQueryDataset) {
          connectionInfo += `BQ Dataset: ${connection.bigQueryDataset}\n`;
        }
        
        if (connection.bigQueryTable) {
          connectionInfo += `BQ Table: ${connection.bigQueryTable}\n`;
        }
        
        // Add Snowflake details if available
        if (connection.snowflakeAccount) {
          connectionInfo += `Snowflake Account: ${connection.snowflakeAccount}\n`;
        }
        
        if (connection.snowflakeWarehouse) {
          connectionInfo += `Snowflake Warehouse: ${connection.snowflakeWarehouse}\n`;
        }
        
        if (connection.snowflakeDatabase) {
          connectionInfo += `Snowflake Database: ${connection.snowflakeDatabase}\n`;
        }
        
        if (connection.snowflakeSchema) {
          connectionInfo += `Snowflake Schema: ${connection.snowflakeSchema}\n`;
        }
        
        if (connection.snowflakeTable) {
          connectionInfo += `Snowflake Table: ${connection.snowflakeTable}\n`;
        }
        
        // Add connection parameters if available
        if (connection.parameters && Object.keys(connection.parameters).length > 0) {
          connectionInfo += `\nParameters:\n`;
          Object.entries(connection.parameters).forEach(([key, value]) => {
            // Don't include sensitive information like passwords or tokens
            if (!key.toLowerCase().includes('password') && 
                !key.toLowerCase().includes('secret') && 
                !key.toLowerCase().includes('token') && 
                !key.toLowerCase().includes('key')) {
              connectionInfo += `  ${key}: ${value}\n`;
            }
          });
        }
        
        // Add creation and update dates if available
        if (connection.createdAt) {
          connectionInfo += `Created: ${new Date(connection.createdAt).toLocaleString()}\n`;
        }
        
        if (connection.updatedAt) {
          connectionInfo += `Updated: ${new Date(connection.updatedAt).toLocaleString()}\n`;
        }
        
        // Add owner if available
        if (connection.owner) {
          connectionInfo += `Owner: ${connection.owner}\n`;
        }
        
        // Add tags if available
        if (connection.tags && connection.tags.length) {
          connectionInfo += `Tags: ${connection.tags.join(', ')}\n`;
        }
        
        // Add ID if available
        if (connection.id) {
          connectionInfo += `ID: ${connection.id}`;
        }
        
        // Copy to clipboard
        navigator.clipboard.writeText(connectionInfo).then(() => {
          // Show notification
          this.tooltipService.showNotification('Connection info copied!', event);
        }).catch(err => {
          console.error('Failed to copy connection info: ', err);
        });
        
        // Prevent event bubbling
        event.stopPropagation();
      });
  }
}
