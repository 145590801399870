import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface RecordingState {
  isRecording: boolean;
  duration: number;
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AudioRecordingService {
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];
  private recordingTimer: any;
  
  private recordingStateSubject = new BehaviorSubject<RecordingState>({
    isRecording: false,
    duration: 0
  });
  
  public recordingState$ = this.recordingStateSubject.asObservable();

  constructor() {}

  /**
   * Start recording audio from the user's microphone
   * @returns Promise that resolves when recording starts
   */
  async startRecording(): Promise<void> {
    try {
      // Reset any previous state
      this.resetRecording();
      
      // Request microphone access
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      // Create new media recorder
      this.mediaRecorder = new MediaRecorder(stream);
      
      // Set up event handlers
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data);
        }
      };
      
      // Start recording
      this.mediaRecorder.start(100); // Collect data every 100ms to ensure we have data
      
      // Update state
      this.recordingStateSubject.next({
        isRecording: true,
        duration: 0
      });
      
      // Start timer
      let duration = 0;
      this.recordingTimer = setInterval(() => {
        duration += 1;
        this.recordingStateSubject.next({
          isRecording: true,
          duration
        });
      }, 1000);
      
    } catch (err) {
      console.error('[AudioRecordingService] Error starting recording:', err);
      this.recordingStateSubject.next({
        isRecording: false,
        duration: 0,
        error: 'Could not access microphone. Please ensure microphone permissions are granted.'
      });
      throw err;
    }
  }

  /**
   * Stop recording and return the audio blob
   * @returns Promise containing the recorded audio blob
   */
  async stopRecording(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      if (!this.mediaRecorder || this.mediaRecorder.state === 'inactive') {
        this.resetRecording();
        reject(new Error('Not currently recording'));
        return;
      }

      // Request data from the recorder before stopping
      this.mediaRecorder.requestData();

      // Set up the stop event handler
      this.mediaRecorder.onstop = () => {
        // Create blob from chunks
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        
        // Update state
        this.recordingStateSubject.next({
          isRecording: false,
          duration: this.recordingStateSubject.value.duration
        });
        
        // Clear timer
        if (this.recordingTimer) {
          clearInterval(this.recordingTimer);
          this.recordingTimer = null;
        }
        
        // Clean up media tracks
        this.mediaRecorder?.stream.getTracks().forEach(track => track.stop());
        
        // Resolve with the audio blob
        resolve(audioBlob);
      };

      // Stop recording
      this.mediaRecorder.stop();
    });
  }

  /**
   * Cancel the current recording
   */
  cancelRecording(): void {
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
      this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
    }
    
    this.resetRecording();
  }

  /**
   * Reset the recording state
   */
  private resetRecording(): void {
    this.audioChunks = [];
    
    if (this.recordingTimer) {
      clearInterval(this.recordingTimer);
      this.recordingTimer = null;
    }
    
    this.recordingStateSubject.next({
      isRecording: false,
      duration: 0
    });
  }

  /**
   * Get the current recording state
   */
  getCurrentState(): RecordingState {
    return this.recordingStateSubject.value;
  }
} 