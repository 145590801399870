/**
 * @file src/app/services/tools/userChangedWorkstream-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';
import { Workstream } from '../../models/workboard.model';

/**
 * Tool: userChangedWorkstream
 * handles the situation the user changed the workstream
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerUserChangedWorkstreamTool(service: ToolsService): void {
  (service as any).userChangedWorkstream = async function(next: string, sessionId: string, riffMLNode: RiffMLNode | null, value: any): Promise<void> {
    console.log(`ToolsService.userChangedWorkstream() called with next=${next}`);
    
    if (!value) {
      console.error('No workstream provided to userChangedWorkstream tool');
      return;
    }

    var tsessionId = value.editSession ?? this.workspaceState.sessionsGridState$.value.selectedSessionId ?? sessionId ?? '';
    // TODO: find a sesstion  that larges edit label and this workspace in related
    
    //sessionId: string, storeTarget: string, storeTargetId: string, storeTargetName: string, storeTargetDescription: string, storeTargetWhyRelevant: string, storeTargetType: string, value: any
    this.setState(tsessionId, 'stagedworkstream', "stagedworkstream", "stagedworkstream", value.workstream.description, 'value changed', 'workstream', value.workstream);

    /*setTimeout(() => {
        // Send a message to the chat component to send a message
        this.workspaceState.sendChatMessage$.next({ sessionId: tsessionId, message: 'Please check the changes I have made to the workstream' });
      }, 400);*/
    
    let hasChanges = false;
    let workstream = value.workstream;
      if (workstream.units && workstream.units.length > 0) {
        for (const unit of workstream.units) {
            // handle unit code to generate
            if (unit.mingusForComparison && unit.mingusForComparison.length > 0 && unit.mingusForComparison!=unit.stagedMingus)
              {
                  hasChanges = true;
                  if (unit.description && unit.description.length > 0)
                  {
                    await this.setState(tsessionId, 'codeDescription', "codeDescription", "codeDescription", "codeDescription", 'value changed', 'text', unit.description);
                  }
      
                  await this.setState(tsessionId, 'unitCode', "unitCode", "unitCode", "unitCode", 'value changed', 'text', unit.code);
                  await this.setState(tsessionId, 'unitMingus', "unitMingus", "unitMingus", "unitMingus", 'value changed', 'text', unit.mingusOriginal);
                  await this.setState(tsessionId, 'unitStagedMingus', "unitStagedMingus", "unitStagedMingus", "unitStagedMingus", 'value changed', 'text', unit.stagedMingus);
      
                  // Get the process turn handler
                  const processTurnHandler = this.getProcessTurnHandler();
                  if (processTurnHandler) {
                    await processTurnHandler.runPlaybook(tsessionId, "47701", false, "unitUpdate");
                    let unitUpdate =  this.stateManagerService.getObject(tsessionId, "session.unitUpdate");
                    if (unitUpdate && unitUpdate.length > 0)
                    {
                      value = this.jsonParserService.safeParseJson(unitUpdate);
                      if (value)
                      {
                        unit.code = value.code.replaceAll('\\"', '"');
                        delete value.code;
                        unit.mingus = JSON.stringify(value);
                        unit.codeChanges = JSON.stringify(value.codeChanges);
                        unit.mingusChanges = JSON.stringify(value.mingusChanges);
                      }
                    }
                  }
                  else
                  {
                    console.error('No ProcessTurnHandler registered to handle playbook');
                  }
              }
        }
    }

    if (hasChanges)
    {
      workstream.inEditMode = true;
      await this.addToState(tsessionId, 'workstreamchanges.' + workstream.id, workstream);
      await this.workspaceState.mergeWorkstream(workstream);

      const processTurnHandler = this.getProcessTurnHandler();
      if (processTurnHandler) {
        await processTurnHandler.runPlaybook(tsessionId, "47702", false, null);
      }
      
    }
  };
}