// app.routes.ts
import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './AuthGuard';
import { LayoutComponent } from './components/layout/layout.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { SpacesComponent } from './components/spaces/spaces.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { AdminComponent } from './components/admin/admin.component';
import { ConnectionsComponent } from './components/connections/connections.component';
import { DataPrepComponent } from './components/data-prep/data-prep.component';
import { ModelingComponent } from './components/modeling/modeling.component';
import { AnalysisComponent } from './components/analysis/analysis.component';

export const routes: Routes = [
  { path: '', redirectTo: 'studio', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MsalGuard, AuthGuard],
    children: [
      { path: 'landing', component: LandingPageComponent },
      { path: 'spaces', component: SpacesComponent },
      { path: 'studio', component: HomeComponent },
      { path: 'templates', component: TemplatesComponent },
      { path: 'connections', component: ConnectionsComponent },
      { path: 'data-prep', component: DataPrepComponent },
      { path: 'modeling', component: ModelingComponent },
      { path: 'analysis', component: AnalysisComponent },
      { path: 'admin', component: AdminComponent },
      { path: '**', redirectTo: 'studio' }
    ]
  }
];
