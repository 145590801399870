// src/app/components/records/tables-draw.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import * as d3 from 'd3';
import { TableModel, ColSchema } from '../../models/tables.model';
import { MemoryItem } from '../../models/memory-item.model';
import { TooltipService } from './tooltip.service';

export interface TableRenderData {
  id: string;
  tableId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: 'table' | 'column' | 'memory';
  element: any; // D3 selection reference
  data: TableModel | ColSchema | MemoryItem;
}

export interface DrawConfig {
  width: number;
  height: number;
  margin: number;
  tableWidth: number;
  tableHeight: number;
  tablePadding: number;
  tableHeaderHeight: number;
  tableBorderRadius: number;
  colSize: number;
  colMargin: number;
  colsPerRow: number;
  tableMemories?: {[tableId: string]: MemoryItem[]};
  memorySize?: number;
  memoryMargin?: number;
}

@Injectable({
  providedIn: 'root'
})
export class TablesDrawService {
  private svg: any;
  private tooltip: any;
  private tableTooltip: any;
  private columnTooltip: any;
  private memoryTooltip: any; // Added memory tooltip
  private objectsMap: Record<string, TableRenderData> = {};
  private renderer: Renderer2;
  
  // Type colors map
  private typeColorMap: {[key: string]: string} = {
    'string': '#8bc34a',
    'number': '#42a5f5',
    'integer': '#42a5f5',
    'boolean': '#ff9800',
    'date': '#9c27b0',
    'datetime': '#9c27b0',
    'time': '#9c27b0',
    'array': '#f44336',
    'object': '#607d8b'
  };
  
  // Memory type colors map
  private memoryTypeColorMap: Record<string, string> = {
    'data_issue': '#607d8b',      // Blue-gray
    'model_evaluation': '#5c6bc0', // Indigo
    'user_feedback': '#26a69a',    // Teal
    'system_alert': '#ef5350',     // Light red
    'performance_metric': '#66bb6a', // Green
    'default': '#90a4ae'           // Light blue-gray
  };
  
  private defaultConfig: DrawConfig = {
    width: 960,
    height: 600,
    margin: 20,
    tableWidth: 220,
    tableHeight: 80,
    tablePadding: 15,
    tableHeaderHeight: 25,
    tableBorderRadius: 10,
    colSize: 4,
    colMargin: 4,
    colsPerRow: 25,
    memorySize: 8,
    memoryMargin: 4
  };
  
  constructor(
    rendererFactory: RendererFactory2,
    private tooltipService: TooltipService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  
  /**
   * Initialize the SVG container for drawing
   */
  public initializeSvg(selector: string, width: number, height: number, margin: number): void {
    this.defaultConfig.width = width;
    this.defaultConfig.height = height;
    this.defaultConfig.margin = margin;
    
    // Remove any existing SVG
    d3.select(selector + ' svg').remove();
    
    // Create the SVG element
    this.svg = d3.select(selector)
      .append('svg')
      .attr('width', width + margin * 2)
      .attr('height', height + margin * 2)
      .style('display', 'block')
      .style('margin', '0 auto'); // Center the SVG horizontally
    
    // Create custom tooltips using the tooltip service
    this.createCustomTooltip();
  }
  
  /**
   * Set the SVG element
   */
  public setSvg(svg: any): void {
    this.svg = svg;
    
    // Create custom tooltips using the tooltip service
    this.createCustomTooltip();
  }

  /**
   * Create custom tooltip elements
   */
  private createCustomTooltip(): void {
    // Create separate tooltips for tables, columns, and memories
    this.tableTooltip = this.tooltipService.createTooltip('table');
    this.columnTooltip = this.tooltipService.createTooltip('table');
    this.memoryTooltip = this.tooltipService.createTooltip('memory'); // Added memory tooltip
  }
  
  /**
   * Draw tables based on provided data
   */
  public drawTables(tablesList: TableModel[], config: Partial<DrawConfig> = {}): void {
    // Clear existing content
    this.clear();
    
    // Ensure tableMemories is a valid object
    config.tableMemories = config.tableMemories || {};

    // Merge provided config with defaults
    const drawConfig = { ...this.defaultConfig, ...config };
    
    if (!tablesList.length) {
      this.svg.append('text')
        .attr('x', drawConfig.width / 2)
        .attr('y', drawConfig.height / 2)
        .attr('text-anchor', 'middle')
        .text('No tables available');
      return;
    }
    
    // Vertical layout with 15px margin between tables
    const verticalMargin = 15;
    
    // Calculate required height for all tables and adjust SVG if necessary
    let totalHeight = 0;
    
    // First pass to estimate heights with memory items
    tablesList.forEach(table => {
      // Generate ID for height calculation
      const tableId = table.id.toLowerCase();
      
      // Get memories for this table
      const tableMemories = drawConfig.tableMemories?.[tableId] || [];
      
      // Base height is the table height
      let tableHeight = drawConfig.tableHeight;
      
      // Add height for memory items if needed
      if (tableMemories.length > 0) {
        // Calculate rows needed for memories
        const memoryItemsPerRow = Math.floor((drawConfig.tableWidth - 20) / (drawConfig.memorySize! + drawConfig.memoryMargin!));
        const memoryRows = Math.ceil(tableMemories.length / memoryItemsPerRow);
        
        // Add space for memory rows plus padding
        tableHeight += (memoryRows * (drawConfig.memorySize! + drawConfig.memoryMargin!)) + 15;
      }
      
      totalHeight += tableHeight + verticalMargin;
    });
    
    totalHeight -= verticalMargin; // Remove extra margin
    totalHeight += 2 * drawConfig.margin; // Add margin
    
    if (totalHeight > drawConfig.height + 2 * drawConfig.margin) {
      // Resize SVG to fit all tables
      this.svg.attr('transform', `translate(${drawConfig.margin},${drawConfig.margin})`)
        .append('rect')
        .attr('width', drawConfig.width)
        .attr('height', totalHeight - 2 * drawConfig.margin)
        .attr('fill', 'none');
      
      // Update parent SVG element height
      d3.select(this.svg.node().parentNode)
        .attr('height', totalHeight);
    }
    
    // Draw each table
    let yPosition = 0;
    tablesList.forEach((table, i) => {
      const adjustedHeight = this.drawTable(table, i, drawConfig, yPosition);
      yPosition += adjustedHeight + verticalMargin;
    });
  }
  
  /**
   * Draw a single table
   */
  private drawTable(
    table: TableModel, 
    index: number, 
    config: DrawConfig, 
    yPosition: number = 0
  ): number {
    // Place tables vertically one under the other
    const x = config.tablePadding;
    const y = yPosition > 0 ? yPosition : index * (config.tableHeight + 15);
    
    // Draw table rectangle
    const tableGroup = this.svg.append('g')
      .attr('class', 'table')
      .attr('transform', `translate(${x},${y})`);
    
    // Determine table color based on database
    const tableColor = '#2196f3'; // Default blue color
    const darkerTableColor = this.darkenColor(tableColor, 0.2); // Darker version for header
    
    // Draw table rectangle with gradient
    const gradientId = `table-gradient-${index}`;
    const defs = this.svg.append('defs');
    const gradient = defs.append('linearGradient')
      .attr('id', gradientId)
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%');
    
    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', tableColor)
      .attr('stop-opacity', 0.2);
      
    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', darkerTableColor)
      .attr('stop-opacity', 0.2);
    
    // Draw table rectangle
    const tableRect = tableGroup.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', config.tableWidth)
      .attr('height', config.tableHeight)
      .attr('rx', config.tableBorderRadius) // Use border radius from config
      .attr('ry', config.tableBorderRadius)
      .attr('fill', `url(#${gradientId})`) // Use gradient
      .attr('stroke', tableColor) // Border color matching the fill
      .attr('stroke-width', 1);
    
    // Add hover effect to the table rectangle
    tableGroup
      .on('mouseover', () => {
        // Highlight the border on hover
        tableRect
          .transition()
          .duration(200)
          .attr('stroke-width', 3); // Increase stroke width on hover
      })
      .on('mouseout', () => {
        // Reset the border on mouseout
        tableRect
          .transition()
          .duration(200)
          .attr('stroke-width', 1); // Reset to original stroke width
      });
    
    // Draw table header
    const headerHeight = config.tableHeaderHeight;
    const headerWidth = config.tableWidth;
    
    // Header background
    tableGroup.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', headerWidth)
      .attr('height', headerHeight)
      .attr('fill', '#2c3e50')  // Darker blue-gray for better contrast with white text
      .attr('rx', config.tableBorderRadius)
      .attr('ry', config.tableBorderRadius);
    
    // Table name
    tableGroup.append('text')
      .attr('x', config.tablePadding)
      .attr('y', headerHeight / 2)
      .attr('dominant-baseline', 'middle')
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
      .attr('fill', '#ffffff')
      .text(this.truncateText(table.name || 'Unnamed Table', 30));
    
    // Generate unique ID for the table
    const tableId = table.id.toLowerCase();
    
    // Get memories for this table
    const tableMemories = config.tableMemories?.[tableId] || [];
    
    // Base height is the config-defined table height
    let adjustedTableHeight = config.tableHeight;
    
    // Store table reference
    this.objectsMap[tableId] = {
      id: tableId,
      tableId: tableId,
      x,
      y,
      width: config.tableWidth,
      height: adjustedTableHeight, // Will be updated if needed
      type: 'table',
      element: tableGroup,
      data: table
    };
    
    // Draw columns
    this.drawTableColumns(tableGroup, table, tableId, config, x, y);
    
    // Draw memory items if any exist
    if (tableMemories.length > 0) {
      const memoryHeight = this.drawTableMemories(tableGroup, tableMemories, tableId, config);
      adjustedTableHeight += memoryHeight;
    }
    
    // Draw outer table rectangle with potentially adjusted height
    tableGroup.insert('rect', ':first-child')
      .attr('class', 'table-rect')
      .attr('width', config.tableWidth)
      .attr('height', adjustedTableHeight)
      .attr('rx', config.tableBorderRadius)
      .attr('ry', config.tableBorderRadius)
      .attr('fill', '#e7edf6')
      .attr('stroke', '#4682B4')
      .attr('stroke-width', 1.5);
    
    // Setup tooltip for table
    this.setupTableTooltip(tableGroup, table);
    
    // Update the table height in the objects map
    this.objectsMap[tableId].height = adjustedTableHeight;
    
    // Add click handler to copy table info to clipboard
    tableGroup.on('click', (event: MouseEvent) => {
      event.stopPropagation(); // Prevent event bubbling
      console.log('Table clicked:', table); // Debug log
      
      // Hide the tooltip
      this.tooltipService.hideTooltip(this.tableTooltip);
      
      // Get full table data to copy
      let contentToCopy = `Table: ${table.name || 'Unnamed Table'}\n`;
      
      if (table.description) {
        contentToCopy += `Description: ${table.description}\n`;
      }
      
      // Get columns from columnGroups if available
      const tableColumns = this.getTableColumns(table.id);
      if (tableColumns && tableColumns.length > 0) {
        contentToCopy += `\nColumns (${tableColumns.length}):\n`;
        tableColumns.forEach((column: any, index: number) => {
          contentToCopy += `${index + 1}. ${column.name || 'Unnamed'} (${column.type || 'Unknown'})`;
          if (column.description) {
            contentToCopy += `: ${column.description}`;
          }
          contentToCopy += '\n';
        });
      }
      
      // Add any other available properties
      const tableObj = table as unknown as Record<string, any>;
      Object.keys(tableObj).forEach(key => {
        // Skip properties we've already handled
        if (!['name', 'description', 'id'].includes(key)) {
          const value = tableObj[key];
          if (value !== undefined && value !== null && typeof value !== 'object') {
            const formattedKey = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
            contentToCopy += `${formattedKey}: ${value}\n`;
          }
        }
      });
      
      // Copy to clipboard
      this.tooltipService.copyToClipboard(contentToCopy)
        .then(success => {
          if (success) {
            this.tooltipService.showNotification('Table info copied!', event);
          } else {
            this.tooltipService.showNotification('Failed to copy table info', event);
          }
        })
        .catch(error => {
          console.error('Error copying table info:', error);
          this.tooltipService.showNotification('Error copying table info', event);
        });
    });
    
    // Return the adjusted height for proper vertical positioning of the next table
    return adjustedTableHeight;
  }
  
  /**
   * Draw columns for a table
   */
  private drawTableColumns(tableGroup: any, table: TableModel, tableId: string, config: DrawConfig, tableX: number, tableY: number): void {
    const colStartY = config.tableHeaderHeight + 5;
    
    // Create columns container
    const colsContainer = tableGroup.append('g')
      .attr('class', 'columns-container');

 
    // Draw columns squares
    table.colSchema.forEach((col, j) => {
      const rowIndex = Math.floor(j / config.colsPerRow);
      const colIndex = j % config.colsPerRow;
      
      const colX = (colIndex * (config.colSize + config.colMargin)) + 10;
      const colY = colStartY + 5 + (rowIndex * (config.colSize + config.colMargin));
      
      // Only draw if it fits in the table height
      if (colY + config.colSize <= config.tableHeight - 5) {
        // Get type-based color
        const normalizedType = this.normalizeType(col.type);
        const typeColor = this.typeColorMap[normalizedType] || '#90a4ae';
        
        const colRect = colsContainer.append('rect')
          .attr('class', 'column-rect')
          .attr('x', colX)
          .attr('y', colY)
          .attr('width', config.colSize)
          .attr('height', config.colSize)
          .attr('fill', typeColor)
          .attr('stroke', '#666')
          .attr('stroke-width', 0.5)
          .attr('data-col-name', col.name)
          .attr('rx', 1)
          .attr('ry', 1);
        
        // Create invisible larger hit area for better hover detection
        const hitArea = colsContainer.append('rect')
          .attr('class', 'column-hit-area')
          .attr('x', colX - 2)
          .attr('y', colY - 2)
          .attr('width', config.colSize + 4)
          .attr('height', config.colSize + 4)
          .attr('fill', 'transparent')
          .attr('pointer-events', 'all')
          .attr('data-col-name', col.name);
        
        // Generate unique ID for the column
        const colId = `${tableId}-col-${col.name.replace(/[^a-zA-Z0-9]/g, '-')}`;
        
        // Store column reference with absolute coordinates
        this.objectsMap[colId] = {
          id: colId,
          tableId: tableId,
          x: colX + tableX,
          y: colY + tableY,
          width: config.colSize,
          height: config.colSize,
          type: 'column',
          element: colRect,
          data: col
        };
        
        // Setup tooltip for column using the hit area
        this.setupColumnTooltip(hitArea, colRect, col, table.name);
      }
    });
    
    // If there are too many columns to show, add a counter
    const maxRowsToShow = Math.floor((config.tableHeight - config.tableHeaderHeight - 10) / (config.colSize + config.colMargin));
    const maxColsToShow = maxRowsToShow * config.colsPerRow;
    
    if (table.colSchema.length > maxColsToShow) {
      tableGroup.append('text')
        .attr('class', 'column-counter')
        .attr('x', config.tableWidth - 10)
        .attr('y', config.tableHeight - 5)
        .attr('text-anchor', 'end')
        .attr('fill', '#666')
        .attr('font-size', '9px')
        .text(`+${table.colSchema.length - maxColsToShow} more columns`);
    }
  }
  
  /**
   * Setup tooltip for column elements
   */
  private setupColumnTooltip(hitArea: any, colRect: any, col: ColSchema, tableName: string): void {
    // Ensure the column tooltip exists
    if (!this.columnTooltip) {
      this.columnTooltip = this.tooltipService.createTooltip('table');
    }
    
    hitArea
      .on('mouseenter', (event: MouseEvent) => {
        // Show tooltip on hover
        const content = this.generateColumnTooltip(col, tableName);
        this.tooltipService.showTooltip(content, event, this.columnTooltip);
      })
      .on('mouseleave', () => {
        // Hide tooltip when mouse leaves
        this.tooltipService.hideTooltip(this.columnTooltip);
      })
      // Add click handler to copy column info to clipboard
      .on('click', (event: MouseEvent) => {
        event.stopPropagation(); // Prevent event bubbling
        console.log('Column clicked:', col); // Debug log
        
        // Hide the tooltip
        this.tooltipService.hideTooltip(this.columnTooltip);
        
        // Get full column data to copy
        let contentToCopy = `Column: ${col.name || 'Unnamed Column'}\n`;
        contentToCopy += `Type: ${col.type || 'Unknown'}\n`;
        
        if (col.description) {
          contentToCopy += `Description: ${col.description}\n`;
        }
        
        if (tableName) {
          contentToCopy += `Table: ${tableName}\n`;
        }
        
        // Add any other available properties
        const columnObj = col as unknown as Record<string, any>;
        Object.keys(columnObj).forEach(key => {
          // Skip properties we've already handled
          if (!['name', 'type', 'description'].includes(key)) {
            const value = columnObj[key];
            if (value !== undefined && value !== null && typeof value !== 'object') {
              const formattedKey = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
              contentToCopy += `${formattedKey}: ${value}\n`;
            }
          }
        });
        
        // Copy to clipboard
        this.tooltipService.copyToClipboard(contentToCopy)
          .then(success => {
            if (success) {
              this.tooltipService.showNotification('Column info copied!', event);
            } else {
              this.tooltipService.showNotification('Failed to copy column info', event);
            }
          })
          .catch(error => {
            console.error('Error copying column info:', error);
            this.tooltipService.showNotification('Error copying column info', event);
          });
      });
  }
  
  /**
   * Draw a line connecting two objects
   */
  public drawConnection(sourceId: string, targetId: string, options: {
    color?: string;
    strokeWidth?: number;
    dashArray?: string;
    markerEnd?: boolean;
  } = {}): void {
    const source = this.objectsMap[sourceId];
    const target = this.objectsMap[targetId];
    
    if (!source || !target) {
      console.error('Cannot draw connection: objects not found', { sourceId, targetId });
      return;
    }
    
    // Default options
    const lineColor = options.color || '#666';
    const lineWidth = options.strokeWidth || 1;
    const dashArray = options.dashArray || null;
    
    // Calculate start and end points
    const startX = source.x + source.width / 2;
    const startY = source.y + source.height / 2;
    const endX = target.x + target.width / 2;
    const endY = target.y + target.height / 2;
    
    // Create line
    const line = this.svg.append('line')
      .attr('x1', startX)
      .attr('y1', startY)
      .attr('x2', endX)
      .attr('y2', endY)
      .attr('stroke', lineColor)
      .attr('stroke-width', lineWidth);
    
    if (dashArray) {
      line.attr('stroke-dasharray', dashArray);
    }
    
    // Add arrow marker if needed
    if (options.markerEnd) {
      // Create marker definition if it doesn't exist
      const markerId = 'arrow-marker';
      if (!this.svg.select(`#${markerId}`).node()) {
        this.svg.append('defs').append('marker')
          .attr('id', markerId)
          .attr('viewBox', '0 -5 10 10')
          .attr('refX', 8)
          .attr('refY', 0)
          .attr('markerWidth', 6)
          .attr('markerHeight', 6)
          .attr('orient', 'auto')
          .append('path')
          .attr('d', 'M0,-5L10,0L0,5')
          .attr('fill', lineColor);
      }
      
      line.attr('marker-end', `url(#${markerId})`);
    }
  }
  
  /**
   * Get object by ID
   */
  public getObject(id: string): TableRenderData | null {
    return this.objectsMap[id] || null;
  }
  
  /**
   * Get all objects
   */
  public getAllObjects(): Record<string, TableRenderData> {
    return this.objectsMap;
  }
  
  /**
   * Find object by predicate
   */
  public findObject(predicate: (obj: TableRenderData) => boolean): TableRenderData | null {
    const key = Object.keys(this.objectsMap).find(k => predicate(this.objectsMap[k]));
    return key ? this.objectsMap[key] : null;
  }
  
  /**
   * Find objects by table ID
   */
  public findObjectsByTableId(tableId: string): TableRenderData[] {
    return Object.values(this.objectsMap).filter(obj => obj.tableId === tableId);
  }
  
  /**
   * Highlight an object
   */
  public highlightObject(id: string, options: { color?: string; duration?: number } = {}): void {
    const obj = this.objectsMap[id];
    if (!obj) return;
    
    const color = options.color || '#ff0000';
    const duration = options.duration || 2000;
    
    if (obj.type === 'table') {
      obj.element.select('.table-rect')
        .transition()
        .duration(200)
        .attr('stroke', color)
        .attr('stroke-width', 3)
        .transition()
        .delay(duration)
        .duration(200)
        .attr('stroke', '#4682B4')
        .attr('stroke-width', 1.5);
    } else if (obj.type === 'column') {
      obj.element
        .transition()
        .duration(200)
        .attr('stroke', color)
        .attr('stroke-width', 2)
        .transition()
        .delay(duration)
        .duration(200)
        .attr('stroke', '#666')
        .attr('stroke-width', 0.5);
    }
  }
  
  /**
   * Dim all tables except those with the specified IDs
   * @param exceptIds IDs of tables to keep highlighted
   */
  public dimAllExcept(exceptIds: string[]): void {
    // Find all table elements
    const allTables = this.svg.selectAll('.table-group');
    
    // Apply dimming to all tables not in the exceptIds list
    allTables.each((d: any, i: number, nodes: any) => {
      const tableNode = d3.select(nodes[i]);
      const tableId = d.id;
      
      if (exceptIds.includes(tableId)) {
        // Highlight this table
        tableNode
          .style('display', 'block')
          .transition()
          .duration(300)
          .style('opacity', 1)
          .style('filter', 'drop-shadow(0 0 5px rgba(66, 133, 244, 0.5))');
      } else {
        // Hide this table completely
        tableNode
          .transition()
          .duration(300)
          .style('opacity', 0)
          .on('end', function() {
            d3.select(this).style('display', 'none');
          });
      }
    });
  }
  
  /**
   * Reset all table highlights
   */
  public resetHighlights(): void {
    // Find all table elements
    const allTables = this.svg.selectAll('.table-group');
    
    // Reset all tables to normal
    allTables
      .style('display', 'block')
      .transition()
      .duration(300)
      .style('opacity', 1)
      .style('filter', null);
  }
  
  /**
   * Truncate text that's too long
   */
  private truncateText(text: string, maxLength: number): string {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  }
  
  /**
   * Normalize column type
   */
  private normalizeType(type: string): string {
    // Normalize the type to match our predefined categories
    const lowerType = type.toLowerCase().trim();
    
    if (lowerType.includes('string') || lowerType.includes('char') || lowerType.includes('text')) {
      return 'string';
    } else if (lowerType.includes('int')) {
      return 'integer';
    } else if (lowerType.includes('float') || lowerType.includes('double') || lowerType.includes('decimal') || lowerType.includes('number')) {
      return 'number';
    } else if (lowerType.includes('bool')) {
      return 'boolean';
    } else if (lowerType.includes('date') && lowerType.includes('time')) {
      return 'datetime';
    } else if (lowerType.includes('date')) {
      return 'date';
    } else if (lowerType.includes('time')) {
      return 'time';
    } else if (lowerType.includes('array') || lowerType.includes('[]')) {
      return 'array';
    } else if (lowerType.includes('object') || lowerType.includes('json')) {
      return 'object';
    }
    
    return 'other';
  }
  
  /**
   * Generate table tooltip content
   */
  private generateTableTooltip(table: any): string {
    let html = '';
    
    // Add header with table name
    const tableName = table.name || 'Unnamed Table';
    html += this.tooltipService.formatHeader(tableName, 'table');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available
    if (table.description) {
      mainContent += `<div class="tooltip-description">${table.description}</div>`;
    }
    
    // Add table details section
    let detailsContent = '';
    
    // Add table type if available
    if (table.type) {
      detailsContent += this.tooltipService.formatProperty('Type', table.type);
    }
    
    // Add row count if available
    if (table.rowCount !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Rows', table.rowCount.toLocaleString());
    }
    
    // Add column count if available
    if (table.columns && table.columns.length) {
      detailsContent += this.tooltipService.formatProperty('Columns', table.columns.length.toLocaleString());
    }
    
    // Add size if available
    if (table.size !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Size', this.formatSize(table.size));
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add schema section if we have columns
    if (table.columns && table.columns.length) {
      let schemaContent = '';
      
      // Add up to 5 columns
      const columnsToShow = table.columns.slice(0, 5);
      columnsToShow.forEach((column: { name: string, type: string }) => {
        schemaContent += this.tooltipService.formatProperty(column.name, column.type);
      });
      
      // Add ellipsis if there are more columns
      if (table.columns.length > 5) {
        schemaContent += `<div style="text-align: center; margin-top: 5px; color: rgba(162, 160, 162, 0.7);">+ ${table.columns.length - 5} more columns</div>`;
      }
      
      // Add schema section
      mainContent += this.tooltipService.formatSection(schemaContent);
    }
    
    // Add main content
    html += mainContent;
    
    // Add footer with ID if available
    if (table.id) {
      html += this.tooltipService.formatFooter(`ID: ${table.id}`);
    }
    
    return html;
  }
  
  /**
   * Generate column tooltip content
   */
  private generateColumnTooltip(col: ColSchema, tableName: string): string {
    // Create a formatted tooltip for column
    let html = `
      <div style="font-weight: bold; font-size: 14px; margin-bottom: 8px; color: #4682B4;">
        ${col.name}
      </div>
      <div><strong>Table:</strong> ${tableName}</div>
      <div><strong>Type:</strong> ${col.type}</div>
    `;
    
    if (col.description) {
      const truncatedDescription = col.description.length > 120 ? col.description.substring(0, 120) + '...' : col.description;
      html += `<div><strong>Description:</strong> ${truncatedDescription}</div>`;
    }
    
    if (col.tags && col.tags.length) {
      html += `<div><strong>Tags:</strong> ${col.tags.join(', ')}</div>`;
    }
    
    if (col.resolveNames && col.resolveNames.length) {
      html += `<div><strong>Resolve Names:</strong> ${col.resolveNames.join(', ')}</div>`;
    }
    
    if (col.insights && col.insights.length) {
      html += `
        <div style="margin-top: 5px;"><strong>Insights:</strong></div>
        <ul style="margin: 5px 0 0 15px; padding: 0;">
          ${col.insights.map(insight => 
            `<li style="margin-bottom: 3px;"><span style="font-size: 12px;">${insight.description}${insight.importance ? ` (Importance: ${insight.importance})` : ''}</span></li>`
          ).join('')}
        </ul>
      `;
    }
    
    return html;
  }
  
  /**
   * Draw memory items for a table
   */
  private drawTableMemories(
    tableGroup: any, 
    memories: MemoryItem[], 
    tableId: string, 
    config: DrawConfig
  ): number {
    if (!memories || memories.length === 0) {
      return 0;
    }
    
    // Create a section for memory items below the columns
    const memoryStartY = config.tableHeight;
    
    // Add a separator line
    tableGroup.append('line')
      .attr('x1', 10)
      .attr('y1', memoryStartY)
      .attr('x2', config.tableWidth - 10)
      .attr('y2', memoryStartY)
      .attr('stroke', '#ccc')
      .attr('stroke-dasharray', '2,2');
    
    // Add a "Memories" label
    tableGroup.append('text')
      .attr('class', 'memories-label')
      .attr('x', 15)
      .attr('y', memoryStartY + 12)
      .attr('font-size', '8px')
      .attr('font-weight', 'bold')
      .attr('fill', '#666')
      .text('Memories:');
    
    // Create memories container
    const memoriesContainer = tableGroup.append('g')
      .attr('class', 'memories-container')
      .attr('transform', `translate(0,${memoryStartY + 20})`);
    
    // Calculate how many memory items can fit per row
    const memorySize = config.memorySize!;
    const memoryMargin = config.memoryMargin!;
    const memoriesPerRow = Math.floor((config.tableWidth - 20) / (memorySize + memoryMargin));
    
    // Draw each memory item
    memories.forEach((memory, index) => {
      const rowIndex = Math.floor(index / memoriesPerRow);
      const colIndex = index % memoriesPerRow;
      
      const memoryX = colIndex * (memorySize + memoryMargin) + 15;
      const memoryY = rowIndex * (memorySize + memoryMargin);
      
      // Get memory type color
      const memoryColor = this.getMemoryTypeColor(memory.type);
      
      // Generate unique ID for the memory
      const memoryId = `${tableId}-memory-${memory.memory_id}`;
      
      // Create hexagon points
      const hexRadius = memorySize / 2;
      const hexPoints = this.calculateHexagonPoints(
        memoryX + hexRadius,
        memoryY + hexRadius,
        hexRadius
      );
      
      // Create gradient for memory
      const gradientId = `memory-gradient-${Math.random().toString(36).substring(2, 9)}`;
      
      // Create gradient definition
      const defs = this.svg?.append('defs');
      const gradient = defs?.append('radialGradient')
        .attr('id', gradientId)
        .attr('cx', '50%')
        .attr('cy', '50%')
        .attr('r', '50%')
        .attr('fx', '50%')
        .attr('fy', '50%');
        
      gradient?.append('stop')
        .attr('offset', '0%')
        .attr('stop-color', d3.color(memoryColor)?.brighter(0.5)?.toString() || memoryColor);
        
      gradient?.append('stop')
        .attr('offset', '100%')
        .attr('stop-color', memoryColor);
      
      // Draw memory as hexagon with gradient
      const memoryHexagon = memoriesContainer.append('polygon')
        .attr('class', 'memory-hexagon')
        .attr('points', hexPoints)
        .attr('fill', `url(#${gradientId})`)
        .attr('stroke', d3.color(memoryColor)?.darker(0.5)?.toString() || '#666')
        .attr('stroke-width', 1.5);
      
      
      // Store memory reference with absolute coordinates
      this.objectsMap[memoryId] = {
        id: memoryId,
        tableId: tableId,
        x: memoryX + parseInt(tableGroup.attr('transform').split('(')[1].split(',')[0], 10),
        y: memoryY + parseInt(tableGroup.attr('transform').split('(')[1].split(',')[1], 10) + memoryStartY + 20,
        width: memorySize,
        height: memorySize,
        type: 'memory',
        element: memoryHexagon,
        data: memory
      };
      
      // Setup tooltip for memory
      this.setupMemoryTooltip(memoryHexagon, memory);
    });
    
    // Calculate total height for memory section: separator + label + memory rows
    const totalRows = Math.ceil(memories.length / memoriesPerRow);
    const memoryHeight = 20 + // Space for separator and label
      (totalRows * (memorySize + memoryMargin)) + // Space for memory rows
      5; // Extra padding at bottom
    
    return memoryHeight;
  }
  
  /**
   * Calculate points for a hexagon
   */
  private calculateHexagonPoints(centerX: number, centerY: number, radius: number): string {
    const points = [];
    const angleOffset = Math.PI / 6; // 30 degrees offset
    
    for (let i = 0; i < 6; i++) {
      const angle = (i * Math.PI / 3) + angleOffset;
      const x = centerX + radius * Math.sin(angle);
      const y = centerY + radius * Math.cos(angle);
      points.push(`${x},${y}`);
    }
    
    // Close the path by returning to the first point
    points.push(points[0]);
    
    return points.join(' ');
  }
  
  /**
   * Setup tooltip for memory items
   */
  private setupMemoryTooltip(element: any, memory: MemoryItem): void {
    // Ensure the custom tooltip exists
    if (!this.memoryTooltip) {
      this.memoryTooltip = this.tooltipService.createTooltip('memory');
    }
    
    element
      .on('mouseover', (event: MouseEvent) => {
        event.stopPropagation(); // Prevent parent tooltips
        
        this.tooltipService.showTooltip(
          this.memoryTooltip,
          event,
          this.generateMemoryTooltip(memory)
        );
        
        // Highlight the memory circle
        element
          .attr('stroke', '#000')
          .attr('stroke-width', 1.5);
      })
      .on('mouseout', () => {
        this.tooltipService.hideTooltip(this.memoryTooltip);
        
        // Reset memory style
        element
          .attr('stroke', '#666')
          .attr('stroke-width', 0.5);
      })
      .on('mousemove', (event: MouseEvent) => {
        this.tooltipService.moveTooltip(this.memoryTooltip, event);
      });
  }
  
  /**
   * Get the color for a memory type
   */
  private getMemoryTypeColor(type: string): string {
    return this.memoryTypeColorMap[type] || this.memoryTypeColorMap['default'];
  }
  
  /**
   * Generate memory tooltip content
   */
  private generateMemoryTooltip(memory: MemoryItem): string {
    let content = '';
    
    // Get type label with proper capitalization
    const typeLabel = memory.type ? 
      memory.type.charAt(0).toUpperCase() + memory.type.slice(1).replace('_', ' ') : 
      'Unknown';
    
    // Add header with memory type
    content += this.tooltipService.formatHeader(`${typeLabel} Memory`, 'memory');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available (observation or insight)
    if (memory.observation) {
      mainContent += `<div class="tooltip-description">${memory.observation}</div>`;
    } else if (memory.insight) {
      mainContent += `<div class="tooltip-description">${memory.insight}</div>`;
    }
    
    // Add memory details section
    let detailsContent = '';
    
    // Add memory properties
    if (memory.memory_id) {
      detailsContent += this.tooltipService.formatProperty('ID', memory.memory_id.substring(0, 8) + '...');
    }
    
    if (memory.importance !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Importance', `${memory.importance}/10`);
    }
    
    if (memory.reliability !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Reliability', `${memory.reliability}/10`);
    }
    
    if (memory.created_date) {
      const date = new Date(memory.created_date);
      detailsContent += this.tooltipService.formatProperty('Created', date.toLocaleString());
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add context section if available
    if (memory.context && memory.context.description) {
      let contextContent = this.tooltipService.formatProperty('Description', memory.context.description);
      
      // Add business dimensions if any
      if (memory.context.business_dimensions && memory.context.business_dimensions.length) {
        memory.context.business_dimensions.forEach(dim => {
          contextContent += this.tooltipService.formatProperty(dim.dimension_type, dim.value);
        });
      }
      
      mainContent += this.tooltipService.formatSection(contextContent, 'Context');
    }
    
    // Add analysis section if available
    if (memory.analysis && memory.analysis.length) {
      let analysisContent = '';
      
      memory.analysis.forEach(analysis => {
        let value = analysis.value !== undefined ? analysis.value : '';
        if (analysis.unit) {
          value += ` ${analysis.unit}`;
        }
        
        analysisContent += this.tooltipService.formatProperty(analysis.metric, value);
      });
      
      mainContent += this.tooltipService.formatSection(analysisContent, 'Analysis');
    }
    
    // Add the main content
    content += mainContent;
    
    return content;
  }
  
  /**
   * Get the SVG element
   */
  public getSvg(): any {
    return this.svg;
  }
  
  /**
   * Get the tooltip element
   */
  public getTooltip(): any {
    return this.tableTooltip;
  }
  
  /**
   * Clear the SVG and objects map
   */
  public clear(): void {
    // if (this.svg) {
    //   this.svg.selectAll('*').remove();
    // }
    this.objectsMap = {};
  }

  /**
   * Format size in bytes to a human-readable format
   */
  private formatSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }
  
  /**
   * Darken a color by a specified amount
   */
  private darkenColor(color: string, amount: number = 0.2): string {
    // Parse the color to RGB
    let r, g, b;
    if (color.startsWith('#')) {
      const hex = color.substring(1);
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    } else if (color.startsWith('rgb')) {
      const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/);
      if (match) {
        r = parseInt(match[1], 10);
        g = parseInt(match[2], 10);
        b = parseInt(match[3], 10);
      } else {
        return color; // Return original if can't parse
      }
    } else {
      return color; // Return original if not hex or rgb
    }
    
    // Darken each component
    r = Math.max(0, Math.floor(r * (1 - amount)));
    g = Math.max(0, Math.floor(g * (1 - amount)));
    b = Math.max(0, Math.floor(b * (1 - amount)));
    
    // Convert back to hex
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }

  private getTableColumns(tableId: string): any[] {
    const tableObj = this.objectsMap[tableId];
    if (tableObj && tableObj.data && 'colSchema' in tableObj.data) {
      return tableObj.data.colSchema || [];
    }
    return [];
  }

  /**
   * Setup tooltip for table elements
   */
  private setupTableTooltip(element: any, table: TableModel): void {
    // Ensure the table tooltip exists
    if (!this.tableTooltip) {
      this.tableTooltip = this.tooltipService.createTooltip('table');
    }
    
    element
      .on('mouseenter', (event: MouseEvent) => {
        // Show tooltip on hover
        const content = this.generateTableTooltip(table);
        this.tooltipService.showTooltip(content, event, this.tableTooltip);
      })
      .on('mouseleave', () => {
        // Hide tooltip when mouse leaves
        this.tooltipService.hideTooltip(this.tableTooltip);
      })
      .on('click', (event: MouseEvent) => {
        event.stopPropagation(); // Prevent event bubbling
        console.log('Table clicked:', table); // Debug log
        
        // Hide the tooltip
        this.tooltipService.hideTooltip(this.tableTooltip);
        
        // Get full table data to copy
        let contentToCopy = `Table: ${table.name || 'Unnamed Table'}\n`;
        
        if (table.description) {
          contentToCopy += `Description: ${table.description}\n`;
        }
        
        // Add columns if available
        if (table.colSchema && table.colSchema.length > 0) {
          contentToCopy += `\nColumns (${table.colSchema.length}):\n`;
          table.colSchema.forEach((col, index) => {
            contentToCopy += `${index + 1}. ${col.name} (${col.type || 'Unknown'})`;
            if (col.description) {
              contentToCopy += `: ${col.description}`;
            }
            contentToCopy += '\n';
          });
        }
        
        // Add any other available properties
        const tableObj = table as unknown as Record<string, any>;
        Object.keys(tableObj).forEach(key => {
          // Skip properties we've already handled
          if (!['name', 'description', 'colSchema'].includes(key)) {
            const value = tableObj[key];
            if (value !== undefined && value !== null && typeof value !== 'object') {
              const formattedKey = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
              contentToCopy += `${formattedKey}: ${value}\n`;
            }
          }
        });
        
        // Copy to clipboard
        this.tooltipService.copyToClipboard(contentToCopy)
          .then(success => {
            if (success) {
              this.tooltipService.showNotification('Table info copied!', event);
            } else {
              this.tooltipService.showNotification('Failed to copy table info', event);
            }
          })
          .catch(error => {
            console.error('Error copying table info:', error);
            this.tooltipService.showNotification('Error copying table info', event);
          });
      });
  }
}