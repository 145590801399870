<div class="app-container relative">
  <app-header></app-header>
  
  <div class="main-container">
    <app-splitter orientation="vertical" class="main-splitter">
      <app-splitter-pane>
        <app-splitter>
          <!-- Left sidebar with tabs -->
          <app-splitter-pane size="270px" [containsSessionsGrid]="true">
            <app-sessions-grid></app-sessions-grid>
          </app-splitter-pane>

          <!-- Chat pane -->
          <app-splitter-pane size="25%">
            <div class="chat-container">
              <app-chat [sessionId]="selectedSessionId"></app-chat>
            </div>
          </app-splitter-pane>

          <!-- Workspace pane -->
          <app-splitter-pane>
            <app-splitter orientation="vertical">
              <!-- Main workspace area -->
              <app-splitter-pane size="65%">
                <div class="workspace-container">
                  <app-workboard></app-workboard>
                </div>
              </app-splitter-pane>
              <!-- Bottom bar -->
              <app-splitter-pane size="35%">
                <div class="bottom-bar">
                  <app-datapane></app-datapane>
                </div>
              </app-splitter-pane>
            </app-splitter>
          </app-splitter-pane>
        </app-splitter>
      </app-splitter-pane>
    </app-splitter>
  </div>
</div>