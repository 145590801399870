/**
 * Types and enums for the chat system
 */
import { Artifact } from './artifact.model';
import { StateItem } from './workspace-state.model';

export enum SessionStatus {
  Active = 'active',
  Paused = 'paused',
  Completed = 'completed',
  Archived = 'archived'
}

export enum MessageRole {
  User = 'user',
  Agent = 'agent',
  System = 'system',
  Process = 'process'
}

export interface ChatTurn {
  id: string;
  sessionId: string;
  content: any;
  role: MessageRole;
  senderId: string;
  senderPlaybookId?: string;
  senderDTeamId?: string;
  timestamp: Date;
  artifacts: Artifact[];
  metadata?: Record<string, unknown>;
  status?: 'pending' | 'sent' | 'error';
  turnMetadata: {
    turnData?: any;
    turnNumber: number;
    processingTime?: number;
    completionTokens?: number;
    promptTokens?: number;
    totalTokens?: number;
    modelName?: string;
    additionalContext?: Record<string, unknown>;
  };
}

export interface AgentTurnBase {
  sessionName: string;
  sessionDescription: string;
  messageToUser: string;
}

interface SessionPlaybook {
  id: string;
  name?: string;
  description?: string;
}

export interface ChatSession {
  id: string;
  title: string;
  status: SessionStatus;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
  participants: {
    users: string[];
    agents: string[];
  };
  metadata?: Record<string, unknown>;
  lastMessage?: ChatTurn;
  turns: ChatTurn[];
  
  // New fields
  sessionPlaybook: SessionPlaybook;
  relatedWorkstreamIds?: string[];
  goal?: string;
  whyRelevant?: string;
  detailedContext?: string;
  DTeamID?: string;
  DTeamName?: string;
  stateMap: { [key: string]: StateItem }; // Object as map
}

export interface ChatContext {
  tablesCollectionStr: string;
  workboardPrompt: string;
  lastMessagesMeta: string;
  lastUserMessage: string;
  lastSessionsMeta: string;
  suggestedWorkstreams: string;
  suggestedSessions: string;
  sessionName: string;
  sessionDescription: string;
  sessionLongContext: string;
  sessionWorkstreams: string;
}