import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { ProjectData } from '../../models/project.interface';
import { Session } from '../../models/session.model';
import { ElementsLinkService } from './elements-link.service';
import { MemoryItem } from '../../models/memory-item.model';
import { TooltipService } from './tooltip.service';

export interface SessionRenderConfig {
  containerWidth: number;
  sessionRectHeight: number;
  sessionMargin: number;
  fontSize: number;
  maxTextLength: number;
  sessionMemories?: {[sessionId: string]: MemoryItem[]};
  memoryTypeColorMap?: {[type: string]: string};
  memorySize?: number;
  memoryMargin?: number;
}

@Injectable({
  providedIn: 'root'
})
export class SessionsDrawService {
  private sessionTooltip: any; // Dedicated tooltip for sessions
  private memoryTooltip: any; // Dedicated tooltip for memories
  
  // Session color - specific color for sessions
  private sessionColor: string = '#5c6bc0'; // Updated to a more modern indigo
  
  private defaultConfig: SessionRenderConfig = {
    containerWidth: 140,
    sessionRectHeight: 16, // Slightly increased height for better readability 
    sessionMargin: 3, // Increased margin between sessions
    fontSize: 8,
    maxTextLength: 25
  };
  
  constructor(
    private elementsLinkService: ElementsLinkService,
    private tooltipService: TooltipService
  ) {
    // Create dedicated tooltips
    this.sessionTooltip = this.tooltipService.createTooltip('session');
    this.memoryTooltip = this.tooltipService.createTooltip('memory');
  }
  
  /**
   * Set tooltip reference
   */
  public setTooltip(tooltip: any): void {
    // Removed
  }
  
  /**
   * Render sessions inside a project container
   * @param projectGroup D3 selection of the project group element
   * @param projectData Project data containing sessions
   * @param containerX X position for the sessions container
   * @param containerY Y position for the sessions container
   * @param config Optional rendering configuration
   * @returns Height of the rendered sessions container
   */
  public renderSessions(
    projectGroup: any,
    projectData: ProjectData,
    containerX: number,
    containerY: number,
    config: Partial<SessionRenderConfig> = {}
  ): number {
    // Merge provided config with defaults
    const renderConfig = { ...this.defaultConfig, ...config };
    
    if (!projectData.sessions || !projectData.sessions.length) {
      return 0; // No sessions to render
    }
    
    // Create a container group for all sessions
    const sessionsGroup = projectGroup.append('g')
      .attr('class', 'sessions-container')
      .attr('transform', `translate(${containerX},${containerY})`);
    
    // Add container title
    sessionsGroup.append('text')
      .attr('class', 'sessions-title')
      .attr('x', renderConfig.containerWidth / 2)
      .attr('y', 0) // Position at top
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'hanging') // Align to top
      .attr('font-size', `${renderConfig.fontSize + 1}px`)
      .attr('font-weight', 'bold')
      .attr('fill', '#333')
      .text('Sessions');
    
    // Track the total height as we add elements
    const titleHeight = 15; // Estimated title height including margin
    let totalHeight = titleHeight; // Start with padding for the title
    
    // Define memory styling properties
    const memorySize = renderConfig.memorySize || 10; // Increased size for better visibility
    const memoryMargin = renderConfig.memoryMargin || 4;
    
    // Render each session with its memory items
    projectData.sessions.forEach((session, index) => {
      // Current Y position for this session
      let currentY = totalHeight;
      
      // Session group
      const sessionGroup = sessionsGroup.append('g')
        .attr('class', 'session-item')
        .attr('transform', `translate(0,${currentY})`);
      
      // Get memories associated with this session
      const sessionMemories = renderConfig.sessionMemories?.[session.id.toLowerCase()] || [];
      let memoryHeight = 0;
      
      // Calculate memory height if there are any memories
      if (sessionMemories.length > 0) {
        // Calculate how many memory items can fit per row
        const memoriesPerRow = Math.floor((renderConfig.containerWidth - 10) / (memorySize + memoryMargin));
        
        // Calculate how many rows we need
        const memoryRows = Math.ceil(sessionMemories.length / memoriesPerRow);
        memoryHeight = (memoryRows * (memorySize + memoryMargin)) + 10; // Extra padding at bottom
      }
      
      // Session rectangle - height depends on whether we have memories
      const sessionRect = sessionGroup.append('rect')
        .attr('class', 'session-rect')
        .attr('width', renderConfig.containerWidth)
        .attr('height', renderConfig.sessionRectHeight + (sessionMemories.length > 0 ? memoryHeight : 0))
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('fill', this.getStatusColor(session.completionStatus))
        .attr('stroke', this.sessionColor)
        .attr('stroke-width', 0.5)
        .style('cursor', 'pointer') // Add pointer cursor
        .on('click', (event: MouseEvent) => {
          event.stopPropagation(); // Prevent event bubbling
          console.log('Session clicked:', session); // Debug log
          
          // Hide tooltip if visible
          this.tooltipService.hideTooltip(this.sessionTooltip);
          
          // Get full session data to copy
          let contentToCopy = `Session ID: ${session.session || 'N/A'}\n`;
          contentToCopy += `Name: ${session.name || 'Unnamed Session'}\n`;
          
          if (session.description) {
            contentToCopy += `Description: ${session.description}\n`;
          }
          
          if (session.createdDate) {
            const date = new Date(session.createdDate);
            contentToCopy += `Created: ${date.toLocaleString()}\n`;
          }
          
          // Add memory count
          contentToCopy += `Memory Count: ${sessionMemories.length}\n`;
          
          // Add any other available properties
          const sessionObj = session as unknown as Record<string, any>;
          Object.keys(sessionObj).forEach(key => {
            // Skip properties we've already handled
            if (!['session', 'name', 'description', 'createdDate'].includes(key)) {
              const value = sessionObj[key];
              if (value !== undefined && value !== null && typeof value !== 'object') {
                const formattedKey = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
                contentToCopy += `${formattedKey}: ${value}\n`;
              }
            }
          });
          
          // Copy to clipboard
          this.tooltipService.copyToClipboard(contentToCopy)
            .then(success => {
              if (success) {
                this.tooltipService.showNotification('Session info copied!', event);
              } else {
                this.tooltipService.showNotification('Failed to copy session info', event);
              }
            })
            .catch(error => {
              console.error('Error copying session info:', error);
              this.tooltipService.showNotification('Error copying session info', event);
            });
        });
      
      // Session name text
      const sessionText = sessionGroup.append('text')
        .attr('class', 'session-name')
        .attr('x', 5)
        .attr('y', renderConfig.sessionRectHeight / 2)
        .attr('dominant-baseline', 'middle')
        .attr('fill', this.getTextColor(session.completionStatus))
        .attr('font-size', `${renderConfig.fontSize}px`)
        .attr('font-weight', 'bold') // Make session name bold
        .text(this.truncateText(session.name, renderConfig.maxTextLength))
        .style('cursor', 'pointer') // Add pointer cursor
        .on('click', (event: MouseEvent) => {
          event.stopPropagation(); // Prevent event bubbling
          console.log('Session text clicked:', session); // Debug log
          
          // Hide tooltip if visible
          this.tooltipService.hideTooltip(this.sessionTooltip);
          
          // Get full session data to copy
          let contentToCopy = `Session ID: ${session.session || 'N/A'}\n`;
          contentToCopy += `Name: ${session.name || 'Unnamed Session'}\n`;
          
          if (session.description) {
            contentToCopy += `Description: ${session.description}\n`;
          }
          
          if (session.createdDate) {
            const date = new Date(session.createdDate);
            contentToCopy += `Created: ${date.toLocaleString()}\n`;
          }
          
          // Add memory count
          contentToCopy += `Memory Count: ${sessionMemories.length}\n`;
          
          // Add any other available properties
          const sessionObj = session as unknown as Record<string, any>;
          Object.keys(sessionObj).forEach(key => {
            // Skip properties we've already handled
            if (!['session', 'name', 'description', 'createdDate'].includes(key)) {
              const value = sessionObj[key];
              if (value !== undefined && value !== null && typeof value !== 'object') {
                const formattedKey = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
                contentToCopy += `${formattedKey}: ${value}\n`;
              }
            }
          });
          
          // Copy to clipboard
          this.tooltipService.copyToClipboard(contentToCopy)
            .then(success => {
              if (success) {
                this.tooltipService.showNotification('Session info copied!', event);
              } else {
                this.tooltipService.showNotification('Failed to copy session info', event);
              }
            })
            .catch(error => {
              console.error('Error copying session info:', error);
              this.tooltipService.showNotification('Error copying session info', event);
            });
        });
      
      // Draw memory items if there are any
      if (sessionMemories.length > 0) {
        // Add a separator line between session name and memories
        sessionGroup.append('line')
          .attr('x1', 5)
          .attr('y1', renderConfig.sessionRectHeight)
          .attr('x2', renderConfig.containerWidth - 5)
          .attr('y2', renderConfig.sessionRectHeight)
          .attr('stroke', '#ccc')
          .attr('stroke-width', 0.5)
          .attr('stroke-dasharray', '3,2');
        
        // Add a small "Memories" label
        sessionGroup.append('text')
          .attr('x', 7)
          .attr('y', renderConfig.sessionRectHeight + 8)
          .attr('font-size', `${renderConfig.fontSize - 1}px`)
          .attr('fill', '#666')
          .text(`Memories (${sessionMemories.length})`);
        
        // Create memories container
        const memoriesContainer = sessionGroup.append('g')
          .attr('class', 'memories-container')
          .attr('transform', `translate(5,${renderConfig.sessionRectHeight + 12})`);
        
        // Calculate how many memory items can fit per row
        const memoriesPerRow = Math.floor((renderConfig.containerWidth - 10) / (memorySize + memoryMargin));
        
        // Draw each memory item
        sessionMemories.forEach((memory, memIndex) => {
          const rowIndex = Math.floor(memIndex / memoriesPerRow);
          const colIndex = memIndex % memoriesPerRow;
          
          const memoryX = colIndex * (memorySize + memoryMargin);
          const memoryY = rowIndex * (memorySize + memoryMargin);
          
          // Get memory type color
          const memoryColor = this.getMemoryTypeColor(memory.type);
          
          // Create a memory group
          const memoryGroup = memoriesContainer.append('g')
            .attr('class', 'memory-item')
            .attr('transform', `translate(${memoryX},${memoryY})`);
          
          // Create hexagon points
          const hexRadius = memorySize / 2;
          const hexPoints = this.calculateHexagonPoints(
            memorySize/2,
            memorySize/2,
            hexRadius
          );
          
          // Create gradient for memory
          const gradientId = `session-memory-gradient-${Math.random().toString(36).substring(2, 9)}`;
          
          // Create gradient definition
          const defs = projectGroup.append('defs');
          const gradient = defs.append('radialGradient')
            .attr('id', gradientId)
            .attr('cx', '50%')
            .attr('cy', '50%')
            .attr('r', '50%')
            .attr('fx', '50%')
            .attr('fy', '50%');
            
          gradient.append('stop')
            .attr('offset', '0%')
            .attr('stop-color', d3.color(memoryColor)?.brighter(0.5)?.toString() || memoryColor);
            
          gradient.append('stop')
            .attr('offset', '100%')
            .attr('stop-color', memoryColor);
          
          // Draw memory as hexagon with gradient
          const memoryHexagon = memoryGroup.append('polygon')
            .attr('class', 'memory-hexagon')
            .attr('points', hexPoints)
            .attr('fill', `url(#${gradientId})`)
            .attr('stroke', d3.color(memoryColor)?.darker(0.5)?.toString() || '#666')
            .attr('stroke-width', 1.5);
          

          
          // Add tooltip for memory
          this.addMemoryTooltip(memoryHexagon, memory);
        });
      }
      
      // Register with the link service to support connections to other elements
      this.elementsLinkService.registerSession(
        session.session,
        projectData.id,
        sessionGroup,
        session,
        0, // X position relative to the session group
        0, // Y position relative to the session group
        renderConfig.containerWidth,
        renderConfig.sessionRectHeight + memoryHeight
      );
      
      // Setup tooltip for this session
      this.setupSessionTooltip(sessionRect, session);
      this.setupSessionTooltip(sessionText, session);
      
      // Update total height for the next session
      totalHeight += renderConfig.sessionRectHeight + memoryHeight + renderConfig.sessionMargin;
    });
    
    return totalHeight; // Return the total height of the rendered container
  }
  
  /**
   * Get color based on session completion status
   */
  private getStatusColor(status: string): string {
    switch (status) {
      case 'new': return '#e3f2fd'; // Light blue
      case 'opened': return '#e8f5e9'; // Light green
      case 'commit': return '#fff9c4'; // Light yellow
      case 'committed': return '#ffecb3'; // Light amber
      case 'done': return '#d7ccc8'; // Light brown
      default: return '#f5f5f5'; // Light grey
    }
  }
  
  /**
   * Get text color based on session completion status
   */
  private getTextColor(status: string): string {
    switch (status) {
      case 'new': return '#0d47a1'; // Dark blue
      case 'opened': return '#1b5e20'; // Dark green
      case 'commit': return '#f57f17'; // Dark yellow
      case 'committed': return '#e65100'; // Dark amber
      case 'done': return '#4e342e'; // Dark brown
      default: return '#424242'; // Dark grey
    }
  }
  
  /**
   * Setup tooltip for session items
   */
  private setupSessionTooltip(element: any, session: Session): void {
    // Ensure the session tooltip exists
    if (!this.sessionTooltip) {
      this.sessionTooltip = this.tooltipService.createTooltip('session');
    }
    
    element
      .style('cursor', 'pointer')
      .on('mouseover', (event: MouseEvent) => {
        event.stopPropagation(); // Prevent parent tooltips
        
        // Show tooltip using the tooltip service
        this.tooltipService.showTooltip(
          this.sessionTooltip,
          event,
          this.generateSessionTooltip(session)
        );
        
        // Highlight the session rectangle - but don't change stroke
        element
          .attr('stroke-width', 1);
      })
      .on('mouseout', () => {
        // Hide tooltip using the tooltip service
        this.tooltipService.hideTooltip(this.sessionTooltip);
        
        // Reset session style
        element
          .attr('stroke-width', 0.5);
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.sessionTooltip, event);
      });
  }
  
  /**
   * Add tooltip for memory items
   */
  private addMemoryTooltip(element: any, memory: MemoryItem): void {
    if (!this.memoryTooltip) {
      return;
    }
    
    element
      .style('cursor', 'pointer')
      .on('mouseover', (event: MouseEvent) => {
        event.stopPropagation(); // Prevent parent tooltips
        
        // Show tooltip using the tooltip service
        this.tooltipService.showTooltip(
          this.memoryTooltip,
          event,
          this.generateMemoryTooltip(memory)
        );
        
        // Highlight the memory hexagon
        element
          .attr('stroke', '#000')
          .attr('stroke-width', 2);
      })
      .on('mouseout', () => {
        // Hide tooltip using the tooltip service
        this.tooltipService.hideTooltip(this.memoryTooltip);
        
        // Reset memory style
        element
          .attr('stroke', d3.color(this.getMemoryTypeColor(memory.type))?.darker(0.5)?.toString() || '#666')
          .attr('stroke-width', 1.5);
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.memoryTooltip, event);
      })
      .on('click', (event: MouseEvent) => {
        event.stopPropagation(); // Prevent event bubbling
        console.log('Memory clicked:', memory); // Debug log
        
        // Hide the current tooltip
        this.tooltipService.hideTooltip(this.memoryTooltip);
        
        // Get the full content to copy
        let contentToCopy = `Memory ID: ${memory.memory_id || 'N/A'}\n`;
        contentToCopy += `Type: ${memory.type || 'Unknown'}\n`;
        contentToCopy += `Importance: ${memory.importance || 'N/A'}/10\n`;
        
        if (memory.observation) {
          contentToCopy += `Observation: ${memory.observation}\n`;
        }
        
        if (memory.insight) {
          contentToCopy += `Insight: ${memory.insight}\n`;
        }
        
        if (memory.created_date) {
          const date = new Date(memory.created_date);
          contentToCopy += `Created: ${date.toLocaleString()}\n`;
        }
        
        // Add any other available properties that might be present
        const memoryObj = memory as unknown as Record<string, any>;
        Object.keys(memoryObj).forEach(key => {
          // Skip properties we've already handled
          if (!['memory_id', 'type', 'importance', 'observation', 'insight', 'created_date'].includes(key)) {
            const value = memoryObj[key];
            if (value !== undefined && value !== null) {
              const formattedKey = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
              contentToCopy += `${formattedKey}: ${value}\n`;
            }
          }
        });
        
        // Copy to clipboard
        this.tooltipService.copyToClipboard(contentToCopy)
          .then(success => {
            // Show notification
            if (success) {
              this.tooltipService.showNotification('Memory content copied!', event);
            } else {
              this.tooltipService.showNotification('Failed to copy content', event);
            }
          })
          .catch(error => {
            console.error('Error during copy operation:', error);
            this.tooltipService.showNotification('Error copying content', event);
          });
      });
  }
  
  /**
   * Generate session tooltip content
   */
  private generateSessionTooltip(session: Session): string {
    let content = '';
    
    // Add header with session name
    const sessionName = session.name || 'Unnamed Session';
    content += this.tooltipService.formatHeader(sessionName, 'session');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available
    if (session.description) {
      mainContent += `<div class="tooltip-description">${session.description}</div>`;
    }
    
    // Add session details section
    let detailsContent = '';
    
    // Add session properties
    if (session.session) {
      detailsContent += this.tooltipService.formatProperty('ID', session.session.substring(0, 8) + '...');
    }
    
    if (session.sessionType) {
      detailsContent += this.tooltipService.formatProperty('Type', session.sessionType);
    }
    
    if (session.completionStatus) {
      detailsContent += this.tooltipService.formatProperty('Status', session.completionStatus);
    }
    
    if (session.createdDate) {
      const date = new Date(session.createdDate);
      detailsContent += this.tooltipService.formatProperty('Created', date.toLocaleString());
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add the main content
    content += mainContent;
    
    return content;
  }
  
  /**
   * Truncate text that's too long
   */
  private truncateText(text: string, maxLength: number): string {
    return text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text || '';
  }
  
  /**
   * Generate tooltip content for a memory
   */
  private generateMemoryTooltip(memory: MemoryItem): string {
    // Get type label with proper capitalization
    const typeLabel = memory.type ? 
      memory.type.charAt(0).toUpperCase() + memory.type.slice(1).replace('_', ' ') : 
      'Unknown';
      
    // Format the tooltip content using the tooltip service formatting methods
    let content = '';
    
    // Add header with memory type
    content += this.tooltipService.formatHeader(`${typeLabel} Memory`, 'memory');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available (observation or insight)
    if (memory.observation) {
      mainContent += `<div class="tooltip-description">${memory.observation}</div>`;
    } else if (memory.insight) {
      mainContent += `<div class="tooltip-description">${memory.insight}</div>`;
    }
    
    // Add memory details section
    let detailsContent = '';
    
    // Add memory properties
    if (memory.memory_id) {
      detailsContent += this.tooltipService.formatProperty('ID', memory.memory_id.substring(0, 8) + '...');
    }
    
    if (memory.importance !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Importance', `${memory.importance}/10`);
    }
    
    if (memory.reliability !== undefined) {
      detailsContent += this.tooltipService.formatProperty('Reliability', `${memory.reliability}/10`);
    }
    
    if (memory.created_date) {
      const date = new Date(memory.created_date);
      detailsContent += this.tooltipService.formatProperty('Created', date.toLocaleString());
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add context section if available
    if (memory.context && memory.context.description) {
      let contextContent = this.tooltipService.formatProperty('Description', memory.context.description);
      
      // Add business dimensions if any
      if (memory.context.business_dimensions && memory.context.business_dimensions.length) {
        memory.context.business_dimensions.forEach(dim => {
          contextContent += this.tooltipService.formatProperty(dim.dimension_type, dim.value);
        });
      }
      
      mainContent += this.tooltipService.formatSection(contextContent, 'Context');
    }
    
    // Add analysis section if available
    if (memory.analysis && memory.analysis.length) {
      let analysisContent = '';
      
      memory.analysis.forEach(analysis => {
        let value = analysis.value !== undefined ? analysis.value : '';
        if (analysis.unit) {
          value += ` ${analysis.unit}`;
        }
        
        analysisContent += this.tooltipService.formatProperty(analysis.metric, value);
      });
      
      mainContent += this.tooltipService.formatSection(analysisContent, 'Analysis');
    }
    
    // Add the main content
    content += mainContent;
    
    return content;
  }
  
  /**
   * Get color for memory type
   */
  private getMemoryTypeColor(type: string): string {
    const memoryTypeColorMap: Record<string, string> = {
      'data_issue': '#607d8b',      // Blue-gray for issues
      'model_evaluation': '#5c6bc0', // Indigo for evaluations
      'user_feedback': '#26a69a',    // Teal for user feedback
      'system_alert': '#ef5350',     // Light red for system alerts
      'performance_metric': '#66bb6a', // Green for performance metrics
      'default': '#90a4ae'          // Light blue-gray for other types
    };
    
    return memoryTypeColorMap[type] || memoryTypeColorMap['default'];
  }
  
  /**
   * Highlight a session by ID
   */
  public highlightSession(sessionId: string): void {
    // Find the session element through the link service
    const sessionRef = this.elementsLinkService.getSessionReference(sessionId);
    if (!sessionRef || !sessionRef.element) return;
    
    // Highlight the session rectangle
    sessionRef.element.select('.session-rect')
      .transition()
      .duration(200)
      .attr('stroke', '#ff9800') // Orange highlight
      .attr('stroke-width', 2);
      
    // Make the session text bold
    sessionRef.element.select('.session-name')
      .transition()
      .duration(200)
      .attr('font-weight', 'bold');
  }
  
  /**
   * Reset all session highlights
   */
  public resetHighlights(): void {
    // Get all session references from the link service
    const sessionRefs = this.elementsLinkService.getAllSessionReferences();
    
    // Reset each session's style
    Object.values(sessionRefs).forEach(sessionRef => {
      if (sessionRef && sessionRef.element) {
        // Reset rectangle style
        sessionRef.element.select('.session-rect')
          .transition()
          .duration(200)
          .attr('stroke', this.sessionColor)
          .attr('stroke-width', 0.5);
          
        // Reset text style
        sessionRef.element.select('.session-name')
          .transition()
          .duration(200)
          .attr('font-weight', 'normal');
      }
    });
  }
  
  /**
   * Calculate points for a hexagon
   */
  private calculateHexagonPoints(centerX: number, centerY: number, radius: number): string {
    const points = [];
    const angleOffset = Math.PI / 6; // 30 degrees offset
    
    for (let i = 0; i < 6; i++) {
      const angle = (i * Math.PI / 3) + angleOffset;
      const x = centerX + radius * Math.sin(angle);
      const y = centerY + radius * Math.cos(angle);
      points.push(`${x},${y}`);
    }
    
    // Close the path by returning to the first point
    points.push(points[0]);
    
    return points.join(' ');
  }
}