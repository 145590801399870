// Types for model selection
export enum AIModel {
    SONNET = 0,
    HAIKU = 1,
    OPUS = 2
}

// Request interface
export interface AICompletionRequest {
    prompt: string;
    model: AIModel;
    maxTokens: number;
    temperature: number;
    details?: string;
}

// Response interfaces
export interface TokenUsage {
    promptTokens: number;
    completionTokens: number;
    totalTokens: number;
}

export interface AICompletionResponse {
    content: string;
    model: string;
    usage: TokenUsage;
}