// src/app/components/notifications/notifications.component.ts
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationsService } from '../../services/notifications.service';
import { NotificationModel, NotificationsState } from '../../models/notification.model';
import { Subscription } from 'rxjs';
import { StateManagerService } from '../../services/state-manager.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ]
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications: NotificationModel[] = [];
  unreadCount = 0;
  showPane = false;
  
  private subscription: Subscription | null = null;
  
  // Type colors map for styling different notification types
  private typeColorMap: {[key: string]: string} = {
    'info': '#42a5f5',     // Blue
    'success': '#43a047',  // Green
    'warning': '#ff9800',  // Orange
    'error': '#e53935',    // Red
    'deployment': '#3949ab', // Indigo
    'message': '#673ab7',  // Deep Purple
    'default': '#9e9e9e'   // Gray for other types
  };

  constructor(
    private notificationsService: NotificationsService,
    private stateManagerService: StateManagerService
  ) {}

  ngOnInit(): void {
    this.subscription = this.notificationsService.notifications$.subscribe((state: NotificationsState) => {
      this.notifications = state.notifications;
      this.unreadCount = state.unreadCount;
      this.showPane = state.showNotificationsPane;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('document:keydown.escape')
  onEscapeKey(): void {
    this.closePane();
  }

  /**
   * Close the notifications pane
   */
  closePane(): void {
    this.notificationsService.closeNotificationsPane();
  }

  /**
   * Dismiss a notification
   */
  dismissNotification(event: Event, id: string): void {
    event.stopPropagation();
    this.notificationsService.removeNotification(id);
  }

  /**
   * Dismiss all notifications
   */
  dismissAll(): void {
    // Loop through all notifications and remove them
    [...this.notifications].forEach(notification => {
      this.notificationsService.removeNotification(notification.id);
    });
  }

  /**
   * Process a notification click
   * This might trigger actions based on the notification type
   */
  onNotificationClick(notification: NotificationModel): void {
    if (notification.riffml) {
      // TODO: Process RiffML if present
      console.log('Processing RiffML:', notification.riffml);
    }

    // Remove the notification after clicking
    this.notificationsService.removeNotification(notification.id);
  }

  /**
   * Get class for notification based on its type
   */
  getNotificationTypeClass(type: string): string {
    return `notification-type-${type.toLowerCase()}`;
  }

  /**
   * Get color for notification based on its type
   */
  getNotificationColor(type: string): string {
    return this.typeColorMap[type.toLowerCase()] || this.typeColorMap['default'];
  }

  /**
   * Format the timestamp for display
   */
  formatTimestamp(timestamp: string): string {
    // Parse the UTC timestamp
    const messageDate = new Date(timestamp);
    
    // Get current time
    const now = new Date();
    
    // Apply time zone offset to make the comparison fair
    // This adjusts for the fact that messageDate is UTC and now is local time
    const timeZoneOffset = now.getTimezoneOffset() * 60000; // convert to milliseconds
    
    // Calculate minutes elapsed, adjusting for timezone difference
    const elapsedMs = now.getTime() - messageDate.getTime() + timeZoneOffset;
    const elapsedMinutes = Math.floor(elapsedMs / 60000);
    
    // Format based on elapsed time
    if (elapsedMinutes < 1) {
      return 'just now';
    } else if (elapsedMinutes < 60) {
      return `${elapsedMinutes} minute${elapsedMinutes !== 1 ? 's' : ''} ago`;
    } else if (elapsedMinutes < 1440) { // less than 24 hours
      const hours = Math.floor(elapsedMinutes / 60);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (elapsedMinutes < 10080) { // less than 7 days
      const days = Math.floor(elapsedMinutes / 1440);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else {
      // For older messages, show the full date in local time format
      return messageDate.toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  }
} 