// src/app/services/state-manager.service.ts

import { Injectable } from '@angular/core';
import { WorkspaceState, StateItem } from '../models/workspace-state.model';
import { WorkspaceManagerService } from './workspace-manager.service';
import { StateOperationResult } from '../models/state-operation-result.model';

import { WorkspaceItem } from '../models/workspace.interface';
import { ProjectItem } from '../models/project.interface';
import { BehaviorSubject } from 'rxjs';
import { ChatSession } from '../models/chat.model';
import { ArtifactsService } from './artifacts.service';

/**
 * Service to manage read/write/add/delete operations on StateItem objects
 * found in the stateMap of workspace, project, or session objects.
 * 
 * Allows accessing nested fields (e.g., "session.music.genre")
 * and array-like paths (e.g., "session.array1[1]", "session.array1.length").
 * 
 * All keys are converted to lowercase to ensure case-insensitive operations.
 */
@Injectable({
  providedIn: 'root'
})
export class StateManagerService {

  constructor(
    private sharedState: WorkspaceState,
    private workspaceManagerService: WorkspaceManagerService,
    private artifactsService: ArtifactsService

  ) { }

  /**
   * Returns the value from the state map, interpreted as an object.
   * 
   * @param rootId An ID that points to either a workspace, project, or session
   * @param typedKey A string key that indicates the type and path (e.g. "workspace.Name", "project.Workstreams", "session.music.genre", "SuggestedWorkstreams" for session by default)
   */
  public getObject(rootId: string, typedKey: string): any {
    const rawValue = this.getStateData(rootId, typedKey.toLowerCase());
    return rawValue;
  }

  /**
   * Returns the value from the state map, interpreted as a string.
   * If the data is not a string, tries to convert via JSON.stringify if it is an object/array.
   * 
   * @param rootId 
   * @param typedKey 
   */
  public getString(rootId: string, typedKey: string): string | null {
    const value = this.getObject(rootId, typedKey.toLowerCase());
    if (value == null) {
      return null;
    }
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'object' || Array.isArray(value)) {
      return JSON.stringify(value);
    }
    return String(value);
  }

  /**
   * Returns the value from the state map, interpreted as a boolean.
   * Tries to parse standard "true"/"false" strings or 1/0 values if needed.
   * 
   * @param rootId 
   * @param typedKey 
   */
  public getBool(rootId: string, typedKey: string): boolean | null {
    const value = this.getObject(rootId, typedKey.toLowerCase());
    if (value == null) {
      return null;
    }
    if (typeof value === 'boolean') {
      return value;
    }
    if (typeof value === 'string') {
      return (value.toLowerCase() === 'true');
    }
    if (typeof value === 'number') {
      return value !== 0;
    }
    return false;
  }

  /**
   * Returns the value from the state map, interpreted as a number.
   * If it cannot parse as number, returns null.
   * 
   * @param rootId 
   * @param typedKey 
   */
  public getNumber(rootId: string, typedKey: string): number | null {
    const value = this.getObject(rootId, typedKey.toLowerCase());
    if (value == null) {
      return null;
    }
    const parsed = Number(value);
    if (isNaN(parsed)) {
      return null;
    }
    return parsed;
  }

  /**
   * Returns the value from the state map, interpreted as an array.
   * If it is not an array, returns null.
   * 
   * @param rootId 
   * @param typedKey 
   */
  public getArray(rootId: string, typedKey: string): any[] | null {
    const value = this.getObject(rootId, typedKey.toLowerCase());
    if (!Array.isArray(value)) {
      return null;
    }
    return value;
  }

  /**
   * Checks if a non-null value exists in the state map for the given key/path.
   * 
   * @param rootId 
   * @param typedKey 
   */
  public exist(rootId: string, typedKey: string): boolean {
    const value = this.getObject(rootId, typedKey.toLowerCase());
    return value !== undefined && value !== null;
  }

  /**
   * Sets or updates a StateItem in the relevant object's stateMap. 
   * You must provide at least the required fields: id and name.
   * If other fields are omitted, they are replaced with defaults.
   * 
   * @param rootId 
   * @param typedKey 
   * @param id 
   * @param name 
   * @param description 
   * @param whyRelevant 
   * @param type 
   * @param data 
   * @returns StateOperationResult containing rootType, mapKey, and the created/updated item (or null if failed)
   */
  public async setState(
    rootId: string | null,
    typedKey: string,
    id: string,
    name: string,
    description?: string,
    whyRelevant?: string,
    type?: string,
    data?: any
  ): Promise<StateOperationResult> {
    const { rootType, mapKey } = this.parseRootTypeAndKey(typedKey.toLowerCase());

    const { item, contextObject, actualRootId } = this.getContextObject(rootType, rootId);
    if (!contextObject) {
      return {
        rootType,
        mapKey,
        newItem: null,
        success: false
      };
    }

    const newItem: StateItem = {
      id: id,
      name: name,
      description: description || '',
      whyRelevant: whyRelevant || '',
      type: type || 'string',
      data: data
    };

    contextObject.stateMap[mapKey.toLowerCase()] = newItem;
    
    return {
      rootType,
      mapKey,
      newItem,
      success: true
    };
  }


  /**
 * Updates state by adding to an array or merging with an existing object.
 * - If the target is an array, appends the value to the array.
 * - If the target is an object, performs a deep merge of the objects.
 * - If the target doesn't exist, initializes it as an array with the value as its first element.
 * - If the typedKey has a format like 'WorkstreamChanges.ws_001', treats it as a map operation
 *   and updates the specific map entry accordingly.
 * - Handles rootType prefixes (workspace, project, session) correctly
 * 
 * @param rootId The ID of the root object (workspace, project, or session)
 * @param typedKey The key path to the state item
 * @param value The value to add or merge
 * @returns StateOperationResult containing rootType, mapKey, and the updated item (or null if failed)
 */
public async addToState(rootId: string | null, typedKey: string, value: any): Promise<StateOperationResult> {
  const lowercaseTypedKey = typedKey.toLowerCase();
  
  // Extract the rootType
  const tres = this.parseRootTypeAndKey(typedKey.toLowerCase());
  
  // Remove the rootType prefix (workspace., project., session.) from the key if present
  let remainingKey = lowercaseTypedKey;
  const rootTypePrefixes = ['workspace.', 'project.', 'session.'];
  
  for (const prefix of rootTypePrefixes) {
    if (lowercaseTypedKey.startsWith(prefix)) {
      remainingKey = lowercaseTypedKey.substring(prefix.length);
      break;
    }
  }

  if (!rootId) {
    const { item, contextObject, actualRootId } = this.getContextObject(tres.rootType, rootId);
    if (!actualRootId) {
      return {
        rootType: tres.rootType,
        mapKey: tres.mapKey,
        newItem: null,
        success: false
      };
    }

    rootId = actualRootId;
  }

  let rootType = tres.rootType;
  
  // Now split the remaining key
  const parts = remainingKey.split('.');
  const isMapOperation = parts.length > 1;
  
  // If it's a map operation, we need to handle the parent map and the specific key
  if (isMapOperation) {
    const mapKey = parts[0]; // e.g., workstreamchanges or tables
    const mapEntryKey = parts[1]; // e.g., ws_001 or something
    const nestedKeyPath = parts.slice(2); // Any further nesting, if present
    
    // Get the parent map
    const parentMap = this.getObject(rootId, `${rootType}.${mapKey}`);
    
    // Create or use existing map (must be an object, not an array)
    let mapData: Record<string, any> = {};
    
    if (parentMap && typeof parentMap === 'object') {
      if (Array.isArray(parentMap)) {
        // If it's an array but should be a map, let's convert it by merging all objects
        parentMap.forEach(item => {
          if (typeof item === 'object' && item !== null) {
            Object.assign(mapData, item);
          }
        });
      } else {
        // If it's already an object, use it
        mapData = { ...parentMap };
      }
    }
    
    // If there's no nested path, merge with existing or add new map entry
    if (nestedKeyPath.length === 0) {
      // If entry exists and both are objects, merge them instead of replacing
      if (mapData[mapEntryKey] && typeof mapData[mapEntryKey] === 'object' && 
          !Array.isArray(mapData[mapEntryKey]) && typeof value === 'object' && !Array.isArray(value)) {
        mapData[mapEntryKey] = this.deepMerge(mapData[mapEntryKey], value);
      } else {
        // Otherwise, set the value directly
        mapData[mapEntryKey] = value;
      }
    } else {
      // Handle nested path within the map entry
      if (!mapData[mapEntryKey] || typeof mapData[mapEntryKey] !== 'object') {
        mapData[mapEntryKey] = {}; // Initialize if not exists or not an object
      }
      
      // Navigate to the correct nesting level
      let current = mapData[mapEntryKey];
      for (let i = 0; i < nestedKeyPath.length - 1; i++) {
        const key = nestedKeyPath[i];
        if (!current[key] || typeof current[key] !== 'object') {
          current[key] = {};
        }
        current = current[key];
      }
      
      // Set or merge the value at the last level
      if (nestedKeyPath.length > 0) {
        const lastKey = nestedKeyPath[nestedKeyPath.length - 1];
        
        // If existing value and new value are both objects, merge them
        if (current[lastKey] && typeof current[lastKey] === 'object' && 
            !Array.isArray(current[lastKey]) && typeof value === 'object' && !Array.isArray(value)) {
          current[lastKey] = this.deepMerge(current[lastKey], value);
        } else {
          current[lastKey] = value;
        }
      }
    }
    
    // Now update the state with the modified map
    const { item, contextObject } = this.getContextObject(rootType, rootId);
    if (!contextObject) {
      return {
        rootType,
        mapKey,
        newItem: null,
        success: false
      };
    }
    
    const lowerMapKey = mapKey.toLowerCase();
    const existingItem = contextObject.stateMap[lowerMapKey];
    
    // Extract the original case of mapKey from the original typedKey
    let originalCaseMapKey = mapKey;
    const originalParts = typedKey.split('.');
    for (let i = 0; i < originalParts.length; i++) {
      if (originalParts[i].toLowerCase() === mapKey) {
        originalCaseMapKey = originalParts[i];
        break;
      }
    }
    
    let finalId = lowerMapKey;
    let finalName = originalCaseMapKey; // Preserve original case for display name
    let finalDesc = '';
    let finalWhy = '';
    let finalType = 'object'; // Always an object for maps
    
    if (existingItem) {
      finalId = existingItem.id;
      finalName = existingItem.name;
      finalDesc = existingItem.description;
      finalWhy = existingItem.whyRelevant;
      finalType = 'object'; // Force type to be object for maps
    }
    
    const newItem: StateItem = {
      id: finalId,
      name: finalName,
      description: finalDesc,
      whyRelevant: finalWhy,
      type: finalType,
      data: mapData
    };
    
    contextObject.stateMap[lowerMapKey] = newItem;
    
    return {
      rootType,
      mapKey,
      newItem,
      success: true
    };
  }
  
  // Normal (non-map) operation: remainingKey is the actual mapKey
  const mapKey = parts[0];
  const existingData = this.getObject(rootId, `${rootType}.${mapKey}`);
  let updatedData;
  
  if (Array.isArray(existingData)) {
    // If existing data is an array, add the new value to it
    updatedData = [...existingData, value];
  } else if (existingData && typeof existingData === 'object' && typeof value === 'object') {
    // If both existing and new value are objects, perform a deep merge
    updatedData = this.deepMerge(existingData, value);
  } else if (existingData === undefined || existingData === null) {
    // For non-map operations, initialize as an array with the value as first element
    updatedData = [value];
  } else {
    // If existing data is not an array or object, replace with an array containing both values
    updatedData = [existingData, value];
  }
  
  // Now update the state with the new data
  const { item, contextObject } = this.getContextObject(rootType, rootId);
  if (!contextObject) {
    return {
      rootType,
      mapKey,
      newItem: null,
      success: false
    };
  }
  
  const lowerMapKey = mapKey.toLowerCase();
  const existingItem = contextObject.stateMap[lowerMapKey];
  
  // Extract the original case of mapKey from the original typedKey
  let originalCaseMapKey = mapKey;
  const originalParts = typedKey.split('.');
  for (let i = 0; i < originalParts.length; i++) {
    if (originalParts[i].toLowerCase() === mapKey) {
      originalCaseMapKey = originalParts[i];
      break;
    }
  }
  
  let finalId = lowerMapKey;
  let finalName = originalCaseMapKey; // Preserve original case for display name
  let finalDesc = '';
  let finalWhy = '';
  let finalType = 'array'; // Default to array for non-map operations
  
  if (existingItem) {
    finalId = existingItem.id;
    finalName = existingItem.name;
    finalDesc = existingItem.description;
    finalWhy = existingItem.whyRelevant;
    finalType = existingItem.type || finalType;
  }
  
  const newItem: StateItem = {
    id: finalId,
    name: finalName,
    description: finalDesc,
    whyRelevant: finalWhy,
    type: finalType,
    data: updatedData
  };
  
  contextObject.stateMap[lowerMapKey] = newItem;
  
  return {
    rootType,
    mapKey,
    newItem,
    success: true
  };
}


  /**
   * Recursively merges source object into target object, handling nested properties.
   * 
   * @param target The target object to merge into
   * @param source The source object to merge from
   * @returns The merged object
   */
  private deepMerge(target: any, source: any): any {
    const output = Object.assign({}, target);
    
    if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach(key => {
        const lowerKey = key.toLowerCase();
        if (isObject(source[key])) {
          if (!(lowerKey in target)) {
            Object.assign(output, { [lowerKey]: source[key] });
          } else {
            // Find the actual key in target regardless of case
            const actualKey = Object.keys(target).find(k => k.toLowerCase() === lowerKey) || lowerKey;
            output[lowerKey] = this.deepMerge(target[actualKey], source[key]);
          }
        } else {
          Object.assign(output, { [lowerKey]: source[key] });
        }
      });
    }
    
    return output;
    
    function isObject(item: any): boolean {
      return (item && typeof item === 'object' && !Array.isArray(item));
    }
  }

  /**
   * Appends an item to the array found in the data of a StateItem (if it is an array).
   * If the array does not exist, it initializes it.
   * 
   * @param rootId 
   * @param typedKey 
   * @param value 
   * @returns StateOperationResult containing rootType, mapKey, and the updated item (or null if failed)
   * @deprecated Use addToState instead
   */
  public async addToArray(rootId: string, typedKey: string, value: any): Promise<StateOperationResult> {
    return this.addToState(rootId, typedKey.toLowerCase(), value);
  }

  /**
   * Deletes state data based on the provided path.
   * - For array tables: Removes a specific item from the array
   * - For nested object properties: Removes the specific property
   * - For root keys: Removes the entire state item
   * 
   * @param rootId The ID of the root object (workspace, project, or session)
   * @param typedKey The key path to the state to delete
   * @returns StateOperationResult containing rootType, mapKey, and the deleted item (null if operation failed)
   */
  public async deleteState(rootId: string, typedKey: string): Promise<StateOperationResult> {
    const { rootType, mapKey, nestedPath } = this.parseRootTypeAndKey(typedKey.toLowerCase());
    
    // Prevent deletion of tables from workspace
    /*if (rootType === 'workspace' && mapKey === 'tables') {
      return {
        rootType,
        mapKey,
        newItem: null,
        success: false
      };
    }*/

    const { item, contextObject } = this.getContextObject(rootType, rootId);
    if (!contextObject) {
      return {
        rootType,
        mapKey,
        newItem: null,
        success: false
      };
    }

    const lowerMapKey = mapKey.toLowerCase();
    const existingItem = contextObject.stateMap[lowerMapKey];
    
    if (!existingItem) {
      return {
        rootType,
        mapKey,
        newItem: null,
        success: false
      };
    }
    
    // Store a copy of the item before deletion for return value
    const deletedItem: StateItem = { ...existingItem };

    // Case 1: No nested path - delete the entire state item
    if (!nestedPath || nestedPath.length === 0) {
      delete contextObject.stateMap[lowerMapKey];
      return {
        rootType,
        mapKey,
        newItem: deletedItem,
        success: true
      };
    }

    // Case 2: Has nested path - we need to traverse and delete the specific property
    if (!existingItem.data) {
      return {
        rootType,
        mapKey,
        newItem: null,
        success: false
      };
    }

    // Handle array-specific case (e.g., "ContextTables[table1]")
    if (lowerMapKey === 'contexttables' && nestedPath.length === 1) {
      const tableId = nestedPath[0].toLowerCase();
      const tablesArray = this.getArray(rootId, `${rootType}.${lowerMapKey}`);
      
      if (tablesArray) {
        const updatedArray = tablesArray.filter(table => 
          typeof table === 'object' && table !== null && 
          (table.id?.toLowerCase() !== tableId)
        );
        
        existingItem.data = updatedArray;
        return {
          rootType,
          mapKey,
          newItem: existingItem,
          success: true
        };
      }
    }

    // General case: Handle nested object property deletion
    const targetObj = existingItem.data;
    const pathToModify = nestedPath.map(p => p.toLowerCase());
    const lastKey = pathToModify.pop();
    
    if (!lastKey) {
      return {
        rootType,
        mapKey,
        newItem: null,
        success: false
      };
    }
    
    // Navigate to the parent object of the property to delete
    let current = targetObj;
    for (const part of pathToModify) {
      if (!current || typeof current !== 'object') {
        return {
          rootType,
          mapKey,
          newItem: null,
          success: false
        };
      }
      
      // Find the actual key in current object regardless of case
      const actualKey = Object.keys(current).find(k => k.toLowerCase() === part);
      if (!actualKey) {
        return {
          rootType,
          mapKey,
          newItem: null,
          success: false
        };
      }
      
      current = current[actualKey];
    }
    
    // Delete the property if parent exists and is an object
    if (current && typeof current === 'object') {
      // Find the actual key in current object regardless of case
      const actualLastKey = Object.keys(current).find(k => k.toLowerCase() === lastKey);
      if (actualLastKey) {
        delete current[actualLastKey];
        return {
          rootType,
          mapKey,
          newItem: existingItem,
          success: true
        };
      }
    }
    
    return {
      rootType,
      mapKey,
      newItem: null,
      success: false
    };
  }

  /**
   * Returns the entire stateMap of a root object (workspace, project, or session).
   * Handles null rootIds by using defaults from sharedState.
   * 
   * @param rootId An ID that points to either a workspace, project, or session (can be null)
   * @param rootType The type of the root object: 'workspace', 'project', or 'session'
   * @returns The full stateMap of the root object or null if not found
   */
  public getFullStateMap(rootId: string | null, rootType: 'workspace' | 'project' | 'session'): { [key: string]: StateItem } | null {
    // Handle null rootIds by using defaults from sharedState
    if (rootType === 'workspace' && (!rootId || !rootId.length)) {
      rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace ?? '';
    }

    if (rootType === 'project' && (!rootId || !rootId.length)) {
      rootId = this.sharedState.userWorkspaceState$.value?.selectedProject ?? '';
    }

    if (rootType === 'session' && (!rootId || !rootId.length)) {
      rootId = this.sharedState.sessionsGridState$.value?.selectedSessionId ?? '';
    }

    // Get the context object that contains the stateMap
    const { contextObject } = this.getContextObject(rootType, rootId);
    
    // Return the stateMap if the context object exists, otherwise null
    if (!contextObject || !contextObject.stateMap) {
      return null;
    }
    
    return contextObject.stateMap;
  }

  /**
   * Gets a StateItem's data from the relevant object and resolves any nested path
   * (e.g., "session.music.genre", "session.array1[0]", "session.array1.length").
   */
  public getStateData(rootId: string, typedKey: string): any {
    // Special check for "workspace.tables"
    const { rootType, mapKey, nestedPath } = this.parseRootTypeAndKey(typedKey.toLowerCase());

    if (rootType=='workspace' && !rootId || !rootId.length)
    {
      rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace ??'';
    }

    if (rootType=='project' && !rootId || !rootId.length)
      {
        rootId = this.sharedState.userWorkspaceState$.value?.selectedProject ??'';
      }

    if ((rootType === 'session' && mapKey === 'lastmessagefromuser') || 
        (mapKey === 'lastmessagefromuser' && (!nestedPath || nestedPath.length === 0))) {
      return this.sharedState.getLastUserMessage(rootId);
    }
    
    if ((rootType === 'session' && mapKey === 'lastmessagefromagent') || 
        (mapKey === 'lastmessagefromagent' && (!nestedPath || nestedPath.length === 0))) {
      return this.sharedState.getLastAgentMessage(rootId);
    }

    // Handle special project-related cases
    if (rootType === 'project') {
      // Handle project.Workstreams - returns all workstreams
      if (mapKey === 'workstreams' && (!nestedPath || nestedPath.length === 0)) {
        return this.sharedState.getAllWorkstreams();
      }
      
      // Handle project.Sessions - returns all sessions
      if (mapKey === 'sessions' && (!nestedPath || nestedPath.length === 0)) {
        return this.sharedState.sessions$.value;
      }
      
      // Handle project.LastSessions - returns most recent sessions
      if (mapKey === 'lastsessions' && (!nestedPath || nestedPath.length === 0)) {
        // Default to 5 sessions if no count specified
        const count = nestedPath.length > 0 ? parseInt(nestedPath[0], 10) || 5 : 5;
        return this.sharedState.getLastSessions(count);
      }
    }

    // If user specifically asked for "workspace.tables" without further nested path
    // we return the TablesCollection from the TablesManagerService
    //if (rootType === 'workspace' && mapKey === 'tables' && (!nestedPath || nestedPath.length === 0)) {
    //  return this.tablesManagerService.getTablesCollection(rootId);
    //}

    // Otherwise proceed with normal retrieval
    const { item, contextObject } = this.getContextObject(rootType, rootId);
    if (!item && (!contextObject || !contextObject.stateMap)) {
      return null;
    }

    // Find the state item by case-insensitive key
    const lowerMapKey = mapKey.toLowerCase();
    const stateItem = contextObject ? 
      contextObject.stateMap[lowerMapKey] || 
      Object.values(contextObject.stateMap).find(si => si.id && si.id.length && si.id.toLowerCase() === lowerMapKey) :
      null;

    if (!stateItem) {
      return null;
    }

    // stateItem.data might be the main object or array
    // then we allow nested path resolution
    if (!nestedPath || nestedPath.length === 0) {
      return stateItem.data;
    }

    return this.resolveNestedPath(stateItem.data, nestedPath.map(p => p.toLowerCase()));
  }

  /**
   * Resolves a nested path such as ["music","genre"] or ["array1","[2]"] or ["array1","length"].
   * Returns null if any segment is not found.
   */
  private resolveNestedPath(current: any, pathParts: string[]): any {
    let result = current;
    for (const part of pathParts) {
      if (result == null) {
        return null;
      }
      // Check for array index
      const arrayIndexMatch = part.match(/^\[(\d+)\]$/);
      if (arrayIndexMatch) {
        const index = parseInt(arrayIndexMatch[1], 10);
        if (!Array.isArray(result) || result[index] === undefined) {
          return null;
        }
        result = result[index];
      } else if (part === 'length' || part === 'count') {
        // "length" or "count" for arrays
        if (!Array.isArray(result)) {
          return null;
        }
        return result.length;
      } else {
        // Normal object property - case insensitive lookup
        if (typeof result !== 'object') {
          return null;
        }
        
        // Find the actual key in the object regardless of case
        const actualKey = Object.keys(result).find(k => k.toLowerCase() === part);
        if (!actualKey) {
          return null;
        }
        
        result = result[actualKey];
      }
    }
    return result;
  }

  /**
   * Returns an object containing:
   *   rootType: 'workspace'|'project'|'session'
   *   mapKey:   The primary key in the stateMap (e.g., "name", "music", "log")
   *   nestedPath: Additional path segments if present (e.g., ["genre"], ["[2]"])
   * 
   * If no prefix is detected, rootType is 'session'.
   */
  private parseRootTypeAndKey(typedKey: string): { rootType: 'workspace'|'project'|'session'; mapKey: string; nestedPath: string[] } {
    if (!typedKey) {
      return { rootType: 'session', mapKey: '', nestedPath: [] };
    }
    
    const lower = typedKey.toLowerCase().trim();
    let prefix: 'workspace'|'project'|'session'|'none' = 'none';
    let remainder = typedKey.toLowerCase();

    if (lower.startsWith('workspace.')) {
      prefix = 'workspace';
      remainder = lower.substring('workspace.'.length);
    } else if (lower.startsWith('project.')) {
      prefix = 'project';
      remainder = lower.substring('project.'.length);
    } else if (lower.startsWith('session.')) {
      prefix = 'session';
      remainder = lower.substring('session.'.length);
    } else {
      // No known prefix, assume session
      prefix = 'session';
      remainder = lower;
    }

    // Split remainder on '.' to handle multi-level path
    // e.g. "music.genre" => mapKey = "music", nestedPath = ["genre"]
    // or "array1[2]" => mapKey = "array1[2]" => then we parse further in the resolver
    const parts = remainder.split('.');
    const mapKey = parts[0];
    const nestedPath = parts.slice(1); // might have zero or more segments

    return {
      rootType: prefix,
      mapKey: mapKey,
      nestedPath: nestedPath
    };
  }

  /**
   * Returns the matching object that contains the stateMap, and the item (if it exists).
   * If no object or stateMap is found, it creates an empty one for session or returns null for workspace/project not found.
   */
  private getContextObject(rootType: 'workspace'|'project'|'session', rootId: string | null): 
    { item: StateItem | null, contextObject: { stateMap: { [key: string]: StateItem } } | null, actualRootId:string } 
  {
    if (rootType === 'workspace') {
      if (!rootId) {
        rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace || '';
      }
      const workspace = this.workspaceManagerService.getWorkspaceById(rootId);
      if (!workspace) {
        return { item: null, contextObject: null, actualRootId:rootId };
      }
      if (!workspace.stateMap) {
        workspace.stateMap = {};
      }
      return { item: null, contextObject: workspace, actualRootId:rootId };
    }

    if (rootType === 'project') {
      // Each project is inside its parent workspace, so we find it
      if (!rootId) {
        rootId = this.sharedState.userWorkspaceState$.value?.selectedProject || '';
      }
      const project = this.workspaceManagerService.getProjectById(rootId);
      if (!project) {
        return { item: null, contextObject: null, actualRootId:rootId };
      }
      if (!project.stateMap) {
        project.stateMap = {};
      }
      return { item: null, contextObject: project, actualRootId:rootId };
    }

    // rootType === 'session'
    // We look up the session object in the sharedState chatSessionsMap if available
    if (!rootId) {
      rootId = this.sharedState.sessionsGridState$.value?.selectedSessionId || '';
    }

    const sessionSubject = this.sharedState.tryGetChatSession(rootId);
    if (!sessionSubject) {
      return { item: null, contextObject: null, actualRootId:rootId };
    }

    const session = sessionSubject.value;
    if (!session.stateMap) {
      session.stateMap = {};
    }
    return { item: null, contextObject: session, actualRootId:rootId };
  }
}