import { Tag, Record, RecordsGroup, RecordGroupList } from '../models/record-management.interface';

const mockTags: Tag[] = [
  {
    id: 'tag1',
    label: 'ML Models',
    description: 'Machine Learning model related records',
    color: '#4CAF50',
    subTags: [
      { id: 'tag1-1', label: 'Training', description: 'Model training related', color: '#81C784' },
      { id: 'tag1-2', label: 'Inference', description: 'Model inference related', color: '#66BB6A' }
    ],
    recordCount: 15
  },
  {
    id: 'tag2',
    label: 'Data',
    description: 'Data processing and storage',
    color: '#2196F3',
    subTags: [
      { id: 'tag2-1', label: 'Storage', description: 'Data storage solutions', color: '#64B5F6' },
      { id: 'tag2-2', label: 'Processing', description: 'Data processing pipelines', color: '#42A5F5' }
    ],
    recordCount: 10
  }
];

const dataCollectionTags: Tag[] = [
  {
    id: 'infra-tag',
    label: 'Infrastructure',
    description: 'Infrastructure setup and management',
    color: '#FF9800',
    subTags: [
      { id: 'cloud-res', label: 'Cloud Resources', description: 'Cloud resource management', color: '#FFB74D' },
      { id: 'sec-access', label: 'Security & Access', description: 'Security and access control', color: '#FFA726' },
      { id: 'data-gov', label: 'Data Governance', description: 'Data governance policies', color: '#FF9100' },
      { id: 'monitoring', label: 'Monitoring', description: 'Monitoring setup and configuration', color: '#FF6D00' }
    ],
    recordCount: 5
  },
  {
    id: 'data-src',
    label: 'Data Sources',
    description: 'Data source integration',
    color: '#2196F3',
    subTags: [
      { id: 'ext-api', label: 'External APIs', description: 'External API integrations', color: '#64B5F6' },
      { id: 'db-conn', label: 'Database Connections', description: 'Database connection management', color: '#42A5F5' },
      { id: 'file-sys', label: 'File Systems', description: 'File system integrations', color: '#1E88E5' }
    ],
    recordCount: 4
  },
  {
    id: 'quality',
    label: 'Quality Control',
    description: 'Data quality control setup',
    color: '#4CAF50',
    subTags: [
      { id: 'val-rules', label: 'Validation Rules', description: 'Data validation rules', color: '#81C784' },
      { id: 'profiling', label: 'Data Profiling', description: 'Data profiling configuration', color: '#66BB6A' },
      { id: 'err-handle', label: 'Error Handling', description: 'Error handling setup', color: '#4CAF50' }
    ],
    recordCount: 4
  }
];

const processingTags: Tag[] = [
  {
    id: 'transform',
    label: 'Data Transformation',
    description: 'Data transformation and feature engineering',
    color: '#3F51B5',
    subTags: [
      { id: 'cleansing', label: 'Cleansing', description: 'Data cleansing operations', color: '#7986CB' },
      { id: 'feature-eng', label: 'Feature Engineering', description: 'Feature engineering processes', color: '#5C6BC0' },
      { id: 'enrichment', label: 'Data Enrichment', description: 'Data enrichment operations', color: '#3949AB' }
    ],
    recordCount: 4
  },
  {
    id: 'business',
    label: 'Business Rules',
    description: 'Business rules and calculations',
    color: '#009688',
    subTags: [
      { id: 'segmentation', label: 'Segmentation', description: 'Segmentation rules', color: '#4DB6AC' },
      { id: 'calculation', label: 'Calculations', description: 'Calculation methods', color: '#26A69A' },
      { id: 'constraints', label: 'Constraints', description: 'Business constraints', color: '#00897B' }
    ],
    recordCount: 4
  },
  {
    id: 'performance',
    label: 'Performance',
    description: 'Performance optimization',
    color: '#FFC107',
    subTags: [
      { id: 'query-opt', label: 'Query Optimization', description: 'Query performance optimization', color: '#FFD54F' },
      { id: 'resources', label: 'Resources', description: 'Resource allocation', color: '#FFCA28' },
      { id: 'caching', label: 'Caching', description: 'Caching strategies', color: '#FFB300' }
    ],
    recordCount: 3
  }
];

const modelingTags: Tag[] = [
  {
    id: 'architecture',
    label: 'Model Architecture',
    description: 'Model architecture and configuration',
    color: '#673AB7',
    subTags: [
      { id: 'model-sel', label: 'Model Selection', description: 'Model selection process', color: '#9575CD' },
      { id: 'param-conf', label: 'Parameters', description: 'Parameter configuration', color: '#7E57C2' },
      { id: 'val-strat', label: 'Validation', description: 'Validation strategy', color: '#5E35B1' }
    ],
    recordCount: 4
  },
  {
    id: 'training',
    label: 'Training Pipeline',
    description: 'Model training and preprocessing',
    color: '#E91E63',
    subTags: [
      { id: 'preprocess', label: 'Preprocessing', description: 'Data preprocessing steps', color: '#F06292' },
      { id: 'train', label: 'Training', description: 'Model training process', color: '#EC407A' },
      { id: 'eval', label: 'Evaluation', description: 'Performance evaluation', color: '#D81B60' }
    ],
    recordCount: 4
  },
  {
    id: 'validation',
    label: 'Model Validation',
    description: 'Model testing and validation',
    color: '#795548',
    subTags: [
      { id: 'test-proc', label: 'Testing', description: 'Testing procedures', color: '#A1887F' },
      { id: 'perf-met', label: 'Performance', description: 'Performance metrics', color: '#8D6E63' },
      { id: 'bus-val', label: 'Business Validation', description: 'Business validation steps', color: '#6D4C41' }
    ],
    recordCount: 4
  }
];

const productionTags: Tag[] = [
  {
    id: 'deployment',
    label: 'Deployment',
    description: 'Deployment management and configuration',
    color: '#2196F3',
    subTags: [
      { id: 'env-setup', label: 'Environment', description: 'Environment setup', color: '#64B5F6' },
      { id: 'version-ctrl', label: 'Version Control', description: 'Version control management', color: '#42A5F5' },
      { id: 'release-mgmt', label: 'Release', description: 'Release management', color: '#1E88E5' }
    ],
    recordCount: 4
  },
  {
    id: 'monitoring',
    label: 'Monitoring',
    description: 'Performance monitoring and metrics',
    color: '#9C27B0',
    subTags: [
      { id: 'sys-monitor', label: 'System', description: 'System monitoring', color: '#BA68C8' },
      { id: 'model-perf', label: 'Model Performance', description: 'Model performance tracking', color: '#AB47BC' },
      { id: 'bus-metrics', label: 'Business Metrics', description: 'Business metrics tracking', color: '#8E24AA' }
    ],
    recordCount: 4
  },
  {
    id: 'maintenance',
    label: 'Maintenance',
    description: 'System maintenance and optimization',
    color: '#FF5722',
    subTags: [
      { id: 'update-proc', label: 'Updates', description: 'Update procedures', color: '#FF8A65' },
      { id: 'resource-opt', label: 'Resources', description: 'Resource optimization', color: '#FF7043' },
      { id: 'doc-mgmt', label: 'Documentation', description: 'Documentation management', color: '#F4511E' }
    ],
    recordCount: 4
  }
];

const mockTagsExtended: Tag[] = [...mockTags, ...dataCollectionTags, ...processingTags, ...modelingTags, ...productionTags];

const mockRecords: Record[] = [
  {
    id: 'rec1',
    icon: 'trending_up',
    name: 'Revenue Prediction',
    description: 'ML model for revenue prediction',
    tags: [mockTags[0]],
    recommendedFlows: [
      { id: 'flow1', name: 'Train Model', description: 'Train the revenue prediction model' },
      { id: 'flow2', name: 'Make Prediction', description: 'Use model to make predictions' }
    ]
  },
  {
    id: 'rec2',
    icon: 'storage',
    name: 'Data Pipeline',
    description: 'Data processing pipeline',
    tags: [mockTags[1]],
    recommendedFlows: [
      { id: 'flow3', name: 'Process Data', description: 'Run the data processing pipeline' }
    ]
  }
];

const mockGroups: RecordsGroup[] = [
  {
    id: 'group1',
    title: 'Revenue Analysis',
    description: 'Revenue prediction and analysis',
    date: '24/11/24',
    isPinned: true,
    isExpanded: true,
    tags: [mockTags[0], mockTags[1]],
    records: [mockRecords[0], mockRecords[1]],
    recommendedFlows: [
      { id: 'flow4', name: 'Analyze customer segments for MVP features', description: 'Segment analysis' },
      { id: 'flow5', name: 'Create event monitoring dashboard', description: 'Dashboard creation' }
    ]
  },
  {
    id: 'group2',
    title: 'Data Processing',
    description: 'Data processing workflows',
    date: '23/11/24',
    isPinned: false,
    isExpanded: false,
    tags: [mockTags[1]],
    records: [mockRecords[1]],
    recommendedFlows: [
      { id: 'flow6', name: 'Set up real-time analytics pipeline', description: 'Pipeline setup' }
    ]
  }
];

export interface RecommendedFlow {
  id: string;
  name: string;
  description: string;
  project?: string;
  phase?: string;
}

export interface ProjectFlows {
  general: RecommendedFlow[];
  [phase: string]: RecommendedFlow[];
}

export interface RecommendedFlowsData {
  default: RecommendedFlow[];
  [project: string]: ProjectFlows | RecommendedFlow[];
}

export const RECOMMENDED_FLOWS: RecommendedFlowsData = {
  // Default workspace flows (3)
  default: [
    { 
      id: 'flow-default-1', 
      name: 'Workspace Analytics Overview', 
      description: 'View analytics performance across all projects' 
    },
    { 
      id: 'flow-default-2', 
      name: 'Cross-Project Insights', 
      description: 'Compare metrics and KPIs across different projects' 
    },
    { 
      id: 'flow-default-3', 
      name: 'Resource Utilization Dashboard', 
      description: 'Monitor team and system resource allocation' 
    }
  ],
  // Project 1: Marketing Campaign Analytics (9 flows: 3 general + 6 phase)
  'project-1': {
    general: [
      { 
        id: 'flow-proj1-1', 
        name: 'Campaign Performance Overview', 
        description: 'High-level view of all marketing campaign metrics', 
        project: 'project-1' 
      },
      { 
        id: 'flow-proj1-2', 
        name: 'Budget Allocation Analysis', 
        description: 'Track and optimize marketing budget distribution', 
        project: 'project-1' 
      },
      { 
        id: 'flow-proj1-3', 
        name: 'Marketing Channel Mix', 
        description: 'Analyze performance across different marketing channels', 
        project: 'project-1' 
      }
    ],
    'phase-1-1': [
      { 
        id: 'flow-proj1-phase1-1', 
        name: 'Campaign Data Integration', 
        description: 'Connect and validate marketing data sources', 
        project: 'project-1', 
        phase: 'phase-1-1' 
      },
      { 
        id: 'flow-proj1-phase1-2', 
        name: 'Attribution Model Setup', 
        description: 'Configure multi-channel attribution tracking', 
        project: 'project-1', 
        phase: 'phase-1-1' 
      },
      { 
        id: 'flow-proj1-phase1-3', 
        name: 'Real-time Tracking Config', 
        description: 'Set up real-time campaign monitoring', 
        project: 'project-1', 
        phase: 'phase-1-1' 
      }
    ],
    'phase-1-2': [
      { 
        id: 'flow-proj1-phase2-1', 
        name: 'ROI Analysis Dashboard', 
        description: 'Track campaign return on investment metrics', 
        project: 'project-1', 
        phase: 'phase-1-2' 
      },
      { 
        id: 'flow-proj1-phase2-2', 
        name: 'Audience Segmentation', 
        description: 'Analyze campaign performance by audience segment', 
        project: 'project-1', 
        phase: 'phase-1-2' 
      },
      { 
        id: 'flow-proj1-phase2-3', 
        name: 'Conversion Path Analysis', 
        description: 'Track user journey to conversion', 
        project: 'project-1', 
        phase: 'phase-1-2' 
      }
    ]
  },
  // Project 2: Customer Behavior Analysis (9 flows: 3 general + 6 phase)
  'project-2': {
    general: [
      { 
        id: 'flow-proj2-1', 
        name: 'Customer Lifetime Value', 
        description: 'Track and analyze customer lifetime value metrics', 
        project: 'project-2' 
      },
      { 
        id: 'flow-proj2-2', 
        name: 'Churn Risk Assessment', 
        description: 'Monitor and predict customer churn patterns', 
        project: 'project-2' 
      },
      { 
        id: 'flow-proj2-3', 
        name: 'Customer Satisfaction Metrics', 
        description: 'Track NPS and satisfaction indicators', 
        project: 'project-2' 
      }
    ],
    'phase-2-1': [
      { 
        id: 'flow-proj2-phase1-1', 
        name: 'Behavioral Data Collection', 
        description: 'Set up customer interaction tracking', 
        project: 'project-2', 
        phase: 'phase-2-1' 
      },
      { 
        id: 'flow-proj2-phase1-2', 
        name: 'Customer Profile Setup', 
        description: 'Configure unified customer profile creation', 
        project: 'project-2', 
        phase: 'phase-2-1' 
      },
      { 
        id: 'flow-proj2-phase1-3', 
        name: 'Feedback Integration', 
        description: 'Set up customer feedback collection points', 
        project: 'project-2', 
        phase: 'phase-2-1' 
      }
    ],
    'phase-2-2': [
      { 
        id: 'flow-proj2-phase2-1', 
        name: 'Behavioral Pattern Analysis', 
        description: 'Identify key customer behavior patterns', 
        project: 'project-2', 
        phase: 'phase-2-2' 
      },
      { 
        id: 'flow-proj2-phase2-2', 
        name: 'Predictive Analytics Setup', 
        description: 'Configure customer behavior prediction models', 
        project: 'project-2', 
        phase: 'phase-2-2' 
      },
      { 
        id: 'flow-proj2-phase2-3', 
        name: 'Customer Journey Mapping', 
        description: 'Map and analyze customer journey touchpoints', 
        project: 'project-2', 
        phase: 'phase-2-2' 
      }
    ]
  },
  // Project 3: Sales Performance Analytics (9 flows: 3 general + 6 phase)
  'project-3': {
    general: [
      { 
        id: 'flow-proj3-1', 
        name: 'Sales Pipeline Overview', 
        description: 'Monitor overall sales pipeline health', 
        project: 'project-3' 
      },
      { 
        id: 'flow-proj3-2', 
        name: 'Revenue Forecasting', 
        description: 'Track and predict revenue trends', 
        project: 'project-3' 
      },
      { 
        id: 'flow-proj3-3', 
        name: 'Sales Team Performance', 
        description: 'Analyze individual and team sales metrics', 
        project: 'project-3' 
      }
    ],
    'phase-3-1': [
      { 
        id: 'flow-proj3-phase1-1', 
        name: 'Sales Data Integration', 
        description: 'Connect CRM and sales data sources', 
        project: 'project-3', 
        phase: 'phase-3-1' 
      },
      { 
        id: 'flow-proj3-phase1-2', 
        name: 'Lead Scoring Setup', 
        description: 'Configure lead qualification criteria', 
        project: 'project-3', 
        phase: 'phase-3-1' 
      },
      { 
        id: 'flow-proj3-phase1-3', 
        name: 'Deal Tracking Config', 
        description: 'Set up deal progress monitoring', 
        project: 'project-3', 
        phase: 'phase-3-1' 
      }
    ],
    'phase-3-2': [
      { 
        id: 'flow-proj3-phase2-1', 
        name: 'Win-Loss Analysis', 
        description: 'Analyze deal success and failure patterns', 
        project: 'project-3', 
        phase: 'phase-3-2' 
      },
      { 
        id: 'flow-proj3-phase2-2', 
        name: 'Sales Cycle Analysis', 
        description: 'Track and optimize sales cycle duration', 
        project: 'project-3', 
        phase: 'phase-3-2' 
      },
      { 
        id: 'flow-proj3-phase2-3', 
        name: 'Territory Performance', 
        description: 'Analyze sales performance by region', 
        project: 'project-3', 
        phase: 'phase-3-2' 
      }
    ]
  }
};

export class MockRecordManagementServer {
  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getRecordGroups(): Promise<RecordsGroup[]> {
    await this.delay(300);
    return [...mockGroups];
  }

  async getRecordGroupLists(): Promise<RecordGroupList[]> {
    await this.delay(300);
    return [
      {
        id: 'recent',
        title: 'Recent',
        defaultExpanded: false,
        groups: mockGroups.filter(g => !g.isPinned)
      },
      {
        id: 'highlighted',
        title: 'Highlighted',
        defaultExpanded: true,
        groups: mockGroups.filter(g => g.isPinned)
      }
    ];
  }

  async toggleGroupPin(groupId: string): Promise<void> {
    await this.delay(200);
    const group = mockGroups.find(g => g.id === groupId);
    if (group) {
      group.isPinned = !group.isPinned;
    }
  }

  async toggleGroupExpansion(groupId: string): Promise<void> {
    await this.delay(200);
    const group = mockGroups.find(g => g.id === groupId);
    if (group) {
      group.isExpanded = !group.isExpanded;
    }
  }

  getDataCollectionStage(): RecordGroupList {
    return {
      id: 'data-collection',
      title: 'Data Collection Stage',
      defaultExpanded: true,
      groups: [
        {
          id: 'infrastructure-setup',
          title: 'Infrastructure Setup',
          description: 'Setup and configuration of data infrastructure',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            dataCollectionTags[0], // Infrastructure
            dataCollectionTags[2]  // Quality Control - for monitoring and validation
          ],
          records: [
            {
              id: 'aws-config',
              icon: 'cloud',
              name: 'AWS configuration for market data table v2.1',
              description: 'Configuration settings for market data table version 2.1',
              recommendedFlows: [
                { id: 'flow-aws-1', name: 'Update Table Schema', description: 'Update the table schema configuration' },
                { id: 'flow-aws-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-aws-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'infra-diagram',
              icon: 'architecture',
              name: 'Infrastructure diagram for customer model 2024',
              description: 'System architecture diagram for customer modeling',
              recommendedFlows: [
                { id: 'flow-diag-1', name: 'Export Diagram as PDF', description: 'Export the diagram to PDF format' },
                { id: 'flow-diag-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-diag-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'security-cert',
              icon: 'security',
              name: 'Security certificate for AWS access (exp. 12/2024)',
              description: 'AWS security certificate and access configuration',
              recommendedFlows: [
                { id: 'flow-sec-1', name: 'Renew Certificate', description: 'Renew the security certificate' },
                { id: 'flow-sec-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-sec-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'access-policy',
              icon: 'policy',
              name: 'Access policy file for marketing team Q1',
              description: 'Access policy configuration for marketing team',
              recommendedFlows: [
                { id: 'flow-pol-1', name: 'Add User Roles', description: 'Add new user roles to the policy' },
                { id: 'flow-pol-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-pol-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'backup-schedule',
              icon: 'backup',
              name: 'Backup schedule for BigQuery connector MKT-1',
              description: 'Backup configuration for BigQuery connector',
              recommendedFlows: [
                { id: 'flow-bak-1', name: 'Modify Backup Frequency', description: 'Change the backup schedule frequency' },
                { id: 'flow-bak-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-bak-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-infra-1', name: 'Configure Data Warehouses', description: 'Configure data warehouses and lakes' },
            { id: 'flow-infra-2', name: 'Setup Streaming', description: 'Set up streaming pipelines' },
            { id: 'flow-infra-3', name: 'Setup Logging', description: 'Implement logging systems' },
            { id: 'flow-infra-4', name: 'Setup Backup', description: 'Create backup procedures' }
          ]
        },
        {
          id: 'data-source-integration',
          title: 'Data Sources Integration',
          description: 'Integration with external data sources',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            dataCollectionTags[1], // Data Sources
            dataCollectionTags[0], // Infrastructure - for security and access
            dataCollectionTags[2]  // Quality Control - for data validation
          ],
          records: [
            {
              id: 'db-conn',
              icon: 'database',
              name: 'Connection string for DBMarketing-prod',
              description: 'Production database connection configuration',
              recommendedFlows: [
                { id: 'flow-db-1', name: 'Test Connection', description: 'Test the database connection' },
                { id: 'flow-db-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-db-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'api-key',
              icon: 'key',
              name: 'API key for SalesForce instance MKT-2',
              description: 'SalesForce API authentication configuration',
              recommendedFlows: [
                { id: 'flow-api-1', name: 'Rotate API Key', description: 'Rotate the API key' },
                { id: 'flow-api-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-api-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'sftp-cred',
              icon: 'vpn_key',
              name: 'SFTP credentials for vendor XYZ',
              description: 'SFTP access credentials configuration',
              recommendedFlows: [
                { id: 'flow-sftp-1', name: 'Reset Password', description: 'Reset the SFTP password' },
                { id: 'flow-sftp-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-sftp-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'data-agreement',
              icon: 'description',
              name: 'Data sharing agreement doc v1.2',
              description: 'Data sharing agreement documentation',
              recommendedFlows: [
                { id: 'flow-agr-1', name: 'Request Signature', description: 'Request signature for the agreement' },
                { id: 'flow-agr-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-agr-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-src-1', name: 'New Connection', description: 'Set up new data source connection' },
            { id: 'flow-src-2', name: 'Setup Validation', description: 'Implement data validation rules' },
            { id: 'flow-src-3', name: 'Setup Schedule', description: 'Create data refresh schedule' }
          ]
        },
        {
          id: 'quality-control',
          title: 'Quality Control Setup',
          description: 'Data quality control configuration',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            dataCollectionTags[2], // Quality Control
            dataCollectionTags[1]  // Data Sources - for data profiling
          ],
          records: [
            {
              id: 'quality-dashboard',
              icon: 'dashboard',
              name: 'Data quality dashboard config v1.0',
              description: 'Data quality monitoring dashboard configuration',
              recommendedFlows: [
                { id: 'flow-dash-1', name: 'Customize Metrics', description: 'Customize dashboard metrics' },
                { id: 'flow-dash-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-dash-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'error-logging',
              icon: 'error',
              name: 'Error logging setup for Pipeline-A',
              description: 'Error logging configuration for data pipeline',
              recommendedFlows: [
                { id: 'flow-err-1', name: 'Clear Error Logs', description: 'Clear the error logs' },
                { id: 'flow-err-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-err-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'validation-rules',
              icon: 'rule',
              name: 'Validation ruleset for customer data',
              description: 'Customer data validation rules configuration',
              recommendedFlows: [
                { id: 'flow-val-1', name: 'Add Validation Rule', description: 'Add new validation rule' },
                { id: 'flow-val-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-val-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'quality-metrics',
              icon: 'analytics',
              name: 'Quality metrics baseline doc',
              description: 'Quality metrics baseline configuration',
              recommendedFlows: [
                { id: 'flow-met-1', name: 'Update Thresholds', description: 'Update quality metric thresholds' },
                { id: 'flow-met-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-met-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-qc-1', name: 'Define Thresholds', description: 'Define quality thresholds' },
            { id: 'flow-qc-2', name: 'Setup Alerts', description: 'Set up monitoring alerts' },
            { id: 'flow-qc-3', name: 'Create Reports', description: 'Create quality reports' }
          ]
        }
      ]
    };
  }

  getProcessingStage(): RecordGroupList {
    return {
      id: 'processing',
      title: 'Processing Stage',
      defaultExpanded: true,
      groups: [
        {
          id: 'data-transformation',
          title: 'Data Transformation',
          description: 'Data transformation and feature engineering processes',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            processingTags[0], // Data Transformation
            processingTags[1]  // Business Rules - for validation and constraints
          ],
          records: [
            {
              id: 'transform-logic',
              icon: 'transform',
              name: 'Transformation logic for Campaign-2024-Q1',
              description: 'Data transformation logic for Q1 campaign',
              recommendedFlows: [
                { id: 'flow-trans-1', name: 'Preview Transformation', description: 'Preview transformation results' },
                { id: 'flow-trans-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-trans-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'feature-dict',
              icon: 'menu_book',
              name: 'Feature dictionary for Customer Churn Model',
              description: 'Feature definitions for churn prediction',
              recommendedFlows: [
                { id: 'flow-feat-1', name: 'Add New Feature', description: 'Add a new feature definition' },
                { id: 'flow-feat-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-feat-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'cleaning-rules',
              icon: 'cleaning_services',
              name: 'Data cleaning rules for Product Dataset',
              description: 'Data cleaning rules configuration',
              recommendedFlows: [
                { id: 'flow-clean-1', name: 'Test Cleaning Rule', description: 'Test data cleaning rule' },
                { id: 'flow-clean-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-clean-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'enrichment-workflow',
              icon: 'add_circle',
              name: 'Enrichment workflow config MKT-3',
              description: 'Data enrichment workflow configuration',
              recommendedFlows: [
                { id: 'flow-enrich-1', name: 'Run Enrichment Job', description: 'Execute enrichment workflow' },
                { id: 'flow-enrich-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-enrich-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-dt-1', name: 'Design Pipeline', description: 'Design transformation pipeline' },
            { id: 'flow-dt-2', name: 'Generate Features', description: 'Implement feature generation' },
            { id: 'flow-dt-3', name: 'Setup Validation', description: 'Set up data validation' }
          ]
        },
        {
          id: 'business-rules',
          title: 'Business Rules Implementation',
          description: 'Implementation of business rules and calculations',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            processingTags[1], // Business Rules
            processingTags[0], // Data Transformation
            processingTags[2]  // Performance - for optimization
          ],
          records: [
            {
              id: 'segmentation-rules',
              icon: 'segment',
              name: 'Customer segmentation rules v2.0',
              description: 'Customer segmentation rules configuration',
              recommendedFlows: [
                { id: 'flow-seg-1', name: 'Simulate Segmentation', description: 'Run segmentation simulation' },
                { id: 'flow-seg-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-seg-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'revenue-calc',
              icon: 'calculate',
              name: 'Revenue calculation method doc',
              description: 'Revenue calculation methodology documentation',
              recommendedFlows: [
                { id: 'flow-rev-1', name: 'Validate Calculations', description: 'Validate calculation methods' },
                { id: 'flow-rev-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-rev-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'business-constraints',
              icon: 'rule',
              name: 'Business constraints for Model-A',
              description: 'Business constraints configuration',
              recommendedFlows: [
                { id: 'flow-con-1', name: 'Update Constraint', description: 'Update business constraint' },
                { id: 'flow-con-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-con-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'validation-thresholds',
              icon: 'tune',
              name: 'Validation thresholds for Campaign-X',
              description: 'Validation thresholds configuration',
              recommendedFlows: [
                { id: 'flow-val-1', name: 'Adjust Threshold', description: 'Adjust validation threshold' },
                { id: 'flow-val-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-val-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-br-1', name: 'Define Rules', description: 'Define business rules' },
            { id: 'flow-br-2', name: 'Implement Methods', description: 'Implement calculation methods' },
            { id: 'flow-br-3', name: 'Create Procedures', description: 'Create validation procedures' }
          ]
        },
        {
          id: 'performance-optimization',
          title: 'Performance Optimization',
          description: 'Query and resource optimization',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            processingTags[2], // Performance
            processingTags[1]  // Business Rules - for optimization constraints
          ],
          records: [
            {
              id: 'query-optimization',
              icon: 'speed',
              name: 'Query optimization report Pipeline-B',
              description: 'Query performance optimization report',
              recommendedFlows: [
                { id: 'flow-qopt-1', name: 'Generate Execution Plan', description: 'Generate query execution plan' },
                { id: 'flow-qopt-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-qopt-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'resource-allocation',
              icon: 'memory',
              name: 'Resource allocation plan Q1',
              description: 'Resource allocation planning',
              recommendedFlows: [
                { id: 'flow-res-1', name: 'Scale Resources', description: 'Scale allocated resources' },
                { id: 'flow-res-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-res-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'cache-config',
              icon: 'cached',
              name: 'Cache configuration for Dataset-X',
              description: 'Caching configuration settings',
              recommendedFlows: [
                { id: 'flow-cache-1', name: 'Clear Cache', description: 'Clear cached data' },
                { id: 'flow-cache-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-cache-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-perf-1', name: 'Optimize Queries', description: 'Optimize query performance' },
            { id: 'flow-perf-2', name: 'Configure Cache', description: 'Configure caching rules' },
            { id: 'flow-perf-3', name: 'Monitor Resources', description: 'Monitor resource usage' }
          ]
        }
      ]
    };
  }

  getModelingStage(): RecordGroupList {
    return {
      id: 'modeling',
      title: 'Model Development Stage',
      defaultExpanded: true,
      groups: [
        {
          id: 'model-architecture',
          title: 'Model Architecture',
          description: 'Model architecture design and configuration',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            modelingTags[0], // Model Architecture
            modelingTags[1]  // Training Pipeline - for parameter configuration
          ],
          records: [
            {
              id: 'arch-doc',
              icon: 'architecture',
              name: 'Model architecture doc for Project-Y',
              description: 'Model architecture documentation and diagrams',
              recommendedFlows: [
                { id: 'flow-arch-1', name: 'Export Diagram', description: 'Export architecture diagram' },
                { id: 'flow-arch-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-arch-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'hyperparam',
              icon: 'tune',
              name: 'Hyperparameter config for Model-2024',
              description: 'Model hyperparameter configuration',
              recommendedFlows: [
                { id: 'flow-param-1', name: 'Run Grid Search', description: 'Execute hyperparameter grid search' },
                { id: 'flow-param-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-param-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'cv-setup',
              icon: 'repeat',
              name: 'Cross-validation setup for Churn Prediction',
              description: 'Cross-validation configuration',
              recommendedFlows: [
                { id: 'flow-cv-1', name: 'Modify Fold Count', description: 'Update cross-validation fold count' },
                { id: 'flow-cv-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-cv-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'model-compare',
              icon: 'compare',
              name: 'Model comparison report v1.1',
              description: 'Model comparison analysis',
              recommendedFlows: [
                { id: 'flow-comp-1', name: 'Update Metrics', description: 'Update comparison metrics' },
                { id: 'flow-comp-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-comp-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-ma-1', name: 'Select Architecture', description: 'Select model architecture' },
            { id: 'flow-ma-2', name: 'Configure Parameters', description: 'Configure model parameters' },
            { id: 'flow-ma-3', name: 'Design Strategy', description: 'Design validation strategy' }
          ]
        },
        {
          id: 'training-pipeline',
          title: 'Training Pipeline',
          description: 'Model training pipeline configuration',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            modelingTags[1], // Training Pipeline
            modelingTags[0], // Model Architecture
            modelingTags[2]  // Model Validation - for evaluation
          ],
          records: [
            {
              id: 'pipeline-config',
              icon: 'settings_input_component',
              name: 'Training pipeline config for Model-A',
              description: 'Training pipeline configuration',
              recommendedFlows: [
                { id: 'flow-pipe-1', name: 'Execute Dry Run', description: 'Run pipeline in dry-run mode' },
                { id: 'flow-pipe-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-pipe-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'preprocess-steps',
              icon: 'transform',
              name: 'Preprocessing steps for Dataset-2024',
              description: 'Data preprocessing configuration',
              recommendedFlows: [
                { id: 'flow-prep-1', name: 'Preview Transforms', description: 'Preview preprocessing transformations' },
                { id: 'flow-prep-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-prep-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'eval-metrics',
              icon: 'analytics',
              name: 'Evaluation metrics baseline doc',
              description: 'Model evaluation metrics baseline',
              recommendedFlows: [
                { id: 'flow-eval-1', name: 'Recalculate Baseline', description: 'Update evaluation baseline' },
                { id: 'flow-eval-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-eval-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'version-setup',
              icon: 'history',
              name: 'Model versioning setup v1.0',
              description: 'Model version control configuration',
              recommendedFlows: [
                { id: 'flow-ver-1', name: 'Create New Version', description: 'Create new model version' },
                { id: 'flow-ver-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-ver-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-tp-1', name: 'Setup Pipeline', description: 'Set up training pipeline' },
            { id: 'flow-tp-2', name: 'Configure Metrics', description: 'Configure evaluation metrics' },
            { id: 'flow-tp-3', name: 'Setup Versioning', description: 'Implement version control' }
          ]
        },
        {
          id: 'model-validation',
          title: 'Model Validation',
          description: 'Model testing and validation procedures',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            modelingTags[2], // Model Validation
            modelingTags[1]  // Training Pipeline - for performance evaluation
          ],
          records: [
            {
              id: 'test-cases',
              icon: 'rule',
              name: 'Test cases for Model-X v1.2',
              description: 'Model test cases configuration',
              recommendedFlows: [
                { id: 'flow-test-1', name: 'Run Test Suite', description: 'Execute test case suite' },
                { id: 'flow-test-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-test-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'perf-report',
              icon: 'assessment',
              name: 'Performance report Campaign-2024',
              description: 'Model performance analysis',
              recommendedFlows: [
                { id: 'flow-perf-1', name: 'Generate Report', description: 'Generate performance report' },
                { id: 'flow-perf-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-perf-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'validation-checklist',
              icon: 'checklist',
              name: 'Business validation checklist',
              description: 'Business validation requirements',
              recommendedFlows: [
                { id: 'flow-check-1', name: 'Mark Complete', description: 'Mark validation steps complete' },
                { id: 'flow-check-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-check-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'accuracy-baseline',
              icon: 'speed',
              name: 'Model accuracy baseline doc',
              description: 'Model accuracy baseline metrics',
              recommendedFlows: [
                { id: 'flow-acc-1', name: 'Compare Versions', description: 'Compare version accuracies' },
                { id: 'flow-acc-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-acc-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-mv-1', name: 'Design Tests', description: 'Design test procedures' },
            { id: 'flow-mv-2', name: 'Setup Dashboards', description: 'Set up monitoring dashboards' },
            { id: 'flow-mv-3', name: 'Create Reports', description: 'Create validation reports' }
          ]
        }
      ]
    };
  }

  getProductionStage(): RecordGroupList {
    return {
      id: 'production',
      title: 'Production Stage',
      defaultExpanded: true,
      groups: [
        {
          id: 'deployment-management',
          title: 'Deployment Management',
          description: 'Deployment and release management',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            productionTags[0], // Deployment
            productionTags[1]  // Monitoring - for deployment monitoring
          ],
          records: [
            {
              id: 'deploy-checklist',
              icon: 'checklist',
              name: 'Deployment checklist for Model-2024',
              description: 'Deployment process checklist',
              recommendedFlows: [
                { id: 'flow-check-1', name: 'Mark Step Complete', description: 'Mark deployment step as complete' },
                { id: 'flow-check-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-check-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'prod-env-config',
              icon: 'settings',
              name: 'Production environment config v2.0',
              description: 'Production environment configuration',
              recommendedFlows: [
                { id: 'flow-env-1', name: 'Test Config', description: 'Test environment configuration' },
                { id: 'flow-env-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-env-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'release-notes',
              icon: 'description',
              name: 'Release notes for Version 1.2',
              description: 'Version release documentation',
              recommendedFlows: [
                { id: 'flow-rel-1', name: 'Add Known Issue', description: 'Add known issue to release notes' },
                { id: 'flow-rel-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-rel-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'rollback-proc',
              icon: 'restore',
              name: 'Rollback procedure doc',
              description: 'Rollback procedures documentation',
              recommendedFlows: [
                { id: 'flow-roll-1', name: 'Simulate Rollback', description: 'Run rollback simulation' },
                { id: 'flow-roll-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-roll-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-dep-1', name: 'Configure Pipeline', description: 'Configure deployment pipeline' },
            { id: 'flow-dep-2', name: 'Setup Alerts', description: 'Set up monitoring alerts' },
            { id: 'flow-dep-3', name: 'Create Procedures', description: 'Create rollback procedures' }
          ]
        },
        {
          id: 'performance-monitoring',
          title: 'Performance Monitoring',
          description: 'System and model performance monitoring',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            productionTags[1], // Monitoring
            productionTags[0], // Deployment - for system configuration
            productionTags[2]  // Maintenance - for optimization
          ],
          records: [
            {
              id: 'monitor-dashboard',
              icon: 'dashboard',
              name: 'Monitoring dashboard config v1.1',
              description: 'Monitoring dashboard configuration',
              recommendedFlows: [
                { id: 'flow-dash-1', name: 'Refresh Widgets', description: 'Refresh dashboard widgets' },
                { id: 'flow-dash-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-dash-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'perf-baseline',
              icon: 'speed',
              name: 'Performance baseline for Model-A',
              description: 'Model performance baseline metrics',
              recommendedFlows: [
                { id: 'flow-base-1', name: 'Update Threshold', description: 'Update performance threshold' },
                { id: 'flow-base-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-base-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'alert-config',
              icon: 'notifications',
              name: 'Alert configuration for System-X',
              description: 'System alert configuration',
              recommendedFlows: [
                { id: 'flow-alert-1', name: 'Test Alert', description: 'Test alert configuration' },
                { id: 'flow-alert-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-alert-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'kpi-tracking',
              icon: 'analytics',
              name: 'KPI tracking setup doc',
              description: 'KPI tracking configuration',
              recommendedFlows: [
                { id: 'flow-kpi-1', name: 'Add Metric', description: 'Add new KPI metric' },
                { id: 'flow-kpi-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-kpi-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-mon-1', name: 'Setup Monitoring', description: 'Set up monitoring systems' },
            { id: 'flow-mon-2', name: 'Configure Thresholds', description: 'Configure alert thresholds' },
            { id: 'flow-mon-3', name: 'Create Reports', description: 'Create performance reports' }
          ]
        },
        {
          id: 'maintenance-planning',
          title: 'Maintenance Planning',
          description: 'System maintenance and resource optimization',
          date: new Date().toISOString(),
          isPinned: false,
          isExpanded: true,
          tags: [
            productionTags[2], // Maintenance
            productionTags[1]  // Monitoring - for performance tracking
          ],
          records: [
            {
              id: 'maint-schedule',
              icon: 'schedule',
              name: 'Maintenance schedule 2024-Q1',
              description: 'Maintenance schedule planning',
              recommendedFlows: [
                { id: 'flow-sched-1', name: 'Reschedule Task', description: 'Reschedule maintenance task' },
                { id: 'flow-sched-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-sched-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'resource-opt',
              icon: 'memory',
              name: 'Resource optimization plan v1.0',
              description: 'Resource optimization planning',
              recommendedFlows: [
                { id: 'flow-opt-1', name: 'Scale Resources', description: 'Scale system resources' },
                { id: 'flow-opt-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-opt-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'doc-update',
              icon: 'history_edu',
              name: 'Documentation update log',
              description: 'Documentation update tracking',
              recommendedFlows: [
                { id: 'flow-doc-1', name: 'Add Entry', description: 'Add documentation update entry' },
                { id: 'flow-doc-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-doc-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            },
            {
              id: 'backup-verify',
              icon: 'backup',
              name: 'Backup verification report',
              description: 'Backup verification status',
              recommendedFlows: [
                { id: 'flow-bak-1', name: 'Verify Backup', description: 'Verify backup integrity' },
                { id: 'flow-bak-2', name: 'Edit Record', description: 'Edit this record' },
                { id: 'flow-bak-3', name: 'Delete Record', description: 'Delete this record' }
              ]
            }
          ],
          recommendedFlows: [
            { id: 'flow-maint-1', name: 'Create Schedule', description: 'Create maintenance schedule' },
            { id: 'flow-maint-2', name: 'Optimize Usage', description: 'Optimize resource usage' },
            { id: 'flow-maint-3', name: 'Update Docs', description: 'Update documentation' }
          ]
        }
      ]
    };
  }
}
