<!-- src/app/components/session/message/message.component.html -->
<div class="message-container" [ngClass]="{'user': message.role === 'user', 'system': message.role === 'agent'}">
  <!-- System (Assistant) Message -->
  <div *ngIf="message.role === 'agent'" class="agent-avatar">
    <div class="relative flex h-7 w-7 items-center justify-center rounded-sm bg-token-main-surface-primary">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 154 120">
        <!-- Left cube -->
        <path fill="#E5E7EB" d="M102.489 90.414V29.586L51.244 0 0 29.586v60.828L51.244 120l51.245-29.586Z"/>
        <path fill="#D1D5DB" d="M102.489 90.414V29.586L51.245 59.172V120l51.244-29.586Z"/>
        <path fill="#9CA3AF" d="M102.489 29.586 51.244 0 0 29.586l51.244 29.586 51.245-29.586Z"/>
        
        <!-- Right cube -->
        <path fill="#F3F4F6" d="M153.734 90.414V29.586L102.489 0 51.245 29.586v60.828L102.49 120l51.245-29.586Z"/>
        <path fill="#E5E7EB" d="M153.733 90.414V29.586l-51.244 29.586V120l51.244-29.586Z"/>
        <path fill="#D1D5DB" d="M153.734 29.586 102.489 0 51.245 29.586l51.244 29.586 51.245-29.586Z"/>
        
        <!-- Center cube -->
        <path fill="#D1D5DB" d="M51.245 29.585 76.867 44.38l25.623-14.793-25.623-14.793-25.622 14.793Z"/>
        <path fill="#9CA3AF" d="m76.866 44.38 25.623 14.792V29.586L76.866 44.38Z"/>
        <path fill="#F3F4F6" d="M51.245 59.173v31.242l25.622 14.793 25.623-14.793V59.173L76.867 44.38 51.245 59.173Z"/>
        <path fill="#6B7280" d="M76.866 105.206V74.792l25.623-15.62v31.241l-25.623 14.793Z"/>
        <path fill="#9CA3AF" d="m51.245 59.173 25.622 15.62 25.623-15.62L76.867 44.38 51.245 59.173Z"/>
        <path fill="#E5E7EB" d="M76.866 44.38v30.414l25.623-15.621L76.866 44.38Z" style="mix-blend-mode:multiply"/>
        <path fill="#D1D5DB" d="M76.867 44.38 51.245 59.171V29.586L76.867 44.38Z"/>
      </svg>
    </div>
  </div>
  
  <div class="message-wrapper">
    <!-- Edit button for user messages -->
    <button *ngIf="message.role === 'user'" 
            class="edit-button"
            (click)="onEdit()"
            aria-label="Edit message">
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.2929 4.29291C15.0641 2.52167 17.9359 2.52167 19.7071 4.2929C21.4784 6.06414 21.4784 8.93588 19.7071 10.7071L18.7073 11.7069L11.6135 18.8007C10.8766 19.5376 9.92793 20.0258 8.89999 20.1971L4.16441 20.9864C3.84585 21.0395 3.52127 20.9355 3.29291 20.7071C3.06454 20.4788 2.96053 20.1542 3.01362 19.8356L3.80288 15.1C3.9742 14.0721 4.46243 13.1234 5.19932 12.3865L13.2929 4.29291ZM13 7.41422L6.61353 13.8007C6.1714 14.2428 5.87846 14.8121 5.77567 15.4288L5.21656 18.7835L8.57119 18.2244C9.18795 18.1216 9.75719 17.8286 10.1993 17.3865L16.5858 11L13 7.41422ZM18 9.5858L14.4142 6.00001L14.7071 5.70712C15.6973 4.71693 17.3027 4.71693 18.2929 5.70712C19.2831 6.69731 19.2831 8.30272 18.2929 9.29291L18 9.5858Z" fill="currentColor"></path></svg>
    </button>

    <div class="message" [ngClass]="{'user-message': message.role === 'user', 'system-message': message.role === 'agent'}">
      <div class="message-content">{{ message.content }}</div>
    </div>

    <!-- System Message Actions -->
    <div *ngIf="message.role === 'agent'" class="message-actions">
      <div class="flex items-center gap-0.5">
        <button class="action-button" (click)="onCopy()" aria-label="Copy">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md-heavy"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 5C7 3.34315 8.34315 2 10 2H19C20.6569 2 22 3.34315 22 5V14C22 15.6569 20.6569 17 19 17H17V19C17 20.6569 15.6569 22 14 22H5C3.34315 22 2 20.6569 2 19V10C2 8.34315 3.34315 7 5 7H7V5ZM9 7H14C15.6569 7 17 8.34315 17 10V15H19C19.5523 15 20 14.5523 20 14V5C20 4.44772 19.5523 4 19 4H10C9.44772 4 9 4.44772 9 5V7ZM5 9C4.44772 9 4 9.44772 4 10V19C4 19.5523 4.44772 20 5 20H14C14.5523 20 15 19.5523 15 19V10C15 9.44772 14.5523 9 14 9H5Z" fill="currentColor"></path></svg>
        </button>
        <button class="action-button" (click)="onLike()" aria-label="Good response">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md-heavy"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.1318 2.50389C12.3321 2.15338 12.7235 1.95768 13.124 2.00775L13.5778 2.06447C16.0449 2.37286 17.636 4.83353 16.9048 7.20993L16.354 8.99999H17.0722C19.7097 8.99999 21.6253 11.5079 20.9313 14.0525L19.5677 19.0525C19.0931 20.7927 17.5124 22 15.7086 22H6C4.34315 22 3 20.6568 3 19V12C3 10.3431 4.34315 8.99999 6 8.99999H8C8.25952 8.99999 8.49914 8.86094 8.6279 8.63561L12.1318 2.50389ZM10 20H15.7086C16.6105 20 17.4008 19.3964 17.6381 18.5262L19.0018 13.5262C19.3488 12.2539 18.391 11 17.0722 11H15C14.6827 11 14.3841 10.8494 14.1956 10.5941C14.0071 10.3388 13.9509 10.0092 14.0442 9.70591L14.9932 6.62175C15.3384 5.49984 14.6484 4.34036 13.5319 4.08468L10.3644 9.62789C10.0522 10.1742 9.56691 10.5859 9 10.8098V19C9 19.5523 9.44772 20 10 20ZM7 11V19C7 19.3506 7.06015 19.6872 7.17071 20H6C5.44772 20 5 19.5523 5 19V12C5 11.4477 5.44772 11 6 11H7Z" fill="currentColor"></path></svg>
        </button>
        <button class="action-button" (click)="onDislike()" aria-label="Bad response">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md-heavy"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.8727 21.4961C11.6725 21.8466 11.2811 22.0423 10.8805 21.9922L10.4267 21.9355C7.95958 21.6271 6.36855 19.1665 7.09975 16.7901L7.65054 15H6.93226C4.29476 15 2.37923 12.4921 3.0732 9.94753L4.43684 4.94753C4.91145 3.20728 6.49209 2 8.29589 2H18.0045C19.6614 2 21.0045 3.34315 21.0045 5V12C21.0045 13.6569 19.6614 15 18.0045 15H16.0045C15.745 15 15.5054 15.1391 15.3766 15.3644L11.8727 21.4961ZM14.0045 4H8.29589C7.39399 4 6.60367 4.60364 6.36637 5.47376L5.00273 10.4738C4.65574 11.746 5.61351 13 6.93226 13H9.00451C9.32185 13 9.62036 13.1506 9.8089 13.4059C9.99743 13.6612 10.0536 13.9908 9.96028 14.2941L9.01131 17.3782C8.6661 18.5002 9.35608 19.6596 10.4726 19.9153L13.6401 14.3721C13.9523 13.8258 14.4376 13.4141 15.0045 13.1902V5C15.0045 4.44772 14.5568 4 14.0045 4ZM17.0045 13V5C17.0045 4.64937 16.9444 4.31278 16.8338 4H18.0045C18.5568 4 19.0045 4.44772 19.0045 5V12C19.0045 12.5523 18.5568 13 18.0045 13H17.0045Z" fill="currentColor"></path></svg>
        </button>
        <button class="action-button" (click)="onDislike()" aria-label="Bad response">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md"><path d="M3.06957 10.8763C3.62331 6.43564 7.40967 3 12 3C14.2824 3 16.4028 3.85067 18.0118 5.25439V4C18.0118 3.44772 18.4595 3 19.0118 3C19.5641 3 20.0118 3.44772 20.0118 4V8C20.0118 8.55228 19.5641 9 19.0118 9H15C14.4477 9 14 8.55228 14 8C14 7.44772 14.4477 7 15 7H16.9571C15.6757 5.76379 13.9101 5 12 5C8.43108 5 5.48466 7.67174 5.0542 11.1237C4.98586 11.6718 4.48619 12.0607 3.93815 11.9923C3.39011 11.924 3.00123 11.4243 3.06957 10.8763ZM10 20H15.7086C16.6105 20 17.4008 19.3964 17.6381 18.5262L19.0018 13.5262C19.3488 12.2539 18.391 11 17.0722 11H15C14.6827 11 14.3841 10.8494 14.1956 10.5941C14.0071 10.3388 13.9509 10.0092 14.0442 9.70591L14.9932 6.62175C15.3384 5.49984 14.6484 4.34036 13.5319 4.08468L10.3644 9.62789C10.0522 10.1742 9.56691 10.5859 9 10.8098V19C9 19.5523 9.44772 20 10 20ZM7 11V19C7 19.3506 7.06015 19.6872 7.17071 20H6C5.44772 20 5 19.5523 5 19V12C5 11.4477 5.44772 11 6 11H7Z" fill="currentColor"></path></svg>
        </button>
      </div>
    </div>
  </div>
</div>