/**
 * @file src/app/services/tools/accept-suggested-workspace-component-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';

/**
 * Tool: acceptSuggestedWorkspaceComponent
 * Accepts all suggested workstreams and sessions from a workspace component.
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerAcceptSuggestedWorkspaceComponentTool(service: ToolsService): void {
  (service as any).acceptSuggestedWorkspaceComponent = async function(next: string, sessionId: string, riffMLNode: RiffMLNode | null, value:any): Promise<void> {
    console.log(`ToolsService.acceptSuggestedWorkspaceComponent() called with listId=${next}`);
    
    // Get the current session ID
    if (!sessionId) {
      console.error('No active session found');
      return;
    }

    // Get the suggested workstreams and sessions from the state manager
    const workboardData: any[] = this.stateManagerService.getObject(sessionId, "session.SuggestedWorkboardNewItems");
    if (!workboardData) {
      console.error(`No data found for suggestedworkstreams: session.SuggestedWorkboardNewItems`);
    }
    else {   
      await this.processAllSuggestedWorkboardItems(workboardData, sessionId);
      this.stateManagerService.deleteState(sessionId, "session.SuggestedWorkboardNewItems");
    }

    const sessionsData: any[] = this.stateManagerService.getObject(sessionId, "session.SuggestedSessionsNewItems");
    if (!sessionsData) {
      console.error(`No data found for suggestedsessions: session.SuggestedWorkboardNewItems`);
      return;
    }
    await this.processAllSuggestedSessions(sessionsData, sessionId);
    this.stateManagerService.deleteState(sessionId, "session.SuggestedSessionsNewItems");
  };

  // Add helper methods to process suggested workboard items and sessions
  (service as any).processAllSuggestedWorkboardItems = async function(dataArray: any[], sessionId: string): Promise<void> {
    if (!Array.isArray(dataArray)) {
      console.error('Expected an array of suggested workboard items');
      return;
    }

    // Process each item sequentially to avoid race conditions
    for (const data of dataArray) {
      await this.processSuggestedWorkboardItems(data, sessionId);
    }
  };

  (service as any).processSuggestedWorkboardItems = async function(data: any, sessionId: string): Promise<void> {
    if (!data.suggestedWorkboardNewItems?.groups) {
      return;
    }

    // The message ID is needed for the change manager calls
    const messageId = data.messageId || '';

    // Process each group, column, and workstream
    for (const group of data.suggestedWorkboardNewItems.groups) {
      if (!group.workstreamCols) {
        continue;
      }

      for (const col of group.workstreamCols) {
        if (!col.workstreams) {
          continue;
        }

        for (const workstream of col.workstreams) {
          // Accept each workstream
          try {
            await this.changeManagerService.acceptWorkstream({
              messageId,
              sessionId,
              workstream,
              group,
              col
            });
          } catch (error) {
            console.error(`Error accepting workstream ${workstream.id}:`, error);
          }
        }
      }
    }
  };

  (service as any).processAllSuggestedSessions = async function(dataArray: any[], sessionId: string): Promise<void> {
    if (!Array.isArray(dataArray)) {
      console.error('Expected an array of suggested sessions');
      return;
    }

    // Process each item sequentially to avoid race conditions
    for (const data of dataArray) {
      await this.processSuggestedSessions(data, sessionId);
    }
  };

  (service as any).processSuggestedSessions = async function(data: any, sessionId: string): Promise<void> {
    const messageId = data.messageId || '';

    // Process existing session suggestions
    if (data.suggestedExistingSessions) {
      for (const session of data.suggestedExistingSessions) {
        try {
          await this.changeManagerService.openExistingSession({
            messageId,
            sessionId,
            session
          });
        } catch (error) {
          console.error(`Error opening existing session ${session.id}:`, error);
        }
      }
    }

    // Process new session suggestions
    if (data.suggestedNewSessions) {
      for (const session of data.suggestedNewSessions) {
        try {
          await this.changeManagerService.acceptNewSession({
            messageId,
            sessionId,
            session
          });
        } catch (error) {
          console.error(`Error accepting new session:`, error);
        }
      }
    }


    //setTimeout(() => {
    //  // Send a message to the chat component to send a message
    //  this.workspaceState.sendChatMessage$.next({ sessionId, message: 'I have accepted the changes' });
    //}, 400);

  };
}