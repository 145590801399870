/**
 * @file src/app/services/tools/runSql-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { SqlResponse } from '../../models/sql.model';
import { TableModel } from '../../models/tables.model';

/**
 * Tool: RunSql
 * Calls a Snowflake SQL query and stores the result in the state manager
 * Also supports previewing the SQL result in the workspace table
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerRunSqlTool(service: ToolsService): void {
  (service as any).runSql = async function(nextVal: string, sessionId: string, riffMLNode: RiffMLNode | null, value: any): Promise<void> {
    if (!riffMLNode) return;
    
    // Get parameters from node attributes
    const runCommand = riffMLNode.attributes['runCommand'] === 'true';
    const addToState = riffMLNode.attributes['addToState'];
    const storeTarget = riffMLNode.attributes['storeTarget'];
    const storeTargetId = riffMLNode.attributes['storeTargetId'] ?? storeTarget;
    const storeTargetName = riffMLNode.attributes['storeTargetName'] ?? storeTarget;
    const storeTargetDescription = riffMLNode.attributes['storeTargetDescription'] ?? '';
    const storeTargetWhyRelevant = riffMLNode.attributes['storeTargetWhyRelevant'] ?? '';
    const storeTargetType = riffMLNode.attributes['storeTargetType'] ?? storeTarget;
    const previewSqlTable = riffMLNode.attributes['previewSqlTable'] === 'true';
    const outputType = riffMLNode.attributes['outputType']?? 'table';
    const storedSql = riffMLNode.attributes['storedSql'];

    
    if (!runCommand && !addToState && !storeTarget && !previewSqlTable && !storedSql) return;
    if (!value || !value.length) return;
    
    try {
      // Update workspace state if preview is enabled
      if (previewSqlTable) {
        // Update the current SQL in workspace state
        this.workspaceState.CurrentSql$.next(value);
        // Clear current table before executing query
        this.workspaceState.CurrentTable$.next(null);
      }
      
      if (runCommand){
        await this.runSqlCommand(value);
        return;
      }

      // Convert observable to promise for proper async/await handling
      const response: SqlResponse = await firstValueFrom(
        this.snowflakeService.executeQueryObservable(value)
      );
      
      if (response.Success) {
        console.log('runSql: SQL query executed successfully:', response);

        // Update preview table if enabled
        if (previewSqlTable) {
          this.workspaceState.CurrentTable$.next(response.Data);
        }

        let outData = response.Data;
        if (outputType === 'table'){
          outData = response.Data;
        } else if (outputType === 'jsonobject' || outputType === 'string'){
          let tempData = response.Data as TableModel;
          if (tempData && tempData.data && tempData.data.length && tempData.data[0] && tempData.data[0].length){
            outData = tempData.data[0][0];
          }

          if (outputType === 'jsonobject'){
              try {
                if (outData.trim().startsWith('{') || outData.trim().startsWith('[')) {
                  value = this.jsonParser.safeParseJson(outData);
                  if (value)
                  {
                    outData = value;
                  }
                }
              } catch (e) {
           }
          }
        }
        
        if (storedSql)
        {
          await this.setState(
            sessionId, 
            storedSql, 
            storedSql, 
            storedSql, 
            storedSql, 
            storedSql, 
            'Table', 
            value
          );
        }

        // Use await to ensure state is updated before proceeding
        if (storeTarget) {
          await this.setState(
            sessionId, 
            storeTarget, 
            storeTargetId, 
            storeTargetName, 
            storeTargetDescription, 
            storeTargetWhyRelevant, 
            storeTargetType, 
            outData
          );
        } else if (addToState) {
          await this.addToState(sessionId, addToState, response.Data);
        }
        
      } else {
        console.error('runSql: SQL query failed:', response.ErrorMessage);
        
        // Update preview table with error if enabled
        if (previewSqlTable) {
          this.workspaceState.CurrentTable$.next({
            error: true,
            message: response.ErrorMessage || 'Query execution failed'
          });
        }
        
        throw new Error(response.ErrorMessage || 'SQL query execution failed');
      }
    } catch (error: unknown) {
      console.error('runSql: Exception during SQL query execution:', error);
      
      // Update preview table with error if enabled
      if (previewSqlTable) {
        this.workspaceState.CurrentTable$.next({
          error: true,
          message: error instanceof Error ? error.message : 'Unknown error during query execution'
        });
      }
      
      throw error; // Re-throw the error for proper error handling up the chain
    }
  };

  (service as any).runSqlCommand = async function(value: any): Promise<void> {  
    
    if (!value || !value.length) return;
    
    try {
      // Convert observable to promise for proper async/await handling
      const response: SqlResponse = await firstValueFrom(
        this.snowflakeService.executeCommandObservable(value)
      );
      
      if (response.Success) {
        console.log('runSqlCommand: SQL command executed successfully:', response);
        
      } else {
        console.error('runSqlCommand: SQL command failed:', response.ErrorMessage);    
        throw new Error(response.ErrorMessage || 'SQL query execution failed');
      }
    } catch (error: unknown) {
      console.error('runSqlCommand: Exception during SQL query execution:', error);
      throw error; // Re-throw the error for proper error handling up the chain
    }
  };
}