<div class="tabs" [class.compact]="compact">
  <mat-chip-listbox>
    <app-tab *ngFor="let tab of tabs"
             [label]="tab.label"
             [color]="getColor(tab)"
             [selected]="isSelected(tab)"
             [compact]="compact"
             (click)="selectTab(tab)">
    </app-tab>
  </mat-chip-listbox>
  <button mat-flat-button class="add-project-button" *ngIf="!compact">
    <mat-icon>add</mat-icon>
  </button>
</div>
