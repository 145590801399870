/* src/app/services/change-manager.service.ts */

import { Injectable } from '@angular/core';
import { ChatService } from './chat.service';
import { WorkboardManagerService } from './workboard-manager.service';
import { SessionsManagerService } from './sessions-manager.service';

import {
  ProjectManagerWorkstream,
  ProjectManagerWorkboardGroup,
  ProjectManagerWorkstreamColumn,
  SuggestedSession,
  SuggestedNewSession
} from '../models/project-manager-chat.model';
import { Workstream, WorkboardGroup, WorkstreamColumn } from '../models/workboard.model';

@Injectable({
  providedIn: 'root'
})
export class ChangeManagerService {
  constructor(
    private workboardManagerService: WorkboardManagerService,
    private sessionsManagerService: SessionsManagerService
  ) {}

  /**
   * Accept a suggested workstream.
   */
  public async acceptWorkstream(params: {
    messageId: string;
    sessionId: string;
    workstream: ProjectManagerWorkstream;
    group: ProjectManagerWorkboardGroup;
    col: ProjectManagerWorkstreamColumn;
  }): Promise<void> {
    /*await this.chatService.updateWorkstreamChangeStatus(
      params.sessionId,
      params.messageId,
      params.workstream,
      'accepted'
    );*/

    const workboardWorkstream: Workstream = {
      id: params.workstream.id,
      name: params.workstream.name,
      state: params.workstream.state || 'closed',
      selectedState: params.workstream.selectedState || 'no',
      description: params.workstream.description || '',
      input_table_ids: params.workstream.input_table_ids || [],
      output_table_ids: params.workstream.output_table_ids || [],
      editPlaybook: params.workstream.editPlaybook || undefined,
      editAgentId: params.workstream.editAgentId || undefined,
      editAgentName: params.workstream.editAgentName || undefined,
      outcome: params.workstream.outcome || '',
      type: params.workstream.type || '',
      config: params.workstream.config || {},
      version: params.workstream.version || undefined,
      mingus: params.workstream.mingus || undefined,
      code: params.workstream.code || undefined,
      stagedCode: params.workstream.stagedCode || undefined,
      stagedMingus: params.workstream.stagedMingus || undefined,
      editSession: params.workstream.editSession || undefined,
      units: params.workstream.units || [],
      lastCommitedVersions: params.workstream.lastCommitedVersions || []
    };

    const workboardGroup: WorkboardGroup = {
      id: params.group.id,
      name: params.group.name,
      state: 'open',
      workstreamCols: []
    };

    const workboardCol: WorkstreamColumn = {
      id: params.col.id,
      workstreams: []
    };

    await this.workboardManagerService.addWorkstreamToBoard({
      workstream: workboardWorkstream,
      group: workboardGroup,
      col: workboardCol
    });
  }

  /**
   * Reject a suggested workstream.
   */
  public async rejectWorkstream(params: {
    messageId: string;
    sessionId: string;
    workstream: ProjectManagerWorkstream;
    group: ProjectManagerWorkboardGroup;
    col: ProjectManagerWorkstreamColumn;
  }): Promise<void> {
    /*await this.chatService.updateWorkstreamChangeStatus(
      params.sessionId,
      params.messageId,
      params.workstream,
      'rejected'
    );*/
  }

  /**
   * Open an existing session suggestion.
   * Sets its change status to 'accepted' and ensures the session
   * is available locally, then selects it.
   */
  public async openExistingSession(params: {
    messageId: string;
    sessionId: string;
    session: SuggestedSession;
  }): Promise<void> {
    /*await this.chatService.updateExistingSessionChangeStatus(
      params.sessionId,
      params.messageId,
      params.session,
      'accepted'
    );*/

    // Ensure we have a local session (create if not found) and select it
    await this.sessionsManagerService.updateSelectedSessionFromSuggested(params.session, 0);
  }

  /**
   * Accept a new session suggestion.
   * Creates the session locally and sets its change status to 'accepted'.
   */
  public async acceptNewSession(params: {
    messageId: string;
    sessionId: string;
    session: SuggestedNewSession;
  }): Promise<void> {

    /*var tindex = await this.chatService.updateNewSessionChangeStatus(
      params.sessionId,
      params.messageId,
      params.session,
      'accepted'
    );*/

    // Create the new suggested session locally
    const newSessionId = await this.sessionsManagerService.createSuggestedSession(params.session, 0);
  }

  /**
   * Reject a new session suggestion.
   */
  public async rejectNewSession(params: {
    messageId: string;
    sessionId: string;
    session: SuggestedNewSession;
  }): Promise<void> {
    
    /*await this.chatService.updateNewSessionChangeStatus(
      params.sessionId,
      params.messageId,
      params.session,
      'rejected'
    );*/

  }
}
