// src/app/services/memory-manager.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MemoryItem } from '../models/memory-item.model';
import { ArtifactsService } from './artifacts.service';
import { WorkspaceState } from '../models/workspace-state.model';

/**
 * Interface representing memory items collection for a specific scope
 */
interface MemoriesCollection {
  scopeId: string;
  scopeType: 'workspace' | 'project';
  items: MemoryItem[];
  lastUpdated: string;
}

/**
 * Map structure for efficient memory item lookups
 */
interface MemoryItemsMap {
  byId: { [memoryId: string]: MemoryItem };
  bySessionId: { [sessionId: string]: MemoryItem[] };
  byType: { [type: string]: MemoryItem[] };
  byContextObject: { [objectId: string]: MemoryItem[] };
  byRelatedMemories: { [memoryId: string]: MemoryItem[] };
  byImportance: { [importance: number]: MemoryItem[] };
}

/**
 * Service to manage memory items within workspaces and projects.
 * Provides functionality to save, load, and query memory items by different criteria.
 */
@Injectable({
  providedIn: 'root'
})
export class MemoryManagerService {
  private memoriesMap: { [scopeId: string]: MemoriesCollection } = {};
  private memoryItemsMaps: { [scopeId: string]: MemoryItemsMap } = {};
  
  // BehaviorSubject to track current loaded memories
  private currentMemoriesSubject = new BehaviorSubject<MemoryItem[]>([]);
  public currentMemories$ = this.currentMemoriesSubject.asObservable();
  
  // Current scope tracking
  private currentScopeId: string | null = null;
  private currentScopeType: 'workspace' | 'project' | null = null;

  constructor(
    private artifactsService: ArtifactsService,
    private sharedState: WorkspaceState
  ) {}

  /**
   * Initializes the memory manager with the current workspace/project
   * Will be called on application startup to load relevant memories
   */
  public async initialize(): Promise<void> {
    const workspaceId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace;
    const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject;
    
    if (projectId) {
      await this.loadMemoriesForScope('project', projectId);
    } else if (workspaceId) {
      await this.loadMemoriesForScope('workspace', workspaceId);
    }
  }

  /**
   * Loads memories for a specific scope (workspace or project)
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @returns Array of memory items for the scope
   */
  public async loadMemoriesForScope(
    scopeType: 'workspace' | 'project', 
    scopeId: string
  ): Promise<MemoryItem[]> {
    if (!scopeId) {
      return [];
    }

    this.currentScopeId = scopeId;
    this.currentScopeType = scopeType;

    const artifactId = this.getArtifactId(scopeType, scopeId);
    
    try {
      const memoriesCollection = await this.artifactsService.loadArtifact(artifactId) as MemoriesCollection;
      
      if (memoriesCollection && memoriesCollection.items) {
        this.memoriesMap[scopeId] = memoriesCollection;
        this.buildMemoryItemsMap(scopeId, memoriesCollection.items);
        this.currentMemoriesSubject.next(memoriesCollection.items);
        return memoriesCollection.items;
      } else {
        // Initialize a new empty collection if none exists
        const newCollection: MemoriesCollection = {
          scopeId,
          scopeType,
          items: [],
          lastUpdated: new Date().toISOString()
        };
        this.memoriesMap[scopeId] = newCollection;
        this.buildMemoryItemsMap(scopeId, []);
        this.currentMemoriesSubject.next([]);
        return [];
      }
    } catch (error) {
      console.error(`Error loading memories for ${scopeType} ${scopeId}:`, error);
      // Initialize a new empty collection if none exists
      const newCollection: MemoriesCollection = {
        scopeId,
        scopeType,
        items: [],
        lastUpdated: new Date().toISOString()
      };
      this.memoriesMap[scopeId] = newCollection;
      this.buildMemoryItemsMap(scopeId, []);
      this.currentMemoriesSubject.next([]);
      return [];
    }
  }

  /**
   * Adds a new memory item to the specified scope
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param memoryItem Memory item to add
   * @returns Promise that resolves when the item is saved
   */
  public async addMemoryItem(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    memoryItem: MemoryItem
  ): Promise<MemoryItem> {
    // Load memories if not already loaded
    if (!this.memoriesMap[scopeId]) {
      await this.loadMemoriesForScope(scopeType, scopeId);
    }

    const collection = this.memoriesMap[scopeId];
    
    // Set created_date if not already set
    if (!memoryItem.created_date) {
      memoryItem.created_date = new Date().toISOString();
    }
    
    // Check if memory with this ID already exists
    const existingIndex = collection.items.findIndex(item => item.memory_id === memoryItem.memory_id);
    
    if (existingIndex >= 0) {
      // Update existing item, but preserve the original created_date
      const originalCreatedDate = collection.items[existingIndex].created_date;
      memoryItem.created_date = originalCreatedDate;
      collection.items[existingIndex] = memoryItem;
    } else {
      // Add new item
      collection.items.push(memoryItem);
    }
    
    collection.lastUpdated = new Date().toISOString();
    
    // Update the in-memory map
    this.buildMemoryItemsMap(scopeId, collection.items);
    
    // Save to artifact storage
    await this.saveMemoriesToArtifact(scopeId);
    
    // Update current memories if this is the current scope
    if (scopeId === this.currentScopeId) {
      this.currentMemoriesSubject.next([...collection.items]);
    }
    
    return memoryItem;
  }

  /**
   * Deletes a memory item from the specified scope
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param memoryId ID of the memory item to delete
   * @returns Promise that resolves to true if successful, false if not found
   */
  public async deleteMemoryItem(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    memoryId: string
  ): Promise<boolean> {
    if (!this.memoriesMap[scopeId]) {
      await this.loadMemoriesForScope(scopeType, scopeId);
    }

    const collection = this.memoriesMap[scopeId];
    const initialLength = collection.items.length;
    
    // Filter out the item to delete
    collection.items = collection.items.filter(item => item.memory_id !== memoryId);
    
    if (collection.items.length === initialLength) {
      // No items were removed
      return false;
    }
    
    collection.lastUpdated = new Date().toISOString();
    
    // Update the in-memory map
    this.buildMemoryItemsMap(scopeId, collection.items);
    
    // Save to artifact storage
    await this.saveMemoriesToArtifact(scopeId);
    
    // Update current memories if this is the current scope
    if (scopeId === this.currentScopeId) {
      this.currentMemoriesSubject.next([...collection.items]);
    }
    
    return true;
  }

  /**
   * Retrieves a memory item by its ID
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param memoryId ID of the memory item to retrieve
   * @returns Memory item or null if not found
   */
  public async getMemoryItemById(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    memoryId: string
  ): Promise<MemoryItem | null> {
    if (!this.memoriesMap[scopeId]) {
      await this.loadMemoriesForScope(scopeType, scopeId);
    }

    const map = this.memoryItemsMaps[scopeId];
    return map?.byId[memoryId] || null;
  }

  /**
   * Gets all memory items associated with a session
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param sessionId ID of the session
   * @returns Array of memory items associated with the session
   */
  public async getMemoryItemsBySession(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    sessionId: string
  ): Promise<MemoryItem[]> {
    if (!this.memoriesMap[scopeId]) {
      await this.loadMemoriesForScope(scopeType, scopeId);
    }

    const map = this.memoryItemsMaps[scopeId];
    return map?.bySessionId[sessionId] || [];
  }

  /**
   * Gets all memory items of a specific type
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param memoryType Type of memory items to retrieve
   * @returns Array of memory items of the specified type
   */
  public async getMemoryItemsByType(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    memoryType: string
  ): Promise<MemoryItem[]> {
    if (!this.memoriesMap[scopeId]) {
      await this.loadMemoriesForScope(scopeType, scopeId);
    }

    const map = this.memoryItemsMaps[scopeId];
    return map?.byType[memoryType] || [];
  }

  /**
   * Gets all memory items related to a specific object in context
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param objectId ID of the context object
   * @returns Array of memory items related to the context object
   */
  public async getMemoryItemsByContextObject(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    objectId: string
  ): Promise<MemoryItem[]> {
    if (!this.memoriesMap[scopeId]) {
      await this.loadMemoriesForScope(scopeType, scopeId);
    }

    const map = this.memoryItemsMaps[scopeId];
    return map?.byContextObject[objectId] || [];
  }

  /**
   * Gets all memory items with a specific object type in context
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param objectType Type of object to find in contexts
   * @returns Array of memory items with the object type in context
   */
  public async getMemoryItemsByContextObjectType(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    objectType: string
  ): Promise<MemoryItem[]> {
    if (!this.memoriesMap[scopeId]) {
      await this.loadMemoriesForScope(scopeType, scopeId);
    }

    // Filter by object type in context
    const collection = this.memoriesMap[scopeId];
    return collection.items.filter(item => 
      item.context.objects.some(obj => obj.object_type === objectType)
    );
  }

  /**
   * Gets all memory items with importance level equal to or greater than specified
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param minImportance Minimum importance level
   * @returns Array of memory items with importance >= minImportance
   */
  public async getMemoryItemsByMinimumImportance(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    minImportance: number
  ): Promise<MemoryItem[]> {
    if (!this.memoriesMap[scopeId]) {
      await this.loadMemoriesForScope(scopeType, scopeId);
    }

    const collection = this.memoriesMap[scopeId];
    return collection.items.filter(item => item.importance >= minImportance);
  }

  /**
   * Gets all memory items related to a specific memory (via related_memories field)
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param memoryId ID of the memory to find related items for
   * @returns Array of related memory items
   */
  public async getRelatedMemoryItems(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    memoryId: string
  ): Promise<MemoryItem[]> {
    if (!this.memoriesMap[scopeId]) {
      await this.loadMemoriesForScope(scopeType, scopeId);
    }

    const map = this.memoryItemsMaps[scopeId];
    return map?.byRelatedMemories[memoryId] || [];
  }

  /**
   * Gets all tables from memory items for a specific scope
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @returns Array of memory items with 'table' object_type in context
   */
  public async getContextTables(
    scopeType: 'workspace' | 'project', 
    scopeId: string
  ): Promise<MemoryItem[]> {
    return this.getMemoryItemsByContextObjectType(scopeType, scopeId, 'table');
  }

  /**
   * Gets all memory items in scope that have a specific object in context
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @param objectId ID of the object to find in contexts
   * @returns Array of memory items with the specified object in context
   */
  public async getMemoriesRelatedToObject(
    scopeType: 'workspace' | 'project', 
    scopeId: string, 
    objectId: string
  ): Promise<MemoryItem[]> {
    return this.getMemoryItemsByContextObject(scopeType, scopeId, objectId);
  }

  /**
   * Gets all memory items in the current scope
   * @returns Observable of memory items in the current scope
   */
  public getCurrentScopeMemories(): Observable<MemoryItem[]> {
    return this.currentMemories$;
  }

  /**
   * Builds a map for efficient lookups of memory items
   * @param scopeId ID of the scope
   * @param items Array of memory items to build the map from
   */
  private buildMemoryItemsMap(scopeId: string, items: MemoryItem[]): void {
    const map: MemoryItemsMap = {
      byId: {},
      bySessionId: {},
      byType: {},
      byContextObject: {},
      byRelatedMemories: {},
      byImportance: {}
    };

    for (const item of items) {
      // Map by ID
      map.byId[item.memory_id] = item;

      // Map by session ID
      if (!map.bySessionId[item.session_id]) {
        map.bySessionId[item.session_id] = [];
      }
      map.bySessionId[item.session_id].push(item);

      // Map by type
      if (!map.byType[item.type]) {
        map.byType[item.type] = [];
      }
      map.byType[item.type].push(item);

      // Map by importance
      if (!map.byImportance[item.importance]) {
        map.byImportance[item.importance] = [];
      }
      map.byImportance[item.importance].push(item);

      // Map by context objects
      if (item.context && item.context.objects) {
        for (const obj of item.context.objects) {
          if (!map.byContextObject[obj.object_id]) {
            map.byContextObject[obj.object_id] = [];
          }
          map.byContextObject[obj.object_id].push(item);
        }
      }

      // Map by related memories
      if (item.context && item.context.related_memories) {
        for (const relatedId of item.context.related_memories) {
          if (!map.byRelatedMemories[relatedId]) {
            map.byRelatedMemories[relatedId] = [];
          }
          map.byRelatedMemories[relatedId].push(item);
        }
      }
    }

    this.memoryItemsMaps[scopeId] = map;
  }

  /**
   * Saves memories collection to artifact storage
   * @param scopeId ID of the scope to save
   */
  private async saveMemoriesToArtifact(scopeId: string): Promise<void> {
    const collection = this.memoriesMap[scopeId];
    if (!collection) {
      return;
    }

    const artifactId = this.getArtifactId(collection.scopeType, scopeId);
    await this.artifactsService.saveArtifact(artifactId, collection);
  }

  /**
   * Generates the artifact ID for storing memories
   * @param scopeType Type of scope ('workspace' or 'project')
   * @param scopeId ID of the scope
   * @returns Artifact ID string
   */
  private getArtifactId(scopeType: 'workspace' | 'project', scopeId: string): string {
    return `memories_${scopeType}_${scopeId}`;
  }
} 