// src/app/services/user.service.ts
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../models/user.interface';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  private userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public user$: Observable<User | null> = this.userSubject.asObservable();
  private tokenReadySubject = new BehaviorSubject<boolean>(false);
  public tokenReady$ = this.tokenReadySubject.asObservable();
  private loginSubscription: Subscription;

  constructor(
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService
  ) {
    // Subscribe to login and token events
    this.loginSubscription = this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => 
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          msg.eventType === EventType.HANDLE_REDIRECT_END
        )
      )
      .subscribe((result: EventMessage) => {
        if (result.eventType === EventType.HANDLE_REDIRECT_END) {
          this.checkAndUpdateUserState();
        } else if (result.payload as AuthenticationResult) {
          const payload = result.payload as AuthenticationResult;
          this.msalService.instance.setActiveAccount(payload.account);
          this.checkAndUpdateUserState();
        }
      });

    // Initial check
    this.checkAndUpdateUserState();
  }

  private checkAndUpdateUserState(): void {
    const account = this.msalService.instance.getActiveAccount();
    
    if (!account || !account.idToken) {
      this.userSubject.next(null);
      this.tokenReadySubject.next(true);
      return;
    }

    try {
      const decodedToken: any = jwtDecode(account.idToken);
      
      if (!decodedToken.preferred_username) {
        console.error('No username found in token');
        this.userSubject.next(null);
        this.tokenReadySubject.next(true);
        return;
      }

      const user: User = {
        name: decodedToken.name || decodedToken.preferred_username,
        email: decodedToken.preferred_username,
      };
      
      this.userSubject.next(user);
      this.tokenReadySubject.next(true);
    } catch (error) {
      console.error('Error processing user token:', error);
      this.userSubject.next(null);
      this.tokenReadySubject.next(true);
    }
  }

  public async getCurrentUser(): Promise<User | null> {
    if (!this.tokenReadySubject.value) {
      await this.waitForTokenReady();
    }
    return this.userSubject.value;
  }

  public async waitForTokenReady(): Promise<void> {
    if (this.tokenReadySubject.value) {
      return;
    }

    return new Promise<void>((resolve) => {
      const subscription = this.tokenReady$.subscribe(ready => {
        if (ready) {
          subscription.unsubscribe();
          resolve();
        }
      });

      // Check again in case we missed any events
      this.checkAndUpdateUserState();
    });
  }

  public updateUser(user: User): void {
    this.userSubject.next(user);
  }

  public clearUser(): void {
    this.userSubject.next(null);
    this.tokenReadySubject.next(false);
  }

  public async logout(): Promise<void> {
    // Clear local user state first
    this.clearUser();

    try {
      // End all active sessions and clear cache
      await this.msalService.instance.logoutPopup({
        mainWindowRedirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
      }).catch(() => {
        // If popup fails, fallback to redirect
        this.msalService.instance.logoutRedirect({
          postLogoutRedirectUri: window.location.origin
        });
      });
    } catch (error) {
      console.error('Logout error:', error);
      // Force clear everything as fallback
      this.msalService.instance.clearCache();
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = window.location.origin;
    }
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }
}