import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordGroupList } from '../../../models/record-management.interface';
import { RecordsGroupComponent } from '../records-group/records-group.component';

@Component({
  selector: 'app-record-group-list',
  standalone: true,
  imports: [CommonModule, RecordsGroupComponent],
  templateUrl: './record-group-list.component.html',
  styleUrls: ['./record-group-list.component.scss']
})
export class RecordGroupListComponent {
  @Input() list!: RecordGroupList;
  @Input() vertical = false;
  @Output() onPinToggle = new EventEmitter<any>();
  @Output() onExpandToggle = new EventEmitter<any>();
}
