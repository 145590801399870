// src/app/components/session/message/select-list/select-list.component.ts

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StateManagerService } from '../../../../services/state-manager.service';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule
  ]
})
export class SelectListComponent implements OnInit {
  @Input() sessionId: string = '';
  /**
   * The "value" attribute in the node indicates the key or path
   * to retrieve from StateManagerService: getStateData(sessionId, value)
   */
  @Input() value: string = '';

  /**
   * The message template that we place in the input box upon click,
   * e.g. "Let's work with connection {Id}"
   * We'll replace {Id} with the item's actual Id, etc.
   */
  @Input() messageOnClick: string = '';

  /**
   * If true => append new line to existing input text
   * If false => replace the entire input box with the constructed text
   */
  @Input() multiple: boolean = false;

  /**
   * If true => after setting or appending the text, also automatically send
   */
  @Input() sendOnClick: boolean = false;

  @Output() itemClicked = new EventEmitter<{
    text: string;
    multiple: boolean;
    sendOnClick: boolean;
    item: any;
  }>();

  items: any[] = [];

  constructor(private stateManager: StateManagerService) {}

  ngOnInit(): void {
    // Retrieve the array from the state manager
    if (this.value) {
      const data = this.stateManager.getStateData(this.sessionId, this.value);
      if (Array.isArray(data)) {
        this.items = data; // expects an array of objects
      }
    }
  }

  onItemSelected(item: any) {
    // Replace tokens in messageOnClick (e.g. {Id}, {Name}, etc.)
    let text = this.messageOnClick || '';
    if (item && typeof item === 'object') {
      for (const key of Object.keys(item)) {
        // Replace all occurrences of {key} with the item[key] value
        const regex = new RegExp(`\\{${key}\\}`, 'g');
        text = text.replace(regex, item[key]);
      }
    }

    // Emit event so the parent can set/append and possibly send
    this.itemClicked.emit({
      text,
      multiple: this.multiple,
      sendOnClick: this.sendOnClick,
      item: item
    });
  }
}
