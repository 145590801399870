<!-- src/app/components/session/message/message.component.html -->
<div
  class="message-container"
  [ngClass]="{
    'user': message.role === 'user',
    'system': message.role === 'agent'
  }"
>
  <!-- Agent Avatar -->
  <div *ngIf="message.role === 'agent'" class="agent-avatar">
    <div class="relative flex h-7 w-7 items-center justify-center rounded-sm bg-token-main-surface-primary">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 154 120">
        <!-- Left cube -->
        <path fill="#E5E7EB" d="M102.489 90.414V29.586L51.244 0 0 29.586v60.828L51.244 120l51.245-29.586Z" />
        <path fill="#D1D5DB" d="M102.489 90.414V29.586L51.245 59.172V120l51.244-29.586Z" />
        <path fill="#9CA3AF" d="M102.489 29.586 51.244 0 0 29.586l51.244 29.586 51.245-29.586Z" />
        <!-- Right cube -->
        <path fill="#F3F4F6" d="M153.734 90.414V29.586L102.489 0 51.245 29.586v60.828L102.49 120l51.245-29.586Z" />
        <path fill="#E5E7EB" d="M153.733 90.414V29.586l-51.244 29.586V120l51.244-29.586Z" />
        <path fill="#D1D5DB" d="M153.734 29.586 102.489 0 51.245 29.586l51.244 29.586 51.245-29.586Z" />
        <!-- Center cube -->
        <path fill="#D1D5DB" d="M51.245 29.585 76.867 44.38l25.623-14.793-25.623-14.793-25.622 14.793Z" />
        <path fill="#9CA3AF" d="m76.866 44.38 25.623 14.792V29.586L76.866 44.38Z" />
        <path fill="#F3F4F6" d="M51.245 59.173v31.242l25.622 14.793 25.623-14.793V59.173L76.867 44.38 51.245 59.173Z" />
        <path fill="#6B7280" d="M76.866 105.206V74.792l25.623-15.62v31.241l-25.623 14.793Z" />
        <path fill="#9CA3AF" d="m51.245 59.173 25.622 15.62 25.623-15.62L76.867 44.38 51.245 59.173Z" />
        <path fill="#E5E7EB" d="M76.866 44.38v30.414l25.623-15.621L76.866 44.38Z" style="mix-blend-mode:multiply" />
        <path fill="#D1D5DB" d="M76.867 44.38 51.245 59.171V29.586L76.867 44.38Z" />
      </svg>
    </div>
  </div>

  <div class="message-wrapper">
    <!-- Edit button for user messages -->
    <button *ngIf="message.role === 'user'"
            class="edit-button"
            (click)="onEdit()"
            aria-label="Edit message">
      <svg width="16"
           height="16"
           viewBox="0 0 24 24"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
           class="icon-md">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.2929 4.29291C15.0641 2.52167 17.9359 2.52167 19.7071 4.2929C21.4784 6.06414 21.4784 8.93588 19.7071 10.7071L18.7073 11.7069L11.6135 18.8007C10.8766 19.5376 9.92793 20.0258 8.89999 20.1971L4.80344 20.9179C4.32186 21.0257 3.82939 20.8617 3.48997 20.5101C3.15056 20.1585 3.00983 19.6621 3.13914 19.1859L4.03871 14.9515C4.23874 13.9128 4.74852 12.9532 5.50981 12.1919L13.2929 4.29291ZM18.2929 5.70712C17.1215 4.53553 15.2642 4.53553 14.0929 5.70712L6.30981 13.6061C5.80495 14.111 5.45699 14.7518 5.31711 15.4462L4.69983 18.4063L7.58432 17.8672C8.27314 17.7373 8.91255 17.4007 9.41843 16.8948L17.293 8.87872L18.2929 7.87875C19.4642 6.70716 19.4642 4.84871 18.2929 3.67712V5.70712Z"
              fill="currentColor"></path>
      </svg>
    </button>

    <!-- Original message content -->
    <div class="message-content">
      <ng-container *ngFor="let part of processedParts">
        <!-- skip if part.hidden -->

        <!-- 1) WorkstreamsList -->
        <ng-container *ngIf="part.nodeType === 'WorkstreamsList' && !part.hidden; else notWkList">
          <div
            class="riffml-workstreams-list"
            [matTooltip]="part.tooltip"
            matTooltipPosition="above"
          >
            {{ part.content }}
          </div>
        </ng-container>

        <ng-template #notWkList>
          <!-- 2) SessionsList -->
          <ng-container *ngIf="part.nodeType === 'SessionsList' && !part.hidden; else notSsList">
            <div
              class="riffml-sessions-list"
              [matTooltip]="part.tooltip"
              matTooltipPosition="above"
            >
              {{ part.content }}
            </div>
          </ng-container>

          <ng-template #notSsList>
            <!-- 3) Button -->
            <ng-container *ngIf="part.nodeType === 'Button' && !part.hidden; else notBtn">
              <button
                class="riffml-button"
                [class.clicked]="part.clicked"
                (click)="onRiffButtonClick(part)"
                [attr.data-tool]="part.tool"
                [attr.data-next]="part.nextPlaybook"
              >
                {{ part.content }}
              </button>
            </ng-container>

            <ng-template #notBtn>
              <!-- 4) specialLink -->
              <ng-container *ngIf="part.nodeType === 'specialLink' && !part.hidden; else notLink">
                <div
                  [ngClass]="part.linkClass"
                  class="special-link"
                  (click)="onRiffLinkClick(part)"
                >
                  {{ part.linkIcon }} {{ part.content }}
                </div>
              </ng-container>

              <ng-template #notLink>
                <!-- 5) Normal text or artifact placeholders -->
                <ng-container *ngIf="!part.artifactTitle; else hasArt">
                  <div>
                    <div [innerHTML]="part.content"></div>
                  </div>
                </ng-container>
                <ng-template #hasArt>
                  <div class="artifact-preview">
                    <div class="artifact-header">
                      <span class="artifact-title">{{ part.artifactTitle }}</span>
                    </div>
                    <pre class="artifact-content">
{{ getArtifactPreview(part.artifactTitle ?? '') }}
                    </pre>
                    <div> {{ part.remainingContent }}</div>
                  </div>
                </ng-template>
              </ng-template>
            </ng-template>
          </ng-template>
        </ng-template>

        <!-- 6) SelectFromList -->
        <ng-container *ngIf="part.nodeType === 'SelectFromList' && !part.hidden; else notSelect">
          <app-select-list
            [sessionId]="sessionId"
            [value]="part.riffMLNode?.attributes?.['value'] ?? ''"
            [messageOnClick]="part.riffMLNode?.attributes?.['messageOnClick'] ?? ''"
            [multiple]="(part.riffMLNode?.attributes?.['multiple'] || 'false') === 'true'"
            [sendOnClick]="(part.riffMLNode?.attributes?.['sendOnClick'] || 'false') === 'true'"
            (itemClicked)="onSelectListItemClicked(part, $event)"
          ></app-select-list>
        </ng-container>

        <ng-template #notSelect></ng-template>
      </ng-container>

      <!-- Unplaced artifacts -->
      <ng-container *ngFor="let artifact of getUnplacedArtifacts()">
        <div class="artifact-preview">
          <div class="artifact-header">
            <span class="artifact-title">{{ artifact.title }}</span>
          </div>
          <pre class="artifact-content">
{{ getArtifactPreview(artifact.title) }}
          </pre>
        </div>
      </ng-container>
    </div>

    <!-- SUGGESTED WORKSTREAMS & SESSIONS (Project Manager Data) -->
    <ng-container *ngIf="message.role === 'agent'">
      <!-- Message actions -->
      <div class="message-actions">
        <button
          mat-icon-button
          class="debug-button"
          *ngIf="showDebugButton"
          (click)="onDebug()"
          aria-label="Debug message"
        >
          <mat-icon>bug_report</mat-icon>
        </button>

        <button mat-icon-button (click)="onCopy()">
          <mat-icon>content_copy</mat-icon>
        </button>
        <button mat-icon-button (click)="onRead()">
          <mat-icon>record_voice_over</mat-icon>
        </button>
        <button mat-icon-button (click)="onLike()">
          <mat-icon>thumb_up</mat-icon>
        </button>
        <button mat-icon-button (click)="onDislike()">
          <mat-icon>thumb_down</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>
