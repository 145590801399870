// src/app/services/chat.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChatSession, MessageRole, SessionStatus, ChatTurn } from '../models/chat.model';
import { v4 as uuidv4 } from 'uuid';
import { Session } from '../models/session.model';
import { WorkspaceState } from '../models/workspace-state.model';
import { ArtifactsService } from './artifacts.service';
import { SessionsManagerService } from './sessions-manager.service';
import { ChatMessageProcessorService } from './chat-message-processor.service'
import { ChatChangeManagerService } from './chat-change-manager.service';
import { ChatContext } from '../models/chat.model';
import {
  ProjectManagerWorkstream,
  ProjectManagerSessionMessage,
  SuggestedSession,
  SuggestedNewSession
} from '../models/project-manager-chat.model';
import { SnowflakeService } from './snowflake.service';
import { StateManagerService } from './state-manager.service';
import { AIModel, AICompletionRequest } from '../models/bedrock.model';
import { PromptLayerService } from './prompt-layer.service';
import { WorkboardManagerService} from './workboard-manager.service'
import { FileInfo } from '../models/fileinfo.model';
import { Artifact, ArtifactAction } from '../models/artifact.model';
import { ProcessTurnInterface } from '../models/process-turn.interface';
import { ToolsService } from './tools.service';
import { NotificationsService } from './notifications.service';
import { RiffMLNode } from './riffml-parser.service'
import { JsonParserService } from './jsonParserService.service'

@Injectable({
  providedIn: 'root'
})
export class ChatService implements ProcessTurnInterface {
  private readonly sessionManagerApiUrl = `${environment.artifactsApiUrl}/session`;
  private readonly orgId = 'asky';

  constructor(
    private http: HttpClient,
    private artifactsService: ArtifactsService,
    private sessionsManagerService: SessionsManagerService,
    private sharedState: WorkspaceState,
    private messageProcessor: ChatMessageProcessorService,
    private changeManager: ChatChangeManagerService,
    private snowflakeService: SnowflakeService,
    private stateManagerService: StateManagerService,
    private promptLayerService: PromptLayerService,
    private workboardManagerService: WorkboardManagerService,
    private toolsService: ToolsService,
    private notificationsService: NotificationsService,
    private jsonParser: JsonParserService
  ) {
    console.log('[ChatService] Initializing service');
    this.toolsService.setProcessTurnHandler(this);
    this.notificationsService.setProcessTurnHandler(this);
    this.messageProcessor.setProcessTurnHandler(this);
  }

  /**
   * Returns an observable of ChatMessages for the given sessionId.
   */
  getMessagesObservable(sessionId: string): Observable<ChatTurn[]> {
    return this.sharedState.getSessionMessagesObservable(sessionId);
  }

  /**
   * Loads the messages from the artifact store and updates the shared state.
   */
  async getMessages(sessionId: string): Promise<ChatTurn[]> {
    //console.log('[ChatService] Getting messages for session:', sessionId);
    const chatSession = await this.loadOrCreateChatSession(sessionId);
    const messages = chatSession.turns;
    this.sharedState.setSessionMessages(sessionId, messages);
    return messages;
  }

  async hasMessages(sessionId: string): Promise<boolean> {
    //console.log('[ChatService] Checking messages for session:', sessionId);
    const chatSession = await this.loadOrCreateChatSession(sessionId);
    return chatSession.turns.length > 0;
  }

  /**
   * Either loads an existing ChatSession artifact, or creates a new one if not found.
   */
  public async loadOrCreateChatSession(sessionId: string): Promise<ChatSession> {
    const tchatSession = this.sharedState.tryGetChatSession(sessionId);
    if (tchatSession) {
      return tchatSession.value;
    }

    const artifactId = `session_${sessionId}`;
    let chatSession = (await this.artifactsService.loadArtifact(artifactId)) as ChatSession;

    if (!chatSession) {
      console.log('[ChatService] Creating new chat session for:', sessionId);
      const sessions = await firstValueFrom(this.sessionsManagerService.getSessions());
      const sessionData = sessions.find((s: Session) => s.id === sessionId);

      if (!sessionData) {
        throw new Error(`Session not found: ${sessionId}`);
      }

      const initialMessage: ProjectManagerSessionMessage = {
        sessionName: sessionData.name,
        sessionDescription: sessionData.description || '',
        messageToUser: sessionData.goal
          ? (sessionData.goal.startsWith('I ') ? sessionData.goal : ('Our goal is: ' + sessionData.goal + '. Let me know when you are ready..'))
          : 'What would you like to do?',
        suggestedWorkboardNewItems: {
          groups: []
        },
        suggestedExistingSessions: [],
        suggestedNewSessions: []
      };

      const timestamp = new Date();
      const initialTurn: ChatTurn = {
        id: uuidv4(),
        sessionId: sessionId,
        content: initialMessage.messageToUser,
        role: MessageRole.Agent,
        senderId: 'project.jammer@ask-y.ai',
        timestamp: timestamp,
        artifacts: [],
        turnMetadata: {
          turnNumber: 1,
          processingTime: 0,
          turnData: { promptRequest: null, responseData: initialMessage }
        }
      };

      chatSession = {
        id: sessionId,
        title: sessionData.name,
        status: SessionStatus.Active,
        createdAt: timestamp,
        updatedAt: timestamp,
        participants: {
          users: [sessionData.createdBy],
          agents: ['project.jammer@ask-y.ai']
        },
        turns: [initialTurn],
        metadata: {},
        sessionPlaybook: {
          id:
            !sessionData.playbook || sessionData.playbook == ChatService.GetDefaultPMPlaybook()
              ? ChatService.GetDefaultPMPlaybook()
              : sessionData.playbook ?? '43154'
        },
        goal: sessionData.goal,
        whyRelevant: sessionData.whyRelevant,
        detailedContext: sessionData.detailedContext,
        relatedWorkstreamIds: sessionData.relatedWorkstreamIds,
        description: sessionData.description,
        DTeamID: sessionData.DTeamID ?? 'PM',
        DTeamName: sessionData.DTeamName ?? 'Project Manager',
        stateMap: {
          id: {
            id: 'id',
            name: 'Session ID',
            description: 'Unique identifier for the chat session',
            whyRelevant: 'Used to uniquely identify this session in the system',
            type: 'string',
            data: sessionId
          },
          name: {
            id: 'name',
            name: 'Session Name',
            description: 'Title of the chat session',
            whyRelevant: 'Identifies the session purpose for users',
            type: 'string',
            data: sessionData.name
          },
          createdat: {
            id: 'createdat',
            name: 'Creation Date',
            description: 'When the session was created',
            whyRelevant: 'Tracks session timeline start',
            type: 'datetime',
            data: timestamp
          },
          updatedat: {
            id: 'updatedat',
            name: 'Last Updated',
            description: 'When the session was last modified',
            whyRelevant: 'Tracks session activity',
            type: 'datetime',
            data: timestamp
          },
          participants: {
            id: 'participants',
            name: 'Participants',
            description: 'All participants in the session',
            whyRelevant: 'Tracks who is involved in the session',
            type: 'string[]',
            data: ['project.jammer@ask-y.ai', ...sessionData.createdBy]
          },
          playbook: {
            id: 'playbook',
            name: 'playbook',
            description: 'Playbook ID for the session',
            whyRelevant: 'Defines the session workflow and rules',
            type: 'string',
            data:
              !sessionData.playbook || sessionData.playbook == ChatService.GetDefaultPMPlaybook()
                ? ChatService.GetDefaultPMPlaybook()
                : sessionData.playbook ?? '43154'
          },
          goal: {
            id: 'goal',
            name: 'Session Goal',
            description: 'Primary objective of the session',
            whyRelevant: 'Defines what the session aims to achieve',
            type: 'string',
            data: sessionData.goal
          },
          whyrelevant: {
            id: 'whyRelevant',
            name: 'Relevance',
            description: 'Why this session matters',
            whyRelevant: 'Explains the session\'s importance',
            type: 'string',
            data: sessionData.whyRelevant
          },
          detailedcontext: {
            id: 'detailedcontext',
            name: 'Detailed Context',
            description: 'Additional context for the session',
            whyRelevant: 'Provides background information',
            type: 'string',
            data: sessionData.detailedContext
          },
          contextworkstreams: {
            id: 'contextworkstreams',
            name: 'Context Workstreams',
            description: 'IDs of related workstreams',
            whyRelevant: 'Links session to relevant workstreams',
            type: 'string[]',
            data: sessionData.relatedWorkstreamIds
          },
          contexttables: {
            id: 'contexttables',
            name: 'Context Tables',
            description: 'IDs of related tables',
            whyRelevant: 'Links session to relevant tables',
            type: 'string[]',
            data: this.getContextTablesArray(sessionData.relatedWorkstreamIds)
          },
          description: {
            id: 'description',
            name: 'Description',
            description: 'Session description',
            whyRelevant: 'Provides detailed information about the session',
            type: 'string',
            data: sessionData.description
          },
          agentid: {
            id: 'agentid',
            name: 'Agent ID',
            description: 'DTeam identifier',
            whyRelevant: 'Identifies the responsible team',
            type: 'string',
            data: sessionData.DTeamID ?? 'PM'
          },
          agentname: {
            id: 'agentname',
            name: 'Agent Name',
            description: 'DTeam display name',
            whyRelevant: 'Human-readable team identifier',
            type: 'string',
            data: sessionData.DTeamName ?? 'Project Manager'
          }
        }
      };

      await this.artifactsService.saveArtifact(artifactId, chatSession);
      

      // If the initial turn had a ProjectManagerSessionMessage, store it
      chatSession.turns.forEach(turn => {
        if (
          chatSession.sessionPlaybook.id == ChatService.GetDefaultPMPlaybook() &&
          turn.turnMetadata?.turnData?.responseData
        ) {
          const agentData = turn.turnMetadata.turnData.responseData as ProjectManagerSessionMessage;
          if (agentData) {
            this.sharedState.updateProjectManagerMessage(sessionId, agentData);
          }
        }
      });
    }

    this.sharedState.setChatSession(chatSession.id, chatSession);
    return chatSession;
  }

  /**
   * Get tables from workstreams
   */
  private getContextTablesArray(workstreamIds: string[] | undefined): string[] {
    let ret: string[] = [];

    if (!workstreamIds || !Array.isArray(workstreamIds) || workstreamIds.length === 0) {
      return [];
    }
    
    for (const workstreamId of workstreamIds) {
      const workstreamData = this.workboardManagerService.getWorkstreamById(workstreamId);
      if (workstreamData && workstreamData.input_table_ids) {
        // merge workstreamData.input_table_ids into ret
        ret = [...ret, ...workstreamData.input_table_ids];
      } 
      if (workstreamData && workstreamData.output_table_ids) {
        // merge workstreamData.output_table_ids into ret
        let exported = workstreamData.output_table_ids.filter(tbl => tbl.export );
        if (exported && exported.length)
        {
          ret = [...ret, ...exported.map(tbl => tbl.name) ];
        }
      } 
    }

    return ret;
}

  /**
   * Persists the updated ChatSession to the artifact store and notifies subscribers.
   */
  private async updateSession(chatSession: ChatSession): Promise<void> {
    const artifactId = `session_${chatSession.id}`;
    await this.artifactsService.saveArtifact(artifactId, chatSession);
    this.sharedState.setChatSession(chatSession.id, chatSession);
    this.sharedState.setSessionMessages(chatSession.id, chatSession.turns);
  }


/**
 * Sends a user message in the chat, then processes the turn via new logic.
 * Now includes support for file attachments with content reading.
 */
async sendMessage(
  sessionId: string, 
  content: string, 
  userId: string, 
  fileInfo?: FileInfo,
  artifacts?: Artifact[]
): Promise<void> {
  console.log(`[ChatService] Sending message in session ${sessionId}`, {
    contentLength: content.length,
    userId,
    hasFileAttachment: !!fileInfo
  });

  // Check if content starts with "nextPlaybook:" and handle it specially
  if (content.startsWith('nextPlaybook:')) {
    const nextPlaybookId = content.substring('nextPlaybook:'.length).trim();
    console.log(`[ChatService] Setting next playbook ID: ${nextPlaybookId}`);
    
    // Set the next playbook ID in the state manager
    this.stateManagerService.setState(
      sessionId,
      'session.nextplaybook',
      'session.nextplaybook',
      'session.nextplaybook',
      'next playbook to be used by the conversation',
      'why relevant: needed to guide the conversation',
      'string',
      nextPlaybookId
    );
    
    // Return early without further processing
    return;
  }

  try {
    // 1) Load or create the chat session
    const chatSession = await this.loadOrCreateChatSession(sessionId);

    // 2) Create a user message turn
    const userMessage = this.messageProcessor.createUserMessageTurn(
      sessionId,
      content,
      userId,
      chatSession.turns.length
    );

    // 3) If there's a file attachment, process it and add as an artifact
    if (fileInfo && fileInfo.file) {
      // Create an artifact ID by replacing special chars in filename with _
      const artifactId = fileInfo.name.replace(/[^a-zA-Z0-9]/g, '_');
      
      // Read the file content as text
      const fileContent = await this.readFileAsText(fileInfo.file);
      
      // Create the file artifact and add it to the message
      const artifact: Artifact = {
        id: artifactId,
        title: fileInfo.name,
        content: fileContent, // Store the actual file content
        type: fileInfo.type || 'text/plain',
        createdAt: new Date(),
        updatedAt: new Date(),
        action:ArtifactAction.Created
      };
      
      userMessage.artifacts = [artifact];
    }

    if (artifacts && artifacts.length > 0) {
      userMessage.artifacts = [...userMessage.artifacts, ...artifacts];
    }

    chatSession.turns.push(userMessage);
    chatSession.updatedAt = new Date();
    chatSession.lastMessage = userMessage;
    await this.updateSession(chatSession);

    // 4) Process the turn (new logic)
    await this.processTurn(chatSession, content);

    // 5) Final save/updates (if needed)
    chatSession.updatedAt = new Date();
    await this.updateSession(chatSession);
  } catch (error) {
    console.error('[ChatService] Error in sendMessage:', error);
    throw error;
  }
}

/**
 * Helper method to read a file as text
 */
private readFileAsText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (event) => {
      resolve(event.target?.result as string);
    };
    
    reader.onerror = (error) => {
      console.error('Error reading file:', error);
      reject(new Error('Failed to read file'));
    };
    
    reader.readAsText(file);
  });
}

  /**
   * Main method to handle the turn logic as per instructions:
   * 1) Get playbookId from stateManagerService
   * 2) If null => set to 43548
   * 3) If not already RiffML => get prompt from promptLayerService
   * 4) Parse RiffML => get actionNodes => handle them
   * 5) If no wait/next/end => call bedrockService => parse => add message => processTurn again
   */
  public async processTurn(
    chatSession: ChatSession,
    content: string,
    isRiffMl: boolean = false,
    promptOrigin: any = null,
    responseOrigin: any = null,
    pplaybookId: string | null = null,
    storeTarget: string | null = null

  ): Promise<void> {
    const sessionId = chatSession.id;

    let playbookId = pplaybookId;

    // (1) Get or create the playbook ID
    if (!playbookId)
    {
      playbookId = this.getOrCreatePlaybookId(sessionId);
    }

    // this is a turn that should not be handled, used in testing
    if (!isRiffMl && playbookId == '0000')
    {
      return;
    }

    // (2) Fetch the RiffML content if needed
    const riffMl = await this.getRiffMl(content, playbookId, isRiffMl);

    // (3) Process the RiffML to get the prompt and action nodes
    const { prompt, actionNodes } = await this.messageProcessor.processRiffMl(riffMl, content, sessionId);

    // (4) Handle action nodes: parse <wait>, <next>, <end>, <message>, <processingmessage>
    const { hasWait, hasNext, hasEnd, nextPlaybookId } = await this.handleActionNodes(chatSession, actionNodes, promptOrigin??riffMl, responseOrigin??prompt);

    // (5) Decide what to do next based on the nodes encountered
    if (hasWait || (isRiffMl && !hasNext)) {
      // Stop if <wait> is present, or if we used a template (isRiffMl = false) and no <next>/<end> was found
      return;
    }

    console.log("PROCESS_TURN: ", { content: content, riffMl: riffMl, prompt: prompt, actionNodes: actionNodes, hasWait: hasWait, hasNext: hasNext, hasEnd: hasEnd });

    // If <next> is present, process recursion with a new or updated playbook
    if (!storeTarget && hasNext) {
      await this.handleNextPlaybook(sessionId, nextPlaybookId, playbookId, chatSession, content, isRiffMl);
      return;
    }

    // If <end> is present, just finish
    if (hasEnd) {
      return;
    }

    // Otherwise, call the Bedrock service to generate content and re-process
    await this.handleBedrockCompletionAndReprocess(chatSession, prompt, content, playbookId, storeTarget, actionNodes);
  }

  /**
   * Retrieves the playbookId from state, sets a default if not found, and returns it.
   *
   * @param sessionId The ID of the current chat session
   * @returns The playbook ID to use
   */
  private getOrCreatePlaybookId(sessionId: string): string {
    let playbookId = this.stateManagerService.getObject(sessionId, 'session.nextplaybook');

    if (!playbookId){
       playbookId = this.stateManagerService.getObject(sessionId, 'session.playbook');
    }

    if (!playbookId) {
      // Set a default if not found
      playbookId = '43548';
      this.stateManagerService.setState(
        sessionId,
        'session.playbook',
        'session.playbook',
        'session.playbook',
        'session playbook used by the conversation',
        'why relevant: needed to guide the conversation',
        'string',
        playbookId
      );
    }

    return playbookId;
  }

  /**
   * Determines whether to use the provided content as-is or to fetch a RiffML prompt
   * from the promptLayerService if the content is not already RiffML.
   *
   * @param content   The user-provided or previously generated content
   * @param playbookId  The ID of the playbook to fetch from promptLayerService
   * @param isRiffMl  Boolean indicating if the content is already RiffML
   * @returns The RiffML string
   */
  private async getRiffMl(content: string, playbookId: string, isRiffMl: boolean): Promise<string> {
    if (isRiffMl) {
      return content;
    }

    // Fetch the template from promptLayerService if not already RiffML
    const promptTemplate = await this.promptLayerService.getTemplate(playbookId);

    if (promptTemplate && promptTemplate.PromptTemplate && promptTemplate.PromptTemplate.Messages && promptTemplate.PromptTemplate.Messages.length > 0) {
      return promptTemplate.PromptTemplate.Messages[0].Content[0].Text;
    } else if (promptTemplate && promptTemplate.PromptTemplate && promptTemplate.PromptTemplate.Content && promptTemplate.PromptTemplate.Content.length > 0) {
      return promptTemplate.PromptTemplate.Content[0].Text;
    } else {
      throw new Error('Invalid prompt template format: missing both Messages and Content:' + playbookId);
    }
  }

  /**
   * Loops through the action nodes extracted from the RiffML, updating the chat session
   * (e.g., adding messages) and noting whether <wait>, <next>, or <end> tags appear.
   *
   * @param chatSession The current chat session
   * @param actionNodes The parsed action nodes from RiffML
   * @returns An object containing boolean flags for wait/next/end and the next playbook ID if present
   */
  private async handleActionNodes(
    chatSession: ChatSession,
    actionNodes: any[],
    prompt: any,
    responseOrigin: any
  ): Promise<{
    hasWait: boolean;
    hasNext: boolean;
    hasEnd: boolean;
    nextPlaybookId: string | null;
  }> {
    const sessionId = chatSession.id;

    let hasWait = false;
    let hasNext = false;
    let hasEnd = false;
    let nextPlaybookId: string | null = null;

    for (const node of actionNodes) {
      const tag = node.tagName.toLowerCase();

      switch (tag) {
        case 'wait':
          hasWait = true;
          break;

        case 'next':
          hasNext = true;
          nextPlaybookId = this.stateManagerService.getObject(sessionId, 'session.nextPlaybook') || null;
          break;

        case 'end':
          hasEnd = true;
          break;

        case 'message':
          await this.addAgentMessage(chatSession, node, prompt, responseOrigin);
          break;

        case 'processingmessage':
          await this.addProcessingMessage(chatSession, node, prompt, responseOrigin);
          break;

        case 'usermessage':
          await this.addUserMessage(chatSession, node);
          break;

        default:
          break;
      }
    }

    return { hasWait, hasNext, hasEnd, nextPlaybookId };
  }

  /**
   * Helper to add a standard Agent message to the chat session.
   *
   * @param chatSession The current chat session
   * @param content     The text content of the <message> node
   */
  private async addAgentMessage(chatSession: ChatSession, node: any, promptRequest: any, responseOrigin: any): Promise<void> {
    const sessionId = chatSession.id;
    const assistantMessage: ChatTurn = {
      id: uuidv4(),
      sessionId,
      content: node,
      role: MessageRole.Agent,
      senderId:
        this.stateManagerService.getObject(sessionId, 'session.AgentName') ??
        chatSession.participants.agents[0],
      timestamp: new Date(),
      artifacts: [],
      turnMetadata: {
        turnNumber: chatSession.turns.length + 1,
        processingTime: 0,
        turnData: { promptRequest: promptRequest, responseData: responseOrigin, messageNode: node }
      }
    };
    chatSession.turns.push(assistantMessage);
    await this.updateSession(chatSession);

     // Check for sleep attribute and wait if specified
     if (node?.attributes?.sleep) {
      const sleepTime = parseInt(node.attributes.sleep, 10);
      if (!isNaN(sleepTime) && sleepTime > 0) {
        await new Promise(resolve => setTimeout(resolve, sleepTime));
      } else {
        console.warn(`Invalid sleep time value: ${node.attributes.sleep}, must be a positive integer`);
      }
    }
  }

  /**
   * Helper to add a "processing" message (role: Process) to the chat session.
   *
   * @param chatSession The current chat session
   * @param content     The text content of the <processingmessage> node
   */
  private async addProcessingMessage(chatSession: ChatSession, node: any, promptRequest: any, responseOrigin: any): Promise<void> {
    const sessionId = chatSession.id;
    const procMessage: ChatTurn = {
      id: uuidv4(),
      sessionId,
      content: node,
      role: MessageRole.Process,
      senderId: 'system',
      timestamp: new Date(),
      artifacts: [],
      turnMetadata: {
        turnNumber: chatSession.turns.length + 1,
        processingTime: 0,
        turnData: { promptRequest: promptRequest, responseData: responseOrigin, messageNode: node }
      }
    };

    chatSession.turns.push(procMessage);
    await this.updateSession(chatSession);

     // Check for sleep attribute and wait if specified
     if (node?.attributes?.sleep) {
      const sleepTime = parseInt(node.attributes.sleep, 10);
      if (!isNaN(sleepTime) && sleepTime > 0) {
        await new Promise(resolve => setTimeout(resolve, sleepTime));
      } else {
        console.warn(`Invalid sleep time value: ${node.attributes.sleep}, must be a positive integer`);
      }
    }
  }

  /**
   * Helper to trigger a user message to be sent via the chat interface.
   * This creates the appearance of the user typing the message.
   *
   * @param chatSession The current chat session
   * @param node        The <usermessage> node containing the message content
   */
  private async addUserMessage(chatSession: ChatSession, node: any): Promise<void> {
    const sessionId = chatSession.id;
    
    // Get the message content from the node
    const messageContent = node.content || '';
    
    if (messageContent.trim() !== '') {
      // Create the message object with sessionId and content
      const message: any = { 
        sessionId: sessionId, 
        message: messageContent 
      };

      // Check if node has artifacts in its attributes and process them
      if (node.attributes && node.attributes['artifacts']) {
        try {
          // Replace escaped quotes with regular quotes and parse the JSON
          const artifactsJson = node.attributes['artifacts'].replace(/\\"/g, '"');
          const artifactsData = this.jsonParser.safeParseJson(artifactsJson);
          
          // Convert each artifact data into a proper Artifact object
          const artifacts: Artifact[] = artifactsData.map((data: any) => ({
            id: data.id,
            title: data.title,
            content: data.content,
            type: data.type || 'text/plain',
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
            action: ArtifactAction.Created
          }));
          
          // Add artifacts to the message
          message.artifacts = artifacts;
        } catch (error) {
          console.error('[ChatService] Error parsing artifacts in addUserMessage:', error);
        }
      }

      // Send the message to the chat interface using the sharedState service
      this.sharedState.sendChatMessage$.next(message);

       // Check for sleep attribute and wait if specified
    if (node?.attributes?.sleep) {
      const sleepTime = parseInt(node.attributes.sleep, 10);
      if (!isNaN(sleepTime) && sleepTime > 0) {
        await new Promise(resolve => setTimeout(resolve, sleepTime));
      } else {
        console.warn(`Invalid sleep time value: ${node.attributes.sleep}, must be a positive integer`);
      }
    }
    }
  }

  /**
   * If <next> is found, this updates the session with the new playbookId and calls
   * `processTurn` again (recursively) to continue the flow.
   *
   * @param sessionId      The current session's ID
   * @param nextPlaybookId The new playbook ID from the <next> tag
   * @param playbookId     The current playbook ID
   * @param chatSession    The current chat session
   * @param content        The user-supplied content
   */
  private async handleNextPlaybook(
    sessionId: string,
    nextPlaybookId: string | null,
    playbookId: string,
    chatSession: ChatSession,
    content: string,
    isRiffMl: boolean = false
  ): Promise<void> {
    if (!nextPlaybookId) {
      console.error('No new playbook specified in <next> tag. Aborting...');
      return;
    }

    if (!isRiffMl && nextPlaybookId === playbookId) {
      console.error('The next playbook is the same as the current one. Halting...');
      return;
    }

    // Update the state to use the new playbook
    this.stateManagerService.setState(
      sessionId,
      'session.playbook',
      'session.playbook',
      'session.playbook',
      'session playbook used by the conversation',
      'why relevant: needed to guide the conversation',
      'string',
      nextPlaybookId
    );

    // Recursively call processTurn with the new playbook
    await this.processTurn(chatSession, content);
  }

  /**
   * Updates the session with the new playbookId and processes the next turn.
   * This method handles the transition between playbooks when a <next> tag is found.
   *
   * @param sessionId      The current session's ID
   * @param nextPlaybookId The new playbook ID to use
   * @returns A Promise that resolves when the playbook transition is complete
   */
  public async runPlaybook(
    sessionId: string,
    nextPlaybookId: string,
    setNextPlaybookId: boolean = false,
    storeTarget: string | null = null
  ): Promise<void> {
    console.log('[ChatService] Handling next playbook transition:', { sessionId, nextPlaybookId });
    
    // Validate inputs
    if (!nextPlaybookId) {
      console.error('[ChatService] No new playbook specified. Aborting playbook transition.');
      return;
    }

    // Get the chat session from workspace state
    const chatSessionResult = this.sharedState.tryGetChatSession(sessionId);
    if (!chatSessionResult || !chatSessionResult.value) {
      console.error('[ChatService] Unable to find chat session:', sessionId);
      return;
    }
    
    const chatSession = chatSessionResult.value;
    
    // Get the most recent content from the last user message
    const lastUserTurn = [...chatSession.turns]
      .reverse()
      .find(turn => turn.role === MessageRole.User);
      
    const content = lastUserTurn?.content || '';

    if (setNextPlaybookId)
    {
      // Update the state to use the new playbook
      this.stateManagerService.setState(
        sessionId,
        'session.nextPlaybook',
        'session.nextPlaybook',
        'session.nextPlaybook',
        'session playbook used by the conversation',
        'why relevant: needed to guide the conversation',
        'string',
        nextPlaybookId
      );
    }

    // Call process turn with the updated playbook
    await this.processTurn(chatSession, content, undefined, null, null, nextPlaybookId, storeTarget);
  }

  /**
   * If none of the special tags (wait, next, end) appear, we call the BedrockService
   * to generate content, then re-process the newly generated content as if it were RiffML.
   *
   * @param chatSession The current chat session
   * @param prompt      The prompt string for the AI model
   * @param content     The user-supplied content (used in details)
   * @param playbookId  The playbook ID for reference
   */
  private async handleBedrockCompletionAndReprocess(
    chatSession: ChatSession,
    prompt: string,
    content: string,
    playbookId: string,
    storeTarget: string | null = null,
    actionNodes: RiffMLNode[] = []
  ): Promise<void> {
    const completionRequest: AICompletionRequest = {
      prompt,
      model: AIModel.SONNET,
      maxTokens: 8000,
      temperature: 0.7,
      details: playbookId + ' ' + content
    };

    // Start the completion request
    const completionPromise = this.messageProcessor['bedrockService'].getCompletion(completionRequest);

    // Check for progress messages in the action nodes
    const progressNode = actionNodes.find(node => node.tagName.toLowerCase() === 'progressmessage');

    if (progressNode) {
      // Process progress messages in the background while waiting for completion
      const interval = parseInt(progressNode.attributes['interval'] || '5000');
      const messages = progressNode.content.split(';').map(msg => msg.trim()).filter(msg => msg);
      
      // Start progress messages in the background
      const progressPromise = (async () => {
        for (const message of messages) {
          const procMessage: ChatTurn = {
            id: uuidv4(),
            sessionId: chatSession.id,
            content: message,
            role: MessageRole.Process,
            senderId: 'system',
            timestamp: new Date(),
            artifacts: [],
            turnMetadata: {
              turnNumber: chatSession.turns.length + 1,
              processingTime: 0,
              turnData: { messageNode: progressNode }
            }
          };
          chatSession.turns.push(procMessage);
          await this.updateSession(chatSession);
          await new Promise(resolve => setTimeout(resolve, interval));
        }
      })();

      // Wait for both completion and progress messages
      const [response] = await Promise.all([completionPromise, progressPromise]);
      
      if (!response.content) {
        return;
      }

      if(storeTarget)
      {
        this.stateManagerService.setState(
          chatSession.id,
          storeTarget,
          storeTarget,
          storeTarget,
          'store target for the conversation',
          'why relevant: needed to guide the conversation',
          'string',
          response.content
        )
      }
      else
      {
        var sres = response.content.toLocaleLowerCase();
        if (sres.indexOf('/next')==-1 && sres.indexOf('/end')==-1 && sres.indexOf('/wait')==-1 &&
            sres.indexOf('next/')==-1 && sres.indexOf('end/')==-1 && sres.indexOf('wait/')==-1) 
        {
          response.content = response.content + ' <message>BUGBUG: missing action, adding wait</message> <wait />'
        }

        // Re-process the new content as RiffML
        await this.processTurn(chatSession, response.content, true, completionRequest, response);
      }
    } else {
      // No progress messages, just wait for completion
      const response = await completionPromise;
      if (!response.content) {
        return;
      }

      if(storeTarget)
      {
        this.stateManagerService.setState(
          chatSession.id,
          storeTarget,
          storeTarget,
          storeTarget,
          'store target for the conversation',
          'why relevant: needed to guide the conversation',
          'string',
          response.content
        );
        console.log('storing data from LLM', storeTarget, response.content);
      }
      else
      {
        var sres = response.content.toLocaleLowerCase();
        if (sres.indexOf('/next')==-1 && sres.indexOf('/end')==-1 && sres.indexOf('/wait')==-1 &&
            sres.indexOf('next/')==-1 && sres.indexOf('end/')==-1 && sres.indexOf('wait/')==-1) 
        {
          response.content = response.content + ' <message>BUGBUG: missing action, adding wait</message> <wait />'
        }

        // Re-process the new content as RiffML
        await this.processTurn(chatSession, response.content, true, completionRequest, response);
      }
    }
  }

  /**
   * Returns the default PM playbook ID (kept from original code).
   */
  public static GetDefaultPMPlaybook() {
    const urlParams = new URLSearchParams(window.location.search);
    const devParam = urlParams.get('dev');

    const playbookId =
      devParam === null
        ? '43548' // no dev param
        : devParam === '1'
        ? '43548'
        : devParam;
    return playbookId;
  }

  /**
   * Updates the status of a workstream change
   */
  async updateWorkstreamChangeStatus(
    sessionId: string,
    messageId: string,
    workstream: ProjectManagerWorkstream,
    newStatus: 'accepted' | 'rejected'
  ): Promise<void> {
    const chatSession = await this.loadOrCreateChatSession(sessionId);
    const updatedSession = await this.changeManager.updateWorkstreamStatus(
      chatSession,
      messageId,
      workstream,
      newStatus
    );
    await this.updateSession(updatedSession);
  }

  /**
   * Updates the status of an existing session suggestion
   */
  async updateExistingSessionChangeStatus(
    sessionId: string,
    messageId: string,
    suggestedSession: SuggestedSession,
    newStatus: 'accepted' | 'rejected'
  ): Promise<void> {
    const chatSession = await this.loadOrCreateChatSession(sessionId);
    const updatedSession = await this.changeManager.updateExistingSessionStatus(
      chatSession,
      messageId,
      suggestedSession,
      newStatus
    );
    await this.updateSession(updatedSession);
  }

  /**
   * Updates the status of a new session suggestion
   */
 async updateNewSessionChangeStatus(
    sessionId: string,
    messageId: string,
    suggestedNewSession: SuggestedNewSession,
    newStatus: 'accepted' | 'rejected'
  ): Promise<number> {
    const chatSession = await this.loadOrCreateChatSession(sessionId);
    const { chatSession: updatedSession, sessionIndex } = await this.changeManager.updateNewSessionStatus(
      chatSession,
      messageId,
      suggestedNewSession,
      newStatus
    );
    await this.updateSession(updatedSession);
    return sessionIndex;
  }
}
