<div class="chat-container">
  <ng-container *ngIf="!isLoading">
    <div class="chat-content">
      <div class="messages-container" #messagesContainer>
        <ng-container *ngFor="let message of messages; let i = index">
          <!-- Render the message component unless it's a system/process message 
               that we only want to show at the last turn -->
          <app-message
            *ngIf="!isProcessMessage(message) || i === messages.length - 1"
            [message]="message"
            [showDebugButton]="isDevMode"
            [sessionId]="sessionId??''"
            [isLastMessage]="i === messages.length - 1"
            (debug)="openDebugView(message.turnMetadata.turnData)"
            (edit)="onEditMessage(message)"
            (copy)="onCopyMessage(message)"
            (read)="onReadMessage(message)"
            (like)="onLikeMessage(message)"
            (dislike)="onDislikeMessage(message)"
            (acceptWorkstream)="onAcceptWorkstream($event)"
            (rejectWorkstream)="onRejectWorkstream($event)"
            (openExistingSession)="onOpenExistingSession($event)"
            (acceptNewSession)="onAcceptNewSession($event)"
            (rejectNewSession)="onRejectNewSession($event)"
            (selectListItemClicked)="onSelectListItemClicked($event)"
          >
          </app-message>
        </ng-container>
      </div>
      <app-input-box
        [isProcessing]="(isProcessing$ | async) ?? false"
        (messageSent)="onMessageSent($event)"
      >
      </app-input-box>
    </div>
  </ng-container>
</div>
