<div class="workstream" 
     [class.closed]="workstream.state === 'closed'"
     [class.selected]="isSelected || workstream.state !== 'closed'"
     [class.has-version-changes]="hasVersionChange"
     [class.edit-mode]="editMode"
     [class.has-changes]="hasChanges"
     [style.width.px]="editMode ? workboardWidth : null"
     [style.height.px]="editMode ? workboardHeight : null">
  
  <div class="workstream-header" (click)="toggleState($event)">
    <div class="workstream-title" [class.largeTitle]="workstream.state !== 'closed'">{{ workstream.name }} <div *ngIf="hasVersionChange" class="uncommitted-label">uncommitted</div></div>
    <!-- Uncommitted label -->
    
    <div class="workstream-controls">
      <!-- Save Changes Button (Shown when in edit mode and changes detected) -->
      <button *ngIf="editMode && workstream.inEditMode"
              class="save-all-changes-button"
              [class.has-changes]="hasChanges"
              [disabled]="!hasChanges"
              matTooltip="Save all changes"
              (click)="saveChanges($event)">
        <mat-icon class="save-icon">check</mat-icon>
        <span>Save Changes</span>
      </button>
      <!-- Edit button -->
      <button *ngIf="editMode" class="edit-button"
              [class.active]="editMode"
              matTooltip="{{ workstream.inEditMode ? 'Exit edit mode' : 'Edit workstream' }}"
              (click)="toggleEditMode($event)">
        <mat-icon class="edit-icon">{{ workstream.inEditMode ? 'close' : 'edit' }}</mat-icon>
      </button>
      <!-- Dev mode info button -->
      <button *ngIf="isDevMode" 
              class="info-button"
              matTooltip="View workstream data"
              (click)="openDebugInfo($event)">
        <mat-icon class="info-icon">info</mat-icon>
      </button>
      <button *ngIf="workstream.state === 'open'" class="toggle-button" (click)="toggleToFull($event)">
        <i class="fas" [class.fa-compress]="editMode" [class.fa-expand]="!editMode"></i>
      </button>
    </div>
  </div>

  <div *ngIf="workstream.state === 'open'" class="workstream-content">
    <!-- Input Tables (Always shown in both views) -->
    <div *ngIf="workstream.input_table_ids?.length" class="tables-section input-tables">
      <div *ngFor="let tableId of workstream.input_table_ids" 
           class="table-item"
           (click)="handleTableClick($event, tableId)">
        {{ tableId }}
      </div>
    </div>

    <!-- Default View (Global Mingus/Code and Output Tables) -->
    <div *ngIf="displayMode === 'default'" class="content-wrapper">
      <!-- Main Content Area -->
      <div class="content-section">
        <div class="content-body">
          <div class="code-editor-wrapper" 
               [class.readonly]="!editMode"
               [class.mingus-view]="activeView === 'mingus'"
               [class.code-view]="activeView === 'code'">
            <div *ngIf="activeView === 'code'" class="language-tags">
              <span [class.active]="workstream.type === 'sql'" (click)="handleLanguageSelection($event, 'sql')">sql</span>
              <span [class.active]="workstream.type === 'python'" (click)="handleLanguageSelection($event, 'python')">python</span>
              <span [class.active]="workstream.type === 'r'" (click)="handleLanguageSelection($event, 'r')">r</span>
              <span [class.active]="workstream.type === 'javascript'" (click)="handleLanguageSelection($event, 'javascript')">javascript</span>
            </div>
            <ngx-codejar
              [code]="currentCode"
              [options]="codeJarOptions"
              [highlightMethod]="highlight"
              [readonly]="!editMode"
              (codeChange)="handleContentEdit($event)"
              class="code-editor"
            ></ngx-codejar>
          </div>
        </div>
        <div class="content-header">
          <div class="content-controls">
            <div class="view-toggle">
              <span [class.active]="activeView === 'mingus'" 
                    (click)="toggleView(); $event.stopPropagation()">
                Mingus
              </span>
              <span [class.active]="activeView === 'code'" 
                    (click)="toggleView(); $event.stopPropagation()">
                Code
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Output Tables -->
      <div *ngIf="workstream.output_table_ids?.length" class="tables-section output-tables">
        <div *ngFor="let tableConfig of workstream.output_table_ids" 
             class="table-item"
             (click)="handleOutputTableClick($event, tableConfig)">
          {{ tableConfig.name }}
        </div>
      </div>
    </div>

    <!-- Units View -->
    <div *ngIf="displayMode === 'units'" class="units-wrapper">
      <!-- Expand All Button -->
      <div class="expand-all-control" (click)="toggleAllExpand($event)">
        <span class="expand-label">{{ allExpanded ? 'Collapse' : 'Expand' }} Mingus</span>
        <div class="expand-icon">
          <svg *ngIf="!allExpanded" class="Icon ExpandIcon HighlightSol HighlightSol--core" data-testid="ExpandIcon" viewBox="0 0 32 32" aria-hidden="true" focusable="false"><path d="M13.7,19.7L5.4,28H13c0.6,0,1,0.4,1,1s-0.4,1-1,1H3c-0.6,0-1-0.4-1-1V19c0-0.6,0.4-1,1-1s1,0.4,1,1v7.6l8.3-8.3c0.4-0.4,1-0.4,1.4,0S14.1,19.3,13.7,19.7z M29,2H19c-0.6,0-1,0.4-1,1s0.4,1,1,1h7.6l-8.3,8.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3L28,5.4V13c0,0.6,0.4,1,1,1s1-0.4,1-1V3C30,2.4,29.6,2,29,2z"></path></svg>
          <svg *ngIf="allExpanded" class="Icon CollapseIcon HighlightSol HighlightSol--core" data-testid="CollapseIcon" viewBox="0 0 32 32" aria-hidden="true" focusable="false"><path d="M30.706 2.706 21.413 12h7.586a1 1 0 0 1 0 2h-10a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v7.586l9.293-9.293a.999.999 0 1 1 1.413 1.414Zm-29.414 28a.997.997 0 0 0 1.414 0l9.293-9.293v7.586a1 1 0 0 0 2 0V19a1 1 0 0 0-1-1h-10a1 1 0 0 0 0 2h7.586l-9.293 9.293a.999.999 0 0 0 0 1.414Z"></path></svg>
        </div>
      </div>

      <!-- Units -->
      <div *ngFor="let unit of workstream.units" class="unit-block" [class.unit-changed]="isUnitChanged(unit.id)">
        <!-- Show unit title only when there are no output tables -->
        <div *ngIf="shouldShowUnitTitle(unit)" class="unit-title">{{ unit.name }}</div>
        
        <!-- Unit Output Tables - Moved to be above the content -->
        <div *ngIf="unit.output_table_ids?.length" class="unit-tables-section" [class.changed-unit-tables]="isUnitChanged(unit.id)">
          <div *ngFor="let tableConfig of unit.output_table_ids" 
               class="table-item"
               [class.export-table]="tableConfig.export"
               [class.changed-unit-table]="isUnitChanged(unit.id)"
               [class.empty-unit-table]="isUnitEmpty(unit)"
               (click)="handleOutputTableClick($event, tableConfig)">
            {{ tableConfig.name }}
            <span *ngIf="tableConfig.export" class="export-arrow">→</span>
            
            <!-- Expand/Collapse Unit Content Button -->
            <div class="expand-unit-control" (click)="toggleUnitExpand($event, unit.id)">
              <svg *ngIf="!expandedUnits[unit.id]" class="Icon ExpandIcon HighlightSol HighlightSol--core" data-testid="ExpandIcon" viewBox="0 0 32 32" aria-hidden="true" focusable="false"><path d="M13.7,19.7L5.4,28H13c0.6,0,1,0.4,1,1s-0.4,1-1,1H3c-0.6,0-1-0.4-1-1V19c0-0.6,0.4-1,1-1s1,0.4,1,1v7.6l8.3-8.3c0.4-0.4,1-0.4,1.4,0S14.1,19.3,13.7,19.7z M29,2H19c-0.6,0-1,0.4-1,1s0.4,1,1,1h7.6l-8.3,8.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3L28,5.4V13c0,0.6,0.4,1,1,1s1-0.4,1-1V3C30,2.4,29.6,2,29,2z"></path></svg>
              <svg *ngIf="expandedUnits[unit.id]" class="Icon CollapseIcon HighlightSol HighlightSol--core" data-testid="CollapseIcon" viewBox="0 0 32 32" aria-hidden="true" focusable="false"><path d="M30.706 2.706 21.413 12h7.586a1 1 0 0 1 0 2h-10a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v7.586l9.293-9.293a.999.999 0 1 1 1.413 1.414Zm-29.414 28a.997.997 0 0 0 1.414 0l9.293-9.293v7.586a1 1 0 0 0 2 0V19a1 1 0 0 0-1-1h-10a1 1 0 0 0 0 2h7.586l-9.293 9.293a.999.999 0 0 0 0 1.414Z"></path></svg>
            </div>
          </div>
        </div>
        
        <!-- Unit Content Area (Expandable) - Now placed after the output tables -->
        <div *ngIf="editMode || expandedUnits[unit.id]" class="unit-content-section">
          <!-- In edit mode, show both panes side by side -->
          <ng-container *ngIf="editMode">
            <!-- Mingus Editor -->
            <div class="content-body" [attr.data-placeholder]="getUnitActiveView(unit.id) === 'mingus' ? 'Mingus will be created here' : 'Code will be created here'">
              <div class="code-editor-wrapper" 
                   [class.readonly]="!editMode"
                   [class.mingus-view]="getUnitActiveView(unit.id) === 'mingus'"
                   [class.code-view]="getUnitActiveView(unit.id) === 'code'">
                <ngx-codejar
                  [id]="'unit-' + unit.id + '-mingus'"
                  [code]="getUnitMingusCode(unit)"
                  [options]="codeJarOptions"
                  [highlightMethod]="highlight"
                  [readonly]="!editMode"
                  (codeChange)="handleUnitContentEdit($event, unit, 'mingus')"
                  class="code-editor"
                ></ngx-codejar>
              </div>
              <div class="content-header">
                <div class="content-controls">
                  <div class="view-label">
                    <span class="active">Mingus</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Code Editor -->
            <div class="content-body" [attr.data-placeholder]="getUnitActiveView(unit.id) === 'code' ? 'Code will be created here' : 'Mingus will be created here'">
              <div class="code-editor-wrapper" 
                   [class.readonly]="!editMode"
                   [class.mingus-view]="getUnitActiveView(unit.id) === 'mingus'"
                   [class.code-view]="getUnitActiveView(unit.id) === 'code'">
                <div *ngIf="editMode" class="language-tags">
                  <span [class.active]="(unit.type || workstream.type) === 'sql'" (click)="handleLanguageSelection($event, 'sql', unit)">sql</span>
                  <span [class.active]="(unit.type || workstream.type) === 'python'" (click)="handleLanguageSelection($event, 'python', unit)">python</span>
                  <span [class.active]="(unit.type || workstream.type) === 'r'" (click)="handleLanguageSelection($event, 'r', unit)">r</span>
                  <span [class.active]="(unit.type || workstream.type) === 'javascript'" (click)="handleLanguageSelection($event, 'javascript', unit)">javascript</span>
                </div>
                <ngx-codejar
                  [id]="'unit-' + unit.id + '-code'"
                  [code]="getUnitCode(unit)"
                  [options]="codeJarOptions"
                  [highlightMethod]="highlight"
                  [readonly]="!editMode"
                  (codeChange)="handleUnitContentEdit($event, unit, 'code')"
                  class="code-editor"
                ></ngx-codejar>
              </div>
              <div class="content-header">
                <div class="content-controls">
                  <div class="view-label">
                    <span class="active">Code</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- In non-edit mode, show single pane with toggle -->
          <ng-container *ngIf="!editMode">
            <!-- Mingus View -->
            <div class="content-body" *ngIf="getUnitActiveView(unit.id) === 'mingus'" [attr.data-placeholder]="getUnitActiveView(unit.id) === 'mingus' ? 'Mingus will be created here' : 'Code will be created here'">
                <div class="code-editor-wrapper" 
                   [class.readonly]="!editMode"
                   [class.mingus-view]="getUnitActiveView(unit.id) === 'mingus'"
                   [class.code-view]="getUnitActiveView(unit.id) === 'code'">
                <ngx-codejar
                  [id]="'unit-' + unit.id + '-mingus'"
                  [code]="getUnitMingusCode(unit)"
                  [options]="codeJarOptions"
                  [highlightMethod]="highlight"
                  [readonly]="!editMode"
                  class="code-editor"
                ></ngx-codejar>
              </div>
            </div>

            <!-- Code View -->
            <div class="content-body" *ngIf="getUnitActiveView(unit.id) === 'code'" [attr.data-placeholder]="getUnitActiveView(unit.id) === 'code' ? 'Code will be created here' : 'Mingus will be created here'">
              <div class="code-editor-wrapper" 
                   [class.readonly]="!editMode"
                   [class.mingus-view]="getUnitActiveView(unit.id) === 'mingus'"
                   [class.code-view]="getUnitActiveView(unit.id) === 'code'">
                <div *ngIf="getUnitActiveView(unit.id) === 'code'" class="language-tags">
                  <span [class.active]="(unit.type || workstream.type) === 'sql'" (click)="handleLanguageSelection($event, 'sql', unit)">Sql</span>
                  <span [class.active]="(unit.type || workstream.type) === 'python'" (click)="handleLanguageSelection($event, 'python', unit)">Python</span>
                  <span [class.active]="(unit.type || workstream.type) === 'r'" (click)="handleLanguageSelection($event, 'r', unit)">R</span>
                  <span [class.active]="(unit.type || workstream.type) === 'javascript'" (click)="handleLanguageSelection($event, 'javascript', unit)">Javascript</span>
                </div>
                <ngx-codejar
                  [id]="'unit-' + unit.id + '-code'"
                  [code]="getUnitCode(unit)"
                  [options]="codeJarOptions"
                  [highlightMethod]="highlight"
                  [readonly]="!editMode"
                  class="code-editor"
                ></ngx-codejar>
              </div>
            </div>

            <!-- Fixed toggle buttons for non-edit mode -->
            <div class="content-header">
              <div class="content-controls">
                <div class="view-toggle">
                  <span [class.active]="getUnitActiveView(unit.id) === 'mingus'" 
                        (click)="toggleUnitView(unit.id, $event)">
                    Mingus
                  </span>
                  <span [class.active]="getUnitActiveView(unit.id) === 'code'" 
                        (click)="toggleUnitView(unit.id, $event)">
                    Code
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>