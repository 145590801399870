// src/app/components/tabs/tab/tab.component.ts
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TabInfo } from '../../../models/tab.interface';

@Component({
  selector: 'app-tab',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule],
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  host: {
    '[attr.data-session-id]': 'tab.sessionId',
    '[style.--tab-color]': 'color'
  }
})
export class TabComponent {
  @Input() tab!: TabInfo;
  @Input() color?: string;
  @Output() close = new EventEmitter<void>();
  @Output() activate = new EventEmitter<void>();
}