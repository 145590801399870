<div class="record-preview" 
     [style.transform]="'translate3d(' + positionX + 'px, ' + positionY + 'px, 0)'"
     [class.visible]="isPositioned"
     #previewContainer>
  <div class="preview-header">
    <div class="preview-info">
      <div class="preview-title">
        <mat-icon class="record-type-icon">{{record?.icon}}</mat-icon>
        <span>{{record?.name}}</span>
      </div>
      <p class="description">{{record?.description}}</p>
      
      <div class="tags">
        <mat-chip-listbox>
          <mat-chip *ngFor="let tag of record?.tags" class="tag-chip">
            <span class="bullet" [style.backgroundColor]="tag.color"></span>
            {{tag.label}}
          </mat-chip>
        </mat-chip-listbox>
      </div>
    </div>
  </div>

  <div class="recommended-flows" *ngIf="record?.recommendedFlows?.length">
    <div class="flow-item" *ngFor="let flow of record?.recommendedFlows">
      <mat-icon class="flow-icon">arrow_forward</mat-icon>
      <span class="flow-name">{{flow.name}}</span>
    </div>
  </div>
</div>
