import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { ChatService } from '../../../services/chat.service';
import { UserService } from '../../../services/user.service';
import { Artifact } from '../../../models/artifact.model';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { ChatTurn } from '../../../models/chat.model';
import { MessageComponent } from '../message/message.component';

import { InputBoxComponent } from '../input-box/input-box.component';

import { PropertiesBarService } from '../../../services/properties-bar.service';
import { ProjectManagerService } from '../../../services/project-manager.service';
import { DebugViewComponent } from '../debug-view/debug-view.component';

import { WorkspaceState } from '../../../models/workspace-state.model';
import { FileInfo } from '../../../models/fileinfo.model';

import {
  ProjectManagerWorkstream,
  SuggestedSession,
  SuggestedNewSession,
  ProjectManagerWorkboardGroup,
  ProjectManagerWorkstreamColumn,
  
} from '../../../models/project-manager-chat.model';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDialogModule,
    MessageComponent,
    InputBoxComponent,
    DebugViewComponent
  ]
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {
  @Input() sessionId?: string;
  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;

  /** ViewChild to reference the input box so we can set or append text. */
  @ViewChild(InputBoxComponent) inputBoxComp!: InputBoxComponent;

  messages: ChatTurn[] = [];
  hasMessages: boolean = false;
  isProcessing$: Observable<boolean>;

  private processingSubject = new BehaviorSubject<boolean>(false);
  private shouldScrollToBottom = false;
  private userId: string | null = null;
  private subscription = new Subscription();
  isLoading = true;

  // Flag to detect if dev mode is turned on (via ?dev=1 in URL)
  isDevMode = false;

  constructor(
    private chatService: ChatService,
    private userService: UserService,
    private projectManager: ProjectManagerService,
    private cd: ChangeDetectorRef,
    private propertiesBarService: PropertiesBarService,
    private dialog: MatDialog,
    private workspaceState: WorkspaceState // Inject the WorkspaceState service

  ) {
    this.isProcessing$ = this.processingSubject.asObservable();
  }

  async ngOnInit() {
    // Check if dev mode is active
    this.isDevMode = window.location.search.includes('dev');

    const user = await this.userService.getCurrentUser();
    this.userId = user?.email || null;

    // Subscribe to session changes
    this.subscription.add(
      this.projectManager.getGridState().subscribe(state => {
        if (state.selectedSessionId !== this.sessionId) {
          this.sessionId = state.selectedSessionId;
          this.loadMessages();
        }
      })
    );

    // Subscribe to sendChatMessage$ changes
    this.subscription.add(
      this.workspaceState.sendChatMessage$.subscribe(messageData => {
        if (messageData && messageData.message) {
          // If a sessionId is provided and it matches the current session, use it
          // Otherwise, use the current sessionId
          const targetSessionId = messageData.sessionId || this.sessionId;
          
          if (targetSessionId) {
            // Only send if we have a valid sessionId
            this.onMessageSent({message: messageData.message, artifacts: messageData.artifacts});
            
            // Reset the BehaviorSubject to prevent repeated sends on re-subscription
            this.workspaceState.sendChatMessage$.next(null);
          }
        }
      })
    );

    await this.loadMessages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sessionId'] && !changes['sessionId'].firstChange) {
      this.loadMessages();
    }
  }

  private async loadMessages() {
    this.isLoading = true;

    if (!this.sessionId || !this.userId) {
      this.messages = [];
      this.hasMessages = false;
      this.isLoading = false;
      return;
    }

    try {
      this.subscription.add(
        this.chatService.getMessagesObservable(this.sessionId).subscribe(messages => {
          this.messages = messages as ChatTurn[];
          this.hasMessages = messages.length > 0;
          this.shouldScrollToBottom = true;
          this.cd.detectChanges();
        })
      );

      await this.chatService.getMessages(this.sessionId);
    } catch (error) {
      console.error('Error loading messages:', error);
    } finally {
      this.isLoading = false;
    }
  }

  ngAfterViewChecked() {
    if (this.shouldScrollToBottom) {
      this.scrollToBottom();
      this.shouldScrollToBottom = false;
    }
  }

  async onMessageSent(data: {message: string, fileInfo?: FileInfo, artifacts?: Artifact[]}): Promise<void> {
    if (!this.sessionId || !this.userId) return;

    this.processingSubject.next(true);
    this.shouldScrollToBottom = true;

    try {
      await this.chatService.sendMessage(this.sessionId, data.message, this.userId, data.fileInfo, data.artifacts);
      this.processingSubject.next(false);
    } catch (error) {
      console.error('Error sending message:', error);
      this.processingSubject.next(false);
    }
  }

  private scrollToBottom(): void {
    try {
      if (
        !this.messagesContainer ||
        !this.messagesContainer.nativeElement ||
        this.messagesContainer.nativeElement.scrollTop === undefined
      ) {
        return;
      }
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onEditMessage(message: ChatTurn): void {
    console.log('Edit message:', message);
  }

  onCopyMessage(message: ChatTurn): void {
    if (message.content) {
      navigator.clipboard.writeText(message.content);
    }
  }

  onReadMessage(message: ChatTurn): void {
    if (message.content) {
      const utterance = new SpeechSynthesisUtterance(message.content);
      window.speechSynthesis.speak(utterance);
    }
  }

  onLikeMessage(message: ChatTurn): void {
    console.log('Like message:', message);
  }

  onDislikeMessage(message: ChatTurn): void {
    console.log('Dislike message:', message);
  }

  onEnlargeArtifact(artifact: Artifact) {
    this.propertiesBarService.showArtifact({
      id: artifact.id,
      title: artifact.title,
      content: artifact.content,
      type: artifact.type || 'text',
      action: artifact.action,
      createdAt: artifact.createdAt,
      updatedAt: artifact.updatedAt,
      language: artifact.language
    });
  }

  isProcessMessage(message: ChatTurn): boolean {
    return message.role === 'process';
  }

  shouldShowProcessMessage(message: ChatTurn, index: number): boolean {
    return this.isProcessMessage(message) && index === this.messages.length - 1;
  }

  hasLoadingDots(message: ChatTurn): boolean {
    return this.isProcessMessage(message) && message.content.endsWith('...');
  }

  getMessageContent(message: ChatTurn): string {
    if (this.hasLoadingDots(message)) {
      return message.content.slice(0, -3);
    }
    return message.content;
  }

  openDebugView(turnData: any): void {
    this.dialog.open(DebugViewComponent, {
      data: turnData,
      width: '90vw',
      maxHeight: '90vh'
    });
  }

  onAcceptWorkstream(event: {
    messageId: string;
    sessionId: string;
    workstream: ProjectManagerWorkstream;
    group: ProjectManagerWorkboardGroup;
    col: ProjectManagerWorkstreamColumn;
  }) {
    this.projectManager.acceptWorkstream(event);
  }

  onRejectWorkstream(event: {
    messageId: string;
    sessionId: string;
    workstream: ProjectManagerWorkstream;
    group: ProjectManagerWorkboardGroup;
    col: ProjectManagerWorkstreamColumn;
  }) {
    this.projectManager.rejectWorkstream(event);
  }

  onOpenExistingSession(event: {
    messageId: string;
    sessionId: string;
    session: SuggestedSession;
  }) {
    this.projectManager.openExistingSession(event);
  }

  onAcceptNewSession(event: {
    messageId: string;
    sessionId: string;
    session: SuggestedNewSession;
  }) {
    this.projectManager.acceptNewSession(event);
  }

  onRejectNewSession(event: {
    messageId: string;
    sessionId: string;
    session: SuggestedNewSession;
  }) {
    this.projectManager.rejectNewSession(event);
  }

  /**
   * Handler for <SelectFromList> itemClicked, from the MessageComponent.
   */
  onSelectListItemClicked(event: {
    text: string;
    multiple: boolean;
    sendOnClick: boolean;
    item: any;
  }) {
    if (!this.inputBoxComp) return;
    // If multiple => append a line, else replace
    if (event.multiple) {
      this.inputBoxComp.appendMessageLine(event.text);
    } else {
      this.inputBoxComp.setMessage(event.text);
    }
    // Optionally send automatically
    if (event.sendOnClick) {
      this.onMessageSent({message: event.text});
      setTimeout(() => {
        this.inputBoxComp.setMessage('');
      }, 200);
    }
  }
}