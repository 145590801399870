<!-- src/app/components/project-selection/project-selection.component.html -->

<nav class="breadcrumbs">
  <ol>
    <li>
      <button 
      mat-icon-button
      class="context-button left"
      *ngIf="debugMode"
      (click)="openContextMenu($event, 'workspace')"
    >
      <mat-icon>info_outline</mat-icon>
    </button>
    
      <div class="nav-item" [matMenuTriggerFor]="workspaceMenu" (click)="onWorkspaceMenuOpened()">
        <span class="workspace-name">{{ getSelectedWorkspaceName() }}</span>
        <button mat-icon-button class="more-button" (click)="onWorkspaceMenuOpened()">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

      <!-- Debug mode context button on the left of the next item -->


      <mat-menu #workspaceMenu="matMenu" yPosition="below">
        <ng-container *ngIf="workspacesData$ | async as data">
          <button mat-menu-item *ngFor="let workspace of data.workspaces" 
                  (click)="selectWorkspace(workspace)"
                  [class.selected]="selectedWorkspace?.id === workspace.id">
            <span class="mdc-list-item__primary-text">
              {{ workspace.name }}
              <button mat-icon-button (click)="$event.stopPropagation(); deleteWorkspace(workspace.id)">
                <mat-icon>delete</mat-icon>
              </button>
            </span>
          </button>
        </ng-container>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="promptNewWorkspace()" class="new-item">
          <mat-icon>add</mat-icon>
          <span>New Workspace</span>
        </button>
      </mat-menu>
    </li>
    <li *ngIf="selectedWorkspace">
            <!-- Debug mode context button on the right of the previous item -->
            <button 
            mat-icon-button
            class="context-button right"
            *ngIf="debugMode"
            (click)="openContextMenu($event, 'project')"
          >
            <mat-icon>info_outline</mat-icon>
          </button>

      <div class="nav-item" [matMenuTriggerFor]="projectMenu" (click)="onProjectMenuOpened()">
        <span class="project-name">{{ getSelectedProjectLabel() }}</span>
        <button mat-icon-button class="more-button" (click)="onProjectMenuOpened()">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

      <mat-menu #projectMenu="matMenu" yPosition="below">
        <ng-container *ngIf="selectedWorkspace">
          <button mat-menu-item *ngFor="let project of selectedWorkspace.projects"
                  (click)="selectProject(selectedWorkspace, project)"
                  [class.selected]="selectedProject?.id === project.id">
            <span class="mdc-list-item__primary-text">
              {{ project.label }}
              <button mat-icon-button (click)="$event.stopPropagation(); deleteProject(selectedWorkspace.id, project.id)">
                <mat-icon>delete</mat-icon>
              </button>
            </span>
          </button>
        </ng-container>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="promptNewProject(selectedWorkspace.id)" class="new-item">
          <mat-icon>add</mat-icon>
          <span>New Project</span>
        </button>
      </mat-menu>
    </li>
  </ol>
</nav>

<!-- The context popup viewer for debug mode -->
<div
  class="context-popup-container"
  *ngIf="showContextPopup"
  #contextPopupRef
  [style.left.px]="contextPopupX"
  [style.top.px]="contextPopupY"
>
  <app-context-viewer
    [stateItems]="contextStateItems"
    (itemSelected)="onContextItemSelected($event)"
  ></app-context-viewer>
</div>
