<div class="datapane-container">
    <div class="top-bar" [class.draggable]="isFloating" (mousedown)="isFloating ? startDragging($event) : null">
        <div class="toggle-buttons">
            <button *ngIf="showData" (click)="exportTableToExcel()" title="Download as Excel">
                <span class="material-icons">download</span>
                Download
            </button>
            <button [class.active]="showCode" (click)="togglePane('code')">
                <span class="material-icons">terminal</span>
                Code
            </button>
            <button [class.active]="showData" (click)="togglePane('data')">
                <span class="material-icons">table_chart</span>
                Data
            </button>
            <button *ngIf="mermaidCode || currentTable" [class.active]="showChart" (click)="togglePane('chart')">
                <span class="material-icons">account_tree</span>
                Chart
            </button>
            <button class="float-btn" (click)="toggleFloating()" [class.active]="isFloating" title="Float/Dock panel">
                <span class="material-icons">{{ isFloating ? 'push_pin' : 'open_in_new' }}</span>
                {{ isFloating ? 'Dock' : 'Float' }}
            </button>
        </div>
    </div>
    
    <div class="pane-container">
        <app-splitter>
            <app-splitter-pane *ngIf="showCode" [size]="getColspan('code') === 4 ? '100%' : getColspan('code') === 3 ? '75%' : getColspan('code') === 2 ? '50%' : '25%'">
                <app-codeview></app-codeview>
            </app-splitter-pane>

            <app-splitter-pane *ngIf="showChart" [size]="getColspan('chart') === 4 ? '100%' : getColspan('chart') === 3 ? '75%' : getColspan('chart') === 2 ? '50%' : '25%'">
                <app-chartview [mermaidCode]="mermaidCode" [isLoading]="isChartLoading"></app-chartview>
            </app-splitter-pane>

            <app-splitter-pane *ngIf="showData" [size]="getColspan('data') === 4 ? '100%' : getColspan('data') === 3 ? '75%' : getColspan('data') === 2 ? '50%' : '25%'">
                <app-datatable></app-datatable>
            </app-splitter-pane>
        </app-splitter>
    </div>
</div>