<div class="debug-view-container">
    <!-- Fixed Header with Title, Copy, and Close -->
    <div class="dialog-header">
      <h2>Debug Information</h2>
      <div class="header-actions">
        <button mat-button class="copy-button" (click)="onCopy()">{{copyLabel}}</button>
        <button mat-icon-button color="basic" (click)="onClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  
    <!-- Scrollable Body -->
    <div class="body-content">
      <pre><code class="json"></code></pre>
    </div>
  </div>