<!-- src/app/components/context-viewer/context-viewer.component.html -->

<div class="context-viewer-container">
    <!-- Adding height: 500px to match the popup max-height -->
    <div class="context-viewer">
      <div class="item-list-container">
        <div class="item-list">
          <table>
            <tr *ngFor="let item of stateItems" (click)="selectItem(item)">
              <td class="icon-cell">
                <mat-icon [matTooltip]="item.type">{{ getItemIcon(item.type) }}</mat-icon>
              </td>
              <td class="name-cell">
                <span
                  matTooltip="{{item.description}}"
                  matTooltipPosition="above"
                >
                  {{item.name}}
                </span>
              </td>
              <td class="value-cell">
                <span
                  matTooltip="{{getFullValue(item.data)}}"
                  matTooltipPosition="above"
                >
                  {{ getShortValue(item.data) }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
  
      <div class="detail-panel-container">
        <div class="detail-panel" *ngIf="selectedItem">
          <h4>{{ selectedItem.name }}</h4>
          <p class="detail-description">{{ selectedItem.description }}</p>
          <div class="detail-section">
            <strong>ID: </strong>{{ selectedItem.id }}
          </div>
          <div class="detail-section">
            <strong>Type: </strong>{{ selectedItem.type }}
          </div>
          <div class="detail-section">
            <strong>Why Relevant: </strong>{{ selectedItem.whyRelevant }}
          </div>
          <div class="detail-section">
            <strong>Data:</strong>
            <button mat-icon-button 
                    (click)="copyDataToClipboard()"
                    matTooltip="Copy to clipboard"
                    class="copy-button">
              <mat-icon>content_copy</mat-icon>
            </button>
            <pre>{{ selectedItem.data | json }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>