import {
    Component,
    OnInit,
    AfterViewInit,
    ElementRef,
    Inject,
    ChangeDetectorRef
  } from '@angular/core';
  import { CommonModule } from '@angular/common';
  import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
  import { MatIconModule } from '@angular/material/icon';
  import { MatButtonModule } from '@angular/material/button';
  import hljs from 'highlight.js';
  
  @Component({
    standalone: true,
    selector: 'app-debug-view',
    templateUrl: './debug-view.component.html',
    styleUrls: ['./debug-view.component.scss'],
    imports: [CommonModule, MatDialogModule, MatIconModule, MatButtonModule]
  })
  export class DebugViewComponent implements OnInit, AfterViewInit {
    jsonContent = '';
    copyLabel = 'Copy';
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<DebugViewComponent>,
      private elementRef: ElementRef,
      private cdr: ChangeDetectorRef
    ) {}
  
    ngOnInit(): void {
      // 1) Convert input data to pretty-printed JSON
      let raw = JSON.stringify(this.data, null, 2);
  
      // 2) Replace literal "\n" with real newlines
      raw = raw.replace(/\\n/g, '\n');
  
      // 3) Store result in jsonContent
      this.jsonContent = raw;
    }
  
    ngAfterViewInit(): void {
      // Trigger change detection to ensure <code> is in DOM
      this.cdr.detectChanges();
      this.highlightCode();
    }
  
    highlightCode(): void {
      const codeBlock = this.elementRef.nativeElement.querySelector('code');
      if (codeBlock) {
        // Put the raw JSON into the code block
        codeBlock.textContent = this.jsonContent;
        // Then syntax highlight it
        hljs.highlightBlock(codeBlock);
      }
    }
  
    onClose(): void {
      this.dialogRef.close();
    }
  
    onCopy(): void {
      navigator.clipboard.writeText(this.jsonContent).then(
        () => {
          this.copyLabel = 'Copied';
          setTimeout(() => {
            this.copyLabel = 'Copy';
            this.cdr.detectChanges();
          }, 5000);
        },
        (err) => {
          console.warn('Failed to copy:', err);
        }
      );
    }
  }