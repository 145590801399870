// records-group.component.ts
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { RecordsGroup, Record as RecordModel } from '../../../models/record-management.interface';
import { RecordPreviewComponent } from '../record-preview/record-preview.component';
import { TagPreviewComponent } from '../tag-preview/tag-preview.component';
import { HoverContainerDirective } from '../record-preview/hover-container.directive';

@Component({
  selector: 'app-records-group',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatChipsModule,
    RecordPreviewComponent,
    TagPreviewComponent,
    HoverContainerDirective
  ],
  templateUrl: './records-group.component.html',
  styleUrls: ['./records-group.component.scss']
})
export class RecordsGroupComponent implements OnInit {
  @Input() group!: RecordsGroup;
  @Output() onPinToggle = new EventEmitter<string>();
  @Output() onExpandToggle = new EventEmitter<string>();

  isExpanded = false;
  hoveredRecord: RecordModel | null = null;
  hoveredElement: HTMLElement | null = null;
  isPreviewHovered = false;
  mousePosition = { x: 0, y: 0 };

  ngOnInit() {
    // Initialize expansion state from group
    this.isExpanded = this.group.isExpanded || false;
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    // Update the group's expansion state
    this.group.isExpanded = this.isExpanded;
    // Emit for service to track state
    this.onExpandToggle.emit(this.group.id);
  }

  onHoverEvent(event: { isHovered: boolean; event?: MouseEvent }, record: RecordModel, element: HTMLElement) {
    if (event.event) {
      this.mousePosition = { x: event.event.clientX, y: event.event.clientY };
    }
    if (event.isHovered) {
      this.showPreview(record, element);
    } else if (!this.isPreviewHovered) {
      this.hidePreview();
    }
  }

  onPreviewHoverEvent(event: { isHovered: boolean }) {
    this.isPreviewHovered = event.isHovered;
    if (!event.isHovered && !this.hoveredElement?.matches(':hover')) {
      this.hidePreview();
    }
  }

  showPreview(record: RecordModel, element: HTMLElement) {
    this.hoveredRecord = record;
    this.hoveredElement = element;
  }

  hidePreview() {
    this.hoveredRecord = null;
    this.hoveredElement = null;
    this.isPreviewHovered = false;
  }
}