/**
 * @file src/app/services/tools/editWorkstream-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';
import { Workstream } from '../../models/workboard.model';
import { Session } from '../../models/session.model';

/**
 * Tool: editWorkstream
 * Edits a workstream by creating a new session with pre-populated fields
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerEditWorkstreamTool(service: ToolsService): void {
  (service as any).editWorkstream = async function(next: string, sessionId: string, riffMLNode: RiffMLNode | null, value: Workstream): Promise<void> {
    console.log(`ToolsService.editWorkstream() called with next=${next}`);
    
    if (!value) {
      console.error('No workstream provided to editWorkstream tool');
      return;
    }
    
    // session is already an edit session
    if (sessionId && sessionId.length && sessionId.indexOf('_edit_')>-1) {
      return;
    }

    const sessions = this.workspaceState.sessions$.value as Session[];
    const session = sessions.find(s => s.id && s.id.length && s.id.indexOf('_edit_')>-1 && (!value.version || s.name.indexOf(value.version) > -1) && s.relatedWorkstreamIds && s.relatedWorkstreamIds.length && s.relatedWorkstreamIds.find(id => id === value.id));
    if (session && session.id) {
      this.sessionsManagerService.updateSelectedSession(session.id);
      return;
    }

    // Generate a unique ID for the suggested session
    const uniqueId = Math.random().toString(36).substring(2) + Date.now().toString(36);
    
    // Create the suggested object with the specified mapping
    const suggested = {
      id: value.id + '_edit_' + uniqueId,
      name: 'Edit ' + value.name + ' version: ' + value.version,
      description: 'Make changes to workstream with description ' + value.description,
      goal: "I am here to help you edit the workstream, you can describe your change here or edit the mingus and I'll help you organize and document the change. How can I help?",
      whyRelevant: 'Make changes to workstream with description ' + value.description,
      detailedContext: 'Make changes to workstream with description ' + value.description,
      DTeamID: value.editAgentId ?? 'da@ask-y.ai',
      DTeamName: value.editAgentName ?? 'Data Analyst',
      sessionPlaybook: {
        id: value.editPlaybook ?? '45790'
      },
      relatedWorkstreamIds: [
        value.id
      ],
      orderTag: "edit"
    };

    value.editSession = suggested.id;
    
    // Call the sessions manager service to create or select the suggested session
    try {
      await this.sessionsManagerService.updateSelectedSessionFromSuggested(suggested, 1);
      console.log(`Created or selected edit session for workstream: ${value.id}`);
    } catch (error) {
      console.error('Error creating or selecting edit session:', error);
    }
  };
}