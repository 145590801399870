// src/app/home/home.component.ts
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from '../components/layout/header/header.component';
import { JamBarComponent } from '../components/layout/jam-bar/jam-bar.component';
import { TabContainerComponent } from '../components/tabs/tab-container/tab-container.component';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarAction } from './sidebar/sidebar.model';
import { SessionsService } from '../services/sessions.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    HeaderComponent,
    JamBarComponent,
    TabContainerComponent,
    SidebarComponent
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor(
    private authService: MsalService,
    private router: Router,
    private sessionsService: SessionsService
  ) {}

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: location.href.indexOf("localhost") > -1 ? 
        'http://localhost:4200' : 'https://clinic.aisel.co'
    });
  }

  onSidebarActionSelected(action: SidebarAction) {
    console.log('Selected action:', action);
  }

  onSidebarStateChange(isCompact: boolean) {
    console.log('Sidebar state changed:', isCompact);
    // Handle sidebar state change
  }
}