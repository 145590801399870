import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SidebarAction, SidebarConfig } from './sidebar.model';
import { Router } from '@angular/router';
import { SessionsService } from '../../services/sessions.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() config: SidebarConfig = {
    position: 'left',
    defaultWidth: 64,
    minWidth: 64,
    maxWidth: 400
  };

  @Input() actions: SidebarAction[] = [
    { 
      id: 'data-connections', 
      icon: 'cloud_sync', 
      label: 'Data Connections', 
      type: 'pinned',
      color: '#4CAF50'  // Green for data source
    },
    { 
      id: 'data-processing', 
      icon: 'tune', 
      label: 'Data Processing Records', 
      type: 'pinned',
      color: '#FF9800'  // Orange for processing
    },
    { 
      id: 'modeling', 
      icon: 'psychology', 
      label: 'Models', 
      type: 'pinned',
      color: '#2196F3'  // Blue for modeling
    },
    { 
      id: 'analytics', 
      icon: 'insights', 
      label: 'Analytics Records', 
      type: 'pinned',
      color: '#9C27B0'  // Purple for analytics
    },
    { 
      id: 'history', 
      icon: 'history', 
      label: 'Sessions History', 
      type: 'history',
      color: '#9e9e9e',
      route: '/home/sessions-history'
    },
    { 
      id: 'start', 
      icon: 'folder', 
      label: 'Workspace Records', 
      type: 'menu',
      color: '#4a90e2',
      route: '/home/start'
    },
    { 
      id: 'library', 
      icon: 'library_books', 
      label: 'Library', 
      type: 'bottom',
      color: '#ff6b6b'
    }
  ];

  @Output() actionSelected = new EventEmitter<SidebarAction>();
  @Output() stateChange = new EventEmitter<boolean>();

  isCompact = true;
  isMenuOpen = false;
  selectedActionId: string | null = null;

  constructor(private elementRef: ElementRef, private router: Router, private sessionsService: SessionsService) {}

  get historyActions(): SidebarAction[] {
    return this.actions.filter(action => action.type === 'history' || action.type === 'records');
  }

  get menuAction(): SidebarAction | undefined {
    return this.actions.find(action => action.type === 'menu');
  }

  get pinnedActions(): SidebarAction[] {
    return this.actions.filter(action => action.type === 'pinned');
  }

  get bottomActions(): SidebarAction[] {
    return this.actions.filter(action => action.type === 'bottom');
  }

  onActionClick(action: SidebarAction): void {
    if (action.type === 'menu') {
      this.isMenuOpen = !this.isMenuOpen;
      this.selectedActionId = this.isMenuOpen ? action.id : null;
      if (action.route) {
        this.sessionsService.openTab({
          id: 'start',
          title: 'Start',
          type: 'start'
        });
      }
    } else if (action.type === 'history') {
      this.selectedActionId = action.id;
      this.isMenuOpen = false;
      if (action.route) {
        this.sessionsService.openTab({
          id: 'sessions-history',
          title: 'Sessions History',
          type: 'sessions-history'
        });
      }
    } else {
      this.selectedActionId = action.id;
      this.isMenuOpen = false;
      if (action.route) {
        this.router.navigate([action.route]);
      }
    }
    this.actionSelected.emit(action);
  }

  toggleCompact(): void {
    this.isCompact = !this.isCompact;
    this.stateChange.emit(this.isCompact);
  }
}
