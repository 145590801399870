import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { TooltipService } from './tooltip.service';

export interface AgentRenderData {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: 'agent';
  element: any; // D3 selection reference
  data: any;
}

export interface DrawConfig {
  width: number;
  height: number;
  margin: number;
  agentRadius: number;
  agentMargin: number;
}

@Injectable({
  providedIn: 'root'
})
export class AgentsDrawService {
  private svg: any;
  private customTooltip: any; // Custom tooltip element
  private objectsMap: Record<string, AgentRenderData> = {};
  private defaultConfig: DrawConfig = {
    width: 960,
    height: 600,
    margin: 20,
    agentRadius: 16,
    agentMargin: 13
  };

  // Role-based color mapping
  private roleColorMap: {[key: string]: string} = {
    'Marketing Manager': '#ff7043',  // Orange
    'Data Analyst': '#42a5f5',      // Blue
    'Data Engineer': '#66bb6a',     // Green
    'default': '#9e9e9e'            // Gray
  };

  constructor(
    private tooltipService: TooltipService
  ) {
    // Create a dedicated custom tooltip for agents
    this.customTooltip = this.tooltipService.createTooltip('agent');
  }

  /**
   * Set the SVG element
   */
  public setSvg(svg: any): void {
    this.svg = svg;
  }

  /**
   * Clear the objects map
   */
  public clear(): void {
    this.objectsMap = {};
  }

  /**
   * Draw agents based on provided data
   */
  public drawAgents(agents: any[], yPosition: number, config: Partial<DrawConfig> = {}): void {
    // Clear existing content
    this.clear();

    // Merge provided config with defaults
    const drawConfig = { ...this.defaultConfig, ...config };

    if (!agents || agents.length === 0) {
      return;
    }

    // Create agents container
    const agentsContainer = this.svg.append('g')
      .attr('class', 'agents-container')
      .attr('transform', `translate(0,${yPosition})`);

    // Draw each agent
    agents.forEach((agent: any, index: number) => {
      this.drawAgent(agentsContainer, agent, index, drawConfig);
    });
  }

  /**
   * Draw a single agent
   */
  private drawAgent(container: any, agent: any, index: number, config: DrawConfig): void {
    const x = config.agentRadius + config.agentMargin;
    const y = (index * (config.agentRadius * 2 + config.agentMargin)) + config.agentRadius + config.agentMargin;
    
    // Create agent group
    const agentGroup = container.append('g')
      .attr('class', 'agent')
      .attr('transform', `translate(${x},${y})`)
      .on('mouseenter', (event: MouseEvent) => {
        // Show tooltip using the tooltip service
        this.tooltipService.showTooltip(
          this.customTooltip,
          event,
          this.generateAgentTooltip(agent)
        );
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.customTooltip, event);
      })
      .on('mouseleave', () => {
        // Hide tooltip using the tooltip service
        this.tooltipService.hideTooltip(this.customTooltip);
      });

    // Get agent initials
    const initials = this.getInitials(agent.name);

    // Get color based on role
    const color = this.roleColorMap[agent.role] || this.roleColorMap['default'];

    // Draw agent circle
    const agentCircle = agentGroup.append('circle')
      .attr('r', config.agentRadius)
      .attr('fill', color)
      .attr('stroke', '#fff')
      .attr('stroke-width', 2);

    // Add initials text
    agentGroup.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .attr('fill', '#fff')
      .attr('font-size', '10px')
      .attr('font-weight', 'bold')
      .text(initials);
      
    // Add name label to the right of the circle
    agentGroup.append('text')
      .attr('x', config.agentRadius + 10)
      .attr('y', 0)
      .attr('dy', '0.35em')
      .attr('fill', 'rgb(162, 160, 162)') // Text color from dark theme
      .attr('font-size', '12px')
      .text(agent.name);

    // Store agent reference
    const containerTransform = container.attr('transform');
    const containerY = containerTransform ? parseInt(containerTransform.split(',')[1], 10) : 0;

    this.objectsMap[agent.id.toLowerCase()] = {
      id: agent.id,
      x,
      y: y + containerY,
      width: config.agentRadius * 2,
      height: config.agentRadius * 2,
      type: 'agent',
      element: agentGroup,
      data: agent
    };
  }

  /**
   * Get initials from a name
   */
  private getInitials(name: string): string {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase();
  }

  /**
   * Generate agent tooltip content
   */
  private generateAgentTooltip(agent: any): string {
    // Get color for the agent based on role
    const color = this.roleColorMap[agent.role] || this.roleColorMap['default'];
    
    // Create header with agent name
    let content = this.tooltipService.formatHeader(agent.name, 'agent');
    
    // Profile section
    let profileContent = '';
    
    // Add role information
    if (agent.role) {
      profileContent += this.tooltipService.formatProperty('Role', agent.role);
    }
    
    // Add email information
    if (agent.email) {
      profileContent += this.tooltipService.formatProperty('Email', agent.email);
    }
    
    // Add department information
    if (agent.department) {
      profileContent += this.tooltipService.formatProperty('Department', agent.department);
    }
    
    // Add title information
    if (agent.title) {
      profileContent += this.tooltipService.formatProperty('Title', agent.title);
    }
    
    // Add profile section if we have any profile content
    if (profileContent) {
      content += this.tooltipService.formatSection(profileContent, 'Profile');
    }
    
    // Skills section
    if (agent.skills && agent.skills.length) {
      let skillsContent = '';
      
      // Format skills as badges
      const skillsHtml = agent.skills.map((skill: string) => 
        `<span style="display: inline-block; background-color: #2a2b2d; border-radius: 0px; padding: 2px 6px; margin-right: 5px; margin-bottom: 5px; font-size: 12px;">${skill}</span>`
      ).join('');
      
      skillsContent += `<div style="padding-left: 2px;">${skillsHtml}</div>`;
      
      // Add skills section
      content += this.tooltipService.formatSection(skillsContent, 'Skills');
    } else if (agent.role === 'Data Analyst') {
      // Default skills for Data Analyst
      let skillsContent = '';
      
      // Format default skills as badges
      const defaultSkills = ['SQL', 'Python', 'Data Visualization'];
      const skillsHtml = defaultSkills.map((skill: string) => 
        `<span style="display: inline-block; background-color: #2a2b2d; border-radius: 0px; padding: 2px 6px; margin-right: 5px; margin-bottom: 5px; font-size: 12px;">${skill}</span>`
      ).join('');
      
      skillsContent += `<div style="padding-left: 2px;">${skillsHtml}</div>`;
      
      // Add skills section
      content += this.tooltipService.formatSection(skillsContent, 'Skills');
    }
    
    // Projects section
    if (agent.projects && agent.projects.length) {
      let projectsContent = '';
      
      // Format projects as a list
      projectsContent += `<ul style="margin: 0; padding-left: 20px;">
        ${agent.projects.map((project: string) => `<li>${project}</li>`).join('')}
      </ul>`;
      
      // Add projects section
      content += this.tooltipService.formatSection(projectsContent, 'Projects');
    }
    
    // Activity section
    let activityContent = '';
    
    // Add last active information
    activityContent += this.tooltipService.formatProperty('Last active', agent.lastActive || 'Today, 10:15 AM');
    
    // Add activity information
    activityContent += `<div style="background-color: #2a2b2d; padding: 8px; border-radius: 0px; font-size: 12px; max-height: 80px; overflow-y: auto;">
      ${agent.activity || 'Updated GA4 implementation documentation'}
    </div>`;
    
    // Add activity section
    content += this.tooltipService.formatSection(activityContent, 'Recent Activity');
    
    // Add footer with ID if available
    if (agent.id) {
      content += this.tooltipService.formatFooter(`ID: ${agent.id}`);
    }
    
    return content;
  }

  /**
   * Get all rendered objects
   */
  public getAllObjects(): Record<string, AgentRenderData> {
    return this.objectsMap;
  }

  /**
   * Highlight an agent
   */
  public highlightObject(id: string, options: { color?: string; duration?: number } = {}): void {
    const obj = this.objectsMap[id.toLowerCase()];
    if (!obj) {
      console.warn(`Agent with ID ${id} not found`);
      return;
    }

    const color = options.color || '#ff5722';
    const duration = options.duration || 2000;

    // Highlight the agent
    obj.element.select('circle')
      .transition()
      .duration(200)
      .attr('stroke', color)
      .attr('stroke-width', 3)
      .transition()
      .delay(duration)
      .duration(200)
      .attr('stroke', '#fff')
      .attr('stroke-width', 2);
  }
}