/* src/app/services/sessions-manager.service.ts */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Session,
  SessionsGridState,
  SessionUpdate,
  ViewStatus
} from '../models/session.model';
import { ArtifactsService } from './artifacts.service';
import { UserService } from './user.service';
import { WorkspaceState } from '../models/workspace-state.model';
import { ProjectData } from '../models/project.interface';
import { ChatService } from './chat.service';

/**
 * Service for handling the sessions in the workspace, including creation,
 * retrieval, and updates of session metadata, as well as selecting
 * which session is currently active in the UI.
 */
@Injectable({
  providedIn: 'root'
})
export class SessionsManagerService {
  constructor(
    private artifactsService: ArtifactsService,
    private userService: UserService,
    private sharedState: WorkspaceState
  ) {}

  private generateId(): string {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  /**
 * Find the maximum numeric index among sessions that share a common prefix label.
 * If no sessions with numbers are found, returns 0.
 * @param sessions - Array of all sessions
 * @param prefix - The label prefix to search for
 * @returns The maximum numeric index found after the prefix
 */
  private findMaxSessionIndex(sessions: Session[], prefix: string): number {
    const regex = new RegExp(`^${prefix}(\\d+)`);
    
    return sessions.reduce((maxIndex, session) => {
      const match = session.session.match(regex);
      if (match) {
        const currentIndex = parseInt(match[1], 10);
        return Math.max(maxIndex, currentIndex);
      }
      return maxIndex;
    }, 0);
  }

  private getCurrentSessionLabel(): string {
    const sessions = this.sharedState.sessions$.value;
    const existingSession = sessions.find(s => s.id === this.sharedState.sessionsGridState$?.value.selectedSessionId);
    return existingSession?.session || this.generateNextSessionId();
  }

  private generateNextSessionId(): string {
    const sessions = this.sharedState.sessions$.value;
    const existingIds = new Set(sessions.map(a => a.session));

    const generateId = (length: number, current: string = ''): string | null => {
      if (current.length === length) {
        return !existingIds.has(current) ? current : null;
      }

      for (let i = 65; i <= 90; i++) {
        const next = current + String.fromCharCode(i);
        const result = generateId(length, next);
        if (result) return result;
      }
      return null;
    };

    let nextId = generateId(1) || generateId(2);
    if (!nextId) {
      throw new Error('No available session IDs');
    }

    return nextId;
  }

  /**
   * Obtain all sessions as an observable.
   */
  getSessions(): Observable<Session[]> {
    return this.sharedState.sessions$.asObservable();
  }

    /**
   * Get a session by its ID.
   * @param id - The ID of the session to retrieve
   * @returns The session with the given ID, or undefined if not found
   */
  getSessionById(id: string): Session | undefined {
    const sessions = this.sharedState.sessions$.value;
    return sessions.find(session => session.id === id);
  }

    /**
 * Get a session by its ID prefix and created in session.
 * @param idprefix - The ID prefix of the session to retrieve
 * @returns The session with the given ID, or undefined if not found
 */
    getSessionByIdPrefix(idprefix: string, createdIn:string): Session | undefined {
      const sessions = this.sharedState.sessions$.value;
      return sessions.find(session => session.id.startsWith(idprefix) && session.createdInSession === createdIn);
    }

  /**
   * Returns a formatted string of the last N sessions ordered by lastEdited date
   * for use in the prompt template.
   */
  async GetLastSessionsForPrompt(count: number = 5): Promise<string> {
    const sessions = this.sharedState.sessions$.value
      .sort((a, b) => new Date(b.lastEdited).getTime() - new Date(a.lastEdited).getTime())
      .slice(0, count);

    if (sessions.length === 0) {
      return '[]';
    }

    const sessionStrings = sessions.map((session, index) => {
      const lines = [
        `session ${index + 1}`,
        `  id ${session.id}`,
        `  created ${session.createdBy} ${new Date(session.createdDate).toISOString()}`,
        `  lastEdited ${new Date(session.lastEdited).toISOString()}`,
        `  sessionLabel ${session.session}`,
        `  name: ${session.name}`,
        `  playbook=${session.playbook}`
      ];

      if (session.description) {
        lines.push(`  description=${session.description}`);
      }

      return lines.join('\n');
    });

    return sessionStrings.join('\n\n');
  }

  /**
   * Update a single field in a session and persist the result.
   */
  async updateSession(update: SessionUpdate): Promise<void> {
    const sessions = this.sharedState.sessions$.value;
    const sessionIndex = sessions.findIndex(a => a.id === update.id);

    if (sessionIndex !== -1) {
      sessions[sessionIndex] = {
        ...sessions[sessionIndex],
        [update.field]: update.value,
        lastEdited: new Date()
      };

      this.sharedState.sessions$.next([...sessions]);

      const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject;
      if (projectId) {
        const artifactId = 'project_' + projectId;
        const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
        if (projectData) {
          projectData.sessions = sessions;
          await this.artifactsService.saveArtifact(artifactId, projectData);
          this.sharedState.setProject(projectId, projectData);
        }
      }
    }
  }

  /**
   * Update session metadata (name/description), then store the updated data.
   */
  async updateProjectMetaData(sessionId: string, newName: string, newDescription: string): Promise<void> {
    const sessions = this.sharedState.sessions$.value;
    const sessionIndex = sessions.findIndex(s => s.id === sessionId);
    if (sessionIndex === -1) {
      return; 
    }

    const session = sessions[sessionIndex];
    let updated = false;

    if (session.name !== newName && newName && newName.length) {
      session.name = newName;
      updated = true;
    }

    if ((session.description || '') !== newDescription && newDescription && newDescription.length) {
      session.description = newDescription;
      updated = true;
    }

    if (updated) {
      session.lastEdited = new Date();
      sessions[sessionIndex] = session;
      this.sharedState.sessions$.next([...sessions]);

      const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject;
      if (projectId) {
        const artifactId = 'project_' + projectId;
        const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
        if (projectData && projectData.sessions) {
          const pSessionIndex = projectData.sessions.findIndex(ps => ps.id === sessionId);
          if (pSessionIndex !== -1) {
            projectData.sessions[pSessionIndex].name = session.name;
            projectData.sessions[pSessionIndex].description = session.description;
            projectData.sessions[pSessionIndex].lastEdited = new Date();
          }
          await this.artifactsService.saveArtifact(artifactId, projectData);
          this.sharedState.setProject(projectId, projectData);
        }
      }
    }
  }

  /**
   * Get the sessions grid state as an observable.
   */
  getGridState(): Observable<SessionsGridState> {
    return this.sharedState.sessionsGridState$.asObservable();
  }

  /**
   * Persist updates to the sessions grid state (selected session, layout, etc.).
   */
  async updateGridState(sessionsGridState: SessionsGridState): Promise<void> {
    this.sharedState.sessionsGridState$.next({
      ...this.sharedState.sessionsGridState$.value,
      ...sessionsGridState
    });

    const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject;
    if (projectId) {
      const artifactId = 'project_' + projectId;
      const userProjectView = await this.artifactsService.loadArtifact(artifactId, true);
      if (userProjectView) {
        userProjectView.sessionState = this.sharedState.sessionsGridState$.value;
        await this.artifactsService.saveArtifact(artifactId, userProjectView, true);
      }
    }
  }

/**
 * Select a session by its ID and update its completion status if needed.
 */
async updateSelectedSession(sessionId: string): Promise<void> {
  const sessions = this.sharedState.sessions$.value;
  const selectedSession = sessions.find(session => session.id === sessionId);

  if (selectedSession && selectedSession.completionStatus === 'new') {
    selectedSession.completionStatus = 'opened';
    this.sharedState.sessions$.next(sessions);

    // Save updated sessions to project artifact
    const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject;
    if (projectId) {
      const artifactId = 'project_' + projectId;
      const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
      if (projectData) {
        projectData.sessions = sessions;
        await this.artifactsService.saveArtifact(artifactId, projectData);
        this.sharedState.setProject(projectId, projectData);
      }
    }
  }

  await this.updateGridState({
    ...this.sharedState.sessionsGridState$.value,
    selectedSessionId: sessionId
  });
}

  /**
   * Create a generic new session with minimal data by default.
   */
  async createSession(session: Partial<Session>): Promise<string> {
    const user = await this.userService.getCurrentUser();
    const projectJammer = {
      email: 'project.jammer@ask-y.ai',
      name: 'Project Manager'
    };

    const sessionId = this.generateNextSessionId();

    const newSession: Session = {
      id: this.generateId(),
      createdBy: user?.email || '',
      createdDate: new Date(),
      lastEdited: new Date(),
      session: sessionId,
      name: 'Ask a PM',
      viewStatus: 'visible' as ViewStatus,
      completionStatus: 'opened',
      participants: [projectJammer, ...(user ? [user] : [])],
      sessionType: 'session',
      playbook: session.playbook || ChatService.GetDefaultPMPlaybook(),
      description: 'New session created by Project Jammer',
      ...session
    };

    const sessions = [...this.sharedState.sessions$.value, newSession];
    this.sharedState.sessions$.next(sessions);

    const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject;
    if (projectId) {
      const artifactId = 'project_' + projectId;
      const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
      if (projectData) {
        projectData.sessions = sessions;
        await this.artifactsService.saveArtifact(artifactId, projectData);
        this.sharedState.setProject(projectId, projectData);
      }
    }

    
    return newSession.id;
  }

  /**
   * Create a new session based on a suggested session object,
   * carrying over details such as name, description, relevant IDs, etc.
   * The session label is generated using the current label prefix and 
   * incrementing based on existing session indices.
   * 
   * @param suggested - The suggested session data to base the new session on
   * @param index - Additional index to append to the session label
   * @returns Promise resolving to the new session's ID
   */
  async createSuggestedSession(suggested: any, index: number): Promise<string> {
    const user = await this.userService.getCurrentUser();
    const projectJammer = {
      email: (suggested.DTeamID || 'default') + '@ask-y.ai',
      name: suggested.DTeamName || 'DA'
    };

    // Get current label prefix and find max existing index
    const currentLabelPrefix = this.getCurrentSessionLabel();
    const maxIndex = this.findMaxSessionIndex(this.sharedState.sessions$.value, currentLabelPrefix);
    
    // Generate new session label with both indices
    const nextSessionId = `${currentLabelPrefix}${maxIndex+1}`;

    // Build the new session with suggested data
    const newSession: Session = {
      id: suggested.id + '_' + this.generateId(),
      createdBy: user?.email || '',
      createdInSession: this.sharedState.sessionsGridState$?.value.selectedSessionId,
      createdDate: new Date(),
      lastEdited: new Date(),
      session: nextSessionId,
      name: suggested.name,
      viewStatus: 'visible',
      completionStatus: 'new',
      participants: [projectJammer, ...(user ? [user] : [])],
      sessionType: 'session',
      // Example shows playbook from suggested.sessionPlaybook.id if provided:
      playbook: suggested.sessionPlaybook?.id || '43154',
      description: suggested.description || '',
      relatedWorkstreamIds: suggested.relatedWorkstreamIds || [],
      goal: suggested.goal || '',
      whyRelevant: suggested.whyRelevant || '',
      detailedContext: suggested.detailedContext || '',
      DTeamID: suggested.DTeamID || '',
      DTeamName: suggested.DTeamName || ''
    };

    // If you wish to store additional suggested fields within the session object
    // (which are not in the Session interface), you can assign them dynamically:
    (newSession as any).orderTag = suggested.orderTag;
    (newSession as any).whyRelevant = suggested.whyRelevant;
    (newSession as any).relatedWorkstreamIds = suggested.relatedWorkstreamIds;
    if (suggested.changeStatus) {
      (newSession as any).changeStatus = suggested.changeStatus;
    }

    const sessions = [...this.sharedState.sessions$.value, newSession];
    this.sharedState.sessions$.next(sessions);

    const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject;
    if (projectId) {
      const artifactId = 'project_' + projectId;
      const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
      if (projectData) {
        projectData.sessions = sessions;
        await this.artifactsService.saveArtifact(artifactId, projectData);
        this.sharedState.setProject(projectId, projectData);
      }
    }

    return newSession.id;
  }

  /**
   * Check if a session with the suggested ID (plus appended sessionId) already exists.
   * If not, create it from the suggested data. Then select it.
   */
  async updateSelectedSessionFromSuggested(suggested: any, index: number): Promise<void> {
    const sessions = this.sharedState.sessions$.value;
    // A simple approach looks for an existing session whose ID starts with the suggested ID
    // (since we append a generated code at creation).
    const existingSession = sessions.find(s => s.id.startsWith(suggested.id));
    let finalId: string;

    if (existingSession) {
      finalId = existingSession.id;
    } else {
      finalId = await this.createSuggestedSession(suggested, index);
    }

    await this.updateSelectedSession(finalId);
  }

  /**
   * Remove a session by ID.
   */
  async deleteSession(id: string): Promise<void> {
    const sessions = this.sharedState.sessions$.value.filter(a => a.id !== id);
    this.sharedState.sessions$.next(sessions);

    const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject;
    if (projectId) {
      const artifactId = 'project_' + projectId;
      const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
      if (projectData) {
        projectData.sessions = sessions;
        await this.artifactsService.saveArtifact(artifactId, projectData);
        this.sharedState.setProject(projectId, projectData);

      }
    }
  }
}
