<!-- Activity Log Button -->
<button mat-icon-button class="activity-log-button" (click)="togglePane()">
  <mat-icon>history</mat-icon>
</button>

<!-- Activity Log Pane -->
<div class="activity-log-pane-overlay" *ngIf="showPane">
  <div class="activity-log-pane">
    <div class="activity-log-pane-header">
      <h2>Activity Log</h2>
      <div class="header-actions">
        <button mat-icon-button class="close-button" (click)="closePane()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="activity-log-pane-content">
      <div class="activity-list">
        <div class="activity-item" *ngFor="let activity of activities">
          <div class="activity-icon">
            <div [class]="activity.icon"></div>
          </div>
          <div class="activity-content">
            <span class="activity-text"><strong>{{ activity.user }}</strong> {{ activity.text }}</span>
            <span class="activity-time">{{ activity.time }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 