import { Component, Input, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'app-tab',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule
  ],
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent {
  @Input() label: string = '';
  @Input() icon?: string;
  @Input() color: string = '#5270ff';
  @Input() selected: boolean = false;
  @Input() compact: boolean = false;

  @HostBinding('style.--tab-color')
  get tabColor(): string {
    return this.color;
  }

  @HostBinding('class.compact')
  get isCompact() {
    return this.compact;
  }
}
