// src/app/components/context-viewer/context-viewer.component.ts

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { StateItem } from '../../../models/workspace-state.model';

@Component({
  selector: 'app-context-viewer',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatSnackBarModule
  ],
  templateUrl: './context-viewer.component.html',
  styleUrls: ['./context-viewer.component.scss']
})
export class ContextViewerComponent {
  @Input() stateItems: StateItem[] = [];
  @Output() itemSelected = new EventEmitter<StateItem>();

  selectedItem: StateItem | null = null;

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ) {}

  getItemIcon(type: string): string {
    switch (type.toLowerCase()) {
      case 'string': return 'text_format';
      case 'datetime': return 'event';
      case 'number': return 'pin';
      case 'boolean': return 'check_circle';
      default: return 'info_outline';
    }
  }

  getShortValue(data: any): string {
    if (data == null) return '—';
    const str = typeof data === 'object' ? JSON.stringify(data) : String(data);
    return str.length > 20 ? str.substring(0, 20) + '...' : str;
  }

  getFullValue(data: any): string {
    if (data == null) return '';
    if (typeof data === 'object') {
      return JSON.stringify(data, null, 2);
    }
    return String(data);
  }

  selectItem(item: StateItem) {
    this.selectedItem = item;
    this.itemSelected.emit(item);
  }

  copyDataToClipboard() {
    if (this.selectedItem && this.selectedItem.data) {
      const textToCopy = typeof this.selectedItem.data === 'object' 
        ? JSON.stringify(this.selectedItem.data, null, 2)
        : String(this.selectedItem.data);
      
      const successful = this.clipboard.copy(textToCopy);
      
      if (successful) {
        this.snackBar.open('Data copied to clipboard', 'Close', {
          duration: 2000,
        });
      }
    }
  }
}
