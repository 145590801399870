import { Component } from '@angular/core';

@Component({
  selector: 'app-admin',
  standalone: true,
  template: `
    <div class="admin">
      <h2>Admin</h2>
      <p>System settings and configuration</p>
    </div>
  `,
  styles: [`
    .admin {
      padding: 2rem;
    }
  `]
})
export class AdminComponent {} 