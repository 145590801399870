<!-- src/app/components/session/message/select-list/select-list.component.html -->

<div class="select-list-container">
  <div
    class="list-item"
    *ngFor="let it of items"
    [matTooltip]="it.whyRelevant"
    matTooltipPosition="above"
    (click)="onItemSelected(it)"
  >
    <div class="list-item-name">{{ it.Name }}</div>
    <div class="list-item-id">{{ it.Id }}</div>
  </div>
</div>
