/**
 * @file src/app/services/tools/accept-suggested-workspace-component-tool.ts
 */
import { Workstream } from '../../models/workboard.model';
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';
import { firstValueFrom } from 'rxjs';

/**
 * Tool: acceptWorkboardChanges
 * Accepts all workboard changes
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerAcceptWorkboardChangesTool(service: ToolsService): void {
  (service as any).acceptWorkboardChanges = async function(next: string, sessionId: string, riffMLNode: RiffMLNode | null, value:any): Promise<void> {
    console.log(`ToolsService.acceptWorkboardChanges() called with next=${next}`);
    
    // Get the current session ID
    if (!sessionId) {
      console.error('No active session found');
      return;
    }

    // Get the current workboard state
    const workboardState = this.workspaceState.workboardState$.value;
    
    // Create a deep copy of the current workboard state to avoid direct mutation
    const updatedWorkboardState = JSON.parse(JSON.stringify(workboardState));
    let hasChanges = false;
    
    // Iterate through all workstreams in the workboard
    for (const group of updatedWorkboardState.groups) {
      for (const col of group.workstreamCols) {
        for (const workstream of col.workstreams) {
          // Check if the workstream has version changes
          if (hasWorkstreamVersionChanges(workstream)) {
            console.log(`Accepting changes for workstream: ${workstream.id}`);
            hasChanges = true;
            
            await this.processOutputsDataAndMeta(workstream, sessionId, riffMLNode);

            // Create a new committed version by adding a copy of the current workstream to lastCommitedVersions
            if (!workstream.lastCommitedVersions) {
              workstream.lastCommitedVersions = [];
            }
            
            // Create a deep copy of the current workstream excluding lastCommitedVersions
            const workstreamCopy = { ...workstream };
            delete workstreamCopy.lastCommitedVersions;
           workstreamCopy.inEditMode = false;
            
            // Add the copy as a new committed version
            workstream.lastCommitedVersions.push({
              ...workstreamCopy,
              commitTimestamp: new Date().toISOString()
            });
          }
        }
      }
    }
    
    // Update the workboardState$ BehaviorSubject if changes were made
    if (hasChanges) {
      console.log('Updating workboardState$ with accepted changes');
      this.workspaceState.workboardState$.next(updatedWorkboardState);
    }
    
    // Remove workstream changes from state
    await this.workboardManagerService.persistCurrentProject();
    // Remove workstream changes from state
    await this.stateManagerService.deleteState(sessionId, "session.workstreamchanges");
    
    console.log(`Workboard changes accepted successfully for session: ${sessionId}`);
  };

    /**
   * Helper function to process outputs data and meta
   * 
   * @param workstream The workstream to process
   */
    (service as any).processOutputsDataAndMeta = async function (workstream: Workstream, sessionId: string, riffMLNode: RiffMLNode | null): Promise<boolean> {
    
    // Process each unit in the workstream first
    if (workstream.units && workstream.units.length > 0) {
      for (const unit of workstream.units) {

        // Check if the unit has output tables
        if (unit.output_table_ids && unit.output_table_ids.length > 0) {
          // Process each output table that has export=true
          for (const tid of unit.output_table_ids) {
            // Only process if:
            // 1. The table has export=true
            // 2. The unit has code
            // 3. The code starts with CREATE OR REPLACE PROCEDURE
            // 4. The unit has a non-empty runCall property
            if (tid.export && 
                unit.code && 
                unit.code.trim().toUpperCase().startsWith('CREATE OR REPLACE PROCEDURE') &&
                unit.runCall && 
                unit.runCall.trim() !== '') {
              
              // Run the unit's code using snowflakeService
              // Convert observable to promise for proper async/await handling
              await firstValueFrom(
                this.snowflakeService.executeCommandObservable(unit.code)
              );

              const response = await Promise.race([
                firstValueFrom(this.snowflakeService.executeQueryObservable(unit.runCall)),
                new Promise((resolve) => setTimeout(() => resolve({ Success: true, Data: null }), 8000))
              ]);

              break; // one time for each unit
            }
          }
        }
      }
    }

    // TODO: replace workstream.code in here
    // Then process the workstream's output tables
    let hasChanges = false;
    if (workstream.output_table_ids && workstream.output_table_ids.length > 0) {
      hasChanges = await this.processOutputTables(workstream, sessionId, 8000);
    }

    if(hasChanges && riffMLNode && riffMLNode.attributes['afterAddTables'])
    {
        const processTurnHandler = this.getProcessTurnHandler();
        if (processTurnHandler) {
          await processTurnHandler.runPlaybook(sessionId, riffMLNode.attributes['afterAddTables'], false);
          // Run a second iteration without limits after the playbook
        } else {
          console.warn('No ProcessTurnHandler registered to handle next playbook:', riffMLNode.attributes['afterAddTables']);
        }
    }

    if (hasChanges) {
      await this.processOutputTables(workstream, sessionId);
    }
    
    // If there are no committed versions yet, consider it as having changes
    return true;
  };

  /**
   * Helper function to process output tables
   * @param workstream The workstream to process
   * @param sessionId The current session ID
   * @param rowLimit Optional limit for the number of rows to process
   * @returns boolean indicating whether changes were made
   */
  (service as any).processOutputTables = async function(workstream: Workstream, sessionId: string, rowLimit?: number): Promise<boolean> {
    let hasChanges = false;
    
    if (!workstream.output_table_ids) {
      return false;
    }
    
    for (const outputTableId of workstream.output_table_ids) {
      for (const tid of workstream.output_table_ids) {
        if (!tid.export) {
          continue;
        }

        // Check if this is SQL type and either has workstream code directly or has units with SQL code
        if (workstream.type?.toLowerCase() == 'sql' && (workstream.code || (workstream.units && workstream.units.length > 0))) {
          hasChanges = true;

          // Generate SQL with WITH statements for all relevant units leading up to this table
          const sqlWithTables = this.workboardManagerService.getWorkstreamSqlWithTables(workstream, tid.name, rowLimit);
          
          // Use the SQL from getWorkstreamSqlWithTables if available, otherwise fall back to workstream.code
          const sqlToUse = sqlWithTables || workstream.code || '';
          
          // Only proceed if we have SQL to use
          if (sqlToUse.trim() !== '') {
            let runCode = `CREATE OR REPLACE TABLE ${tid.name} AS \n ${sqlToUse} \n SELECT * FROM ${tid.name}`;
            let runPreviewCode = `${sqlToUse} \n SELECT TOP 10 * FROM ${tid.name}`;

            await Promise.race([
              this.runSqlCommand(runCode),
              new Promise((resolve) => setTimeout(() => resolve({ Success: true, Data: null }), 8000))
            ]);

            await this.runSql(null, sessionId, {attributes: {addToState: 'CreatedTables.' + tid.name.replaceAll('.', '$')}}, runPreviewCode);

            if (rowLimit && rowLimit>0)
            {
              await this.addToState(sessionId, 'CreatedTablesSql.' + tid.name.replaceAll('.', '$'), runCode);
            }
          }
        }
      }
    }
    
    return hasChanges;
  };
}

/**
 * Helper function to check if a workstream has version changes
 * 
 * @param workstream The workstream to check for version changes
 * @returns boolean indicating whether there are version changes
 */
function hasWorkstreamVersionChanges(workstream: Workstream): boolean {
  // Check if there are committed versions and if the current version differs from the last committed version
  if (workstream.lastCommitedVersions && workstream.lastCommitedVersions.length > 0) {
    const lastCommittedVersion = workstream.lastCommitedVersions[workstream.lastCommitedVersions.length - 1];
    return lastCommittedVersion.version !== workstream.version;
  }
  
  // If there are no committed versions yet, consider it as having changes
  return true;
}

