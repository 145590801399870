export interface Artifact {
  id: string;
  title: string;
  type: string;
  preview?: string;
  content: string;
  action: ArtifactAction;
  createdAt: Date;
  updatedAt?: Date;
  language?: string;
}

export enum ArtifactAction {
  Sent = 'sent',
  Created = 'created',
  Edited = 'edited'
}