import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

export interface TranscriptionResponse {
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class TranscriptionService {
  private readonly transcriptionApiUrl = `${environment.artifactsApiUrl}/whisper/transcribe`;

  constructor(private http: HttpClient) {}

  /**
   * Sends an audio file to the transcription service
   * @param audioBlob The audio file blob to transcribe
   * @param language The language code (defaults to 'en')
   * @returns Promise containing the transcription response
   */
  async transcribeAudio(
    audioBlob: Blob,
    language: string = 'en'
  ): Promise<TranscriptionResponse> {
    try {
      const formData = new FormData();
      formData.append('file', audioBlob, 'recording.webm');
      formData.append('language', language);

      const response = await firstValueFrom(
        this.http.post<TranscriptionResponse>(this.transcriptionApiUrl, formData)
      );
      
      return response;
    } catch (error) {
      console.error('[TranscriptionService] Error transcribing audio:', error);
      throw error;
    }
  }

  /**
   * Sends an audio file to the transcription service as an Observable
   * @param audioBlob The audio file blob to transcribe
   * @param language The language code (defaults to 'en')
   * @returns Observable of the transcription response
   */
  transcribeAudioObservable(
    audioBlob: Blob,
    language: string = 'en'
  ): Observable<TranscriptionResponse> {
    const formData = new FormData();
    formData.append('file', audioBlob, 'recording.webm');
    formData.append('language', language);

    return this.http.post<TranscriptionResponse>(this.transcriptionApiUrl, formData);
  }
} 