// src/app/components/records/pipelines-draw.service.ts
import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { MemoryItem } from '../../models/memory-item.model';
import { TooltipService } from './tooltip.service';

export interface PipelineRenderData {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: 'pipeline';
  element: any; // D3 selection reference
  data: any;
}

export interface DrawConfig {
  width: number;
  height: number;
  margin: number;
  pipelineWidth: number;
  pipelineHeight: number;
  pipelinePadding: number;
  pipelineHeaderHeight: number;
  pipelineBorderRadius: number;
  stageSize: number;
  stageMargin: number;
  stagesPerRow: number;
  pipelineMemories?: {[pipelineId: string]: MemoryItem[]};
  memorySize?: number;
  memoryMargin?: number;
}

@Injectable({
  providedIn: 'root'
})
export class PipelinesDrawService {
  private svg: any;
  private tooltip: any;
  private pipelineTooltip: any; // Dedicated tooltip for pipelines
  private memoryTooltip: any; // Dedicated tooltip for memory
  private customTooltip: any; // Custom tooltip element
  private objectsMap: Record<string, PipelineRenderData> = {};

  // Status colors
  private statusColorMap: {[key: string]: string} = {
    'active': '#66bb6a',      // Green for active pipelines
    'paused': '#ffb74d',      // Orange for paused pipelines
    'failed': '#ef5350',      // Red for failed pipelines
    'completed': '#42a5f5',   // Blue for completed pipelines
    'default': '#90a4ae'      // Gray for other statuses
  };

  // Pipeline type colors
  private pipelineTypeColorMap: {[key: string]: string} = {
    'data_pipeline': '#5c6bc0',      // Indigo for data pipelines
    'etl_pipeline': '#26a69a',       // Teal for ETL pipelines
    'ml_pipeline': '#66bb6a',        // Green for ML pipelines
    'analytics_pipeline': '#42a5f5', // Blue for analytics pipelines
    'default': '#607d8b'             // Blue-gray for other types
  };

  // Service type colors and icons
  private serviceTypeColorMap: {[key: string]: {color: string, icon: string}} = {
    'BigQuery': { color: '#1A73E8', icon: '📊' },      // Vibrant Google blue for BigQuery
    'Snowflake': { color: '#29B5E8', icon: '❄️' },     // Snowflake blue
    'Redshift': { color: '#8C4FFF', icon: '🔴' },      // Amazon purple for Redshift
    'AzureSQL': { color: '#0078D4', icon: '☁️' },      // Azure blue
    'PostgreSQL': { color: '#336791', icon: '🐘' },    // PostgreSQL blue
    'MySQL': { color: '#00758F', icon: '🐬' },         // MySQL blue
    'MongoDB': { color: '#4DB33D', icon: '🍃' },       // MongoDB green
    'Databricks': { color: '#FF3621', icon: '⚡' },    // Databricks red
    'S3': { color: '#FF9900', icon: '📦' },           // AWS orange for S3
    'GCS': { color: '#FBBC05', icon: '☁️' },          // Google yellow for GCS
    'ADLS': { color: '#0078D4', icon: '💾' },         // Azure blue for ADLS
    'default': { color: '#90A4AE', icon: '🔌' }       // Default gray
  };

  private defaultConfig: DrawConfig = {
    width: 960,
    height: 600,
    margin: 20,
    pipelineWidth: 220,
    pipelineHeight: 100,
    pipelinePadding: 15,
    pipelineHeaderHeight: 25,
    pipelineBorderRadius: 10,
    stageSize: 4,
    stageMargin: 4,
    stagesPerRow: 5
  };

  constructor(
    private tooltipService: TooltipService
  ) {
    // Create a dedicated custom tooltip for pipelines
    this.pipelineTooltip = this.tooltipService.createTooltip('pipeline');
    
    // Create a dedicated custom tooltip for memories
    this.memoryTooltip = this.tooltipService.createTooltip('memory');
  }

  /**
   * Set the SVG element
   */
  public setSvg(svg: any): void {
    this.svg = svg;
  }

  /**
   * Create a custom tooltip element
   */
  private createCustomTooltip(): void {
    // Use the tooltip service to create a tooltip
    this.customTooltip = this.tooltipService.createTooltip('pipeline');
  }

  /**
   * Clear the objects map
   */
  public clear(): void {
    this.objectsMap = {};
  }

  /**
   * Draw pipelines based on provided data
   */
  public drawPipelines(pipelinesList: any[], config: Partial<DrawConfig> = {}): void {
    // Clear existing content
    this.clear();

    // Ensure pipelineMemories is a valid object
    config.pipelineMemories = config.pipelineMemories || {};

    // Merge provided config with defaults
    const drawConfig = { ...this.defaultConfig, ...config };

    if (!pipelinesList || pipelinesList.length === 0) {
      return;
    }

    // Calculate vertical spacing
    const verticalMargin = 20;
    let currentY = 0;

    // Draw each pipeline
    pipelinesList.forEach((pipeline, index) => {
      const yPosition = currentY;
      const height = this.drawPipeline(pipeline, index, verticalMargin, drawConfig, yPosition);
      currentY += height + verticalMargin;
    });
  }

  /**
   * Draw a single pipeline
   */
  private drawPipeline(
    pipeline: any, 
    index: number, 
    verticalMargin: number, 
    config: DrawConfig, 
    yPosition: number = 0
  ): number {
    // Create pipeline group
    const pipelineGroup = this.svg.append('g')
      .attr('class', 'pipeline')
      .attr('transform', `translate(0, ${yPosition})`);
    
    // Determine pipeline color based on type
    const pipelineType = pipeline.type || 'default';
    const pipelineColor = this.pipelineTypeColorMap[pipelineType] || this.pipelineTypeColorMap['default'];
    
    // Create gradient for pipeline background
    const gradientId = `pipeline-gradient-${pipeline.id || index}`;
    const defs = this.svg.append('defs');
    const gradient = defs.append('linearGradient')
      .attr('id', gradientId)
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '100%');
    
    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', pipelineColor)
      .attr('stop-opacity', 0.1);
      
    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', this.darkenColor(pipelineColor, 0.1))
      .attr('stop-opacity', 0.3);

    // Draw pipeline rectangle
    const pipelineRect = pipelineGroup.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', config.pipelineWidth)
      .attr('height', config.pipelineHeight)
      .attr('rx', config.pipelineBorderRadius)
      .attr('ry', config.pipelineBorderRadius)
      .attr('fill', `url(#${gradientId})`)
      .attr('stroke', pipelineColor)
      .attr('stroke-width', 1);

    // Draw pipeline header
    this.drawPipelineHeader(pipelineGroup, pipeline, config);
    
    // Draw pipeline visualization
    this.drawPipelineVisualization(pipelineGroup, pipeline, config);

    // Store pipeline data for later reference
    this.objectsMap[pipeline.id || `pipeline-${index}`] = {
      id: pipeline.id || `pipeline-${index}`,
      x: 0,
      y: yPosition,
      width: config.pipelineWidth,
      height: config.pipelineHeight,
      type: 'pipeline',
      element: pipelineGroup,
      data: pipeline
    };

    // Add event listeners for tooltip
    this.addPipelineEventListeners(pipelineGroup, pipeline);

    // Draw pipeline memories if available
    if (config.pipelineMemories && config.pipelineMemories[pipeline.id]) {
      const memoryHeight = this.drawPipelineMemories(pipelineGroup, config.pipelineMemories[pipeline.id], pipeline.id, config);
      return config.pipelineHeight + memoryHeight;
    }

    return config.pipelineHeight;
  }

  /**
   * Draw a pipeline header
   */
  private drawPipelineHeader(pipelineGroup: any, pipeline: any, config: DrawConfig): void {
    const headerHeight = config.pipelineHeaderHeight;
    const headerWidth = config.pipelineWidth;
    
    // Header background
    pipelineGroup.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', headerWidth)
      .attr('height', headerHeight)
      .attr('fill', '#2c3e50')  // Darker blue-gray for better contrast with white text
      .attr('rx', config.pipelineBorderRadius)
      .attr('ry', config.pipelineBorderRadius);
    
    // Pipeline name
    pipelineGroup.append('text')
      .attr('x', config.pipelinePadding)
      .attr('y', headerHeight / 2)
      .attr('dominant-baseline', 'middle')
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
      .attr('fill', '#ffffff')
      .text(this.truncateText(pipeline.pipelineName || pipeline.name || 'Unnamed Pipeline', 30));
  }

  /**
   * Draw a visual representation of the pipeline
   */
  private drawPipelineVisualization(pipelineGroup: any, pipeline: any, config: DrawConfig): void {
    const visualizationGroup = pipelineGroup.append('g')
      .attr('class', 'pipeline-visualization')
      .attr('transform', `translate(${config.pipelinePadding}, ${config.pipelineHeaderHeight + 10})`);

    // Get source and target service types
    const sourceServiceType = 'BigQuery';
    const targetServiceType = 'Snowflake';
    
    const sourceInfo = this.serviceTypeColorMap[sourceServiceType] || this.serviceTypeColorMap['default'];
    const targetInfo = this.serviceTypeColorMap[targetServiceType] || this.serviceTypeColorMap['default'];
    
    // Draw source and target nodes with a line between them
    const sourceX = 25;
    const targetX = config.pipelineWidth - 45;
    const centerY = 25;
    
    // Source node (BigQuery)
    visualizationGroup.append('circle')
      .attr('cx', sourceX)
      .attr('cy', centerY)
      .attr('r', 10)
      .attr('fill', sourceInfo.color)
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 1.5)
      .attr('filter', 'drop-shadow(0 0 3px rgba(0,0,0,0.3))');
    
    // Source icon
    visualizationGroup.append('text')
      .attr('x', sourceX)
      .attr('y', centerY)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr('font-size', '10px')
      .attr('fill', '#ffffff')
      .text(sourceInfo.icon);
    
    // Target node (Snowflake)
    const targetGradientId = `target-gradient-${pipeline.id}`;
    const targetDefs = this.svg.append('defs');
    const targetGradient = targetDefs.append('radialGradient')
      .attr('id', targetGradientId)
      .attr('cx', '50%')
      .attr('cy', '50%')
      .attr('r', '70%');
    
    targetGradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', targetInfo.color)
      .attr('stop-opacity', 1);
      
    targetGradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', this.darkenColor(targetInfo.color, 0.3))
      .attr('stop-opacity', 1);
    
    visualizationGroup.append('circle')
      .attr('cx', targetX)
      .attr('cy', centerY)
      .attr('r', 12)
      .attr('fill', `url(#${targetGradientId})`)
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 1.5)
      .attr('filter', 'drop-shadow(0 0 4px rgba(0,0,0,0.4))');
    
    // Target icon
    visualizationGroup.append('text')
      .attr('x', targetX)
      .attr('y', centerY)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr('font-size', '12px')
      .attr('fill', '#ffffff')
      .text(targetInfo.icon);
    
    // Connection line with animation
    const pathData = this.createConnectionPath(sourceX, centerY, targetX, centerY);
    
    const path = visualizationGroup.append('path')
      .attr('d', pathData)
      .attr('fill', 'none')
      .attr('stroke', '#4fc3f7')
      .attr('stroke-width', 2.5)
      .attr('stroke-dasharray', '0')
      .attr('marker-end', 'url(#arrow-pipeline)')
      .attr('filter', 'drop-shadow(0 0 2px rgba(0,0,0,0.3))');
    
    // Create arrow marker if not already defined
    this.createArrowMarker('pipeline', '#4fc3f7');
    
    // Add source label with better visibility
    const sourceLabel = 'BigQuery';
    
    // Calculate source label width
    const maxSourceWidth = sourceX * 2 - 10;
    const sourceTextWidth = Math.min(sourceLabel.length * 6, maxSourceWidth);
    
    // Source label with background
    const sourceLabelBg = visualizationGroup.append('rect')
      .attr('x', sourceX - (sourceTextWidth / 2))
      .attr('y', centerY - 22)
      .attr('width', sourceTextWidth)
      .attr('height', 14)
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('fill', 'rgba(0,0,0,0.7)');
    
    visualizationGroup.append('text')
      .attr('x', sourceX)
      .attr('y', centerY - 15)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .attr('font-size', '9px')
      .attr('fill', '#ffffff')
      .text(sourceLabel);
  }

  /**
   * Create a path between two points
   */
  private createConnectionPath(x1: number, y1: number, x2: number, y2: number): string {
    const midX = (x1 + x2) / 2;
    return `M ${x1} ${y1} C ${midX} ${y1}, ${midX} ${y2}, ${x2} ${y2}`;
  }

  /**
   * Create an arrow marker for the path
   */
  private createArrowMarker(id: string, color: string): void {
    // Check if marker already exists
    if (this.svg.select(`#arrow-${id}`).empty()) {
      const defs = this.svg.append('defs');
      defs.append('marker')
        .attr('id', `arrow-${id}`)
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 8)
        .attr('refY', 0)
        .attr('markerWidth', 6)
        .attr('markerHeight', 6)
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M0,-5L10,0L0,5')
        .attr('fill', color);
    }
  }

  /**
   * Animate data flow along a path
   */
  private animateDataFlow(path: any): void {
    // Animation removed - no moving dot
  }

  /**
   * Add event listeners to pipeline
   */
  private addPipelineEventListeners(pipelineGroup: any, pipeline: any): void {
    // Ensure the pipeline tooltip exists
    if (!this.pipelineTooltip) {
      this.pipelineTooltip = this.tooltipService.createTooltip('pipeline');
    }
    
    pipelineGroup
      .style('cursor', 'pointer')
      .on('mouseenter', (event: any) => {
        // Use the tooltip service to show the tooltip
        this.tooltipService.showTooltip(
          this.pipelineTooltip,
          event,
          this.generatePipelineTooltip(pipeline)
        );
        
        // Highlight pipeline
        pipelineGroup.select('rect').attr('stroke-width', 2);
      })
      .on('mousemove', (event: any) => {
        // Use the tooltip service to move the tooltip
        this.tooltipService.moveTooltip(this.pipelineTooltip, event);
      })
      .on('mouseleave', () => {
        // Use the tooltip service to hide the tooltip
        this.tooltipService.hideTooltip(this.pipelineTooltip);
        
        // Reset pipeline highlight
        pipelineGroup.select('rect').attr('stroke-width', 1);
      })
      .on('click', (event: any) => {
        // Hide tooltip when clicked
        this.tooltipService.hideTooltip(this.pipelineTooltip);
        
        // Copy pipeline data to clipboard
        const pipelineName = pipeline.pipelineName || pipeline.name || 'Unnamed Pipeline';
        
        // Create a formatted string with pipeline information
        let pipelineInfo = `Pipeline: ${pipelineName}\n`;
        
        if (pipeline.description) {
          pipelineInfo += `Description: ${pipeline.description}\n`;
        }
        
        // Add pipeline status if available
        const status = pipeline.status || pipeline.pipelineStatus || 'Unknown';
        pipelineInfo += `Status: ${status}\n`;
        
        // Add pipeline type if available
        if (pipeline.type || pipeline.pipelineType) {
          pipelineInfo += `Type: ${pipeline.type || pipeline.pipelineType}\n`;
        }
        
        // Add source and target information
        if (pipeline.sourceType || pipeline.source) {
          pipelineInfo += `Source: ${pipeline.sourceType || pipeline.source || 'BigQuery'}\n`;
        }
        
        if (pipeline.targetType || pipeline.target) {
          pipelineInfo += `Target: ${pipeline.targetType || pipeline.target || 'Snowflake'}\n`;
        }
        
        // Add stages count if available
        if (pipeline.stages && pipeline.stages.length) {
          pipelineInfo += `Stages: ${pipeline.stages.length}\n`;
          
          // Add detailed stage information
          pipeline.stages.forEach((stage: any, index: number) => {
            pipelineInfo += `\nStage ${index + 1}: ${stage.name || 'Unnamed Stage'}\n`;
            
            if (stage.description) {
              pipelineInfo += `  Description: ${stage.description}\n`;
            }
            
            if (stage.type) {
              pipelineInfo += `  Type: ${stage.type}\n`;
            }
            
            if (stage.status) {
              pipelineInfo += `  Status: ${stage.status}\n`;
            }
            
            if (stage.startTime) {
              pipelineInfo += `  Start Time: ${new Date(stage.startTime).toLocaleString()}\n`;
            }
            
            if (stage.endTime) {
              pipelineInfo += `  End Time: ${new Date(stage.endTime).toLocaleString()}\n`;
            }
          });
        }
        
        // Add schedule information if available
        if (pipeline.schedule) {
          pipelineInfo += `Schedule: ${pipeline.schedule}\n`;
        }
        
        // Add creation date if available
        if (pipeline.createdAt || pipeline.createTime) {
          const createDate = pipeline.createdAt || pipeline.createTime;
          pipelineInfo += `Created: ${new Date(createDate).toLocaleString()}\n`;
        }
        
        // Add last run date if available
        if (pipeline.lastRun) {
          pipelineInfo += `Last Run: ${new Date(pipeline.lastRun).toLocaleString()}\n`;
        }
        
        // Add next run date if available
        if (pipeline.nextRun) {
          pipelineInfo += `Next Run: ${new Date(pipeline.nextRun).toLocaleString()}\n`;
        }
        
        // Add owner if available
        if (pipeline.owner) {
          pipelineInfo += `Owner: ${pipeline.owner}\n`;
        }
        
        // Add tags if available
        if (pipeline.tags && pipeline.tags.length) {
          pipelineInfo += `Tags: ${pipeline.tags.join(', ')}\n`;
        }
        
        // Add ID if available
        if (pipeline.id) {
          pipelineInfo += `ID: ${pipeline.id}`;
        }
        
        // Copy to clipboard
        navigator.clipboard.writeText(pipelineInfo).then(() => {
          // Show notification
          this.tooltipService.showNotification('Pipeline info copied!', event);
        }).catch(err => {
          console.error('Failed to copy pipeline info: ', err);
        });
        
        // Prevent event bubbling
        event.stopPropagation();
      });
  }

  /**
   * Generate pipeline tooltip content
   */
  private generatePipelineTooltip(pipeline: any): string {
    let html = '';
    
    // Add header with pipeline name
    const pipelineName = pipeline.pipelineName || pipeline.name || 'Unnamed Pipeline';
    html += this.tooltipService.formatHeader(pipelineName, 'pipeline');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available
    if (pipeline.description) {
      mainContent += `<div class="tooltip-description">${pipeline.description}</div>`;
    }
    
    // Add pipeline details section
    let detailsContent = '';
    
    // Add pipeline status if available
    const status = pipeline.status || pipeline.pipelineStatus || 'Unknown';
    detailsContent += this.tooltipService.formatProperty('Status', status);
    
    // Add pipeline type if available
    if (pipeline.type || pipeline.pipelineType) {
      detailsContent += this.tooltipService.formatProperty('Type', pipeline.type || pipeline.pipelineType);
    }
    
    // Add source and target information
    if (pipeline.sourceType || pipeline.source) {
      detailsContent += this.tooltipService.formatProperty('Source', pipeline.sourceType || pipeline.source || 'BigQuery');
    }
    
    if (pipeline.targetType || pipeline.target) {
      detailsContent += this.tooltipService.formatProperty('Target', pipeline.targetType || pipeline.target || 'Snowflake');
    }
    
    // Add stages count if available
    if (pipeline.stages && pipeline.stages.length) {
      detailsContent += this.tooltipService.formatProperty('Stages', pipeline.stages.length);
    }
    
    // Add last run date if available
    if (pipeline.lastRun) {
      detailsContent += this.tooltipService.formatProperty('Last Run', new Date(pipeline.lastRun).toLocaleString());
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add BigQuery section if we have any BigQuery details
    let bigQueryContent = '';
    
    // Add BigQuery project if available
    if (pipeline.bigQueryProject) {
      bigQueryContent += this.tooltipService.formatProperty('BQ Project', pipeline.bigQueryProject);
    }
    
    // Add BigQuery dataset if available
    if (pipeline.bigQueryDatasetName) {
      bigQueryContent += this.tooltipService.formatProperty('BQ Dataset', pipeline.bigQueryDatasetName);
    }
    
    // Add BigQuery SQL if available (truncated)
    if (pipeline.bigquerySql) {
      bigQueryContent += this.tooltipService.formatProperty('SQL Query', this.truncateText(pipeline.bigquerySql, 50));
    }
    
    // Add BigQuery linked service if available
    if (pipeline.bigQueryLinkedServiceName) {
      bigQueryContent += this.tooltipService.formatProperty('BQ Linked Service', pipeline.bigQueryLinkedServiceName);
    }
    
    // Add BigQuery section if we have any BigQuery details
    if (bigQueryContent) {
      mainContent += this.tooltipService.formatSection(bigQueryContent);
    }
    
    // Add Snowflake section if we have any Snowflake details
    let snowflakeContent = '';
    
    // Add Snowflake target table if available
    if (pipeline.snowflakeTargetTable) {
      snowflakeContent += this.tooltipService.formatProperty('SF Table', pipeline.snowflakeTargetTable);
    }
    
    // Add Snowflake dataset if available
    if (pipeline.snowflakeDatasetName) {
      snowflakeContent += this.tooltipService.formatProperty('SF Dataset', pipeline.snowflakeDatasetName);
    }
    
    // Add Snowflake section if we have any Snowflake details
    if (snowflakeContent) {
      mainContent += this.tooltipService.formatSection(snowflakeContent);
    }
    
    // Add Blob section if we have any Blob details
    let blobContent = '';
    
    // Add Blob dataset if available
    if (pipeline.blobDatasetName) {
      blobContent += this.tooltipService.formatProperty('Blob Dataset', pipeline.blobDatasetName);
    }
    
    // Add Blob section if we have any Blob details
    if (blobContent) {
      mainContent += this.tooltipService.formatSection(blobContent);
    }
    
    // Add Connection section if we have any Connection details
    let connectionContent = '';
    
    // Add Connection reference if available
    if (pipeline.connectionRef) {
      connectionContent += this.tooltipService.formatProperty('Connection Ref', pipeline.connectionRef);
    }
    
    // Add Connection section if we have any Connection details
    if (connectionContent) {
      mainContent += this.tooltipService.formatSection(connectionContent);
    }
    
    // Add main content
    html += mainContent;
    
    // Add footer with ID if available
    if (pipeline.id) {
      html += this.tooltipService.formatFooter(`ID: ${pipeline.id}`);
    }
    
    return html;
  }

  /**
   * Draw memory items for a pipeline
   */
  private drawPipelineMemories(pipelineGroup: any, memories: MemoryItem[], pipelineId: string, config: DrawConfig): number {
    // Default memory size and margin if not provided
    const memorySize = config.memorySize || 16;
    const memoryMargin = config.memoryMargin || 8;
    
    // Start position for memories (below the pipeline content)
    const memoryStartY = config.pipelineHeight + 10;
    
    // Create memories container
    const memoriesContainer = pipelineGroup.append('g')
      .attr('class', 'pipeline-memories-container')
      .attr('transform', `translate(${config.pipelinePadding}, ${memoryStartY})`);
    
    // Calculate items per row based on pipeline width
    const itemsPerRow = Math.floor((config.pipelineWidth - (2 * config.pipelinePadding)) / (memorySize + memoryMargin));
    
    // Draw memory items
    memories.forEach((memory, index) => {
      const rowIndex = Math.floor(index / itemsPerRow);
      const colIndex = index % itemsPerRow;
      
      const memX = colIndex * (memorySize + memoryMargin);
      const memY = rowIndex * (memorySize + memoryMargin);
      
      // Determine color based on memory type
      let memoryColor = '#90a4ae'; // Default color
      
      switch(memory.type) {
        case 'data_issue':
          memoryColor = '#607d8b'; // Blue-gray
          break;
        case 'model_evaluation':
          memoryColor = '#5c6bc0'; // Indigo
          break;
        case 'user_feedback':
          memoryColor = '#26a69a'; // Teal
          break;
        case 'system_alert':
          memoryColor = '#ef5350'; // Light red
          break;
        case 'performance_metric':
          memoryColor = '#66bb6a'; // Green
          break;
      }
      
      // Draw memory item
      const memoryItem = memoriesContainer.append('rect')
        .attr('class', 'memory-item')
        .attr('x', memX)
        .attr('y', memY)
        .attr('width', memorySize)
        .attr('height', memorySize)
        .attr('rx', 2)
        .attr('ry', 2)
        .attr('fill', memoryColor)
        .attr('stroke', '#666')
        .attr('stroke-width', 0.5)
        .attr('data-memory-id', (memory as any).id || index.toString())
        .style('cursor', 'pointer');
      
      // Setup tooltip for memory item
      this.setupMemoryTooltip(memoryItem, memory);
    });
    
    // Calculate the total height used by memories
    const memoryRows = Math.ceil(memories.length / itemsPerRow);
    const memoryHeight = memoryRows * (memorySize + memoryMargin);
    
    return memoryHeight;
  }

  /**
   * Setup tooltip for memory item
   */
  private setupMemoryTooltip(element: any, memory: MemoryItem): void {
    element
      .on('mouseenter', (event: MouseEvent) => {
        // Format tooltip content
        const tooltipContent = `
          <div class="tooltip-title">${memory.type || 'Memory'}</div>
          <div class="tooltip-content">
            <div><strong>Observation:</strong> ${memory.observation || 'N/A'}</div>
            <div><strong>Insight:</strong> ${memory.insight || 'N/A'}</div>
            ${memory.importance ? `<div><strong>Importance:</strong> ${memory.importance}</div>` : ''}
          </div>
        `;
        
        this.tooltipService.showTooltip(this.memoryTooltip, event, tooltipContent);
      })
      .on('mouseleave', () => {
        this.tooltipService.hideTooltip(this.memoryTooltip);
      })
      .on('mousemove', (event: MouseEvent) => {
        this.tooltipService.moveTooltip(this.memoryTooltip, event);
      });
  }

  /**
   * Get all rendered objects
   */
  public getAllObjects(): Record<string, PipelineRenderData> {
    return this.objectsMap;
  }

  /**
   * Highlight a pipeline
   */
  public highlightObject(id: string, options: { color?: string; duration?: number } = {}): void {
    const obj = this.objectsMap[id];
    if (!obj) {
      console.warn(`Pipeline with ID ${id} not found`);
      return;
    }

    const color = options.color || '#2196f3';
    const duration = options.duration || 2000;

    // Highlight the pipeline
    obj.element.select('rect')
      .transition()
      .duration(200)
      .attr('stroke', color)
      .attr('stroke-width', 2)
      .transition()
      .delay(duration)
      .duration(200)
      .attr('stroke', 'rgba(255, 255, 255, 0.1)')
      .attr('stroke-width', 1);
  }

  /**
   * Get color for a pipeline status
   */
  private getStatusColor(status: string): string {
    return this.statusColorMap[status?.toLowerCase()] || this.statusColorMap['default'];
  }

  /**
   * Get color for a stage status
   */
  private getStageStatusColor(status: string): string {
    switch (status?.toLowerCase()) {
      case 'completed':
        return '#66bb6a'; // Green
      case 'running':
        return '#42a5f5'; // Blue
      case 'pending':
        return '#ffb74d'; // Orange
      case 'failed':
        return '#ef5350'; // Red
      default:
        return '#90a4ae'; // Gray
    }
  }

  /**
   * Helper function to darken a color
   */
  private darkenColor(color: string, amount: number): string {
    // Convert hex to RGB
    let r = parseInt(color.substring(1, 3), 16);
    let g = parseInt(color.substring(3, 5), 16);
    let b = parseInt(color.substring(5, 7), 16);
    
    // Darken
    r = Math.max(0, Math.floor(r * (1 - amount)));
    g = Math.max(0, Math.floor(g * (1 - amount)));
    b = Math.max(0, Math.floor(b * (1 - amount)));
    
    // Convert back to hex
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }

  /**
   * Truncate text that's too long
   */
  private truncateText(text: string, maxLength: number): string {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  }
}
