import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import * as d3 from 'd3';
import { Session } from '../../models/session.model';
import { Workstream } from '../../models/workboard.model';

export interface ElementReference {
  id: string;
  element: any; // D3 selection
  projectId: string;
  data: any; // The original data object
  x: number;
  y: number;
  width: number;
  height: number;
  type: 'session' | 'workstream';
}

@Injectable({
  providedIn: 'root'
})
export class ElementsLinkService {
  private tooltip: any;
  private svg: any;
  private sessionElements: Record<string, ElementReference> = {};
  private workstreamElements: Record<string, ElementReference> = {};
  private activeLinks: any[] = []; // Store active link elements for removal
  private renderer: Renderer2;
  
  // Colors for highlighting
  private highlightColor: string = '#ff5722'; // orange highlight color
  private linkColor: string = '#ff9800'; // orange link line color
  private linkWidth: number = 1.5;
  private linkDash: string = '3,3'; // Dashed line pattern
  
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  
  /**
   * Set SVG and tooltip references
   */
  public setReferences(svg: any, tooltip: any): void {
    this.svg = svg;
    this.tooltip = tooltip;
  }
  
  /**
   * Clear all stored element references
   */
  public clear(): void {
    this.sessionElements = {};
    this.workstreamElements = {};
    this.clearLinks();
  }
  
  /**
   * Register a session element for linking
   */
  public registerSession(
    sessionId: string,
    projectId: string,
    element: any,
    data: Session,
    x: number,
    y: number,
    width: number,
    height: number
  ): void {
    this.sessionElements[sessionId.toLowerCase()] = {
      id: sessionId,
      element,
      projectId,
      data,
      x,
      y,
      width,
      height,
      type: 'session'
    };
  }
  
  /**
   * Register a workstream element for linking
   */
  public registerWorkstream(
    workstreamId: string,
    projectId: string,
    element: any,
    data: Workstream,
    x: number,
    y: number,
    width: number,
    height: number
  ): void {
    this.workstreamElements[workstreamId.toLowerCase()] = {
      id: workstreamId,
      element,
      projectId,
      data,
      x,
      y,
      width,
      height,
      type: 'workstream'
    };
  }
  
  /**
   * Get a session reference by ID
   */
  public getSessionReference(sessionId: string): ElementReference | null {
    return this.sessionElements[sessionId.toLowerCase()] || null;
  }
  
  /**
   * Get a workstream reference by ID
   */
  public getWorkstreamReference(workstreamId: string): ElementReference | null {
    return this.workstreamElements[workstreamId.toLowerCase()] || null;
  }
  
  /**
   * Get all session references
   */
  public getAllSessionReferences(): Record<string, ElementReference> {
    return this.sessionElements;
  }
  
  /**
   * Get all workstream references
   */
  public getAllWorkstreamReferences(): Record<string, ElementReference> {
    return this.workstreamElements;
  }
  
  /**
   * Highlight a table by ID
   */
  public highlightTable(tableId: string): void {
    console.log(`Highlighting table: ${tableId}`);
  }
  
  /**
   * Reset all highlights
   */
  public resetHighlights(): void {
    console.log('Resetting all highlights from elements-link service');
  }
  
  /**
   * Show links from a session to related workstreams
   */
  public showSessionLinks(sessionId: string): void {
    const session = this.sessionElements[sessionId.toLowerCase()];
    if (!session || !session.data.relatedWorkstreamIds) return;
    
    // Clear any existing links
    this.clearLinks();
    
    // Highlight the session
    this.highlightElement(session.element);
    
    // Find and highlight related workstreams within the same project
    session.data.relatedWorkstreamIds.forEach((workstreamId: string) => {
      const workstream = this.findWorkstreamById(workstreamId, session.projectId);
      if (workstream) {
        // Highlight the workstream
        this.highlightElement(workstream.element);
        
        // Draw a line from session to workstream
        this.drawLinkLine(session, workstream);
      }
    });
  }
  
  /**
   * Show links from a workstream to related sessions
   */
  public showWorkstreamLinks(workstreamId: string, projectId: string): void {
    const workstream = this.findWorkstreamById(workstreamId, projectId);
    if (!workstream) return;
    
    // Clear any existing links
    this.clearLinks();
    
    // Highlight the workstream
    this.highlightElement(workstream.element);
    
    // Find and highlight related sessions
    Object.values(this.sessionElements).forEach(session => {
      if (
        session.projectId === projectId && 
        session.data.relatedWorkstreamIds && 
        session.data.relatedWorkstreamIds.includes(workstreamId)
      ) {
        // Highlight the session
        this.highlightElement(session.element);
        
        // Draw a line from workstream to session
        this.drawLinkLine(session, workstream);
      }
    });
  }
  
  /**
   * Clear all links and highlights
   */
  public clearLinks(): void {
    // Remove all link lines and highlighted elements
    this.activeLinks.forEach(link => {
      if (link && link.remove) {
        link.remove();
      }
    });
    
    // Remove any lingering link-line-group elements as fallback
    if (this.svg) {
      this.svg.selectAll('.link-line-group').remove();
    }
    
    // Clear the active links array
    this.activeLinks = [];
  }
  
  /**
   * Find a workstream by ID within a specific project
   */
  private findWorkstreamById(workstreamId: string, projectId: string): ElementReference | null {
    const workstream = Object.values(this.workstreamElements).find(
      w => w.id === workstreamId && w.projectId === projectId
    );
    return workstream || null;
  }
  
  /**
   * Highlight an element with a temporary effect
   */
  private highlightElement(element: any): void {
    if (!element) return;
    
    // Store original stroke color and width for restoration
    const originalStroke = element.select('rect').attr('stroke');
    const originalStrokeWidth = element.select('rect').attr('stroke-width');
    
    // Apply highlight effect
    element.select('rect')
      .attr('stroke', this.highlightColor)
      .attr('stroke-width', 2);
      
    // Store element in activeLinks for restoration later
    this.activeLinks.push({
      element: element,
      originalStroke: originalStroke,
      originalStrokeWidth: originalStrokeWidth,
      remove: function() {
        element.select('rect')
          .attr('stroke', this.originalStroke)
          .attr('stroke-width', this.originalStrokeWidth);
      }
    });
  }
  
  /**
   * Draw a link line between session and workstream
   */
  private drawLinkLine(session: ElementReference, workstream: ElementReference): void {
    if (!this.svg) return;
    
    // Create a group to contain the link elements
    const linkGroup = this.svg.append('g')
      .attr('class', 'link-line-group');
    
    try {
      // Get the actual DOM node for session and workstream elements
      const sessionNode = session.element.node();
      const workstreamNode = workstream.element.node();
      
      if (!sessionNode || !workstreamNode) {
        console.error('Session or workstream nodes not found');
        return;
      }
      
      // Get bounding client rects to determine positions in the viewport
      const sessionRect = sessionNode.getBoundingClientRect();
      const workstreamRect = workstreamNode.getBoundingClientRect();
      
      // Get the SVG's bounding client rect
      const svgNode = this.svg.node();
      const svgRect = svgNode.getBoundingClientRect();
      
      // Convert viewport coordinates to SVG coordinates
      const sessionX = sessionRect.left - svgRect.left;
      const sessionY = sessionRect.top - svgRect.top;
      const workstreamX = workstreamRect.left - svgRect.left;
      const workstreamY = workstreamRect.top - svgRect.top;
      
      // Calculate coordinates for connection points (more precise)
      let leftX, leftY, rightX, rightY;
      
      // Determine if session is on left or right
      if (sessionX < workstreamX) {
        // Session is on the left side
        // Use the right edge of session and left edge of workstream for connection
        leftX = sessionX + sessionRect.width;
        leftY = sessionY + (sessionRect.height / 2);
        rightX = workstreamX;
        rightY = workstreamY + (workstreamRect.height / 2);
      } else {
        // Workstream is on the left side
        leftX = workstreamX + workstreamRect.width;
        leftY = workstreamY + (workstreamRect.height / 2);
        rightX = sessionX;
        rightY = sessionY + (sessionRect.height / 2);
      }
      
      // Calculate distance between elements to determine curve intensity
      const distance = Math.abs(leftX - rightX);
      const curveOffset = Math.min(distance * 0.3, 40); // Control how much the curve bends
      
      // Log connection points for debugging
      console.log('Drawing link line:', {
        session: { x: sessionX, y: sessionY, w: sessionRect.width, h: sessionRect.height },
        workstream: { x: workstreamX, y: workstreamY, w: workstreamRect.width, h: workstreamRect.height },
        leftPoint: { x: leftX, y: leftY },
        rightPoint: { x: rightX, y: rightY }
      });
      
      // Create a path for the link line with nicer bezier curve
      const linkLine = linkGroup.append('path')
        .attr('d', `M ${leftX} ${leftY} 
                   C ${leftX + curveOffset} ${leftY}, 
                     ${rightX - curveOffset} ${rightY}, 
                     ${rightX} ${rightY}`)
        .attr('fill', 'none')
        .attr('stroke', this.linkColor)
        .attr('stroke-width', this.linkWidth)
        .attr('stroke-dasharray', this.linkDash);
      
      // Add small circles at connection points for better visibility
      const startPoint = linkGroup.append('circle')
        .attr('cx', leftX)
        .attr('cy', leftY)
        .attr('r', 3)
        .attr('fill', this.linkColor);
        
      const endPoint = linkGroup.append('circle')
        .attr('cx', rightX)
        .attr('cy', rightY)
        .attr('r', 3)
        .attr('fill', this.linkColor);
      
      // Store group in activeLinks for removal
      this.activeLinks.push({
        remove: function() {
          linkGroup.remove();
        }
      });
    } catch (error) {
      console.error('Error drawing link line:', error);
      linkGroup.remove();
    }
  }
  
  /**
   * Position tooltip for session hover 
   * Places tooltip very close to the session with minimal overlap
   */
  public positionSessionTooltip(event: any): { left: number; top: number } {
    // Find the session element being hovered
    const sessionId = this.findSessionFromEvent(event);
    if (sessionId) {
      const session = this.sessionElements[sessionId.toLowerCase()];
      if (session) {
        try {
          // Get the session element's position
          const sessionNode = session.element.node();
          if (sessionNode) {
            const rect = sessionNode.getBoundingClientRect();
            
            // Get tooltip element dimensions if available
            let tooltipWidth = 300;
            let tooltipHeight = 200;
            
            if (this.tooltip && this.tooltip.node()) {
              const tooltipRect = this.tooltip.node().getBoundingClientRect();
              if (tooltipRect.width > 0) {
                tooltipWidth = tooltipRect.width;
                tooltipHeight = tooltipRect.height;
              }
            }
            
            // Position the tooltip with just a 3px overlap to the left of the session
            // and slightly above it
            return {
              left: rect.left - tooltipWidth + 3,
              top: rect.top - tooltipHeight + 3
            };
          }
        } catch (error) {
          console.error('Error positioning session tooltip:', error);
        }
      }
    }
    
    // Fallback to a fixed offset if we can't find the element
    return {
      left: Math.max(event.pageX - 300, 10),
      top: Math.max(event.pageY - 200, 10)
    };
  }
  
  /**
   * Position tooltip for workstream hover
   * Places tooltip at the top-right of the workstream with minimal overlap
   */
  public positionWorkstreamTooltip(event: any): { left: number; top: number } {
    // Find the workstream element being hovered
    const workstreamId = this.findWorkstreamFromEvent(event);
    const projectId = this.findProjectIdFromEvent(event);
    
    if (workstreamId && projectId) {
      const workstream = this.findWorkstreamById(workstreamId, projectId);
      if (workstream) {
        try {
          // Get the workstream element's position
          const workstreamNode = workstream.element.node();
          if (workstreamNode) {
            const rect = workstreamNode.getBoundingClientRect();
            
            // Get tooltip dimensions if available
            let tooltipWidth = 300;
            let tooltipHeight = 200;
            
            if (this.tooltip && this.tooltip.node()) {
              const tooltipRect = this.tooltip.node().getBoundingClientRect();
              if (tooltipRect.width > 0 && tooltipRect.height > 0) {
                tooltipWidth = tooltipRect.width;
                tooltipHeight = tooltipRect.height;
              }
            }
            
            // Position the tooltip at the top-right corner of the workstream with minimal overlap
            return {
              left: rect.right - 10, // Right align with 10px overlap
              top: rect.top - tooltipHeight + 10 // Position above with 10px overlap
            };
          }
        } catch (error) {
          console.error('Error positioning workstream tooltip:', error);
        }
      }
    }
    
    // Fallback to a fixed offset if we can't find the element
    return {
      left: event.pageX + 20, // Position to the right of cursor
      top: Math.max(event.pageY - 200, 10) // Position above cursor
    };
  }
  
  /**
   * Helper to find session ID from an event
   * Uses event target to determine which session is being hovered
   */
  private findSessionFromEvent(event: any): string | null {
    try {
      // Try to find the session by walking up from event target
      let currentElement = event.target;
      for (let i = 0; i < 10; i++) { // Limit traversal depth
        if (!currentElement) break;
        
        // For each element, check if it matches any of our session elements
        for (const [id, session] of Object.entries(this.sessionElements)) {
          const sessionNode = session.element.node();
          if (sessionNode && (sessionNode === currentElement || sessionNode.contains(currentElement))) {
            return id;
          }
        }
        
        currentElement = currentElement.parentElement;
      }
      
      return null;
    } catch (error) {
      console.error('Error finding session from event:', error);
      return null;
    }
  }
  
  /**
   * Helper to find workstream ID from an event
   * Uses event target to determine which workstream is being hovered
   */
  private findWorkstreamFromEvent(event: any): string | null {
    try {
      // Try to find the workstream by walking up from event target
      let currentElement = event.target;
      for (let i = 0; i < 10; i++) { // Limit traversal depth
        if (!currentElement) break;
        
        // For each element, check if it matches any of our workstream elements
        for (const [id, workstream] of Object.entries(this.workstreamElements)) {
          const workstreamNode = workstream.element.node();
          if (workstreamNode && (workstreamNode === currentElement || workstreamNode.contains(currentElement))) {
            return id;
          }
        }
        
        currentElement = currentElement.parentElement;
      }
      
      return null;
    } catch (error) {
      console.error('Error finding workstream from event:', error);
      return null;
    }
  }
  
  /**
   * Helper to find project ID from an event
   */
  private findProjectIdFromEvent(event: any): string | null {
    try {
      // First try to infer from the session or workstream
      const sessionId = this.findSessionFromEvent(event);
      if (sessionId && this.sessionElements[sessionId.toLowerCase()]) {
        return this.sessionElements[sessionId.toLowerCase()].projectId;
      }
      
      const workstreamId = this.findWorkstreamFromEvent(event);
      if (workstreamId) {
        // Find the project ID by checking all workstreams with this ID
        for (const workstream of Object.values(this.workstreamElements)) {
          if (workstream.id === workstreamId) {
            return workstream.projectId;
          }
        }
      }
      
      // If we couldn't find it through session or workstream, try DOM traversal
      let target = event.target;
      while (target && !target.classList.contains('project')) {
        target = target.parentElement;
        if (!target) break;
      }
      
      if (target) {
        // Try to extract project ID from a data attribute
        return target.getAttribute('data-project-id');
      }
      
      return null;
    } catch (error) {
      console.error('Error finding project ID from event:', error);
      return null;
    }
  }
} 