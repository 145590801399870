import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ChatSession, SessionStatus } from '../models/chat.models';
import { UserService } from './user.service';
import { Project, Phase, TabReference, TabInfo } from '../models/session.interface';
import { GetObjects } from '../mocks/data.mock';
import { ChatService } from './chat.service';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {
  private sessions = new BehaviorSubject<ChatSession[]>([]);
  private openedTabs = new BehaviorSubject<string[]>([]);
  private tabs = new BehaviorSubject<TabInfo[]>([]);
  private projects = new BehaviorSubject<Project[]>([]);
  private phases = new BehaviorSubject<Phase[]>([]);
  private selectedProject = new BehaviorSubject<Project | null>(null);
  private selectedPhase = new BehaviorSubject<Phase | null>(null);
  private tabReferences = new BehaviorSubject<Map<string, TabReference>>(new Map());

  constructor(private userService: UserService, private chatService: ChatService) {
    console.log('[SessionsService] Initializing service');
    this.initializeService();
  }

  private async initializeService(): Promise<void> {
    try {
      const user = await this.userService.getCurrentUser();
      if (user?.email) {
        // Initialize with a default session if needed
        this.startSession(user.email);
      } else {
        console.warn('[SessionsService] No user available during initialization');
      }
      
      // Load initial projects
      await this.loadInitialData();
    } catch (error) {
      console.error('[SessionsService] Error initializing service:', error);
    }
  }

  async getSessions(
    userId: string,
    limit?: number,
    offset?: number
  ): Promise<ChatSession[]> {
    console.log(`[SessionsService] Getting sessions for user ${userId}`, { limit, offset });
    let sessions = this.sessions.value;
    
    if (limit && offset) {
      sessions = sessions.slice(offset, offset + limit);
    } else if (limit) {
      sessions = sessions.slice(0, limit);
    }

    console.log(`[SessionsService] Found ${sessions.length} sessions`);
    return sessions;
  }

  async getOpenSessions(userId: string): Promise<ChatSession[]> {
    console.log(`[SessionsService] Getting open sessions for user ${userId}`);
    const sessions = this.sessions.value.filter(s => s.status === SessionStatus.Active);
    console.log(`[SessionsService] Found ${sessions.length} open sessions`);
    return sessions;
  }

  async startSession(
    userId: string,
    initialMessage?: string,
    metadata?: Record<string, unknown>
  ): Promise<ChatSession> {
    console.log(`[SessionsService] Starting new session for user ${userId}`, { initialMessage, metadata });
    
    // Get current project and phase
    const currentProject = this.selectedProject.value;
    const currentPhase = this.selectedPhase.value;
    
    const newSession: ChatSession = {
      id: uuidv4(),
      title: initialMessage || (currentProject && currentPhase ? 
        `${currentProject.label} - ${currentPhase.label}` : 'New Chat'),
      status: SessionStatus.Active,
      createdAt: new Date(),
      updatedAt: new Date(),
      participants: {
        users: [userId],
        agents: []
      },
      metadata: {
        ...metadata,
        projectId: currentProject?.id,
        phaseId: currentPhase?.id
      }
    };

    const currentSessions = this.sessions.value;
    this.sessions.next([...currentSessions, newSession]);
    console.log('[SessionsService] New session created:', newSession);

    // Add to opened tabs and make it active
    const openedTabs = this.openedTabs.value;
    this.openedTabs.next([...openedTabs, newSession.id]);
    
    // Update tabs to make the new session active
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => ({ ...tab, active: false }));
    updatedTabs.push({
      id: newSession.id,
      sessionId: newSession.id,
      title: newSession.title,
      active: true,
      type: 'chat'
    });
    this.tabs.next(updatedTabs);
    
    // Set tab reference with current project and phase
    if (currentProject && currentPhase) {
      this.setTabReference(newSession.id, currentProject.id, currentPhase.id, currentProject.color);
    }
    
    console.log(`[SessionsService] Session ${newSession.id} added to tabs and activated`);
    return newSession;
  }

  async closeSession(sessionId: string, userId: string): Promise<void> {
    console.log(`[SessionsService] Closing session ${sessionId} for user ${userId}`);
    await this.updateSessionStatus(sessionId, SessionStatus.Completed, userId);
    
    // Remove from opened tabs
    const openedTabs = this.openedTabs.value;
    this.openedTabs.next(openedTabs.filter(id => id !== sessionId));
    
    // Remove from tabs and activate another tab if needed
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.filter(tab => tab.sessionId !== sessionId);
    
    // If we just closed the active tab and there are other tabs, activate the last one
    if (currentTabs.find(tab => tab.sessionId === sessionId)?.active && updatedTabs.length > 0) {
      updatedTabs[updatedTabs.length - 1].active = true;
    }
    
    this.tabs.next(updatedTabs);
    console.log(`[SessionsService] Session ${sessionId} removed from tabs`);
  }

  async archiveSession(sessionId: string, userId: string): Promise<void> {
    console.log(`[SessionsService] Archiving session ${sessionId} for user ${userId}`);
    await this.updateSessionStatus(sessionId, SessionStatus.Archived, userId);
  }

  private async updateSessionStatus(
    sessionId: string,
    status: SessionStatus,
    userId: string
  ): Promise<void> {
    console.log(`[SessionsService] Updating session ${sessionId} status to ${status}`);
    const currentSessions = this.sessions.value;
    const sessionIndex = currentSessions.findIndex(s => s.id === sessionId);
    
    if (sessionIndex === -1) {
      console.warn(`[SessionsService] Session ${sessionId} not found`);
      return;
    }

    const updatedSessions = [...currentSessions];
    updatedSessions[sessionIndex] = {
      ...updatedSessions[sessionIndex],
      status,
      updatedAt: new Date()
    };

    this.sessions.next(updatedSessions);
    console.log(`[SessionsService] Session ${sessionId} status updated to ${status}`);
  }

  async updateSessionMetadata(
    sessionId: string,
    metadata: Record<string, unknown>,
    userId: string
  ): Promise<void> {
    console.log(`[SessionsService] Updating session ${sessionId} metadata for user ${userId}`, metadata);
    const currentSessions = this.sessions.value;
    const sessionIndex = currentSessions.findIndex(s => s.id === sessionId);
    
    if (sessionIndex === -1) return;

    const updatedSessions = [...currentSessions];
    updatedSessions[sessionIndex] = {
      ...updatedSessions[sessionIndex],
      metadata: { ...updatedSessions[sessionIndex].metadata, ...metadata },
      updatedAt: new Date()
    };

    this.sessions.next(updatedSessions);
    console.log(`[SessionsService] Session ${sessionId} metadata updated`);
  }

  async addParticipant(
    sessionId: string,
    participantId: string,
    role: 'user' | 'agent',
    userId: string
  ): Promise<void> {
    console.log(`[SessionsService] Adding participant ${participantId} as ${role} to session ${sessionId}`);
    const currentSessions = this.sessions.value;
    const sessionIndex = currentSessions.findIndex(s => s.id === sessionId);
    
    if (sessionIndex === -1) return;

    const updatedSessions = [...currentSessions];
    const session = { ...updatedSessions[sessionIndex] };
    
    if (role === 'user') {
      session.participants.users = [...session.participants.users, participantId];
    } else {
      session.participants.agents = [...session.participants.agents, participantId];
    }

    updatedSessions[sessionIndex] = session;
    this.sessions.next(updatedSessions);
    console.log(`[SessionsService] Participant ${participantId} added to session ${sessionId}`);
  }

  async removeParticipant(
    sessionId: string,
    participantId: string,
    userId: string
  ): Promise<void> {
    console.log(`[SessionsService] Removing participant ${participantId} from session ${sessionId}`);
    const currentSessions = this.sessions.value;
    const sessionIndex = currentSessions.findIndex(s => s.id === sessionId);
    
    if (sessionIndex === -1) return;

    const updatedSessions = [...currentSessions];
    const session = { ...updatedSessions[sessionIndex] };
    
    session.participants.users = session.participants.users.filter(id => id !== participantId);
    session.participants.agents = session.participants.agents.filter(id => id !== participantId);

    updatedSessions[sessionIndex] = session;
    this.sessions.next(updatedSessions);
    console.log(`[SessionsService] Participant ${participantId} removed from session ${sessionId}`);
  }

  openTab(tab: { id: string; title: string; type?: string }): void {
    // Add to opened tabs if not already there
    const openedTabs = this.openedTabs.value;
    if (!openedTabs.includes(tab.id)) {
      this.openedTabs.next([...openedTabs, tab.id]);
    }

    // Create or update tab info
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(t => ({ ...t, active: false }));
    const existingTabIndex = updatedTabs.findIndex(t => t.sessionId === tab.id);

    if (existingTabIndex !== -1) {
      updatedTabs[existingTabIndex].active = true;
    } else {
      updatedTabs.push({
        id: tab.id,
        sessionId: tab.id,
        title: tab.title,
        active: true,
        type: tab.type || 'chat'
      });
    }

    this.tabs.next(updatedTabs);
  }

  setActiveSession(sessionId: string): void {
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => ({
      ...tab,
      active: tab.sessionId === sessionId
    }));
    this.tabs.next(updatedTabs);
  }

  getOpenedTabs(): Observable<string[]> {
    return this.openedTabs.asObservable();
  }

  getTabs(): Observable<TabInfo[]> {
    return this.tabs.asObservable();
  }

  // Projects and phases management
  getProjects(): Observable<Project[]> {
    return this.projects.asObservable();
  }

  getSelectedProject(): Observable<Project | null> {
    return this.selectedProject.asObservable();
  }

  getSelectedPhase(): Observable<Phase | null> {
    return this.selectedPhase.asObservable();
  }

  async loadInitialData(): Promise<void> {
    try {
      const mockProjects = await GetObjects('Project');
      const projects: Project[] = mockProjects.map(p => ({
        id: p.id,
        label: p.label,
        color: p.color,
        type: p.type
      }));
      this.projects.next(projects);
    } catch (error) {
      console.error('[SessionsService] Error loading initial data:', error);
      this.projects.next([]);
    }
  }

  async selectProject(project: Project): Promise<void> {
    this.selectedProject.next(project);
    try {
      const mockPhases = await GetObjects('Phase', project.color);
      const phases: Phase[] = mockPhases.map(p => ({
        id: p.id,
        label: p.label,
        projectId: project.id
      }));
      this.phases.next(phases);
      // Reset phase selection when changing project
      this.selectedPhase.next(null);
    } catch (error) {
      console.error('[SessionsService] Error loading phases:', error);
      this.phases.next([]);
    }
  }

  selectPhase(phase: Phase): void {
    this.selectedPhase.next(phase);
  }

  async getPhases(projectColor: string): Promise<Phase[]> {
    try {
      const mockPhases = await GetObjects('Phase', projectColor);
      const phases: Phase[] = mockPhases.map(p => ({
        id: p.id,
        label: p.label,
        projectId: p.parentId || ''
      }));
      this.phases.next(phases);
      return phases;
    } catch (error) {
      console.error('[SessionsService] Error getting phases:', error);
      return [];
    }
  }

  async updateSessionProjectAndPhase(
    sessionId: string, 
    project: Project | null, 
    phase: Phase | null, 
    skipHeaderUpdate: boolean = false
  ): Promise<boolean> {
    // Check if session exists and is active
    const session = this.sessions.value.find(s => s.id === sessionId);
    if (!session || session.status !== SessionStatus.Active) {
      console.warn('[SessionsService] Cannot update session: Session not found or not active');
      return false;
    }

    // Check if session has messages
    const hasMessages = await this.chatService.hasMessages(sessionId);
    if (hasMessages) {
      console.warn('[SessionsService] Cannot update session: Session has messages');
      return false;
    }

    // If project and phase are the same as current, no need to update
    if (this.isSessionMatchingProjectAndPhase(session, project, phase)) {
      console.log('[SessionsService] Session already matches selected project and phase');
      return true;
    }

    // Update session metadata
    const currentSessions = this.sessions.value;
    const updatedSessions = currentSessions.map(s => {
      if (s.id === sessionId) {
        return {
          ...s,
          title: project && phase ? `${project.label} - ${phase.label}` : s.title,
          metadata: {
            ...s.metadata,
            projectId: project?.id,
            phaseId: phase?.id
          }
        };
      }
      return s;
    });
    this.sessions.next(updatedSessions);

    // Update tab reference
    if (project) {
      this.setTabReference(
        sessionId, 
        project.id, 
        phase?.id || '', // Provide empty string as fallback for undefined
        project.color
      );
    }

    // Update selected project/phase if not skipping header update
    if (!skipHeaderUpdate) {
      if (project) {
        this.selectedProject.next(project);
        const phases = await this.getPhases(project.color);
        if (phase && phases.find(p => p.id === phase.id)) {
          this.selectedPhase.next(phase);
        } else {
          this.selectedPhase.next(null);
        }
      } else {
        this.selectedProject.next(null);
        this.selectedPhase.next(null);
      }
    }

    return true;
  }

  private isSessionMatchingProjectAndPhase(
    session: ChatSession,
    project: Project | null,
    phase: Phase | null
  ): boolean {
    const metadata = session.metadata as Record<string, unknown>;
    return metadata['projectId'] === project?.id && metadata['phaseId'] === phase?.id;
  }

  async startSessionWithProject(
    userId: string,
    project: Project,
    phase: Phase | null = null,
    initialMessage?: string
  ): Promise<ChatSession> {
    console.log(`[SessionsService] Starting new session for user ${userId} with project`, { project, phase });
    
    const newSession: ChatSession = {
      id: uuidv4(),
      title: phase ? `${project.label} - ${phase.label}` : project.label,
      status: SessionStatus.Active,
      createdAt: new Date(),
      updatedAt: new Date(),
      participants: {
        users: [userId],
        agents: []
      },
      metadata: {
        projectId: project.id,
        phaseId: phase?.id
      }
    };

    const currentSessions = this.sessions.value;
    this.sessions.next([...currentSessions, newSession]);
    console.log('[SessionsService] New session created:', newSession);

    // Add to opened tabs and make it active
    const openedTabs = this.openedTabs.value;
    this.openedTabs.next([...openedTabs, newSession.id]);
    
    // Update tabs to make the new session active
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => ({ ...tab, active: false }));
    updatedTabs.push({
      id: newSession.id,
      sessionId: newSession.id,
      title: newSession.title,
      active: true,
      type: 'chat'
    });
    this.tabs.next(updatedTabs);
    
    // Set tab reference
    this.setTabReference(newSession.id, project.id, phase?.id || '', project.color);
    
    return newSession;
  }

  async updateSessionTitleOnFirstMessage(sessionId: string, message: string): Promise<void> {
    // Get current session
    const currentSessions = this.sessions.value;
    const session = currentSessions.find(s => s.id === sessionId);
    if (!session) return;

    // Update session title with first few words of message (max 50 chars)
    const title = message.length > 50 ? message.substring(0, 47) + '...' : message;
    
    // Update sessions
    const updatedSessions = currentSessions.map(s => {
      if (s.id === sessionId) {
        return {
          ...s,
          title
        };
      }
      return s;
    });
    this.sessions.next(updatedSessions);

    // Update tabs
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => {
      if (tab.sessionId === sessionId) {
        return {
          ...tab,
          title
        };
      }
      return tab;
    });
    this.tabs.next(updatedTabs);
  }

  // Tab reference management
  setTabReference(sessionId: string, projectId: string, phaseId: string, color: string): void {
    const refs = this.tabReferences.value;
    refs.set(sessionId, { projectId, phaseId, color });
    this.tabReferences.next(refs);

    // Update tab info with new reference
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => {
      if (tab.sessionId === sessionId) {
        const project = this.projects.value.find(p => p.id === projectId);
        const phase = this.phases.value.find(p => p.id === phaseId);
        return {
          ...tab,
          title: phase ? `${project?.label} - ${phase.label}` : project?.label || tab.title,
          reference: { projectId, phaseId, color }
        };
      }
      return tab;
    });
    this.tabs.next(updatedTabs);
  }

  getTabReference(sessionId: string): TabReference | undefined {
    return this.tabReferences.value.get(sessionId);
  }
}