// src/app/services/chat-change-manager.service.ts

import { Injectable } from '@angular/core';
import { ChatSession, ChatTurn } from '../models/chat.model';
import { 
  ProjectManagerSessionMessage, 
  ProjectManagerWorkstream,
  SuggestedSession,
  SuggestedNewSession
} from '../models/project-manager-chat.model';

@Injectable({
  providedIn: 'root'
})
export class ChatChangeManagerService {
  constructor() {}

  /**
   * Updates the change status of a workstream in a specific message
   */
  async updateWorkstreamStatus(
    chatSession: ChatSession,
    messageId: string,
    workstream: ProjectManagerWorkstream,
    newStatus: 'accepted' | 'rejected'
  ): Promise<ChatSession> {
    // Find the relevant message
    const foundMessage = chatSession.turns.find(t => t.id === messageId);
    if (!foundMessage) {
      throw new Error(`Message not found with ID: ${messageId}`);
    }

    // Extract the ProjectManagerSessionMessage
    const pmMessage = this.tryGetProjectManagerData(foundMessage);
    if (!pmMessage) {
      throw new Error('Message has no ProjectManagerSessionMessage data');
    }

    // Find and update the workstream
    const allGroups = pmMessage.suggestedWorkboardNewItems?.groups || [];
    let foundWorkstream: ProjectManagerWorkstream | null = null;

    for (const g of allGroups) {
      for (const c of g.workstreamCols) {
        const existingWs = c.workstreams.find(ws => ws.id === workstream.id);
        if (existingWs) {
          existingWs.changeStatus = newStatus;
          foundWorkstream = existingWs;
          break;
        }
      }
      if (foundWorkstream) break;
    }

    if (!foundWorkstream) {
      throw new Error('Referenced Workstream not found in message data');
    }

    // Update session
    chatSession.updatedAt = new Date();
    return chatSession;
  }

  /**
   * Updates the change status of an existing session suggestion
   */
  async updateExistingSessionStatus(
    chatSession: ChatSession,
    messageId: string,
    suggestedSession: SuggestedSession,
    newStatus: 'accepted' | 'rejected'
  ): Promise<ChatSession> {
    const foundMessage = chatSession.turns.find(t => t.id === messageId);
    if (!foundMessage) {
      throw new Error(`Message not found with ID: ${messageId}`);
    }

    const pmMessage = this.tryGetProjectManagerData(foundMessage);
    if (!pmMessage) {
      throw new Error('Message has no ProjectManagerSessionMessage data');
    }

    // Find and update the existing session
    const allExistingSessions = pmMessage.suggestedExistingSessions || [];
    const matchedSession = allExistingSessions.find(s => s.id === suggestedSession.id);

    if (!matchedSession) {
      throw new Error('Referenced existing session not found in message data');
    }

    matchedSession.changeStatus = newStatus;
    chatSession.updatedAt = new Date();
    
    return chatSession;
  }

  /**
   * Updates the change status of a new session suggestion
   */
  async updateNewSessionStatus(
    chatSession: ChatSession,
    messageId: string,
    suggestedNewSession: SuggestedNewSession,
    newStatus: 'accepted' | 'rejected'
  ): Promise<{ chatSession: ChatSession; sessionIndex: number }> {
    const foundMessage = chatSession.turns.find(t => t.id === messageId);
    if (!foundMessage) {
      throw new Error(`Message not found with ID: ${messageId}`);
    }

    const pmMessage = this.tryGetProjectManagerData(foundMessage);
    if (!pmMessage) {
      throw new Error('Message has no ProjectManagerSessionMessage data');
    }

    // Find and update the new session
    const allNewSessions = pmMessage.suggestedNewSessions || [];
    const sessionIndex = allNewSessions.findIndex(s => s.id === suggestedNewSession.id);

    if (sessionIndex === -1) {
      throw new Error('Referenced new session not found in message data');
    }

    allNewSessions[sessionIndex].changeStatus = newStatus;
    chatSession.updatedAt = new Date();

    return { chatSession, sessionIndex };
  }

  /**
   * Checks if session metadata needs updating based on AI response
   */
  checkSessionMetadataChanges(
    chatSession: ChatSession,
    responseData: ProjectManagerSessionMessage
  ): { 
    hasChanges: boolean;
    newName?: string;
    newDescription?: string;
  } {
    const newSessionName = (responseData.sessionName || '').trim();
    const newSessionDesc = (responseData.sessionDescription || '').trim();
    let hasChanges = false;

    const changes = {
      hasChanges: false,
      newName: undefined as string | undefined,
      newDescription: undefined as string | undefined
    };

    if (newSessionName && newSessionName !== chatSession.title) {
      changes.hasChanges = true;
      changes.newName = newSessionName;
    }

    if (newSessionDesc && newSessionDesc !== (chatSession.description || '')) {
      changes.hasChanges = true;
      changes.newDescription = newSessionDesc;
    }

    return changes;
  }

  /**
   * Utility function to safely extract the ProjectManagerSessionMessage
   * from a ChatTurn's turnMetadata
   */
  private tryGetProjectManagerData(turn: ChatTurn): ProjectManagerSessionMessage | null {
    if (
      turn.turnMetadata &&
      turn.turnMetadata.turnData &&
      turn.turnMetadata.turnData.responseData
    ) {
      return turn.turnMetadata.turnData.responseData as ProjectManagerSessionMessage;
    }
    return null;
  }
}