// src/app/components/project-selection/project-selection.component.ts

import { Component, OnInit, ElementRef, ViewChild, HostListener, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { ProjectManagerService } from '../../services/project-manager.service';
import { WorkspaceItem, WorkspacesData } from '../../models/workspace.interface';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ProjectItem } from '../../models/project.interface';
import { WorkspaceDialogComponent } from './workspace-dialog/workspace-dialog.component';
import { ProjectDialogComponent } from './project-dialog/project-dialog.component';
import { UserWorkspaceState, UserWorkspaceStateUpdate } from '../../models/user-workspace-state.interface';
import { UserService } from '../../services/user.service';
import { WorkspaceManagerService } from '../../services/workspace-manager.service';
import { ContextViewerComponent } from './context-viewer/context-viewer.component';
import { StateItem } from '../../models/workspace-state.model';

@Component({
  selector: 'app-project-selection',
  templateUrl: './project-selection.component.html',
  styleUrls: ['./project-selection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    ContextViewerComponent
  ]
})
export class ProjectSelectionComponent implements OnInit {
  workspacesData$ = new BehaviorSubject<WorkspacesData | null>(null);
  selectedWorkspace: WorkspaceItem | null = null;
  selectedProject: ProjectItem | null = null;
  @Output() workspaceSelected = new EventEmitter<void>();
  @Output() projectSelected = new EventEmitter<void>();
  @Output() menuOpened = new EventEmitter<void>();

  debugMode = false;

  // For context viewer
  showContextPopup = false;
  contextPopupType: 'workspace' | 'project' | null = null;
  contextPopupX = 0;
  contextPopupY = 0;
  contextStateItems: StateItem[] = [];
  @ViewChild('contextPopupRef') contextPopupRef!: ElementRef;

  constructor(
    public projectManager: ProjectManagerService,
    private dialog: MatDialog,
    private userService: UserService,
    private workspaceManager: WorkspaceManagerService
  ) {}

  ngOnInit() {
    // Determine if debug mode is active
    if (typeof window !== 'undefined') {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has('dev') && queryParams.get('dev') !== '0') {
        this.debugMode = true;
      }
    }

    // Subscribe to workspaces data
    this.projectManager.getWorkspacesData().subscribe(data => {
      this.workspacesData$.next(data);
    });

    // Subscribe to user workspace state
    this.projectManager.getUserWorkspaceState().subscribe(state => {
      if (state && this.workspacesData$.value) {
        const workspace = this.workspacesData$.value.workspaces.find(w => w.id === state.selectedWorkspace);
        if (workspace) {
          this.selectedWorkspace = workspace;
          const project = workspace.projects.find(p => p.id === state.selectedProject);
          if (project) {
            this.selectedProject = project;
          }
        }
      } else {
        const data = this.workspacesData$.value;
        if (data && data.workspaces.length > 0) {
          // If no state exists, select the first workspace and project
          const workspace = data.workspaces[0];
          this.selectWorkspace(workspace);
        }
      }
    });
  }

  async selectWorkspace(workspace: WorkspaceItem) {
    this.selectedWorkspace = workspace;

    // Select first project of the workspace by default
    let projectToSelect: ProjectItem | null = null;
    if (workspace.projects?.length > 0) {
      projectToSelect = workspace.projects[0];
      this.selectedProject = projectToSelect;
    } else {
      this.selectedProject = null;
    }

    // Update the user workspace state with only the changed fields
    const stateUpdate: UserWorkspaceStateUpdate = {
      selectedWorkspace: workspace.id,
      selectedProject: projectToSelect?.id || ''
    };
    await this.projectManager.saveUserWorkspaceState(stateUpdate);
    await this.projectManager.selectWorkspace(workspace.id);

    // Emit event to notify that workspace has been selected
    this.workspaceSelected.emit();
  }

  async selectProject(workspace: WorkspaceItem, project: ProjectItem) {
    this.selectedWorkspace = workspace;
    this.selectedProject = project;

    // Update the user workspace state with only the changed fields
    const stateUpdate: UserWorkspaceStateUpdate = {
      selectedWorkspace: workspace.id,
      selectedProject: project.id
    };
    await this.projectManager.saveUserWorkspaceState(stateUpdate);
    await this.projectManager.selectProject(workspace.id, project.id);

    // Emit event to notify that project has been selected
    this.projectSelected.emit();
  }

  private async getUserEmail(): Promise<string> {
    const user = await this.userService.getCurrentUser();
    return user?.email || '';
  }

  async addWorkspace(name: string) {
    await this.projectManager.addWorkspace(name);
    // Select the newly created workspace
    const data = this.workspacesData$.value;
    if (data) {
      const newWorkspace = data.workspaces[data.workspaces.length - 1];
      await this.selectWorkspace(newWorkspace);
    }
  }

  async addProject(workspaceId: string, label: string) {
    await this.projectManager.addProject(workspaceId, label);
    // Select the newly created project
    const workspace = this.workspacesData$.value?.workspaces.find((w: WorkspaceItem) => w.id === workspaceId);
    if (workspace && workspace.projects.length > 0) {
      const newProject = workspace.projects[workspace.projects.length - 1];
      await this.selectProject(workspace, newProject);
    }
  }

  async deleteWorkspace(workspaceId: string) {
    const workspace = this.workspacesData$.value?.workspaces.find((w: WorkspaceItem) => w.id === workspaceId);
    const dialogRef = this.dialog.open(WorkspaceDialogComponent, {
      width: '400px',
      data: {
        title: 'Delete Workspace',
        message: `Are you sure you want to delete workspace ${workspace?.name}?`,
        confirmText: 'Delete',
        itemName: workspace?.name
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (result === true) {
      await this.projectManager.deleteWorkspace(workspaceId);
      if (this.selectedWorkspace?.id === workspaceId) {
        this.selectedWorkspace = null;
        this.selectedProject = null;
      }
    }
  }

  async deleteProject(workspaceId: string, projectId: string) {
    const project = this.selectedWorkspace?.projects.find((p: ProjectItem) => p.id === projectId);
    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      width: '400px',
      data: {
        title: 'Delete Project',
        message: `Are you sure you want to delete project ${project?.label}?`,
        confirmText: 'Delete',
        itemName: project?.label
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (result === true) {
      await this.projectManager.deleteProject(workspaceId, projectId);
      if (this.selectedProject?.id === projectId) {
        this.selectedProject = null;
      }
    }
  }

  async promptNewWorkspace() {
    const dialogRef = this.dialog.open(WorkspaceDialogComponent, {
      width: '400px',
      data: {
        title: 'New Workspace',
        message: 'Enter a name for the new workspace:',
        confirmText: 'Create',
        name: ''
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (result) {
      await this.addWorkspace(result);
    }
  }

  async promptNewProject(workspaceId: string | undefined) {
    if (!workspaceId) return;

    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      width: '400px',
      data: {
        title: 'New Project',
        message: 'Enter a name for the new project:',
        confirmText: 'Create',
        label: ''
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (result) {
      await this.addProject(workspaceId, result);
    }
  }

  getSelectedWorkspaceName(): string {
    return this.selectedWorkspace?.name || 'Select Workspace';
  }

  getSelectedProjectLabel(): string {
    return this.selectedProject?.label || 'Select Project';
  }

  /**
   * Opens the context menu (popup) for either workspace or project.
   */
  openContextMenu(event: MouseEvent, type: 'workspace' | 'project'): void {
    event.stopPropagation();
    this.showContextPopup = true;
    this.contextPopupType = type;
    this.contextPopupX = event.clientX;
    this.contextPopupY = event.clientY;

    if (type === 'workspace' && this.selectedWorkspace) {
      this.contextStateItems = this.getStateItemsFromWorkspace(this.selectedWorkspace);
    } else if (type === 'project' && this.selectedProject) {
      const projectData = this.workspaceManager.getProjectById(this.selectedProject.id);
      this.contextStateItems = projectData ? this.getStateItemsFromProject(projectData) : [];
    }
  }

  closeContextPopup(): void {
    this.showContextPopup = false;
    this.contextPopupType = null;
  }

  onContextItemSelected(item: StateItem) {
    // Perform any logic needed when a state item is clicked
    console.log('Context item selected:', item);
  }

  /**
   * Helper to convert the workspace's stateMap into an array of StateItem
   */
  private getStateItemsFromWorkspace(workspace: WorkspaceItem): StateItem[] {
    const map = workspace.stateMap || {};
    return Object.values(map);
  }

  /**
   * Helper to convert the project's stateMap into an array of StateItem
   */
  private getStateItemsFromProject(projectData: { stateMap: { [key: string]: StateItem } }): StateItem[] {
    const map = projectData.stateMap || {};
    return Object.values(map);
  }

  // Close the context popup if user clicks outside of it
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.showContextPopup && this.contextPopupRef) {
      const popupEl = this.contextPopupRef.nativeElement as HTMLElement;
      if (popupEl && !popupEl.contains(event.target as Node)) {
        this.closeContextPopup();
      }
    }
  }

  // Methods to emit events when workspace or project menus are opened
  onWorkspaceMenuOpened(): void {
    this.menuOpened.emit();
  }

  onProjectMenuOpened(): void {
    this.menuOpened.emit();
  }
}
