// src\app\services\tables-manager.service.ts

import { Injectable } from '@angular/core';
import { TableModel, ColSchema, Insight } from '../models/tables.model';
import { WorkspaceState } from '../models/workspace-state.model';
import { StateManagerService } from './state-manager.service';
import { WorkspaceManagerService } from './workspace-manager.service';

/**
 * Service to manage tables collection in a workspace context.
 * 
 * Uses the StateManagerService to store and retrieve tables data, with tables now
 * formatted as a map object (key-value pairs) rather than an array within a collection.
 * Each table is accessible by its ID directly in the tables object.
 */
@Injectable({
  providedIn: 'root'
})
export class TablesManagerService {

  constructor(
    private sharedState: WorkspaceState,
    private stateManagerService: StateManagerService,
    private workspaceManagerService: WorkspaceManagerService
  ) {}

  /**
   * Retrieve the tables map for the current workspace.
   * - Gets the tables object from stateManagerService
   * - If no collection exists, creates a default, saves, and returns it.
   * 
   * @returns Promise resolving to a map of table IDs to TableModel objects
   */
  public async getTablesCollection(): Promise<Record<string, TableModel>> {
    const rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace;
    if (!rootId) {
      return this.createDefaultTablesCollection();
    }

    const tables = this.stateManagerService.getObject(rootId, 'workspace.tables');
    
    if (!tables) {
      // Create a new default collection
      const newCollection = this.createDefaultTablesCollection();
      await this.updateTablesCollection(newCollection);
      return newCollection;
    }

    return tables;
  }

  /**
   * Force an update to the tables collection for the current workspace.
   * Updates via stateManagerService and persists changes.
   * 
   * @param updatedCollection the new tables collection (map format)
   */
  public async updateTablesCollection(updatedCollection: Record<string, TableModel>): Promise<void> {
    const rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace;
    if (!rootId) {
      return;
    }
    
    await this.stateManagerService.setState(
      rootId, 
      'workstream.workspace', 
      'tables', 
      'Tables', 
      'Workspace tables collection', 
      'Contains metadata about tables available in this workspace', 
      'object', 
      updatedCollection
    );
    
    await this.workspaceManagerService.saveWorkspacesData();
  }

  /**
   * Get a single TableModel by ID from the tables collection for the current workspace.
   * 
   * @param tableId the table ID to fetch
   * @returns TableModel or null if not found
   */
  public async getTableModel(tableId: string): Promise<TableModel | null> {
    const tablesCollection = await this.getTablesCollection();
    return tablesCollection[tableId] || null;
  }

  /**
   * Update (or add if not found) a TableModel in the tables collection for the current workspace.
   * Uses stateManagerService.addToState to update the specific table within the tables map.
   * 
   * @param updatedTable the TableModel to update/add
   * @returns the updated tables collection or null if something failed
   */
  public async updateTableModel(updatedTable: TableModel): Promise<Record<string, TableModel> | null> {
    if (!updatedTable || !updatedTable.id) {
      return null;
    }

    const rootId = this.sharedState.userWorkspaceState$.value?.selectedWorkspace;
    if (!rootId) {
      return null;
    }

    // Use addToState to update just the specific table in the map
    await this.stateManagerService.addToState(
      rootId,
      `workspace.tables.${updatedTable.id}`,
      updatedTable
    );
    
    await this.workspaceManagerService.saveWorkspacesData();
    
    // Return the updated collection
    return this.getTablesCollection();
  }

  /**
   * Add or update a ColSchema in the specified table of the current workspace.
   * 
   * @param tableId the ID of the table to update
   * @param colSchema the column schema to add/update
   * @returns the updated tables collection or null if table not found
   */
  public async addOrUpdateColSchema(
    tableId: string,
    colSchema: ColSchema
  ): Promise<Record<string, TableModel> | null> {
    const table = await this.getTableModel(tableId);
    if (!table) {
      return null;
    }

    // Create a copy of the table to avoid directly modifying the reference
    const updatedTable = { ...table };
    
    // Create a copy of the colSchema array
    updatedTable.colSchema = [...updatedTable.colSchema];
    
    const colIndex = updatedTable.colSchema.findIndex(col => col.name === colSchema.name);
    if (colIndex !== -1) {
      // Update existing
      updatedTable.colSchema[colIndex] = colSchema;
    } else {
      // Add new
      updatedTable.colSchema.push(colSchema);
    }

    // Use updateTableModel to update the specific table
    return this.updateTableModel(updatedTable);
  }

  /**
   * Add or update a table-level Insight in the specified table of the current workspace.
   * 
   * @param tableId the ID of the table
   * @param newInsight the insight to add or update (by matching ID)
   * @returns the updated tables collection or null if table not found
   */
  public async addOrUpdateInsight(
    tableId: string,
    newInsight: Insight
  ): Promise<Record<string, TableModel> | null> {
    const table = await this.getTableModel(tableId);
    if (!table) {
      return null;
    }

    // Create a copy of the table to avoid directly modifying the reference
    const updatedTable = { ...table };
    
    // Create a copy of the insights array
    updatedTable.insights = [...updatedTable.insights];
    
    const insightIndex = updatedTable.insights.findIndex(i => i.id === newInsight.id);
    if (insightIndex !== -1) {
      updatedTable.insights[insightIndex] = newInsight;
    } else {
      updatedTable.insights.push(newInsight);
    }

    // Use updateTableModel to update the specific table
    return this.updateTableModel(updatedTable);
  }

  /**
   * Add or update an insight in a specific column (ColSchema) of a table.
   * 
   * @param tableId the ID of the table
   * @param colName the name of the column to update
   * @param newInsight the insight to add or update (by matching ID)
   * @returns the updated tables collection or null if table or column not found
   */
  public async addOrUpdateInsightInColumn(
    tableId: string,
    colName: string,
    newInsight: Insight
  ): Promise<Record<string, TableModel> | null> {
    const table = await this.getTableModel(tableId);
    if (!table) {
      return null;
    }

    // Create a copy of the table to avoid directly modifying the reference
    const updatedTable = { ...table };
    
    // Create a copy of the colSchema array
    updatedTable.colSchema = [...updatedTable.colSchema];
    
    const colIndex = updatedTable.colSchema.findIndex(c => c.name === colName);
    if (colIndex === -1) {
      return null;
    }

    // Create a copy of the specific column
    updatedTable.colSchema[colIndex] = { 
      ...updatedTable.colSchema[colIndex],
      insights: [...updatedTable.colSchema[colIndex].insights]
    };
    
    const col = updatedTable.colSchema[colIndex];
    const insightIndex = col.insights.findIndex(i => i.id === newInsight.id);
    
    if (insightIndex !== -1) {
      col.insights[insightIndex] = newInsight;
    } else {
      col.insights.push(newInsight);
    }

    // Use updateTableModel to update the specific table
    return this.updateTableModel(updatedTable);
  }

  /**
   * Returns the entire tables collection as a formatted string, iterating over each TableModel
   * and using `getTableModelAsString`.
   * 
   * @param tablesCollection the collection to format
   */
  public getTablesCollectionAsString(tablesCollection: Record<string, TableModel>): string {
    if (!tablesCollection || Object.keys(tablesCollection).length === 0) {
      return 'No tables available.';
    }
    
    return Object.values(tablesCollection)
      .map(table => this.getTableModelAsString(table))
      .join('\n\n');
  }

  /**
   * Returns a single TableModel as a formatted string, including table info, col schema, and sample rows.
   * 
   * @param table the TableModel to format
   */
  public getTableModelAsString(table: TableModel): string {
    const infoStr = `
=== TABLE__INFORMATION ===
id: ${table.id}
name: ${table.name}
tableName: ${table.tableName}
description: ${table.description}

=== COLUMN DETAILS ===
${table.colSchema?table.colSchema.map(col => `${col.name} - ${col.type} - ${col.description}`).join('\n'):''}

${this.getTableRowExamplesAsString(table)}`;
    return infoStr;
  }

  /**
   * Returns only the name, description, and column schema as a formatted string (no sample rows).
   * 
   * @param table the TableModel
   */
  public getTableSchemaAsString(table: TableModel): string {
    const schemaStr = `
=== TABLE__INFORMATION ===
id: ${table.id}
name: ${table.name}
tableName: ${table.tableName}
description: ${table.description}

=== COLUMN__DETAILS ===
${table.colSchema?table.colSchema.map(col => `${col.name} - ${col.type} - ${col.description}`).join('\n'):''}`;
    return schemaStr;
  }

  /**
   * Returns the sample rows of a table as a formatted string (each row on a new line).
   * 
   * @param table the TableModel
   */
  public getTableRowExamplesAsString(table: TableModel): string {
    if (!table.sampleRows || table.sampleRows.length === 0) {
      return '=== SAMPLE ROWS ===\nNo sample rows available.';
    }
    return `=== SAMPLE ROWS ===
${table.sampleRows.join('\n')}`;
  }

  /**
   * Create a default tables collection with one table and sample data.
   * Uses the new map format instead of array-based collection.
   */
  private createDefaultTablesCollection(): Record<string, TableModel> {
    const defaultTableId = 'test-prql-work-data-customers';
    const defaultTable: TableModel = {
      id: defaultTableId,
      name: 'WORK DATA CUSTOMERS',
      tableName: 'TEST.PRQL_WORK_DATA_CUSTOMERS',
      path: '',
      description: 'The table contains records of customer information, including their personal and business details, contact information, and support representative assignment.',
      colSchema: [
        { name: 'CUSTOMER_ID', type: 'TEXT', description: 'Unique identifier for each customer.', insights: [], tags: [], resolveNames: [] },
        { name: 'FIRST_NAME', type: 'TEXT', description: 'The first name of the customer.', insights: [], tags: [], resolveNames: [] },
        { name: 'LAST_NAME', type: 'TEXT', description: 'Last names of customers.', insights: [], tags: [], resolveNames: [] },
        { name: 'COMPANY', type: 'TEXT', description: 'The names of the companies or organizations represented in the data.', insights: [], tags: [], resolveNames: [] },
        { name: 'ADDRESS', type: 'TEXT', description: 'Customer addresses as text strings.', insights: [], tags: [], resolveNames: [] },
        { name: 'CITY', type: 'TEXT', description: 'The names of the cities where customers reside.', insights: [], tags: [], resolveNames: [] },
        { name: 'STATE', type: 'TEXT', description: 'The abbreviated name of the state or province.', insights: [], tags: [], resolveNames: [] },
        { name: 'COUNTRY', type: 'TEXT', description: 'The country of origin for each customer.', insights: [], tags: [], resolveNames: [] },
        { name: 'POSTAL_CODE', type: 'TEXT', description: 'Six-character alphanumeric codes for mail sorting and delivery.', insights: [], tags: [], resolveNames: [] },
        { name: 'PHONE', type: 'TEXT', description: 'Collection of customer phone numbers in various formats.', insights: [], tags: [], resolveNames: [] },
        { name: 'FAX', type: 'TEXT', description: 'Fax numbers, represented as strings.', insights: [], tags: [], resolveNames: [] },
        { name: 'EMAIL', type: 'TEXT', description: 'Email addresses.', insights: [], tags: [], resolveNames: [] },
        { name: 'SUPPORT_REP_ID', type: 'TEXT', description: 'Unique identifier for the support representative.', insights: [], tags: [], resolveNames: [] }
      ],
      sampleRows: [
        '1\tLuís\tGonçalves\tEmbraer - Empresa Brasileira de Aeronáutica S.A.\tAv. Brigadeiro Faria Lima, 2170\tSão José dos Campos\tSP\tBrazil\t12227-000\t55 (12) 3923-5555\t55 (12) 3923-5566\tluisg@embraer.com.br\t3',
        '10\tEduardo\tMartins\tWoodstock Discos\tRua Dr. Falcão Filho, 155\tSão Paulo\tSP\tBrazil\t01007-010\t55 (11) 3033-5446\t55 (11) 3033-4564\teduardo@woodstock.com.br\t4',
        '11\tAlexandre\tRocha\tBanco do Brasil S.A.\tAv. Paulista, 2022\tSão Paulo\tSP\tBrazil\t01310-200\t55 (11) 3055-3278\t55 (11) 3055-8131\talero@uol.com.br\t5',
        '12\tRoberto\tAlmeida\tRiotur\tPraça Pio X, 119\tRio de Janeiro\tRJ\tBrazil\t20040-020\t55 (21) 2271-7000\t55 (21) 2271-7070\troberto.almeida@riotur.gov.br\t3',
        '13\tFernanda\tRamos\tnull\tQe 7 Bloco G\tBrasília\tDF\tBrazil\t71020-677\t55 (61) 3363-5547\t55 (61) 3363-7855\tfernadaramos4@uol.com.br\t4',
        '14\tMark\tPhilips\tTelus\t8210 111 ST NW\tEdmonton\tAB\tCanada\tT6G 2C7\t1 (780) 434-4554\t1 (780) 434-5565\tmphilips12@shaw.ca\t5',
        '15\tJennifer\tPeterson\tRogers Canada\t700 W Pender Street\tVancouver\tBC\tCanada\tV6C 1G8\t1 (604) 688-2255\t1 (604) 688-8756\tjenniferp@rogers.ca\t3',
        '16\tFrank\tHarris\tGoogle Inc.\t1600 Amphitheatre Parkway\tMountain View\tCA\tUSA\t94043-1351\t1 (650) 253-0000\t1 (650) 253-0000\tfharris@google.com\t4',
        '17\tJack\tSmith\tMicrosoft Corporation\t1 Microsoft Way\tRedmond\tWA\tUSA\t98052-8300\t1 (425) 882-8080\t1 (425) 882-8081\tjacksmith@microsoft.com\t5',
        '18\tMichelle\tBrooks\tnull\t627 Broadway\tNew York\tNY\tUSA\t10012-2612\t1 (212) 221-3546\t1 (212) 221-4679\tmichelleb@aol.com\t3'
      ],
      insights: [],
      tags: [],
      resolveNames: []
    };

    // Create a map with the default table keyed by its ID
    // return { [defaultTableId]: defaultTable };
    return {};
  }
}
