import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { TooltipService } from './tooltip.service';

export interface DocumentRenderData {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: 'document';
  element: any; // D3 selection reference
  data: any;
}

export interface DrawConfig {
  width: number;
  height: number;
  margin: number;
  documentWidth: number;
  documentHeight: number;
  documentMargin: number;
  documentCornerCut: number;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentsDrawService {
  private svg: any;
  private tooltip: any;
  private objectsMap: Record<string, DocumentRenderData> = {};
  private customTooltip: any; // Custom tooltip element

  private defaultConfig: DrawConfig = {
    width: 960,
    height: 600,
    margin: 20,
    documentWidth: 80,
    documentHeight: 90,
    documentMargin: 25,
    documentCornerCut: 20
  };

  constructor(
    private tooltipService: TooltipService
  ) {
    // Create a dedicated custom tooltip for documents
    this.customTooltip = this.tooltipService.createTooltip('document');
  }

  /**
   * Initialize the SVG container for drawing
   */
  public setSvg(svg: any): void {
    this.svg = svg;
  }
  
  /**
   * Clear the objects map
   */
  public clear(): void {
    this.objectsMap = {};
  }

  /**
   * Draw documents based on provided data
   */
  public drawDocuments(documents: any, config: Partial<DrawConfig> = {}): void {
    // Clear existing content
    this.clear();

    // Merge provided config with defaults
    const drawConfig = { ...this.defaultConfig, ...config };

    if (!documents || Object.keys(documents).length === 0) {
      return;
    }

    // Create documents container
    const documentsContainer = this.svg.append('g')
      .attr('class', 'documents-container');

    // Draw each document vertically
    Object.values(documents).forEach((doc: any, index: number) => {
      this.drawDocument(documentsContainer, doc, index, drawConfig);
    });
  }

  /**
   * Draw a single document
   */
  private drawDocument(container: any, doc: any, index: number, config: DrawConfig): void {
    // Position documents vertically instead of horizontally
    const x = 15;
    const y = index * (config.documentHeight + config.documentMargin) + 15;

    // Create document group
    const docGroup = container.append('g')
      .attr('class', 'document')
      .attr('transform', `translate(${x},${y})`)
      .on('mouseenter', (event: MouseEvent) => {
        // Show tooltip using the tooltip service
        this.tooltipService.showTooltip(
          this.customTooltip,
          event,
          this.generateDocumentTooltip(doc)
        );
        
        // Highlight the document
        docGroup.select('path')
          .attr('stroke', '#2196f3')
          .attr('stroke-width', 2);
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.customTooltip, event);
      })
      .on('mouseleave', () => {
        // Hide tooltip using the tooltip service
        this.tooltipService.hideTooltip(this.customTooltip);
        
        // Reset document style
        docGroup.select('path')
          .attr('stroke', '#607d8b')
          .attr('stroke-width', 1.5);
      })
      .on('click', (event: MouseEvent) => {
        // Hide tooltip when clicked
        this.tooltipService.hideTooltip(this.customTooltip);
        
        // Copy document data to clipboard
        const documentName = doc.name || 'Unnamed Document';
        
        // Create a formatted string with document information
        let documentInfo = `Document: ${documentName}\n`;
        
        // Add document title if available and different from name
        if (doc.title && doc.title !== doc.name) {
          documentInfo += `Title: ${doc.title}\n`;
        }
        
        // Add extension if available
        const extension = doc.extension || (doc.name && doc.name.split('.').pop()) || '';
        if (extension) {
          documentInfo += `Type: ${extension.toUpperCase()}\n`;
        }
        
        // Add description if available
        if (doc.description) {
          documentInfo += `Description: ${doc.description}\n`;
        }
        
        // Add size information
        if (doc.size !== undefined) {
          documentInfo += `Size: ${this.formatFileSize(doc.size)}\n`;
        }
        
        // Add version information
        if (doc.version !== undefined) {
          documentInfo += `Version: ${doc.version}\n`;
        }
        
        // Add status information
        if (doc.status) {
          documentInfo += `Status: ${doc.status}\n`;
        }
        
        // Add tags if available
        const tags = doc.tags && doc.tags.length ? doc.tags : null;
        if (tags) {
          documentInfo += `Tags: ${tags.join(', ')}\n`;
        }
        
        // Add creation information
        const createdDate = doc.created_date || doc.creationDate;
        if (createdDate) {
          const created = new Date(createdDate).toLocaleDateString();
          documentInfo += `Created: ${created}`;
          if (doc.createdBy) {
            documentInfo += ` by ${doc.createdBy}`;
          }
          documentInfo += '\n';
        }
        
        // Add modification information
        const modifiedDate = doc.modified_date || doc.changeDate;
        if (modifiedDate) {
          const modified = new Date(modifiedDate).toLocaleDateString();
          documentInfo += `Modified: ${modified}`;
          if (doc.changedBy) {
            documentInfo += ` by ${doc.changedBy}`;
          }
          documentInfo += '\n';
        }
        
        // Add author information
        if (doc.author) {
          documentInfo += `Author: ${doc.author}\n`;
        }
        
        // Add path information
        if (doc.path) {
          documentInfo += `Path: ${doc.path}\n`;
        }
        
        // Add ID if available
        if (doc.id) {
          documentInfo += `ID: ${doc.id}`;
        }
        
        // Copy to clipboard
        navigator.clipboard.writeText(documentInfo).then(() => {
          // Show notification
          this.tooltipService.showNotification('Document info copied!', event);
        }).catch(err => {
          console.error('Failed to copy document info: ', err);
        });
        
        // Prevent event bubbling
        event.stopPropagation();
      });

    // Create document shape path (rectangle with cut corner)
    const path = [
      `M 0,0`,
      `L ${config.documentWidth - config.documentCornerCut},0`,
      `L ${config.documentWidth},${config.documentCornerCut}`,
      `L ${config.documentWidth},${config.documentHeight}`,
      `L 0,${config.documentHeight}`,
      'Z'
    ].join(' ');

    // Draw document shape
    const docShape = docGroup.append('path')
      .attr('d', path)
      .attr('fill', '#e3f2fd')
      .attr('stroke', '#607d8b')
      .attr('stroke-width', 1.5);

    // Draw corner fold
    const cornerPath = [
      `M ${config.documentWidth - config.documentCornerCut},0`,
      `L ${config.documentWidth - config.documentCornerCut},${config.documentCornerCut}`,
      `L ${config.documentWidth},${config.documentCornerCut}`
    ].join(' ');

    docGroup.append('path')
      .attr('d', cornerPath)
      .attr('fill', 'none')
      .attr('stroke', '#607d8b')
      .attr('stroke-width', 1);

    // Add document name text (truncated if needed)
    const truncatedName = doc.name.length > 10 ? doc.name.substring(0, 8) + '...' : doc.name;
    docGroup.append('text')
      .attr('x', config.documentWidth / 2)
      .attr('y', config.documentHeight / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', '11px')
      .attr('fill', '#607d8b')
      .text(truncatedName);

    // Add extension text
    docGroup.append('text')
      .attr('x', config.documentWidth / 2)
      .attr('y', config.documentHeight - 15)
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
      .attr('fill', '#607d8b')
      .text(doc.extension.toUpperCase());

    // Store document reference with absolute coordinates
    const containerTransform = container.attr('transform');
    const containerX = containerTransform ? parseInt(containerTransform.split('(')[1].split(',')[0], 10) : 0;
    const containerY = containerTransform ? parseInt(containerTransform.split('(')[1].split(',')[1], 10) : 0;

    this.objectsMap[doc.id.toLowerCase()] = {
      id: doc.id,
      x: x + containerX,
      y: y + containerY,
      width: config.documentWidth,
      height: config.documentHeight,
      type: 'document',
      element: docGroup,
      data: doc
    };
  }

  /**
   * Generate document tooltip content
   */
  private generateDocumentTooltip(doc: any): string {
    // Extract file extension from name if available
    const extension = doc.extension || (doc.name && doc.name.split('.').pop()) || '';
    
    // Get document title (without extension)
    const title = doc.title || (doc.name ? doc.name.replace(`.${extension}`, '') : 'Untitled Document');
    
    // Create header with document title
    let content = this.tooltipService.formatHeader(title, 'document');
    
    // Basic information section
    let basicInfoContent = '';
    
    // Add type/extension information
    if (extension) {
      basicInfoContent += this.tooltipService.formatProperty('Type', extension.toUpperCase());
    }
    
    // Add size information
    if (doc.size !== undefined) {
      basicInfoContent += this.tooltipService.formatProperty('Size', this.formatFileSize(doc.size));
    }
    
    // Add version information
    if (doc.version !== undefined) {
      basicInfoContent += this.tooltipService.formatProperty('Version', doc.version);
    }
    
    // Add status information
    if (doc.status) {
      basicInfoContent += this.tooltipService.formatProperty('Status', doc.status);
    }
    
    // Add basic information section if we have any content
    if (basicInfoContent) {
      content += this.tooltipService.formatSection(basicInfoContent, 'Document Info');
    }
    
    // Description section
    if (doc.description) {
      let descriptionContent = `<div style="padding-left: 2px;">${doc.description}</div>`;
      content += this.tooltipService.formatSection(descriptionContent, 'Description');
    } else {
      // Default description
      let descriptionContent = `<div style="padding-left: 2px;">Comprehensive guide on best practices for Google Analytics 4 implementation, data collection, and analysis</div>`;
      content += this.tooltipService.formatSection(descriptionContent, 'Description');
    }
    
    // Tags section
    const defaultTags = ['analytics', 'implementation', 'best-practices', 'GA4', 'tracking'];
    const tags = doc.tags && doc.tags.length ? doc.tags : defaultTags;
    
    let tagsContent = '';
    
    // Format tags as badges
    const tagsHtml = tags.map((tag: string) => 
      `<span style="display: inline-block; background-color: #2a2b2d; border-radius: 0px; padding: 2px 6px; margin-right: 5px; margin-bottom: 5px; font-size: 12px;">${tag}</span>`
    ).join('');
    
    tagsContent += `<div style="padding-left: 2px;">${tagsHtml}</div>`;
    
    // Add tags section
    content += this.tooltipService.formatSection(tagsContent, 'Tags');
    
    // Metadata section
    let metadataContent = '';
    
    // Created info
    const createdDate = doc.created_date || doc.creationDate || '2024-02-10';
    const created = new Date(createdDate).toLocaleDateString();
    metadataContent += this.tooltipService.formatProperty('Created', `${created}${doc.createdBy ? ' by ' + doc.createdBy : ' by sarah@asky.dev'}`);
    
    // Modified info
    if (doc.modified_date || doc.changeDate) {
      const modifiedDate = doc.modified_date || doc.changeDate;
      const modified = new Date(modifiedDate).toLocaleDateString();
      metadataContent += this.tooltipService.formatProperty('Modified', `${modified}${doc.changedBy ? ' by ' + doc.changedBy : ' by michael@asky.dev'}`);
    }
    
    // Add author information
    if (doc.author) {
      metadataContent += this.tooltipService.formatProperty('Author', doc.author);
    }
    
    // Add metadata section if we have any content
    if (metadataContent) {
      content += this.tooltipService.formatSection(metadataContent, 'Metadata');
    }
    
    // Path section
    if (doc.path) {
      let pathContent = `<div style="padding-left: 2px; font-family: monospace; font-size: 12px; word-break: break-all; color: #90a4ae;">${doc.path}</div>`;
      content += this.tooltipService.formatSection(pathContent, 'Path');
    } else {
      // Default path
      let pathContent = `<div style="padding-left: 2px; font-family: monospace; font-size: 12px; word-break: break-all; color: #90a4ae;">/marketing/reference/</div>`;
      content += this.tooltipService.formatSection(pathContent, 'Path');
    }
    
    // Preview section
    if (doc.preview) {
      let previewContent = `<div style="background-color: #2a2b2d; padding: 8px; border-radius: 0px; font-family: monospace; font-size: 12px; max-height: 120px; overflow-y: auto; white-space: pre-wrap;">${doc.preview}</div>`;
      content += this.tooltipService.formatSection(previewContent, 'Preview');
    } else {
      // Default preview
      const defaultPreview = `Term,Definition,Category,Usage Example
CTR,Click-Through Rate: Number of clicks divided by impressions,Performance,3.5% CTR is typical for search ads
Conversion,Completed goal action by user,Metrics,Purchase, signup, or form submission`;
      
      let previewContent = `<div style="background-color: #2a2b2d; padding: 8px; border-radius: 0px; font-family: monospace; font-size: 12px; max-height: 120px; overflow-y: auto; white-space: pre-wrap;">${defaultPreview}</div>`;
      content += this.tooltipService.formatSection(previewContent, 'Preview');
    }
    
    // Add footer with ID if available
    if (doc.id) {
      content += this.tooltipService.formatFooter(`ID: ${doc.id}`);
    }
    
    return content;
  }
  
  /**
   * Format file size in a human-readable format
   */
  private formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  /**
   * Get all rendered objects
   */
  public getAllObjects(): Record<string, DocumentRenderData> {
    return this.objectsMap;
  }

  /**
   * Highlight a document
   */
  public highlightObject(id: string, options: { color?: string; duration?: number } = {}): void {
    const obj = this.objectsMap[id];
    if (!obj) return;

    const color = options.color || '#2196f3';
    const duration = options.duration || 2000;

    obj.element.select('path')
      .transition()
      .duration(200)
      .attr('stroke', color)
      .attr('stroke-width', 2)
      .transition()
      .delay(duration)
      .duration(200)
      .attr('stroke', '#607d8b')
      .attr('stroke-width', 1.5);
  }
}