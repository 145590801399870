// src/environments/environment.ts
export const environment = {
  production: false,
  artifactsApiUrl: window.location.hostname === 'localhost' ? 'http://localhost:7071/api' : 'https://thejam.azurewebsites.net/api',
  organizationId: 'asky',
  workspaceContainerRef: 'asky____asky___artifacts____artifacts',
  documentsContainerRef: 'asky____asky___documents____artifacts',
  keyCode: '?code=-8NG8CPNjhn7pKRNY92HbkCFTjc8lQZ-TRaFZS4Px9qnAzFuuYfnMA%3D%3D',
  rootWorkspaceName: 'workspaces-asky'
};
