<!--
  @file src/app/components/templates/templates.component.html
  @description Template for the templates component
-->

<!-- Navigation and content container -->
<div class="templates-container">
  <!-- Left sidebar -->
  <div class="sidebar">
    <!-- Search box -->
    <div class="search-box">
      <div class="search-input-container">
        <svg class="search-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        <input type="text" 
               placeholder="Search templates..." 
               [(ngModel)]="searchQuery" 
               (ngModelChange)="searchQuery = $event" />
      </div>
    </div>

    <!-- Categories -->
    <div class="categories">
      <div class="category-group">
        <div class="category-item" [class.active]="selectedCategory === 'all'" (click)="selectCategory('all')">
          All Guides
        </div>
        <div class="category-item" [class.active]="selectedCategory === 'recent'" (click)="selectCategory('recent')">
          Recently Viewed
        </div>
      </div>

      <!-- Created by Ask-y section -->
      <div class="category-group">
        <div class="category-label">Created By Ask-y</div>
        <ng-container *ngIf="selectedTab === 'solutions' && (solutionData$ | async) as solutionData">
          <div *ngFor="let category of solutionData.categories" 
               class="category-item"
               [class.active]="selectedCategory === category.id"
               (click)="selectCategory(category.id)">
            {{ category.name }}
          </div>
        </ng-container>
        <ng-container *ngIf="selectedTab === 'tasks' && (menuData$ | async) as menuData">
          <div *ngFor="let category of menuData.categories"
               class="category-item"
               [class.active]="selectedCategory === category.name"
               (click)="selectCategory(category.name)">
            {{ category.name }}
          </div>
        </ng-container>
      </div>

      <!-- Custom Templates section -->
      <div class="category-group">
        <div class="category-label">Custom Templates</div>
        <div class="category-item">Organization</div>
        <div class="category-item">Made By You</div>
      </div>
    </div>
  </div>

  <!-- Main content -->
  <div class="main-content">
    <!-- Tabs -->
    <div class="tabs">
      <div class="tab" [class.active]="selectedTab === 'solutions'" (click)="selectTab('solutions')">
        Guided Solutions
      </div>
      <div class="tab" [class.active]="selectedTab === 'tasks'" (click)="selectTab('tasks')">
        Guided Tasks
      </div>
    </div>

    <!-- Title with count -->
    <div class="section-title">
      <ng-container *ngIf="selectedTab === 'solutions' && (solutionData$ | async) as solutionData">
        {{ selectedCategory === 'all' ? 'All Solutions' : 
           selectedCategory === 'recent' ? 'Recently Viewed' : 
           (solutionData.categories | find:selectedCategory:'id')?.name }} 
        ({{ getFilteredSolutions(solutionData).length }})
      </ng-container>
      <ng-container *ngIf="selectedTab === 'tasks' && (menuData$ | async) as menuData">
        {{ selectedCategory === 'all' ? 'All Tasks' : 
           selectedCategory === 'recent' ? 'Recently Viewed' : 
           (menuData.categories | find:selectedCategory:'name')?.name }}
        ({{ getFilteredTasks(menuData).length }})
      </ng-container>
    </div>

    <!-- Solutions/Tasks Grid -->
    <div class="templates-grid">
      <ng-container *ngIf="selectedTab === 'solutions' && (solutionData$ | async) as solutionData">
        <div *ngFor="let solution of getFilteredSolutions(solutionData)" 
             class="template-card" 
             (click)="openModal(solution)">
          <div class="card-content">
            <h3>{{ solution.name }}</h3>
            <p class="goal">{{ solution.goal }}</p>
            <div class="preview-image" [style.backgroundImage]="'url(' + solution.previewurl + ')'"></div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedTab === 'tasks' && (menuData$ | async) as menuData">
        <div *ngFor="let item of getFilteredTasks(menuData)" 
             class="template-card" 
             (click)="openModal(item)">
          <div class="card-content">
            <h3>{{ item.name }}</h3>
            <div class="preview-image" [style.backgroundImage]="'url(' + item.svg + ')'"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal-overlay" *ngIf="selectedSolution || selectedTask" (click)="closeModal()">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <!-- Close button -->
    <button class="close-button" (click)="closeModal()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </button>

    <!-- Content container -->
    <div class="modal-container">
      <!-- Left side - Details -->
      <div class="modal-details">
        <!-- Solution Content -->
        <ng-container *ngIf="selectedTab === 'solutions' && selectedSolution">
          <h2>{{ selectedSolution.name }}</h2>
          
          <!-- Goal section -->
          <div class="section">
            <h3>Goal</h3>
            <p>{{ selectedSolution.goal }}</p>
          </div>

          <!-- Description section -->
          <div class="section">
            <h3>Description</h3>
            <p>{{ selectedSolution.description }}</p>
          </div>

          <!-- Components section -->
          <div class="section">
            <h3>Components</h3>
            <div class="components-list">
              <div class="component-item" *ngFor="let component of selectedSolution.components">
                <!-- Component type icon -->
                <div class="component-icon" [ngClass]="component.type">
                  <svg *ngIf="component.type === 'project'" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                  </svg>
                  <svg *ngIf="component.type === 'connection'" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                  </svg>
                  <svg *ngIf="component.type === 'model'" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                  </svg>
                  <svg *ngIf="component.type === 'dashboard'" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="3" y1="9" x2="21" y2="9"></line>
                    <line x1="9" y1="21" x2="9" y2="9"></line>
                  </svg>
                </div>
                <div class="component-details">
                  <h4>{{ component.name }}</h4>
                  <p>{{ component.description }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Key Approaches section -->
          <div class="section">
            <h3>Key Approaches</h3>
            <ul class="key-approaches">
              <li *ngFor="let approach of selectedSolution.keyApproaches">{{ approach }}</li>
            </ul>
          </div>
        </ng-container>

        <!-- Task Content -->
        <ng-container *ngIf="selectedTab === 'tasks' && selectedTask">
          <h2>{{ selectedTask.name }}</h2>
          
          <!-- Description section -->
          <div class="section">
            <h3>Description</h3>
            <p>{{ selectedTask.firstMessage }}</p>
          </div>

          <!-- Playbook section -->
          <div class="section" *ngIf="selectedTask.playbookId">
            <h3>Playbook ID</h3>
            <p>{{ selectedTask.playbookId }}</p>
          </div>
        </ng-container>
      </div>

      <!-- Right side - Preview -->
      <div class="modal-preview">
        <div class="preview-image" [style.backgroundImage]="'url(' + (selectedTab === 'solutions' ? selectedSolution?.previewurl : selectedTask?.svg) + ')'"></div>
      </div>
    </div>

    <!-- Button container -->
    <div class="modal-footer">
      <button class="create-button">{{ selectedTab === 'solutions' ? 'Create Solution' : 'Create Task' }}</button>
    </div>
  </div>
</div> 