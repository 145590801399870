// src/app/components/header/header.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';

import { UserService } from '../../services/user.service';
import { User } from '../../models/user.interface';
import { ProjectSelectionComponent } from '../project-selection/project-selection.component';
import { RecordsComponent } from '../records/records.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { NotificationsService } from '../../services/notifications.service';
import { NotificationsState } from '../../models/notification.model';
import { LibraryMenuComponent } from '../library-menu/library-menu.component';
import { ActivityLogComponent } from '../activity-log/activity-log.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    ProjectSelectionComponent,
    RecordsComponent,
    NotificationsComponent,
    LibraryMenuComponent,
    ActivityLogComponent
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User | null = null;
  showRecordsPane: boolean = false;
  isRecordsPaneExpanded: boolean = true;
  recordsPaneWidth: string = 'calc(100% - 270px)';
  unreadNotificationsCount: number = 0;
  
  private userSubscription?: Subscription;
  private notificationsSubscription?: Subscription;

  constructor(
    private authService: MsalService,
    private userService: UserService,
    private notificationsService: NotificationsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.userService.waitForTokenReady();

    this.userSubscription = this.userService.user$.subscribe(
      (userData) => {
        this.user = userData;
      }
    );
    
    // Subscribe to notifications state to get unread count
    this.notificationsSubscription = this.notificationsService.notifications$.subscribe(
      (state: NotificationsState) => {
        this.unreadNotificationsCount = state.unreadCount;
      }
    );

    // Add click event listeners to close records pane when project selection menus are clicked
    setTimeout(() => {
      // Get all elements that could open menus
      const menuTriggers = document.querySelectorAll('.middle-section .nav-item, .middle-section .workspace-name, .middle-section .project-name, .middle-section .more-button');
      
      menuTriggers.forEach(element => {
        element.addEventListener('click', () => {
          console.log('Menu trigger clicked:', element);
          this.closeRecordsPane();
        });
      });
    }, 1000);
  }

  getUserInitials(fullName: string | undefined): string {
    if (!fullName) {
      return '';
    }
    const parts = fullName.trim().split(' ');
    const initials = parts.map(part => part[0]).join('').toUpperCase();
    return initials;
  }

  toggleRecordsPane(): void {
    this.showRecordsPane = !this.showRecordsPane;
    
    // If opening records pane, close notifications pane
    if (this.showRecordsPane) {
      this.notificationsService.closeNotificationsPane();
      // Start in expanded state when opening
      this.isRecordsPaneExpanded = true;
    }
  }

  closeRecordsPane(): void {
    this.showRecordsPane = false;
  }
  
  toggleRecordsPaneExpansion(): void {
    this.isRecordsPaneExpanded = !this.isRecordsPaneExpanded;
    
    if (!this.isRecordsPaneExpanded) {
      // When collapsing, find the vertical splitter container
      const verticalSplitters = document.querySelectorAll('.splitter-container.vertical');
      if (verticalSplitters.length > 0) {
        // Get the rightmost vertical splitter
        const rightmostSplitter = verticalSplitters[verticalSplitters.length - 1] as HTMLElement;
        if (rightmostSplitter) {
          // Find the width of the pane in the vertical splitter
          const panes = rightmostSplitter.querySelectorAll('app-splitter-pane');
          if (panes.length > 0) {
            // Get the width of the rightmost pane
            const rightPane = panes[panes.length - 1] as HTMLElement;
            const style = window.getComputedStyle(rightPane);
            const width = style.width;
            
            if (width) {
              // Use the width of the rightmost pane
              this.recordsPaneWidth = width;
            } else {
              // Fallback: use a percentage that matches the screenshot
              this.recordsPaneWidth = '25%';
            }
          } else {
            this.recordsPaneWidth = '25%';
          }
        } else {
          this.recordsPaneWidth = '25%';
        }
      } else {
        // If we can't find vertical splitters, try to find the first content wrapper
        const contentWrapper = document.querySelector('.content-wrapper') as HTMLElement;
        if (contentWrapper) {
          const style = window.getComputedStyle(contentWrapper);
          const width = style.width;
          if (width) {
            this.recordsPaneWidth = width;
          } else {
            this.recordsPaneWidth = '25%';
          }
        } else {
          // Fallback to a reasonable default if we can't find any splitters
          this.recordsPaneWidth = '25%';
        }
      }
    }
  }

  toggleNotificationsPane(): void {
    // Close records pane if open
    if (this.showRecordsPane) {
      this.showRecordsPane = false;
    }
    
    this.notificationsService.toggleNotificationsPane();
  }

  logout(): void {
    this.userService.clearUser();
    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.notificationsSubscription?.unsubscribe();
  }
} 