<div class="session-item" [class.expanded]="isExpanded">
  <div class="session-header">
    <div class="session-info">
      <div class="session-title">
        <div class="folder-container">
          <div class="folder-tab"></div>
          <div class="folder-body">
            <mat-icon class="folder-icon">{{group.records[0].icon || 'folder'}}</mat-icon>
          </div>
        </div>
        {{group.title}}
      </div>
      <p *ngIf="isExpanded" class="description">{{group.description}}</p>
      <div class="session-meta">
        <span class="date">{{group.date}}</span>
        <div class="indicators">
          <mat-icon *ngFor="let record of group.records" 
                   class="indicator-icon"
                   [matTooltip]="record.name"
                   [matTooltipPosition]="'above'"
                   #recordIcon
                   appHoverContainer
                   (hover)="onHoverEvent($event, record, recordIcon._elementRef.nativeElement)"
                   style="width: 20px; height: 20px; font-size: 20px;">
            {{record.icon}}
          </mat-icon>
        </div>
      </div>
    </div>

    <button mat-icon-button class="pin-button" (click)="onPinToggle.emit(group.id)">
      <mat-icon style="width: 20px; height: 20px; font-size: 20px;">
        {{group.isPinned ? 'push_pin' : 'push_pin_outlined'}}
      </mat-icon>
    </button>

    <button mat-icon-button class="expand-button" (click)="toggleExpand()">
      <mat-icon style="width: 20px; height: 20px; font-size: 20px;">
        {{isExpanded ? 'expand_less' : 'expand_more'}}
      </mat-icon>
    </button>
  </div>

  <app-record-preview
    *ngIf="hoveredRecord && hoveredElement"
    [record]="hoveredRecord"
    [triggerElement]="hoveredElement"
    [mousePosition]="mousePosition"
    appHoverContainer
    (hover)="onPreviewHoverEvent($event)">
  </app-record-preview>

  <div class="session-records" *ngIf="isExpanded">
    <div class="tags">
      <mat-chip-listbox>
        <mat-chip *ngFor="let tag of group.tags" 
                 class="tag-chip">
          <mat-icon class="folder-icon">folder</mat-icon>
          /{{tag.label}}
        </mat-chip>
      </mat-chip-listbox>
    </div>

    <div class="record-item" *ngFor="let record of group.records | slice:0:3"
         appHoverContainer
         #recordItem
         (hover)="onHoverEvent($event, record, recordItem)">
      <mat-icon class="record-type-icon">{{record.icon}}</mat-icon>
      <span>{{record.name}}</span>
    </div>

    <div class="records-count-seemore" *ngIf="group.records.length > 3">See all {{group.records.length}} records</div>
    <div class="records-count" *ngIf="group.records.length <= 3">{{group.records.length}} Records</div>

    <!--
    <div class="recommended-flows" *ngIf="group.recommendedFlows?.length">
      <div class="flow-item" *ngFor="let flow of group.recommendedFlows">
        <mat-icon class="flow-icon">arrow_forward</mat-icon>
        <span class="flow-name">{{flow.name}}</span>
      </div>
    </div>
-->

  </div>
</div>