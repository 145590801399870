import { Injectable, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { MemoryItem } from '../../models/memory-item.model';
import { ElementsLinkService } from './elements-link.service';
import { MatDialog } from '@angular/material/dialog';
import { TooltipService } from './tooltip.service';

// Extended memory item with position for d3 simulation
interface MemoryItemWithPosition extends MemoryItem, d3.SimulationNodeDatum {
  x?: number;
  y?: number;
  title?: string;
}

export interface MemoryRenderData {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: 'memory';
  element: any; // D3 selection reference
  data: MemoryItem;
}

export interface MemoryDrawConfig {
  width: number;
  height: number;
  margin: number;
  memoryRadius: number;
  memoryMargin: number;
  memoryTypeColorMap: {[key: string]: string};
  centerX?: number;
  yOffset?: number; // Added yOffset for positioning
  showTitle?: boolean; // Whether to show the title
  titleText?: string; // Title text
}

@Injectable({
  providedIn: 'root'
})
export class MemoriesDrawService {
  private svg: d3.Selection<SVGGElement, unknown, null, undefined> | null = null;
  private tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any> | null = null;
  private memoryTooltip: any; // Dedicated tooltip for memories
  public objectsMap: { [id: string]: MemoryRenderData } = {};
  // Track the currently focused memory
  public focusedMemoryId: string | null = null;

  // Event emitter for memory click
  public memoryClicked = new EventEmitter<{memory: MemoryItem, relatedRecords: any[], isSameMemory: boolean}>();
  
  // Default configuration for memory drawing
  private defaultConfig: MemoryDrawConfig = {
    width: 800,
    height: 170,
    margin: 10,
    memoryRadius: 22,
    memoryMargin: 5,
    memoryTypeColorMap: {
      'data_issue': '#607d8b',      // Blue-gray for data issues
      'model_evaluation': '#5c6bc0', // Indigo for model evaluations
      'user_feedback': '#26a69a',    // Teal for user feedback
      'system_alert': '#ef5350',     // Light red for system alerts
      'performance_metric': '#66bb6a', // Green for performance metrics
      'default': '#90a4ae'           // Light blue-gray for default
    }
  };

  constructor(
    private elementsLinkService: ElementsLinkService,
    private dialog: MatDialog,
    private tooltipService: TooltipService
  ) {
    // Create a dedicated tooltip for memories
    this.memoryTooltip = this.tooltipService.createTooltip('memory');
  }

  /**
   * Set the SVG and tooltip elements
   */
  public setSvg(svg: any): void {
    this.svg = svg;
  }

  /**
   * Clear the objects map
   */
  public clear(): void {
    this.objectsMap = {};

  }

  /**
   * Get all rendered memory objects
   */
  public getAllObjects(): Record<string, MemoryRenderData> {
    return this.objectsMap;
  }

  /**
   * Draw memories based on provided data
   */
  public drawMemories(memories: MemoryItem[], config: Partial<MemoryDrawConfig> = {}): void {
    
    // Clear existing content
    this.clear();

    // Merge with default config - ensure all properties have values
    const mergedConfig: MemoryDrawConfig = { ...this.defaultConfig, ...config };

    if (!memories || memories.length === 0) {
      return;
    }

    if (!this.svg) {
      console.error('SVG not initialized');
      return;
    }

    // Create a container for memories
    const memoriesContainer = this.svg.append('g')
      .attr('class', 'memories-container')
      .attr('transform', `translate(0, ${mergedConfig.yOffset || 0})`);

    // Add title if needed
    if (mergedConfig.showTitle && mergedConfig.titleText) {
      memoriesContainer.append('text')
        .attr('x', mergedConfig.centerX || mergedConfig.width / 2)
        .attr('y', 0)
        .attr('text-anchor', 'middle')
        .attr('font-size', '16px')
        .attr('font-weight', 'bold')
        .attr('fill', '#DB4437') // Red color for memories
        .text(mergedConfig.titleText);

      // Adjust the starting y position to account for the title
      const titleOffset = 30;
      if (!mergedConfig.yOffset) {
        mergedConfig.yOffset = titleOffset;
      } else {
        mergedConfig.yOffset += titleOffset;
      }
    }

    // Create a group for memory items
    const memoriesItemsContainer = memoriesContainer.append('g')
      .attr('class', 'memories-items')
      .attr('transform', `translate(${mergedConfig.margin}, ${mergedConfig.margin})`);

    // Position the group based on yOffset if provided
    if (mergedConfig.yOffset) {
      memoriesItemsContainer.attr('transform', `translate(${mergedConfig.margin}, ${mergedConfig.yOffset + mergedConfig.margin})`);
    }

    // Convert memories to nodes for D3 simulation
    const memoriesWithPosition: MemoryItemWithPosition[] = memories.map(memory => ({
      ...memory
    }));

    // Arrange memories in a horizontal row instead of using force simulation
    const memoryCount = memoriesWithPosition.length;
    const memorySpacing = 100; // Space between memory centers
    
    // Position each memory in a horizontal row, starting from the left
    memoriesWithPosition.forEach((memory, index) => {
      memory.x = 40 + (index * memorySpacing); // Start from left with 40px initial offset
      memory.y = 30; // Reduced Y position to be closer to title
    });
    
    // Draw each memory with its assigned position
    memoriesWithPosition.forEach(memory => {
      this.drawMemory(memory, memoriesItemsContainer, mergedConfig);
    });
    
    // Check if there's a memory that needs to be kept highlighted
    if (this.focusedMemoryId) {
      // Use a short timeout to ensure all transitions have completed
      setTimeout(() => {
        this.keepMemoryHighlighted(this.focusedMemoryId!);
      }, 100);
    }
  }

  /**
   * Draw a single memory item
   */
  private drawMemory(memory: MemoryItemWithPosition, container: d3.Selection<SVGGElement, unknown, null, undefined>, config: MemoryDrawConfig): void {
    if (!memory.x || !memory.y) {
      return;
    }

    const x = memory.x;
    const y = memory.y;

    // Create a group for the memory
    const memoryGroup = container.append('g')
      .attr('class', 'memory')
      .attr('transform', `translate(${x},${y})`);

    // Get color based on memory type
    const color = config.memoryTypeColorMap[memory.type] || config.memoryTypeColorMap['default'];
    
    // Ensure we have a valid color to prevent black fallback
    const validColor = color || '#90a4ae'; // Use light blue-gray as a fallback if color is undefined

    // Create hexagon points
    const hexPoints = this.createHexagonPoints(config.memoryRadius);

    // Add hexagon with gradient fill for more visual appeal
    const gradientId = `memory-gradient-${Math.random().toString(36).substring(2, 9)}`;
    
    // Create gradient definition
    const defs = this.svg?.append('defs');
    const gradient = defs?.append('radialGradient')
      .attr('id', gradientId)
      .attr('cx', '50%')
      .attr('cy', '50%')
      .attr('r', '50%')
      .attr('fx', '50%')
      .attr('fy', '50%');
      
    gradient?.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', d3.color(validColor)?.brighter(0.5)?.toString() || validColor);
      
    gradient?.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', validColor);

    // Add hexagon without shadow
    memoryGroup.append('path')
      .attr('d', d3.line()(hexPoints))
      .attr('fill', `url(#${gradientId})`)
      .attr('stroke', d3.color(validColor)?.darker(0.5)?.toString() || validColor)
      .attr('stroke-width', 1.5);



    // Add memory type icon in the center - removed note symbols
    const iconMap: {[key: string]: string} = {
      'data_issue': '',
      'model_evaluation': '',
      'default': ''
    };
    
    const icon = iconMap[memory.type] || iconMap['default'];
    
    memoryGroup.append('text')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr('font-size', config.memoryRadius * 0.8)
      .attr('fill', 'white')
      .attr('font-weight', 'bold')
      .text(icon);
      
    // Add importance indicator as small number
    if (memory.importance) {
      memoryGroup.append('circle')
        .attr('cx', config.memoryRadius * 0.8)
        .attr('cy', -config.memoryRadius * 0.8)
        .attr('r', config.memoryRadius * 0.4)
        .attr('fill', memory.importance >= 8 ? '#ff4500' : 
               memory.importance >= 5 ? '#ff9800' : '#aaa')
        .attr('stroke', 'white')
        .attr('stroke-width', 1);
        
      memoryGroup.append('text')
        .attr('x', config.memoryRadius * 0.8)
        .attr('y', -config.memoryRadius * 0.8)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'central')
        .attr('font-size', config.memoryRadius * 0.5)
        .attr('fill', 'white')
        .attr('font-weight', 'bold')
        .text(memory.importance);
    }

    // Add memory ID label below the hexagon
    memoryGroup.append('text')
      .attr('y', config.memoryRadius + 12)
      .attr('text-anchor', 'middle')
      .attr('font-size', '10px')
      .attr('fill', '#333')
      .attr('font-weight', 'bold')
      .text(`ID: ${memory.memory_id.substring(0, 8)}...`);
      
    // Make memory interactive
    memoryGroup
      .style('cursor', 'pointer')
      .on('mouseover', (event: MouseEvent) => {
        memoryGroup.select('path')
          .transition()
          .duration(200)
          .attr('d', d3.line()(this.createHexagonPoints(config.memoryRadius * 1.1)));
          
        // Show tooltip using the tooltip service
        this.tooltipService.showTooltip(
          this.memoryTooltip,
          event,
          this.generateMemoryTooltip(memory)
        );
      })
      .on('mousemove', (event: MouseEvent) => {
        // Move tooltip with mouse
        this.tooltipService.moveTooltip(this.memoryTooltip, event);
      })
      .on('mouseout', () => {
        memoryGroup.select('path')
          .transition()
          .duration(200)
          .attr('d', d3.line()(this.createHexagonPoints(config.memoryRadius)));
          
        // Hide tooltip using the tooltip service
        this.tooltipService.hideTooltip(this.memoryTooltip);
      })
      .on('click', (event: MouseEvent) => {
        // Hide tooltip when memory is clicked
        this.tooltipService.hideTooltip(this.memoryTooltip);
        
        // Handle memory click event
        this.handleMemoryClick(memory);
      });

    // Generate a unique ID for the memory
    const memoryId = this.getMemoryId(memory);

    // Store memory reference
    const containerTransform = container.attr('transform');
    const containerX = containerTransform ? parseInt(containerTransform.split(',')[0].replace('translate(', ''), 10) : 0;
    const containerY = containerTransform ? parseInt(containerTransform.split(',')[1], 10) : 0;

    this.objectsMap[memoryId] = {
      id: memoryId,
      x: x + containerX,
      y: y + containerY,
      width: config.memoryRadius * 2,
      height: config.memoryRadius * 2,
      type: 'memory',
      element: memoryGroup,
      data: memory
    };
  }

  /**
   * Handle memory click event
   */
  private handleMemoryClick(memory: MemoryItem): void {
    // Generate the unique ID for this memory
    const uniqueMemoryId = this.getMemoryId(memory);
    
    // Toggle focus state - using uniqueMemoryId instead of memory.memory_id
    if (this.focusedMemoryId === uniqueMemoryId) {
      this.clearMemoryFocus();
      return;
    }
    
    // Set focus on this memory using the unique ID
    this.focusMemory(uniqueMemoryId);
    
    // Find related records
    const relatedRecords = this.findRelatedRecords(memory);
    
    // Emit the memory clicked event
    this.memoryClicked.emit({
      memory: memory,
      relatedRecords: relatedRecords,
      isSameMemory: false
    });
  }

  /**
   * Focus on a specific memory
   */
  public focusMemory(uniqueMemoryId: string): void {
    // Clear any existing focus if it's not the same memory
    if (this.focusedMemoryId && this.focusedMemoryId !== uniqueMemoryId) {
      this.clearMemoryFocus();
    }
    
    this.focusedMemoryId = uniqueMemoryId;
    
    // Dim all memories except the focused one
    Object.keys(this.objectsMap).forEach(id => {
      const obj = this.objectsMap[id];
      if (obj.type === 'memory') {
        if (id === uniqueMemoryId) {
          // Highlight the focused memory
          obj.element.transition()
            .duration(300)
            .style('opacity', 1);
          
          // Increase size
          obj.element.select('path')
            .transition()
            .duration(300)
            .attr('d', d3.line()(this.createHexagonPoints(obj.width)));
        } else {
          // Dim other memories
          obj.element.transition()
            .duration(300)
            .style('opacity', 0.3);
        }
      }
    });
    
    // Get the memory data
    const memoryData = this.objectsMap[uniqueMemoryId]?.data;
    
    // Find related records for the memory
    const relatedRecords = memoryData ? this.findRelatedRecords(memoryData) : [];
    
    // Emit the memory clicked event with the memory data
    // Set isSameMemory to false since this is a direct focus, not a toggle
    this.memoryClicked.emit({ 
      memory: memoryData,
      relatedRecords: relatedRecords,
      isSameMemory: false
    });
  }

  /**
   * Toggle focus on a specific memory
   */
  public toggleMemoryFocus(memoryId: string): void {
    // Get the memory data
    const memoryData = this.objectsMap[memoryId]?.data;
    if (!memoryData) return;
    
    // Check if this is the same memory that's already focused (for toggle behavior)
    const isSameMemory = this.focusedMemoryId === memoryId;
    
    // If this is the same memory, we'll clear the focus (handled in RecordsComponent)
    // Otherwise, set the new focused memory
    if (!isSameMemory) {
      this.focusedMemoryId = memoryId;
      
      // Dim all memories except the focused one
      Object.keys(this.objectsMap).forEach(id => {
        const obj = this.objectsMap[id];
        if (obj.type === 'memory') {
          if (id === memoryId) {
            // Highlight the focused memory
            obj.element.transition()
              .duration(300)
              .style('opacity', 1);
            
            // Make it slightly larger
            obj.element.select('path')
              .transition()
              .duration(300)
              .attr('d', d3.line()(this.createHexagonPoints(obj.width / 1.8)));
          } else {
            // Dim other memories
            obj.element.transition()
              .duration(300)
              .style('opacity', 0.3);
          }
        }
      });
    }
    
    // Find related records for the memory
    const relatedRecords = this.findRelatedRecords(memoryData);
    
    // Emit the memory clicked event with the memory data and toggle state
    // The RecordsComponent will handle the toggle behavior
    this.memoryClicked.emit({
      memory: memoryData,
      relatedRecords: relatedRecords,
      isSameMemory: isSameMemory
    });
  }
  
  /**
   * Keep the selected memory highlighted when the panel is open
   * @param memoryId ID of the memory to keep highlighted
   */
  public keepMemoryHighlighted(memoryId: string): void {
    if (!memoryId) return;
    
    // First try to find the memory directly in the objectsMap
    if (this.objectsMap[memoryId]) {
      const obj = this.objectsMap[memoryId];
      if (obj && obj.type === 'memory') {
        // Get the memory type to determine the highlight color
        const memoryType = obj.data?.type || 'default';
        let highlightColor = '#ff584a'; // Default to accent red color
        
        // Use the memory type to determine the highlight color
        switch(memoryType) {
          case 'data_issue':
            highlightColor = '#607d8b'; // Blue-gray
            break;
          case 'model_evaluation':
            highlightColor = '#5c6bc0'; // Indigo
            break;
          case 'user_feedback':
            highlightColor = '#26a69a'; // Teal
            break;
          case 'system_alert':
            highlightColor = '#ef5350'; // Light red
            break;
          case 'performance_metric':
            highlightColor = '#66bb6a'; // Green
            break;
          default:
            highlightColor = '#ff584a'; // Accent red color
        }
        
        // Highlight the selected memory
        obj.element.transition()
          .duration(300)
          .style('opacity', 1);
          
        // Make it larger (twice the size when focused as per memory)
        obj.element.select('path')
          .transition()
          .duration(300)
          .attr('d', d3.line()(this.createHexagonPoints(obj.width)));
        
        // Dim other standalone memories, but not memory indicators inside tables
        Object.keys(this.objectsMap).forEach(id => {
          const currentObj = this.objectsMap[id];
          
          // Only dim if it's a memory and not inside a table
          if (id !== memoryId && 
              currentObj.type === 'memory' && 
              !this.isMemoryInsideTable(currentObj)) {
            
            currentObj.element.transition()
              .duration(300)
              .style('opacity', 0.3);
          }
        });
        
        return; // Successfully highlighted the memory
      }
    }
    
    // If not found by direct ID, try to find by memory_id property
    // This handles cases where multiple memories might have the same memory_id
    const matchingObjects = Object.entries(this.objectsMap)
      .filter(([key, obj]) => 
        obj.type === 'memory' && 
        obj.data && 
        obj.data.memory_id === memoryId);
    
    if (matchingObjects.length > 0) {
      // Get the exact memory that was clicked (stored in focusedMemoryId)
      const exactMatch = matchingObjects.find(([key]) => key === this.focusedMemoryId);
      
      // If we have an exact match, use it; otherwise use the first match
      const [key, obj] = exactMatch || matchingObjects[0];
      
      // Get the memory type to determine the highlight color
      const memoryType = obj.data?.type || 'default';
      let highlightColor = '#ff584a'; // Default to accent red color
      
      // Use the memory type to determine the highlight color
      switch(memoryType) {
        case 'data_issue':
          highlightColor = '#607d8b'; // Blue-gray
          break;
        case 'model_evaluation':
          highlightColor = '#5c6bc0'; // Indigo
          break;
        case 'user_feedback':
          highlightColor = '#26a69a'; // Teal
          break;
        case 'system_alert':
          highlightColor = '#ef5350'; // Light red
          break;
        case 'performance_metric':
          highlightColor = '#66bb6a'; // Green
          break;
        default:
          highlightColor = '#ff584a'; // Accent red color
      }
      
      // Highlight the found memory
      obj.element.transition()
        .duration(300)
        .style('opacity', 1);
        
      // Make it larger (twice the size when focused as per memory)
      obj.element.select('path')
        .transition()
        .duration(300)
        .attr('d', d3.line()(this.createHexagonPoints(obj.width)));
      
      // Dim other standalone memories, but not memory indicators inside tables
      Object.keys(this.objectsMap).forEach(id => {
        const currentObj = this.objectsMap[id];
        
        // Only dim if it's a memory and not inside a table
        if (id !== key && 
            currentObj.type === 'memory' && 
            !this.isMemoryInsideTable(currentObj)) {
          
          currentObj.element.transition()
            .duration(300)
            .style('opacity', 0.3);
        }
      });
    }
  }
  
  /**
   * Check if a memory is inside a table
   * @param obj The memory object to check
   * @returns True if the memory is inside a table, false otherwise
   */
  private isMemoryInsideTable(obj: MemoryRenderData): boolean {
    // Check if this memory is a small indicator inside a table
    // Memory indicators inside tables have a parent element with class 'table'
    // or have a smaller size than standalone memories
    
    // Check if the memory has a small size (indicator inside a table)
    if (obj.width && obj.width < 15) {
      return true;
    }
    
    // Check if the memory has a parent element with class 'table'
    if (obj.element && obj.element.node()) {
      const parentElement = obj.element.node().parentElement;
      if (parentElement) {
        const parentSelection = d3.select(parentElement);
        if (parentSelection.classed('table') || 
            parentSelection.attr('data-type') === 'table' ||
            parentSelection.attr('class')?.includes('table')) {
          return true;
        }
      }
    }
    
    return false;
  }

  /**
   * Clear focus from all memories
   */
  public clearMemoryFocus(): void {
    this.focusedMemoryId = null;
    
    // Restore all memories to their normal state
    Object.keys(this.objectsMap).forEach(id => {
      const obj = this.objectsMap[id];
      if (obj.type === 'memory') {
        obj.element.transition()
          .duration(300)
          .style('opacity', 1)
          .style('filter', null);
          
        // Restore original size
        obj.element.select('path')
          .transition()
          .duration(300)
          .attr('d', d3.line()(this.createHexagonPoints(obj.width / 2)));
      }
    });
    
    // Emit an event that focus was cleared
    const clearEvent = new CustomEvent('memory-focus-cleared');
    document.dispatchEvent(clearEvent);
  }

  /**
   * Highlight a specific memory object
   */
  public highlightObject(id: string, options: { color?: string; duration?: number } = {}): void {
    const obj = this.objectsMap[id];
    if (!obj) return;

    const color = options.color || '#ff9800';
    const duration = options.duration || 1000;

    // Highlight the memory
    obj.element.select('path')
      .transition()
      .duration(duration / 2)
      .attr('stroke', color)
      .attr('stroke-width', 3)
      .attr('opacity', 1)
      .transition()
      .duration(duration / 2)
      .attr('stroke', '#fff')
      .attr('stroke-width', 1.5)
      .attr('opacity', 0.8);
  }

  /**
   * Find records related to this memory
   */
  private findRelatedRecords(memory: MemoryItem): any[] {
    const relatedRecords: any[] = [];
    
    // First, add related objects from the objectsMap
    Object.entries(this.objectsMap).forEach(([id, obj]) => {
      // Skip the current memory
      if (this.isMemory(obj) && obj.data && obj.data.memory_id === memory.memory_id) {
        return;
      }
      
      // Check for table relationships
      if (this.isTable(obj)) {
        // If memory has a table_id and it matches this table
        if (memory.table_id && id === memory.table_id) {
          relatedRecords.push({
            id: id,
            type: 'table',
            name: this.getObjectName(obj)
          });
        }
        // Or if the table belongs to the same project as the memory
        else if (memory.project_id && obj.data && obj.data.project_id === memory.project_id) {
          relatedRecords.push({
            id: id,
            type: 'table',
            name: this.getObjectName(obj)
          });
        }
      }
      
      // Check for memory relationships
      else if (this.isMemory(obj)) {
        // For memories, we'll consider them related if they share the same project_id
        if (memory.project_id && obj.data && obj.data.project_id === memory.project_id) {
          relatedRecords.push({
            id: id,
            type: 'memory',
            name: this.getObjectName(obj)
          });
        }
        
        // Also consider memories related if they share the same session_id
        if (memory.session_id && obj.data && obj.data.session_id === memory.session_id) {
          // Avoid duplicates
          if (!relatedRecords.some(record => record.id === id)) {
            relatedRecords.push({
              id: id,
              type: 'memory',
              name: this.getObjectName(obj)
            });
          }
        }
        
        // Also consider memories related if they share the same table_id
        if (memory.table_id && obj.data && obj.data.table_id === memory.table_id) {
          // Avoid duplicates
          if (!relatedRecords.some(record => record.id === id)) {
            relatedRecords.push({
              id: id,
              type: 'memory',
              name: this.getObjectName(obj)
            });
          }
        }
      }
      
      // Check for project relationships
      else if (this.isProject(obj)) {
        if (memory.project_id && id === memory.project_id) {
          relatedRecords.push({
            id: id,
            type: 'project',
            name: this.getObjectName(obj)
          });
        }
      }
    });
    
    // Now try to find related sessions and workstreams
    if (this.elementsLinkService) {
      // Get session references that might be related to this memory
      const sessionRefs = this.elementsLinkService.getAllSessionReferences();
      Object.values(sessionRefs).forEach(session => {
        if (memory.session_id === session.id || memory.project_id === session.projectId) {
          if (!relatedRecords.some(record => record.id === session.id)) {
            relatedRecords.push({
              id: session.id,
              type: 'session',
              name: session.data?.name || session.data?.session_name || 'Session'
            });
          }
        }
      });
      
      // Get workstream references that might be related to this memory
      const workstreamRefs = this.elementsLinkService.getAllWorkstreamReferences();
      Object.values(workstreamRefs).forEach(workstream => {
        if (memory.project_id === workstream.projectId) {
          if (!relatedRecords.some(record => record.id === workstream.id)) {
            relatedRecords.push({
              id: workstream.id,
              type: 'workstream',
              name: workstream.data?.name || workstream.data?.workstream_name || 'Workstream'
            });
          }
        }
      });
    }
    
    return relatedRecords;
  }
  
  /**
   * Get a display name for an object
   */
  private getObjectName(obj: any): string {
    if (!obj || !obj.data) return 'Unknown';
    
    // Different naming logic based on object type
    switch (obj.type) {
      case 'table':
        return obj.data.name || obj.data.table_name || 'Unnamed Table';
      case 'project':
        return obj.data.name || obj.data.project_name || 'Unnamed Project';
      case 'agent':
        return obj.data.name || obj.data.agent_name || 'Unnamed Agent';
      case 'document':
        return obj.data.name || obj.data.document_name || 'Unnamed Document';
      case 'memory':
        return `Memory (${obj.data.type || 'Unknown Type'})`;
      default:
        return 'Unknown Object';
    }
  }

  /**
   * Generate tooltip content for a memory
   */
  private generateMemoryTooltip(memory: MemoryItem): string {
    // Get type label with proper capitalization
    const typeLabel = memory.type ? 
      memory.type.charAt(0).toUpperCase() + memory.type.slice(1).replace('_', ' ') : 
      'Unknown';
      
    // Format the tooltip content using the tooltip service formatting methods
    let content = '';
    
    // Add header with memory type
    content += this.tooltipService.formatHeader(`${typeLabel} Memory`, 'memory');
    
    // Main content section
    let mainContent = '';
    
    // Add description if available (observation or insight)
    if (memory.observation) {
      mainContent += `<div class="tooltip-description">${memory.observation}</div>`;
    } else if (memory.insight) {
      mainContent += `<div class="tooltip-description">${memory.insight}</div>`;
    }
    
    // Add memory details section
    let detailsContent = '';
    
    // Add memory properties
    if (memory.memory_id) {
      detailsContent += this.tooltipService.formatProperty('ID', memory.memory_id.substring(0, 8) + '...');
    }
    
    if (memory.importance) {
      detailsContent += this.tooltipService.formatProperty('Importance', `${memory.importance}/10`);
    }
    
    if (memory.reliability) {
      detailsContent += this.tooltipService.formatProperty('Reliability', `${memory.reliability}/10`);
    }
    
    if (memory.created_date) {
      const date = new Date(memory.created_date);
      detailsContent += this.tooltipService.formatProperty('Created', date.toLocaleString());
    }
    
    // Add details section if we have any details
    if (detailsContent) {
      mainContent += this.tooltipService.formatSection(detailsContent, 'Details');
    }
    
    // Add the main content
    content += `<div class="tooltip-content">${mainContent}</div>`;
    
    return content;
  }

  /**
   * Get color for memory type
   */
  private getMemoryTypeColor(type: string): string {
    return this.defaultConfig.memoryTypeColorMap[type] || this.defaultConfig.memoryTypeColorMap['default'];
  }

  /**
   * Create hexagon points for the Ask-Y logo shape
   */
  private createHexagonPoints(size: number): [number, number][] {
    const points: [number, number][] = [];
    const angleOffset = Math.PI / 6; // 30 degrees offset

    for (let i = 0; i < 6; i++) {
      const angle = (i * Math.PI / 3) + angleOffset;
      const x = size * Math.sin(angle);
      const y = size * Math.cos(angle);
      points.push([x, y]);
    }

    // Close the path
    points.push(points[0]);

    return points;
  }

  /**
   * Generate a unique ID for a memory
   */
  private getMemoryId(memory: MemoryItem): string {
    // Create a hash from multiple properties to ensure uniqueness
    const uniqueProperties = [
      memory.memory_id,
      memory.project_id || '',
      memory.session_id,
      memory.type,
      memory.created_date,
      memory.observation,
      memory.insight,
      memory.importance?.toString() || '',
      memory.reliability?.toString() || '',
      // Add position info if available to differentiate between visually distinct instances
      (memory as MemoryItemWithPosition).x?.toString() || '',
      (memory as MemoryItemWithPosition).y?.toString() || ''
    ];
    
    // Join all properties and create a more unique identifier
    return `memory-${uniqueProperties.join('-')}`.toLowerCase();
  }

  /**
   * Type guard to check if an object is a table
   */
  private isTable(obj: any): boolean {
    return obj.type === 'table';
  }
  
  /**
   * Type guard to check if an object is a memory
   */
  private isMemory(obj: any): boolean {
    return obj.type === 'memory';
  }
  
  /**
   * Type guard to check if an object is a project
   */
  private isProject(obj: any): boolean {
    return obj.type === 'project';
  }
}
