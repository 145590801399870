<div class="input-box">
  <!-- Transcription error message -->
  <div class="transcription-error" *ngIf="transcriptionError">
    <span>{{ transcriptionError }}</span>
  </div>

  <div class="input-container" [class.recording]="isRecording">
    <!-- File preview area -->
    <div class="file-preview" *ngIf="attachedFile && !isRecording">
      <div class="file-info">
        <span class="file-name">{{ attachedFile.name }}</span>
        <span class="file-size">({{ (attachedFile.size / 1024).toFixed(1) }} KB)</span>
      </div>
      <button 
        class="remove-file-button"
        (click)="removeAttachedFile()"
        aria-label="Remove attached file">
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    
    <!-- Recording indicator -->
    <div class="recording-indicator" *ngIf="isRecording">
      <div class="recording-pulse"></div>
      <span class="recording-time">{{ formatRecordingTime(recordingDuration) }}</span>
    </div>

    <!-- Transcribing indicator -->
    <div class="transcribing-indicator" *ngIf="isTranscribing">
      <span>Transcribing...</span>
    </div>
    
    <!-- Message input area - hide during recording -->
    <textarea
      *ngIf="!isRecording"
      #textArea
      [(ngModel)]="message"
      placeholder="Send a message"
      (keydown)="onKeyDown($event)"
      (input)="adjustTextarea()"
      [disabled]="isTranscribing"
      rows="1"></textarea>
      
    <div class="actions-container">
      <!-- File attachment button -->
      <button 
        class="action-button attach-button"
        (click)="onAttachFile()"
        [disabled]="isRecording || isTranscribing"
        aria-label="Attach files">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z" fill="#232423"/>
        </svg>
      </button>
      
      <!-- Stop button (shown during recording) with separate handling -->
      <button 
        *ngIf="isRecording"
        class="action-button stop-button"
        (click)="stopRecordingAndTranscribe()"
        aria-label="Stop recording">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="6" y="6" width="12" height="12" rx="2" fill="currentColor"/>
        </svg>
      </button>
      
      <!-- Send/Record button (not shown during recording) -->
      <button 
        *ngIf="!isRecording"
        class="action-button"
        [class.send-button]="!shouldShowAudioIcon()"
        [class.audio-button]="shouldShowAudioIcon()"
        [disabled]="(!shouldShowAudioIcon() && !message.trim() && !attachedFile) || isProcessing || isTranscribing"
        (click)="handleButtonClick($event)"
        [attr.aria-label]="shouldShowAudioIcon() ? 'Start recording' : 'Send message'">
        
        <!-- Audio icon (shown when input is empty) -->
        <svg *ngIf="shouldShowAudioIcon()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.5 4C8.67157 4 8 4.67157 8 5.5V18.5C8 19.3284 8.67157 20 9.5 20C10.3284 20 11 19.3284 11 18.5V5.5C11 4.67157 10.3284 4 9.5 4Z" fill="currentColor"></path>
          <path d="M13 8.5C13 7.67157 13.6716 7 14.5 7C15.3284 7 16 7.67157 16 8.5V15.5C16 16.3284 15.3284 17 14.5 17C13.6716 17 13 16.3284 13 15.5V8.5Z" fill="currentColor"></path>
          <path d="M4.5 9C3.67157 9 3 9.67157 3 10.5V13.5C3 14.3284 3.67157 15 4.5 15C5.32843 15 6 14.3284 6 13.5V10.5C6 9.67157 5.32843 9 4.5 9Z" fill="currentColor"></path>
          <path d="M19.5 9C18.6716 9 18 9.67157 18 10.5V13.5C18 14.3284 18.6716 15 19.5 15C20.3284 15 21 14.3284 21 13.5V10.5C21 9.67157 20.3284 9 19.5 9Z" fill="currentColor"></path>
        </svg>
        
        <!-- Send icon (shown when input has text) -->
        <svg *ngIf="!shouldShowAudioIcon()" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z" fill="currentColor"/>
        </svg>
      </button>
    </div>
  </div>
</div>