// home.component.ts
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitterModule } from '../shared/components/splitter/splitter.module';
import { HeaderComponent } from '../components/header/header.component';
import { ChatComponent } from '../components/session/chat/chat.component';
import { DatapaneComponent } from '../components/datapane/datapane.component';
import { WorkboardComponent } from '../components/workboard/workboard.component';
import { ProjectManagerService } from '../services/project-manager.service';
import { SessionsGridComponent } from '../components/sessions-grid/sessions-grid.component';
import { Subscription } from 'rxjs';
import { SessionsGridState } from '../models/session.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    SplitterModule,
    ChatComponent,
    DatapaneComponent,
    WorkboardComponent,
    SessionsGridComponent,
    HeaderComponent
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  private subscription = new Subscription();
  selectedSessionId: string | undefined;

  constructor(
    private projectManager: ProjectManagerService
  ) {}

  async ngOnInit() {

    // Initialize project manager
    await this.projectManager.initWorkspaces();

    // Subscribe to grid state to get selected session
    this.subscription.add(
      this.projectManager.getGridState().subscribe((state: SessionsGridState) => {
        this.selectedSessionId = state.selectedSessionId;
      })
    );
  } 

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
