/**
 * Types and enums for the chat system
 */

export enum SessionStatus {
  Active = 'active',
  Paused = 'paused',
  Completed = 'completed',
  Archived = 'archived'
}

export enum ArtifactAction {
  Sent = 'sent',
  Created = 'created',
  Edited = 'edited'
}

export enum MessageRole {
  User = 'user',
  Agent = 'agent',
  System = 'system'
}

export interface Artifact {
  id: string;
  title: string;
  type: string;
  preview?: string;
  content: string;
  action: ArtifactAction;
  createdAt: Date;
  updatedAt?: Date;
  language?: string;
}

export interface ChatMessage {
  id: string;
  sessionId: string;
  content: string;
  role: MessageRole;
  senderId: string;
  timestamp: Date;
  artifacts: Artifact[];
  metadata?: Record<string, unknown>;
  status?: 'pending' | 'sent' | 'error';
}

export interface ChatSession {
  id: string;
  title: string;
  status: SessionStatus;
  createdAt: Date;
  updatedAt: Date;
  participants: {
    users: string[];
    agents: string[];
  };
  metadata?: Record<string, unknown>;
  lastMessage?: ChatMessage;
}