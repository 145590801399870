/**
 * @file src/app/services/tools/generate-chart-tool.ts
 */
import { RiffMLNode } from '../riffml-parser.service';
import { ToolsService } from '../tools.service';

/**
 * Tool: generateChart
 * Generates a mermaid chart from the current table data and chart description
 * 
 * @param service The ToolsService instance that provides access to other services
 */
export function registerGenerateChartTool(service: ToolsService): void {
  (service as any).generateChart = async function(next: string, sessionId: string, riffMLNode: RiffMLNode | null, value: any): Promise<void> {
    console.log(`ToolsService.generateChart() called with next=${next}`);
    
    // Get the current chart description
    const chartDescription = this.workspaceState.CurrentChartDescription$.getValue();
    const chartDetailedDescription = this.workspaceState.CurrentChartDetailedDescription$.getValue();

    // Get the current table data
    const currentTable = this.workspaceState.CurrentTable$.getValue();
    if (!currentTable) {
      console.warn('No table data available');
      return;
    }

    // Store the current table in state
    await this.setState(
      sessionId,
      'currentTable',
      'currentTable',
      'Current Table',
      'The current table data for chart generation',
      'Used for generating the mermaid chart',
      'table',
      currentTable
    );

    if (chartDescription && chartDescription.trim() !== '') {
        await this.setState(
            sessionId,
            'currentChartDescription',
            'currentChartDescription',
            'Current Chart Description',
            'The current chart description',
            'Used for generating the mermaid chart',
            'string',
            chartDescription
        );
    }

    if (chartDetailedDescription && chartDetailedDescription.trim() !== '') {
        await this.setState(
            sessionId,
            'currentChartDetailedDescription',
            'currentChartDetailedDescription',
            'Current Chart Detailed Description',
            'The detailed description of the chart',
            'Used for generating the mermaid chart with more context',
            'string',
            chartDetailedDescription
        );
    }

    // Get the process turn handler
    const processTurnHandler = this.getProcessTurnHandler();
    if (!processTurnHandler) {
      console.error('No ProcessTurnHandler registered to handle chart generation');
      return;
    }

    // Run the chart generation playbook
    await processTurnHandler.runPlaybook(sessionId, '48224', false, 'currentChart');

    // Get the generated mermaid code from state
    const currentChart = await this.stateManagerService.getObject(sessionId, 'currentChart');

    // TODO: handle json format
    if (currentChart) {
      // Try to parse as JSON if it's a string
      const parsedChart = typeof currentChart === 'string' ? this.jsonParserService.safeParseJson(currentChart) : currentChart;
      
      if (typeof parsedChart === 'object' && parsedChart !== null) {
        // Handle the JSON format with mermaid and description
        if (parsedChart.mermaid) {
          // Unescape the mermaid string if needed
          const unescapedMermaid = parsedChart.mermaid.replace(/\\"/g, '"');
          this.workspaceState.CurrentMermaid$.next(unescapedMermaid);
        }
        if (parsedChart.description) {
          // Unescape the description string if needed
          const unescapedDescription = parsedChart.description.replace(/\\"/g, '"');
          this.workspaceState.CurrentChartDescription$.next(unescapedDescription);
        }
      } else {
        // If not a JSON object, treat as plain mermaid string
        this.workspaceState.CurrentMermaid$.next(currentChart);
      }
    } else {
      console.error('Failed to generate mermaid chart');
    }
  };
} 