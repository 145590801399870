/**
 * @file src/app/components/templates/templates.component.ts
 * @description Component for managing templates functionality
 */

import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryMenuService, MenuData, SolutionData, Solution, MenuItem } from '../../services/library-menu.service';
import { Observable, combineLatest, map } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Pipe({
  name: 'find',
  standalone: true
})
export class FindPipe implements PipeTransform {
  transform(array: any[] | null, value: any, key: string): any {
    if (!array) return null;
    return array.find(item => item[key] === value);
  }
}

@Component({
  selector: 'app-templates',
  standalone: true,
  imports: [CommonModule, FindPipe, FormsModule],
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  menuData$: Observable<MenuData | null>;
  solutionData$: Observable<SolutionData | null>;
  selectedTab: 'solutions' | 'tasks' = 'solutions';
  selectedCategory: string = 'all';
  recentlyViewed: Solution[] = [];
  searchQuery: string = '';
  selectedSolution: Solution | null = null;
  selectedTask: MenuItem | null = null;

  constructor(private libraryMenuService: LibraryMenuService) {
    this.menuData$ = this.libraryMenuService.menuData$;
    this.solutionData$ = this.libraryMenuService.solutionData$;
    this.loadRecentlyViewed();
  }

  ngOnInit() {}

  selectTab(tab: 'solutions' | 'tasks') {
    this.selectedTab = tab;
    this.selectedCategory = 'all';
    this.closeModal();
  }

  selectCategory(category: string) {
    this.selectedCategory = category;
    this.closeModal();
  }

  openModal(item: Solution | MenuItem) {
    if (this.selectedTab === 'solutions') {
      this.selectedSolution = item as Solution;
      this.selectedTask = null;
    } else {
      this.selectedTask = item as MenuItem;
      this.selectedSolution = null;
    }
  }

  closeModal() {
    this.selectedSolution = null;
    this.selectedTask = null;
  }

  getFilteredSolutions(solutionData: SolutionData): Solution[] {
    if (!solutionData) return [];

    let solutions: Solution[] = [];

    if (this.selectedCategory === 'recent') {
      solutions = this.recentlyViewed;
    } else if (this.selectedCategory === 'all') {
      solutions = solutionData.categories.reduce((acc, category) => {
        return [...acc, ...category.solutions];
      }, [] as Solution[]);
    } else {
      const category = solutionData.categories.find(c => c.id === this.selectedCategory);
      solutions = category ? category.solutions : [];
    }

    // Apply search filter if there's a search query
    if (this.searchQuery.trim()) {
      const query = this.searchQuery.toLowerCase().trim();
      return solutions.filter(solution => 
        solution.name.toLowerCase().includes(query) ||
        solution.goal.toLowerCase().includes(query) ||
        solution.description.toLowerCase().includes(query)
      );
    }

    return solutions;
  }

  getFilteredTasks(menuData: MenuData): MenuItem[] {
    if (!menuData) return [];

    let tasks: MenuItem[] = [];

    if (this.selectedCategory === 'recent') {
      return []; // Implement recently viewed tasks if needed
    } else if (this.selectedCategory === 'all') {
      tasks = menuData.categories.reduce((acc, category) => {
        return [...acc, ...category.items];
      }, [] as MenuItem[]);
    } else {
      const category = menuData.categories.find(c => c.name === this.selectedCategory);
      tasks = category ? category.items : [];
    }

    // Apply search filter if there's a search query
    if (this.searchQuery.trim()) {
      const query = this.searchQuery.toLowerCase().trim();
      return tasks.filter(task => 
        task.name.toLowerCase().includes(query) ||
        (task.firstMessage && task.firstMessage.toLowerCase().includes(query))
      );
    }

    return tasks;
  }

  viewSolution(solution: Solution) {
    // Add to recently viewed
    const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed') || '[]');
    const exists = recentlyViewed.find((s: Solution) => s.id === solution.id);
    if (!exists) {
      recentlyViewed.unshift(solution);
      if (recentlyViewed.length > 5) {
        recentlyViewed.pop();
      }
      localStorage.setItem('recentlyViewed', JSON.stringify(recentlyViewed));
      this.recentlyViewed = recentlyViewed;
    }
  }

  private loadRecentlyViewed() {
    this.recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed') || '[]');
  }
} 