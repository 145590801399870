import { Component, Input, ElementRef, AfterContentInit, ContentChildren, QueryList, HostBinding } from '@angular/core';
import { SplitterPaneComponent } from './splitter-pane.component';

@Component({
  selector: 'app-splitter',
  template: `
    <div class="splitter-container" 
         [class.vertical]="orientation === 'vertical'"
         [class.horizontal]="orientation === 'horizontal'">
      <ng-content></ng-content>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .splitter-container {
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      gap: 0;
    }
    .horizontal {
      flex-direction: row;
    }
    .vertical {
      flex-direction: column;
      height: calc(100vh - 45px);
    }
  `]
})
export class SplitterComponent implements AfterContentInit {
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @ContentChildren(SplitterPaneComponent) panes!: QueryList<SplitterPaneComponent>;

  ngAfterContentInit() {
    this.updatePanes();
    this.panes.changes.subscribe(() => this.updatePanes());
  }

  private updatePanes() {
    this.panes.forEach((pane, index) => {
      pane.isVertical = this.orientation === 'vertical';
      pane.isLastPane = index === this.panes.length - 1;
      
      // For vertical splits, handle the last pane differently
      if (this.orientation === 'vertical' && pane.isLastPane) {
        // Last pane should take remaining height
        pane.hostFlex = '1 1 auto';
        pane.hostWidth = '100%';
        pane.hostHeight = 'auto';
      } else {
        // For all other panes or horizontal splits, use normal size behavior
        pane.updateDisplay();
      }
    });
  }
} 