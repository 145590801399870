/* 
 * # Artifact: src\app\services\workboard-manager.service.ts
 *
 * Explanation:
 * - We added a new method `GetWorkboardForPrompt()` that returns a string representation
 *   of the current workboard state, following the format shown in the example.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkboardState, WorkboardGroup, WorkstreamColumn, Workstream } from '../models/workboard.model';
import { ArtifactsService } from './artifacts.service';
import { WorkspaceState } from '../models/workspace-state.model';
import { ProjectData } from '../models/project.interface';

@Injectable({
  providedIn: 'root'
})
export class WorkboardManagerService {
  constructor(
    private artifactsService: ArtifactsService,
    private sharedState: WorkspaceState
  ) {}

  public getWorkboardState(): Observable<WorkboardState> {
    return this.sharedState.workboardState$.asObservable();
  }

  /**
   * Loads project data and user project view for the current project
   * @returns Object containing project data, user project view, and project ID
   */
  public async loadCurrentProject(): Promise<{
    projectData: ProjectData | null,
    userProjectView: any | null,
    projectId: string | null
  }> {
    const projectId = this.sharedState.userWorkspaceState$.value?.selectedProject;
    if (!projectId) {
      return { projectData: null, userProjectView: null, projectId: null };
    }

    const artifactId = 'project_' + projectId;
    const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
    let userProjectView = await this.artifactsService.loadArtifact(artifactId, true);

    if (!userProjectView) {
       userProjectView = {
        sessionState: {
          filters: [],
          sortBy: 'lastEdited' as const,
          sortDirection: 'desc' as const
        },
        groupStates: {},
        workstreamStates: {}
      };

      await this.artifactsService.saveArtifact('project_' + projectId, userProjectView, true);
      this.sharedState.setProject(projectId, projectData);

    }

    return { projectData, userProjectView, projectId };
  }

  /**
   * Gets project data for a specific project ID
   * @param projectId Project ID to retrieve data for
   * @returns Promise containing the project data or null if not found
   */
  public async getProjectDataById(projectId: string): Promise<ProjectData | null> {
    if (!projectId) {
      return null;
    }

    const artifactId = 'project_' + projectId;
    try {
      const projectData = await this.artifactsService.loadArtifact(artifactId) as ProjectData;
      return projectData;
    } catch (error) {
      console.error(`Error loading project data for ID ${projectId}:`, error);
      return null;
    }
  }

  /**
   * Saves project data and updates the user project view
   * @param projectId Project ID
   * @param projectData Project data to save
   * @param userProjectView User project view to save
   */
  public async saveProjectChanges(
    projectId: string, 
    projectData: ProjectData | null, 
    userProjectView: any | null
  ): Promise<void> {
    const artifactId = 'project_' + projectId;
    
    if (projectData) {
      projectData.workboardState = this.sharedState.workboardState$.value;
      await this.artifactsService.saveArtifact(artifactId, projectData, false, false);
      this.sharedState.setProject(projectId, projectData);

    }

    if (userProjectView) {
      await this.artifactsService.saveArtifact(artifactId, userProjectView, true, false);
    }
  }

  /**
   * Gets workstream data by ID from the current workboard state
   * @param workstreamId The ID of the workstream to retrieve
   * @returns The workstream object if found, undefined otherwise
   */
  public getWorkstreamById(workstreamId: string): Workstream | undefined {
    const currentState = this.sharedState.workboardState$.value;
    
    if (!currentState || !currentState.groups) {
      return undefined;
    }
    
    for (const group of currentState.groups) {
      for (const col of group.workstreamCols) {
        const workstream = col.workstreams.find(ws => ws.id === workstreamId);
        if (workstream) {
          return workstream;
        }
      }
    }
    
    return undefined;
  }

  public async updateWorkstreamSelection(workstreamId: string, selectedState: 'yes' | 'no'): Promise<void> {
    const currentState = this.sharedState.workboardState$.value;
    let targetWorkstream: Workstream | null = null;

    // Update workboard state
    const updatedGroups = currentState.groups.map((group: WorkboardGroup) => ({
      ...group,
      workstreamCols: group.workstreamCols.map((col: WorkstreamColumn) => {
        const workstream = col.workstreams.find((ws: Workstream) => ws.id === workstreamId);
        if (workstream) {
          targetWorkstream = workstream;
          return {
            ...col,
            workstreams: col.workstreams.map((ws: Workstream) => {
              if (ws.id === workstreamId) {
                return { ...ws, selectedState };
              }
              return ws;
            })
          };
        }
        return col;
      })
    }));
    
    const newState: WorkboardState = { groups: updatedGroups };
    this.sharedState.workboardState$.next(newState);

    // Update selected workstreams list
    if (targetWorkstream) {
      this.sharedState.updateSelectedWorkstreams(
        targetWorkstream,
        selectedState === 'yes'
      );
    }

    // Persist changes
    await this.updateWorkstreamSelectStateInMemory(workstreamId, selectedState);
  }

  private async updateWorkstreamSelectStateInMemory(workstreamId: string, selectedState: string) {
    const { projectData, userProjectView, projectId } = await this.loadCurrentProject();
    if (projectId && userProjectView) {
      if (!userProjectView.workstreamSelections) {
        userProjectView.workstreamSelections = {};
      }
      userProjectView.workstreamSelections[workstreamId] = selectedState;
      await this.saveProjectChanges(projectId, projectData, userProjectView);
    }
  }

  /**
   * 4) Called by SessionsGrid when auto-select is active and user clicks a session.
   * @param relatedWorkstreamIds IDs of related workstreams to select.
   * @param mode 'auto-replace' | 'auto-select' | 'off'
   */
  public autoSelectWorkstreams(
    relatedWorkstreamIds: string[],
    mode: 'auto-replace' | 'auto-select' | 'off'
  ): void {
    if (!relatedWorkstreamIds || relatedWorkstreamIds.length === 0) {
      return;
    }

    switch (mode) {
      case 'auto-replace':    
        // Then select all the new ones
        this.selectWorkstreamsByIds(relatedWorkstreamIds, true);
        break;

      case 'auto-select':
        // Just select the new ones (existing selections remain)
        this.selectWorkstreamsByIds(relatedWorkstreamIds, false);
        break;

      case 'off':
      default:
        // Do nothing
        break;
    }
  }

/**
 * Utility function to locate each workstream by ID and set it as selected
 * @param workstreamIds Array of workstream IDs to select
 * @param replace If true, deselect all workstreams not in the list
 */
private async selectWorkstreamsByIds(workstreamIds: string[], replace: boolean): Promise<void> {
  const currentState = this.sharedState.workboardState$.value;
  let stateUpdated = false;
  
  // Create a map of all workstreams for quick lookup
  const allWorkstreams: Workstream[] = [];
  
  // Find all workstreams in the current state
  currentState.groups.forEach(group => {
    group.workstreamCols.forEach(col => {
      col.workstreams.forEach(ws => {
        allWorkstreams.push(ws);
      });
    });
  });
  
  // Create updated groups with modified workstreams
  const updatedGroups = currentState.groups.map(group => ({
    ...group,
    workstreamCols: group.workstreamCols.map(col => {
      const hasUpdates = col.workstreams.some(ws => {
        // If replace is true, we need to process all workstreams
        // Otherwise, only process workstreams in the provided list
        return replace || workstreamIds.includes(ws.id);
      });
      
      if (!hasUpdates) {
        return col;
      }
      
      return {
        ...col,
        workstreams: col.workstreams.map(ws => {
          // For workstreams in the provided list, always select them
          if (workstreamIds.includes(ws.id)) {
            stateUpdated = true;
            return { 
              ...ws, 
              selectedState: 'yes' as 'yes',
              state: 'open' as 'open'
            };
          } 
          // For other workstreams, if replace is true, deselect them
          else if (replace) {
            stateUpdated = true;
            return { 
              ...ws, 
              selectedState: 'no' as 'no',
              state: 'closed' as 'closed'
            };
          }
          // Otherwise, leave them as they are
          return ws;
        })
      };
    })
  }));
  
  // Only update state and persist if changes were made
  if (stateUpdated) {
    const newState: WorkboardState = { 
      groups: updatedGroups as WorkboardGroup[] 
    };
    this.sharedState.workboardState$.next(newState);
    
    // Update selected workstreams list
    workstreamIds.forEach(wsId => {
      const ws = allWorkstreams.find(w => w.id === wsId);
      if (ws) {
        this.sharedState.updateSelectedWorkstreams(ws, true);
      }
    });
    
    if (replace) {
      // Remove workstreams that are not in the list
      allWorkstreams
        .filter(ws => !workstreamIds.includes(ws.id))
        .forEach(ws => {
          this.sharedState.updateSelectedWorkstreams(ws, false);
        });
    }
    
    // Persist changes to storage
    await this.updateWorkstreamSelectionStatesInMemory(
      allWorkstreams.map(ws => ws.id),
      workstreamIds
    );
  }
}

/**
 * Updates workstream selection states in memory for multiple workstreams
 * @param allWorkstreamIds All workstream IDs to consider
 * @param selectedWorkstreamIds IDs of workstreams that should be selected
 */
private async updateWorkstreamSelectionStatesInMemory(
  allWorkstreamIds: string[], 
  selectedWorkstreamIds: string[]
): Promise<void> {
  const { projectData, userProjectView, projectId } = await this.loadCurrentProject();
  
  if (projectId && userProjectView) {
    if (!userProjectView.workstreamSelections) {
      userProjectView.workstreamSelections = {};
    }
    
    if (!userProjectView.workstreamStates) {
      userProjectView.workstreamStates = {};
    }
    
    // Update all relevant workstreams at once
    allWorkstreamIds.forEach(wsId => {
      const isSelected = selectedWorkstreamIds.includes(wsId);
      userProjectView.workstreamSelections[wsId] = isSelected ? 'yes' : 'no';
      userProjectView.workstreamStates[wsId] = isSelected ? 'open' : 'closed';
    });
    
    // Save all changes at once
    await this.saveProjectChanges(projectId, projectData, userProjectView);
  }
}

  public async persistCurrentProject() {
    const { projectData, userProjectView, projectId } = await this.loadCurrentProject();
    if (projectId && userProjectView) {
      if (!userProjectView.workstreamSelections) {
        userProjectView.workstreamSelections = {};
      }
      await this.saveProjectChanges(projectId, projectData, userProjectView);
    }
  }

  /**
   * Generates a text prompt representation of the current workboard state.
   */
  public async GetWorkboardForPrompt(): Promise<string> {
    const state = this.sharedState.workboardState$.value;
    if (!state || !state.groups || state.groups.length === 0) {
      return 'No Workboard data found';
    }

    const lines: string[] = [];

    state.groups.forEach((group, index) => {
      lines.push(`GROUP ${index + 1}`);
      lines.push(` name ${group.name}`);
      lines.push(` id ${group.id}`);
      lines.push(` state ${group.state}`);

      group.workstreamCols.forEach(col => {
        lines.push(`   COLUMN ${col.id}`);

        if (col.workstreams.length === 0) {
          lines.push('   workstreams none');
        } else {
          col.workstreams.forEach(ws => {
            lines.push(`     WORKSTREAM ${ws.id}`);
            lines.push(`       name ${ws.name}`);
            lines.push(`       state ${ws.state}`);
            lines.push(`       description ${ws.description}`);
            if (!ws.input_table_ids || ws.input_table_ids.length === 0) {
              lines.push(`       input_table_ids none`);
            } else {
              lines.push(`       input_table_ids ${ws.input_table_ids.join(', ')}`);
            }
            lines.push(`       outcome ${ws.outcome}`);
          });
        }
      });
      lines.push(''); // spacing between groups
    });

    return lines.join('\n');
  }

  public async addWorkstreamToBoard(params: {
    group: WorkboardGroup;
    col: WorkstreamColumn;
    workstream: Workstream;
  }): Promise<void> {
    const currentState = this.sharedState.workboardState$.value;
    let stateUpdated = false;

    const newState: WorkboardState = {
      groups: [...currentState.groups]
    };

    let targetGroup = newState.groups.find(g => g.id === params.group.id);
    if (!targetGroup) {
      targetGroup = {
        ...params.group,
        workstreamCols: []
      };
      newState.groups.push(targetGroup);
      stateUpdated = true;
    }

    let targetCol = targetGroup.workstreamCols.find(c => c.id === params.col.id);
    if (!targetCol) {
      targetCol = {
        ...params.col,
        workstreams: []
      };
      targetGroup.workstreamCols.push(targetCol);
      stateUpdated = true;
    }

    const workstreamExists = targetCol.workstreams.some(w => w.id === params.workstream.id);
    if (!workstreamExists) {
      targetCol.workstreams.push(params.workstream);
      stateUpdated = true;
    }

    if (stateUpdated) {
      this.sharedState.workboardState$.next(newState);

      const { projectData, userProjectView, projectId } = await this.loadCurrentProject();
      if (projectId && userProjectView) {
        userProjectView.workstreamStates[params.workstream.id] = params.workstream.state;
        await this.saveProjectChanges(projectId, projectData, userProjectView);
      }
    }
  }

  public async updateGroupState(groupId: string, state: 'open' | 'closed'): Promise<void> {
    const currentState = this.sharedState.workboardState$.value;
    const updatedGroups = currentState.groups.map((group: WorkboardGroup) => {
      if (group.id === groupId) {
        return { ...group, state };
      }
      return group;
    });
    
    const newState: WorkboardState = { groups: updatedGroups };
    this.sharedState.workboardState$.next(newState);

    const { projectData, userProjectView, projectId } = await this.loadCurrentProject();
    if (projectId && userProjectView) {
      userProjectView.groupStates[groupId] = state;
      await this.saveProjectChanges(projectId, projectData, userProjectView);
    }
  }

  public async updateWorkstreamState(workstreamId: string, state: 'open' | 'closed'): Promise<void> {
    const currentState = this.sharedState.workboardState$.value;
    const updatedGroups = currentState.groups.map((group: WorkboardGroup) => ({
      ...group,
      workstreamCols: group.workstreamCols.map((col: WorkstreamColumn) => {
        const workstream = col.workstreams.find((ws: Workstream) => ws.id === workstreamId);
        if (workstream) {
          return {
            ...col,
            workstreams: col.workstreams.map((ws: Workstream) => {
              if (ws.id === workstreamId) {
                return { ...ws, state };
              }
              return ws;
            })
          };
        }
        return col;
      })
    }));
    
    const newState: WorkboardState = { groups: updatedGroups };
    this.sharedState.workboardState$.next(newState);

    const { projectData, userProjectView, projectId } = await this.loadCurrentProject();
    if (projectId && userProjectView) {
      userProjectView.workstreamStates[workstreamId] = state;
      await this.saveProjectChanges(projectId, projectData, userProjectView);
    }
  }

  /**
   * Generates a SQL query with WITH statements for units in a workstream up to a specified output table.
   * @param workstream The workstream containing the units with SQL code
   * @param tableId The ID of the output table to search for
   * @param limit Optional number of rows to limit each query to
   * @returns A string with the concatenated WITH statements ending with the unit containing the target table ID
   */
  public getWorkstreamSqlWithTables(workstream: Workstream, tableId: string, limit?: number): string {
    if (!workstream || !workstream.units || workstream.units.length === 0) {
      return '';
    }

    const sqlParts: string[] = [];
    let foundTargetTable = false;
    let isFirstUnit = true;

    for (const unit of workstream.units) {
      // Check if this unit has output tables
      if (!unit.output_table_ids || unit.output_table_ids.length === 0) {
        continue;
      }

      // Get the unit code
      const unitCode = unit.code || '';
      
      // Skip empty code
      if (unitCode.trim() === '') {
        continue;
      }
      
      // Check if this is valid SQL code that can be used in a WITH block
      if (!this.isValidWithClauseSql(unitCode)) {
        continue;
      }

      // Check if this unit has the target output table
      const targetTableConfig = unit.output_table_ids.find(tableConfig => 
        tableConfig.name === tableId
      );
      const hasTargetTable = !!targetTableConfig;

      // If this unit has valid SQL code
      if (isFirstUnit) {
        // First WITH statement doesn't need a comma
        // Use the first output table name for the WITH clause
        const tableName = unit.output_table_ids[0].name;
        const codeWithLimit = limit && limit > 0 ? `${unitCode} LIMIT ${limit}` : unitCode;
        sqlParts.push(`WITH ${tableName} AS (\n${codeWithLimit}\n)`);
        isFirstUnit = false;
      } else {
        // Subsequent statements need a comma
        const tableName = unit.output_table_ids[0].name;
        const codeWithLimit = limit && limit > 0 ? `${unitCode} LIMIT ${limit}` : unitCode;
        sqlParts.push(`,${tableName} AS (\n${codeWithLimit}\n)`);
      }

      // If we found the target table, we're done
      if (hasTargetTable) {
        foundTargetTable = true;
        break;
      }
    }

    // If we didn't find any matching tables or didn't add any SQL parts, return empty string
    if (sqlParts.length === 0 || !foundTargetTable) {
      return '';
    }

    // Add a semicolon at the end
    return sqlParts.join('\n');
  }

  /**
   * Determines if the given SQL code can be used in a WITH clause
   * @param sqlCode The SQL code to check
   * @returns True if the code can be used in a WITH clause, false otherwise
   */
  private isValidWithClauseSql(sqlCode: string): boolean {
    if (!sqlCode || sqlCode.trim() === '') {
      return false;
    }

    // Normalize the SQL by removing comments and extra whitespace
    let normalizedSql = this.removeCommentsFromSql(sqlCode).trim();
    
    // Check if it contains a SELECT statement
    // This is a simple heuristic - a more robust solution would use a SQL parser
    return /^\s*(WITH|SELECT|VALUES|TABLE|MERGE)/i.test(normalizedSql);
  }

  /**
   * Removes SQL comments from the given SQL code
   * @param sqlCode The SQL code to process
   * @returns SQL code with comments removed
   */
  private removeCommentsFromSql(sqlCode: string): string {
    if (!sqlCode) {
      return '';
    }

    // Remove single-line comments (-- to end of line)
    let result = sqlCode.replace(/--.*$/gm, '');
    
    // Remove multi-line comments (/* ... */)
    result = result.replace(/\/\*[\s\S]*?\*\//g, '');
    
    return result;
  }
}