// src/app/AuthGuard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { jwtDecode } from "jwt-decode";
import { UserService } from './services/user.service';
import { firstValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private msalService: MsalService,
    private router: Router,
    private userService: UserService
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      // Wait for token to be ready
      await firstValueFrom(this.userService.tokenReady$.pipe(
        filter(ready => ready === true),
        take(1)
      ));

      const account = this.msalService.instance.getActiveAccount();

      if (!account || !account.idToken) {
        console.error('No active account found or no ID token');
        this.router.navigate(['/login']);
        return false;
      }

      try {
        const decodedToken: any = jwtDecode(account.idToken);
        const email = decodedToken.preferred_username;

        if (!email) {
          console.error('Unable to extract email from token');
          this.userService.clearUser();
          this.msalService.logout();
          return false;
        }

        const isValidUser = email.toLowerCase().endsWith('@ask-y.ai') || email.toLowerCase().endsWith('@asky.dev');

        if (!isValidUser) {
          console.error('Invalid user credentials');
          this.userService.clearUser();
          this.msalService.logout();
          return false;
        }

        return true;
      } catch (error) {
        console.error('Error decoding token:', error);
        this.router.navigate(['/login']);
        return false;
      }
    } catch (error) {
      console.error('Error in authentication:', error);
      this.router.navigate(['/login']);
      return false;
    }
  }
}